import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography, Box, Hidden, IconButton } from '@material-ui/core';
import { resetCartState } from '../redux/CartRedux';
import { getMeasurementObjectFromRegularSize, inputMeasurementsForm, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import AddIcon from '@material-ui/icons/Add';
import { BodyMeasurementDetailList, ClothingMeasurementDetailList, ClothingMeasurements, ClothingMeasurementsKey, ClothingType, ClothingTypeDisplayString, FitType, FitTypeDisplayString, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { Mixpanel } from '../mixpanel';
import EditIcon from '@material-ui/icons/Edit';
import { closeOverlayAndReset as closeOverlayAndResetEditView, openOverlay as openOverlayEditView, setValues as setValuesEditView } from '../redux/UIOverlayMeasurementEditViewRedux';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import { useEffect, useState } from 'react';
import { formatTimestampSize } from './PageProductDetailSizeSelection';


const useStyles = makeStyles((theme: Theme) =>
createStyles({
  title: {
    padding: '20px',
    fontWeight: theme.typography.fontWeightBold as number,
  },
  containerStyle: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    width: '90vw',
    maxWidth: '375px'
  },
  nameText: {
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '16px',
    marginBottom: '4px',
  },
  bodyText: {
    fontWeight: theme.typography.fontWeightMedium as number,
    fontSize: '15px',
    marginLeft: '3px',
  },
  buttonStyle: {
    marginTop: '5px',
  },
  sizeOptionsBoxDetailText: {
    fontWeight: 500,
    letterSpacing: 0.5,
    paddingTop: '2px',
    fontSize: '2.1vw', // 9px -> 3px is 0.8vw
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px',
      paddingTop: '2px',
    }
  },
  sizeOptionsBoxDetailIconButton: {
    border: '1px solid #232323',
    borderRadius: '30px',
    backgroundColor: '#232323',
    color: '#fefefe',
    padding: '1.2vw 2.4vw',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 10px 5px 10px',
    }
  },
  sizeOptionsBoxEditIconButton: {
    border: '1px solid #232323',
    borderRadius: '40px',
    backgroundColor: '#232323',
    color: '#fefefe',
    padding: '1.5vw 1.5vw',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 7px 5px 7px',
    }
  },
  measurementMenuItem: {
    padding: '14px 16px 14px 16px',
    marginBottom: '10px',
    borderRadius: '20px',
    position: 'relative',

    border: '1px solid #ebebeb',

    [theme.breakpoints.only('xs')]: {
      padding: '3.2vw 3.4vw 3.2vw 3.4vw',
      borderRadius: '5vw',
    },
  },
  measurementMenuItemMainText: {
    fontSize: '12px',
    letterSpacing: '0.3px',
    fontWeight: 600,
    color: '#232323',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.1vw',
    }
  },
  measurementMenuItemDetailContentText: {
    fontSize: '10px',
    fontWeight: 300,
    color: '#232323',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.3vw',
    }
  },
}),
);

export default function PageAccountYourAccount() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const userClothingMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsDictionary)

  // const [selectedOption, setSelectedOption] = useState<number | null>(null);

  // const handleCategoryClick = (measurementOption: number) => {
  //   setSelectedOption(measurementOption);
  // };

  return (
      <Box>
        {/* Tab Kategori Yang Tidak Jadi Diimplementasi Saat Ini */}
        {/* <Box
          style={{
            width: '100%',
            padding: '20px 0px 20px 20px',
            overflowX: 'auto', 
            display: 'flex',
          }}
        >
          {Object.keys(userClothingMeasurementsDict).map((option, index) => {
            console.log(option)
            const clothingType = option.split('-')[0]
            const fitType = option.split('-')[1]
            const length = Object.keys(userClothingMeasurementsDict[option]).length

            console.log(clothingType)
            console.log(fitType)

            if (clothingType === 'undefined' || fitType === 'undefined') {
              return null
            }

            return (
              <Box 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  color: '#fefefe',
                  backgroundColor: '#232323',
                  padding: '8px 20px 8px 20px',
                  borderRadius: '20px',
                  whiteSpace: 'nowrap',
                  marginRight: '20px'
                }}
              key={option}
              >
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}
                >
                  {ClothingTypeDisplayString[clothingType as ClothingType]}
                </Typography>
                <Typography
                  style={{
                    fontSize: '10px',
                    fontWeight: 400
                  }}
                >
                  {FitTypeDisplayString[fitType as FitType]}
                </Typography>
                <Typography
                  style={{
                    fontSize: '10px',
                    fontWeight: 400
                  }}
                >
                  {length} Ukuran
                </Typography>
              </Box>
            )
          }
          )}
        </Box> */}
        
        {
          Object.keys(userClothingMeasurementsDict).map((option, index) => {
            const userMeasurementsByKeyTwo = userClothingMeasurementsDict[option];
            const result: JSX.Element[] = [];

            const clothingType = option.split('-')[0]
            const fitType = option.split('-')[1]
            const length = Object.keys(userClothingMeasurementsDict[option]).length

            if (clothingType === 'undefined' || fitType === 'undefined') {
              return null
            }

            result.push(
              <Box
                style={{
                  margin: '20px 0px 0px 30px'
                }}
              >
                <Typography
                style={{
                  fontSize: '18px',
                  fontWeight: 600,
                  fontFamily: "OakesGrotesk",
                }}>
                {ClothingTypeDisplayString[clothingType as ClothingType]} <span style={{fontWeight: 200, color: '#c6c6c6', letterSpacing: 0.5}}>- {FitTypeDisplayString[fitType as FitType]}</span> <span style={{fontWeight: 400, color: '#c6c6c6', fontSize: '12px', letterSpacing: 0.5}}> ({length} ukuran)</span>
                </Typography>
              </Box>
            )

            for (let creationDateTime in userMeasurementsByKeyTwo) {
              const userMeasurements = userMeasurementsByKeyTwo[creationDateTime][0]; 
              if (userMeasurements.clothingMeasurements === undefined) {
                result.push(
                  <Box className={classes.containerStyle}>
                    <Box className={classes.measurementMenuItem}
                      key={creationDateTime}
                    >
                      <Grid container>
                        <Grid item xs={11} style={{display: 'flex', alignItems: 'center'}}>
                        <Typography className={classes.measurementMenuItemMainText}>
                          {userMeasurements.measurementName}
                        </Typography>
                        </Grid>
                        <Grid item xs={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <IconButton
                        className={classes.sizeOptionsBoxEditIconButton}
                        disabled={!userMeasurements}
                        onClick={() => {
                          dispatch(closeOverlayAndResetEditView());
                          dispatch(setValuesEditView({
                            overlayType: 'edit',
                            clothingType: userMeasurements.clothingType as ClothingType,
                            fitType: userMeasurements.fitType as FitType,
                            isFullScreen: false,
                            currentMeasurementObject: getMeasurementObjectFromRegularSize(creationDateTime, userMeasurements.fitType as FitType, userMeasurements.clothingType as ClothingType, userMeasurements.regularSize ? userMeasurements.regularSize : RegularSizeValue.M),
                            productDetail: undefined,
                          }))
                          dispatch(openOverlayEditView());
                        }} aria-label="zoom-out">
                          <EditIcon style={{height: '12px', width: '12px'}}/>
                      </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  );
                continue;
              }
              const clothingMeasurements = userMeasurements.clothingMeasurements;
              result.push(
              <Box className={classes.containerStyle}>
                <Box className={classes.measurementMenuItem}
                key={creationDateTime}
              >
                <Grid container>
                  <Grid item xs={7} style={{display: 'flex', alignItems: 'center'}}>
                    <Grid container>
                      <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                        <Typography className={classes.measurementMenuItemDetailContentText}>
                          Dibuat {formatTimestampSize(creationDateTime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                        <Typography className={classes.measurementMenuItemMainText}>
                          {userMeasurements.measurementName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Grid container spacing={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Box
                          style={{
                            display: 'flex',
                            gap: '5px'
                          }}
                        >
                          <IconButton
                    className={classes.sizeOptionsBoxDetailIconButton}
                    disabled={!userMeasurements}
                    onClick={() => {
                      dispatch(closeOverlayAndResetEditView());
                      dispatch(setValuesEditView({
                        overlayType: 'view',
                        clothingType: userMeasurements.clothingType as ClothingType,
                        fitType: userMeasurements.fitType as FitType,
                        isFullScreen: false,
                        currentMeasurementObject: userMeasurements,
                        productDetail: undefined,
                      }))
                      dispatch(openOverlayEditView());
                    }}aria-label="zoom-out">
                      <Typography className={classes.sizeOptionsBoxDetailText}>
                        DETAIL SIZE ⓘ
                      </Typography>
                  </IconButton>
              
                  <IconButton
                    className={classes.sizeOptionsBoxEditIconButton}
                    disabled={!userMeasurements}
                    onClick={() => {
                      dispatch(closeOverlayAndResetEditView());
                      dispatch(setValuesEditView({
                        overlayType: 'edit',
                        clothingType: userMeasurements.clothingType as ClothingType,
                        fitType: userMeasurements.fitType as FitType,
                        isFullScreen: false,
                        currentMeasurementObject: userMeasurements,
                        productDetail: undefined,
                      }))
                      dispatch(openOverlayEditView());
                    }} aria-label="zoom-out">
                      <EditIcon style={{height: '12px', width: '12px'}}/>
                  </IconButton>
                        </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              </Box>
              )
            }
            return result;
          })
        }
      </Box>
  );
}
import {
  CircularProgress,
  createStyles,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useLocation, Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../reduxhooks";
import {
  availableCategories,
  fetchMailingPreference,
  getValidMBSValues,
  MailingCategoryKey,
  MessageBroadcastSettings,
  postMailingPreference,
} from "../redux/MailingPreferenceRedux";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import { FareastButton } from "./OverlayComponentButton";
import { ArrowBack } from "@material-ui/icons";
import { sendWebsiteLivestreamMessage } from "../telegrambotevents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: "20px",
      fontWeight: theme.typography.fontWeightBold as number,
    },
    titleContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    content: {
      width: "80%",
      marginTop: "50px",
    },
    formContainer: {
      border: "2px solid #ccc",
      borderRadius: "20px",
      padding: "10px 20px 20px 20px",
      marginBottom: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    categoryRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: "5%",
    },
    complaintRow: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "10px",
      alignItems: "center",
      width: "100%",
    },
    columnHeader: {
      fontWeight: "bold",
      fontSize: "10px",
      textAlign: "center",
    },
    categoryText: {
      fontWeight: 400,
      fontSize: "10px",
    },
    divider: {
      margin: "10px 0",
    },
    checkboxLabel: {
      marginRight: "0",
    },
    submitButton: {
      marginTop: "20px",
    },
    submitButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    checkBoxContainer: {
      flex: 1.5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    checkBoxContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    categoryContainer: {
      flex: 1.5,
    },
    titleImage: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      marginBottom: theme.spacing(3),
    },
    titleText: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
    },
    descriptionText: {
      fontSize: "12px",
    },
    dividerLine: {
      flex: 1,
    },
    dividerText: {
      padding: theme.spacing(0, 2),
    },
    // Dari https://mui.com/material-ui/react-switch/ ada template IOS
    switch: {
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor: "#34C759",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color: theme.palette.grey[100],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: "#E9E9EA",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    },
    dialog: {
      "& .MuiDialog-paper": {
        maxWidth: "280px",
        margin: 0,
        borderRadius: 20,
        overflow: "hidden",
        padding: "25px 25px 25px 25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  })
);

const categoriesNameMap: Record<MailingCategoryKey, string> = {
  "polo-shirt": "Polo Shirt",
  "basic-shirt": "Shirt",
  "sweater": "Sweater",
  "basic-tshirt": "T Shirt",
  "design-tshirt": "Design Shirt",
  "other-atasan": "Atasan Lainnya",
};

interface ConfirmationFormValues {
  complaintArray: number[];
  userSub: string | null;
}

// Masih temporary, belum tahu juga mau seperti apa complaintnya
const availableComplaints = [0, 1, 2, 3, 4, 5];

const complaintsMap: Record<number, string> = {
  0: "Terlalu sering menerima newsletter",
  1: "Konten newsletter membosankan",
  2: "Konten tidak relevan dengan saya",
  3: "Konten terlalu repetitif",
  4: "Saya tidak ingin menerima newsletter",
  5: "Lainnya",
};

const loadingTimeout = 2000;

const confirmationSchema = yup.object().shape({
  complaintArray: yup
    .array()
    .of(yup.number().required())
    .min(1)
    .required("Required"),
  userSub: yup.string().required("Required"),
});

const schema = yup.object().shape({
  StopAllMessaging: yup.boolean().required("Required"),
  CategorySkipMessagingDict: yup.object().required("Required"),
});

function PageMailingPreference() {
  const classes = useStyles();

  // Routing
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userSub = params.get("usersub");
  const dispatch = useAppDispatch();

  // State
  const messageBroadcastSettingsDraft: MessageBroadcastSettings =
    useAppSelector(
      (state) => state.mailingPreference.messageBroadcastSettingsDraft
    );

  const APIRequestStatus = useAppSelector(
    (state) => state.mailingPreference.getStatus
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Dialog
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean;
    values: MessageBroadcastSettings | null;
  }>({
    open: false,
    values: null,
  });

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialog({ open: false, values: null });
  };

  useEffect(() => {
    if (userSub) {
      dispatch(fetchMailingPreference(userSub));
    }
  }, []);

  // userSub tidak ada di query atau API request gagal / userSub tidak ditemukan di server
  if (!userSub || APIRequestStatus === 3) {
    return <Redirect to="/shop" />;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "50px 0px",
        maxWidth: "500px",
      }}
    >
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <img
            src={
              "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/stacked_email.png"
            }
            className={classes.titleImage}
          />
          <div
            style={{
              width: "100%",
            }}
          >
            <Typography variant="h5" className={classes.titleText}>
              Preferensi Subscribe Whatsapp
            </Typography>
            <Typography variant="body1" className={classes.descriptionText}>
              Atur jenis informasi yang ingin kamu dapatkan rutin melalui
              Whatsapp Studio S.
            </Typography>
          </div>
        </div>
        {
          (APIRequestStatus === 0 || APIRequestStatus === 1 || isLoading) ?
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}>
            <CircularProgress/>
          </div>
          :
          <>
        <div className={classes.formContainer}>
          <Formik
            initialValues={getValidMBSValues(messageBroadcastSettingsDraft)}
            validationSchema={schema}
            onSubmit={(values) => {
              console.log(values);
              if (values.StopAllMessaging) {
                setConfirmationDialog({ open: true, values: values });
              } else {
                dispatch(
                  postMailingPreference({
                    userSub: userSub,
                    messageBroadcastSettings: JSON.parse(JSON.stringify(values)),
                  })
                );
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false)
                }, loadingTimeout);
              }
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, resetForm }) => (
              <Form id="preference-form">
                <div className={classes.categoryRow}>
                  <Typography className={classes.categoryText}>
                    Kirimi Saya Promo
                  </Typography>
                  <Switch
                    className={classes.switch}
                    onChange={() => {
                      const stopAllMessagingNewValue = !values.StopAllMessaging;
                      setFieldValue("StopAllMessaging", stopAllMessagingNewValue);

                      if (stopAllMessagingNewValue) {
                        // If stop all messaging is true (user stops all messages),
                        // we set category skip to true for all
                        availableCategories.forEach((category) => {
                          setFieldValue(`CategorySkipMessagingDict.${category}`, true);
                        });
                      }

                      if (!stopAllMessagingNewValue) {
                        // If stop all messaging is false (user is given messages), 
                        // we reset to initial values IF the initial values have stopAllMessagingNewValue equal to false.
                        const newInitialValues = getValidMBSValues(messageBroadcastSettingsDraft);

                        if (newInitialValues.StopAllMessaging === false) {
                          availableCategories.forEach((category) => {
                            resetForm({ values: getValidMBSValues(messageBroadcastSettingsDraft) });
                          });
                        } else {
                          // Set all to be on / skip equal to false
                          availableCategories.forEach((category) => {
                            setFieldValue(`CategorySkipMessagingDict.${category}`, false);
                          });
                        }
                      }
                    }}
                    checked={!values.StopAllMessaging}
                  />
                </div>

                <Divider className={classes.divider} />

                {availableCategories.map((category) => (
                  <div key={category} className={classes.categoryRow}>
                    <Typography
                      className={classes.categoryText}
                      style={{
                        color: values.StopAllMessaging ? "grey" : "inherit",
                      }}
                    >
                      {categoriesNameMap[category]}
                    </Typography>
                    <Switch
                      className={classes.switch}
                      onChange={() => {
                        const categorySkipNewValue = !values.CategorySkipMessagingDict[category];
                        setFieldValue(`CategorySkipMessagingDict.${category}`, categorySkipNewValue);
                    
                        if (!categorySkipNewValue && values.StopAllMessaging) {
                          setFieldValue("StopAllMessaging", false);
                        }
                    
                        if (categorySkipNewValue) {
                          var stopAllMessaging = true;
                    
                          for (const key in values.CategorySkipMessagingDict) {
                            if (key === category) {
                              continue;
                            }
                            stopAllMessaging =
                              stopAllMessaging && values.CategorySkipMessagingDict[key as MailingCategoryKey];
                          }
                    
                          if (stopAllMessaging) {
                            setFieldValue("StopAllMessaging", true);
                          }
                        }
                      }}
                      checked={!values.CategorySkipMessagingDict[category]}
                      // disabled={values.StopAllMessaging}
                    />
                  </div>
                ))}
              </Form>
            )}
          </Formik>
        </div>
        <div className={classes.submitButtonContainer}>
          <FareastButton
            aria-label="simpan"
            type="submit"
            form="preference-form"
            buttonMarginLeftRight="0px"
            buttonMarginTopBottom="0px"
            style={{
              marginTop: "20px",
              marginBottom: "8px",
            }}
          >
            SIMPAN
          </FareastButton>
        </div>
          </>
        }
      </div>
      <Dialog
        className={classes.dialog}
        onClose={() => handleCloseConfirmationDialog()}
        open={confirmationDialog.open}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          <div>
            <IconButton
              edge="end"
              aria-label="back"
              color="primary"
              onClick={() => handleCloseConfirmationDialog()}
              style={{
                color: "#000",
                marginBottom: "10px",
                marginLeft: "-10px",
              }}
            >
              <ArrowBack />
            </IconButton>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div style={{ width: "100%" }}>
                <Typography
                  variant="h5"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Berhenti Subscribe Whatsapp
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "10px",
                    fontWeight: 400,
                  }}
                >
                  Ingin berhenti subscribe? kamu bisa subscribe kembali dan
                  mendapatkan promo khusus subscriber di kemudian hari.
                </Typography>
              </div>
            </div>
            <Formik<ConfirmationFormValues>
              initialValues={{ complaintArray: [], userSub: userSub }}
              onSubmit={(values) => {
                if (confirmationDialog.values) {
                  console.log(values);
                  console.log(confirmationDialog.values);
                  dispatch(
                    postMailingPreference({
                      userSub: userSub,
                      messageBroadcastSettings: JSON.parse(JSON.stringify(confirmationDialog.values)),
                    })
                  );
                  setIsLoading(true);
                  setTimeout(() => {
                    setIsLoading(false)
                  }, loadingTimeout);
                }
                sendWebsiteLivestreamMessage("[STOP MAILING LIST] ALASAN: " + JSON.stringify(values.complaintArray.map(value => complaintsMap[value])) + " (USER " + values.userSub + ")");
                handleCloseConfirmationDialog();
              }}
              validationSchema={confirmationSchema}
            >
              {({ values, isValid, dirty, errors, touched }) => (
                <Form id="confirmation-form">
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Beritahu kami kenapa anda berhenti subscribe
                  </Typography>
                  <FieldArray name="complaintArray">
                    {({ push, remove }) => (
                      <div>
                        {availableComplaints.map((value) => (
                          <div key={value} className={classes.complaintRow}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                    "& .MuiSvgIcon-root": { fontSize: 18 },
                                  }}
                                  checked={values.complaintArray.includes(
                                    value
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      push(value);
                                    } else {
                                      const idx =
                                        values.complaintArray.indexOf(value);
                                      if (idx !== -1) remove(idx);
                                    }
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "10px" }}
                                >
                                  {complaintsMap[value]}
                                </Typography>
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  {touched.complaintArray && errors.complaintArray && (
                    <Typography
                      color="error"
                      style={{ fontSize: "10px", marginTop: "5px" }}
                    >
                      {errors.complaintArray}
                    </Typography>
                  )}
                  <div className={classes.submitButtonContainer}>
                    <FareastButton
                      aria-label="simpan"
                      type="submit"
                      form="confirmation-form"
                      buttonMarginLeftRight="0px"
                      buttonMarginTopBottom="0px"
                      style={{ marginTop: "50px" }}
                      disabled={!isValid || !dirty}
                    >
                      SUBMIT
                    </FareastButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default PageMailingPreference;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import axios, { AxiosRequestConfig } from "axios";
import { RootState } from "../store";
import { ProductCategory } from "common-ts/dist/models/Product";

// MDN
// The nullish coalescing (??) operator is a logical operator
// that returns its right-hand side operand when its left-hand side operand is null or undefined,
// and otherwise returns its left-hand side operand.

// Takes in a message broadcast settings object (backwards compatible),
// and returns a valid MessageBroadcastSettings object for the current specification.
// This is strongly-typed, so it will always return a valid value 
// regardless of the input data completeness or "version".
export function getValidMBSValues(
  messageBroadcastSettingsDraft: any
) {
  const resultValues: MessageBroadcastSettings =  {
    StopAllMessaging: messageBroadcastSettingsDraft?.StopAllMessaging ?? false,
    CategorySkipMessagingDict: {
      "basic-tshirt":
        messageBroadcastSettingsDraft?.StopAllMessaging === true ? true :
        messageBroadcastSettingsDraft?.CategorySkipMessagingDict?.[
          "basic-tshirt"
        ] ?? false,
      "design-tshirt":
        messageBroadcastSettingsDraft?.StopAllMessaging === true ? true :
        messageBroadcastSettingsDraft?.CategorySkipMessagingDict?.[
          "design-tshirt"
        ] ?? false,
      "polo-shirt":
        messageBroadcastSettingsDraft?.StopAllMessaging === true ? true :
        messageBroadcastSettingsDraft?.CategorySkipMessagingDict?.[
          "polo-shirt"
        ] ?? false,
      "basic-shirt":
        messageBroadcastSettingsDraft?.StopAllMessaging === true ? true :
        messageBroadcastSettingsDraft?.CategorySkipMessagingDict?.[
          "basic-shirt"
        ] ?? false,
      "sweater":
        messageBroadcastSettingsDraft?.StopAllMessaging === true ? true :
        messageBroadcastSettingsDraft?.CategorySkipMessagingDict?.[
          "sweater"
        ] ?? false,
      "other-atasan":
        messageBroadcastSettingsDraft?.StopAllMessaging === true ? true :
        messageBroadcastSettingsDraft?.CategorySkipMessagingDict?.[
          "other-atasan"
        ] ?? false,
    },
  };

  return resultValues;
}

// Sementara di sini dulu sebelum ditaruh di common-ts (kalau mau)
export type MailingCategoryKey = 'polo-shirt' | 'basic-shirt' | 'sweater' | 'basic-tshirt' | 'design-tshirt' | 'other-atasan';
export const availableCategories: MailingCategoryKey[] = [
  "polo-shirt",
  "basic-shirt",
  "sweater",
  "basic-tshirt",
  "design-tshirt",
  "other-atasan",
];

export interface MessageBroadcastSettings {
  StopAllMessaging: boolean;
  CategorySkipMessagingDict: Record<MailingCategoryKey, boolean>;
}

interface mailingListPreference {
  success: boolean;
  paidOrderCount: number;
  messageBroadcastSettingsDraft: MessageBroadcastSettings;

  getStatus: APIRequestStatus;
  postStatus: APIRequestStatus;
}

// Initial State
// Untuk CreateSlice => Daftar review UNTUK SATU kategori DAN draft review baru
const initialState: mailingListPreference = {
  success: false,
  paidOrderCount: 0,

  messageBroadcastSettingsDraft: {
    StopAllMessaging: false,
    CategorySkipMessagingDict: {
      "polo-shirt": false,
      "basic-shirt": false,
      "sweater": false,
      "basic-tshirt": false,
      "design-tshirt": false,
      "other-atasan": false
    },
  },

  getStatus: APIRequestStatus.Idle,
  postStatus: APIRequestStatus.Idle,
};

// FUNGSI => API CALL - GET
export const fetchMailingPreference = createAsyncThunk(
  "mailingPreference/fetchMailingPreference",
  async (payload: string, thunkAPI) => {
    const response = await axios({
      method: "get",
      url: "https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/mail-pref",
      params: {
        usersub: payload,
      },
    });

    return response.data;
  }
);

export const postMailingPreference = createAsyncThunk(
  "mailingPreference/postMailingPreference", 
  async (payload: {userSub: string, messageBroadcastSettings: MessageBroadcastSettings}, thunkAPI) => {

    const data = JSON.stringify(
      {userSub: payload.userSub, messageBroadcastSettings: payload.messageBroadcastSettings}
    );

    console.log('To be sent: ' + data)

    const response = await axios({
      method: 'post',
      url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/mail-pref',
      data: data
    });

    thunkAPI.dispatch(fetchMailingPreference(payload.userSub));

    return response.data;
  });

export const mailingPreferenceSlice = createSlice({
  name: "mailingPreference",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMailingPreference.pending, (state, action) => {
        // Ganti status GET di state => BERLANGSUNG
        state.getStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchMailingPreference.fulfilled, (state, action) => {
        if (action?.payload) {
          let success: boolean = action.payload.success;
          let paidOrderCount: number = action.payload.paidOrderCount;
          let messageBroadcastSettings: MessageBroadcastSettings = getValidMBSValues(action.payload.messageBroadcastSettings);

          console.log('API Success: ' + JSON.stringify(messageBroadcastSettings));

          if (!success) {
            state.getStatus = APIRequestStatus.Failure;
          } else {
            state.paidOrderCount = paidOrderCount;
            state.messageBroadcastSettingsDraft = messageBroadcastSettings;
            state.getStatus = APIRequestStatus.Success;
          }
        }
      })
      .addCase(fetchMailingPreference.rejected, (state, action) => {
        // Ganti status GET di state => GAGAL
        state.getStatus = APIRequestStatus.Failure;
        console.log("Category Review List Fetch [GET] Failed");
      });
  },
});

// Action creators are generated for each case reducer function

export default mailingPreferenceSlice.reducer;

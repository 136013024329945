import { Box } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { closeResultRatingOverlay } from '../redux/UIOverlayMeasurementCreateViewRedux';
import { useAppDispatch } from '../reduxhooks';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import OverlayViewRating from './OverlayViewMeasurementCreateNewRatingsBlocks1Rating';
import OverlayViewComment from './OverlayViewMeasurementCreateNewRatingsBlocks2Comment';
import OverlayViewEndThankYou from './OverlayViewMeasurementCreateNewRatingsBlocks3EndThankYou';
import OverlayViewEndAskInterview from './OverlayViewMeasurementCreateNewRatingsBlocks4EndAskInterview';

// State data for flow
export interface FlowStateData {
  currentScreen: string
}

// Callback to change state data for flow.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: string) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentRatingsBlocks(flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentResultsRatings(flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentResultsRatings(flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  switch (flowStateData.currentScreen) {
    case "rating": // 1. Algorithm or 2. Regular
      children.push(<OverlayViewRating
        handleSubmit={(ratingValue: number) => {
          if ((window as any).fbq !== null) { 
            (window as any).fbq('trackCustom', 'RatingCustomSize', {
              rating: ratingValue
            });
          }
          
          Mixpanel.track("customSizeResultsRating", {
            ratingValue: ratingValue
          })
          sendWebsiteEventMessage("User menilai results custom fit dengan rating " + ratingValue);
          
          // delayedDispatch("endThankYou"); WE DIRECTLY EXIT
          dispatch(closeResultRatingOverlay());
        }}
        />);
      break;
    case "endThankYou":
      children.push(<OverlayViewEndThankYou
        handleSubmit={() => {
          dispatch(closeResultRatingOverlay());
        }}
        />);
      break;
  }

  return children;
}
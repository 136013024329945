export var SizeType;
(function (SizeType) {
    SizeType["personal"] = "personal";
    SizeType["regular"] = "regular";
})(SizeType || (SizeType = {}));
export var RegularSizeValueShortName = {
    "NOTSELECTED": "",
    "2X-Small (2XS)": "XXS",
    "X-Small (XS)": "XS",
    "Small (S)": "S",
    "Medium (M)": "M",
    "Large (L)": "L",
    "X-Large (XL)": "XL",
    "2X-Large (2XL)": "XXL",
    "3X-Large (3XL)": "3XL",
    "4X-Large (4XL)": "4XL",
    "5X-Large (5XL)": "5XL"
};
export var RegularSizeValue;
(function (RegularSizeValue) {
    RegularSizeValue["NOTSELECTED"] = "NOTSELECTED";
    RegularSizeValue["XXS"] = "2X-Small (2XS)";
    RegularSizeValue["XS"] = "X-Small (XS)";
    RegularSizeValue["S"] = "Small (S)";
    RegularSizeValue["M"] = "Medium (M)";
    RegularSizeValue["L"] = "Large (L)";
    RegularSizeValue["XL"] = "X-Large (XL)";
    RegularSizeValue["XXL"] = "2X-Large (2XL)";
    RegularSizeValue["XXXL"] = "3X-Large (3XL)";
    RegularSizeValue["XXXXL"] = "4X-Large (4XL)";
    RegularSizeValue["XXXXXL"] = "5X-Large (5XL)";
})(RegularSizeValue || (RegularSizeValue = {}));

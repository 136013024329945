import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography } from '@material-ui/core';

interface StyleProps {
  buttonMarginTopBottom: string
  buttonMarginLeftRight: string
}

const useStyles = ({buttonMarginTopBottom, buttonMarginLeftRight}: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  button: {
    marginTop: buttonMarginTopBottom,
    marginBottom: buttonMarginTopBottom,
    marginLeft: buttonMarginLeftRight,
    marginRight: buttonMarginLeftRight,
    fontWeight: theme.typography.fontWeightMedium as number,
    color: 'white',
    backgroundColor: '#121212',
    borderRadius: '30px',
    padding: '12px 20px 8px 20px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'grey',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    }
  },
  buttonWhite: {
    marginTop: buttonMarginTopBottom,
    marginBottom: buttonMarginTopBottom,
    marginLeft: buttonMarginLeftRight,
    marginRight: buttonMarginLeftRight,
    fontWeight: theme.typography.fontWeightMedium as number,
    color: '#343434',
    backgroundColor: '#ffffff',
    borderRadius: '30px',
    padding: '12px 20px 8px 20px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    }
  },
  buttonGrey: {
    marginTop: buttonMarginTopBottom,
    marginBottom: buttonMarginTopBottom,
    marginLeft: buttonMarginLeftRight,
    marginRight: buttonMarginLeftRight,
    fontWeight: theme.typography.fontWeightMedium as number,
    color: 'black',
    backgroundColor: '#EAEAEA',
    borderRadius: '30px',
    padding: '12px 20px 8px 20px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'grey',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    }
  },
  buttonText: {
    marginTop: buttonMarginTopBottom,
    marginBottom: buttonMarginTopBottom,
    marginLeft: buttonMarginLeftRight,
    marginRight: buttonMarginLeftRight,
    fontWeight: theme.typography.fontWeightMedium as number,
    borderRadius: '0',
    fontSize: '14px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    }
  },
}),
);

interface ButtonProps {
  buttonMarginTopBottom: string
  buttonMarginLeftRight: string
  [x: string]: any
}

export function FareastButton({ buttonMarginTopBottom, buttonMarginLeftRight, ...allProps }: ButtonProps) {
  const classes = useStyles({
    buttonMarginTopBottom: buttonMarginTopBottom, 
    buttonMarginLeftRight: buttonMarginLeftRight,
  })();

  return (
    <Button variant="contained" className={classes.button} {...allProps}/>
  );
}

export function FareastButtonWhite({ buttonMarginTopBottom, buttonMarginLeftRight, ...allProps }: ButtonProps) {
  const classes = useStyles({
    buttonMarginTopBottom: buttonMarginTopBottom, 
    buttonMarginLeftRight: buttonMarginLeftRight,
  })();

  return (
    <Button variant="contained" className={classes.buttonWhite} {...allProps}/>
  );
}

interface GreyButtonProps {
  buttonMarginTopBottom: string
  buttonMarginLeftRight: string
  [x: string]: any
}

export function FareastButtonGrey({ buttonMarginTopBottom, buttonMarginLeftRight, ...allProps }: GreyButtonProps) {
  const classes = useStyles({
    buttonMarginTopBottom: buttonMarginTopBottom, 
    buttonMarginLeftRight: buttonMarginLeftRight,
  })();

  return (
    <Button variant="contained" className={classes.buttonGrey} {...allProps}/>
  );
}

interface TextButtonProps {
  buttonMarginTopBottom: string
  buttonMarginLeftRight: string
  [x: string]: any
}

export function FareastButtonText({ buttonMarginTopBottom, buttonMarginLeftRight, ...allProps }: GreyButtonProps) {
  const classes = useStyles({
    buttonMarginTopBottom: buttonMarginTopBottom, 
    buttonMarginLeftRight: buttonMarginLeftRight,
  })();

  return (
    <Button variant="text" className={classes.buttonText} {...allProps}/>
  );
}

interface Props {
  formButtonText: string
  isTypeSubmit: boolean
  handleClick?: () => void
  buttonMarginTopBottom: string
  buttonMarginLeftRight: string
  disabled?: boolean
}

export default function OverlayComponentButton({ formButtonText, isTypeSubmit, handleClick, 
  buttonMarginTopBottom, buttonMarginLeftRight, disabled }: Props) {
  const classes = useStyles({buttonMarginTopBottom, buttonMarginLeftRight})();

  if (isTypeSubmit) {
    return (
      <Button variant="contained" className={classes.button} type="submit" disabled={disabled}>
        {formButtonText}
      </Button>
    );
  } else {
    return (
      <Button variant="contained" className={classes.button} onClick={handleClick} disabled={disabled} >
        {formButtonText}
      </Button>
    );
  }
}
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import OptionsSlider from "./PageComponentOptionsSliderInfinite";
import ReviewItem from "./PageComponentReviewItem";
import { CategoryReview } from "common-ts/dist/models/CategoryReview";


interface ReviewSliderProps {
  reviews: CategoryReview[];
  onClickContent: () => void;
  onClickImage: (reviewIndex: number, imageIndex: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reviewItemSliderContainer: {
      marginTop: "40px",
    },
    previewItemSpacer: {
      margin: "0",
      flex: "0 0 auto",
      width: "20px",
    },
    reviewItem: {
      marginTop: "-20px",
      marginRight: theme.spacing(1.5),
      boxSizing: "border-box",
      flexGrow: 0,
      alignItems: "stretch",
      width: "18vw",
      minHeight: "calc(14vw * 0.95)",
      flexBasis: "18vw",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "65vw",
        minHeight: "calc(65vw * 0.95)",
        flexBasis: "65vw",
      },
    },
    loadMoreTrigger: {
      height: "20px",
      width: "100%",
    },
  })
);

const REVIEWS_PER_PAGE = 20;

const ReviewSlider: React.FC<ReviewSliderProps> = ({
  reviews,
  onClickContent,
  onClickImage,
}) => {
  const classes = useStyles();
  const [displayedReviews, setDisplayedReviews] = useState<CategoryReview[]>([]);
  const [page, setPage] = useState(0);
  const loadMoreTriggerRef = useRef(null);

  const loadMoreReviews = () => {
    const startIndex = page * REVIEWS_PER_PAGE;
    const endIndex = startIndex + REVIEWS_PER_PAGE;
    const newReviews = reviews.slice(startIndex, endIndex);
    setDisplayedReviews(prev => [...prev, ...newReviews]);
    setPage(prev => prev + 1);
  };

  useEffect(() => {
    loadMoreReviews();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && displayedReviews.length < reviews.length) {
          loadMoreReviews();
        }
      },
      { threshold: 1.0 }
    );

    if (loadMoreTriggerRef.current) {
      observer.observe(loadMoreTriggerRef.current);
    }

    return () => observer.disconnect();
  }, [displayedReviews, reviews]);

  return (
    <Box className={classes.reviewItemSliderContainer}>
      <OptionsSlider
        title={[]}
        marginDesktop="0px 0px 0px 0px"
        marginMobile="0px 0px 0px 0px"
        contents={[
          <Box className={classes.previewItemSpacer} key="spacer" />,
          ...displayedReviews.map((review, index) => (
            <Box className={classes.reviewItem} key={index}>
              <ReviewItem
                {...review}
                onClickContent={onClickContent}
                onClickImage={(imageIndex: number) =>
                  onClickImage(index, imageIndex)
                }
              />
            </Box>
          )),
        ]}
      />
      <div ref={loadMoreTriggerRef} className={classes.loadMoreTrigger} />
    </Box>
  );
};

export default ReviewSlider;
import { Box, Dialog, Divider, Fade, IconButton, Link, Slide } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Bantuan, BantuanSpacer } from '../App';
import { Mixpanel } from '../mixpanel';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputBodyMeasurementsForm, inputMeasurementsForm, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import { UniversalSizeActiveScreen, closeOverlayAndResetWithCleanup, updateCurrentScreen } from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FlowComponentMaster } from './OverlayViewUniversalSizeFlowBlocks';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 2) + " !important") as any,
    },
    dialogContentContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start', 
    },
    dialogContentLeftContainer: {
      width: '46%',
      paddingLeft: '29%',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: '0%',
        // paddingTop: '20px',
        width: '100%',
        justifyContent: 'center',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleLogoContainer: {
      width: "100%",
      position: "relative",
      paddingTop: '2.5vw',
      paddingBottom: '6vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '5vw',
        paddingBottom: '7vw',
        height: '10vw',
      },
      cursor: 'pointer',
    },
    titleLogoDesktop: {
      paddingTop: '1.5vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '3vw',
        height: '10vw',
      }
    },
    titleLogoClose: {
      position: "absolute",
      top: "3vw",
      right: "3vw",
      color: "#000000",
    },
    signInsignUpToggleText: {
      fontSize: '15px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px',
      }

    },
    horizontalBarDivider: {
      width: '100%',
      margin: '10px 0px 6px 0px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      }
    },
    horizontalContent: {
      width: '100%',
    }
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayUniversalSize);
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const userSub = useAppSelector(state => state.account.sub);
  const firstName = useAppSelector(state => state.account.name?.split(' ')?.[0]);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [childrenVisible, setChildrenVisible] = React.useState<boolean>(true);
  const [titleNumber, setTitleNumber] = React.useState<number>(-1);

  const currentUserMeasurement = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementDraft);

  function delayedDispatch(currentScreen: UniversalSizeActiveScreen) {
    setChildrenVisible(false);
    setTimeout(() => {
      dispatch(updateCurrentScreen(currentScreen));
      setChildrenVisible(true);
    }, 1000)
  }

  function closeOverlayAndResetUserMeasurementDraft() {
    dispatch(closeOverlayAndResetWithCleanup());
    Mixpanel.track('sizeCreateExit', {
      'signedIn': isSignedIn ? 'true' : 'false',
    });
  }

  if (currentUserMeasurement.creationDateTime === undefined) {
    const creationDateTime = new Date().toISOString();
    
    var objToday = new Date(),
      dayOfMonth = objToday.getDate(),
      months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      curMonth = months[objToday.getMonth()],
      curYear = objToday.getFullYear(),
      curHour = objToday.getHours(),
      curMinute = objToday.getMinutes(),
      curSeconds = objToday.getSeconds() < 10 ? "0" + objToday.getSeconds() : objToday.getSeconds(),
      curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

    var measurementName = curMonth + " " + dayOfMonth + " " 
      + curYear + " " + curHour + ":" + curMinute + " - Measurement";
    
    const payload: UserClothTypeSpecificBodyMeasurements = {
      creationDateTime: creationDateTime,
      updateDateTime: creationDateTime,
      measurementName: measurementName,
      nama: firstName ? firstName : 'Kamu',
    }
    dispatch(inputMeasurementsForm(payload));
    
    const payloadBody: UserBodyMeasurements = {
      creationDateTime: creationDateTime,
      updateDateTime: creationDateTime,
      measurementName: measurementName,
      nama: firstName ? firstName : 'Kamu',
    }
    dispatch(inputBodyMeasurementsForm(payloadBody));

    return null;
  }

  let content: JSX.Element = 
  FlowComponentMaster({
    isSignedIn: isSignedIn,
  }, {
    currentScreen: overlayView.currentScreen,
    titleNumber: titleNumber,
    currentUserBodyMeasurements: currentUserMeasurement,
    userSub: userSub,
  }, {
    delayedDispatch: delayedDispatch,
    setTitleNumber: (titleNumber) => { setTitleNumber(titleNumber); },
  });

  return (
    <Dialog
    fullScreen
    maxWidth="lg"
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    onClose={closeOverlayAndResetUserMeasurementDraft}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <div className={classes.dialogContentLeftContainer}>
          <Box className={classes.horizontalContent}>
            <Fade in={overlayView.status === UIOverlayViewStatus.On && childrenVisible} timeout={400}>
              {content}
            </Fade>
          </Box>
        </div>
      </div>
    </Dialog>
  );
}
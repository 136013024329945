import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import React, { useEffect } from 'react';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { signUp, updateState } from '../redux/AccountRedux';
import { Mixpanel } from '../mixpanel';
import axios from 'axios';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import {v4 as uuidv4} from 'uuid';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      }
    },
    formContainer: {
      width: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      },
      // height: '340px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '50px',
        paddingBottom: '50px',
      },
    },
    form: {
    },
    formRow: {
    },
    subFormTitle: {
      marginBottom: '12px',
    },
    errorMessageText: {
      color: '#2e2e2e',
      backgroundColor: 'lightgrey',
      paddingLeft: '10px',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginTop: '14px',
      marginBottom: '4px',
      fontSize: '13px',
      fontWeight: 500,
    },
    formButton: {
      marginTop: '30px',
    }
  }),
);

const validationSchema = yup.object({
  name: yup.string()
    .min(4, 'Your name should be of minimum 4 characters length')
    .required('Your name is required'),
  email: yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string()
    .required('Password is required')
    .min(4, 'Password should be of minimum 4 characters length'),
    // .matches(/^(?=.*[0-9])/, 'Your password must contain at least one number')
    // .matches(/^(?=.*[@$!%*#?&.])/, 'Your password must contain at least one allowed special character (@, $, !, %, *, #, ?, & or .)')
    // .matches(/^(?=.*[a-z])/, 'Your password must contain at least one lowercase character')
    // .matches(/^(?=.*[A-Z])/, 'Your password must contain at least one uppercase character'),
  phone_number: yup.string()
    .min(6, 'Phone number should be of minimum 6 characters length')
    .required('Phone number is required'),
});

export default function PageAccountSignUp(props: { changeToSignInWithPhoneNumber: (phoneNumber: string) => void }) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const errorMessage = useAppSelector(state => state.account.signUpError);
  const email = useAppSelector(state => state.account.email);
  const phoneNumber = useAppSelector(state => state.account.phoneNumber);

  const isBeingSubmitted = useAppSelector(state => state.account.statusSignUp === APIRequestStatus.RequestInProgress);

  let phoneNumberWithoutPrefix = phoneNumber;
  if (phoneNumber.substring(0, 3) === "+62") {
    phoneNumberWithoutPrefix = phoneNumberWithoutPrefix.substring(3);
  }

  // Pure UI State for this component only, so this is good.
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  async function signUpAttempt(name: string, email: string, password: string, 
    phone_number: string) {
    try {
      dispatch(updateState({
        name: name,
        email: email,
        phoneNumber: phone_number
      }));
      
      dispatch(signUp({
        password: password,
      }));

      sendWebsiteEventMessage( "Ada yang coba sign up: " + JSON.stringify({
        name: name,
        email: email,
        phoneNumber: phone_number,
        password: password
      }));

    } catch (error) {
      sendWebsiteEventMessage(JSON.stringify(error));
      console.log('error signing up:', error);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      phone_number: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateState({
        signInError: '',
        signUpError: '',
      }));
      Mixpanel.track('signUpAttempt', {
        'source': 'sizeCreateNewLastStep',
      });
      signUpAttempt(values.name, values.email, values.password, '+62' + values.phone_number);
    },
  });

  useEffect(() => {
    console.log("Set formik sign up values", email, phoneNumberWithoutPrefix);
    formik.setValues({
      name: '',
      email: email,
      password: '',
      phone_number: phoneNumberWithoutPrefix
    })
    dispatch(updateState({
      signUpError: '',
    }));
  }, [])

  if (errorMessage === "Nomor sudah dipakai.") {
    // We redirect to sign in
    sendWebsiteEventMessage("Nomor sign up sudah dipakai. Redirect ke sign in.");
    props.changeToSignInWithPhoneNumber(phoneNumber);
  }

  return (
    <Box className={classes.formContainer}>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography variant="h5" className={classes.subFormTitle} color="textPrimary">
          <b>Buat Akun Baru</b>
        </Typography>
        {
          errorMessage === "" ?
          null :
          <Typography variant="body2" className={classes.errorMessageText}>
            {errorMessage}
          </Typography>
        }
        <div className={classes.formRow}>
          <TextField
            margin="normal"
            className={classes.textField}
            id="name"
            name="name"
            label="Name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </div>
        <div className={classes.formRow}>
          <TextField
            margin="normal"
            className={classes.textField}
            id="email"
            name="email"
            label="Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className={classes.formRow}>
          <TextField
            margin="normal"
            className={classes.textField}
            id="phone_number"
            name="phone_number"
            label="Phone Number"
            type="number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
            helperText={formik.touched.phone_number && formik.errors.phone_number}
            InputProps={{
              startAdornment: <InputAdornment position="start">+62</InputAdornment>,
            }}
          />
        </div>
        <div className={classes.formRow}>
          <TextField
            margin="normal"
            className={classes.textField}
            id="password"
            name="password"
            label="Password"
            type={values.showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
        </div>
        <Button variant="contained" type="submit" className={classes.formButton} fullWidth disabled={isBeingSubmitted}>
          <b>Submit</b>
        </Button>
      </form>
    </Box>
  );
}
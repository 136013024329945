import { Box, Dialog, Fade, Link, Slide, DialogTitle, Typography, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputMeasurementsForm, postClothingMeasurement, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset } from '../redux/UIOverlayMeasurementEditViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FlowComponentMasterPersonalSize } from './OverlayViewMeasurementClothingEditBlocks';
import { Close } from '@material-ui/icons';
import { getSortKey } from './OverlayViewPersonalSize';

interface stylesProps {
  isFullScreen: boolean
}

const useStyles = ({ isFullScreen }: stylesProps) =>  makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 2) + " !important") as any, // LEVEL 2 IN Z INDEX
      '& .MuiPaper-root': {
        borderRadius: isFullScreen ? '0px' : '30px',
        margin: isFullScreen ? '0px' : '0px 16px',
        maxHeight: isFullScreen ? '' : 'calc(100% - 16px)',
        maxWidth: isFullScreen ? '' : '380px',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    dialogContentContainer: {
      overflowX: 'hidden',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      }
    },
    toggleSignUpSignInContainer: {
      paddingTop: '15px',
      paddingBottom: '30px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    titleLogoContainer: {
      paddingTop: '30px',
      paddingBottom: '20px',
      height: '42px',
    },
    titleLogoDesktop: {
      height: '24px',
    },
    popupTitle: {
      padding: '0.5em 0.5em 0 0.5em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    popupTitlePilihUkuranText: {
      fontSize: '12px',
      color: '#D1D1D1',
      fontWeight: 300,
      padding: '16px 0px 0px 18px'
    },
    popupCloseButton: {
      marginRight: '3px',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMeasurementEditView);
  const dispatch = useAppDispatch();
  const classes = useStyles({ isFullScreen: overlayView.isFullScreen })();

  const currentUserMeasurement = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementDraft);

  const [currentScreen, setCurrentScreen] = React.useState<string>("editingScreen");
  const [childrenVisible, setChildrenVisible] = React.useState<boolean>(true);
  const [titleNumber, setTitleNumber] = React.useState<number>(-1);

  const productsDictionary = useAppSelector(state => state.productsList.productsDictionary);
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  
  const clothingType = overlayView.clothingType;
  const fitType = overlayView.fitType;

  let displayImagesArray: string[] = [];

  if (productsDictionary) {
    const startingProductIds = Object.keys(productsDictionary)
    .sort((a, b) => getSortKey(productsDictionary[a], productSizeAvailabilityDict?.[a]) > getSortKey(productsDictionary[b], productSizeAvailabilityDict?.[b]) ? 1 : -1)

    const clothingTypeCurrent = clothingType;
    const fitTypeCurrent = fitType;
    const filteredArray = startingProductIds.filter((productId) => productsDictionary[productId].clothingType === clothingTypeCurrent && productsDictionary[productId].defaultFitType === fitTypeCurrent);
    displayImagesArray = filteredArray.map((productId) => productsDictionary[productId].displayImage);
  }

  function delayedDispatch(currentScreenValue: string) {
    setChildrenVisible(false);
    setTimeout(() => {
      setCurrentScreen(currentScreenValue);
      setChildrenVisible(true);
    }, 1000)
  }

  function closeOverlayAndResetUserMeasurementDraft() {
    dispatch(closeOverlayAndReset());
    dispatch(resetUserMeasurementDraft());
    delayedDispatch("editingScreen");
  }

  let content: JSX.Element = 
  FlowComponentMasterPersonalSize({
    clothingType: overlayView.clothingType,
    fitType: overlayView.fitType,
    sourceUserMeasurement: overlayView.currentMeasurementObject,
    editedSourceUserMeasurement: overlayView.measurementObjectDraft,
    productDetail: overlayView.productDetail,
    useDialog: !overlayView.isFullScreen,
    origin: overlayView.origin,
    displayImagesArray: displayImagesArray
  }, {
    currentScreen: currentScreen,
    titleNumber: titleNumber,
    currentUserMeasurement: currentUserMeasurement,
  }, {
    delayedDispatch: delayedDispatch,
    setTitleNumber: (titleNumber) => { setTitleNumber(titleNumber); },
  });

  return (
    <Dialog
    maxWidth="lg"
    fullScreen={overlayView.isFullScreen}
    open={overlayView.status === UIOverlayViewStatus.On && overlayView.currentMeasurementObject !== undefined && overlayView.overlayType === 'edit'}
    TransitionComponent={Transition}
    keepMounted
    onClose={closeOverlayAndResetUserMeasurementDraft}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <DialogTitle style={{ padding: '0px 0px' }}>
        <Box className={classes.popupTitle}>
          <Typography className={classes.popupTitlePilihUkuranText}>
            {overlayView.origin === 'qr' ? 'UBAH UKURAN PRODUK' : 'DETAIL UKURAN'}
          </Typography>
          <IconButton
            className={classes.popupCloseButton}
            edge="end"
            color="inherit"
            onClick={closeOverlayAndResetUserMeasurementDraft}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <div className={classes.dialogContentContainer}>
        <Fade in={childrenVisible} timeout={800} style={{
          width: '90%'
        }}>
          {content}
        </Fade>
      </div>
    </Dialog>
  )
}
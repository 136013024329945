import { Box, Divider, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import CartContentItem from './CartContentItem';
import { checkIfMeasKitSizeExists, getDiscountedPriceAmount, getDiscountedPriceAmountWithPromotions, getDiscountedPriceAmountWithPromotionsAndShipment, getEndShipmentPrice, getNumberOfItems, getOriginalPriceAmount, getShipmentPriceSubsidyAmount, submitCouponRequest } from '../redux/CartRedux';
import { CartStateItem, CartState } from 'common-ts/dist/models/Cart';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { ProductsList } from 'common-ts/dist/models/Product';
import { useFormik } from 'formik';
import * as yup from 'yup';
import OverlayComponentForm from './OverlayComponentForm';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import { Mixpanel } from '../mixpanel';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { OngkirBanner } from './OverlayViewPopupOngkir';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartContent: {
      display: 'block',
      position: 'relative',
      textAlign: 'center',
    },
    totalAmount: {
      padding: '5px 0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    dividerItemsTotal: {
      marginTop: '40px',
      marginBottom: '12px',
    },
    dividerCartTotal: {
      marginTop: '12px',
      marginBottom: '12px',
    },
    priceText: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
      }
    },
    priceStrikeText: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      textDecoration: 'line-through',
      marginRight: '10px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
      }
    },
    shipmentText: {
      fontSize: '15px',
    },
    discountLabelText: {
      color: 'green',
    },
    discountKeyText: {
      fontSize: '15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
      }
    },
    discountPriceText: {
      color: 'green',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
      }
    },
    potonganOngkir: {
      padding: '5px 0px 0px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      color: "#09B038"
    },
    potonganOngkirSubtextBox: {
      padding: '2px 0px 5px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      color: "#09B038"
    },
    potonganOngkirTextIcon: {
      padding: "0 5px"
    },
    potonganOngkirText: {
      fontSize: "13px",
      fontWeight: 600,
      letterSpacing: "0.7px",
    },
    potonganOngkirSubtext: {
      fontSize: "10px",
      fontWeight: 400,
      letterSpacing: "0.4px",
    }
  }),
);

const validationSchema = yup.object({
  contentKey: yup.string()
  .min(5, '')
  .required('Masukkan kode promo kamu disini'),
});

export function CartCouponInput() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));

  const formik = useFormik({
    initialValues: {
      contentKey: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Mixpanel.track('couponKeyInput', {
        'couponKey': values.contentKey,
      })
      dispatch(submitCouponRequest(values.contentKey));
    },
  });

  let children: JSX.Element[] = [];
  
  children.push(
    <TextField
      margin="dense"
      fullWidth
      id="contentKey"
      name="contentKey"
      label="Kode promo"
      type="text"
      value={formik.values.contentKey}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={false}
      helperText={(formik.touched.contentKey && formik.errors.contentKey)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <OverlayComponentButton
      isTypeSubmit={true}
      formButtonText="Gunakan Kode"
      buttonMarginTopBottom="10px"
      buttonMarginLeftRight="0px"
    />
  )

  return (
    <OverlayComponentForm
      formTitle=""
      formSubmitBody={submitChildren}
      formFormBody={children}
      formHandleSubmit={formik.handleSubmit}
      titleWidthMdUp='400px'
      titleWidthSmDown='300px'
      formWidthMdUp='420px'
      formWidthSmDown='100%'
      formJustifyContent='space-evenly'
      formAlignItems='flex-start'
    />
  )
}

interface CartContentProps {
  cart: CartState,
  productsList: ProductsList,
  isModifiable: boolean
  withPromoField?: boolean
}

function CartContent({ cart, productsList, isModifiable, withPromoField }: CartContentProps) {
  const userBodyMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const [lastRenderTime, setLastRenderTime] = React.useState<string>(new Date().toISOString());

  const cartProductList = cart.cartProductList;
  const cartShipmentPrice = cart.shipmentPrice;
  const classes = useStyles();

  const thereIsMeasKitSize = checkIfMeasKitSizeExists(cart, userBodyMeasurementsDict);
  // if (thereIsMeasKitSize
  // && (cart.couponKey === "" || cart.coupon === undefined)
  // && cart.couponFetchStatus !== APIRequestStatus.RequestInProgress) {
  //   Mixpanel.track('couponKeyInput', {
  //     'couponKey': "CUSTOM-SIZE-EDIT-DISCOUNT",
  //   });
  //   dispatch(submitCouponRequest("CUSTOM-SIZE-EDIT-DISCOUNT"));
  // }

  const originalPriceAmount = getOriginalPriceAmount(cart, productsList);
  const discountedPriceAmount = getDiscountedPriceAmount(cart, productsList);
  const discountedPriceAmountWithPromotions = getDiscountedPriceAmountWithPromotions(cart, productsList, userBodyMeasurementsDict);
  const discountedPriceAmountWithPromotionsAndShipment = getDiscountedPriceAmountWithPromotionsAndShipment(cart, productsList, userBodyMeasurementsDict);
  
  const numberOfItemsInCart = getNumberOfItems(cart);
  const shipmentMaxPriceCut = getShipmentPriceSubsidyAmount(numberOfItemsInCart);
  const endCartShipmentPrice = getEndShipmentPrice(discountedPriceAmountWithPromotions, getNumberOfItems(cart), cart.shipmentPrice) as number;

  return (
    <div className={classes.cartContent}>
      <div>
        {
          cartProductList.map((item: CartStateItem) => 
          <CartContentItem cartItem={item} productsList={productsList} 
          isModifiable={isModifiable} parentRenderCallback={(renderTime) => {
            setLastRenderTime(renderTime);
          }}/>)
        }
      </div>
      <Divider className={classes.dividerItemsTotal} />
      {
        withPromoField === false ? 
        null :
        <CartCouponInput/>
      }
      {
        withPromoField === false ? 
        null :
        <OngkirBanner/>
      }
      {
        withPromoField === false ? 
        null :
        <Divider className={classes.dividerCartTotal} />
      }
      {
        cart.couponKey ? 
        <div className={classes.totalAmount}>
          <Typography variant="body1">
            <b>
              PROMO CODE:
            </b> 
          </Typography>
          <Typography className={classes.discountKeyText}>
            {cart.couponKey}
          </Typography>
        </div> : 
        null
      }
      <div className={classes.totalAmount}>
        <Typography variant="body1">
          <b>
            ITEM(S) SUBTOTAL:
          </b> 
        </Typography>
        <Typography className={classes.priceText}>
          {/* <span className={classes.priceStrikeText}>
            { 
            originalPriceAmount !== discountedPriceAmount ?
            "Rp " + originalPriceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
            ""
            }
          </span> */}
          Rp { discountedPriceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
        </Typography>
      </div>
      {
        cart.couponKey ? 
        <div className={classes.totalAmount}>
          <Typography variant="body1" className={classes.discountLabelText}>
            <b>
              PROMOTIONS:
            </b> 
          </Typography>
          <Typography className={classes.discountPriceText}>
            <b>-</b> Rp {(discountedPriceAmount - discountedPriceAmountWithPromotions).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Typography>
        </div> : 
        null
      }
      <div className={classes.totalAmount}>
        <Typography variant="body1">
          <b>
            SHIPPING:
          </b> 
        </Typography>
        { 
        cartShipmentPrice === undefined ?
        <Typography className={classes.shipmentText}>
          -
        </Typography> :
        <Typography className={classes.priceText}>
          {"Rp " + cartShipmentPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Typography>
        } 
      </div>
      {
        numberOfItemsInCart > 0 ?
        <div className={classes.potonganOngkir}>
          <Typography variant="body1" className={classes.potonganOngkirText}>
              POTONGAN ONGKIR<span className={classes.potonganOngkirTextIcon}>&#x24D8;</span>:
          </Typography>
          {
            cartShipmentPrice === undefined ? 
            <Typography className={classes.priceText}>
              {"Up to Rp " + shipmentMaxPriceCut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>:
            <Typography className={classes.priceText}>
              {"- Rp " + (cartShipmentPrice - endCartShipmentPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          }
        </div>
        : null
      }
      {
        numberOfItemsInCart > 0 ?
        <div className={classes.potonganOngkirSubtextBox}>
          <Typography variant="body1" className={classes.potonganOngkirSubtext}>
            Selamat! Kamu mendapat potongan ongkir!
          </Typography>
        </div>
        : null
      }
      <div className={classes.totalAmount}>
        <Typography variant="body1">
          <b>
            TOTAL:
          </b> 
        </Typography>
        <Typography className={classes.priceText}>
          <span className={classes.priceStrikeText}>
            { 
            discountedPriceAmount === discountedPriceAmountWithPromotions ?
            "" :
            cartShipmentPrice === undefined ?
            "Rp " + discountedPriceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
            "Rp " + (discountedPriceAmount + cartShipmentPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          </span>
          Rp { 
          cartShipmentPrice === undefined ?
          discountedPriceAmountWithPromotions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
          discountedPriceAmountWithPromotionsAndShipment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
        </Typography>
      </div>
    </div>
  );
}

export default CartContent;
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Box, Dialog, IconButton, Link, Slide, Typography, Divider } from '@material-ui/core';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import { ArrowBack, Backspace } from '@material-ui/icons';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentTitle from './OverlayComponentTitle';

interface StyleProps {
  width: string
}

const useStyles = ({ width }: StyleProps) => makeStyles((theme: Theme) =>
  createStyles({
    barContainer: {
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: '6px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      width: '100%',
    },
    horizontalBarProgressBackground: {
      height: '1px',
      width: '220px',
      backgroundColor: '#aaa',
      position: 'relative',
      // marginTop: '20px',
    },
    horizontalBarProgress: {
      position: 'absolute',
      height: '2px',
      bottom: '-1px',
      backgroundColor: '#000',
      transition: 'all .25s linear',
      width: width,
    }
  }),
);

interface BackButtonSegmentProps {
  start: number
  end: number
  current: number
}

export default function VerticalBar({ start, end, current }: BackButtonSegmentProps) {
  let calcHeight = 0;
  calcHeight = 100 * (current - start)/(end - start); 
  const classes = useStyles({width: calcHeight.toString() + '%' })();

  return (
    <div className={classes.barContainer}>
      <OverlayComponentTitle text={'0' + current.toString() + '.'} fontSizeMobile='14px'/>
      <div className={classes.horizontalBarProgressBackground}>
        <div className={classes.horizontalBarProgress}>
        </div>
      </div>
      <OverlayComponentTitle text={'0' + end.toString() + '.'} fontSizeMobile='14px'/>
    </div>
  )
}
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OverlayAddressFlow {
  status: UIOverlayViewStatus,

  currentScreen: string,
}

// Define the initial state using that type
const initialState: OverlayAddressFlow = {
  status: UIOverlayViewStatus.OffAndReady,
  currentScreen: "list",
}

export const slice = createSlice({
  name: 'overlayAddressView',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;

      state.currentScreen = "list";
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;

      state.currentScreen = "list";
    },

    updateCurrentScreen: (state, action: PayloadAction<string>) => {
      state.currentScreen = action.payload;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay,
  updateCurrentScreen, } = slice.actions

export default slice.reducer
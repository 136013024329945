import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { ProductOnUser, Collection } from 'common-ts/dist/models/Product';
import { ProductsList } from 'common-ts/dist/models/Product';
import axios from 'axios';
import { RootState } from '../store';
import { Mixpanel } from '../mixpanel';
import { BodyMeasurementsForSize } from 'common-ts/dist/models/UserMeasurements';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';

// Define the initial state using that type
const initialState: ProductsList = {
  collectionsDictionary: {},
  productsDictionary: {},

  discoveryCollectionsOrder: [],
  efficientCollectionsOrder: [],
  efficientProductsOrder: [],

  productViewEventTimerDict: {},

  status: APIRequestStatus.Idle,
  error: null,

  productSizeAvailabilityDict: {},

  statusSizeAvailability: APIRequestStatus.Idle,
}

export const fetchProductsList = createAsyncThunk('productsList/fetchProductsList', async (payload: any, thunkAPI) => {
  const response = await axios({
    method: 'get',
    url: 'https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/products',
  });

  // Re-fetch reviews
  thunkAPI.dispatch(fetchProductsSizeAvailabilityDict(response.data.productIdList));

  return response.data
})

export const fetchProductsSizeAvailabilityDict = createAsyncThunk('productsList/fetchProductsSizeAvailabilityDict', async (payload: string[]) => {

  var measurementsArray = [];
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XXS]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XS]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.S]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.M]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.L]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XL]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XXL]);

  const response = await axios({
    method: 'post',
    url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/availability/product-size',
    data: JSON.stringify({
      productIdArray: payload,
      measurementsArray: measurementsArray
    })
  });

  return response.data
})

// export const attemptProductGlimpseEvent = createAsyncThunk('productsList/attemptProductGlimpseEvent', 
// async (payload: string, thunkAPI) => {
//   let productViewEventTimerDict = (thunkAPI.getState() as RootState).productsList.productViewEventTimerDict;

//   var currentTimestamp = Math.floor(Date.now() / 1000); // Timestamp in seconds

//   // We send another event if more than 10 seconds have passed since the last glimpse event
//   if (currentTimestamp - productViewEventTimerDict[payload] > 10) {
//     productViewEventTimerDict[payload] = currentTimestamp;
    
//   }

//   return response.data
// })

export const productSlice = createSlice({
  name: 'productsList',
  initialState,
  reducers: {
    attemptProductGlimpseEvent: (state, action: PayloadAction<string>) => {
      var currentTimestamp = Math.floor(Date.now() / 1000); // Timestamp in seconds

      // We send another event if more than 10 seconds have passed since the last glimpse event.
      // Each "impression" should have a 10 second interval from one another,
      // and is counted as one view / impression.
      // whereas one "reach" follows the one reach per user rule.
      if (state.productViewEventTimerDict[action.payload] === undefined
        || currentTimestamp - state.productViewEventTimerDict[action.payload] > 10) {
        state.productViewEventTimerDict[action.payload] = currentTimestamp;

        Mixpanel.track('productGlimpse', {
          'productId': "productId-" + action.payload,
          'timestamp': currentTimestamp
        });
        console.log("Tracking mixpanel for productId " + action.payload + " and timestamp " + currentTimestamp);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsList.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductsList.fulfilled, (state, action) => {
        // Payload is already in Object format.

        // Product
        const productDetailArray: ProductOnUser[] = action.payload.products;
        state.productsDictionary = {};
        for (let key in productDetailArray) {
          const productDetail: ProductOnUser = productDetailArray[key];
          state.productsDictionary[productDetail.productId] = productDetail;
        }

        // Collection
        const collectionArray: Collection[] = action.payload.collections;
        state.collectionsDictionary = {};
        for (let key in collectionArray) {
          const collectionDetail: Collection = collectionArray[key];
          state.collectionsDictionary[collectionDetail.collectionId] = collectionDetail;
        }

        state.discoveryCollectionsOrder = action.payload.discoveryCollectionsOrder;
        state.efficientCollectionsOrder = action.payload.efficientCollectionsOrder;
        state.efficientProductsOrder = action.payload.efficientProductsOrder;

        state.status = APIRequestStatus.Success;
      })
      .addCase(fetchProductsList.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
        // state.error = action.error.message
        console.log("fetch is failure");
      })
      .addCase(fetchProductsSizeAvailabilityDict.pending, (state, action) => {
        state.statusSizeAvailability = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductsSizeAvailabilityDict.fulfilled, (state, action) => {
        // Payload is already in Object format.

        // Product Size Availability Dict
        state.productSizeAvailabilityDict = action.payload;

        state.statusSizeAvailability = APIRequestStatus.Success;
      })
      .addCase(fetchProductsSizeAvailabilityDict.rejected, (state, action) => {
        state.statusSizeAvailability = APIRequestStatus.Failure;
      })
  }
})

// Action creators are generated for each case reducer function
export const { attemptProductGlimpseEvent } = productSlice.actions

export default productSlice.reducer
import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  contentContainer: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#2d2d2d',
    marginTop: '-2px',
    cursor: 'pointer',
    transition: 'opacity 0.5s',
    opacity: 1,
    WebkitTapHighlightColor: 'rgba(255,255,255,0)',
    position: 'relative'
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: '3.7vw',
    lineHeight: '5vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    marginBottom: '32px',
    padding: '0px 20px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
  },
  contentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    lineHeight: '1.8',
    marginBottom: '0px',
    padding: '0px 20px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  element: {
    width: '100%',
    marginTop: '0px',
  },
  loadingBarContainer: {
    width: '12vw',
    position: 'absolute',
    left: '8.24vw',
    top: '71vw',
  },
  loadingBarContainerMobile: {
    width: '40vw',
    position: 'absolute',
    left: '16.24vw',
    top: '265vw',
  },
  loadingBarRoot: {
    height: "4px",
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: "#565656"
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#232323"
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#fafafa"
    },
    "& .MuiLinearProgress-dashedColorPrimary": {
      backgroundImage:
        "radial-gradient(#f6ce95 0%, #f6ce95 16%, transparent 42%)"
    }
  },
  loadingBarRootMobile: {
    height: "4px",
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: "#565656"
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#f6ce95"
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#fafafa"
    },
    "& .MuiLinearProgress-dashedColorPrimary": {
      backgroundImage:
        "radial-gradient(#f6ce95 0%, #f6ce95 16%, transparent 42%)"
    }
  }
}));

export default function GreatFitGreatLooks() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  
  const [stateDict, setStateDict] = useState({
    divisionNumber: 0,
    loadingBarPercentage: 1,
  });

  React.useEffect(() => {
    const id = setInterval(() => {
      setStateDict((oldStateDict) => {
        return {
          divisionNumber: oldStateDict.loadingBarPercentage > 100 ? (oldStateDict.divisionNumber + 1) % 4 : oldStateDict.divisionNumber,
          loadingBarPercentage: oldStateDict.loadingBarPercentage > 100 ? 1 : oldStateDict.loadingBarPercentage + 10,
        }
      });
    }, 400);

    return () => {
      clearInterval(id);
    };
  }, []);

  var desktopSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageDesktop_V2_Field_Product.jpg';
  var mobileSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageMobile_V2_Field_Product.jpg';

  if (stateDict.divisionNumber === 1) {
    desktopSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageDesktop_V2_Field_Visual.jpg';
    mobileSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageMobile_V2_Field_Visual.jpg';
  }

  if (stateDict.divisionNumber === 2) {
    desktopSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageDesktop_V2_Field_Engineering.jpg';
    mobileSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageMobile_V2_Field_Engineering.jpg';
  }

  if (stateDict.divisionNumber === 3) {
    desktopSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageDesktop_V2_Field_Fashion.jpg';
    mobileSrc = 'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CareerPageMobile_V2_Field_Fashion.jpg';
  }

  const nextToImage = (
    <img src={desktopSrc} className={classes.element}/>
    );
  const mobile = (
    <img src={mobileSrc} className={classes.element}/>
    );

  return (
    <Box className={classes.contentContainer}
    onClick={() => {
      setStateDict({
        divisionNumber: (stateDict.divisionNumber + 1) % 4,
        loadingBarPercentage: 1,
      });
    }}>
      {
        isMdOrUp ? 
        <Box className={classes.loadingBarContainer}>
          <LinearProgress variant="determinate" value={stateDict.loadingBarPercentage} 
          classes={{
            root: classes.loadingBarRoot
          }}/>
        </Box> : 
      <Box className={classes.loadingBarContainerMobile}>
        <LinearProgress variant="determinate" value={stateDict.loadingBarPercentage} 
          classes={{
            root: classes.loadingBarRootMobile
          }}/>
        </Box>


      }
      {
        isMdOrUp ? 
        nextToImage :
        mobile
      }
    </Box>)
}
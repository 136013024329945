import { Divider, IconButton, SwipeableDrawer, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import React from 'react';
import { Redirect } from "react-router-dom";
import { Mixpanel } from '../mixpanel';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import { cancelUserOrders, resetCartAddressAndPaymentState, updateUserTrigger } from '../redux/CartRedux';
import { fetchProductsList } from '../redux/ProductsListRedux';
import { openOverlay } from '../redux/UIOverlayAccountViewRedux';
import { updateCurrentScreen } from '../redux/UIOverlayNavbarRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import CartContent from './CartContent';
import CartContentEmpty from './CartContentEmpty';
import { FareastButton } from './OverlayComponentButton';
import axios from 'axios';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { postNewProductAnalyticsLog } from '../redux/ProductAnalyticsRedux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartWindow: {
      width: '100vw',
      height: '100%',
      background: '#fafafa',
      [theme.breakpoints.up('sm')]: {
        width: '480px',
      },
    },
    cartWindowContent: {
      textAlign: 'center',
      background: '#fafafa',
      padding: '0px 24px',
      width: 'auto',
      height: 'auto',
    },
    arrowHideButton: {
      position: 'absolute',
      left: '15px',
      paddingTop: '10px',
    },
    cartTitleOrLogo: {
      position: 'relative',
      display: 'inline-block',
      paddingTop: '30px',
      paddingBottom: '6px',
    },
    icon: {
      color: '#000',
    },
    dividerCart: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    checkOutButton: {
      marginTop: '20px',
    },
    checkoutCalculateShippingText: {
      fontSize: '12px',
      marginTop: '16px',
      marginBottom: '-8px',
    }
  }),
);

interface CartWindowProps {
  navState: string,
}

function CartWindow({ navState }: CartWindowProps) {
  const productsList = useAppSelector((state) => state.productsList);
  const productsListFetchStatus = useAppSelector( state => state.productsList.status );
  const cart = useAppSelector((state) => state.cart);
  const isLoggedIn = useAppSelector((state) => state.account.authState === 'signedin');
  const userTriggerCheckOutStatus = useAppSelector(state => state.cart.userTriggerCheckout);
  const userBodyMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const userClothingMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsDictionary);
  const userAccount = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  // We reset cart address and payment if there is any.
  if (cart.orderStatus !== 'unsubmitted' || cart.address !== undefined 
  || cart.destinationObject !== undefined || cart.destinationKiriminAjaObject !== undefined) {
    console.log("Reset cart state");
    dispatch(resetCartAddressAndPaymentState());
  }

  let allMeasurementsOwnedByUser = true;
  for (let key in cart.cartProductList) {
    const cartItem = cart.cartProductList[key];
    const productDetail = productsList.productsDictionary[cartItem.productId];
    if (productDetail === undefined) {
      allMeasurementsOwnedByUser = false;
      break;
    }
    let key2 = productDetail.clothingType + "-" + cartItem.fitType;
    let measurementOwnedByUser = false;
    if (cartItem.sizeType === 'regular' || cartItem.measurementIsBodyMeasurement) {
      measurementOwnedByUser = true;
    } else if ( cartItem.sizeType === 'personal'
      && userClothingMeasurementsDict?.[key2]?.[cartItem.measurementCreationDateTime]?.[0]) {
      let cartItemMeasurement = userClothingMeasurementsDict[key2][cartItem.measurementCreationDateTime][0];
      measurementOwnedByUser = (cartItemMeasurement.idType === 'email'
      && cartItemMeasurement.email === userAccount.email)
      || (cartItemMeasurement.idType === 'phoneNumber'
      && cartItemMeasurement.phoneNumber === userAccount.phoneNumber);
    }
    // We set this to always be true on this current iteration.
    // allMeasurementsOwnedByUser = allMeasurementsOwnedByUser && measurementOwnedByUser;
  }

  // Check if any items in the cart are out of stock. If so, disable check out button
  // until the user reduces the amount in cart.

  let allItemsInStockApproximate = true;
  let cartProductItemsAmount: {[key:string]: number} = {};
  for (let key in cart.cartProductList) {
    const cartItem = cart.cartProductList[key];
    if (cartProductItemsAmount[cartItem.productId] === undefined) {
      cartProductItemsAmount[cartItem.productId] = 0;
    }
    cartProductItemsAmount[cartItem.productId] += cartItem.amount;
  }

  // TODO Check if all items are in stock
  // for (let key in cartProductItemsAmount) {
  //   const productDetail = productsList.productsDictionary[key];
  //   if (productDetail) {
  //     if (cartProductItemsAmount[key] > productDetail.amountInStock) {
  //       allItemsInStockApproximate = false;
  //     }
  //   }
  // }

  // Default status is API success and user trigger false.
  // When the check out button is clicked, it becomes API request in progress
  // and user trigger true. This will not be processed yet. 
  // After API is success / productlist is updated, if all items in stock, checkout.
  // If some are missing, turn off user trigger and ask user to "fix" the cart.
  if (productsListFetchStatus === APIRequestStatus.Success && userTriggerCheckOutStatus && isLoggedIn) {
    if (allItemsInStockApproximate) {
      console.log("User is logged in. All items in stock. Proceeding to checkout");
      sendWebsiteEventMessage("[CHECKOUT] User is logged in. All items in stock. Proceeding to checkout.");
      dispatch(resetCartAddressAndPaymentState());

      // We cancel the user's all other orders here
      dispatch(cancelUserOrders());

      return (<Redirect to={categoryMap["CHECK OUT"] }/>);
    } else {
      console.log('Stock is not there. Reverting user');
      dispatch(updateUserTrigger(false));
    }
  }

  function handleCheckoutRequest() {
    (window as any).dataLayer.push({'event': 'checkOut'});
    Mixpanel.track('checkOut');

    let itemsInCartString: string = "";
    for (let cartIndex = 0; cartIndex < cart.cartProductList.length; cartIndex++) {
      let cartItem = cart.cartProductList[cartIndex];
      const productDetail = productsList.productsDictionary[cartItem.productId];
      itemsInCartString = itemsInCartString + productDetail.name + " sebanyak " + cartItem.amount + "; "

      dispatch(postNewProductAnalyticsLog({event: 'CHECKOUT', productId: cartItem.productId, isMobileView: !isDesktop }))
    }

    if ((window as any).fbq !== null) { 
      (window as any).fbq('track', 'InitiateCheckout');
    }

    if (isLoggedIn) {
      sendWebsiteEventMessage("Ada yang check out (signed in). Item di cart: " + itemsInCartString);
      dispatch(fetchProductsList({}));
      dispatch(updateUserTrigger(true));
    } else {
      sendWebsiteEventMessage("Ada yang check out (NOT signed in). Item di cart: " + itemsInCartString);
      dispatch(openOverlay());
    }
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={navState === "CART"}
      onClose={(event) => dispatch(updateCurrentScreen("DEFAULT"))}
      onOpen={(event) => dispatch(updateCurrentScreen("CART"))}
    >
      <div className={classes.cartWindow}>
        <div className={classes.cartWindowContent}>
          <div className={classes.arrowHideButton}>
            <IconButton
              edge="end"
              aria-label="close cart"
              color="primary"
              onClick={() => {
                dispatch(updateCurrentScreen(navState === "CART" ? "DEFAULT" : "CART")); 
                (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/', 'routeSource': 'cartWindow'});
                Mixpanel.track('navigateRoute', {
                  'routePath': '/',
                  'routeSource': 'cartWindow',
                });
                Mixpanel.track('cartClose', {
                  'routeSource': 'cartWindow',
                });
              }}
              className={classes.icon}
            >
              <ArrowForward />
            </IconButton>
          </div>
          <div className={classes.cartTitleOrLogo}>
            <IconButton
                    aria-label="cart icon"
                    color="primary"
                    className={classes.icon}
                  >
              <ShoppingCartOutlinedIcon />
            </IconButton>
          </div>
          <Typography variant="body1">
            <b>
              YOUR CART
            </b>
          </Typography>
          <Divider className={classes.dividerCart} />

          {
            cart.cartProductList.length === 0 ? 
            <CartContentEmpty /> :
            <div>
              <CartContent cart={cart} productsList={productsList} isModifiable={true} />
              <Typography className={classes.checkoutCalculateShippingText}>
                Check out untuk menghitung ongkos kirim.
              </Typography>
              <FareastButton
                aria-label="check out"
                onClick={handleCheckoutRequest}
                disabled={!allItemsInStockApproximate || !allMeasurementsOwnedByUser}
                fullWidth
                buttonMarginLeftRight='0px'
                buttonMarginTopBottom='20px'
              >
                <Typography>
                  <b>CHECK OUT</b>
                </Typography>
              </FareastButton>
            </div>
          }
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default CartWindow;
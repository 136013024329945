import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { SetStateAction } from 'react';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import { Link as RouterLink } from "react-router-dom";
import { fetchProductsList } from '../redux/ProductsListRedux';
import { FareastButton } from './OverlayComponentButton';
import { updateCurrentScreen } from '../redux/UIOverlayNavbarRedux';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paymentContent: {
    height: '100%',
    padding: '0px 24px',
    marginTop: '-10px',
  },
  subFormTitle: {
    marginTop: '28px',
    marginBottom: '20px',
    fontSize: '15px',
  },
  paymentPortalButton: {
    marginTop: '20px',
  },
  formNavButtonsContainer: {
    height: 'auto',
    width: '100%',
    right: '0px',
    display: 'flex',
    justifyContent: 'right',
  },
  formNavButtons: {
    marginTop: '20px',
    marginRight: '18px',
    marginBottom: '24px',
  },
}),
);

interface PageCheckoutProps {
  checkoutStep: string,
  setCheckoutStep: React.Dispatch<SetStateAction<string>>,
}

export default function PageCheckoutForms({checkoutStep, setCheckoutStep}: PageCheckoutProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  return (
    <div className={classes.paymentContent}>
      <Typography variant="h5" className={classes.subFormTitle}>
        Your transaction have been cancelled.
      </Typography>
      <div className={classes.formNavButtonsContainer}>
        <FareastButton
          component={RouterLink}
          to={categoryMap["HOME"]}
          buttonMarginTopBottom="24px"
          buttonMarginLeftRight="18px"
          onClick={() => {
            dispatch(fetchProductsList({}));
            dispatch(updateCurrentScreen("DEFAULT"));
          } }
        >
          Go back to main page
        </FareastButton>
      </div>
    </div>
  );
}
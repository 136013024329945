import React, { useCallback, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Link, useMediaQuery } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Mixpanel } from '../mixpanel';
import { attemptProductGlimpseEvent } from '../redux/ProductsListRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { FareastButton, FareastButtonGrey } from './OverlayComponentButton';
import { determineMediaType } from './PageProductDetail';
import { VideoComponent } from './PageComponentElementCarousel';
import { postNewProductAnalyticsLog } from '../redux/ProductAnalyticsRedux';

interface stylesProps {
  image_url: string,
  image_url_hover: string,
  margin: string,
  padding: string
}

const useStyles = ({ image_url, image_url_hover, margin, padding }: stylesProps) => makeStyles((theme: Theme) =>
  createStyles({
    video: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    productListItem: {
      width: '100%',
      margin: margin,
      padding: padding,
      '&:hover': {
        zIndex: 100, // To ensure that the items come first
      },
    },
    paper: {
      position: "relative", // Needed for child ::before
      overflow: "visible !important", // Needed such that overflows are not clipped
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: '-12px',
        [theme.breakpoints.up('md')]: {
          inset: '-16px',
        },
        backgroundColor: "#ffffff",
        zIndex: -1,
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          '&::before': {
            boxShadow: '0px 0px 18px 8px #c8c8c8',
            display: 'block',
            zIndex: 1,
          },
        },
      }
    },
    cardActionArea: {
      zIndex: 1,
      color: 'aliceblue',
    },
    media: {
      paddingBottom: '150%', // We use 2 : 3 aspect ratio
      backgroundPosition: 'center top',
      backgroundImage: `url(${image_url})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '&:hover': {
        backgroundImage: `url(${image_url_hover})`,
      },
    },
    imageContentContainer: {
      paddingBottom: '150%',
      height: '0px', // This is useful to make an aspect ratio of our liking 
      position: 'relative',
    },
    imageContent: {
      width: '100%',
    },
    hiddenElement: {
      display: 'none',
    },
    itemContent: {
      paddingTop: "14px",
      paddingLeft: "6px",
      paddingRight: "6px",
      paddingBottom: "6px",
      marginBottom: "0px",
    },
    itemHeader: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "6px",
    },
    itemHeaderTitleText: {
      fontSize: '1.43vw',
      fontFamily: 'OakesGrotesk, sans-serif',
      fontWeight: 500,
      letterSpacing: '0.03em',
      color: '#232323',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3.3vw',
      },
    },
    itemPriceText: {
      textAlign: 'right',
      fontSize: '1.25vw',
      fontWeight: 300,
      color: '#000000',
      fontFamily: 'Open sans, sans-serif',
      marginTop: '-0.1vw',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3vw',
        marginTop: '0px',
      },
      whiteSpace: 'nowrap',
    },
    itemPriceTextDiscount: {
      textAlign: 'right',
      fontSize: '1vw',
      fontWeight: 500,
      color: 'red',
      fontFamily: 'Open sans, sans-serif',
      marginTop: '-0.1vw',
      marginRight: "1vw",
      [theme.breakpoints.down('xs')]: {
        fontSize: '3vw',
        marginTop: '0px',
        marginRight: "2.4vw",
      },
      whiteSpace: 'nowrap',
    },
    itemPriceTextDiscountStrikeThrough: {
      textAlign: 'right',
      fontSize: '1vw',
      fontWeight: 'lighter',
      color: '#4D4D4D',
      fontFamily: 'Open sans, sans-serif',
      marginTop: '-0.1vw',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3vw',
        marginTop: '0px',
      },
      textDecoration: 'line-through',
      whiteSpace: 'nowrap',
    },
    priceBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: 'fit-content',
    },
    circularProgressContainer: {
      width: '100%',
      aspectRatio: '2/3', // We use 2 : 3 aspect ratio
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    soldOutOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255,255,255,0.1)'
    },
    sisaBox: {
      backgroundColor: "#ff1e1e",
      textAlign: "center",
      position: "absolute",
      bottom: "1vw",
      left: "1vw",
      width: "6vw",
      borderRadius: "1.3vw",
      [theme.breakpoints.down('xs')]: {
        bottom: "2.4vw",
        left: "2.4vw",
        width: "14vw",
        borderRadius: "3vw",
      },
    },
    sisaText: {
      color: '#ffffff',
      fontSize: "1.3vw",
      paddingTop: "0.3vw",
      [theme.breakpoints.down('xs')]: {
        fontSize: "3vw",
        paddingTop: "0.5vw",
      },
    }
  }),
);

interface PageProductItemProps {
  product_id: string,
  image_url: string,
  image_url_hover: string,
  title: string,
  originalPrice: number,
  discountedPrice: number,
  isSoldOut?: boolean,
  isComingAgainSoon?: boolean,
  product_url: string,
  margin?: string,
  padding?: string,
}


export default function PageElementProduct({ product_id, image_url, image_url_hover, title, originalPrice, discountedPrice, isSoldOut,
  isComingAgainSoon, product_url, margin, padding }: PageProductItemProps) {
  margin = margin ? margin : '0px 0px 0px 0px';
  padding = padding ? padding : '30px 9px 9px 9px';
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  const classes = useStyles({ image_url, image_url_hover, margin, padding })();
  const dispatch = useAppDispatch();

  // GET VIDEO URL IF IT EXISTS
  let video_url = ''
  if (determineMediaType(image_url) === 'video') {
    video_url = image_url
  }

  const numberOfItemsRemaining = productSizeAvailabilityDict?.[product_id]?.numberOfItemsRemaining;

  isSoldOut = isSoldOut || numberOfItemsRemaining === 0;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [isHovered, setIsHovered] = React.useState(false);

  const [imgHover, setImgHover] = React.useState<string>('');
  const [imgNotHover, setImgNotHover] = React.useState<string>('');

  const [imgHoverContainer, setImgHoverContainer] = React.useState<string>('');
  const [imgNotHoverContainer, setImgNotHoverContainer] = React.useState<string>('');

  // Intersection Observer API for lazy loading
  const { ref, inView, entry } = useInView({
    threshold: [0.02, 0.05, 0.1, 0.2],
    triggerOnce: true,
    rootMargin: '400px',
  });

  // Intersection Observer API for viewing event
  const { ref: ref2, inView: inView2, entry: entry2 } = useInView({
    threshold: 0.7,
    triggerOnce: false,
    rootMargin: '10px',
  });

  useEffect(() => {
    if (inView && isMdOrUp) {
      const imgHoverNew = new Image();
      imgHoverNew.onload = () => {
        setImgHoverContainer(imgHoverNew.src);
      }
      imgHoverNew.src = image_url_hover;

      const imgNotHoverNew = new Image();
      imgNotHoverNew.onload = () => {
        setImgNotHoverContainer(imgNotHoverNew.src);
      }
      imgNotHoverNew.src = image_url;
    }
  }, [image_url, image_url_hover, inView]);

  useEffect(() => {
    if (inView2) {
      dispatch(attemptProductGlimpseEvent(product_id));
      dispatch(postNewProductAnalyticsLog({event: 'VIEW', productId: product_id, isMobileView: !isDesktop }))
    }
  }, [inView2]);

  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node) => {
      ref(node);
      ref2(node);
    },
    [ref, ref2],
  );

  if (imgHoverContainer !== "" && imgHoverContainer === image_url_hover && imgHoverContainer !== imgHover) {
    setImgHover(imgHoverContainer);
  }
  if (imgNotHoverContainer !== "" && imgNotHoverContainer === image_url && imgNotHoverContainer !== imgNotHover) {
    setImgNotHover(imgNotHoverContainer);
  }

  if (!isMdOrUp) {
    return (
        <div className={classes.productListItem} ref={setRefs}
          onClick={() => {

            sendWebsiteEventMessage("Ada yang buka product " + title + " dari x" + window.location.href);

            Mixpanel.track('navigateRoute', {
              'routePath': product_url,
              'routeSource': 'pageProductListItem',
            });
            Mixpanel.track('productClickToProductDetailPage', {
              'productId': "productId-" + product_id,
              'productUrl': product_url,
              'routeSource': 'pageProductListItem',
            });
            console.log("Tracking mixpanel productClickToProductDetailPage for product " + product_id);
          }}>
            <Link component={RouterLink} to={product_url} underline="none" style={{
              WebkitTapHighlightColor: 'transparent',
            }}>
              <div className={classes.imageContentContainer}>
                {
                  isSoldOut ?
                  <Box className={classes.soldOutOverlay}>
                    <FareastButton
                      buttonMarginLeftRight='20px'
                      buttonMarginTopBottom='20px'>
                      <b>SOLD OUT</b>
                    </FareastButton>
                  </Box> :
                  isComingAgainSoon ?
                  <Box className={classes.soldOutOverlay}>
                    <FareastButtonGrey
                      buttonMarginLeftRight='20px'
                      buttonMarginTopBottom='20px'>
                      <b>COMING BACK SOON</b>
                    </FareastButtonGrey>
                  </Box> :
                  null
                }
                {
                  video_url ? 
                  (
                    <div key={"full-img-product-" + product_id }>
                      <VideoComponent 
                        source={video_url} 
                        videoHasSound={false} // All element product video has sound disabled
                      />
                    </div>
                  ) : (
                    <img className={classes.imageContent} src={image_url} alt={"img-hover-" + product_id} key={"img-hover-" + product_id} />
                  )
                }
                {
                  !isSoldOut && numberOfItemsRemaining && numberOfItemsRemaining < 14 ?
                    <Box className={classes.sisaBox}>
                      <Typography className={classes.sisaText}>
                        Sisa {numberOfItemsRemaining}
                      </Typography>
                    </Box> :
                    null
                }
              </div>
            </Link>
            <Box className={classes.itemContent}>
              <Link component={RouterLink} to={product_url} underline="none" style={{
                WebkitTapHighlightColor: 'transparent',
              }}>
              <Box className={classes.itemHeader}>
                <Typography className={classes.itemHeaderTitleText}>
                  {ReactHtmlParser(title)}
                </Typography>
                {
                  discountedPrice === -1 || isSoldOut === true || isComingAgainSoon === true ?
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceText}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box> :
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceTextDiscount}>
                        {("Rp " + discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                      <Typography className={classes.itemPriceTextDiscountStrikeThrough}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box>
                }
              </Box>
              </Link>
            </Box>
        </div>
    );
  }

  return (
    <div className={classes.productListItem} ref={setRefs}>
      <Card elevation={0} square={true} className={classes.paper}>
        <CardActionArea component={RouterLink} to={product_url} disableRipple
          onClick={() => {

            sendWebsiteEventMessage("Ada yang buka product " + title + " dari x" + window.location.href);

            (window as any).dataLayer.push({ 'event': 'navigateRoute', 'routePath': product_url, 'routeSource': 'pageProductListItem' });
            Mixpanel.track('navigateRoute', {
              'routePath': product_url,
              'routeSource': 'pageProductListItem',
            });
            Mixpanel.track('productClickToProductDetailPage', {
              'productId': "productId-" + product_id,
              'productUrl': product_url,
              'routeSource': 'pageProductListItem',
            });
            console.log("Tracking mixpanel productClickToProductDetailPage for product " + product_id);
          }} className={classes.cardActionArea}>
          {
            !imgHover || !imgNotHover || imgHover !== image_url_hover || imgNotHover !== image_url ?
              <div className={classes.circularProgressContainer}>
                <CircularProgress />
              </div> :
              <div className={classes.imageContentContainer}
                onMouseEnter={() => setIsHovered(isMdOrUp)}
                onMouseLeave={() => setIsHovered(false)}>
                {
                  isSoldOut ?
                    <Box className={classes.soldOutOverlay}>
                      <FareastButton
                        buttonMarginLeftRight='20px'
                        buttonMarginTopBottom='20px'>
                        <b>SOLD OUT</b>
                      </FareastButton>
                    </Box> :
                    isComingAgainSoon ?
                      <Box className={classes.soldOutOverlay}>
                        <FareastButtonGrey
                          buttonMarginLeftRight='20px'
                          buttonMarginTopBottom='20px'>
                          <b>COMING BACK SOON</b>
                        </FareastButtonGrey>
                      </Box> :
                      null
                }
                {
                  isHovered ?
                  <img className={classes.imageContent} src={imgHover} alt={"img-" + product_id} key={"img-" + product_id} /> : (
                  <>
                  {
                    video_url ? 
                    (
                      <div key={"full-img-product-" + product_id }>
                        <VideoComponent 
                          source={video_url} 
                          videoHasSound={false} // All element product video has sound disabled
                        />
                      </div>
                    ) : (
                      <img className={classes.imageContent} src={imgNotHover} alt={"img-hover-" + product_id} key={"img-hover-" + product_id} />
                    )
                  }
                  </>)
                }
                {
                  !isSoldOut && numberOfItemsRemaining && numberOfItemsRemaining < 14 ?
                    <Box className={classes.sisaBox}>
                      <Typography className={classes.sisaText}>
                        Sisa {numberOfItemsRemaining}
                      </Typography>
                    </Box> :
                    null
                }
              </div>
          }
          <Box className={classes.itemContent}>
            <Link component={RouterLink} to={product_url} underline="none">
              <Box className={classes.itemHeader}>
                <Typography className={classes.itemHeaderTitleText}>
                  {ReactHtmlParser(title)}
                </Typography>
                {
                  discountedPrice === -1 || isSoldOut === true || isComingAgainSoon === true ?
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceText}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box> :
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceTextDiscount}>
                        {("Rp " + discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                      <Typography className={classes.itemPriceTextDiscountStrikeThrough}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box>
                }
              </Box>
            </Link>
          </Box>
        </CardActionArea>
      </Card>
    </div>
  );
  
}
import { Accordion, AccordionDetails, AccordionSummary, Box, createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingTop: '80px',
    paddingBottom: '80px',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'inherit',
      padding: '40px 0'
    }
  },
  segmentContainer: {
    width: '100%',
    [theme.breakpoints.up("sm")]: {
      width: '85%',
    }
  },
  titleTopText: {
    display: 'inline-block',
    border: '1px solid black',
    padding: '0 5px',
    borderRadius: '25px',
    fontWeight: 500,
    letterSpacing: '0.05em',
    fontFamily: 'Roboto Mono',
    fontSize: '1vw',
    marginBottom: '0.9vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.151vw',
      float: 'left',
      margin: '3vw 0 3vw 10vw',
    }
  },
  titleText: {
    fontFamily: 'TT Hoves, Open sans, sans-serif',
    fontWeight: 400,
    color: '#111111',
    fontSize: '2.5vw',
    textAlign: 'center',
    marginBottom: '2vw',
    padding: '0 28%',
    [theme.breakpoints.only('xs')]: {
      fontSize: '5.6vw',
      margin: '0 0 3vw 0',
      padding: '0 10vw'
    },
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'inherit'
    }
  },
  contentInnerContainer: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 10vw',
      width: '80vw',
    },
    margin: '5px 0',
  },
  contentTitleNumber: {
    fontSize: '3vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '12.9vw',
    },
    marginTop: '1vw',
    marginBottom: '1.4vw',
    fontFamily: 'Roboto Mono',
    fontWeight: 500,
    color:'black',
  },
  contentTitle: {
    fontWeight: 300,
    fontSize: '1.6vw',
    letterSpacing: '0.02em',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.7vw',
      lineHeight: '4.3vw',
    },
    fontFamily: 'TT Hoves, Open sans, sans-serif',
    color: '#343434',
    lineHeight: '2.2vw',
  },
  contentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '1vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.2vw',
      lineHeight: '5vw',
    },
    lineHeight: '2.1vw',
    fontFamily: 'TT Hoves, sans-serif',
    color: '#343434',
  },
  linkPelajariSizing: {
    color: 'primary',
  },
  underlinedText: {
    textDecoration: 'underline',
  },
  tmTitle: {
    fontWeight: 700,
    fontSize: '20px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
  },
  tm: {
    fontWeight: 400,
    fontSize: '1.5vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2vw',
    },
  },
  accordContainer: {
    boxShadow: 'none',
    borderBottom: '0.5px solid #111111',
    borderRadius: 'unset!important'
  }
}));

export default function SesuaiUkuran() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Box>
        <Typography className={classes.titleText}>
          Setiap baju yang kamu beli di Studio S. dibuat <span className={classes.underlinedText}>#SesuaiUkuran<sup className={classes.tm}>TM</sup></span> supaya kamu bisa:
        </Typography>
      </Box>
      <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.segmentContainer}>
        <Grid item xs={12} className={classes.contentContainer}>
          <Box className={classes.contentInnerContainer}>
            <Accordion className={classes.accordContainer} >
              <AccordionSummary
                expandIcon={<Add />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.contentTitle}>Beli baju online, selalu pas & puas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.contentText}>
                  111 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.contentContainer}>
          <Box className={classes.contentInnerContainer}>
              <Accordion className={classes.accordContainer}>
                <AccordionSummary
                  expandIcon={<Add />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.contentTitle}>Tampil stylish apapun tipe badanmu</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.contentText}>
                    222 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.contentContainer}>
          <Box className={classes.contentInnerContainer}>
            <Accordion className={classes.accordContainer}>
              <AccordionSummary
                expandIcon={<Add />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className={classes.contentTitle}>Belanja minim dampak lingkungan</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.contentText}>
                  333 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
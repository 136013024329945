import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography, Box, Hidden, Drawer, AppBar, Tabs, Tab, ListItemIcon, ListItem, List, ListItemText } from '@material-ui/core';
import { signOutAndReset } from '../redux/AccountRedux';
import { resetCartState } from '../redux/CartRedux';
import React from 'react';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import PageAccountYourAccountYourMeasurements from './PageAccountYourAccountYourMeasurements';
import PageAccountYourAccountYourOrders from './PageAccountYourAccountYourOrders';
import PageAccountYourAccountYourAddresses from './PageAccountYourAccountYourAddresses';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import ViewListIcon from '@material-ui/icons/ViewList';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { resetUserMeasurementList } from '../redux/MeasurementsRedux';
import { Mixpanel } from '../mixpanel';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  yourAccountContent: {
    padding: '0px 0px',
  },
  welcomeSideBar: {
    marginTop: '28px',
    marginBottom: '15px',
    marginLeft: '30px',
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '24px',
  },
  subFormTitle: {
    marginTop: '28px',
    marginBottom: '20px',
    marginLeft: '20px',
    fontWeight: theme.typography.fontWeightBold as number,
  },
  paymentPortalButton: {
    marginTop: '20px',
  },
  accountSegmentTabs: {

  },
  accountSegmentListItem: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    minHeight: 32,
  },
  accountSegmentListItemIcon: {
    minWidth: '70px',
    justifyContent: 'center',
    paddingRight: '10px',
  },
  accountSegmentListItemText: {
    fontSize: '15px',
  },
  yourAccountPanelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: "60px",
    paddingTop: "50px"
  },
  accountContentGridItem: {
    overflow: 'auto',
    bottom: '0',
  },
  yourAccountUserNameTitleText: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "5px",
  },
  yourAccountUserNameText: {
    fontSize: "18px",
    letterSpacing: "0.5px",
    fontWeight: 300,
    marginBottom: "27px",
    color: "#565656"
  },
  yourAccountTab: {
    fontSize: "0.82rem",
    letterSpacing: "0.3px",
    color: "#919191"
  }
}),
);

export default function PageAccountYourAccount() {
  const userAccount = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [tabIndex, setTabIndex] = React.useState(0);

  async function signOut() {
    try {
      Mixpanel.track('signOutAttempt');
      dispatch(signOutAndReset());
      dispatch(resetCartState()); // Uncomment to reset cart on sign out
      dispatch(resetUserMeasurementList());
    } catch (error) {
      console.log("error in sign out:", error);
    }
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
      <Box className={classes.yourAccountContent}>
        <Grid container>
          <Hidden mdUp>
            <Grid item xs={12}>
              <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="simple tabs example"
              variant="fullWidth" className={classes.accountSegmentTabs}
              indicatorColor="primary"
              textColor="primary"
              >
                <Tab icon={<AccountCircleIcon />} label="Akun" classes={{root: classes.yourAccountTab}}/>
                <Tab icon={<AccessibilityNewIcon />} label="Ukuran" classes={{root: classes.yourAccountTab}} />
                <Tab icon={<ViewListIcon />} label="Order" classes={{root: classes.yourAccountTab}} />
                <Tab icon={<HomeIcon />} label="Alamat" classes={{root: classes.yourAccountTab}} />
                {/* <Tab icon={<HomeIcon />} label="Mail" classes={{root: classes.yourAccountTab}} /> */}
              </Tabs>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={3}>
              <Typography variant="h5" className={classes.welcomeSideBar}>
                Welcome back, {userAccount.name}
              </Typography>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button 
                selected={tabIndex === 0}
                onClick={(event) => {handleChangeTab(event, 0)}}
                className={classes.accountSegmentListItem}
                dense={false}
                >
                  <ListItemIcon className={classes.accountSegmentListItemIcon}>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Your Account" 
                    primaryTypographyProps={{className:classes.accountSegmentListItemText}}/>
                </ListItem>
                <ListItem button 
                selected={tabIndex === 1}
                onClick={(event) => {handleChangeTab(event, 1)}}
                className={classes.accountSegmentListItem}
                dense={false}
                >
                  <ListItemIcon className={classes.accountSegmentListItemIcon}>
                    <AccessibilityNewIcon />
                  </ListItemIcon>
                  <ListItemText primary="Your Measurements" 
                    primaryTypographyProps={{className:classes.accountSegmentListItemText}}/>
                </ListItem>
                <ListItem button 
                selected={tabIndex === 2}
                onClick={(event) => {handleChangeTab(event, 2)}}
                className={classes.accountSegmentListItem}
                dense={false}
                >
                  <ListItemIcon className={classes.accountSegmentListItemIcon}>
                    <ViewListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Your Orders" 
                    primaryTypographyProps={{className:classes.accountSegmentListItemText}}/>
                </ListItem>
                <ListItem button 
                selected={tabIndex === 3}
                onClick={(event) => {handleChangeTab(event, 3)}}
                className={classes.accountSegmentListItem}
                dense={false}
                >
                  <ListItemIcon className={classes.accountSegmentListItemIcon}>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Your Addresses" 
                    primaryTypographyProps={{className:classes.accountSegmentListItemText}}/>
                </ListItem>
                {/* <ListItem button 
                selected={tabIndex === 4}
                onClick={(event) => {handleChangeTab(event, 4)}}
                className={classes.accountSegmentListItem}
                dense={false}
                >
                  <ListItemIcon className={classes.accountSegmentListItemIcon}>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Mailing List" 
                    primaryTypographyProps={{className:classes.accountSegmentListItemText}}/>
                </ListItem> */}
              </List>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9} className={classes.accountContentGridItem}>
            {
              tabIndex === 0 ? 
              <Box className={classes.yourAccountPanelContainer} >
                <Typography className={classes.yourAccountUserNameTitleText}>
                  You are signed in as
                </Typography>
                <Typography className={classes.yourAccountUserNameText}>
                  {userAccount.name}
                </Typography>
                <Button onClick={() => signOut()}
                  startIcon={<ExitToAppIcon />}
                  variant="outlined">
                  <b>SIGN OUT</b>
                </Button>
              </Box> : 
              tabIndex === 1 ?
              <PageAccountYourAccountYourMeasurements/> :
              tabIndex === 2 ?
              <PageAccountYourAccountYourOrders/> : 
              <PageAccountYourAccountYourAddresses /> 
            }
          </Grid>
        </Grid>
      </Box>
  );
}
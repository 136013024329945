import { Box } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { RegularSizeValue, RegularSizeValueShortName, SizeType } from 'common-ts/dist/models/Cart';
import { ProductOnUser } from 'common-ts/dist/models/Product';
import { BodyMeasurements, BodyMeasurementsForSize, CalculateClothBodyMeasurementsFromBodyMeasurements, CalculateClothingMeasurementsFromBodyMeasurements, ClothingMeasurements, ClothingType, ClothingTypeDisplayString, FitType, FitTypeDisplayString, RoundClothingMeasurementsToHalfPoint, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { fetchUserMeasurementEstimates, inputMeasurementsForm, postClothingMeasurement } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset, openResultRatingOverlay } from '../redux/UIOverlayMeasurementCreateViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import BackButtonSegment from './OverlayViewGenerateMeasure0BackSegment';
import OverlayViewSignUpPersonalOrRegularSize from './OverlayViewGenerateMeasure1SelectMethod1PersonalOrRegularSize';
import OverlayViewSignUpAlgorithmOrFavoriteSize from './OverlayViewGenerateMeasure1SelectMethod2AlgorithmOrFavoriteSize';
import OverlayViewSignUpDibuatinAtauBuatSendiri from './OverlayViewGenerateMeasure1SelectMethod3DibuatinAtauBuatSendiri';
import OverlayViewSignUpStart from './OverlayViewGenerateMeasureMethod1Algorithm0NameAge';
import OverlayViewFaq from './OverlayViewGenerateMeasureMethod1Algorithm10FAQ';
import OverlayViewAlgorithmIntroduction from './OverlayViewGenerateMeasureMethod1Algorithm0Introduction';
import OverlayViewSignUpHeightWeight from './OverlayViewGenerateMeasureMethod1Algorithm1HeightWeight';
import OverlayViewSignUpBodyType from './OverlayViewGenerateMeasureMethod1Algorithm2BodyType';
import OverlayViewSignUpSelectFitIssues from './OverlayViewGenerateMeasureMethod1Algorithm4SelectFitIssues';
import OverlayViewSignUpHaveMeasurementTape from './OverlayViewGenerateMeasureMethod1Algorithm5HaveMeasurementTape';
import OverlayViewSignUpWantToMeasure from './OverlayViewGenerateMeasureMethod1Algorithm5WantToMeasure2';
import OverlayViewSignUpMeasureBodyParts from './OverlayViewGenerateMeasureMethod1Algorithm7MeasureBodyParts';
import OverlayViewSignUpMeasurementEstimateLoading from './OverlayViewGenerateMeasureMethod1Algorithm8MeasurementEstimateLoading';
import OverlayViewSignUpMeasurementEstimate from './OverlayViewGenerateMeasureMethod1Algorithm9MeasurementEstimateIter2';
import OverlayViewRegularSelectSize from './OverlayViewGenerateMeasureMethod2Regular0SelectSize';
import OverlayViewFavoriteSelectSize from './OverlayViewGenerateMeasureMethod3Favorite0SelectSize';
import OverlayViewFavoriteModifySize from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';
import OverlayViewDibuatinSelectSize from './OverlayViewGenerateMeasureMethod4Dibuatin0SelectSize';
import OverlayViewSaveMeasName from './OverlayViewMeasurementClothingEdit2SaveScreen';
import { updateState } from '../redux/AccountRedux';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import PersonalSizeUkurTiapBagian from './OverlayViewUniversalSize5UkurTiapBagian';
import PageTailorChat from './PageTailorChat';
import { socket } from '../socket';
import { openOverlay } from '../redux/UIOverlayInstantSizeTeamRedux';
import { closeOverlayAndReset as closeOverlayAndResetMethodSelection, closeOverlayButDoNotResetScreenState as closeOverlayAndResetMethodSelectionCustom } from '../redux/UIOverlayMeasurementMethodSelectionViewRedux';
import { closeOverlayAndReset as closeOverlayAndResetSelection } from '../redux/UIOverlayMeasurementSelectionViewRedux';

// Determines which flow is used based on starting parameters
export interface FlowSelectionData {
  chatUserId: string
  clothingType: ClothingType
  fitType: FitType
  isSignedIn: boolean
  addToCartAfterCreation: boolean
  productDetail: ProductOnUser | undefined
}

// State data for flow
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateData {
  currentScreen: string
  titleNumber: number
  currentUserMeasurement: UserClothTypeSpecificBodyMeasurements
  currentUserBodyMeasurement: UserBodyMeasurements
  hasFinishedResultsRating: boolean
}

// Callback to change state data for flow. 
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: string) => void
  setTitleNumber: (titleNumber: number) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentMaster(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentMensTops(flowSelectionData, flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentMensTops(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let titleNumber = -1;

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  switch (flowStateData.currentScreen) {
    case "personalOrRegularSize": // 1. Custom or 2. Regular
      children.push(<OverlayViewSignUpPersonalOrRegularSize
        handleSubmit={(choosePersonalSize: boolean) => {
          if (choosePersonalSize) {
            Mixpanel.track('personalOrRegularSizeNext', {
              'answer': 'personal',
            });
            delayedDispatch("dibuatinAtauBuatSendiri");
            Mixpanel.track('personalSizeStart');
            sendWebsiteEventMessage("User chooses personal size.");

            if ((window as any).fbq !== null) { 
              (window as any).fbq('trackCustom', 'ChoosePersonalSize');
            };

            // Turn off, too annoying.
            // setTimeout(() => {
            //   dispatch(openOverlay());
            // }, 1800);
          } else {
            Mixpanel.track('personalOrRegularSizeNext', {
              'answer': 'regular',
            });
            const payload: UserClothTypeSpecificBodyMeasurements = {
              sizeType: SizeType.regular,
            }
            dispatch(inputMeasurementsForm(payload));
            delayedDispatch("regularSelectSize");
            Mixpanel.track('regularSizeStart');
            sendWebsiteEventMessage("User chooses regular size.");

            if ((window as any).fbq !== null) { 
              (window as any).fbq('trackCustom', 'ChooseRegularSize');
            };
          }
        }
        }/>);
      break;
    case "dibuatinAtauBuatSendiri":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('dibuatinAtauBuatSendiriBack');
          delayedDispatch("personalOrRegularSize");
        }}
      />);
      children.push(<OverlayViewSignUpDibuatinAtauBuatSendiri
        handleSubmit={(chooseInstantSizeTeam: boolean) => {
          if (chooseInstantSizeTeam) {
            Mixpanel.track('dibuatinAtauBuatSendiriNext', {
              'answer': 'dibuatin',
            });
            delayedDispatch("dibuatinPilihSize");
            sendWebsiteEventMessage("User chooses dibuatkan tim.");
          } else {
            Mixpanel.track('dibuatinAtauBuatSendiriNext', {
              'answer': 'sendiri',
            });
            delayedDispatch("favoriteOrAlgorithmSize");
            sendWebsiteEventMessage("User chooses buat sendiri.");
          }
        }
      }/>);
      break;
    case "dibuatinPilihSize":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('dibuatinPilihSizeBack');
          delayedDispatch("dibuatinAtauBuatSendiri");
        }}
      />);
      children.push(<OverlayViewDibuatinSelectSize
        clothingType={flowStateData.currentUserMeasurement.clothingType as ClothingType}
        fitType={flowStateData.currentUserMeasurement.fitType as FitType}
        key2={flowStateData.currentUserMeasurement.clothingType + "-" + flowStateData.currentUserMeasurement.fitType}
        handleSubmit={(value) => {
          sendWebsiteEventMessage("User chooses dibuatkan tim: SIZE " + value);

          // If out of hour, redirect to WA
          // We redirect from 12 malam (above or equal to 17 UTC) to 6:59 pagi (under or equal to 23 UTC)
          const d = new Date();
          let hour = d.getUTCHours();

          if (hour >= 17 && hour <= 23) {
            sendWebsiteEventMessage("User chooses dibuatkan tim: Offline hours, redirecting to WA.");
            
            const url = 'https://wa.me/6287813028340?text=Halo%20Instant%20Size%20Team,' + 
            '%20saya%20mau%20buat%20ukuran%20' + 
            ClothingTypeDisplayString[flowStateData.currentUserMeasurement.clothingType as ClothingType] + 
            '.%20Biasa%20memakai%20ukuran%20' + RegularSizeValueShortName[value] + '.';
            window.open(url, '_blank');

            return;
          }

          sendWebsiteEventMessage("User chooses dibuatkan tim: Continuing to chat.");

          Mixpanel.track('dibuatinPilihSizeNext', {
            'selectedSize': value,
          });
          let userMeasurements: UserClothTypeSpecificBodyMeasurements = {
            sizeType: SizeType.personal,
            measurementName: "Ukuran " + 
              (flowStateData.currentUserMeasurement.clothingType ? 
                ClothingTypeDisplayString[flowStateData.currentUserMeasurement.clothingType] + ' ' : '')
             + "Custom Dari " + value,
            regularSize: value,
            bodyOrClothingMeasurements: "CLOTHING",
            creationMethod: "online-sizeteam",
            generatedMeasurements: BodyMeasurementsForSize[value],
            adjustedMeasurements: BodyMeasurementsForSize[value],
          }

          dispatch(inputMeasurementsForm(userMeasurements));
          delayedDispatch("dibuatinChat");

          setTimeout(() => {
            socket.timeout(5000).emit('chat message', {
              "chatUserId": flowSelectionData.chatUserId,
              "creationDateTime": new Date().toISOString(),
              "contentType": "STRING",
              "content": "Halo kak, ini dengan Ubay dari Studio S. 🙏😊",
              "author": "OTHER",
              "isAutomated": true, // For automated messages
            });

            setTimeout(() => {
              socket.timeout(5000).emit('chat message', {
                "chatUserId": flowSelectionData.chatUserId,
                "creationDateTime": new Date().toISOString(),
                "contentType": "STRING",
                "content": "Saya berpengalaman 3 tahun sebagai pattern maker, sebelum sekarang di studio s",
                "author": "OTHER",
                "isAutomated": true, // For automated messages
              });

              setTimeout(() => {
                socket.timeout(5000).emit('chat message', {
                  "chatUserId": flowSelectionData.chatUserId,
                  "creationDateTime": new Date().toISOString(),
                  "contentType": "STRING",
                  "content": "Saya lihat ini ukuran nya " + RegularSizeValueShortName[value] + " ya, boleh minta TB dan BB nya kak? 🙏",
                  "author": "OTHER",
                  "isAutomated": true, // For automated messages
                });
              }, 6000);
            }, 4000);
          }, 2000);
        }}
      />);
      break;
    case "dibuatinChat":
      children.push(
        <Box style={{
          position: 'fixed',
          width: '100vw',
          marginLeft: '-10vw',
        }}>
          <PageTailorChat clientVersion={true} />
        </Box>);
      break;
    case "favoriteOrAlgorithmSize":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('favoriteOrAlgorithmSizeBack');
          delayedDispatch("dibuatinAtauBuatSendiri");
        }}
      />);
      children.push(<OverlayViewSignUpAlgorithmOrFavoriteSize
        handleSubmit={(chooseAlgorithmSize: boolean) => {
          if (chooseAlgorithmSize) {
            Mixpanel.track('algorithmOrFavoriteSizeNext', {
              'answer': 'algorithm',
            });
            const payloadBody: UserClothTypeSpecificBodyMeasurements = {
              sizeType: SizeType.personal,
            }
            dispatch(inputMeasurementsForm(payloadBody));
            delayedDispatch("algorithmIntroduction");
            Mixpanel.track('algorithmSizeStart');
            sendWebsiteEventMessage("User chooses to create custom size using algorithm.");
          } else {
            Mixpanel.track('algorithmOrFavoriteSizeNext', {
              'answer': 'favorite',
            });
            const payloadBody: UserClothTypeSpecificBodyMeasurements = {
              sizeType: SizeType.personal,
            }
            dispatch(inputMeasurementsForm(payloadBody));
            delayedDispatch("favoriteSelectSize");
            Mixpanel.track('favoriteSizeStart');
            sendWebsiteEventMessage("User chooses to create custom size using favorite size.");
          }
        }
        }/>);
      break;
    case "favoriteSelectSize":
      // children.push(<BackButtonSegment
      //   handleBack={() => {
      //     Mixpanel.track('favoriteSelectSizeBack');
      //     const payloadBody2: UserClothTypeSpecificBodyMeasurements = {
      //       sizeType: undefined,
      //     }
      //     dispatch(inputMeasurementsForm(payloadBody2));
      //     delayedDispatch("favoriteOrAlgorithmSize");
      //   }}
      // />);
      children.push(<OverlayViewFavoriteSelectSize
        clothingType={flowStateData.currentUserMeasurement.clothingType as ClothingType}
        fitType={flowStateData.currentUserMeasurement.fitType as FitType}
        key2={flowStateData.currentUserMeasurement.clothingType + "-" + flowStateData.currentUserMeasurement.fitType}
        handleSubmit={(value) => {
          Mixpanel.track('favoriteSelectSizeNext', {
            'selectedSize': value,
          });
          let userMeasurements: UserClothTypeSpecificBodyMeasurements = {
            measurementName: "Ukuran " + 
              (flowStateData.currentUserMeasurement.clothingType ? 
                ClothingTypeDisplayString[flowStateData.currentUserMeasurement.clothingType] + ' ' : '')
             + "Custom Dari " + value,
            regularSize: value,
            bodyOrClothingMeasurements: "CLOTHING",
            creationMethod: "online-favorit",
            generatedMeasurements: BodyMeasurementsForSize[value],
            adjustedMeasurements: BodyMeasurementsForSize[value],
          }
          dispatch(inputMeasurementsForm(userMeasurements));
          delayedDispatch("favoriteModifySize");
        }}
      />);
      break;
    case "favoriteModifySize":
      children.push(<BackButtonSegment
        handleBack={() => {
          delayedDispatch("favoriteSelectSize");
        }}
      />);
      children.push(<OverlayViewFavoriteModifySize
        startingClothingBodyMeasurements={
          CalculateClothBodyMeasurementsFromBodyMeasurements(flowStateData.currentUserMeasurement.clothingType as ClothingType, 
            flowStateData.currentUserMeasurement.fitType as FitType, 
            BodyMeasurementsForSize[flowStateData.currentUserMeasurement.regularSize as RegularSizeValue], RegularSizeValue.L, [])}
        startingClothingMeasurements={
          RoundClothingMeasurementsToHalfPoint(
          CalculateClothingMeasurementsFromBodyMeasurements(flowStateData.currentUserMeasurement.clothingType as ClothingType, 
          flowStateData.currentUserMeasurement.fitType as FitType, 
          BodyMeasurementsForSize[flowStateData.currentUserMeasurement.regularSize as RegularSizeValue], RegularSizeValue.L))}
        clothingType={flowStateData.currentUserMeasurement.clothingType as ClothingType}
        fitType={flowStateData.currentUserMeasurement.fitType as FitType}
        key2={flowStateData.currentUserMeasurement.clothingType + "-" + flowStateData.currentUserMeasurement.fitType}
        productId={ flowSelectionData?.productDetail?.productId }
        productDetail={ flowSelectionData?.productDetail }
        handleSubmit={(value, valueClothing) => {
          Mixpanel.track('regularSelectSizeNext', {
            'selectedSize': value,
          });

          // DIRECT EXIT: SAVE MEASUREMENTS ARE REMOVED
          let clothingBodyMeas: BodyMeasurements = value;
          let clothingMeas: ClothingMeasurements = valueClothing;
          const userMeasurementsInput: UserClothTypeSpecificBodyMeasurements = {};
          userMeasurementsInput["clothingMeasurements"] = clothingMeas;
          userMeasurementsInput["generatedMeasurements"] = clothingBodyMeas;
          userMeasurementsInput["adjustedMeasurements"] = clothingBodyMeas;
          userMeasurementsInput.caraBuatString = "Ukuran Favorit (Custom)";
          dispatch(inputMeasurementsForm(userMeasurementsInput));

          delayedDispatch("favoriteNameScreen");
        }}
      />);
      break;
    case "favoriteNameScreen":
      children.push(<OverlayViewSaveMeasName 
        isAllowedBack={false}
        clothingType={flowSelectionData.clothingType as ClothingType}
        fitType={flowSelectionData.fitType as FitType}
        key2={flowSelectionData.clothingType + "-" + flowSelectionData.fitType}
        clothingMeasurements={flowStateData.currentUserMeasurement.clothingMeasurements}
        startingName={flowStateData.currentUserMeasurement.measurementName ? flowStateData.currentUserMeasurement.measurementName : 'Ukuran Custom'}
        handleSubmit={(measName) => {
          dispatch(inputMeasurementsForm({
            measurementName: measName
          }));

          sendWebsiteEventMessage("User favorite size save is skipped.");
          dispatch(postClothingMeasurement());

          dispatch(closeOverlayAndResetMethodSelectionCustom());
          dispatch(closeOverlayAndResetSelection());
          dispatch(closeOverlayAndReset());
        }}
        handleBack={() => {}}/>); // Mungkin mau diimplementasikan di sini juga nanti
      break;
    case "algorithmIntroduction": 
      // children.push(<BackButtonSegment
      //   handleBack={() => {
      //     Mixpanel.track('algorithmIntroductionBack');
      //     const payloadBody: UserClothTypeSpecificBodyMeasurements = {
      //       sizeType: undefined,
      //     }
      //     dispatch(inputMeasurementsForm(payloadBody));
      //     delayedDispatch("dibuatinAtauBuatSendiri");
      //   }}
      // />);
      children.push(<OverlayViewAlgorithmIntroduction
        handleSubmit={() => {
          Mixpanel.track('algorithmIntroductionNext', {
            'answer': 'algorithm',
          });
          delayedDispatch("algorithmSignUpStart");
        }
        }/>);
      break;
    case "algorithmSignUpStart":
      // children.push(<BackButtonSegment
      //   handleBack={() => {
      //     Mixpanel.track('algoritmSignUpStartBack');
      //     delayedDispatch("dibuatinAtauBuatSendiri");
      //   }}
      // />);
      children.push(<OverlayViewSignUpStart 
        handleSubmit={(values) => {
          Mixpanel.track('algorithmSignUpStartNext');
          if (!flowSelectionData.isSignedIn) {
            const payload = {
              name: values.givenName,
            };
            dispatch(updateState(payload));
          }
          const payloadBody: UserClothTypeSpecificBodyMeasurements = {
            nama: values.givenName,
            umur: values.age,
          }
          dispatch(inputMeasurementsForm(payloadBody));
          delayedDispatch("algorithmHeightWeight");
        }}
        titleNumber="01."/>);
      titleNumber = 1;
      break;
    case "algorithmHeightWeight":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('algorithmHeightWeightBack');
          if (!flowSelectionData.isSignedIn) {
            const payload = {
              name: undefined,
            };
            dispatch(updateState(payload));
          }
          const payloadBody: UserClothTypeSpecificBodyMeasurements = {
            nama: undefined,
            umur: undefined,
          }
          dispatch(inputMeasurementsForm(payloadBody));
          delayedDispatch("algorithmSignUpStart");
        }}
      />);
      children.push(<OverlayViewSignUpHeightWeight
        handleSubmit={(values) => {
          Mixpanel.track('algorithmHeightWeightNext');
          const payloadBody: UserClothTypeSpecificBodyMeasurements = {
            height: values.tinggi_badan,
            weight: values.berat_badan,
            measurementName: 'Ukuran ' 
            + ClothingTypeDisplayString[flowSelectionData.clothingType] 
            + ' (' + FitTypeDisplayString[flowSelectionData.fitType]
            + ') ' + flowStateData.currentUserMeasurement.nama
            + ' | ' + values.berat_badan + ' kg'
          }
          dispatch(inputMeasurementsForm(payloadBody));
          delayedDispatch("algorithmBodyType");
        }}
        titleNumber="02."/>);
      titleNumber = 2;
      break;
    case "algorithmBodyType":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('algorithmBodyTypeBack');
          const payloadBody: UserClothTypeSpecificBodyMeasurements = {
            height: undefined,
            weight: undefined,
            measurementName: undefined,
          }
          dispatch(inputMeasurementsForm(payloadBody));
          delayedDispatch("algorithmHeightWeight");
        }}
      />);
      children.push(<OverlayViewSignUpBodyType
        handleSubmit={(values) => {
          Mixpanel.track('algorithmBodyTypeNext');
          const payloadBody: UserClothTypeSpecificBodyMeasurements = {
            bodyType: values.bodyType,
          }
          dispatch(inputMeasurementsForm(payloadBody));

          // We pre-fetch before anything happens
          dispatch(fetchUserMeasurementEstimates());

          delayedDispatch("algorithmMeasurementEstimateLoading");
        }}
        titleNumber="03."/>);
      titleNumber = 3;
      break;
    case "algorithmMeasurementEstimateLoading":
      children.push(<OverlayViewSignUpMeasurementEstimateLoading
        onFetchMeasEstimateSuccess={() => {
          Mixpanel.track('algorithmMeasurementEstimateLoadingFinish');
          delayedDispatch("algorithmMeasurementEstimate");
          Mixpanel.track('personalMeasurementResultsStart', {
            'method': 'algorithm'
          });
          sendWebsiteEventMessage("User finishes personal size forms, user results displaying.");
        }}/>);
      break;
    case "algorithmMeasurementEstimate":
      children.push(<OverlayViewSignUpMeasurementEstimate
        userMeasurements={flowStateData.currentUserMeasurement}
        useClothingMeasurementDraft={true}
        hasFinishedResultsRating={flowStateData.hasFinishedResultsRating}
        handleSubmit={() => {
          if (!flowStateData.hasFinishedResultsRating) {
            dispatch(openResultRatingOverlay());
            sendWebsiteEventMessage("User clicks next, displaying results rating screen.");
            return;
          }
          sendWebsiteEventMessage("User clicks next, continuing to save.");
          Mixpanel.track('algorithmMeasurementEstimateNext', {
            'signedIn': 'false'
          })

          // DIRECT EXIT: SAVE MEASUREMENTS ARE REMOVED
          const userMeasurements: UserClothTypeSpecificBodyMeasurements = {};
          userMeasurements['bodyOrClothingMeasurements'] = "CLOTHING";
          userMeasurements["creationMethod"] = "online-smartsize";

          // NORMALIZE LINGKAR PERUT (MAKE BIGGER)
          userMeasurements["adjustedMeasurements"] = { ...flowStateData.currentUserMeasurement.adjustedMeasurements };
          if (userMeasurements?.["adjustedMeasurements"]?.["lingkarPerut"]) {
            userMeasurements["adjustedMeasurements"]["lingkarPerut"] = Math.round(userMeasurements["adjustedMeasurements"]["lingkarPerut"] / 0.916);
          }

          // FOR SMART SIZE, ADD INCREASES TO LINGKAR DADA, LINGKAR PERUT & LINGKAR LENGAN ATAS
          if (userMeasurements?.["adjustedMeasurements"]?.["lingkarDada"]) {
            userMeasurements["adjustedMeasurements"]["lingkarDada"] = Math.round(userMeasurements["adjustedMeasurements"]["lingkarDada"] * 1.05);
          }
          if (userMeasurements?.["adjustedMeasurements"]?.["lingkarPerut"]) {
            userMeasurements["adjustedMeasurements"]["lingkarPerut"] = Math.round(userMeasurements["adjustedMeasurements"]["lingkarPerut"] * 1.05);
          }
          if (userMeasurements?.["adjustedMeasurements"]?.["lingkarLenganBisep"]) {
            userMeasurements["adjustedMeasurements"]["lingkarLenganBisep"] = Math.round(userMeasurements["adjustedMeasurements"]["lingkarLenganBisep"] * 1.05);
          }

          // FOR SMART SIZE, SET LINGKAR PERUT MINIMUM EQUAL TO LINGKAR DADA
          if (userMeasurements?.["adjustedMeasurements"]?.["lingkarDada"] && 
            userMeasurements?.["adjustedMeasurements"]?.["lingkarPerut"] &&
            userMeasurements["adjustedMeasurements"]["lingkarDada"] < userMeasurements["adjustedMeasurements"]["lingkarPerut"]) {
            userMeasurements["adjustedMeasurements"]["lingkarPerut"] = userMeasurements["adjustedMeasurements"]["lingkarDada"];
          }

          dispatch(inputMeasurementsForm(userMeasurements));

          sendWebsiteEventMessage("User personal size save is skipped.");
          dispatch(postClothingMeasurement());

          dispatch(closeOverlayAndResetMethodSelection());
          dispatch(closeOverlayAndResetSelection());
          dispatch(closeOverlayAndReset());
        }}/>);
      break;
    case "regularSelectSize":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('regularSelectSizeBack');
          const payload: UserClothTypeSpecificBodyMeasurements = {
            sizeType: undefined,
          }
          dispatch(inputMeasurementsForm(payload));
          delayedDispatch("personalOrRegularSize");
        }}
      />);
      children.push(<OverlayViewRegularSelectSize
        productId={ flowSelectionData?.productDetail?.productId }
        clothingType={flowStateData.currentUserMeasurement.clothingType as ClothingType}
        fitType={flowStateData.currentUserMeasurement.fitType as FitType}
        key2={flowStateData.currentUserMeasurement.clothingType + "-" + flowStateData.currentUserMeasurement.fitType}
        handleSubmit={(value) => {
          Mixpanel.track('regularSelectSizeNext', {
            'selectedSize': value,
          });
          let userMeasurements: UserClothTypeSpecificBodyMeasurements = {
            measurementName: value,
            regularSize: value,
            bodyOrClothingMeasurements: "CLOTHING",
            creationMethod: "online-reguler",
          }
          dispatch(inputMeasurementsForm(userMeasurements));

          dispatch(postClothingMeasurement());

          dispatch(closeOverlayAndReset());
        }}
      />);
      break;
  }

  if (flowStateData.titleNumber != titleNumber) {
    flowStateCallback.setTitleNumber(titleNumber);
  }

  return children;
}
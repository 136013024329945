import { Box, Collapse, Container, Fade, Grid, Slide } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { SetStateAction } from 'react';
import { categoryList, categoryMap, categoryMapEvent } from 'common-ts/dist/models/CategoryList';

import MobileCategoryMenuItem from './MobileCategoryMenuItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuWindow: {
      display: 'block',
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: '#fafafa',
      zIndex: 2,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

interface MobileCategoryMenuProps {
  navState: string,
}

function MobileCategoryMenu({ navState }: MobileCategoryMenuProps) {
  const classes = useStyles();

  return (
    <Slide in={navState === "MENU"}>
      <Box className={classes.menuWindow}>
        <Toolbar/>
        {
          categoryList.map((value, index) => {
            return <MobileCategoryMenuItem 
              navState={navState}
              title={value} 
              routeLink={categoryMap[value]} 
              eventName={categoryMapEvent[value]} />;
          })
        }
      </Box>
    </Slide>
  );
}

export default MobileCategoryMenu;
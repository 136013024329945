import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PageCareerHeader from './PageCareers1Header';
import PageCareerAbout from './PageCareers2About';
import PageCareerMission from './PageCareers3Mission';
import PageCareerMidBanner from './PageCareers4MidBanner';
import PageCareerValue1 from './PageCareers5Value1';
import PageCareerValue2 from './PageCareers6Value2';
import PageCareerValue3 from './PageCareers7Value3';
import PageCareerDivisions from './PageCareers8Divisions';
import PageCareerCTA from './PageCareers9CTA';
import PageCareerCareerFooter from './PageCareers10CareerFooter';
import NavBarCareers from './PageCareersNavBarCareers';
import { useRef } from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  careerPageRoot: {
  },
}),
);

export default function PageCareers() {
  const classes = useStyles();

  const refAbout = useRef(null);
  const executeScrollAbout = () => (refAbout as any).current.scrollIntoView({ behavior: 'smooth', block: 'end' })    

  const refWorkWithUs = useRef(null);
  const executeScrollWorkWithUs = () => (refWorkWithUs as any).current.scrollIntoView({ behavior: 'smooth', block: 'start' })    

  const refVacancies = useRef(null);
  const executeScrollVacancies= () => (refVacancies as any).current.scrollIntoView({ behavior: 'smooth', block: 'end' })      

  const refWhatWeDo = useRef(null);
  const executeScrollWhatWeDo= () => (refWhatWeDo as any).current.scrollIntoView({ behavior: 'smooth', block: 'end' })    

  const elements: JSX.Element[] = [];
  elements.push(
    <NavBarCareers transparentAtTop={true} coloredAtTop={false}  largerTitleAtTop={true} transparent={false}
    executeScrollAbout={executeScrollAbout}
    executeScrollWorkWithUs={executeScrollWorkWithUs}
    executeScrollVacancies={executeScrollVacancies}
    executeScrollWhatWeDo={executeScrollWhatWeDo}
    />)

  elements.push(
    <Box className={classes.careerPageRoot}>
      <PageCareerHeader scrollToFunction={executeScrollVacancies} />
      <PageCareerAbout />
      <div ref={refAbout}>
      </div>
      <PageCareerMission />
      <div ref={refWorkWithUs}>
      </div>
      <PageCareerValue1 />
      <PageCareerValue2 />
      <PageCareerDivisions />
      <div ref={refWhatWeDo}>
      </div>
      <PageCareerCTA />
      <div ref={refVacancies}>
      </div>
    </Box>
  );

  return (
    <Box>
      {elements}
    </Box>
  )
}
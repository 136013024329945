import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Mixpanel } from '../mixpanel';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import NavBar from './NavBar';
import PageHomeHeader from './PageHome1HeaderVideo';
import PageHomeSesuaiUkuran from "./PageHome2SesuaiUkuran";
import PageHomePenampilanTerbaik from './PageHome2PenampilanTerbaik';
import PageHomeCaraBelanja from './PageHome3CaraBelanja';
import PageHomeKataMereka from "./PageHome3KataMereka";
import PageHomeCustomSizeSemuaBagus from './PageHome3CustomSizeSemuaBagus';
import PageHomeCustomSizeTanpaUkur from './PageHome5PurchaseSteps';
import PageHomeShopOnlineFreely from './PageHome2ShopOnlineFreely';
import PageHomeBanner from './PageHome3Banner';
import PageHomeSLA from './PageHome5SLA_Iter7';
import PageHomeGaransiPastiPas from './PageHome5GaransiPastiPas';
import PageHomePurchaseSatuPersonalSize from './PageHome5PurchaseSatuPersonalSize';
import PageHomeInstagramPosts from './PageHome6InstagramPosts';
import PageHomeJustIn from './PageHome8JustIn';
import PageHomeLastToOrder from './PageHome9LastToOrder';
import PageHomeBelanjaSekarang from './PageHome5BelanjaSekarang';
import PageHomeRandomItems from './PageHome9RandomItems';
import { Redirect } from 'react-router-dom';
import { categoryMap } from 'common-ts/dist/models/CategoryList';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  homePageRoot: {
  },
}),
);

export default function PageHome() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const authState = useAppSelector(state => state.account.authState);
  const isLoggedIn = authState === 'signedin';

  const openCreateNewSize = () => {
    (window as any).dataLayer.push({'event': 'homePageSizeCreateNew'});
    Mixpanel.track('homePageSizeCreateNew', {
    });
  }

  return (
    <Redirect to={categoryMap["SHOP"]} />
  )

  const elements: JSX.Element[] = [];
  elements.push(
    <NavBar transparentAtTop={true} coloredAtTop={true}  largerTitleAtTop={true} transparent={true}/>)

  elements.push(
    <Box className={classes.homePageRoot}>
      <PageHomeHeader/>
      <PageHomeSesuaiUkuran/>
      <PageHomeKataMereka/>
      <PageHomeCustomSizeSemuaBagus/>
      <PageHomeCustomSizeTanpaUkur/>
      <PageHomeSLA/>
      <PageHomeBelanjaSekarang/>
    </Box>
  );

  return (
    <Box>
      {elements}
    </Box>
  )
}
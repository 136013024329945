import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Link } from '@material-ui/core';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Link as RouterLink } from "react-router-dom";
import React from 'react';
import { useEffect } from 'react';
import PreloadImage from './ImagePreloader';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import { Mixpanel } from '../mixpanel';

interface StyleProps {
  justifyContent: string
}

const useStyles = ({justifyContent}: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  productListItem: {
    width: '100%',
    display: 'flex',
    justifyContent: justifyContent,
    flexWrap: 'wrap',
  },
}),
);

interface PageProductItemProps {
  content: JSX.Element[]
  justifyContent?: string // determines how the children is distributed.
}

export default function PageElementContainerList({content,justifyContent} : PageProductItemProps) {
  justifyContent = justifyContent ? justifyContent : 'space-around';
  const classes = useStyles({justifyContent})();

  return (
    <Box className={classes.productListItem}>
      {
        content
      }
    </Box>
  );
}
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { SizeType } from 'common-ts/dist/models/Cart';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentTwoChoices from './OverlayComponentChoiceTwo';
import OverlayComponentForm from './OverlayComponentForm';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  optionGridItemSpacer: {
    minHeight: '80px',
    margin: '0px 16px 20px 0px',
  },
  optionGridItemContainer: {
    border: '1px solid #E3E3E3',
    borderRadius: '34px',
    padding: '24px 16px 24px 22px',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
  optionGridItemContainerSelected: {
    border: '1px solid #828282',
    borderRadius: '34px',
    padding: '24px 16px 24px 22px',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
  optionBox: {
    marginTop: '6px',
    minHeight: '14px',
    width: '14px',
    backgroundColor: '#E4E4E4',
    borderRadius: '14px',
  },
  optionBoxSelected: {
    marginTop: '6px',
    minHeight: '14px',
    width: '14px',
    backgroundColor: '#232323',
    borderRadius: '14px',
  },
  optionTitle: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#343434',
    fontSize: '4.7vw',
    marginBottom: '13px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
    },
  },
  optionSubtitle: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 300,
    color: '#737373',
    fontSize: '2.4vw',
    lineHeight: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '10px',
    },
  },
  optionSubtitleEmphasis: {
    fontWeight: 500,
    fontSize: '11px',
    color: '#343434',
    marginTop: '10px',
    lineHeight: '18px',
  },
  title: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#232323',
    fontSize: '26px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '22px',
    },
    marginBottom: '18px',
  },
  subtitle: {
    fontFamily: 'Raleway',
    fontWeight: 300,
    color: '#000000',
    fontSize: '13.71px',
    lineHeight: '20.57px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px',
    },
    marginBottom: '32px',
  }
}),
);

interface Props {
  handleSubmit: (choosePersonalSize: boolean) => void
}

export default function Page({ handleSubmit }: Props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<SizeType | undefined>(undefined);

  function handleSubmitFunction(e: any) {
    e.preventDefault();
    handleSubmit(selected === SizeType.personal);
  }

  let children: JSX.Element[] = [];
  let submitChildren: JSX.Element[] = [];

  children.push(
    <OverlayComponentBaseContainerHorizontal>
      <OverlayComponentBaseContainerWidth
        widthMdUp="100%"
        widthSmDown="100%">
        <Typography className={classes.title}>
          Pilih Tipe <br></br>Ukuran Kamu
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}
            onClick={() => {
              setSelected(SizeType.regular);
            }}>
            <Box className={classes.optionGridItemSpacer}>
              <Grid container className={selected === SizeType.regular ? classes.optionGridItemContainerSelected : classes.optionGridItemContainer}>
                <Grid item xs={1}>
                  <div className={selected === SizeType.regular ? classes.optionBoxSelected : classes.optionBox}></div>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{
                    marginLeft: '12px',
                    marginRight: '10px',
                  }}>
                    <Typography className={classes.optionTitle}>
                      Ukuran Reguler
                    </Typography>
                    <Typography className={classes.optionSubtitle}>
                      Dapatkan Personal Measurement Kit<sup>TM</sup> di setiap pembelian pertama.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={process.env.PUBLIC_URL + '/icon-selection-uk-reguler.svg'}
                    style={{
                      width: '100%',
                      paddingTop: '12px',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}
            onClick={() => {
              setSelected(SizeType.personal);
            }}>
            <Box className={classes.optionGridItemSpacer}>
              <Grid container className={selected === SizeType.personal ? classes.optionGridItemContainerSelected : classes.optionGridItemContainer}>
                <Grid item xs={1}>
                  <div className={selected === SizeType.personal ? classes.optionBoxSelected : classes.optionBox}></div>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{
                    marginLeft: '12px',
                    marginRight: '10px',
                  }}>
                    <Typography className={classes.optionTitle}>
                      Ukuran Custom
                    </Typography>
                    <Typography className={classes.optionSubtitle}>
                      Buat dalam waktu 5 menit, tanpa perlu ukur badan. Tanpa biaya tambahan.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={process.env.PUBLIC_URL + '/icon-selection-uk-custom.svg'}
                    style={{
                      width: '100%',
                      paddingTop: '12px',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </OverlayComponentBaseContainerWidth>
    </OverlayComponentBaseContainerHorizontal>
  );
  submitChildren.push(
    <OverlayComponentBaseContainerHorizontal
    alignItems='flex-end' >
      <Box>
        {
          selected !== undefined ? 
          <OverlayComponentButton
            formButtonText="Next"
            isTypeSubmit={true}
            buttonMarginTopBottom="10px"
            buttonMarginLeftRight="20px"/> :
          null
        }
      </Box>
    </OverlayComponentBaseContainerHorizontal>
  )

  return (
    <OverlayComponentForm
      formTitle=""
      formSubmitBody={submitChildren}
      formFormBody={children}
      formHandleSubmit={handleSubmitFunction}
      titleWidthMdUp='560px'
      titleWidthSmDown='100%'
      formWidthMdUp='560px'
      formWidthSmDown='100%'
      formJustifyContent='space-evenly'
      formAlignItems='center'
    />
  );
}
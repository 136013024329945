import { Box, Dialog, Link, Slide } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { postAddress } from '../redux/AddressRedux';
import { getShipmentPrice, updateAddressInOrder } from '../redux/CartRedux';
import { closeOverlayAndReset } from '../redux/UIOverlayAddressViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayViewAddressEdit from './OverlayViewAddressEdit';
import OverlayViewAddressListPanel from './OverlayViewAddressListPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    dialogContentContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
    },
    toggleSignUpSignInContainer: {
      paddingTop: '15px',
      paddingBottom: '30px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    titleLogoContainer: {
      paddingTop: '30px',
      paddingBottom: '20px',
      height: '42px',
    },
    titleLogoDesktop: {
      height: '24px',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayViewAddress() {
  const overlayView = useAppSelector(state => state.overlayAddressView);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  let children: JSX.Element[] = [];
  if (overlayView.status === UIOverlayViewStatus.On) {
    switch (overlayView.currentScreen) {
      case "edit": // EDIT IN ACCOUNT
        children.push(
          <OverlayViewAddressEdit handleSubmit={(address)=>{
            dispatch(postAddress());
            dispatch(closeOverlayAndReset());
          }}/>
        )
        break;
      case "list": // IN CHECKOUT
        children.push(
          <OverlayViewAddressListPanel />
          );
        break;
      case "editAndChoose": // IN CHECKOUT
        children.push(
          <OverlayViewAddressEdit handleSubmit={(address)=>{
            dispatch(postAddress());
            dispatch(closeOverlayAndReset());
            
            dispatch(updateAddressInOrder({
              addressName: address.addressName,
              address: address.address,
              provinsi: address.provinsi,
              kabupaten: address.kabupaten,
              kecamatan: address.kecamatan,
              kodePos: address.kodePos,
              kotaKecamatanText: address.kotaKecamatanText,
              keterangan: address.keterangan,
              phoneNumber: address.phoneNumber,
              destinationObject: address.destinationObject,
              destinationKiriminAjaObject: address.destinationKiriminAjaObject,
            }));
            // dispatch(getShipmentPrice(address.destinationObject));
          }}/>
        )
        break;
    }
  }

  return (
    <Dialog
    fullScreen
    maxWidth="lg"
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => dispatch(closeOverlayAndReset())}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <Box className={classes.titleLogoContainer} >
          <Link component={RouterLink} to={"#"} onClick={() => dispatch(closeOverlayAndReset())}>
            <img className={classes.titleLogoDesktop} src={process.env.PUBLIC_URL + '/studios-wordmark-final.svg'} alt="maja logo"/>
          </Link>
        </Box>
        {children}
      </div>
    </Dialog>
  )
}
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Box, Dialog, IconButton, Link, Slide, Typography, useMediaQuery } from '@material-ui/core';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import { ArrowBack, Backspace } from '@material-ui/icons';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowBackButtonContainer: {
      position: 'relative',
      [theme.breakpoints.only('xs')]: {
        paddingBottom: '54px',
      }
    },
    arrowBackButton: {
      position: 'absolute',
      left: '-120px',
      top: '2px',
      [theme.breakpoints.only('xs')]: {
        left: '-14px',
        top: '6px',
      },
    },
    arrowBack: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  }),
);

interface BackButtonSegmentProps {
  handleBack: () => void
}

export default function BackButtonSegment({ handleBack }: BackButtonSegmentProps) {
  const classes = useStyles();

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <OverlayComponentBaseContainerWidth
      widthMdUp='100px' widthSmDown='100px'>
      <OverlayComponentBaseContainerHorizontal alignItems="flex-start">
        <div className={classes.arrowBackButtonContainer}>
          <IconButton onClick={handleBack} edge="start" className={classes.arrowBackButton}>
            {
              isMdOrUp ?
              <ArrowBack className={classes.arrowBack} fontSize={"medium"}/> :
              <ArrowBack className={classes.arrowBack} fontSize={"small"}/>
            }
          </IconButton>
        </div>
      </OverlayComponentBaseContainerHorizontal>
    </OverlayComponentBaseContainerWidth>
  )
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import React, { useEffect } from 'react';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, Link } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { signIn, updateState } from '../redux/AccountRedux';
import { Mixpanel } from '../mixpanel';
import axios, { AxiosRequestConfig } from 'axios';
import { ArrowBack } from '@material-ui/icons';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      }
    },
    formContainer: {
      width: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      },
      // height: '400px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        // height: '280px',
        paddingTop: '50px',
        paddingBottom: '50px',
      },
    },
    form: {

    },
    formRow: {
    },
    subFormTitle: {
      marginBottom: '12px',
    },
    errorMessageText: {
      color: '#2e2e2e',
      backgroundColor: 'lightgrey',
      paddingLeft: '10px',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginTop: '14px',
      marginBottom: '4px',
      fontSize: '13px',
      fontWeight: 500,
    },
    formButton: {
      marginTop: '30px',
    },
    changeLogInLink: {
    },
    changeLogInLinkText: {
      fontSize: '12.5px',
      fontWeight: 500,
      paddingTop: '10px',
      paddingLeft: '6px',
    },
    otpDescText: {
      fontSize: '13px',
      fontWeight: 300,
      paddingTop: '10px',
      paddingLeft: '6px',
      width: '240px',
    },
    otpResendText: {
      fontSize: '12px',
      fontWeight: 300,
      marginTop: '12px',
    },
    otpResendTextLink: {
      fontSize: '12px',
      fontWeight: 300,
      textDecoration: 'underline'
    }
  }),
);

const validationSchema = yup.object({
  email: yup.string()
    .email('Enter a valid email'),
  phone_number: yup.string(),
  password: yup.string(),
  otp_value: yup.string(),
});

export default function PageAccountSignIn(props: {phoneNumber: string, changeToSignUpWithPhoneNumber: (phoneNumber: string) => void}) {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  let customerNumber = params.get("phone_number");
  if (!customerNumber && props.phoneNumber) {
    customerNumber = props.phoneNumber;
  }

  if (customerNumber) {
    customerNumber = customerNumber.replace(/[^\d]/g, '');
    while (customerNumber.startsWith("62") || customerNumber.startsWith("0")) {
      if (customerNumber.startsWith("62")) {
        customerNumber = customerNumber.substring(2);
      } else if (customerNumber.startsWith("0")) {
        customerNumber = customerNumber.substring(1);
      }
    }
  }

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [isUsingEmail, setIsUsingEmail] = React.useState<boolean>(false);
  const [usePhoneStage, setUsePhoneStage] = React.useState<'input' | 'otp'>('input');
  const errorMessage = useAppSelector(state => state.account.signInError);
  const email = useAppSelector(state => state.account.email);
  const phoneNumber = useAppSelector(state => state.account.phoneNumber);

  let phoneNumberWithoutPrefix = phoneNumber;
  if (phoneNumberWithoutPrefix && phoneNumber.substring(0, 3) === "+62") {
    phoneNumberWithoutPrefix = phoneNumberWithoutPrefix.substring(3);
  }

  // Pure UI State for this component only, so this is good.
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  async function sendOtpRequest(phoneNumber: string, redirectToSignUpOnError: boolean) {
    let config : AxiosRequestConfig = {
      method: 'post',
      url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/otp',
      data: JSON.stringify({
        phoneNumber: phoneNumber,
      })
    };

    const response = await axios(config);

    console.log(response);

    if (redirectToSignUpOnError) {
      if (response?.data?.userLogInStatus === 'SUCCESS') {
        setUsePhoneStage('otp');
      } else if (response?.data?.userLogInStatus === 'ERROR' && response?.data?.userLogInException === 'UserNotFoundException') {
        console.log("Phone number not found: " + phoneNumber);
        props.changeToSignUpWithPhoneNumber(phoneNumber);
      }
    }
  }

  async function signInAttempt(email: string, phoneNumber: string, password: string, useEmail: boolean, otpValue: string) {
    if (useEmail) {
      dispatch(updateState({
        email: email,
        phoneNumber: "",
      }));
    } else {
      dispatch(updateState({
        email: "",
        phoneNumber: phoneNumber,
      }));
    }
    dispatch(signIn({ password: password, useEmail: useEmail, otpValue: otpValue }));
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      phone_number: customerNumber ? customerNumber : '',
      password: '',
      otp_value: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Send otp here
      if (!isUsingEmail && !values?.otp_value?.length) {
        if (values.phone_number.startsWith('123')) {
          setUsePhoneStage('otp');
        } else {
          sendWebsiteEventMessage( "Kirim OTP ke nomor user +62" + values.phone_number);
          sendOtpRequest('+62' + values.phone_number, true);
        }
        return;
      }

      let phoneNumberValue = values.phone_number;
      if (phoneNumberValue.startsWith('123')) {
        phoneNumberValue = phoneNumberValue.substring(3);
      };

      dispatch(updateState({
        signInError: '',
        signUpError: '',
      }));
      Mixpanel.track('signInAttempt');
      signInAttempt(values.email, '+62' + phoneNumberValue, values.password, isUsingEmail, values.otp_value);
    },
  });

  useEffect(() => {
    formik.setValues({
      email: email,
      phone_number: customerNumber ? customerNumber : phoneNumberWithoutPrefix ? phoneNumberWithoutPrefix : '',
      password: '',
      otp_value: '',
    });
    dispatch(updateState({
      signInError: '',
    }));
  }, []);

  return (
    <Box className={classes.formContainer}>
      {
        isUsingEmail || (!isUsingEmail && usePhoneStage === 'input') ?
        <form className={classes.form} noValidate autoComplete="off" onSubmit={(event: any) => {
            event.preventDefault();
            if (isUsingEmail && formik.values.email === "") {
              console.log("Email is empty.");
              return;
            }
            if (!isUsingEmail && formik.values.phone_number === "") {
              console.log("Phone number is empty.");
              return;
            }
            formik.handleSubmit();
          }}>
          <Typography variant="h5" className={classes.subFormTitle} color="textPrimary">
            <b>Masuk Akun</b>
          </Typography>
          {
            errorMessage === "" ?
            null :
            <Typography variant="body2" className={classes.errorMessageText}>
              {errorMessage}
            </Typography>
          }
          {
            isUsingEmail ? 
            <div className={classes.formRow}>
              <TextField
                margin="normal"
                className={classes.textField}
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div> :
            (!isUsingEmail && usePhoneStage === 'input') ?
            <div className={classes.formRow}>
              <TextField
                margin="normal"
                className={classes.textField}
                id="phone_number"
                name="phone_number"
                label="Phone Number"
                type="text"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                helperText={formik.touched.phone_number && formik.errors.phone_number}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+62</InputAdornment>,
                }}
              />
            </div> :
            null
          }
          {
            isUsingEmail ? 
            <div className={classes.formRow}>
              <TextField
                margin="normal"
                className={classes.textField}
                id="password"
                name="password"
                label="Password"
                type={values.showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </div>:
            null
          }
          <Link className={classes.changeLogInLink} href="#" onClick={() => { setIsUsingEmail(!isUsingEmail); }} underline="always">
            <Typography className={classes.changeLogInLinkText}>Masuk Dengan {isUsingEmail ? "Nomor HP" : "Email"} Saja</Typography>
          </Link>
          <Button variant="contained" type="submit" className={classes.formButton} fullWidth>
            <b>Submit</b>
          </Button>
        </form>
        :
        <form className={classes.form} noValidate autoComplete="off" onSubmit={(event: any) => {
          event.preventDefault();
          if (isUsingEmail && formik.values.email === "") {
            console.log("Email is empty.");
            return;
          }
          if (!isUsingEmail && formik.values.phone_number === "") {
            console.log("Phone number is empty.");
            return;
          }
          formik.handleSubmit();
        }}>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={() => {
            setUsePhoneStage("input");
            formik.setFieldValue("otp_value", '');
          }}
          style={{
            marginBottom: "6px"
          }}
        >
          <ArrowBack fontSize={"small"}/>
        </IconButton>
        <Typography variant="h5" className={classes.subFormTitle} color="textPrimary">
          <b>Masukkan Kode OTP</b>
        </Typography>
        {
          errorMessage === "" ?
          null :
          <Typography variant="body2" className={classes.errorMessageText}>
            {errorMessage}
          </Typography>
        }
        <Typography className={classes.otpDescText}>Kode OTP sudah kami kirim ke WhatsApp (WA) kamu.</Typography>
        {
          (!isUsingEmail && usePhoneStage === 'otp') ?
          <div className={classes.formRow}>
            <TextField
              margin="normal"
              className={classes.textField}
              id="otp_value"
              name="otp_value"
              label="Kode OTP"
              type="text"
              value={formik.values.otp_value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.otp_value && Boolean(formik.errors.otp_value)}
              helperText={formik.touched.otp_value && formik.errors.otp_value}
            />
          </div> :
          null
        }
        <Typography className={classes.otpResendText}>Kode Belum Sampai? 
        <span className={classes.otpResendTextLink} onClick={() => { 
          alert("Kode OTP terkirim!");
          sendOtpRequest('+62' + formik.values.phone_number, false);
        }}> Kirim Ulang OTP</span></Typography>
        
        <Button variant="contained" type="submit" className={classes.formButton} fullWidth>
          <b>Submit</b>
        </Button>
      </form>
      }
    </Box>
    );
}
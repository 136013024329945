import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { Close, Star, StarHalf, StarOutline } from '@material-ui/icons';
import { useFormik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import * as yup from 'yup';
import OverlayComponentForm from './OverlayComponentForm';
import OverlayComponentButton, { FareastButton } from './OverlayComponentButton';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  introductionContainer: {
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center', 
  },
  titleText: {
    fontSize: '5vw',
    fontWeight: 600,
    paddingTop: "16vw",
    paddingBottom: "6vw",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8vw',
      paddingTop: "3vw",
      paddingBottom: "1.6vw",
    }
  },
  errorRatingText: {
    textAlign: "center",
    fontSize: '3vw',
    fontWeight: 300,
    color: '#ff4545',
    [theme.breakpoints.up('md')]: {
      fontSize: '1vw',
    }
  },
  submitChidrenContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    padding: "0 4vw",
    [theme.breakpoints.up('md')]: {
      padding: '0 1.5vw',
    }
  },
  starsContainer: {
    width: "70vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.up('md')]: {
      width: '26vw',
    }
  },
  starsSvgFilled: {
    width: "6vw",
    height: "6vw",
    color: "orange",
    [theme.breakpoints.up('md')]: {
      width: '2.25vw',
      height: '2.25vw',
    }
  },
  starsSvg: {
    width: "6vw",
    height: "6vw",
    [theme.breakpoints.up('md')]: {
      width: '2.25vw',
      height: '2.25vw',
    }
  },
  resultRatingSubtextContainer: {
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  resultRatingSubtext: {
    fontSize: '3vw',
    fontWeight: 400,
    textAlign: 'center',
    color: "#454545",
    [theme.breakpoints.up('md')]: {
      fontSize: '1vw',
    }
  }
}),
);

interface StarsInputElementProps {
  stars: number
  onStarClicked: (stars: number) => void
}

function StarsInputElement({stars, onStarClicked}: StarsInputElementProps) {
  const classes = useStyles();

  return (
    <Box className={classes.starsContainer}>
      <IconButton onClick={() => {onStarClicked(1)}}>
        {
          stars >= 1 ? 
          <Star className={classes.starsSvgFilled}/> : 
          stars >= 0.5 ?
          <StarHalf className={classes.starsSvgFilled} /> :
          <StarOutline className={classes.starsSvg} />
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(2)}}>
        {
          stars >= 2 ? 
          <Star className={classes.starsSvgFilled}/> : 
          stars >= 1.5 ?
          <StarHalf className={classes.starsSvgFilled}/> :
          <StarOutline className={classes.starsSvg}/>
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(3)}}>
        {
          stars >= 3 ? 
          <Star className={classes.starsSvgFilled}/> : 
          stars >= 2.5 ?
          <StarHalf className={classes.starsSvgFilled}/> :
          <StarOutline className={classes.starsSvg}/>
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(4)}}>
        {
          stars >= 4 ? 
          <Star className={classes.starsSvgFilled}/> : 
          stars >= 3.5 ?
          <StarHalf className={classes.starsSvgFilled}/> :
          <StarOutline className={classes.starsSvg}/>
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(5)}}>
        {
          stars >= 5 ? 
          <Star className={classes.starsSvgFilled}/> : 
          stars >= 4.5 ?
          <StarHalf className={classes.starsSvgFilled}/> :
          <StarOutline className={classes.starsSvg}/>
        }
      </IconButton>
    </Box>
  )
}

const validationSchema = yup.object({
  stars: yup.number()
  .min(1, '')
  .required('Rating anda dibutuhkan'),
});

interface Props {
  handleSubmit: (ratingValue: number) => void
}

export default function PageResultsRating({ handleSubmit }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  const formik = useFormik({
    initialValues: {
      stars: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values.stars);
    },
  });

  let children: JSX.Element[] = [];

  children.push(
    <Typography className={classes.titleText}>
      Seberapa akurat (sesuai)<br></br>hasil ini untuk kakak?
    </Typography>
  );

  children.push(
    <Box>
      {
        formik.errors.stars ? 
        <Typography className={classes.errorRatingText}>
          {formik.errors.stars}
        </Typography> :
        null
      }
      <StarsInputElement
        stars={formik.values.stars}
        onStarClicked={(stars) => {
          sendWebsiteEventMessage("User menilai results custom fit dengan click bintang " + stars);
          formik.setFieldValue("stars", stars);
        }}/>
      <Box className={classes.resultRatingSubtextContainer}>
        <Typography className={classes.resultRatingSubtext}>
          Tidak<br></br>Akurat
        </Typography>
        <Typography className={classes.resultRatingSubtext}>
          Sangat<br></br>Akurat
        </Typography>
      </Box>
    </Box>
  );

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <Box className={classes.buttonContainer}>
      <OverlayComponentButton
        isTypeSubmit={false}
        handleClick={() => {
          if (formik.values.stars === 0) {
            formik.setFieldError("stars", "Rating anda dibutuhkan.");
            return;
          }
          formik.handleSubmit();
        }}
        formButtonText="SUBMIT"
        buttonMarginTopBottom="30px"
        buttonMarginLeftRight="0px"
      />
    </Box>
  )

  return (
    <Box className={classes.introductionContainer}>
      {children}
      <Box className={classes.submitChidrenContainer}>
        {submitChildren}
      </Box>
    </Box>
  );
}
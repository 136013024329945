import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useEffect, useRef } from 'react';
import React from 'react';
import { ClothingMeasurementAdjustmentDisplayRange, ClothingMeasurements, ClothingType, FitType, IsEqualClothingMeasurements, NextStepClothingMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { RegularSizeValue, RegularSizeValueShortName } from 'common-ts/dist/models/Cart';

export const getSizeMeasurementsAndNamesWithinRange = (clothMeasName: string, clothMeasValue: number, rangeOfClothMeas: number, 
  sizeMeasValues: ClothingMeasurements[], sizeMeasNames: RegularSizeValue[]) => {

  const resultData: {
    shortName: string
    lengthOfClothMeas: number
    focusRecommended: boolean
    isEqual: boolean
  }[] = [];

  if (sizeMeasValues.length !== sizeMeasNames.length) {
    return resultData;
  }

  let lastLengthAdded = -1; // Remove duplicates

  for (var i = 0; i < sizeMeasValues.length; i++) {
    const sizeClothMeasValue = sizeMeasValues[i]?.[clothMeasName as keyof ClothingMeasurements];
    if (sizeClothMeasValue &&
      sizeClothMeasValue !== lastLengthAdded) {
      resultData.push({
        shortName: RegularSizeValueShortName[sizeMeasNames[i]],
        lengthOfClothMeas: sizeClothMeasValue,
        focusRecommended: (clothMeasValue - rangeOfClothMeas <= sizeClothMeasValue &&
        sizeClothMeasValue <= clothMeasValue + rangeOfClothMeas),
        isEqual: clothMeasValue === sizeClothMeasValue,
      })
      lastLengthAdded = sizeClothMeasValue;
    }
  }

  return resultData;
}

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  canvasContainer: {
    width: '100%',
    height: '100%',
  },
  canvas: {
    width: '100%',
    height: '100%',
  },
}),
);

const drawArrow = (ctx: any, fromx: number, fromy: number,
  tox: number, toy: number, headlen: number) => {
  var dx = tox - fromx;
  var dy = toy - fromy;
  var angle = Math.atan2(dy, dx);
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy);
  ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
  ctx.moveTo(tox, toy);
  ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
}
const drawArrowBi = (ctx: any, fromx: number, fromy: number,
  tox: number, toy: number, headlen: number) => {
  ctx.stroke()
  ctx.beginPath();
  const oriStokeStyle = ctx.strokeStyle;
  const oriFillStyle = ctx.fillStyle;
  ctx.strokeStyle = '#666666';
  ctx.fillStyle = '#363636';

  var dx = tox - fromx;
  var dy = toy - fromy;
  var angle = Math.atan2(dy, dx);
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy);
  ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 9), toy - headlen * Math.sin(angle - Math.PI / 9));
  ctx.moveTo(tox, toy);
  ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 9), toy - headlen * Math.sin(angle + Math.PI / 9));
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(fromx + headlen * Math.cos(angle - Math.PI / 9), fromy + headlen * Math.sin(angle - Math.PI / 9));
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(fromx + headlen * Math.cos(angle + Math.PI / 9), fromy + headlen * Math.sin(angle + Math.PI / 9));

  ctx.stroke()
  ctx.beginPath();
  ctx.strokeStyle = oriStokeStyle;
  ctx.fillStyle = oriFillStyle;
}
const drawGuideline = (ctx: any, fromx: number, fromy: number,
  tox: number, toy: number, text: string, textX: number, textY: number, fontSize: string, isFocused: boolean, isEqual: boolean) => {
  ctx.stroke()
  ctx.beginPath();
  const oriStokeStyle = ctx.strokeStyle;
  const oriFillStyle = ctx.fillStyle;
  const oriFont = ctx.font;
  const oriLineDash = ctx.getLineDash();
  ctx.strokeStyle = isEqual ? '#14af2e' : isFocused ? '#8c8c8c' : '#dfdfdf';
  ctx.fillStyle = isEqual ? '#14af2e' : '#343434';
  ctx.setLineDash([10, 10]);

  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy)
  if (isFocused) {
    ctx.font = "normal normal bold " + fontSize;
    ctx.fillText(text, textX, textY);
  }

  ctx.stroke()
  ctx.beginPath();
  ctx.strokeStyle = oriStokeStyle;
  ctx.fillStyle = oriFillStyle;
  ctx.font = oriFont;
  ctx.setLineDash(oriLineDash);
}
const drawMensTops = (ctx: any, animationFitState: ClothingMeasurements, 
  lenganPanjang: boolean, userScreenRatio: number | undefined, putAtTop: boolean | undefined, drawGuidelinesData: DrawGuidelinesData | undefined) => {
  if (animationFitState.lingkarLeherBaju === undefined
    || animationFitState.lebarBahuBaju === undefined
    || animationFitState.lebarDadaBaju === undefined
    || animationFitState.lebarPerutBaju === undefined
    || animationFitState.panjangBaju === undefined
    || animationFitState.lebarLenganBaju === undefined
    || (lenganPanjang && animationFitState.panjangLenganPanjang === undefined)
    || (!lenganPanjang && animationFitState.panjangLenganPendek === undefined)) {
    return null;
  }

  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.lineWidth = 1 * devicePixelRatio;
  ctx.strokeStyle = drawGuidelinesData ? '#8f8f8f' : '#666666';
  ctx.fillStyle = '#363636';

  let panjangLengan = (lenganPanjang ? animationFitState.panjangLenganPanjang : animationFitState.panjangLenganPendek) as number;

  function getX5FromX2(inp: number, length: number) {
    return inp + (length * Math.cos(32 * Math.PI / 180));
  }
  function getY5FromY2(inp: number, length: number) {
    return inp + (length * Math.sin(32 * Math.PI / 180));
  }
  function getX6FromX5(inp: number, length: number) {
    return inp - length * Math.sin(32 * Math.PI / 180);
  }
  function getY6FromY5(inp: number, length: number) {
    return inp + length * Math.cos(32 * Math.PI / 180);
  }

  //Drawing
  let neckWidth = 0.5 * 0.4 * animationFitState.lingkarLeherBaju;
  let topMargin = 3 * neckWidth;
  let xbase = 0.5 * ctx.canvas.width;
  let ybase = topMargin;

  let x1 = neckWidth;
  let y1 = 0;
  let x2 = 0.5 * animationFitState.lebarBahuBaju;
  let y2 = 0.3 * (x2 - x1);
  let x3 = 0.5 * animationFitState.lebarDadaBaju;
  let y3 = 0;
  let x4 = 0.5 * animationFitState.lebarPerutBaju;
  let y4 = animationFitState.panjangBaju;
  let x5 = getX5FromX2(x2, panjangLengan);
  let y5 = getY5FromY2(y2, panjangLengan);
  let x6 = getX6FromX5(x5, animationFitState.lebarLenganBaju);
  let y6 = getY6FromY5(y5, animationFitState.lebarLenganBaju);
  // Revisi y3 agar sesuai dengan lengan
  y3 = y6 - (x6 - x3) * Math.tan(24 * Math.PI / 180);
  // Bentuk jahitan lengan
  let x5a = x2 + (panjangLengan * 0.92) * Math.cos(32 * Math.PI / 180);
  let y5a = y2 + (panjangLengan * 0.92) * Math.sin(32 * Math.PI / 180);
  let x6a = x5a - animationFitState.lebarLenganBaju * Math.sin(32 * Math.PI / 180);
  let y6a = y5a + animationFitState.lebarLenganBaju * Math.cos(32 * Math.PI / 180);
  // Bentuk jahitan bawah
  let x4a = 0.5 * animationFitState.lebarPerutBaju;
  let y4a = 0.97 * animationFitState.panjangBaju;

  // Scaling
  let screenRatio = 0.8;
  // If scale ratio is provided and still shows 90% of the object
  if (userScreenRatio !== undefined) {
    screenRatio = userScreenRatio;
  }
  let deltaX = 2 * x5;
  let deltaY = y4;
  // Screen ratio the % of product seen. 
  // Scale ratio is the multiplier from CSS size cm to CSS screen.
  // How many pixels, do we assign for each cm ?
  let multiplyX = (screenRatio * ctx.canvas.width) / deltaX;
  let multiplyY = (screenRatio * ctx.canvas.height) / deltaY;
  let usedScreenMultiplierRatio = Math.min(multiplyX, multiplyY);
  ybase = Math.max(0.05 * ctx.canvas.height, (putAtTop === true ? 0.1 : 0.5) * (ctx.canvas.height - y4 * usedScreenMultiplierRatio));

  // VIEW SETUP: xbase, ybase, usedScreenMultiplierRatio
  // usedScreenMultiplierRatio is set to be a static amount, since we want a "sense of movement" here.
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'lebarBahuBaju') {
    usedScreenMultiplierRatio = (0.95 * ctx.canvas.width) / 60; // 60 cm horizontally occupies 95% of the screen
    ybase = 0.5 * ctx.canvas.height - 5 * usedScreenMultiplierRatio;
  }
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'lebarDadaBaju') {
    usedScreenMultiplierRatio = (0.95 * ctx.canvas.width) / 74; // 74 cm horizontally occupies 95% of the screen
    ybase = - (y2 - 10) * usedScreenMultiplierRatio; // We move the thing top by y3 from the middle of screen
  }
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'lebarPerutBaju') {
    usedScreenMultiplierRatio = (0.95 * ctx.canvas.width) / 78; // 78 cm horizontally occupies 95% of the screen
    ybase = 0.5 * ctx.canvas.height - (y4 - 8) * usedScreenMultiplierRatio; // We move the thing top by y3 from the middle of screen
  }
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'panjangBaju') {
    usedScreenMultiplierRatio = (0.95 * ctx.canvas.width) / 78; // 78 cm horizontally occupies 95% of the screen
    ybase = 0.5 * ctx.canvas.height - 60 * usedScreenMultiplierRatio; // We move the thing top by 70 cm
  }
  if (drawGuidelinesData && !lenganPanjang && 
    (drawGuidelinesData.lastChangedMeasurement === 'panjangLenganPendek'
    || drawGuidelinesData.lastChangedMeasurement === 'lebarLenganBaju')) {
    usedScreenMultiplierRatio = (0.95 * ctx.canvas.width) / 50; // 50 cm horizontally occupies 95% of the screen
    ybase = 0.5 * ctx.canvas.height - (y2 + y3) * 0.5 * usedScreenMultiplierRatio; // We set height to be 20% of total height
    xbase = 0.3 * ctx.canvas.width - x3 * usedScreenMultiplierRatio; // We set height to be 30% of total width, then offset it left by x3
  }
  if (drawGuidelinesData && lenganPanjang && 
    (drawGuidelinesData.lastChangedMeasurement === 'panjangLenganPanjang'
    || drawGuidelinesData.lastChangedMeasurement === 'lebarLenganBaju')) {
    usedScreenMultiplierRatio = (0.95 * ctx.canvas.width) / 90; // 90 cm horizontally occupies 95% of the screen
    ybase = 0.1 * ctx.canvas.height; // We set height to be 20% of total height
    xbase = 0.1 * ctx.canvas.width; // We set height to be 30% of total width, then offset it left by x3
  }


  x1 = x1 * usedScreenMultiplierRatio;
  y1 = y1 * usedScreenMultiplierRatio;
  x2 = x2 * usedScreenMultiplierRatio;
  y2 = y2 * usedScreenMultiplierRatio;
  x3 = x3 * usedScreenMultiplierRatio;
  y3 = y3 * usedScreenMultiplierRatio;
  x4 = x4 * usedScreenMultiplierRatio;
  y4 = y4 * usedScreenMultiplierRatio;
  x5 = x5 * usedScreenMultiplierRatio;
  y5 = y5 * usedScreenMultiplierRatio;
  x6 = x6 * usedScreenMultiplierRatio;
  y6 = y6 * usedScreenMultiplierRatio;

  x4a = x4a * usedScreenMultiplierRatio;
  y4a = y4a * usedScreenMultiplierRatio;
  x5a = x5a * usedScreenMultiplierRatio;
  y5a = y5a * usedScreenMultiplierRatio;
  x6a = x6a * usedScreenMultiplierRatio;
  y6a = y6a * usedScreenMultiplierRatio;

  const fontSizePxMain = 12 * devicePixelRatio;
  ctx.font = fontSizePxMain + "px Verdana";
  ctx.beginPath();

  if (!drawGuidelinesData) {
    ctx.fillText("" + (Math.round(animationFitState.lebarDadaBaju)), xbase - 0.5 * x3, ybase + y3 - 0.5 * fontSizePxMain);
    drawArrowBi(ctx, xbase + x3, ybase + y3, xbase - x3, ybase + y3, x3 * 0.15);
    ctx.fillText("" + (Math.round(animationFitState.panjangBaju)), xbase + x1 + 0.5 * fontSizePxMain, ybase + (0.6 * y3 + 0.4 * y4));
    drawArrowBi(ctx, xbase + x1, ybase + y1, xbase + x1, ybase + y4, x3 * 0.15);
    ctx.fillText("" + (Math.round(animationFitState.lebarPerutBaju)), xbase - 0.5 * x3, ybase + (0.2 * y3 + 0.8 * y4) - 0.5 * fontSizePxMain);
    drawArrowBi(ctx, xbase + x4, ybase + (0.2 * y3 + 0.8 * y4), xbase - x4, ybase + (0.2 * y3 + 0.8 * y4), x3 * 0.15);
    ctx.moveTo(xbase + x4a, ybase + y4a);
    ctx.lineTo(xbase - x4a, ybase + y4a);
    ctx.moveTo(xbase + x5a, ybase + y5a);
    ctx.lineTo(xbase + x6a, ybase + y6a);
    ctx.moveTo(xbase - x5a, ybase + y5a);
    ctx.lineTo(xbase - x6a, ybase + y6a);
  }
  ctx.moveTo(xbase + x1, ybase + y1);
  ctx.lineTo(xbase + x2, ybase + y2);
  ctx.lineTo(xbase + x5, ybase + y5);
  ctx.lineTo(xbase + x6, ybase + y6);
  ctx.lineTo(xbase + x3, ybase + y3);
  ctx.lineTo(xbase + x4, ybase + y4);
  ctx.lineTo(xbase - x4, ybase + y4);
  ctx.lineTo(xbase - x3, ybase + y3);
  ctx.lineTo(xbase - x6, ybase + y6);
  ctx.lineTo(xbase - x5, ybase + y5);
  ctx.lineTo(xbase - x2, ybase + y2);
  ctx.lineTo(xbase - x1, ybase + y1);
  ctx.lineTo(xbase + x1, ybase + y1);

  const fontSizePx = 12 * devicePixelRatio;
  const detailFontStyle = "px 'Open Sans'";

  // DOUBLE VERTICAL GUIDELINES
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'lebarBahuBaju') {
    // Draw Main Line
    ctx.fillText("" + (Math.round(animationFitState.lebarBahuBaju)), xbase - 0.5 * x2, ybase + y2 + 1.3 * fontSizePx);
    drawArrowBi(ctx, xbase + x2, ybase + y2, xbase - x2, ybase + y2, x2 * 0.15);

    // Draw Guidelines
    const guidelinesToDraw = getSizeMeasurementsAndNamesWithinRange(drawGuidelinesData.lastChangedMeasurement,
      animationFitState.lebarBahuBaju, ClothingMeasurementAdjustmentDisplayRange[drawGuidelinesData.lastChangedMeasurement],
      drawGuidelinesData.sizeMeasurementValues, drawGuidelinesData.sizeMeasurementNames);
    const curfontSize = 0.8 * fontSizePx;
    let countText = 1;
    for (let guidelineObject of guidelinesToDraw) {
      const guideWidth = 0.5 * guidelineObject.lengthOfClothMeas * usedScreenMultiplierRatio;
      drawGuideline(ctx, xbase + guideWidth, ybase,
        xbase + guideWidth, ybase + 1.5 * y3, 
        guidelineObject.shortName, 
        xbase + guideWidth - curfontSize, ybase + y2 + 1 * usedScreenMultiplierRatio + countText * 2 * curfontSize, 
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      drawGuideline(ctx, xbase - guideWidth, ybase,
        xbase - guideWidth, ybase + 1.5 * y3, 
        '', 
        xbase, ybase,
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      countText++;
    }
  }
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'lebarDadaBaju') {
    // Draw Main Line
    ctx.fillText("" + (Math.round(animationFitState.lebarDadaBaju)), xbase - 0.5 * x3, ybase + y3 - 0.5 * fontSizePx);
    drawArrowBi(ctx, xbase + x3, ybase + y3, xbase - x3, ybase + y3, x3 * 0.15);

    // Draw Guidelines
    const guidelinesToDraw = getSizeMeasurementsAndNamesWithinRange(drawGuidelinesData.lastChangedMeasurement,
      animationFitState.lebarDadaBaju, ClothingMeasurementAdjustmentDisplayRange[drawGuidelinesData.lastChangedMeasurement],
      drawGuidelinesData.sizeMeasurementValues, drawGuidelinesData.sizeMeasurementNames);
    const curfontSize = 0.8 * fontSizePx;
    let countText = 1;
    for (let guidelineObject of guidelinesToDraw) {
      const guideWidth = 0.5 * guidelineObject.lengthOfClothMeas * usedScreenMultiplierRatio;
      drawGuideline(ctx, xbase + guideWidth, ybase,
        xbase + guideWidth, ybase + 1.1 * y4, 
        guidelineObject.shortName, 
        xbase + guideWidth - 0.8 * curfontSize, ybase + 1.1 * y3 + countText * 1.2 * curfontSize, 
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      drawGuideline(ctx, xbase - guideWidth, ybase,
        xbase - guideWidth, ybase + 1.1 * y4, 
        '', 
        xbase, ybase,
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
        countText++;
    }
  }
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'lebarPerutBaju') {
    // Draw Main Line
    ctx.fillText("" + (Math.round(animationFitState.lebarPerutBaju)), xbase - 0.5 * x3, ybase + (0.2 * y3 + 0.8 * y4) - 0.5 * fontSizePx);
    drawArrowBi(ctx, xbase + x4, ybase + (0.2 * y3 + 0.8 * y4), xbase - x4, ybase + (0.2 * y3 + 0.8 * y4), x3 * 0.15);

    // Draw Guidelines
    const guidelinesToDraw = getSizeMeasurementsAndNamesWithinRange(drawGuidelinesData.lastChangedMeasurement,
      animationFitState.lebarPerutBaju, ClothingMeasurementAdjustmentDisplayRange[drawGuidelinesData.lastChangedMeasurement],
      drawGuidelinesData.sizeMeasurementValues, drawGuidelinesData.sizeMeasurementNames);
    const curfontSize = 0.8 * fontSizePx;
    let countText = 1;
    for (let guidelineObject of guidelinesToDraw) {
      const guideWidth = 0.5 * guidelineObject.lengthOfClothMeas * usedScreenMultiplierRatio;
      drawGuideline(ctx, xbase + guideWidth, ybase,
        xbase + guideWidth, ybase + 1.1 * y4, 
        guidelineObject.shortName, 
        xbase + guideWidth - 0.8 * curfontSize, ybase + y4 - 6 * usedScreenMultiplierRatio + countText * 1.2 * curfontSize, 
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      drawGuideline(ctx, xbase - guideWidth, ybase,
        xbase - guideWidth, ybase + 1.1 * y4, 
        '', 
        xbase, ybase,
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      countText++;
    }
  }

  // HORIZONTAL GUIDELINES 
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'panjangBaju') {
    // Draw Main Line
    ctx.fillText("" + (Math.round(animationFitState.panjangBaju)), xbase + x1 + 0.5 * fontSizePx, ybase + (0.6 * y3 + 0.4 * y4));
    drawArrowBi(ctx, xbase + x1, ybase + y1, xbase + x1, ybase + y4, x3 * 0.15);

    // Draw Guidelines
    const guidelinesToDraw = getSizeMeasurementsAndNamesWithinRange(drawGuidelinesData.lastChangedMeasurement,
      animationFitState.panjangBaju, ClothingMeasurementAdjustmentDisplayRange[drawGuidelinesData.lastChangedMeasurement],
      drawGuidelinesData.sizeMeasurementValues, drawGuidelinesData.sizeMeasurementNames);
    const curfontSize = 0.8 * fontSizePx;
    let countText = 1;
    for (let guidelineObject of guidelinesToDraw) {
      drawGuideline(ctx, xbase + 1.2 * x4, ybase + guidelineObject.lengthOfClothMeas * usedScreenMultiplierRatio,
        xbase - 1.2 * x4, ybase + guidelineObject.lengthOfClothMeas * usedScreenMultiplierRatio, 
        guidelineObject.shortName, 
        xbase - x4 + 1.2 * curfontSize * countText, 
        ybase + guidelineObject.lengthOfClothMeas * usedScreenMultiplierRatio - 0.1 * curfontSize, 
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      countText++;
    }
  }

  // ARM VERTICAL GUIDELINES
  if (drawGuidelinesData && drawGuidelinesData.lastChangedMeasurement === 'lebarLenganBaju') {
    // Draw Guidelines
    const guidelinesToDraw = getSizeMeasurementsAndNamesWithinRange(drawGuidelinesData.lastChangedMeasurement,
      animationFitState.lebarLenganBaju, ClothingMeasurementAdjustmentDisplayRange[drawGuidelinesData.lastChangedMeasurement],
      drawGuidelinesData.sizeMeasurementValues, drawGuidelinesData.sizeMeasurementNames);
    const curfontSize = 0.7 * fontSizePx;
    let countText = 1;
    for (let guidelineObject of guidelinesToDraw) {
      const guideWidth = guidelineObject.lengthOfClothMeas;
      drawGuideline(ctx, 
        xbase + x5 + getX5FromX2(0, -4) * usedScreenMultiplierRatio, ybase + y5 + getY5FromY2(0, -4) * usedScreenMultiplierRatio,
        xbase + x5 + getX5FromX2(0, 3) * usedScreenMultiplierRatio, ybase + y5 + getY5FromY2(0, 3) * usedScreenMultiplierRatio, 
        '',
        xbase, ybase, 
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      drawGuideline(ctx, 
        xbase + x5 + getX6FromX5(0, guideWidth) * usedScreenMultiplierRatio + getX5FromX2(0, -4) * usedScreenMultiplierRatio, 
        ybase + y5 + getY6FromY5(0, guideWidth) * usedScreenMultiplierRatio + getY5FromY2(0, -4) * usedScreenMultiplierRatio,
        xbase + x5 + getX6FromX5(0, guideWidth) * usedScreenMultiplierRatio + getX5FromX2(0, 3) * usedScreenMultiplierRatio, 
        ybase + y5 + getY6FromY5(0, guideWidth) * usedScreenMultiplierRatio + getY5FromY2(0, 3) * usedScreenMultiplierRatio,
        guidelineObject.shortName, 
        xbase + x5 + getX6FromX5(0, guideWidth) * usedScreenMultiplierRatio + getX5FromX2(0, -4 + countText * 1) * usedScreenMultiplierRatio + 0.3 * curfontSize, 
        ybase + y5 + getY6FromY5(0, guideWidth) * usedScreenMultiplierRatio + getX5FromX2(0, -4 + countText * 1) * usedScreenMultiplierRatio + 0.3 * curfontSize, 
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      countText++;
    }
  }

  // ARM HORIZONTAL GUIDELINES
  if (drawGuidelinesData && (drawGuidelinesData.lastChangedMeasurement === 'panjangLenganPendek'
  || drawGuidelinesData.lastChangedMeasurement === 'panjangLenganPanjang')) {
    // Draw Guidelines
    const guidelinesToDraw = getSizeMeasurementsAndNamesWithinRange(drawGuidelinesData.lastChangedMeasurement,
      (drawGuidelinesData.lastChangedMeasurement === 'panjangLenganPendek' ? animationFitState.panjangLenganPendek as number : 
      animationFitState.panjangLenganPanjang as number), ClothingMeasurementAdjustmentDisplayRange[drawGuidelinesData.lastChangedMeasurement],
      drawGuidelinesData.sizeMeasurementValues, drawGuidelinesData.sizeMeasurementNames);
    const curfontSize = 0.8 * fontSizePx;
    let countText = 1;
    for (let guidelineObject of guidelinesToDraw) {
      const guideWidth = guidelineObject.lengthOfClothMeas;
      drawGuideline(ctx, 
        xbase + x2 + getX5FromX2(0, guideWidth) * usedScreenMultiplierRatio, 
        ybase + y2 + getY5FromY2(0, guideWidth) * usedScreenMultiplierRatio,
        xbase + x2 + getX5FromX2(0, guideWidth) * usedScreenMultiplierRatio + getX6FromX5(0, 1.3 * animationFitState.lebarLenganBaju) * usedScreenMultiplierRatio,
        ybase + y2 + getY5FromY2(0, guideWidth) * usedScreenMultiplierRatio + getY6FromY5(0, 1.4 * animationFitState.lebarLenganBaju) * usedScreenMultiplierRatio,
        guidelineObject.shortName, 
        xbase + x2 + getX5FromX2(0, guideWidth) * usedScreenMultiplierRatio + getX6FromX5(0, countText * 3) * usedScreenMultiplierRatio - 0.5 * curfontSize, 
        ybase + y2 + getY5FromY2(0, guideWidth) * usedScreenMultiplierRatio + getY6FromY5(0, countText * 3) * usedScreenMultiplierRatio,
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      drawGuideline(ctx, 
        xbase - x2 - getX5FromX2(0, guideWidth) * usedScreenMultiplierRatio, 
        ybase + y2 + getY5FromY2(0, guideWidth) * usedScreenMultiplierRatio,
        xbase - x2 - getX5FromX2(0, guideWidth) * usedScreenMultiplierRatio - getX6FromX5(0, 1.3 * animationFitState.lebarLenganBaju) * usedScreenMultiplierRatio,
        ybase + y2 + getY5FromY2(0, guideWidth) * usedScreenMultiplierRatio + getY6FromY5(0, 1.4 * animationFitState.lebarLenganBaju) * usedScreenMultiplierRatio,
        '', 
        xbase, 
        ybase,
        curfontSize + detailFontStyle, guidelineObject.focusRecommended, guidelineObject.isEqual);
      countText++;
    }
  }

  ctx.lineTo(xbase + x1, ybase + y1);

  // ctx.arcTo(xbase, ybase + y1 + x1, xbase - x1, ybase + y1, 1.2 * x1);
  ctx.quadraticCurveTo(xbase, ybase + y1 + 1.2 * x1, xbase - x1, ybase + y1);
  ctx.moveTo(xbase + 1.25 * x1, ybase + y1 + 0.3 * 0.25 * x1);
  ctx.quadraticCurveTo(xbase, ybase + y1 + 1.24 * 1.25 * x1, xbase - 1.25 * x1, ybase + y1 + 0.3 * 0.25 * x1);
  ctx.moveTo(xbase + x2, ybase + y2);
  ctx.quadraticCurveTo(xbase + x2 * 0.75, ybase + 0.5 * (y2 + y3), xbase + x3, ybase + y3);
  ctx.moveTo(xbase - x2, ybase + y2);
  ctx.quadraticCurveTo(xbase - x2 * 0.75, ybase + 0.5 * (y2 + y3), xbase - x3, ybase + y3);
  ctx.stroke()
}

export interface DrawGuidelinesData {
  lastChangedMeasurement: string
  lastChangedMeasurementName: string
  guidelineDisplayRange: number
  sizeMeasurementValues: ClothingMeasurements[]
  sizeMeasurementNames: RegularSizeValue[]
}

interface Props {
  clothingType: ClothingType
  fitType: FitType
  scaleRatio?: number
  putAtTop?: boolean
  incrementTogether?: boolean
  goalFit: ClothingMeasurements
  drawGuidelinesData?: DrawGuidelinesData
  canvasManualHeight?: string
}

export default function OverlayComponentCanvas({clothingType, fitType, scaleRatio, putAtTop, 
  incrementTogether, goalFit, drawGuidelinesData, canvasManualHeight}: Props) {
  const classes = useStyles();
  const [currentAnimationFit, setCurrentAnimationFit] = React.useState<ClothingMeasurements>({
    lingkarLeherBaju: 0,
    lebarBahuBaju: 0,
    lebarDadaBaju: 0,
    lebarPerutBaju: 0,
    panjangBaju: 0,
    lebarLenganBaju: 0,
    panjangLenganPendek: 0,
    panjangLenganPanjang: 0,
  })
  const [currentDrawGuidelinesData, setCurrentDrawGuidelinesData] = React.useState<DrawGuidelinesData | undefined>(undefined);

  const canvasRef = useRef(null);

  function drawClothing(clothingType: ClothingType, context: any, 
    goalFit: ClothingMeasurements, scaleRatio: number | undefined, putAtTop: boolean | undefined,
    drawGuidelinesData: DrawGuidelinesData | undefined) {

    // KEY2TODO-TYPE Set up visualization for ClothingType
    // If the is not set yet, the item will NOT be drawn
    switch (clothingType) {
      // IMPORTANT NOTE 
      // Each Illustration, and each ClothingMeasurementKey, is actually expensive, in terms of developer time.
      // Use and plan wisely.
      // Illustration Type 1: "Atasan Lengan Pendek"
      case ClothingType.Kaos:
      case ClothingType.KemejaPendek:
      case ClothingType.PoloShirt:
        drawMensTops(context, goalFit, false, scaleRatio, putAtTop, drawGuidelinesData);
        break;
      // Illustration Type 2: "Atasan Lengan Panjang"
      case ClothingType.KemejaPanjang:
      case ClothingType.Sweater:
      case ClothingType.Jaket:
      case ClothingType.KaosPanjang:
      case ClothingType.PoloShirtPanjang:
      case ClothingType.KokoTigaEmpat:
        drawMensTops(context, goalFit, true, scaleRatio, putAtTop, drawGuidelinesData);
        break;
    }
  }
  
  useEffect(() => {
    const canvas: any = canvasRef.current
    const context = (canvas as any).getContext('2d')
    let animationFrameId: number;
    let internalCurrentAnimationFit: ClothingMeasurements = { ...currentAnimationFit }


    if (currentAnimationFit.lingkarLeherBaju === 0 && !IsEqualClothingMeasurements(currentAnimationFit, goalFit)) {
      drawClothing(clothingType, context, goalFit, scaleRatio, putAtTop, drawGuidelinesData);
      setCurrentAnimationFit(goalFit);
      setCurrentDrawGuidelinesData(drawGuidelinesData);
      return () => {};
    }

    const render = () => {
      if (!IsEqualClothingMeasurements(internalCurrentAnimationFit, goalFit) || drawGuidelinesData !== currentDrawGuidelinesData) {
        internalCurrentAnimationFit = NextStepClothingMeasurements(internalCurrentAnimationFit, goalFit, incrementTogether !== undefined ? incrementTogether : true)

        drawClothing(clothingType, context, internalCurrentAnimationFit, scaleRatio, putAtTop, drawGuidelinesData);
        animationFrameId = window.requestAnimationFrame(render);

        // Update fit state every time the animation is changed
        setCurrentAnimationFit(internalCurrentAnimationFit);
        setCurrentDrawGuidelinesData(drawGuidelinesData);
      }
    }
    render()
    
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    }
  }, [goalFit, drawGuidelinesData])

  useEffect(() => {
    const canvas: any = canvasRef.current
    const context = (canvas as any).getContext('2d')

    let rect = canvas.getBoundingClientRect();

    canvas.width = rect.width*devicePixelRatio;
    canvas.height = rect.height*devicePixelRatio;

    drawClothing(clothingType, context, goalFit, scaleRatio, putAtTop, drawGuidelinesData);
  }, [canvasRef]);

  return (
    <Box className={classes.canvasContainer}>
      <canvas ref={canvasRef} className={classes.canvas} style={{
        height: canvasManualHeight ? canvasManualHeight : '100%'
      }}/>
    </Box>
  );
}
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OverlayNavbarFlow {
  currentScreen: string,
}

// Define the initial state using that type
const initialState: OverlayNavbarFlow = {
  currentScreen: "DEFAULT",
}

export const overlayNavbarSlice = createSlice({
  name: 'overlayNavbar',
  initialState,
  reducers: {
    updateCurrentScreen: (state, action: PayloadAction<string>) => {
      state.currentScreen = action.payload;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { updateCurrentScreen } = overlayNavbarSlice.actions

export default overlayNavbarSlice.reducer
import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface stylesProps {
  margin: string;
}

const useStyles = ({ margin }: stylesProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      ReviewItemRoot: {
        display: "flex",
        flexWrap: "nowrap",
        "&:hover": {
          zIndex: 100,
        },
        margin,
      },
      image: {
        width: '12vw',
        height: '12vw',
        objectFit: "cover",
        marginRight: theme.spacing(1.5),
        cursor: "pointer",
        WebkitTapHighlightColor: 'transparent',
        [theme.breakpoints.down('sm')]: {
          width: '96px',
          height: '96px'
        }
      },
    })
  );

interface ReviewItemImageProps {
  name: string;
  timestamp: string;
  rating: number;
  comment: string;
  displayImageList: string;
  userSub?: string;
  margin?: string;
  padding?: string;
  onClickImage: (imageIndex: number) => void;
}

const ReviewItemImage: React.FC<ReviewItemImageProps> = ({
  displayImageList,
  margin = "0px 0px 0px 0px",
  onClickImage,
}) => {
  const classes = useStyles({ margin })();

  return (
    <Box className={classes.ReviewItemRoot}>
      {JSON.parse(displayImageList).map((url: any, index: any) => (
        <Box
          key={index}
          className={classes.image}
          onClick={() => onClickImage(index)}
        >
          <img
            src={url.replace('.jpg', '_thumbnail.jpg')}
            style={{
              borderRadius: 10,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt={`Review image ${index + 1}`}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ReviewItemImage;
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Fade, Link, Slide } from '@material-ui/core';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Link as RouterLink } from "react-router-dom";
import React from 'react';
import { useEffect } from 'react';
import PreloadImage from './ImagePreloader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  productListItem: {
    '&:hover': {
      zIndex: 100, // To ensure that the items come first
    }
  },
}),
);

interface Props {
  xsSize: number
  smSize: number
  mdSize: number
  lgSize: number
  content: JSX.Element
}

export default function OptionItemContainer({xsSize, smSize, mdSize, lgSize, content} : Props) {
  const classes = useStyles();

  // Intersection Observer API
  const { ref, inView, entry } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <Grid item xs={xsSize as GridSize} sm={smSize as GridSize}
      md={mdSize as GridSize} lg={lgSize as GridSize} 
      className={classes.productListItem}>
      <div ref={ref}>
        {/* <Slide direction="up" in={inView} timeout={400}> */}
          <Box>
            {content}
          </Box>
        {/* </Slide> */}
      </div>
    </Grid>
  );
}
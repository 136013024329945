import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Link, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { updateOrderState, getCurrentOrder, getDiscountedPriceAmountWithPromotionsAndShipment, getDiscountedPriceAmountWithPromotions, resetCartStateAndSetOrderStatus, paymentTokenFetch, removePaymentToken } from '../redux/CartRedux';
import { SetStateAction, useEffect } from 'react';
import OverlayComponentButton, { FareastButton } from './OverlayComponentButton';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import React from 'react';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paymentContent: {
    height: '100%',
    padding: '0px 24px',
  },
  subFormTitle: {
    marginTop: '30px',
    marginBottom: '12px',
    fontSize: "20px",
    fontWeight: 500,
  },
  subFormTitleCategory: {
    marginTop: '32px',
    marginBottom: '10px',
    fontSize: "14px",
    fontWeight: 500,
  },
  paymentPortalButton: {
    marginTop: '20px',
  },
  formNavButtonsContainer: {
    height: 'auto',
    width: '100%',
    right: '0px',
    display: 'flex',
    justifyContent: 'right',
  },
  formNavButtons: {
    marginTop: '20px',
    marginRight: '18px',
    marginBottom: '24px',
  },
  contentText: {
    fontSize: '15px',
    fontWeight: 300,
    marginBottom: '5px',
  },
  priceText: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
  },
  paymentOptionBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  chooseOtherPaymentOptionBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "6px",
    marginTop: "8px",
  },
  paymentOptionRadio: {
    color: "#121212 !important",
  },
  paymentOptionImg: {
    // maxHeight: "26px",
    // width: "24px",
    marginRight: "16px",
    width: "32px",
  },
  paymentOptionText: {
    fontSize: '15px',
    fontWeight: 300,
    paddingTop: "3px",
  },
  chooseOtherPaymentMethodText: {
    fontSize: '15px',
    fontWeight: 500,
    marginTop: "14px",
    marginBottom: "10px",
  },
  paymentOptionBcaBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "22px",
    marginBottom: "25px",
  },
  paymentOptionBcaText: {
    fontSize: '14px',
    fontWeight: 400,
    paddingTop: "3px",
  },
  contentPaymentSmallText: {
    fontSize: '15px',
    fontWeight: 300,
    marginBottom: "2px"
  },
  contentPaymentText: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "18px"
  },
  salinBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  salinButton: {
    height: "35px",
    marginTop: "-5px",
  },
  snapWindow: {
    width: '100%'
  },
  circularProgressContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "250px",
  },
}),
);

interface PageCheckoutProps {
  checkoutStep: string,
  setCheckoutStep: React.Dispatch<SetStateAction<string>>,
}

export default function PageCheckoutForms({checkoutStep, setCheckoutStep} : PageCheckoutProps) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [paymentOptionValue, setPaymentOptionValue] = React.useState<string>('transferBCA');
  const [paymentMethodStep, setPaymentMethodStep] = React.useState<string>("choosePaymentMethod"); // choosePaymentMethod or commitPaymentMethod
  const totalPayment = useAppSelector(state => getDiscountedPriceAmountWithPromotionsAndShipment(state.cart, state.productsList, state.userMeasurementsList.userBodyMeasurementsDictionary));
  const paymentStep = useAppSelector(state => state.cart.orderStatus);
  const userSub = useAppSelector(state => state.account.sub);
  const orderCreationDateTime = useAppSelector(state => state.cart.orderData?.creationDateTime);
  const orderId = (orderCreationDateTime + "-" + userSub).substring(0, 36);
  const paymentToken = useAppSelector(state => state.cart.paymentToken);
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));

  const useEWallet = (paymentOptionValue === "ovo" 
  || paymentOptionValue === "shopeepay"
  || paymentOptionValue === "dana");

  const eWalletName = paymentOptionValue === "ovo" ? "Ovo" : 
  paymentOptionValue === "shopeepay" ? "ShopeePay" : 
  paymentOptionValue === "dana" ? "Dana" : "";

  useEffect(() => {
    if (paymentToken !== "") {
      (window as any).snap.hide();

      console.log("Setting snap to paymentToken " + paymentToken);
      (window as any).snap.embed(paymentToken, {
        embedId: 'snap-container',
        selectedPaymentType: "",
        language: "id",
        onSuccess: function(result: any){
          console.log("success: ",result);

          sendWebsiteEventMessage("[CHECKOUT] Payment total of Rp " + totalPayment + " is successful! Congratulations!");
          // Facebook Purchase Event, Based on Midtrans
          // if ((window as any).fbq !== null) { 
          //   (window as any).fbq('track', 'Purchase', {
          //     currency: "IDR",
          //     value: totalPayment,
          //   })
          // }

          dispatch(updateOrderState('verifyPaymentWithServer'));
          dispatch(getCurrentOrder());
        },
        onPending: function(result: any){
          console.log("pending: ",result);

          (window as any).snap.hide();
          setPaymentMethodStep("choosePaymentMethod");
        },
        onError: function(result: any){
          console.log("error: ",result);
        },
        onClose: function(){
          console.log("close: ");

          (window as any).snap.hide();
          setPaymentMethodStep("choosePaymentMethod");
        }});
    }
  }, [paymentToken]);

  const handleChangePaymentOption = (event: any) => {
    sendWebsiteEventMessage("Payment method " + event.target.value + "di select");
    setPaymentOptionValue(event.target.value);
  };

  const addedTotalPayment = totalPayment + (new Date(orderCreationDateTime).getTime() % 500);

  const useMidtrans = 
  paymentOptionValue !== "transferBCA" &&
  !(useEWallet && !isMdOrUp);

  return (
      <div className={classes.paymentContent}>
        {
          paymentStep === "waitingForPayment" ? 
          (paymentMethodStep === "choosePaymentMethod" ? 
          <>
            <Typography variant="h5" className={classes.subFormTitle}>
                Metode Pembayaran
            </Typography>
            <Typography className={classes.contentText}>
                Total yang harus dibayar sebesar <span className={classes.priceText}>Rp {totalPayment}.</span>
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup aria-label="paymethod" name="paymethod1" value={paymentOptionValue} onChange={handleChangePaymentOption}>
                <Typography variant="h5" className={classes.subFormTitleCategory}>
                    E-Wallet
                </Typography>
                <FormControlLabel value="gopay" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-gopay"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-gopay.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      Gopay
                    </Typography>
                  </Box>} />
                <FormControlLabel value="ovo" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-gopay"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-ovo.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      OVO
                    </Typography>
                  </Box>} />
                <FormControlLabel value="shopeepay" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-gopay"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-shopeepay.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      ShopeePay
                    </Typography>
                  </Box>} />
                <FormControlLabel value="dana" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-gopay"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-dana.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      Dana
                    </Typography>
                  </Box>} />
                {
                  isMdOrUp ? 
                  <FormControlLabel value="qris" control={<Radio className={classes.paymentOptionRadio} />} label={
                    <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-qris"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-qris.png"
                    />
                      <Typography className={classes.paymentOptionText}>
                        QRIS
                      </Typography>
                    </Box>} />:
                  null
                }
                <Typography variant="h5" className={classes.subFormTitleCategory}>
                    Transfer Bank
                </Typography>
                <FormControlLabel value="transferBCA" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-bca"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-bca.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      Transfer Bank BCA
                    </Typography>
                  </Box>} />
                <FormControlLabel value="virtualAccountMandiri" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-mandiri"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-mandiri.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      Mandiri VA (Virtual Account)
                    </Typography>
                  </Box>} />
                <FormControlLabel value="virtualAccountBNI" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-bni"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-bni.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      BNI VA (Virtual Account)
                    </Typography>
                  </Box>} />
                <FormControlLabel value="virtualAccountBRI" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-bri"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-bri.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      BRI VA (Virtual Account)
                    </Typography>
                  </Box>} />
                <FormControlLabel value="virtualAccountPermata" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <img
                      className={classes.paymentOptionImg}
                      alt="icon-permata"
                      src="https://d31zz7s33c4k72.cloudfront.net/icon-permata.png"
                    />
                    <Typography className={classes.paymentOptionText}>
                      Permata VA (Virtual Account)
                    </Typography>
                  </Box>} />
                <FormControlLabel value="virtualAccountLainnya" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <AccountBalanceOutlinedIcon 
                      className={classes.paymentOptionImg} />
                    <Typography className={classes.paymentOptionText}>
                      Transfer Dari Bank Lainnya
                    </Typography>
                  </Box>} />
                <Typography variant="h5" className={classes.subFormTitleCategory}>
                    Metode Lainnya
                </Typography>
                <FormControlLabel value="caraPembayaranLainnya" control={<Radio className={classes.paymentOptionRadio} />} label={
                  <Box className={classes.paymentOptionBox}>
                    <AccountBalanceWalletOutlinedIcon 
                      className={classes.paymentOptionImg} />
                    <Typography className={classes.paymentOptionText}>
                      Cara Pembayaran Lainnya
                    </Typography>
                  </Box>} />
              </RadioGroup>
            </FormControl>
            <OverlayComponentButton handleClick={() => {
              sendWebsiteEventMessage("[CHECKOUT] Payment dengan cara " + paymentOptionValue + " dipilih.");

              if (useMidtrans) {
                dispatch(removePaymentToken());

                let enabledPaymentsArray = undefined;
                if (paymentOptionValue.startsWith("virtualAccount")) {
                  enabledPaymentsArray = ["bank_transfer"];
                  if (paymentOptionValue === "virtualAccountMandiri") {
                    enabledPaymentsArray = ["echannel"];
                  }
                  if (paymentOptionValue === "virtualAccountBNI") {
                    enabledPaymentsArray = ["bni_va"];
                  }
                  if (paymentOptionValue === "virtualAccountBRI") {
                    enabledPaymentsArray = ["bri_va"];
                  }
                  if (paymentOptionValue === "virtualAccountPermata") {
                    enabledPaymentsArray = ["permata_va"];
                  }
                }
                if (paymentOptionValue === "gopay") {
                  enabledPaymentsArray = ["gopay"];
                }
                if (paymentOptionValue === "qris") {
                  enabledPaymentsArray = ["other_qris"];
                }
                if (useEWallet && isMdOrUp) {
                  enabledPaymentsArray = ["other_qris"];
                }
                if (paymentOptionValue === "caraPembayaranLainnya") {
                  enabledPaymentsArray = undefined;
                }

                console.log("Fetching new payment token");
                // Get new payment token from our API endpoint
                dispatch(paymentTokenFetch({
                  orderId: orderId,
                  enabledPayments: enabledPaymentsArray,
                }));
              }

              setPaymentMethodStep("commitPaymentMethod"); // Get to next payment method step
            }}
              formButtonText="PAY NOW"
              isTypeSubmit={false}
              buttonMarginTopBottom="20px"
              buttonMarginLeftRight="10px"/>
          </> : 
          paymentMethodStep === "commitPaymentMethod" && paymentOptionValue === "transferBCA" ?
          <>
            <Link onClick={() => {
              (window as any).snap.hide();
              setPaymentMethodStep("choosePaymentMethod");
            }}>
              <Box className={classes.chooseOtherPaymentOptionBox} >
                <ArrowBackOutlinedIcon 
                  className={classes.paymentOptionImg} />
                <Typography className={classes.chooseOtherPaymentMethodText}>
                  Pilih Metode Pembayaran Lain
                </Typography>
              </Box>
            </Link>
            <Box>
              <Typography variant="h5" className={classes.subFormTitle}>
                  Transfer Bank BCA
              </Typography>
              <Typography className={classes.contentPaymentSmallText}>
                Lakukan transfer ke rekening bank BCA berikut. Kami akan melakukan verifikasi pembayaran dalam 15 menit.
              </Typography>
              <Box className={classes.paymentOptionBcaBox}>
                <img
                  className={classes.paymentOptionImg}
                  alt="icon-bca"
                  src="https://d31zz7s33c4k72.cloudfront.net/icon-bca.png"
                />
                <Typography className={classes.paymentOptionBcaText}>
                  Bank BCA
                </Typography>
              </Box>
              <Typography className={classes.contentPaymentSmallText}>
                Nomor Rekening
              </Typography>
              <Box className={classes.salinBox}>
                <Typography className={classes.contentPaymentText}>
                  4971770079
                </Typography>
                <Button
                  variant="outlined"
                  className={classes.salinButton}
                  startIcon={<FileCopyOutlinedIcon />}
                  size="small"
                  onClick = {() => {
                    // Copy the text
                    (navigator as any).clipboard.writeText("4971770079");
                  }}
                >
                  Salin
                </Button>
              </Box>
              <Typography className={classes.contentPaymentSmallText}>
                Atas Nama
              </Typography>
              <Typography className={classes.contentPaymentText}>
                MIKAEL HARSENO SUBIANTO
              </Typography>
              <Typography className={classes.contentPaymentSmallText}>
                Jumlah yang Harus Ditransfer
              </Typography>
              <Typography className={classes.contentPaymentText}>
                Rp {addedTotalPayment}
              </Typography>
              <OverlayComponentButton handleClick={() => {
                // Open WhatsApp verification link message
                const url = 'https://wa.me/6287813028340?text=Permisi%20kak,%20saya%20baru%20transfer%20sebesar%20Rp%20' 
                + addedTotalPayment.toString() + '%20untuk%20pembayaran%20order%20' + orderId;
                window.open(url, '_blank');

                sendWebsiteEventMessage("[CHECKOUT] Payment confirmation bank BCA with amount of " + addedTotalPayment.toString() + ".");
                // Facebook Purchase Event, Based on Transfer Bank BCA
                // if ((window as any).fbq !== null) { 
                //   (window as any).fbq('track', 'Purchase', {
                //     currency: "IDR",
                //     value: addedTotalPayment,
                //   })
                // }

                dispatch(resetCartStateAndSetOrderStatus("success"));
              }}
              formButtonText="SAYA SUDAH BAYAR"
              isTypeSubmit={false}
              buttonMarginTopBottom="20px"
              buttonMarginLeftRight="10px"/>
            </Box>
          </> :
          paymentMethodStep === "commitPaymentMethod" && (useEWallet && !isMdOrUp) ?
          <>
            <Link onClick={() => {
              (window as any).snap.hide();
              setPaymentMethodStep("choosePaymentMethod");
            }}>
              <Box className={classes.chooseOtherPaymentOptionBox} >
                <ArrowBackOutlinedIcon 
                  className={classes.paymentOptionImg} />
                <Typography className={classes.chooseOtherPaymentMethodText}>
                  Pilih Metode Pembayaran Lain
                </Typography>
              </Box>
            </Link>
            <Box>
              <Typography variant="h5" className={classes.subFormTitle}>
                  Pembayaran Via {eWalletName}
              </Typography>
              <Typography className={classes.contentPaymentSmallText}>
                Lakukan transfer ke akun {eWalletName} berikut. Kami akan melakukan verifikasi pembayaran dalam 15 menit.
              </Typography>
              <Box className={classes.paymentOptionBcaBox}>
                <img
                  className={classes.paymentOptionImg}
                  alt="icon-bca"
                  src={
                    paymentOptionValue === "ovo" ? "https://d31zz7s33c4k72.cloudfront.net/icon-ovo.png" :
                    paymentOptionValue === "shopeepay" ? "https://d31zz7s33c4k72.cloudfront.net/icon-shopeepay.png" :
                    paymentOptionValue === "dana" ? "https://d31zz7s33c4k72.cloudfront.net/icon-dana.png" : 
                    "https://d31zz7s33c4k72.cloudfront.net/icon-dana.png"
                  }
                />
                <Typography className={classes.paymentOptionBcaText}>
                  {eWalletName}
                </Typography>
              </Box>
              <Typography className={classes.contentPaymentSmallText}>
                Nomor Akun {eWalletName}
              </Typography>
              <Box className={classes.salinBox}>
                <Typography className={classes.contentPaymentText}>
                  087813028340
                </Typography>
                <Button
                  variant="outlined"
                  className={classes.salinButton}
                  startIcon={<FileCopyOutlinedIcon />}
                  size="small"
                  onClick = {() => {
                    // Copy the text
                    (navigator as any).clipboard.writeText("087813028340");
                  }}
                >
                  Salin
                </Button>
              </Box>
              <Typography className={classes.contentPaymentSmallText}>
                Atas Nama
              </Typography>
              <Typography className={classes.contentPaymentText}>
                MIKAEL HARSENO SUBIANTO
              </Typography>
              <Typography className={classes.contentPaymentSmallText}>
                Jumlah yang Harus Ditransfer
              </Typography>
              <Typography className={classes.contentPaymentText}>
                Rp {addedTotalPayment}
              </Typography>
              <OverlayComponentButton handleClick={() => {
                // Open WhatsApp verification link message
                const url = 'https://wa.me/6287813028340?text=Permisi%20kak,%20saya%20baru%20transfer%20sebesar%20Rp%20' 
                + addedTotalPayment.toString() + '%20untuk%20pembayaran%20order%20' + orderId;
                window.open(url, '_blank');

                sendWebsiteEventMessage("[CHECKOUT] Payment confirmation " + eWalletName + " with amount of " + addedTotalPayment.toString() + ".");
                // Facebook Purchase Event, Based on Transfer Bank BCA
                // if ((window as any).fbq !== null) { 
                //   (window as any).fbq('track', 'Purchase', {
                //     currency: "IDR",
                //     value: addedTotalPayment,
                //   })
                // }

                dispatch(resetCartStateAndSetOrderStatus("success"));
              }}
              formButtonText="SAYA SUDAH BAYAR"
              isTypeSubmit={false}
              buttonMarginTopBottom="20px"
              buttonMarginLeftRight="10px"/>
            </Box>
          </> :
          <>
            <Link onClick={() => {
              (window as any).snap.hide();
              setPaymentMethodStep("choosePaymentMethod");
            }}>
              <Box className={classes.chooseOtherPaymentOptionBox} >
                <ArrowBackOutlinedIcon 
                  className={classes.paymentOptionImg} />
                <Typography className={classes.chooseOtherPaymentMethodText}>
                  Pilih Metode Pembayaran Lain
                </Typography>
              </Box>
          </Link>
          </>) : 
          paymentStep === 'verifyPaymentWithServer' ?
          <Typography className={classes.contentText}>
            Please wait while we verify your payment with the server...
          </Typography> :
          null
        }
        {
          paymentStep === "waitingForPayment" ? 
          <div id="snap-container" className={classes.snapWindow}></div> :
          null
        }
        {
          paymentStep === "waitingForPayment" 
          && paymentMethodStep === "commitPaymentMethod"
          && useMidtrans
          && paymentToken === ""
          ? 
          <Box className={classes.circularProgressContainer}>
            <CircularProgress/>
          </Box>:
          null
        }
      </div>
  );
}
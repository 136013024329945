import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { BodyMeasurementsForSize, CalculateClothBodyMeasurementsFromBodyMeasurements, CalculateClothingMeasurementsFromBodyMeasurements, ClothingType, ClothingTypeDisplayString, FitType, RoundClothingMeasurementsToHalfPoint, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements, UserClothingMeasurementsPreference, UserMeasurementsState, UserPersonalSizeInput } from 'common-ts/dist/models/UserMeasurements';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import { setViewedBodyMeas } from './UIOverlayPersonalSizeRedux';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';

export const getCurrentSelectedMeasurement = (key2: string, userPrefDict: any, userBodyMeasurementsDictionary: any, userClothingMeasurementsDictionary: any) => {

  // Current selected measurement
  let currentSelectedMeasurementId = userPrefDict[key2] ? userPrefDict[key2] : '';
  let currentSelectedMeasurementIdIsBody = currentSelectedMeasurementId.startsWith("body-");
  let currentSelectedMeasurementIdCreationDateTime = currentSelectedMeasurementIdIsBody ? currentSelectedMeasurementId.substring(5) : currentSelectedMeasurementId;
  const currentSelectedMeasurement = 
    !currentSelectedMeasurementId ? undefined :
    currentSelectedMeasurementIdIsBody ? userBodyMeasurementsDictionary?.[currentSelectedMeasurementIdCreationDateTime]?.[0] :
    userClothingMeasurementsDictionary[key2]?.[currentSelectedMeasurementIdCreationDateTime]?.[0];
  const currentSelectedMeasurementIsBody = currentSelectedMeasurement?.bodyOrClothingMeasurements === "BODY"; // Defaults to clothing

  let userBodyMeasurementCreationDateTimeList: string[] = Object.keys(userBodyMeasurementsDictionary).sort().reverse();
  let userClothingMeasurementCreationDateTimeList: string[] = userClothingMeasurementsDictionary[key2] ? Object.keys(userClothingMeasurementsDictionary[key2]).sort().reverse() : [];

  // Set selected measurement to the latest in the list if currentSelectedMeasurement is undefined
  const key2DefaultSelectedMeasurementCanBeSet = userBodyMeasurementCreationDateTimeList.length > 0 || userClothingMeasurementCreationDateTimeList.length > 0;
  const key2DefaultSelectedMeasurementCanBeSetRecommended = currentSelectedMeasurement === undefined && currentSelectedMeasurementId === '';

  return {
    currentSelectedMeasurementId: currentSelectedMeasurementId,
    currentSelectedMeasurementIdIsBody: currentSelectedMeasurementIdIsBody,
    currentSelectedMeasurementIdCreationDateTime: currentSelectedMeasurementIdCreationDateTime,
    currentSelectedMeasurement: currentSelectedMeasurement,
    currentSelectedMeasurementIsBody: currentSelectedMeasurementIsBody,
    key2DefaultSelectedMeasurementCanBeSet: key2DefaultSelectedMeasurementCanBeSet,
    key2DefaultSelectedMeasurementCanBeSetRecommended: key2DefaultSelectedMeasurementCanBeSetRecommended,
    currentSingleBodyMeasurementCreationDateTime: userPrefDict?.["BODY"] ? userPrefDict["BODY"] : ''
  }
}

// Membuat template objek measurement untuk regular size
export const getMeasurementObjectFromRegularSize = (creationDateTime: string, fitType: FitType, clothingType: ClothingType, regularSize: RegularSizeValue) => {
  const startingClothingBodyMeasurements = CalculateClothBodyMeasurementsFromBodyMeasurements(
    clothingType as ClothingType, 
    fitType as FitType, 
    BodyMeasurementsForSize[regularSize as RegularSizeValue], 
    RegularSizeValue.L, 
    []
  )
  const startingClothingMeasurementsUnrounded = CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, startingClothingBodyMeasurements, RegularSizeValue.L);
  const startingClothingMeasurements = RoundClothingMeasurementsToHalfPoint(startingClothingMeasurementsUnrounded);

  let UserClothTypeSpecificBodyMeasurements: UserClothTypeSpecificBodyMeasurements = {
    creationDateTime: creationDateTime,
    measurementName: "Uk Custom " + regularSize,
    regularSize: regularSize,
    bodyOrClothingMeasurements: "CLOTHING",
    creationMethod: "online-favorit",

    clothingMeasurements: startingClothingMeasurements,
    generatedMeasurements: startingClothingBodyMeasurements,
    adjustedMeasurements: startingClothingBodyMeasurements,
    
    caraBuatString: "Ukuran Favorit (Custom)",
  }

  return (UserClothTypeSpecificBodyMeasurements)
}

// Define the initial state using that type
const initialState: UserMeasurementsState = {
  userBodyMeasurementsDictionary: 
    localStorage.getItem('userBodyMeasurementsDictionary') !== null ? 
    JSON.parse(localStorage.getItem('userBodyMeasurementsDictionary') as string) :
    {},
  userClothingMeasurementsDictionary: 
    localStorage.getItem('userClothingMeasurementsDictionary') !== null ? 
    JSON.parse(localStorage.getItem('userClothingMeasurementsDictionary') as string) :
    {},

  userClothingMeasurementsPreferenceDictionary: 
    localStorage.getItem('userClothingMeasurementsPreferenceDictionary') !== null ? 
    JSON.parse(localStorage.getItem('userClothingMeasurementsPreferenceDictionary') as string) :
    {},

  userBodyMeasurementDraft: {},
  userClothingMeasurementDraft: {},

  userItemQRSize: {},
  userItemQRSizeId: "",
  userItemQRCartProductItem: {},

  userPersonalSizeInput: {
    userSub: ""
  },

  lastCreatedMeasurement: "",
  
  status: APIRequestStatus.Idle,
  error: null,

  postMeasurementStatus: APIRequestStatus.Idle,
  fetchMeasurementEstimateStatus: APIRequestStatus.Idle,
  fetchUserItemQRSizeStatus: APIRequestStatus.Idle,
  fetchUserPersonalSizeInputStatus: APIRequestStatus.Idle,
}

export const fetchUserItemQRSize = createAsyncThunk('userMeasurementsList/fetchUserItemQRSize', 
  async (payload: string, thunkAPI) => {

    var config: AxiosRequestConfig = {
      method: 'get',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/orderitem?orderitemid=' + payload,
      headers: { 
      }
    };
    const response = await axios(config);

    return response.data
  })

export const fetchUserPersonalSizeInputItemQR = createAsyncThunk('userMeasurementsList/fetchUserPersonalSizeInputItemQR', 
  async (payload: string, thunkAPI) => {

    var config: AxiosRequestConfig = {
      method: 'get',
      url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/personalsizeinput?usersub=' + payload
      + "&isitemqrscan=true",
      headers: { 
      }
    };
    const response = await axios(config);

    return response.data
  })

export const postUserPersonalSizeInput = createAsyncThunk(
  'userMeasurementsList/postUserPersonalSizeInput', 
  async (payload: UserPersonalSizeInput, thunkAPI) => {

    var config: AxiosRequestConfig = {
      method: 'post',
      url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/personalsizeinput',
      headers: {
      },
      data: payload
    };
    const response = await axios(config);

    return response.data
  });

export const uploadLocalUnsavedMeasAndSynchronize = createAsyncThunk('userMeasurementsList/uploadLocalUnsavedMeasAndSynchronize', 
  async (payload, thunkAPI) => {
    let userSignedIn = (thunkAPI.getState() as RootState).account.authState === 'signedin';
    let userSub = (thunkAPI.getState() as RootState).account.sub;

    if (!userSignedIn || !userSub) {
      return {};
    }

    let userBodyMeasurementsDictionary = (thunkAPI.getState() as RootState).userMeasurementsList.userBodyMeasurementsDictionary; 
    let userClothingMeasurementsDictionary = (thunkAPI.getState() as RootState).userMeasurementsList.userClothingMeasurementsDictionary; 

    let userBodyMeasurementCreationDateTimeList: string[] = Object.keys(userBodyMeasurementsDictionary);
    for (var i = 0; i < userBodyMeasurementCreationDateTimeList.length; i++) {
      for (var j = 0; j < userBodyMeasurementsDictionary[userBodyMeasurementCreationDateTimeList[i]].length; j++) {
        let measObj = userBodyMeasurementsDictionary[userBodyMeasurementCreationDateTimeList[i]][j];

        if (!measObj.idType) {
          const url = 'https://j2oavyj5rl.execute-api.ap-southeast-1.amazonaws.com/MeasurementAPIProduction/userbodymeasurement';
          const data = JSON.stringify({
            ...measObj,
            idType: 'userSub',
            userSub: userSub
          });
          await axios.post( url, data, {} );
        }
      }
    }

    let key2List = Object.keys(userClothingMeasurementsDictionary);
    for (var key2Index = 0; key2Index < key2List.length; key2Index++) {
      let userClothingMeasurementCreationDateTimeList: string[] = Object.keys(userClothingMeasurementsDictionary[key2List[key2Index]]);
      for (var i2 = 0; i2 < userClothingMeasurementCreationDateTimeList.length; i2++) {
        for (var j2 = 0; j2 < userClothingMeasurementsDictionary[key2List[key2Index]][userClothingMeasurementCreationDateTimeList[i2]].length; j2++) {
          let measObj = userClothingMeasurementsDictionary[key2List[key2Index]][userClothingMeasurementCreationDateTimeList[i2]][j2];
  
          if (!measObj.idType) {
            const url = 'https://j2oavyj5rl.execute-api.ap-southeast-1.amazonaws.com/MeasurementAPIProduction/userclothingmeasurement';
            const data = JSON.stringify({
              ...measObj,
              idType: 'userSub',
              userSub: userSub
            });
            await axios.post( url, data, {} );
          }
        }
      }
    }

    console.log("All local measurements uploaded!");

    thunkAPI.dispatch(fetchUserMeasurementsList());

    return {};
  })

export const fetchUserMeasurementsList = createAsyncThunk('userMeasurementsList/fetchUserMeasurementsList', 
  async (payload, thunkAPI) => {
    const rootState = (thunkAPI.getState() as RootState);
    const idToken = rootState.account.idToken;

    var config: AxiosRequestConfig = {
      method: 'get',
      url: 'https://j2oavyj5rl.execute-api.ap-southeast-1.amazonaws.com/MeasurementAPIProduction/measurements',
      headers: { 
        'Authorization': 'Bearer ' + idToken, 
      }
    };
    const response = await axios(config);

    return response.data
  })

export const fetchUserMeasurementEstimates = createAsyncThunk(
  'userMeasurementsList/fetchUserMeasurementEstimates', 
  async (payload, thunkAPI) => {
    const url = 'https://r194kp23ei.execute-api.ap-southeast-1.amazonaws.com/MeasurementEstimateAPIProduction';

    let userMeasurements = (thunkAPI.getState() as RootState).userMeasurementsList.userClothingMeasurementDraft; 

    const data = JSON.stringify(userMeasurements);

    console.log(userMeasurements);
    const response = await axios.post( url, data, {} );
    console.log(response.data);

    return response.data;
  });

  export const fetchUserMeasurementEstimatesBody = createAsyncThunk(
  'userMeasurementsList/fetchUserMeasurementEstimatesBody', 
  async (payload, thunkAPI) => {
    const url = 'https://r194kp23ei.execute-api.ap-southeast-1.amazonaws.com/MeasurementEstimateAPIProduction';

    let userMeasurements = (thunkAPI.getState() as RootState).userMeasurementsList.userBodyMeasurementDraft; 

    const data = JSON.stringify(userMeasurements);

    console.log(userMeasurements);
    const response = await axios.post( url, data, {} );
    console.log(response.data);

    return response.data;
  });

export const postClothingMeasurementPreference = createAsyncThunk(
  'userMeasurementsList/postClothingMeasurementPreference', 
  async (payload: UserClothingMeasurementsPreference, thunkAPI) => {
    let userSignedIn = (thunkAPI.getState() as RootState).account.authState === 'signedin';

    // You can only post your clothing measurement preference if you are signed in
    if (!userSignedIn) {
      return {
        responseData: '',
      };
    }

    let idToken = (thunkAPI.getState() as RootState).account.idToken;

    // const data = JSON.stringify(payload);

    var config: AxiosRequestConfig = {
      method: 'post',
      url: 'https://j2oavyj5rl.execute-api.ap-southeast-1.amazonaws.com/MeasurementAPIProduction/userclothingmeasurementpreference',
      headers: { 
        'Authorization': 'Bearer ' + idToken, // contains sub
      },
      data: payload
    };
    const response = await axios(config);

    let payloadResponse = {
      responseData: response.data,
    }

    return payloadResponse
  });


export const saveLocallyClothingMeasurementPreference = createAsyncThunk(
  'userMeasurementsList/saveLocallyClothingMeasurementPreference', 
  async (payload: UserClothingMeasurementsPreference, thunkAPI) => {

    console.log("Local save preferencee ", payload);

    let payloadResponse = {
      responseData: payload,
    }

    return payloadResponse
  });

// Posts the clothing measurement, and 
// if signed in re-fetches measurement list after and 
// if not signed in saves locally after.
export const postBodyMeasurement = createAsyncThunk(
  'userMeasurementsList/postBodyMeasurement', 
  async (payload: { clothingType: ClothingType, fitType: FitType } | "", thunkAPI) => {
    const url = 'https://j2oavyj5rl.execute-api.ap-southeast-1.amazonaws.com/MeasurementAPIProduction/userbodymeasurement';

    let userSignedIn = (thunkAPI.getState() as RootState).account.authState === 'signedin';
    let userSub = (thunkAPI.getState() as RootState).account.sub;
    let userMeasurements = (thunkAPI.getState() as RootState).userMeasurementsList.userBodyMeasurementDraft;
    console.log(userMeasurements);

    const creationDateTime = userMeasurements.creationDateTime;

    let response = {
      data: {}
    };

    if (userSignedIn) {
      const data = JSON.stringify({
        ...userMeasurements,
        idType: 'userSub',
        userSub: userSub
      });
  
      response = await axios.post( url, data, {} );
      
      if (payload !== "") {
        // Post new clothing measurement preference, before re-fetching
        // Every newly posted measurement is set as the new default selection
        await thunkAPI.dispatch(postClothingMeasurementPreference({
          key2: payload?.clothingType + "-" + payload?.fitType,
          creationDateTime: "body-" + userMeasurements.creationDateTime as string
        }));
      }

      // We set the new body measurement as the default BODY
      await thunkAPI.dispatch(postClothingMeasurementPreference({
        key2: "BODY",
        creationDateTime: "body-" + userMeasurements.creationDateTime as string
      }));

      // We start re-fetching user measurements after this response is received.
      thunkAPI.dispatch(uploadLocalUnsavedMeasAndSynchronize());

      // USER PERSONAL SIZE INPUT MODULE
      const userPersonalSizeInputNewEntry: UserPersonalSizeInput = {
        userSub: userSub,
        name: userMeasurements.nama,
        age: userMeasurements.umur,
        height: userMeasurements.height,
        weight: userMeasurements.weight,
        bodyType: userMeasurements.bodyType,
      }
      thunkAPI.dispatch(postUserPersonalSizeInput(userPersonalSizeInputNewEntry));
    } else {
      thunkAPI.dispatch(saveLocallyBodyMeasurement(payload));
    }

    if (creationDateTime) {
      thunkAPI.dispatch(setViewedBodyMeas(creationDateTime));
    }

    let payloadResponse = {
      responseData: response.data,
      creationDateTime: creationDateTime,
    }

    return payloadResponse
  });

// Posts the clothing measurement, and 
// if signed in re-fetches measurement list after and 
// if not signed in saves locally after.
export const postClothingMeasurement = createAsyncThunk(
  'userMeasurementsList/postClothingMeasurement', 
  async (payload, thunkAPI) => {
    const url = 'https://j2oavyj5rl.execute-api.ap-southeast-1.amazonaws.com/MeasurementAPIProduction/userclothingmeasurement';

    let userSignedIn = (thunkAPI.getState() as RootState).account.authState === 'signedin';
    let userSub = (thunkAPI.getState() as RootState).account.sub;
    let userMeasurements = (thunkAPI.getState() as RootState).userMeasurementsList.userClothingMeasurementDraft;
    console.log(userMeasurements);

    const creationDateTime = userMeasurements.creationDateTime;

    let response = {
      data: {}
    };

    let waitForRefetch = false;

    if (!userSignedIn && userMeasurements.userSub && userMeasurements.idType) {
      const data = JSON.stringify({
        ...userMeasurements,
      });

      response = await axios.post( url, data, {} );

      waitForRefetch = false;

    } else if (userSignedIn) {
      const data = JSON.stringify({
        ...userMeasurements,
        idType: 'userSub',
        userSub: userSub
      });

      response = await axios.post( url, data, {} );

      // Post new clothing measurement preference, before re-fetching
      // Every newly posted measurement is set as the new default selection
      await thunkAPI.dispatch(postClothingMeasurementPreference({
        key2: userMeasurements.clothingType + "-" + userMeasurements.fitType,
        creationDateTime: userMeasurements.creationDateTime as string
      }));

      // We start re-fetching user measurements after this response is received.
      thunkAPI.dispatch(uploadLocalUnsavedMeasAndSynchronize());

      // USER PERSONAL SIZE INPUT MODULE
      const userPersonalSizeInputNewEntry: UserPersonalSizeInput = {
        userSub: userSub,
        name: userMeasurements.nama,
        age: userMeasurements.umur,
        height: userMeasurements.height,
        weight: userMeasurements.weight,
        bodyType: userMeasurements.bodyType,
      }
      thunkAPI.dispatch(postUserPersonalSizeInput(userPersonalSizeInputNewEntry));

      waitForRefetch = true;
    } else {
      thunkAPI.dispatch(saveLocallyClothingMeasurement());

      waitForRefetch = false;
    }

    let payloadResponse = {
      responseData: response.data,
      creationDateTime: creationDateTime,
      waitForRefetch: waitForRefetch,
    }

    return payloadResponse
  });

export const saveLocallyBodyMeasurement = createAsyncThunk(
  'userMeasurementsList/saveLocallyBodyMeasurement', 
  async (payload: { clothingType: ClothingType, fitType: FitType } | "", thunkAPI) => {
    let userMeasurements = (thunkAPI.getState() as RootState).userMeasurementsList.userBodyMeasurementDraft;

    let payloadResponse = {
      responseData: userMeasurements,
    }

    console.log("User Measurements to save locally ", userMeasurements);

    if (payload !== "") {
      // IMPORTANT We automatically set the current local clothing measurement as the default
      thunkAPI.dispatch(saveLocallyClothingMeasurementPreference({
        key2: payload.clothingType + "-" + payload.fitType,
        creationDateTime: "body-" + (userMeasurements.creationDateTime as string)
      }))
    }
    
    thunkAPI.dispatch(saveLocallyClothingMeasurementPreference({
      key2: "BODY",
      creationDateTime: "body-" + (userMeasurements.creationDateTime as string)
    }))

    return payloadResponse
  });

export const saveLocallyClothingMeasurement = createAsyncThunk(
  'userMeasurementsList/saveLocallyClothingMeasurement', 
  async (payload, thunkAPI) => {
    let userMeasurements = (thunkAPI.getState() as RootState).userMeasurementsList.userClothingMeasurementDraft;

    let payloadResponse = {
      responseData: userMeasurements,
    }

    console.log("User Measurements to save locally ", userMeasurements);

    // IMPORTANT We automatically set the current local clothing measurement as the default
    thunkAPI.dispatch(saveLocallyClothingMeasurementPreference({
      key2: userMeasurements.clothingType + "-" + userMeasurements.fitType,
      creationDateTime: userMeasurements.creationDateTime as string
    }))

    return payloadResponse
  });

export const setKey2PreferencesMeasurement = createAsyncThunk(
  'userMeasurementsList/setKey2PreferencesMeasurement', 
  async (payload: string, thunkAPI) => {

  const key2 = payload;

  let state = (thunkAPI.getState() as RootState).userMeasurementsList;

  let userBodyMeasurementCreationDateTimeList: string[] = Object.keys(state.userBodyMeasurementsDictionary).sort().reverse();
  let userClothingMeasurementCreationDateTimeList: string[] = state.userClothingMeasurementsDictionary[key2] ? Object.keys(state.userClothingMeasurementsDictionary[key2]).sort().reverse() : [];

  if (userBodyMeasurementCreationDateTimeList.length > 0) {
    thunkAPI.dispatch(saveLocallyClothingMeasurementPreference({
      key2: key2,
      creationDateTime: state.userClothingMeasurementsPreferenceDictionary?.["BODY"] ? 
        state.userClothingMeasurementsPreferenceDictionary["BODY"] : // This already has body- in it
        "body-" + userBodyMeasurementCreationDateTimeList[0]
    }));
  } else if (userClothingMeasurementCreationDateTimeList.length > 0) {
    thunkAPI.dispatch(saveLocallyClothingMeasurementPreference({
      key2: key2,
      creationDateTime: userClothingMeasurementCreationDateTimeList[0]
    }));
  }

  return null;
});

export const orderSlice = createSlice({
  name: 'userMeasurementsList',
  initialState,
  reducers: {
    resetUserMeasurementList: (state) => {
      state.userBodyMeasurementsDictionary = {};
      state.userClothingMeasurementsDictionary = {};
      state.userClothingMeasurementsPreferenceDictionary = {};
      state.lastCreatedMeasurement = "";

      localStorage.setItem('userBodyMeasurementsDictionary', JSON.stringify(state.userBodyMeasurementsDictionary));
      localStorage.setItem('userClothingMeasurementsDictionary', JSON.stringify(state.userClothingMeasurementsDictionary));
      localStorage.setItem('userClothingMeasurementsPreferenceDictionary', JSON.stringify(state.userClothingMeasurementsPreferenceDictionary));
    },

    // Resets the user measurement draft.
    resetUserMeasurementDraft: (state) => {
      state.userBodyMeasurementDraft = {};
      state.userClothingMeasurementDraft = {};
      state.userPersonalSizeInput = { userSub: "" };
      state.userItemQRSize = {};
      state.userItemQRSizeId = "";
      state.userItemQRCartProductItem = {};
      state.fetchMeasurementEstimateStatus = APIRequestStatus.Idle;
      state.fetchUserItemQRSizeStatus = APIRequestStatus.Idle;
      state.fetchUserPersonalSizeInputStatus = APIRequestStatus.Idle;
    },

    inputMeasurementsForm: (state, action: PayloadAction<UserClothTypeSpecificBodyMeasurements>) => {
      Object.assign(state.userClothingMeasurementDraft, action.payload);

      // We make a deep copy for selected issues
      if (action.payload.selectedIssues) { 
        state.userClothingMeasurementDraft.selectedIssues = [...action.payload.selectedIssues];
      }

      console.log(JSON.stringify(state.userClothingMeasurementDraft));
    },

    inputBodyMeasurementsForm: (state, action: PayloadAction<UserBodyMeasurements>) => {
      Object.assign(state.userBodyMeasurementDraft, action.payload);

      // We make a deep copy for selected issues
      if (action.payload.selectedIssues) { 
        state.userBodyMeasurementDraft.selectedIssues = [...action.payload.selectedIssues];
      }

      console.log(JSON.stringify(state.userBodyMeasurementDraft));
    },

    loadUserClothingMeasurementToDraft: (state, action: PayloadAction<{
      clothingType: ClothingType,
      fitType: FitType,
      creationDateTime: string,
      updateDateTime: string,
    }>) => {
      state.userClothingMeasurementDraft = {};

      const {clothingType,fitType,creationDateTime,updateDateTime} = action.payload;
      const key2 = clothingType + "-" + fitType;
      if (key2 in state.userClothingMeasurementsDictionary
        && creationDateTime in state.userClothingMeasurementsDictionary[key2]) {
        const userMeasurementList = state.userClothingMeasurementsDictionary[key2][creationDateTime];
        for (var i = 0; i < userMeasurementList.length; i++) {
          const userMeasurementToLoad = userMeasurementList[i];
          if (userMeasurementToLoad.updateDateTime === updateDateTime) {
            Object.assign(state.userClothingMeasurementDraft, userMeasurementToLoad);
            
            // We make a deep copy for selected issues
            if (userMeasurementToLoad.selectedIssues) { 
              state.userClothingMeasurementDraft.selectedIssues = [...userMeasurementToLoad.selectedIssues];
            }

            return;
          }
        }
      } else {
        console.log("User Measurement To Load Is Not Found!");
      }
    },

    resetLastCreatedMeasurement: (state) => {
      state.lastCreatedMeasurement = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserMeasurementsList.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchUserMeasurementsList.fulfilled, (state, action) => {
        state.userClothingMeasurementsDictionary = {};
        state.userBodyMeasurementsDictionary = {};
        state.userClothingMeasurementsPreferenceDictionary = {};

        const userClothingMeasurementList: UserClothTypeSpecificBodyMeasurements[] 
        = action.payload['UserClothingMeasurement'];
        for (let i = userClothingMeasurementList.length - 1; i >= 0; i--) {
          let userClothingMeasurement = userClothingMeasurementList[i];
          let key2 = userClothingMeasurement.key2 as string;
          let creationDateTime = userClothingMeasurement.creationDateTime as string;
          if (state.userClothingMeasurementsDictionary[key2] === undefined) {
            state.userClothingMeasurementsDictionary[key2] = {};
          }
          if (state.userClothingMeasurementsDictionary[key2][creationDateTime] === undefined) {
            state.userClothingMeasurementsDictionary[key2][creationDateTime] = [];
          }
          state.userClothingMeasurementsDictionary[key2][creationDateTime].push(userClothingMeasurement);
        }

        const userBodyMeasurementList: UserBodyMeasurements[] 
        = action.payload['UserBodyMeasurement'];
        for (let i = userBodyMeasurementList.length - 1; i >= 0; i--) {
          let userBodyMeasurement = userBodyMeasurementList[i];
          let creationDateTime = userBodyMeasurement.creationDateTime as string;
          if (state.userBodyMeasurementsDictionary[creationDateTime] === undefined) {
            state.userBodyMeasurementsDictionary[creationDateTime] = [];
          }
          state.userBodyMeasurementsDictionary[creationDateTime].push(userBodyMeasurement);
        }

        const userClothingMeasurementPreferenceList: UserClothingMeasurementsPreference[] 
        = action.payload['UserClothingMeasurementPreference'];

        // Get current default body measurement
        let userSelectedBodyMeasurement = '';
        for (let i = 0; i < userClothingMeasurementPreferenceList.length; i++) {
          let userClothingMeasurementPreference = userClothingMeasurementPreferenceList[i];

          if (userClothingMeasurementPreference.key2 === "BODY") { // TEMPORARY FIX
            if (state.userBodyMeasurementsDictionary?.[userClothingMeasurementPreference.creationDateTime.substring(5)]?.[0]) {
              userSelectedBodyMeasurement = userClothingMeasurementPreference.creationDateTime;
            }
          }
        }

        for (let i = 0; i < userClothingMeasurementPreferenceList.length; i++) {
          let userClothingMeasurementPreference = userClothingMeasurementPreferenceList[i];

          // Put it into preferences if it exists
          if (userClothingMeasurementPreference?.creationDateTime && userClothingMeasurementPreference.creationDateTime.startsWith("body-")) {
            state.userClothingMeasurementsPreferenceDictionary[userClothingMeasurementPreference.key2] = userSelectedBodyMeasurement;

            // WHAT SHOULD HAPPEN UNDER NORMAL CIRCUMSTANCES
            // if (state.userBodyMeasurementsDictionary?.[userClothingMeasurementPreference.creationDateTime.substring(5)]?.[0]) {
            //   state.userClothingMeasurementsPreferenceDictionary[userClothingMeasurementPreference.key2] = userClothingMeasurementPreference.creationDateTime;
            // }
          } else if (userClothingMeasurementPreference?.creationDateTime && !userClothingMeasurementPreference.creationDateTime.startsWith("body-")) {
            if (state.userClothingMeasurementsDictionary?.[userClothingMeasurementPreference.key2]?.[userClothingMeasurementPreference.creationDateTime]?.[0]) {
              state.userClothingMeasurementsPreferenceDictionary[userClothingMeasurementPreference.key2] = userClothingMeasurementPreference.creationDateTime;
            }
          }
        }

        console.log(JSON.stringify(state.userClothingMeasurementsPreferenceDictionary, null, 2));

        localStorage.setItem('userBodyMeasurementsDictionary', JSON.stringify(state.userBodyMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsDictionary', JSON.stringify(state.userClothingMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsPreferenceDictionary', JSON.stringify(state.userClothingMeasurementsPreferenceDictionary));

        state.status = APIRequestStatus.Success;
        state.postMeasurementStatus = APIRequestStatus.Success; // Post measurement is only successful after re-fetch
      })
      .addCase(fetchUserMeasurementsList.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
        console.log("fetch is failure");
      })
      .addCase(fetchUserItemQRSize.pending, (state, action) => {
        state.fetchUserItemQRSizeStatus = APIRequestStatus.RequestInProgress;
        console.log("Fetching user personal size input ");
      })
      .addCase(fetchUserItemQRSize.fulfilled, (state, action) => {
        // Payload is already in object form
        console.log("Success: Received user personal size input ");

        if (action.payload?.success) {
          Object.assign(state.userItemQRCartProductItem, action.payload.content.cartProductItem);
          Object.assign(state.userItemQRSize, action.payload.content.cartProductItem.userMeasurementsForItem);
          state.userItemQRSizeId = action.payload.content.userItemQRSizeId;
        }

        state.fetchUserItemQRSizeStatus = APIRequestStatus.Success;
      })
      .addCase(fetchUserItemQRSize.rejected, (state, action) => {
        console.log("Failure: User personal size input error");
        state.fetchUserItemQRSizeStatus = APIRequestStatus.Failure;
      })
      .addCase(fetchUserPersonalSizeInputItemQR.pending, (state, action) => {
        state.fetchUserPersonalSizeInputStatus = APIRequestStatus.RequestInProgress;
        console.log("Fetching user personal size input ");
      })
      .addCase(fetchUserPersonalSizeInputItemQR.fulfilled, (state, action) => {
        // Payload is already in object form
        console.log("Success: Received user personal size input ");

        // We don't used the returned data in item qr user personal input get

        state.fetchUserPersonalSizeInputStatus = APIRequestStatus.Success;
      })
      .addCase(fetchUserPersonalSizeInputItemQR.rejected, (state, action) => {
        console.log("Failure: User personal size input error");
        state.fetchUserPersonalSizeInputStatus = APIRequestStatus.Failure;
      })
      .addCase(setKey2PreferencesMeasurement.pending, (state, action) => {
      })
      .addCase(setKey2PreferencesMeasurement.fulfilled, (state, action) => {
      })
      .addCase(setKey2PreferencesMeasurement.rejected, (state, action) => {
      })
      .addCase(postUserPersonalSizeInput.pending, (state, action) => {
        console.log("Post clothing measurement preference pending");
      })
      .addCase(postUserPersonalSizeInput.fulfilled, (state, action) => {
        console.log("Success: Sent user measurement data");
      })
      .addCase(postUserPersonalSizeInput.rejected, (state, action) => {
        console.log("Failure: User measurement data send error");
      })
      .addCase(fetchUserMeasurementEstimates.pending, (state, action) => {
        state.fetchMeasurementEstimateStatus = APIRequestStatus.RequestInProgress;
        console.log("Fetching user measurement estimate");
      })
      .addCase(fetchUserMeasurementEstimates.fulfilled, (state, action) => {
        // Payload is already in object form
        console.log("Success: Received user measurement estimate");

        state.userClothingMeasurementDraft.generatedMeasurements = action.payload.generatedMeasurements;
        state.userClothingMeasurementDraft.adjustedMeasurements = action.payload.adjustedMeasurements;

        if (action.payload.generatedMeasurementsRange) {
          state.userClothingMeasurementDraft.generatedMeasurementsRange = action.payload.generatedMeasurementsRange;
        }
        if (action.payload.generatedMeasurementsSafe) {
          state.userClothingMeasurementDraft.generatedMeasurementsSafe = action.payload.generatedMeasurementsSafe;
        }

        console.log(JSON.stringify(state.userClothingMeasurementDraft));
        console.log(JSON.stringify(state.userBodyMeasurementDraft));

        state.fetchMeasurementEstimateStatus = APIRequestStatus.Success;
      })
      .addCase(fetchUserMeasurementEstimates.rejected, (state, action) => {
        console.log("Failure: User measurement estimate request error");
        state.fetchMeasurementEstimateStatus = APIRequestStatus.Failure;
      })
      .addCase(fetchUserMeasurementEstimatesBody.pending, (state, action) => {
        state.fetchMeasurementEstimateStatus = APIRequestStatus.RequestInProgress;
        console.log("Fetching user measurement estimate");
      })
      .addCase(fetchUserMeasurementEstimatesBody.fulfilled, (state, action) => {
        // Payload is already in object form
        console.log("Success: Received user measurement estimate");

        state.userBodyMeasurementDraft.generatedMeasurements = action.payload.generatedMeasurements;
        state.userBodyMeasurementDraft.adjustedMeasurements = action.payload.adjustedMeasurements;

        if (action.payload.generatedMeasurementsRange) {
          state.userBodyMeasurementDraft.generatedMeasurementsRange = action.payload.generatedMeasurementsRange;
        }
        if (action.payload.generatedMeasurementsSafe) {
          state.userBodyMeasurementDraft.generatedMeasurementsSafe = action.payload.generatedMeasurementsSafe;
        }

        console.log(JSON.stringify(state.userClothingMeasurementDraft));
        console.log(JSON.stringify(state.userBodyMeasurementDraft));

        state.fetchMeasurementEstimateStatus = APIRequestStatus.Success;
      })
      .addCase(fetchUserMeasurementEstimatesBody.rejected, (state, action) => {
        console.log("Failure: User measurement estimate request error");
        state.fetchMeasurementEstimateStatus = APIRequestStatus.Failure;
      })
      .addCase(postClothingMeasurement.pending, (state, action) => {
        console.log("Post clothing measurement pending");
        state.postMeasurementStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(postClothingMeasurement.fulfilled, (state, action) => {
        console.log("Success: Sent user measurement data");

        if (!action.payload.waitForRefetch) {
          state.postMeasurementStatus = APIRequestStatus.Success;
        }
        
        state.lastCreatedMeasurement = action.payload.creationDateTime as string;
      })
      .addCase(postClothingMeasurement.rejected, (state, action) => {
        console.log("Failure: User measurement data send error");
        state.postMeasurementStatus = APIRequestStatus.Failure;
      })
      .addCase(postBodyMeasurement.pending, (state, action) => {
        console.log("Post body measurement pending");
      })
      .addCase(postBodyMeasurement.fulfilled, (state, action) => {
        console.log("Success: Sent user body measurement data");

        state.lastCreatedMeasurement = "body-" + (action.payload.creationDateTime as string);
      })
      .addCase(postBodyMeasurement.rejected, (state, action) => {
        console.log("Failure: User body measurement data send error");
      })
      .addCase(saveLocallyBodyMeasurement.pending, (state, action) => {
        console.log("Save locally body measurement pending");
      })
      .addCase(saveLocallyBodyMeasurement.fulfilled, (state, action) => {
        console.log("Success: Save locally body measurement");

        let userBodyMeasurement = state.userBodyMeasurementDraft;
        let creationDateTime = userBodyMeasurement.creationDateTime as string;
        if (state.userBodyMeasurementsDictionary[creationDateTime] === undefined) {
          state.userBodyMeasurementsDictionary[creationDateTime] = [];
        }
        state.userBodyMeasurementsDictionary[creationDateTime].push(userBodyMeasurement);
        console.log("Save locally body measurement tester1 ");

        // TODO Body measurements preferences is still not good. When a new body measurement is made, it is not preferred.

        state.lastCreatedMeasurement = "body-" + creationDateTime;

        // We update cookies everytime this no-sign-up measurements get added
        localStorage.setItem('userBodyMeasurementsDictionary', JSON.stringify(state.userBodyMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsDictionary', JSON.stringify(state.userClothingMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsPreferenceDictionary', JSON.stringify(state.userClothingMeasurementsPreferenceDictionary));
      })
      .addCase(saveLocallyBodyMeasurement.rejected, (state, action) => {
        console.log("Failure: User body measurement data send error");
      })
      .addCase(saveLocallyClothingMeasurement.pending, (state, action) => {
        console.log("Save locally clothing measurement pending");
      })
      .addCase(saveLocallyClothingMeasurement.fulfilled, (state, action) => {
        console.log("Success: Save locally clothing measurement");

        let userClothingMeasurement = state.userClothingMeasurementDraft;
        let key2 = userClothingMeasurement.clothingType + "-" + userClothingMeasurement.fitType;
        let creationDateTime = userClothingMeasurement.creationDateTime as string;
        if (state.userClothingMeasurementsDictionary[key2] === undefined) {
          state.userClothingMeasurementsDictionary[key2] = {};
        }
        if (state.userClothingMeasurementsDictionary[key2][creationDateTime] === undefined) {
          state.userClothingMeasurementsDictionary[key2][creationDateTime] = [];
        }
        state.userClothingMeasurementsDictionary[key2][creationDateTime].push(userClothingMeasurement);
        console.log("Save locally clothing measurement tester1 ");

        state.lastCreatedMeasurement = creationDateTime;

        // We update cookies everytime this no-sign-up measurements get added
        localStorage.setItem('userBodyMeasurementsDictionary', JSON.stringify(state.userBodyMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsDictionary', JSON.stringify(state.userClothingMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsPreferenceDictionary', JSON.stringify(state.userClothingMeasurementsPreferenceDictionary));
      })
      .addCase(saveLocallyClothingMeasurement.rejected, (state, action) => {
        console.log("Failure: User measurement data send error");
      })
      .addCase(postClothingMeasurementPreference.pending, (state, action) => {
        console.log("Post clothing measurement preference pending");
      })
      .addCase(postClothingMeasurementPreference.fulfilled, (state, action) => {
        console.log("Success: Sent user measurement data");
      })
      .addCase(postClothingMeasurementPreference.rejected, (state, action) => {
        console.log("Failure: User measurement data send error");
      })
      .addCase(uploadLocalUnsavedMeasAndSynchronize.pending, (state, action) => {
        console.log("Post clothing measurement preference pending");
      })
      .addCase(uploadLocalUnsavedMeasAndSynchronize.fulfilled, (state, action) => {
        console.log("Success: Sent user measurement data");
      })
      .addCase(uploadLocalUnsavedMeasAndSynchronize.rejected, (state, action) => {
        console.log("Failure: User measurement data send error");
      })
      .addCase(saveLocallyClothingMeasurementPreference.pending, (state, action) => {
        console.log("Save locally clothing measurement preference pending");
      })
      .addCase(saveLocallyClothingMeasurementPreference.fulfilled, (state, action) => {
        console.log("Success: Saving locally preference");

        let key2 = action.payload.responseData.key2;
        let creationDateTime = action.payload.responseData.creationDateTime;
        state.userClothingMeasurementsPreferenceDictionary[key2] = creationDateTime;

        // We also update all the other values in the dictionary
        if (key2 === "BODY") {
          const key2List = Object.keys(state.userClothingMeasurementsPreferenceDictionary);
          for (let i = 0; i < key2List.length; i++) {
            let currentKey2 = key2List[i];

            if (state.userClothingMeasurementsPreferenceDictionary[currentKey2].startsWith("body-")) {
              state.userClothingMeasurementsPreferenceDictionary[currentKey2] = creationDateTime;
            }
          }
        }

        console.log(JSON.stringify(state.userClothingMeasurementsPreferenceDictionary, null, 2));

        // We update cookies everytime this no-sign-up measurements get added
        localStorage.setItem('userBodyMeasurementsDictionary', JSON.stringify(state.userBodyMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsDictionary', JSON.stringify(state.userClothingMeasurementsDictionary));
        localStorage.setItem('userClothingMeasurementsPreferenceDictionary', JSON.stringify(state.userClothingMeasurementsPreferenceDictionary));
      })
      .addCase(saveLocallyClothingMeasurementPreference.rejected, (state, action) => {
        console.log("Failure: User measurement data send error");
      })
  }
})

// Action creators are generated for each case reducer function
export const { resetUserMeasurementDraft, inputMeasurementsForm, inputBodyMeasurementsForm, resetLastCreatedMeasurement,
  loadUserClothingMeasurementToDraft, resetUserMeasurementList } = orderSlice.actions

export default orderSlice.reducer
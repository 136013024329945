import { Box, Button, Fade, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink } from "react-router-dom";
import { Mixpanel } from '../mixpanel';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import PageComponentTitleOnBackgroundVideo from './PageComponentTitleOnBackgroundVideo';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  contentContainer: {
    textAlign: 'center',
    padding: '20px 20px 40px 20px',
    width: '100vw',
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
      padding: '20px 20px 104px 36px',
      textAlign: 'left',
    },
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: '3vw',
    marginBottom: '1.2vw',
    [theme.breakpoints.down('xs')]: {
      fontSize: '6vw',
      lineHeight: '8vw',
      marginBottom: '2.4vw',
      textAlign: 'left',
    },
    color: '#EFEFEF',
    lineHeight: '4.2vw',
    letterSpacing: '0.02em',
  },
  contentText: {
    fontWeight: 300,
    fontSize: '1.5vw',
    margin: '0px 0px 2.4vw 0px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '3vw',
      lineHeight: '3.3vw',
      marginBottom: '4.6vw',
    },
    color: '#EFEFEF',
    lineHeight: '2.1vw',
  },
  verticalSpacer: {
    height: '100px',
    borderLeft: '1px solid white',
    margin: '0px 0px 30px 0px',
    [theme.breakpoints.down('xs')]: {
      height: '0px',
      marginBottom: '0px',
    },
  },
  contentButton: {
    textAlign: 'center',
    fontFamily: 'Roboto Mono, sans-serif',
    letterSpacing: '0.1em',
    backgroundColor: '#EFEFEF',
    borderRadius: '40px',
    color: '#222222',
    '&:hover': {
      backgroundColor: '#222222',
      color: '#EFEFEF',
    },
    padding: '10px 44px 10px 44px',
    marginBottom: '3.6vw',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '4vh',
      padding: '8px 40px 8px 40px',
    }
  }
}));

interface Props {
}

export default function GreatFitGreatLooks({ }: Props) {
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  // Intersection Observer API
  const { ref, inView, entry } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const content = (
    <div ref={ref}>
      <Fade in={inView} timeout={800}>
        <Box className={classes.contentContainer}>
          <Typography variant="h2" className={classes.contentTitle}>
            Fit Baju Terbaik, <br></br>Tiap Kali Beli Online.
          </Typography>
          <Typography variant="h2" className={classes.contentText}>
            Semua baju yang kamu pesan disini akan datang dengan<br></br>
            ukuran yang memberikan penampilan terbaik kamu.
          </Typography>
          <Button variant="contained" className={classes.contentButton} size="large"
          component={RouterLink} to={categoryMap["SHOP"]} 
          onClick={() => {
            (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/shop', 'routeSource': 'headerButton'});
            Mixpanel.track('navigateRoute', {
              'routePath': '/shop',
              'routeSource': 'headerButton',
            });
            Mixpanel.track('goToShopPage', {
              'routeSource': 'headerButton',
            });
          }}>
            <b>BELANJA SEKARANG</b>
          </Button>
        </Box>
      </Fade>
    </div>
  )

  const contentMobile = (
    <div ref={ref}>
      <Fade in={inView} timeout={800}>
        <Box className={classes.contentContainer}>
          <Typography variant="h2" className={classes.contentTitle}>
            Fit Baju Terbaik, <br></br>Tiap Kali Beli Online.
          </Typography>
          <Typography variant="h2" className={classes.contentText}>
            Semua baju yang kamu pesan disini akan datang dengan<br></br>
            ukuran yang memberikan penampilan terbaik kamu.
          </Typography>
          <Button variant="contained" className={classes.contentButton} size="large"
          component={RouterLink} to={categoryMap["SHOP"]} 
          onClick={() => {
            (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/shop', 'routeSource': 'headerButton'});
            Mixpanel.track('navigateRoute', {
              'routePath': '/shop',
              'routeSource': 'headerButton',
            });
            Mixpanel.track('goToShopPage', {
              'routeSource': 'headerButton',
            });
          }}>
            <b>BELANJA SEKARANG</b>
          </Button>
        </Box>
      </Fade>
    </div>
  )

  const desktopContent = (
    <PageComponentTitleOnBackgroundVideo
      posterUrl='https://d31zz7s33c4k72.cloudfront.net/PosterVideoHeader_2.jpg'
      videoUrl='https://d31zz7s33c4k72.cloudfront.net/VideoHeaderSatuUkuranDesktop.mp4'
      mediaWidth='100%'
      content={content}
    />);


  const mobileContent = (
    <PageComponentTitleOnBackgroundVideo
    posterUrl='https://d31zz7s33c4k72.cloudfront.net/PosterVideoHeader_2.jpg'
      videoUrl='https://d31zz7s33c4k72.cloudfront.net/VideoHeaderSatuUkuranMobile.mp4'
      mediaWidth='100%'
      content={contentMobile}
    />);

  return isSmOrUp ? desktopContent : mobileContent;
}
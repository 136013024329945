import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import OverlayComponentBaseContainerVertical from './OverlayComponentBaseContainerVertical';
import OverlayComponentTitle from './OverlayComponentTitle';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';

interface StyleProps {
}

const useStyles = ({ }: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
}),
);

interface Props {
  justifyContent?: string
  alignItems?: string
  children?: JSX.Element | JSX.Element[];
}

export default function OverlayComponentForm({ justifyContent, alignItems, children }: Props) {
  const classes = useStyles({})();

  return (
    <OverlayComponentBaseContainerVertical
      justifyContent={ justifyContent ? justifyContent : 'flex-start'}>
      <OverlayComponentBaseContainerWidth
        widthSmDown="100%"
        widthMdUp="100%">
        <OverlayComponentBaseContainerHorizontal
          alignItems={ alignItems ? alignItems : 'center'}>
          {
            children
          }
        </OverlayComponentBaseContainerHorizontal>
      </OverlayComponentBaseContainerWidth>
    </OverlayComponentBaseContainerVertical>
  );
}
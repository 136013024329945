import { Box, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Image } from '@material-ui/icons';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    paddingTop: '80px',
    paddingBottom: '80px',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'inherit'
    }
  },
  segmentContainer: {
    width: '100%',
    [theme.breakpoints.up("sm")]: {
      width: '85%',
    }
  },
  titleTopText: {
    display: 'inline-block',
    border: '1px solid black',
    padding: '0 5px',
    borderRadius: '25px',
    fontWeight: 500,
    letterSpacing: '0.05em',
    fontFamily: 'Roboto Mono',
    fontSize: '1vw',
    marginBottom: '0.9vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.151vw',
      float: 'left',
      margin: '3vw 0 3vw 10vw',
    }
  },
  titleText: {
    fontFamily: 'TT Hoves, Open sans, sans-serif',
    fontWeight: 400,
    color: '#343434',
    fontSize: '2.5vw',
    textAlign: 'center',
    marginBottom: '4vw',
    padding: '0 25vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '5.6vw',
      padding: '0 12vw',
    },
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentHeader: {
    margin: '0'
  },
  contentInnerContainer: {
    [theme.breakpoints.only('xs')]: {
      margin: '0 10vw',
      width: '72vw',
    },
    margin: '15px 0',
  },
  contentTitleNumber: {
    fontSize: '3vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '12.9vw',
    },
    marginTop: '1vw',
    marginBottom: '1.4vw',
    fontFamily: 'Roboto Mono',
    fontWeight: 500,
    color:'black',
  },
  contentTitle: {
    fontWeight: 400,
    fontSize: '2.5vw',
    padding: '1vw',
    [theme.breakpoints.only('xs')]: {
      minHeight: '8vw',
      fontSize: '3.5vw',
      lineHeight: '6.8vw',
      padding: '0 5vw'
    },
    fontFamily: 'OakesGrotesk',
    color: '#343434',
    lineHeight: '2.2vw',
  },
  contentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '1.4vw',
    lineHeight: '2.1vw',
    fontFamily: 'TT Hoves, sans-serif',
    color: '#343434',
    letterSpacing: '0.3px',
    [theme.breakpoints.only('xs')]: {
        fontSize: '3.2vw',
        lineHeight: '5vw',
    },
  },
  imageContentContainer: {
    width: '30vw',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '72vw',
      marginBottom: '10vw'
    },
  },
  imageContent: {
    width: '100%',
  },
  imageTopDiv:{
    display: 'flex',
    width: '90%',
    position: 'absolute',
    top: '-1.85vw',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      top: '-3.7vw',
    },
  },
  imageBottomDiv: {
    display: 'flex',
    position: 'absolute',
    bottom: '-1.65vw',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.only('xs')]: {
      bottom: '-3.8vw',
      width: '100%',
    },
  },
  imageText:{
    fontSize: '2.5vw',
    fontFamily: 'Roboto Mono',
    fontWeight: 500,
    letterSpacing: '0.08em',
    [theme.breakpoints.only('xs')]: {
      fontSize: '5vw'
    },
  },
  linkPelajariSizing: {
    color: 'primary',
  },
  underlinedText: {
    textDecoration: 'underline',
  },
  tmTitle: {
    fontWeight: 700,
    fontSize: '20px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
  },
  tm: {
    fontWeight: 400,
    fontSize: '1.5vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2vw',
    },
  },
  tabsControl: {
    minHeight: 'unset',
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },
  tabTitle: {
    fontSize: '1.4vw',
    lineHeight: '2.1vw',
    color: '#111111',
    fontFamily: 'Roboto Mono',
    letterSpacing: '0.08em',
    borderLeft: '0.5px solid #D9D9D9',
    borderRight: '0.5px solid #D9D9D9',
    borderTop: '0.5px solid #D9D9D9',
    minHeight: '4vw',
    minWidth: '12vw',
    [theme.breakpoints.only('xs')]: {
      minHeight: '8vw',
      fontSize: '3.5vw',
      lineHeight: '6.8vw',
      padding: '0 5vw'
    },
    '&.Mui-selected': {
      backgroundColor: '#D9D9D9',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  textBoxBordered: {
    // For double border
    textAlign: 'center',
    position: 'relative',
    zIndex: 0,
    border: '0.5px solid',
    borderRadius: '1.5vw',

    width: '28vw',
    padding: '3vw',
    [theme.breakpoints.only('xs')]: {
        width: '60vw',
        padding: '7vw',
        borderRadius: '4vw'
    },

    '&:before': {
      content: `""`,
      position: 'absolute',
      display: 'block',
      background: 'inherit',
      borderRadius: 'inherit',
      border: 'inherit',
      left: '-4px',
      top: '4px',
      width: '100%',
      height: '100%',
      zIndex: '-1',
    }
  }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ 
            bgcolor: '#D9D9D9'
            }}>
            <Box sx={{
              p: '10vw'
            }}>{children}</Box>
          </Box>
        )}
      </div>
    );
  }
  
  function allyProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function KataMereka() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  // Tab item
  const tabItem = (
    <Box className={classes.contentContainer}>
        <Box className={`${classes.contentInnerContainer} ${classes.imageContentContainer}`}>
          <img className={classes.imageContent} src='https://cdn.stockmediaserver.com/smsimg28/pv/IsignstockContributors/ISS_18592_00810.jpg?token=mmuF90NWPaPvlcsEdBd-etjp7oGoUTQLxt0UAVoNa4Y&class=pv&smss=53&expires=4102358400' />
          <Box className={classes.imageTopDiv}>
            <Typography className={classes.imageText}>BENNY</Typography>
            <Typography className={classes.imageText}>{`(M)`}</Typography>
          </Box>
          <Box className={classes.imageBottomDiv}>
            <Typography className={classes.imageText}>68KG</Typography>
            <Typography className={classes.imageText}>175CM</Typography>
            <Typography className={classes.imageText}>29YR</Typography>
          </Box>
        </Box>
        <Box className={classes.contentInnerContainer}>
            <Box className={classes.textBoxBordered}>
              <Typography className={classes.contentText}>
                  “Bahu terlalu sempit. Bagian lengan mengangkat. Panjangnya ngatung di tinggi 175cm, jadi agak aneh.”
              </Typography>
            </Box>
        </Box>
    </Box>
  )


  // For tab control
  const [value, setValue] = useState(0);  
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box>
        <Typography className={classes.titleText}>
          Kata mereka yang sudah coba baju <span className={classes.underlinedText}>#SesuaiUkuran<sup className={classes.tm}>TM</sup></span> dari Studio S.:
        </Typography>
      </Box>
      {/* {
        isMdOrUp ?
        <Typography className={classes.titleText}>
          Malas Ukur?  <br></br>Algoritma Pintar<sup className={classes.tmTitle}>TM</sup> Kami Siap Bantu
        </Typography> :
        <Typography className={classes.titleText}>
          Malas Ukur?  <br></br>
          Algoritma Pintar<sup className={classes.tmTitle}>TM</sup> <br></br>
          Kami Siap Bantu
        </Typography>
      } */}
      <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.segmentContainer}>
      <Box sx={{ width: '100%' }}>
      <Box className={classes.contentHeader}>
        <Tabs
        className={classes.tabsControl}
        value={value}
        textColor="inherit"
        onChange={handleChange} 
        aria-label="basic tabs example">
          <Tab label="BENNY" {...allyProps(0)} className={classes.tabTitle}/>
          <Tab label="YUSUF" {...allyProps(1)} className={classes.tabTitle}/>
          <Tab label="SARAH" {...allyProps(2)} className={classes.tabTitle}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {tabItem}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tabItem}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {tabItem}
      </TabPanel>
    </Box>
      </Grid>
    </Box>
  )
}
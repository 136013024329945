import { UIOverlayViewStatus } from "common-ts/dist/models/UIOverlayViewStatus";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ClothingType,
  FitType,
  UserBodyMeasurements,
  UserClothTypeSpecificBodyMeasurements,
} from "common-ts/dist/models/UserMeasurements";
import ProductOnUser from "common-ts/dist/models/Product";

export interface MeasureSignUpFlow {
  status: UIOverlayViewStatus;
  clothingType: ClothingType;
  fitType: FitType;
  isFullScreen: boolean;
  productDetail: ProductOnUser | undefined;
  pilihUkuranData: any;
}

// Define the initial state using that type
const initialState: MeasureSignUpFlow = {
  status: UIOverlayViewStatus.OffAndReady,
  clothingType: ClothingType.Kaos,
  fitType: FitType.SlimFit,
  isFullScreen: false,
  productDetail: undefined,
  pilihUkuranData: {
    key2: '',
    creationDateTime: '',
    prefCreationDateTime: '',
    isOwnedByUser: false
  },
};

export const overlayMeasurementSelectSlice = createSlice({
  name: "overlayMeasurementSelect",
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;
      // state.clothingType = ClothingType.Kaos;
      // state.fitType = FitType.SlimFit;
      state.productDetail = undefined;
      state.isFullScreen = false;
    },
    setPilihUkuranDataValues: (
      state,
      action: PayloadAction<{
        key2: string;
        creationDateTime: string;
        prefCreationDateTime: string;
        isOwnedByUser: boolean;
      }>
    ) => {
      state.pilihUkuranData = { ...action.payload };
    },
    setValues: (
      state,
      action: PayloadAction<{
        clothingType: ClothingType;
        fitType: FitType;
        isFullScreen: boolean;
        productDetail?: ProductOnUser;
      }>
    ) => {
      state.clothingType = action.payload.clothingType;
      state.fitType = action.payload.fitType;
      state.isFullScreen = action.payload.isFullScreen;
      if (action?.payload?.productDetail) {
        state.productDetail = action.payload.productDetail;
      }
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;
    },
  },
  extraReducers(builder) {},
});

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, setValues, setPilihUkuranDataValues } =
  overlayMeasurementSelectSlice.actions;

export default overlayMeasurementSelectSlice.reducer;

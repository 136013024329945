import { Box, Link, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  caraBelanjaContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    paddingTop: '80px',
    paddingBottom: '80px',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'inherit'
    }
  },
  segmentContainer: {
    width: '100%',
    [theme.breakpoints.up("sm")]: {
      width: '85%',
    }
  },
  titleTopText: {
    display: 'inline-block',
    border: '1px solid black',
    padding: '0 5px',
    borderRadius: '25px',
    fontWeight: 500,
    letterSpacing: '0.05em',
    fontFamily: 'Roboto Mono',
    fontSize: '1vw',
    marginBottom: '0.9vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.151vw',
      float: 'left',
      margin: '3vw 0 3vw 10vw',
    }
  },
  titleText: {
    fontFamily: 'Space Grotesk, sans-serif',
    fontWeight: 400,
    color: '#343434',
    fontSize: '3.9vw',
    textAlign: 'center',
    marginBottom: '2vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '5.645vw',
      margin: '0 0 3vw 10vw',
      textAlign: 'left',
    },
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'inherit'
    }
  },
  contentInnerContainer: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 10vw',
      width: '80vw',
    },
    margin: '15px 0',
  },
  contentTitleNumber: {
    fontSize: '3vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '12.9vw',
    },
    marginTop: '1vw',
    marginBottom: '1.4vw',
    fontFamily: 'Roboto Mono',
    fontWeight: 500,
    color:'black',
  },
  contentTitle: {
    fontWeight: 400,
    fontSize: '1.45vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '5.5vw',
      lineHeight: '6.8vw',
    },
    marginBottom: '2vw',
    fontFamily: 'OakesGrotesk',
    color: '#343434',
    lineHeight: '2.2vw',
  },
  contentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '1vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.2vw',
      lineHeight: '5vw',
    },
    lineHeight: '2.1vw',
    fontFamily: 'TT Hoves, sans-serif',
    color: '#343434',
  },
  linkPelajariSizing: {
    color: 'primary',
  },
  underlinedText: {
    textDecoration: 'underline',
  },
  tmTitle: {
    fontWeight: 700,
    fontSize: '20px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
  },
  tm: {
    fontWeight: 200,
    fontSize: '0.5vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2vw',
    },
  }
}));

export default function Environment() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <Box className={classes.caraBelanjaContainer}>
      <Box>
        <Typography className={classes.titleTopText}>
          CARA BELANJA
        </Typography>
      </Box>
      {
        isMdOrUp ?
        <Typography className={classes.titleText}>
          Malas Ukur?  <br></br>Algoritma Pintar<sup className={classes.tmTitle}>TM</sup> Kami Siap Bantu
        </Typography> :
        <Typography className={classes.titleText}>
          Malas Ukur?  <br></br>
          Algoritma Pintar<sup className={classes.tmTitle}>TM</sup> <br></br>
          Kami Siap Bantu
        </Typography>
      }
      <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.segmentContainer}>
        <Grid item xs={12} sm={4} md={4}>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentInnerContainer}>
              <Typography variant="h2" className={classes.contentTitleNumber}>
                01.
              </Typography>
              <Typography variant="h2" className={classes.contentTitle}>
                Pilih baju
              </Typography>
              <Typography variant="h4" className={classes.contentText}>
                Masuk ke halaman <span className={classes.underlinedText}>Shop</span> untuk melihat semua pilihan 
                style yang tersedia di SUR, lalu pilih baju-baju
                yang paling kamu suka
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentInnerContainer}>
              <Typography variant="h2" className={classes.contentTitleNumber}>
                02.
              </Typography>
              <Typography variant="h2" className={classes.contentTitle}>
                Fitting Ukuran
              </Typography>
              <Typography variant="h4" className={classes.contentText}>
                Algoritma <span className={classes.underlinedText}>Smart Measure<sup className={classes.tm}>TM</sup></span> kami telah dilatih
                menggunakan 5,492 poin data yang kami kumpulkan
                untuk menentukan ukuran terbaik buat kamu.
                {/* <br></br> */}
                {/* <Link href="#" onClick={()=> {}} className={classes.linkPelajariSizing}>
                  Pelajari lebih lanjut Personal Size <sup>TM</sup>.
                </Link> */}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentInnerContainer}>
              <Typography variant="h2" className={classes.contentTitleNumber}>
                03.
              </Typography>
              <Typography variant="h2" className={classes.contentTitle}>
                Checkout
              </Typography>
              <Typography variant="h4" className={classes.contentText}>
              Lengkapi detil pengiriman dan pembayaran untuk menyelesaikan <i>checkout</i>. 
              Baju ukuran <i>custom</i> kamu akan dikirim dalam waktu dua hari saja!
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
import { Box, Button, Dialog, IconButton, Paper, Slide, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { closeOverlayAndReset, setCurrentFlowScreen } from '../redux/UIOverlayFlowVersionRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentButton from './OverlayComponentButton';
import { Close, FiberManualRecord } from '@material-ui/icons';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ReactHtmlParser from 'react-html-parser';
import
{
    AnimatePresence,
    motion,
    MotionProps,
    PanInfo
} from "framer-motion";
import { StyledButtonWrapper, StyledIndicatorIconButtonActive, StyledIndicatorIconButtonNonActive, StyledIndicators } from './OverlayViewPopupFlowVersionStyles';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
      '& .MuiPaper-root': { 
        width: "80vw",
        borderRadius: "5.4vw",
      },
      '& .MuiBackdrop-root': { 
        backgroundColor: "rgba(0, 0, 0, 0.7)"
      } 
    },
    dialogContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
      textAlign: 'center',
    },
    dialogDivImage: {
      width: "100%",
      height: "56vw",
      overflow: "hidden",
      position: "relative"
    },
    dialogDivImageCustom: {
      width: "100%",
      height: "61vw",
      overflow: "hidden",
      position: "relative"
    },
    mainImg: {
      width: "102%",
      height: "56vw",
      objectFit: "cover"
    },
    mainImgCustom: {
      width: "102%",
      height: "61vw",
      objectFit: "cover"
    },
    dialogDivImageCloseButton: {
      position: "absolute",
      top: "0",
      right: "0",
      margin: "4px",
      color: "#ffffff",
      zIndex: 2,
    },
    dialogDivTextSvg: {
      width: "100%"
    },
    dialogDivText: {
      width: "calc(100% - 8vw)",
      padding: "4vw",
    },
    tanpaPerluUkurSubheaderText: {
      margin: "0.5vw 0 1.6vw 0",
      fontSize: "2.6vw",
      letterSpacing: "0.2vw",
      textAlign: "left",
      fontWeight: 300,
      color: "#232323",
    },
    tanpaPerluUkurTitleText: {
      margin: "1vw 0 2vw 0",
      fontSize: "5.2vw",
      textAlign: "left",
      fontWeight: 600,
      color: "#232323",
    },
    tanpaPerluUkurContentText: {
      margin: "1vw 0 0vw 0",
      fontSize: "2.8vw",
      textAlign: "left",
      fontWeight: 300,
      lineHeight: "5vw",
    },
    mainTextSvg: {
      width: "calc(100% - 9vw)",
      marginTop: "5vw",
      marginBottom: "2vw"
    },
    dialogDivButtons: {
      width: "100%",
      height: "18vw",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    dialogDivCarousel: {
      width: "100%",
      height: "18vw",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      position: "relative",
    },
    styledIndicators: {
      width: "100%",
      marginTop: "2px",
      textAlign: "center"
    },
    styledIndicatorsHidden: {
      width: "100%",
      marginTop: "2px",
      textAlign: "center",
      opacity: "0"
    },
    indicatorSvgIcon: {
      width: "0.52em",
      margin: "0.05em"
    },
    secondaryFinishButtonOverlay: {
      position: "absolute",
      right: "145px",
      bottom: "15px",
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#232323',
      fontSize: '3.7vw',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
    },
    finishButtonOverlay: {
      position: "absolute",
      right: "10px",
      bottom: "13px",
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#ffffff',
      backgroundColor: '#121212',
      borderRadius: '30px',
      padding: '9px 20px 8px 20px',
      fontSize: '3.7vw',
      '&:hover': {
        backgroundColor: '#eaeaea',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
    },
    firstButton: {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#ffffff',
      backgroundColor: '#121212',
      borderRadius: '30px',
      padding: '9px 20px 8px 20px',
      fontSize: '3.7vw',
      '&:hover': {
        backgroundColor: '#eaeaea',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
      margin: "0 14px 0 14px"
    },
    secondButton: {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#121212',
      fontSize: '3.7vw',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
    },
    closeBarMobile: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end', 
    },
    textTitle: {
      fontSize: '17px',
      marginBottom: '20px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    textContent: {
      fontSize: '12px',
      marginBottom: '20px',
      marginLeft: '20px',
      marginRight: '20px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    textContent2: {
      fontSize: '13px',
      marginBottom: '40px',
      marginLeft: '20px',
      marginRight: '20px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        marginBottom: '40px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    styledItem: {
      position: "absolute",
      height: "100%",
      width: '100%',
    },
    styledRoot: {
      position: "relative",
      overflow: "hidden",
    },
    styledItemWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    hiddenArrowIcon: {
      opacity: 0,
    }
  }),
);

interface TanpaPerluUkurComponentData {
  CloseButtonColorWhite: boolean,
  SubHeader: string,
  Name: string, 
  Content: string, 
  Image: string
}

const tanpaPerluUkurSteps: TanpaPerluUkurComponentData[] = [
  {
      CloseButtonColorWhite: true,
      SubHeader: "",
      Name: "Buat Ukuran Custom,<br>Tanpa Perlu Ukur Badan",
      Content: "Kami menyediakan 2 cara mudah membuat ukuran <u>tanpa perlu mengukur badan ataupun pita ukur.</u>",
      Image: "https://images-far-east.s3.ap-southeast-1.amazonaws.com/hardintro1-image-intro-jpeg-adobe.jpg",
  },
  {
      CloseButtonColorWhite: true,
      SubHeader: "METODE 1",
      Name: "Pakai Algoritma Pintar<sup>TM</sup>",
      Content: "Algoritma Pintar<sup>TM</sup> membuat ukuran personal dari <br>tinggi, berat dan tipe badan kamu. Dirancang untuk memberikan hasil yang praktis dan akurat.",
      Image: "https://images-far-east.s3.ap-southeast-1.amazonaws.com/hardintro1-image-algo-jpeg-adobe.jpg",
  },
  {
      CloseButtonColorWhite: true,
      SubHeader: "METODE 2",
      Name: "Dari Ukuran Baju Favorit",
      Content: "Punya baju yang kamu suka? <br>Buat ukuran custom, mulai dari ukuran baju favoritmu. <br><u>Cuma butuh penggaris</u>, dan waktu 5 menit.",
      Image: "https://images-far-east.s3.ap-southeast-1.amazonaws.com/hardintro1-image-ukur-jpeg-adobe.jpg",
  },
  {
      CloseButtonColorWhite: false,
      SubHeader: "MULAI BERSAMA KAMI",
      Name: "Semua Orang Bisa <br>#LookBetter di Studio S.",
      Content: "Kami telah membantu banyak orang menemukan ukuran yang paling bagus untuk mereka. Shop untuk #LookBetter sekarang!",
      Image: "https://images-far-east.s3.ap-southeast-1.amazonaws.com/hardintro1-image-testi-jpeg-adobe.jpg",
  },
]

export interface CarouselItemProps
{
    animation: 'fade' | 'slide',
    next?: Function,
    prev?: Function,
    state: {
        active: number,
        prevActive: number,
        next: boolean
    }
    swipe?: boolean,
    index: number,
    maxIndex: number,
    duration: number,
    height?: number | string,
    setHeight: Function,
    childProps: TanpaPerluUkurComponentData,
}

export const CarouselItem = ({ next, prev, swipe, state, index, maxIndex, duration, height, setHeight, childProps }: CarouselItemProps) =>
{
  const classes = useStyles();
  const dispatch = useAppDispatch();

    const dragProps: MotionProps = {
        drag: 'x',
        layout: true,
        onDragEnd: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo): void =>
        {
            if (!swipe) return;

            if (info.offset.x > 0) prev && prev();
            else if (info.offset.x < 0) next && next();

            event.stopPropagation();
        },
        dragElastic: 0,
        dragConstraints: { left: 0, right: 0 }
    }

    const divRef = useRef<any>(null);

    const checkAndSetHeight = useCallback(() => {
        if (index !== state.active) return;
        if (!divRef.current) return;

        if (divRef.current.offsetHeight === 0)
        {
            setTimeout(() => checkAndSetHeight(), 100);
        }
        else
        {
            setHeight(divRef.current.offsetHeight);
        }
    }, [setHeight, state.active, index, divRef])

    // Set height on every child change
    useEffect(() => {
      checkAndSetHeight(); 
    }, [checkAndSetHeight]);

    const variantsSlideNoFade = {
        leftwardExit: {
          x: '-100%',
          opacity: undefined,
          zIndex: 0,
        },
        leftOut: {
          x: '-100%',
          opacity: undefined,
          display: 'none',
          zIndex: 0,
        },
        rightwardExit: {
          x: '100%',
          opacity: undefined,
          zIndex: 0,
        },
        rightOut: {
          x: '100%',
          opacity: undefined,
          display: 'none',
          zIndex: 0,
        },
        center: {
          x: 0,
          opacity: 1,
          zIndex: 1,
        },
    };
    
    const variantsNoSlideButFade = {
      leftwardExit: {
        opacity: 0,
        zIndex: 0,
      },
      leftOut: {
        opacity: 0,
        display: 'none',
        zIndex: 0,
      },
      rightwardExit: {
        opacity: 0,
          zIndex: 0,
      },
      rightOut: {
        opacity: 0,
        display: 'none',
        zIndex: 0,
      },
      center: {
        x: 0,
        opacity: 1,
        zIndex: 1,
      },
  };

    // Handle animation directions and opacity given based on active, prevActive and this item's index
    const { active, next: isNext, prevActive } = state;
    let animate = 'center';
    if (index === active)
        animate = 'center';
    else if (index === prevActive)
    {
        animate = isNext ? 'leftwardExit' : 'rightwardExit';
        if (active === maxIndex && index === 0) animate = 'rightwardExit';
        if (active === 0 && index === maxIndex) animate = 'leftwardExit'
    }
    else
    {
        animate = index < active ? 'leftOut' : 'rightOut';
        if (active === maxIndex && index === 0) animate = 'rightOut';
        if (active === 0 && index === maxIndex) animate = 'leftOut'
    }

    duration = duration / 1000;

    return (
      <div className={classes.styledItem}>
        <AnimatePresence custom={isNext}>
            <motion.div {...(swipe && dragProps)} style={{ height: '100%' }}>

                <div className={classes.dialogContentContainer}>
                  <div className={classes.dialogDivImageCustom}>
                    <motion.div
                        custom={isNext}
                        variants={variantsSlideNoFade}
                        animate={animate}
                        transition={{
                            x: { type: "tween", duration: duration, delay: 0 },
                            opacity: { duration: duration },
                        }}
                        style={{ position: 'relative', height: '100%' }}
                    >
                      <img 
                      src={childProps.Image} 
                      alt="measkit-img"
                      className={classes.mainImgCustom}/>
                    </motion.div>
                    <IconButton aria-label="closebutton" className={classes.dialogDivImageCloseButton} 
                    onClick={() => {
                      dispatch(closeOverlayAndReset());
                      sendWebsiteEventMessage("User closed flow screen tanpa ukur, tombol close");
                    } }
                    >
                      <Close />
                    </IconButton>
                  </div>
                  <motion.div
                      custom={isNext}
                      variants={variantsNoSlideButFade}
                      animate={animate}
                      transition={{
                          x: { type: "tween", duration: duration * 1.3, delay: 0 },
                          opacity: { duration: duration * 1.3 },
                      }}
                      style={{ position: 'relative', height: '100%', width: "100%" }}
                  >
                    <div className={classes.dialogDivText}>
                      {
                        childProps.SubHeader ? 
                        <Typography className={classes.tanpaPerluUkurSubheaderText}>
                          {ReactHtmlParser(childProps.SubHeader)}
                        </Typography> :
                        null
                      }
                      <Typography className={classes.tanpaPerluUkurTitleText}>
                        {ReactHtmlParser(childProps.Name)}
                      </Typography>
                      <Typography className={classes.tanpaPerluUkurContentText}>
                        {ReactHtmlParser(childProps.Content)}
                      </Typography>
                    </div>
                  </motion.div>
                </div>
            </motion.div>
        </AnimatePresence>
      </div>
    )
}

export interface IndicatorProps
{
    IndicatorIcon?: ReactNode,
    length: number,
    active: number,
    press: Function,
    isHidden?: boolean,
}

export const Indicators = (props: IndicatorProps) =>
{
  const classes = useStyles();
    const IndicatorIcon = useMemo(() => props.IndicatorIcon !== undefined ? props.IndicatorIcon : <FiberManualRecord className={classes.indicatorSvgIcon}/>, [props.IndicatorIcon]);

    const completeListIfRequired = useCallback((arrayOfIcons: Array<ReactNode>) =>
    {
        while (arrayOfIcons.length < props.length)
        {
            let index = 0;
            arrayOfIcons.push(arrayOfIcons[index]);
            index += 1;
        }
    }, [props.length])

    let indicators = [];

    for (let i = 0; i < props.length; i++)
    {

        const createIndicator = (IndicatorIcon: ReactNode) =>
        {
          if (i === props.active) {
            return (
              <StyledIndicatorIconButtonActive
                  key={i}
                  onClick={() => { 
                    if (!props.isHidden) {
                      props.press(i);
                    }
                  }}
                  aria-label={`indicator ${i + 1}`}
              >
                  {IndicatorIcon}
              </StyledIndicatorIconButtonActive>
            );
          } else {
            return (
              <StyledIndicatorIconButtonNonActive
                  key={i}
                  onClick={() => { 
                    if (!props.isHidden) {
                      props.press(i);
                    }
                  }}
                  aria-label={`$indicator ${i + 1}`}
              >
                  {IndicatorIcon}
              </StyledIndicatorIconButtonNonActive>
            );
          }
        }

        Array.isArray(IndicatorIcon)
            ? indicators.push(createIndicator(IndicatorIcon[i])) && completeListIfRequired(IndicatorIcon)
            : indicators.push(createIndicator(IndicatorIcon))

    }

    return (
      <Box className={ props.isHidden ? classes.styledIndicatorsHidden : classes.styledIndicators}>
        {indicators}
      </Box>
    )
}

const OverlayCarousel = 
({ childProps }: {
  childProps: TanpaPerluUkurComponentData[] }) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();

  const [state, setState] = React.useState({
      active: 0,
      prevActive: 0,
      next: true
  });

  const [childrenHeight, setChildrenHeight] = React.useState<number>();

  const next = (event: any) =>
  {
    let last = childProps.length - 1;
    const nextActive = state.active + 1 > last ? state.active : state.active + 1;

    setNext(nextActive, true)

    if (event)
      event.stopPropagation();
  }

  const prev = (event: any) =>
  {
    const nextActive = state.active - 1 < 0 ? state.active : state.active - 1;

    setNext(nextActive, false)

    if (event)
      event.stopPropagation();
  }

  const setNext = (index: number, isNext: boolean) =>
  {
      sendWebsiteEventMessage("User closed flow screen tanpa ukur, set next ke " + (index + 1));

      if (Array.isArray(childProps))
      {
          if (index > childProps.length - 1) index = childProps.length - 1;
          if (index < 0) index = 0;
      }
      else
      {
          index = 0;
      }

      if (isNext === undefined)
      {
          isNext = index > state.active
      }

      setState({
          active: index,
          prevActive: state.active,
          next: isNext
      })
  }

  return (
    <div className={classes.styledRoot}>
      <div style={{
        height: "104vw",
        position: 'relative',
        width: '100%',
      }}>
        {
          childProps.map((child, index) =>
          {
              return (
                  <CarouselItem
                      key={`carousel-item${index}`}
                      state={state}
                      index={index}
                      maxIndex={childProps.length - 1}
                      animation={"slide"}
                      duration={500}
                      swipe={true}
                      next={next}
                      prev={prev}
                      setHeight={setChildrenHeight}
                      childProps={child}
                  />
              )
          })
        }
      </div>
      <div className={classes.dialogDivCarousel}>
        {
          state.active === 0 ?
          <IconButton className={classes.hiddenArrowIcon}>
            <ArrowLeftIcon fontSize="large"/>
          </IconButton> :
          <IconButton onClick={() => setNext(state.active - 1, false)}>
            <ArrowLeftIcon fontSize="large"/>
          </IconButton>
        }
        <Indicators
            length={Array.isArray(childProps) ? childProps.length : 0}
            active={state.active}
            press={setNext}
            isHidden={state.active === childProps.length - 1}
        />
        {
          state.active === childProps.length - 1 ?
          <IconButton className={classes.hiddenArrowIcon}>
            <ArrowRightIcon fontSize="large"/>
          </IconButton> :
          <IconButton onClick={() => setNext(state.active + 1, true)}>
            <ArrowRightIcon fontSize="large"/>
          </IconButton>
        }
        {
          state.active === childProps.length - 1 ?
          <Button
          variant="text"
          className={classes.secondaryFinishButtonOverlay}
          onClick={
            () => {
              dispatch(closeOverlayAndReset());
              sendWebsiteEventMessage("User closed flow screen tanpa ukur, tombol lihat ig profile");
              const url = 'https://www.instagram.com/studio.s.id';
              window.open(url, '_blank');
            }
          }>
            LIHAT IG PROFILE
          </Button>:
          null          
        }
        {
          state.active === childProps.length - 1 ?
          <Button
          variant="contained"
          className={classes.finishButtonOverlay}
          onClick={
            () => {
              dispatch(closeOverlayAndReset());
              sendWebsiteEventMessage("User closed flow screen tanpa ukur, tombol mulai di akhir");
            }
          }>
            MULAI BELANJA
          </Button>:
          null          
        }
      </div>
    </div>)
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayFlowVersionView);
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useAppDispatch();
  const classes = useStyles();

  if (isSmOrUp) {
    return (null);
  }

  return (
    <Dialog
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      {
        overlayView.currentFlowScreen === "regulerdulu" ?
        <div className={classes.dialogContentContainer}>
          <div className={classes.dialogDivImage}>
            <img 
            src={"https://images-far-east.s3.ap-southeast-1.amazonaws.com/softintro1-image-measkit.jpg"} 
            alt="measkit-img"
            className={classes.mainImg}/>
            <IconButton aria-label="closebutton" className={classes.dialogDivImageCloseButton} 
            onClick={() => {
              dispatch(closeOverlayAndReset());
              sendWebsiteEventMessage("User closed flow screen reguler dulu, tombol close");
            } }>
              <Close />
            </IconButton>
          </div>
          <div className={classes.dialogDivTextSvg}>
            <img 
            src={"https://images-far-east.s3.ap-southeast-1.amazonaws.com/softintro1-text-regulerdulu-2.svg"} 
            alt="measkit-img"
            className={classes.mainTextSvg}/>
          </div>
          <div className={classes.dialogDivButtons}>
            <Button
            variant="text"
            className={classes.secondButton}
            onClick={
              () => {
                dispatch(closeOverlayAndReset());
                sendWebsiteEventMessage("User click pelajari custom");
                setTimeout(() => {
                  dispatch(setCurrentFlowScreen("tanpaukur"));
                }, 600);
              }
            }>
              PELAJARI CUSTOM
            </Button>
            <Button
            variant="contained"
            className={classes.firstButton}
            onClick={
              () => {
                dispatch(closeOverlayAndReset());
                sendWebsiteEventMessage("User closed flow screen reguler dulu, tombol lanjut belanja");
              }
            }>
              LANJUT BELANJA
            </Button>
          </div>
        </div>: 
        null
      }
      {
        overlayView.currentFlowScreen === "tanpaukur" ?
        <OverlayCarousel childProps={tanpaPerluUkurSteps}/>:
        null
      }
    </Dialog>
  )
}
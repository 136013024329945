import { Box, Button, Fade, Typography, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  progressDiv: {
    backgroundColor: '#ffffff',
    height: '10px',
  },
  progress: {
    backgroundColor: '#343434',
    height: '10px',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '80px 0px 100px 0px',
    [theme.breakpoints.only('xs')]: {
      padding: '60px 0px 60px 0px',
    }
  },
  desktopOuterContainer: {
    margin: '0px 5vw 0px 5vw',
    width: 'auto',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'unset',
      margin: '0px 0vw 0px 0vw',
      padding: '0 0 0 10vw',
    }
  },
  desktopRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  mobileRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // marginTop: '4vw',
  },
  imageContainer: {
    // margin: '10px 10px 10px 10px',
  },
  video: {
    width: '100%',
  },
  textContentContainer: {
    width: 'auto',
    // Use margin + width auto
    margin: '3vw 0px 0px 0px',
    textAlign: 'left',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      margin: '0px 4px 40px 4px',
    }
  },
  textContentInnerContainer: {
    // borderTop: '1px solid #e0e0e0',
    paddingTop: '13px',
    paddingBottom: '13px',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '2px',
      paddingBottom: '2px',
    }
  },
  buttonContainer: {
  },
  buttonNavigation: {
    marginTop: '100%',
  },
  buttonNavigationMobile: {
  },
  titleTopText: {
    display: 'inline-block',
    border: '1px solid black',
    padding: '0 5px',
    borderRadius: '25px',
    fontWeight: 500,
    letterSpacing: '0.05em',
    fontFamily: 'Roboto Mono',
    fontSize: '1vw',
    marginBottom: '0.9vw',
    marginTop: '1vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.151vw',
      float: 'left',
      margin: '3vw 0 3vw 10vw',
    }
  },
  titleText: {
    fontFamily: 'OakesGrotesk, sans-serif',
    fontWeight: 400,
    color: '#343434',
    fontSize: '3.9vw',
    marginBottom: '2vw',
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 0 10vw',
      textAlign: 'left',
      fontSize: '5.645vw',
      marginBottom: '2.5vw',
    },
  },
  contentText: {
    fontFamily: 'TT Hoves, sans-serif',
    fontWeight: 300,
    fontSize: '1.5vw',
    margin: '0px 0px 4.4vw 0px',
    padding: '0 20vw',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      padding: '0 7vw 0 10vw',
      fontSize: '3vw',
      lineHeight: '3.3vw',
      marginBottom: '7vw',
    },
    lineHeight: '2.1vw',
  },
  textContentTitleText: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 600,
    color: '#343434',
    fontSize: '1.8vw',
    marginBottom: '10px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '4vw',
      marginBottom: '6px',
    }
  },
  textContentBodyText: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 400,
    color: '#343434',
    fontSize: '1vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.7vw',
    }
  },
  textContentNumberText: {
    position: 'absolute',
    fontFamily: 'OakesGrotesk',
    fontWeight: 600,
    color: '#a4a4a4',
    fontSize: '2vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '6vw',
    }
  },
  textContentNumberTextSelected: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 600,
    color: '#343434',
    fontSize: '2vw',
    [theme.breakpoints.only('xs')]: {
      fontSize: '6vw',
    }
  },
  textContentNumberContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  underlineDiv: {
    height: '5px',
    width: '15px',
    backgroundColor: '#343434',
  }
}));

const useVideoPlayer = (videoElement: any) => {
  const [playerState, setPlayerState] = React.useState({
    isPlaying: false,
    progress: 1,
    currentTime: 1,
    speed: 1,
    isMuted: false,
  });


  useEffect(() => {
    if (videoElement === undefined 
      || videoElement === null
      || videoElement.current === undefined
      || videoElement.current === null
      ) {
      return;
    }
    if (playerState.isPlaying) {
      videoElement.current.muted = "muted";
      return videoElement.current.play();
    } else {
      videoElement.current.muted = "muted";
      return videoElement.current.pause();
    }
  }, [playerState.isPlaying, videoElement]);

  const togglePlay = (isPlaying: boolean) => {
    setPlayerState({
      ...playerState,
      isPlaying: isPlaying,
    });
  };

  const handleOnTimeUpdate = () => {
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    setPlayerState({
      ...playerState,
      progress,
      currentTime: videoElement.current.currentTime,
    });
  };

  const handleVideoSetTime = (setTime: number) => {
    videoElement.current.currentTime = setTime;
    setPlayerState({
      ...playerState,
      progress: (setTime / videoElement.current.duration) * 100,
      currentTime: setTime,
    });
  };

  const handleVideoProgress = (event: any) => {
    const manualChange = Number(event.target.value);
    const currentTime = (videoElement.current.duration / 100) * manualChange;
    videoElement.current.currentTime = currentTime;
    setPlayerState({
      ...playerState,
      progress: manualChange,
      currentTime: currentTime,
    });
  };

  const handleVideoSpeed = (event: any) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  useEffect(() => {
    if (videoElement === undefined 
      || videoElement === null
      || videoElement.current === undefined
      || videoElement.current === null
      ) {
      return;
    }
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoSetTime,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
  };
};

export default function CaraBelanja() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: [0.4, 0.5, 0.6, 0.7, 0.8, 0.9],
  });

  const videoElement = useRef(null);
  const {
    playerState,
    togglePlay,
  } = useVideoPlayer(videoElement);

  let inViewTrue = inView
  && entry !== undefined
  && entry.intersectionRatio !== undefined
  && entry.intersectionRatio > 0.5;

  useEffect(() => {
    if (inViewTrue) {
      if (playerState.isPlaying === false) {
        togglePlay(true);
      }
    } else {
      if (playerState.isPlaying === true) {
        togglePlay(false);
      }
    }

    return;
  }, 
  [inViewTrue]);

  if (inViewTrue) {
    if (playerState.isPlaying === false) {
      togglePlay(true);
    }
  } else {
    if (playerState.isPlaying === true) {
      togglePlay(false);
    }
  }

  const desktopContent = (
    <Grid container className={classes.desktopOuterContainer}>
      <Grid item xs={12}>
        <Box className={classes.imageContainer}>
          <video
            src={'https://images-far-east.s3.ap-southeast-1.amazonaws.com/SatuUkuranLebihBaik_9.mp4'}
            ref={videoElement}
            className={classes.video}
            autoPlay
            muted={true}
          />
        </Box>
      </Grid>
    </Grid>);

  const mobileContent = (
    <Grid container justifyContent="center" className={classes.desktopOuterContainer}>
      <Grid item xs={11}>
        <Box className={classes.imageContainer}>
          <video
            src={'https://images-far-east.s3.ap-southeast-1.amazonaws.com/SatuUkuranLebihBaikMobile_3.mp4'}
            ref={videoElement}
            className={classes.video}
            autoPlay
            muted={true}
          />
        </Box>
      </Grid>
    </Grid>);

  const result = (
    <div className={classes.mainContainer} ref={ref}>
      <Box>
        <Typography className={classes.titleTopText}>
          SOLUSI TERBAIK
        </Typography>
      </Box>
      {
        isMdOrUp ? 
        <Typography className={classes.titleText}>
          Satu Ukuran <i>Custom</i>, <br></br>Datang Selalu Pas Di Badan
        </Typography> :
        <Typography className={classes.titleText}>
          Satu Ukuran <i>Custom</i>, <br></br>Datang Selalu Pas Di Badan
        </Typography>
      }
      <Typography className={classes.contentText}>
        Dengan membuat satu ukuran custom, baju yang kamu
        beli di website akan datang sesuai dengan ukuran
        badan kamu, sehingga memberikan hasil yang terbaik.
      </Typography>
      {
      isMdOrUp ? 
      desktopContent : 
      mobileContent
      }
    </div>
  )
  return result;
}
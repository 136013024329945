import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { Box, Link, useMediaQuery, withWidth, Paper, Button, IconButton } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import OptionItem from './PageComponentOptionsItemContainer';
import { useAppSelector } from '../reduxhooks';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useRef } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Fab from '@material-ui/core/Fab';

interface StyleProps {
  marginDesktop: string
  marginMobile: string
  buttonMarginLeft: string
  buttonMarginRight: string
  flexAuto: string
}

const useStyles = ({marginDesktop, marginMobile, flexAuto,
  buttonMarginLeft, buttonMarginRight }: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  productSuggestionsContainer: {
    margin: marginDesktop,
    [theme.breakpoints.down('xs')]: {
      margin: marginMobile,
    },
    width: '100%',
    display: 'block',
    position: 'relative',
  },
  title: {
    margin: '10px 20px 0px 20px',
    fontWeight: theme.typography.fontWeightBold as number,
  },
  horizontalOptionsSlider: {
    marginTop: '-10px', // Required so that hover shadow is visible top
    paddingTop: '20px', // Required so that hover shadow is visible top
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    overflowX: 'auto',
    overflowY: 'hidden',
    boxSizing: 'border-box',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiGrid-item': {
      [theme.breakpoints.down('xs')]: {
        flex: flexAuto,
      },
      flex: flexAuto,
    },
  },
  floatingButtonLeft: {
    position: 'absolute',
    top: 'calc(50% - 20px) !important',
    left: buttonMarginLeft + ' !important',
    zIndex: 500,
  },
  floatingButtonRight: {
    position: 'absolute',
    top: 'calc(50% - 20px) !important',
    right: buttonMarginRight + ' !important',
    zIndex: 500,
  }
}));

interface Props {
  title: JSX.Element[]
  contents: JSX.Element[]
  contentsChange?: string
  marginDesktop: string
  marginMobile: string
  buttonMarginLeft?: string
  buttonMarginRight?: string
  flexAuto?: string
}

export default function OptionsSlider({title, contents, contentsChange, marginDesktop, marginMobile, 
  buttonMarginLeft, buttonMarginRight, flexAuto}: Props) {
  const classes = useStyles({marginDesktop, marginMobile, 
    buttonMarginLeft: buttonMarginLeft ? buttonMarginLeft : '4px',
    buttonMarginRight: buttonMarginRight ? buttonMarginRight : '4px',
    flexAuto: flexAuto ? flexAuto : '0 0 auto'})();
  const optionsSliderRef = useRef(null);
  const [scrollLeft, setScrollLeft] = React.useState<number>(2);

  let children: any = null;

  let canScrollLeft = scrollLeft > 4;
  let canScrollRight = true;
  if (optionsSliderRef && optionsSliderRef.current) {
    canScrollRight = (optionsSliderRef.current as any).scrollLeft + (optionsSliderRef.current as any).offsetWidth
    < (optionsSliderRef.current as any).scrollWidth - 4;
  }

  // Mount effect is used to get the component to re-render 
  useEffect(() => {
    setTimeout(() => {
      setScrollLeft(0);
    }, 400)
  }, []);


  useEffect(() => {
    if (!optionsSliderRef || !optionsSliderRef.current) {
      return;
    } 
    
    (optionsSliderRef.current as any).scrollTo({
      left: 0,
      behavior: 'smooth'
    });
  }, [contentsChange]);

  function animateScroll(targetPosition: number) {
    if (!optionsSliderRef || !optionsSliderRef.current) {
      return;
    } 

    if (targetPosition > 1) {
      targetPosition -= 1;
    }
    
    (optionsSliderRef.current as any).scrollTo({
      left: targetPosition,
      behavior: 'smooth'
    });
  }

  return (
    <Box className={classes.productSuggestionsContainer}>
      {
        title
      }
      {
        canScrollLeft ?
        <Fab onClick={() => { 
          if (optionsSliderRef !== undefined && optionsSliderRef.current !== undefined) 
          {
            children = (optionsSliderRef.current as any).children;

            // Get next children element's offsetLeft position
            let currentPosition = (optionsSliderRef.current as any).scrollLeft;
            let targetPosition = currentPosition;
            for (let i = children.length - 1; i >= 0; i--) {
              let child = children[i];
              if (currentPosition - child.offsetLeft > 10) {
                targetPosition = child.offsetLeft;
                break;
              }
            }

            animateScroll(targetPosition);
          }
        }} className={classes.floatingButtonLeft}>
          <KeyboardArrowLeftIcon/>
        </Fab> : 
        null
      }
      {
        canScrollRight ?
        <Fab onClick={() => { 
          if (optionsSliderRef !== undefined && optionsSliderRef.current !== undefined) 
          {
            children = (optionsSliderRef.current as any).children;

            // Get next children element's offsetLeft position
            let currentPosition = (optionsSliderRef.current as any).scrollLeft;
            let targetPosition = currentPosition;
            for (let i = 0; i < children.length; i++) {
              let child = children[i];
              if (child.offsetLeft - currentPosition > 10) {
                targetPosition = child.offsetLeft;
                break;
              }
            }
            
            animateScroll(targetPosition);
          }
        }} className={classes.floatingButtonRight}>
          <KeyboardArrowRightIcon/>
        </Fab> :
        null
      }
      <div className={classes.horizontalOptionsSlider} ref={optionsSliderRef}
        onScroll={() => {
          if (optionsSliderRef && optionsSliderRef.current) {
            setScrollLeft((optionsSliderRef.current as any).scrollLeft)
          }
        }}
        
        >
          {
            contents
          }
      </div>
    </Box>
    );
}
export var categoryMap = {
    "HOME": "/",
    "MEN'S": "/mens",
    "WOMEN'S": "/womens",
    "NEW ARRIVALS": "/new-arrivals",
    "SUSTAINABILITY": "/sustainability",
    "YOUR ACCOUNT": "/your-account",
    "HELP": "/help",
    "SHOP": "/shop",
    "CHECK OUT": "/checkout",
};
export var categoryMapEvent = {
    "HOME": "goToHomePage",
    "YOUR ACCOUNT": "goToAccountPage",
    "SHOP": "goToShopPage",
};
export var categoryList = [
    "HOME",
    "SHOP",
    "YOUR ACCOUNT",
];

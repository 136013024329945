import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import OptionsSlider from "./PageComponentOptionsSliderInfinite";
import ReviewItemImage from "./PageComponentReviewItemImage";
import { CategoryReview } from "common-ts/dist/models/CategoryReview";


interface ReviewSliderImageProps {
  reviews: CategoryReview[];
  onClickImage: (reviewIndex: number, imageIndex: number) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    reviewItemSliderContainer: {
      marginTop: "-30px",
    },
    ItemContainerSpacer: {
      margin: "0",
      flex: "0 0 auto",
      width: "20px",
    },
  })
);

const ReviewSliderImage: React.FC<ReviewSliderImageProps> = ({
  reviews,
  onClickImage,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.reviewItemSliderContainer}>
      <OptionsSlider
        title={[]}
        marginDesktop="0px 0px 0px 0px"
        marginMobile="0px 0px 0px 0px"
        contents={[
          <Box key="spacer" className={classes.ItemContainerSpacer} />,
        ].concat(
          reviews.map((review, reviewIndex) => (
            <Box key={reviewIndex}>
              <ReviewItemImage
                {...review}
                onClickImage={(imageIndex: number) =>
                  onClickImage(reviewIndex, imageIndex)
                }
              />
            </Box>
          ))
        )}
      />
    </Box>
  );
};

export default ReviewSliderImage;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { RegularSizeValue } from "./Cart";
function mergeArraysUnique(array1, array2) {
    var arrayMerge = array1.concat(array2);
    return arrayMerge.filter(function (item, index) {
        return arrayMerge.indexOf(item) === index;
    });
}
// [TYPES] I. BODY MEASUREMENTS
// ALL POSSIBLE BODY MEASUREMENTS
// KEY2TODO-CB Add if there is CB
export var BodyMeasurementsKey;
(function (BodyMeasurementsKey) {
    BodyMeasurementsKey["panjangBaju"] = "panjangBaju";
    BodyMeasurementsKey["lingkarDada"] = "lingkarDada";
    BodyMeasurementsKey["lingkarPerut"] = "lingkarPerut";
    BodyMeasurementsKey["lingkarPinggul"] = "lingkarPinggul";
    BodyMeasurementsKey["lingkarLeher"] = "lingkarLeher";
    BodyMeasurementsKey["lebarBahu"] = "lebarBahu";
    BodyMeasurementsKey["lingkarLenganBisep"] = "lingkarLenganBisep";
    BodyMeasurementsKey["panjangLenganPanjang"] = "panjangLenganPanjang";
    BodyMeasurementsKey["lingkarLenganPergelangan"] = "lingkarLenganPergelangan";
    // CB
    BodyMeasurementsKey["lebarLenganPendek"] = "lebarLenganPendek";
    BodyMeasurementsKey["panjangLenganPendek"] = "panjangLenganPendek";
    BodyMeasurementsKey["lebarLenganBawahTigaEmpat"] = "lebarLenganBawahTigaEmpat";
})(BodyMeasurementsKey || (BodyMeasurementsKey = {}));
// RARELY CHANGES / CHANGE IS A BIG DEAL
export var CoreBodyMeasurements = [
    BodyMeasurementsKey.lingkarDada,
    BodyMeasurementsKey.lingkarPerut,
    BodyMeasurementsKey.panjangBaju,
    BodyMeasurementsKey.lingkarLeher,
    BodyMeasurementsKey.lebarBahu,
    BodyMeasurementsKey.lingkarPinggul,
    BodyMeasurementsKey.lingkarLenganBisep,
    BodyMeasurementsKey.lingkarLenganPergelangan,
    BodyMeasurementsKey.panjangLenganPanjang,
];
export var BodyMeasurementDetailList = {
    "panjangBaju": {
        issueDescription: "Baju kepanjangan atau kependekan",
        bodyMeasurement: "panjangBaju",
        bodyMeasurementName: "Panjang Baju",
        measurementInstructionVideoSrc: 'https://d31zz7s33c4k72.cloudfront.net/instruction-videos/Sequence+01+Panjang+Baju+Badan.mp4',
        measurementInstructionDescription: "Ukur dari samping leher ke bagian paling bawah baju.",
    },
    "lingkarDada": {
        issueDescription: "Bagian dada terlalu ketat, sehingga ngecap bagian dada",
        bodyMeasurement: "lingkarDada",
        bodyMeasurementName: "Lingkar Dada",
        measurementInstructionVideoSrc: 'https://d31zz7s33c4k72.cloudfront.net/instruction-videos/Sequence+01+LingkarDada.mp4',
        measurementInstructionDescription: "Ukur di bagian tengah dada.",
    },
    "lingkarPerut": {
        issueDescription: "Baju kesempitan atau terlalu lebar di daerah perut",
        bodyMeasurement: "lingkarPerut",
        bodyMeasurementName: "Lingkar Perut",
        measurementInstructionVideoSrc: 'https://d31zz7s33c4k72.cloudfront.net/instruction-videos/Sequence+01+Lingkar+Perut.mp4',
        measurementInstructionDescription: "Ukur di bagian terbesar perut atau pinggang.",
    },
    "lingkarPinggul": {
        issueDescription: "Baju kesempitan atau terlalu lebar di daerah pinggul",
        bodyMeasurement: "lingkarPinggul",
        bodyMeasurementName: "Lingkar Pinggul",
        measurementInstructionVideoSrc: '',
        measurementInstructionDescription: "Ukur di bagian terbesar pinggul atau di bagian sabuk celana.",
    },
    "lingkarLeher": {
        issueDescription: "Kerah sering sempit",
        bodyMeasurement: "lingkarLeher",
        bodyMeasurementName: "Lingkar Leher",
        measurementInstructionVideoSrc: 'https://d31zz7s33c4k72.cloudfront.net/instruction-videos/Sequence+01+Lingkar+Leher.mp4',
        measurementInstructionDescription: "Ukur di bagian dasar leher.",
    },
    "lebarBahu": {
        issueDescription: "Bagian bahu terlalu naik, atau terlalu turun",
        bodyMeasurement: "lebarBahu",
        bodyMeasurementName: "Lebar Bahu",
        measurementInstructionVideoSrc: 'https://d31zz7s33c4k72.cloudfront.net/instruction-videos/Sequence+01+Lebar+Bahu.mp4',
        measurementInstructionDescription: "Ukur dari satu ujung pundak ke yang lain.",
    },
    "panjangLenganPanjang": {
        issueDescription: "Lengan panjang terlalu pendek atau terlalu panjang",
        bodyMeasurement: "panjangLenganPanjang",
        bodyMeasurementName: "Panjang Lengan Panjang",
        measurementInstructionVideoSrc: '',
        measurementInstructionDescription: "Ukur dari pundak ke ujung lengan.",
    },
    "lingkarLenganBisep": {
        issueDescription: "Bagian lengan mengangkat, atau bagian lengan terlalu sempit",
        bodyMeasurement: "lingkarLenganBisep",
        bodyMeasurementName: "Lingkar Lengan Bisep",
        measurementInstructionVideoSrc: 'https://d31zz7s33c4k72.cloudfront.net/instruction-videos/Sequence+01+Lingkar+Lengan+Bisep.mp4',
        measurementInstructionDescription: "Ukur di bagian tengah bisep.",
    },
    "lingkarLenganPergelangan": {
        issueDescription: "Bagian pergelangan terlalu besar, atau terlalu sempit",
        bodyMeasurement: "lingkarLenganPergelangan",
        bodyMeasurementName: "Lingkar Lengan Pergelangan",
        measurementInstructionVideoSrc: '',
        measurementInstructionDescription: "Ukur di bagian pergelangan tangan.",
    },
};
// BODY MEASUREMENTS FOR EACH SIZE. ONLY HAS GENERAL-REQ BODY MEASUREMENTS (NO CLOTH-TYPE REQ BODY MEAS)
// REGULAR SIZE MEASUREMENTS
// APRIL 3, 2024
export var BodyMeasurementsForSize = {
    "NOTSELECTED": {
        panjangBaju: 68,
        lingkarDada: 96,
        lingkarPerut: 92,
        lingkarPinggul: 92,
        lingkarLeher: 47,
        lebarBahu: 46,
        lingkarLenganBisep: 28,
        panjangLenganPanjang: 58,
        lingkarLenganPergelangan: 17,
    },
    "2X-Small (2XS)": {
        panjangBaju: 62,
        lingkarDada: 73,
        lingkarPerut: 73,
        lingkarPinggul: 73,
        lingkarLeher: 34,
        lebarBahu: 41.5,
        lingkarLenganBisep: 27,
        panjangLenganPanjang: 61,
        lingkarLenganPergelangan: 16,
    },
    "X-Small (XS)": {
        panjangBaju: 65,
        lingkarDada: 80,
        lingkarPerut: 80,
        lingkarPinggul: 80,
        lingkarLeher: 36,
        lebarBahu: 43,
        lingkarLenganBisep: 28,
        panjangLenganPanjang: 62,
        lingkarLenganPergelangan: 16,
    },
    "Small (S)": {
        panjangBaju: 68,
        lingkarDada: 87,
        lingkarPerut: 87,
        lingkarPinggul: 87,
        lingkarLeher: 37,
        lebarBahu: 44.5,
        lingkarLenganBisep: 29,
        panjangLenganPanjang: 62,
        lingkarLenganPergelangan: 17.8,
    },
    "Medium (M)": {
        panjangBaju: 69,
        lingkarDada: 95,
        lingkarPerut: 95,
        lingkarPinggul: 95,
        lingkarLeher: 38,
        lebarBahu: 46,
        lingkarLenganBisep: 30,
        panjangLenganPanjang: 63,
        lingkarLenganPergelangan: 17.8,
    },
    "Large (L)": {
        panjangBaju: 71,
        lingkarDada: 102,
        lingkarPerut: 102,
        lingkarPinggul: 102,
        lingkarLeher: 41,
        lebarBahu: 48,
        lingkarLenganBisep: 31.25,
        panjangLenganPanjang: 64,
        lingkarLenganPergelangan: 19.4,
    },
    "X-Large (XL)": {
        panjangBaju: 73,
        lingkarDada: 110,
        lingkarPerut: 110,
        lingkarPinggul: 110,
        lingkarLeher: 43,
        lebarBahu: 49.5,
        lingkarLenganBisep: 33.6,
        panjangLenganPanjang: 65,
        lingkarLenganPergelangan: 19.4,
    },
    "2X-Large (2XL)": {
        panjangBaju: 74,
        lingkarDada: 116,
        lingkarPerut: 116,
        lingkarPinggul: 116,
        lingkarLeher: 45,
        lebarBahu: 51,
        lingkarLenganBisep: 36,
        panjangLenganPanjang: 66,
        lingkarLenganPergelangan: 21,
    },
    "3X-Large (3XL)": {
        panjangBaju: 63,
        lingkarDada: 72,
        lingkarPerut: 64,
        lingkarPinggul: 64,
        lingkarLeher: 39,
        lebarBahu: 42,
        lingkarLenganBisep: 28,
        panjangLenganPanjang: 58,
        lingkarLenganPergelangan: 21,
    },
    "4X-Large (4XL)": {
        panjangBaju: 63,
        lingkarDada: 72,
        lingkarPerut: 64,
        lingkarPinggul: 64,
        lingkarLeher: 39,
        lebarBahu: 42,
        lingkarLenganBisep: 28,
        panjangLenganPanjang: 58,
        lingkarLenganPergelangan: 21,
    },
    "5X-Large (5XL)": {
        panjangBaju: 63,
        lingkarDada: 72,
        lingkarPerut: 64,
        lingkarPinggul: 64,
        lingkarLeher: 39,
        lebarBahu: 42,
        lingkarLenganBisep: 28,
        panjangLenganPanjang: 58,
        lingkarLenganPergelangan: 21,
    },
};
// [TYPES] II. CLOTHING MEASUREMENTS
// ALL POSSIBLE CLOTHING MEASUREMENTS
export var ClothingMeasurementsKey;
(function (ClothingMeasurementsKey) {
    ClothingMeasurementsKey["panjangBaju"] = "panjangBaju";
    ClothingMeasurementsKey["lebarDadaBaju"] = "lebarDadaBaju";
    ClothingMeasurementsKey["lebarPerutBaju"] = "lebarPerutBaju";
    ClothingMeasurementsKey["lingkarLeherBaju"] = "lingkarLeherBaju";
    ClothingMeasurementsKey["lebarBahuBaju"] = "lebarBahuBaju";
    ClothingMeasurementsKey["panjangLenganPanjang"] = "panjangLenganPanjang";
    ClothingMeasurementsKey["lebarLenganBaju"] = "lebarLenganBaju";
    ClothingMeasurementsKey["lebarLenganAtasBaju"] = "lebarLenganAtasBaju";
    ClothingMeasurementsKey["panjangLenganPendek"] = "panjangLenganPendek";
})(ClothingMeasurementsKey || (ClothingMeasurementsKey = {}));
export var ClothingMeasurementDetailList = {
    panjangBaju: {
        issueDescription: "Baju terlalu pendek atau panjang",
        clothingMeasurement: ClothingMeasurementsKey.panjangBaju,
        clothingMeasurementName: "Panjang Baju"
    },
    lebarDadaBaju: {
        issueDescription: "Bagian dada terlalu sempit",
        clothingMeasurement: ClothingMeasurementsKey.lebarDadaBaju,
        clothingMeasurementName: "Lebar Dada Baju"
    },
    lebarPerutBaju: {
        issueDescription: "Baju terlalu sempit atau longgar di bagian perut",
        clothingMeasurement: ClothingMeasurementsKey.lebarPerutBaju,
        clothingMeasurementName: "Lebar Perut Baju"
    },
    lingkarLeherBaju: {
        issueDescription: "Bagian leher terlalu sempit atau longgar",
        clothingMeasurement: ClothingMeasurementsKey.lingkarLeherBaju,
        clothingMeasurementName: "Lingkar Lubang Leher Baju"
    },
    lebarBahuBaju: {
        issueDescription: "Lebar bahu terlalu kecil atau terlalu besar",
        clothingMeasurement: ClothingMeasurementsKey.lebarBahuBaju,
        clothingMeasurementName: "Lebar Bahu"
    },
    panjangLenganPendek: {
        issueDescription: "Lengan pendek terlalu pendek atau panjang",
        clothingMeasurement: ClothingMeasurementsKey.panjangLenganPendek,
        clothingMeasurementName: "Panjang Lengan Pendek"
    },
    panjangLenganPanjang: {
        issueDescription: "Lengan panjang terlalu pendek atau panjang",
        clothingMeasurement: ClothingMeasurementsKey.panjangLenganPanjang,
        clothingMeasurementName: "Panjang Lengan Panjang"
    },
    lebarLenganBaju: {
        issueDescription: "Lebar lengan baju ingin jadi tertentu",
        clothingMeasurement: ClothingMeasurementsKey.lebarLenganBaju,
        clothingMeasurementName: "Lebar Lengan Baju"
    },
    lebarLenganAtasBaju: {
        issueDescription: "Lebar lengan atas baju ingin jadi tertentu",
        clothingMeasurement: ClothingMeasurementsKey.lebarLenganAtasBaju,
        clothingMeasurementName: "Lebar Lengan Atas Baju"
    },
};
// CLOTHING MEASUREMENTS CHECK FUNCTION
export function IsEqualClothingMeasurements(a, b) {
    var curKeys = Object.keys(a);
    for (var i = 0; i < curKeys.length; i++) {
        var curKey = curKeys[i];
        if (b[curKey] === undefined ||
            a[curKey] !== b[curKey]) {
            return false;
        }
    }
    curKeys = Object.keys(b);
    for (var i = 0; i < curKeys.length; i++) {
        var curKey = curKeys[i];
        if (a[curKey] === undefined ||
            a[curKey] !== b[curKey]) {
            return false;
        }
    }
    return true;
}
// CLOTHING MEASUREMENTS ANIMATION FUNCTION
export function NextStepClothingMeasurements(cur, goal, incrementTogether) {
    var nextStep = __assign({}, cur);
    var curKeys = Object.keys(cur);
    for (var i = 0; i < curKeys.length; i++) {
        var curKey = curKeys[i];
        if (goal[curKey] === undefined) {
            // console.log("Goal doesn't have key.");
            continue;
        }
        if (cur[curKey]
            === goal[curKey]) {
            continue;
        }
        var curVal = cur[curKey];
        var goalVal = goal[curKey];
        if (curVal < goalVal) {
            nextStep[curKey] = curVal + (Math.abs(curVal - goalVal) > 1 ? 1 : Math.abs(curVal - goalVal));
        }
        else {
            nextStep[curKey] = curVal - (Math.abs(curVal - goalVal) > 1 ? 1 : Math.abs(curVal - goalVal));
        }
        if (!incrementTogether) {
            return nextStep;
        }
    }
    return nextStep;
}
// [TYPES] III. CLOTHING TYPE & FIT TYPE
// ClothingType signifies the type of clothing type the product has.
// KEY2TODO-TYPE Add new ClothingType
export var ClothingType;
(function (ClothingType) {
    ClothingType["All"] = "All";
    ClothingType["Kaos"] = "Kaos";
    ClothingType["KaosPanjang"] = "KaosPanjang";
    ClothingType["Sweater"] = "Sweater";
    ClothingType["PoloShirt"] = "PoloShirt";
    ClothingType["PoloShirtPanjang"] = "PoloShirtPanjang";
    ClothingType["KemejaPendek"] = "KemejaPendek";
    ClothingType["KemejaPanjang"] = "KemejaPanjang";
    ClothingType["Jaket"] = "Jaket";
    ClothingType["KokoTigaEmpat"] = "KokoTigaEmpat";
})(ClothingType || (ClothingType = {}));
// KEY2TODO-TYPE Add new ClothingType
export var ClothingTypeDisplayString = {
    All: "All",
    Kaos: "Kaos",
    KaosPanjang: "Kaos Lengan Panjang",
    Sweater: "Sweater",
    PoloShirt: "Polo Shirt",
    PoloShirtPanjang: "Polo Shirt Lengan Panjang",
    KemejaPendek: "Kemeja Pendek",
    KemejaPanjang: "Kemeja Panjang",
    Jaket: "Jaket",
    KokoTigaEmpat: "Koko Santai",
};
// KEY2TODO-TYPE Add if there is new FitType
export var FitType;
(function (FitType) {
    FitType["SlimFit"] = "SlimFit";
    FitType["RegularFit"] = "RegularFit";
    FitType["OversizedFit"] = "OversizedFit";
})(FitType || (FitType = {}));
// KEY2TODO-TYPE Add if there is new FitType
export var FitTypeDisplayString = {
    SlimFit: "Slim Fit",
    RegularFit: "Regular Fit",
    OversizedFit: "Oversized Fit",
};
// [RELATIONSHIP] I. BODY MEASUREMENTS FOR EACH CLOTHING TYPE
// REQUIRED BODY MEASUREMENTS ONLY. OPTIONAL BODY MEASUREMENTS NOT INCLUDED.
export function getBodyMeasurementListInOrder(bodyMeasurementList, measurementOrderData) {
    var newOrder = [];
    if (IssueOrderByBodyType[measurementOrderData.bodyType]) {
        var bodyTypeOrder = IssueOrderByBodyType[measurementOrderData.bodyType];
        for (var bodyMeasurementKey in bodyTypeOrder) {
            var bodyMeasurement = bodyTypeOrder[bodyMeasurementKey];
            if (bodyMeasurementList.indexOf(bodyMeasurement) !== -1) {
                newOrder.push(bodyMeasurement);
            }
        }
        return newOrder;
    }
    else {
        return __spreadArray([], bodyMeasurementList, true);
    }
}
// ONLY CONTAINS B MEASUREMENTS
var IssueOrderByBodyType = {
    "upright_triangle": ["lingkarPerut", "lingkarDada", "lingkarLeher", "lebarBahu",
        "lingkarLenganBisep", "panjangBaju", "panjangLenganPanjang", "lingkarLenganPergelangan"],
    "rectangle": ["panjangBaju", "lingkarLenganBisep", "lebarBahu", "lingkarLeher",
        "lingkarPerut", "lingkarDada", "panjangLenganPanjang", "lingkarLenganPergelangan"],
    "inverted_triangle": ["lingkarPerut", "lingkarLenganBisep", "lebarBahu", "panjangBaju",
        "lingkarLeher", "lingkarDada", "panjangLenganPanjang", "lingkarLenganPergelangan"],
};
// [RELATIONSHIP] II. CLOTHING MEASUREMENTS FOR EACH CLOTHING TYPE
// Classification of each clothing measurement. 
// Are they basic, advanced or unavailable ?
// When it is not listed here, we assume it is advanced.
export var ClothingMeasurementAdjustmentLevel = (_a = {},
    _a[ClothingMeasurementsKey.panjangBaju] = "basic",
    _a[ClothingMeasurementsKey.lebarDadaBaju] = "basic",
    _a[ClothingMeasurementsKey.lebarPerutBaju] = "basic",
    _a[ClothingMeasurementsKey.lebarBahuBaju] = "basic",
    _a[ClothingMeasurementsKey.lebarLenganAtasBaju] = "advanced",
    _a[ClothingMeasurementsKey.lebarLenganBaju] = "advanced",
    _a[ClothingMeasurementsKey.panjangLenganPendek] = "advanced",
    _a[ClothingMeasurementsKey.panjangLenganPanjang] = "advanced",
    _a[ClothingMeasurementsKey.lingkarLeherBaju] = "advanced",
    _a);
export var ClothingMeasurementAdjustmentDisplayRange = (_b = {},
    _b[ClothingMeasurementsKey.panjangBaju] = 3,
    _b[ClothingMeasurementsKey.lebarDadaBaju] = 4,
    _b[ClothingMeasurementsKey.lebarPerutBaju] = 4,
    _b[ClothingMeasurementsKey.lebarBahuBaju] = 4,
    _b[ClothingMeasurementsKey.lebarLenganAtasBaju] = 2,
    _b[ClothingMeasurementsKey.lebarLenganBaju] = 2,
    _b[ClothingMeasurementsKey.panjangLenganPendek] = 2,
    _b[ClothingMeasurementsKey.panjangLenganPanjang] = 3,
    _b[ClothingMeasurementsKey.lingkarLeherBaju] = 1,
    _b);
export var ClothingMeasurementOrdering = [
    ClothingMeasurementsKey.lingkarLeherBaju,
    ClothingMeasurementsKey.lebarDadaBaju,
    ClothingMeasurementsKey.lebarPerutBaju,
    ClothingMeasurementsKey.panjangBaju,
    ClothingMeasurementsKey.lebarBahuBaju,
    ClothingMeasurementsKey.lebarLenganAtasBaju,
    ClothingMeasurementsKey.panjangLenganPanjang,
    ClothingMeasurementsKey.panjangLenganPendek,
    ClothingMeasurementsKey.lebarLenganBaju,
];
export var ClothingMeasurementInstructionsImageUrl = (_c = {},
    _c[ClothingMeasurementsKey.panjangBaju] = "https://images-far-east.s3.ap-southeast-1.amazonaws.com/clothmeasureinstruct-panjangbaju.png",
    _c[ClothingMeasurementsKey.lebarDadaBaju] = "https://images-far-east.s3.ap-southeast-1.amazonaws.com/clothmeasureinstruct-lebardada.png",
    _c[ClothingMeasurementsKey.lebarPerutBaju] = "https://images-far-east.s3.ap-southeast-1.amazonaws.com/clothmeasureinstruct-lebarperut.png",
    _c[ClothingMeasurementsKey.lebarBahuBaju] = "https://images-far-east.s3.ap-southeast-1.amazonaws.com/clothmeasureinstruct-lebarbahu.png",
    _c[ClothingMeasurementsKey.lebarLenganBaju] = "https://images-far-east.s3.ap-southeast-1.amazonaws.com/clothmeasureinstruct-lebarlengan.png",
    _c[ClothingMeasurementsKey.panjangLenganPendek] = "https://images-far-east.s3.ap-southeast-1.amazonaws.com/clothmeasureinstruct-panjanglengan.png",
    _c[ClothingMeasurementsKey.panjangLenganPanjang] = "https://images-far-east.s3.ap-southeast-1.amazonaws.com/clothmeasureinstruct-panjanglengan.png",
    _c[ClothingMeasurementsKey.lingkarLeherBaju] = "",
    _c[ClothingMeasurementsKey.lebarLenganAtasBaju] = "",
    _c);
// CB TO C CONVERSIONS
var standardCompleteClothBodyToClothingMeasLenganPendek = (_d = {},
    _d[BodyMeasurementsKey.lebarBahu] = ClothingMeasurementsKey.lebarBahuBaju,
    _d[BodyMeasurementsKey.panjangBaju] = ClothingMeasurementsKey.panjangBaju,
    _d[BodyMeasurementsKey.lingkarLeher] = ClothingMeasurementsKey.lingkarLeherBaju,
    _d[BodyMeasurementsKey.lingkarDada] = ClothingMeasurementsKey.lebarDadaBaju,
    _d[BodyMeasurementsKey.lingkarPerut] = ClothingMeasurementsKey.lebarPerutBaju,
    _d[BodyMeasurementsKey.lingkarLenganBisep] = ClothingMeasurementsKey.lebarLenganAtasBaju,
    // CB
    _d[BodyMeasurementsKey.panjangLenganPendek] = ClothingMeasurementsKey.panjangLenganPendek,
    _d[BodyMeasurementsKey.lebarLenganPendek] = ClothingMeasurementsKey.lebarLenganBaju,
    _d);
var standardCompleteClothBodyToClothingMeasLenganPanjang = (_e = {},
    _e[BodyMeasurementsKey.lebarBahu] = ClothingMeasurementsKey.lebarBahuBaju,
    _e[BodyMeasurementsKey.panjangBaju] = ClothingMeasurementsKey.panjangBaju,
    _e[BodyMeasurementsKey.lingkarLeher] = ClothingMeasurementsKey.lingkarLeherBaju,
    _e[BodyMeasurementsKey.lingkarDada] = ClothingMeasurementsKey.lebarDadaBaju,
    _e[BodyMeasurementsKey.lingkarPerut] = ClothingMeasurementsKey.lebarPerutBaju,
    _e[BodyMeasurementsKey.lingkarLenganBisep] = ClothingMeasurementsKey.lebarLenganAtasBaju,
    _e[BodyMeasurementsKey.panjangLenganPanjang] = ClothingMeasurementsKey.panjangLenganPanjang,
    _e[BodyMeasurementsKey.lingkarLenganPergelangan] = ClothingMeasurementsKey.lebarLenganBaju,
    _e);
// KEY2TODO-TYPE Assign clothBody (CB) and cloth (C) variables
export var CompleteClothBodyToCompleteClothMeasForClothingTypeAndFitType = {
    All: {},
    // Lengan Pendek
    Kaos: standardCompleteClothBodyToClothingMeasLenganPendek,
    PoloShirt: standardCompleteClothBodyToClothingMeasLenganPendek,
    KemejaPendek: standardCompleteClothBodyToClothingMeasLenganPendek,
    // Lengan Panjang
    KaosPanjang: standardCompleteClothBodyToClothingMeasLenganPanjang,
    PoloShirtPanjang: standardCompleteClothBodyToClothingMeasLenganPanjang,
    KemejaPanjang: standardCompleteClothBodyToClothingMeasLenganPanjang,
    Sweater: standardCompleteClothBodyToClothingMeasLenganPanjang,
    Jaket: standardCompleteClothBodyToClothingMeasLenganPanjang,
    // Other Atasan
    KokoTigaEmpat: (_f = {},
        _f[BodyMeasurementsKey.lebarBahu] = ClothingMeasurementsKey.lebarBahuBaju,
        _f[BodyMeasurementsKey.panjangBaju] = ClothingMeasurementsKey.panjangBaju,
        _f[BodyMeasurementsKey.lingkarLeher] = ClothingMeasurementsKey.lingkarLeherBaju,
        _f[BodyMeasurementsKey.lingkarDada] = ClothingMeasurementsKey.lebarDadaBaju,
        _f[BodyMeasurementsKey.lingkarPerut] = ClothingMeasurementsKey.lebarPerutBaju,
        _f[BodyMeasurementsKey.lingkarLenganBisep] = ClothingMeasurementsKey.lebarLenganAtasBaju,
        _f[BodyMeasurementsKey.panjangLenganPanjang] = ClothingMeasurementsKey.panjangLenganPanjang,
        // CB 
        _f[BodyMeasurementsKey.lebarLenganBawahTigaEmpat] = ClothingMeasurementsKey.lebarLenganBaju,
        _f),
};
// CB ITERATION @ FRONTEND
// There are 2 states for each variable.
// FREE OR FOLLOWING. This is Action / Next Step Consequences / Dynamic (Determines NEXT CB)
// All B variables are FREE.
// All CB variables not in B can be FREE or FOLLOWING.
// VISIBLE OR HIDDEN. This is Access Control / Predetermined (Determines Access)
// FREE measurements must be VISIBLE. Otherwise, people wouldn't be able to set the variable.
// FOLLOWING measurements can be 'set' to VISIBLE or HIDDEN. 
// - If HIDDEN, the variable will always keep FOLLOWING. There are no HIDDEN variables that are FREE.
// - If VISIBLE, the default can be FREE or FOLLOWING. After it is interacted with, it becomes FREE.
// Currently, all variables that start off as FOLLOWING are HIDDEN.
// CB -> C MAPPING.
// Each CB chooses a C to represent it, and with it plus and minus functions (to CB) as effect.
// The visualization will depend on the C variable.
var standardDisplayClothBodyToClothingMeasLenganPendek = (_g = {},
    _g[BodyMeasurementsKey.lebarBahu] = ClothingMeasurementsKey.lebarBahuBaju,
    _g[BodyMeasurementsKey.panjangBaju] = ClothingMeasurementsKey.panjangBaju,
    _g[BodyMeasurementsKey.lingkarLeher] = ClothingMeasurementsKey.lingkarLeherBaju,
    _g[BodyMeasurementsKey.lingkarDada] = ClothingMeasurementsKey.lebarDadaBaju,
    _g[BodyMeasurementsKey.lingkarPerut] = ClothingMeasurementsKey.lebarPerutBaju,
    _g[BodyMeasurementsKey.lingkarLenganBisep] = ClothingMeasurementsKey.lebarLenganBaju,
    // FOLLOWING
    _g[BodyMeasurementsKey.panjangLenganPendek] = ClothingMeasurementsKey.panjangLenganPendek,
    _g);
var standardDisplayClothBodyToClothingMeasLenganPanjang = standardCompleteClothBodyToClothingMeasLenganPanjang;
// KEY2TODO-TYPE Assign clothBody (CB) and cloth (C) display
export var ClothBodyToDisplayedClothMeasForClothingTypeAndFitType = {
    All: {},
    // Lengan Pendek
    Kaos: standardDisplayClothBodyToClothingMeasLenganPendek,
    PoloShirt: standardDisplayClothBodyToClothingMeasLenganPendek,
    KemejaPendek: standardDisplayClothBodyToClothingMeasLenganPendek,
    // Lengan Panjang
    KaosPanjang: standardDisplayClothBodyToClothingMeasLenganPanjang,
    PoloShirtPanjang: standardDisplayClothBodyToClothingMeasLenganPanjang,
    KemejaPanjang: standardDisplayClothBodyToClothingMeasLenganPanjang,
    Sweater: standardDisplayClothBodyToClothingMeasLenganPanjang,
    Jaket: standardDisplayClothBodyToClothingMeasLenganPanjang,
    // Other Atasan
    KokoTigaEmpat: (_h = {},
        _h[BodyMeasurementsKey.lebarBahu] = ClothingMeasurementsKey.lebarBahuBaju,
        _h[BodyMeasurementsKey.panjangBaju] = ClothingMeasurementsKey.panjangBaju,
        _h[BodyMeasurementsKey.lingkarLeher] = ClothingMeasurementsKey.lingkarLeherBaju,
        _h[BodyMeasurementsKey.lingkarDada] = ClothingMeasurementsKey.lebarDadaBaju,
        _h[BodyMeasurementsKey.lingkarPerut] = ClothingMeasurementsKey.lebarPerutBaju,
        _h[BodyMeasurementsKey.lingkarLenganBisep] = ClothingMeasurementsKey.lebarLenganBaju,
        _h[BodyMeasurementsKey.panjangLenganPanjang] = ClothingMeasurementsKey.panjangLenganPanjang,
        _h),
};
// KEY2TODO-TYPE Set default FOLLOWING measurements
// Fill this with the default (starting) FOLLOWING measurements
export var DefaultFollowingMeasurementsForClothingTypeAndFitType = {
    "Kaos-SlimFit": [BodyMeasurementsKey.lebarLenganPendek],
    "Kaos-RegularFit": [BodyMeasurementsKey.lebarLenganPendek],
    "PoloShirt-RegularFit": [BodyMeasurementsKey.lebarLenganPendek],
    "KemejaPendek-RegularFit": [BodyMeasurementsKey.lebarLenganPendek],
    "KokoTigaEmpat-RegularFit": [BodyMeasurementsKey.lebarLenganBawahTigaEmpat],
    "KaosPanjang-RegularFit": [],
    "PoloShirtPanjang-RegularFit": [],
    "KemejaPanjang-RegularFit": [],
    "Sweater-RegularFit": [],
    "Jaket-RegularFit": [],
};
// KEY2TODO-TYPE Fill with pattern constants
var ConstantsForClothingTypeAndFitType = {
    "Kaos-SlimFit": {
        "EaseBadan": 0.05,
        "EaseLenganAtas": 0.23,
        "RatioLenganBawahLenganAtas": 0.9,
        "RatioPanjangLenganLenganAtas": 0.68,
    },
    "Kaos-RegularFit": {
        "EaseBadan": 0.05,
        "EaseLenganAtas": 0.23,
        "RatioLenganBawahLenganAtas": 0.9,
        "RatioPanjangLenganLenganAtas": 0.68,
    },
    "PoloShirt-RegularFit": {
        "EaseBadan": 0.05,
        "EaseLenganAtas": 0.28,
        "RatioLenganBawahLenganAtas": 0.91,
        "RatioPanjangLenganLenganAtas": 0.71,
    },
    "KemejaPendek-RegularFit": {
        "EaseBadan": 0.1,
        "EaseLenganAtas": 0.28,
        "RatioLenganBawahLenganAtas": 0.86,
        "RatioPanjangLenganLenganAtas": 0.78,
    },
    "PoloShirtPanjang-RegularFit": {
        "EaseBadan": 0.05,
        "EaseLenganAtas": 0.28,
        "EasePergelanganTangan": 0.235,
    },
    "KemejaPanjang-RegularFit": {
        "EaseBadan": 0.1,
        "EaseLenganAtas": 0.28,
        "EasePergelanganTangan": 0.235,
    },
    "KokoTigaEmpat-RegularFit": {
        "EaseBadan": 0.19,
        "EaseLenganAtas": 0.28,
        "RatioPanjangLenganPanjang": 0.7,
        "RatioLenganBawahLenganAtas": 0.86, // Lengan Bawah = 0.5 * #RatioLenganBawahLenganAtas * (1 + #EaseLenganAtas) * lingkarLenganBisep
    }
};
// KEY2TODO-TYPE Write the conversion function
// This is B -> CB. This is a process more commonly known as hydration.
// Mutes B to CB, but it is idempotent (If CB result is entered, the same result will be spitted out)
export function CalculateClothBodyMeasurementsFromBodyMeasurements(clothingType, fitType, bodyMeas, defaultSize, setAsFollowing) {
    var key2 = clothingType + "-" + fitType;
    var defaultBodyMeasurements = BodyMeasurementsForSize[defaultSize];
    var resultClothBodyMeasurements = {}; // This is CB
    var setAsFollowingDict = {};
    for (var _i = 0, setAsFollowing_1 = setAsFollowing; _i < setAsFollowing_1.length; _i++) {
        var followingBodyMeasKey = setAsFollowing_1[_i];
        setAsFollowingDict[followingBodyMeasKey] = true;
    }
    if (key2 === "Kaos-SlimFit" || key2 === "Kaos-RegularFit" || key2 === "PoloShirt-RegularFit" || key2 === "KemejaPendek-RegularFit") {
        var key2Constants = ConstantsForClothingTypeAndFitType[key2];
        resultClothBodyMeasurements.lebarBahu = bodyMeas.lebarBahu ? bodyMeas.lebarBahu : defaultBodyMeasurements.lebarBahu;
        resultClothBodyMeasurements.panjangBaju = bodyMeas.panjangBaju ? bodyMeas.panjangBaju : defaultBodyMeasurements.panjangBaju;
        resultClothBodyMeasurements.lingkarDada = bodyMeas.lingkarDada ? bodyMeas.lingkarDada : defaultBodyMeasurements.lingkarDada;
        resultClothBodyMeasurements.lingkarPerut = bodyMeas.lingkarPerut ? bodyMeas.lingkarPerut : defaultBodyMeasurements.lingkarPerut;
        resultClothBodyMeasurements.lingkarLenganBisep = bodyMeas.lingkarLenganBisep ? bodyMeas.lingkarLenganBisep : defaultBodyMeasurements.lingkarLenganBisep;
        // We "fit" the CB_KEY2 values with CB_KEY2_REQUEST_POLICY
        resultClothBodyMeasurements.lingkarLeher = bodyMeas.lingkarLeher ? bodyMeas.lingkarLeher : defaultBodyMeasurements.lingkarLeher;
        if (resultClothBodyMeasurements.lingkarLeher <= 37) {
            resultClothBodyMeasurements.lingkarLeher = 37;
        }
        else if (resultClothBodyMeasurements.lingkarLeher <= 41) {
            resultClothBodyMeasurements.lingkarLeher = 41;
        }
        else if (resultClothBodyMeasurements.lingkarLeher <= 45) {
            resultClothBodyMeasurements.lingkarLeher = 45;
        }
        // FOLLOWING Variables
        resultClothBodyMeasurements.panjangLenganPendek = bodyMeas.panjangLenganPendek ? bodyMeas.panjangLenganPendek : undefined;
        resultClothBodyMeasurements.lebarLenganPendek = bodyMeas.lebarLenganPendek ? bodyMeas.lebarLenganPendek : undefined;
        if (resultClothBodyMeasurements.panjangLenganPendek === undefined || setAsFollowingDict[BodyMeasurementsKey.panjangLenganPendek]) {
            var lingkarLenganBisepToPanjangLenganRatio = key2Constants["RatioPanjangLenganLenganAtas"];
            resultClothBodyMeasurements.panjangLenganPendek = bodyMeas.lingkarLenganBisep ? bodyMeas.lingkarLenganBisep * lingkarLenganBisepToPanjangLenganRatio :
                defaultBodyMeasurements.lingkarLenganBisep * lingkarLenganBisepToPanjangLenganRatio;
        }
        if (resultClothBodyMeasurements.lebarLenganPendek === undefined || setAsFollowingDict[BodyMeasurementsKey.lebarLenganPendek]) {
            var lingkarLenganBisepToLebarLenganRatio = (1 + key2Constants["EaseLenganAtas"]) * key2Constants["RatioLenganBawahLenganAtas"] * 0.5;
            resultClothBodyMeasurements.lebarLenganPendek = bodyMeas.lingkarLenganBisep ? bodyMeas.lingkarLenganBisep * lingkarLenganBisepToLebarLenganRatio :
                defaultBodyMeasurements.lingkarLenganBisep * lingkarLenganBisepToLebarLenganRatio;
        }
    }
    if (key2 === "KokoTigaEmpat-RegularFit") {
        var key2Constants = ConstantsForClothingTypeAndFitType[key2];
        resultClothBodyMeasurements.lebarBahu = bodyMeas.lebarBahu ? bodyMeas.lebarBahu : defaultBodyMeasurements.lebarBahu;
        resultClothBodyMeasurements.panjangBaju = bodyMeas.panjangBaju ? bodyMeas.panjangBaju : defaultBodyMeasurements.panjangBaju;
        resultClothBodyMeasurements.lingkarDada = bodyMeas.lingkarDada ? bodyMeas.lingkarDada : defaultBodyMeasurements.lingkarDada;
        resultClothBodyMeasurements.lingkarPerut = bodyMeas.lingkarPerut ? bodyMeas.lingkarPerut : defaultBodyMeasurements.lingkarPerut;
        resultClothBodyMeasurements.lingkarLenganBisep = bodyMeas.lingkarLenganBisep ? bodyMeas.lingkarLenganBisep : defaultBodyMeasurements.lingkarLenganBisep;
        // We "fit" the CB_KEY2 values with CB_KEY2_REQUEST_POLICY
        resultClothBodyMeasurements.lingkarLeher = bodyMeas.lingkarLeher ? bodyMeas.lingkarLeher : defaultBodyMeasurements.lingkarLeher;
        if (resultClothBodyMeasurements.lingkarLeher <= 37) {
            resultClothBodyMeasurements.lingkarLeher = 37;
        }
        else if (resultClothBodyMeasurements.lingkarLeher <= 41) {
            resultClothBodyMeasurements.lingkarLeher = 41;
        }
        else if (resultClothBodyMeasurements.lingkarLeher <= 45) {
            resultClothBodyMeasurements.lingkarLeher = 45;
        }
        resultClothBodyMeasurements.panjangLenganPanjang = bodyMeas.panjangLenganPanjang ? bodyMeas.panjangLenganPanjang : defaultBodyMeasurements.panjangLenganPanjang;
        // FOLLOWING Variables
        resultClothBodyMeasurements.lebarLenganBawahTigaEmpat = bodyMeas.lebarLenganBawahTigaEmpat ? bodyMeas.lebarLenganBawahTigaEmpat : undefined;
        if (resultClothBodyMeasurements.lebarLenganBawahTigaEmpat === undefined || setAsFollowingDict[BodyMeasurementsKey.lebarLenganBawahTigaEmpat]) {
            var lingkarLenganBisepToLebarLenganRatio = (1 + key2Constants["EaseLenganAtas"]) * key2Constants["RatioLenganBawahLenganAtas"] * 0.5;
            resultClothBodyMeasurements.lebarLenganBawahTigaEmpat = bodyMeas.lingkarLenganBisep ? bodyMeas.lingkarLenganBisep * lingkarLenganBisepToLebarLenganRatio :
                defaultBodyMeasurements.lingkarLenganBisep * lingkarLenganBisepToLebarLenganRatio;
        }
    }
    if (key2 === "KaosPanjang-RegularFit" || key2 === "PoloShirtPanjang-RegularFit" ||
        key2 === "KemejaPanjang-RegularFit" || key2 === "Sweater-RegularFit" || key2 === "Jaket-RegularFit") {
        var key2Constants = ConstantsForClothingTypeAndFitType[key2];
        resultClothBodyMeasurements.lebarBahu = bodyMeas.lebarBahu ? bodyMeas.lebarBahu : defaultBodyMeasurements.lebarBahu;
        resultClothBodyMeasurements.panjangBaju = bodyMeas.panjangBaju ? bodyMeas.panjangBaju : defaultBodyMeasurements.panjangBaju;
        resultClothBodyMeasurements.lingkarDada = bodyMeas.lingkarDada ? bodyMeas.lingkarDada : defaultBodyMeasurements.lingkarDada;
        resultClothBodyMeasurements.lingkarPerut = bodyMeas.lingkarPerut ? bodyMeas.lingkarPerut : defaultBodyMeasurements.lingkarPerut;
        resultClothBodyMeasurements.lingkarLenganBisep = bodyMeas.lingkarLenganBisep ? bodyMeas.lingkarLenganBisep : defaultBodyMeasurements.lingkarLenganBisep;
        // We "fit" the CB_KEY2 values with CB_KEY2_REQUEST_POLICY
        resultClothBodyMeasurements.lingkarLeher = bodyMeas.lingkarLeher ? bodyMeas.lingkarLeher : defaultBodyMeasurements.lingkarLeher;
        if (resultClothBodyMeasurements.lingkarLeher <= 37) {
            resultClothBodyMeasurements.lingkarLeher = 37;
        }
        else if (resultClothBodyMeasurements.lingkarLeher <= 41) {
            resultClothBodyMeasurements.lingkarLeher = 41;
        }
        else if (resultClothBodyMeasurements.lingkarLeher <= 45) {
            resultClothBodyMeasurements.lingkarLeher = 45;
        }
        resultClothBodyMeasurements.panjangLenganPanjang = bodyMeas.panjangLenganPanjang ? bodyMeas.panjangLenganPanjang : defaultBodyMeasurements.panjangLenganPanjang;
        resultClothBodyMeasurements.lingkarLenganPergelangan = bodyMeas.lingkarLenganPergelangan ? bodyMeas.lingkarLenganPergelangan : defaultBodyMeasurements.lingkarLenganPergelangan;
        if (key2 !== "PoloShirtPanjang-RegularFit") {
            // We use "subset match, then snap" method
            if (resultClothBodyMeasurements.lingkarLeher <= 37 &&
                resultClothBodyMeasurements.lingkarLenganPergelangan <= 17.8) {
                resultClothBodyMeasurements.lingkarLeher = 37;
                resultClothBodyMeasurements.lingkarLenganPergelangan = 17.8;
            }
            else if (resultClothBodyMeasurements.lingkarLeher <= 41 &&
                resultClothBodyMeasurements.lingkarLenganPergelangan <= 19.4) {
                resultClothBodyMeasurements.lingkarLeher = 41;
                resultClothBodyMeasurements.lingkarLenganPergelangan = 19.4;
            }
            else if (resultClothBodyMeasurements.lingkarLeher <= 45 &&
                resultClothBodyMeasurements.lingkarLenganPergelangan <= 21) {
                resultClothBodyMeasurements.lingkarLeher = 45;
                resultClothBodyMeasurements.lingkarLenganPergelangan = 21;
            }
        }
    }
    return resultClothBodyMeasurements;
}
// KEY2TODO-TYPE
// This is CB -> C OR B -> C (Will be converted to CB first).
export function CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, bodyMeas, defaultSize) {
    var key2 = clothingType + "-" + fitType;
    var defaultBodyMeasurements = BodyMeasurementsForSize[defaultSize];
    var resultClothingMeasurements = {};
    if (bodyMeas === null || bodyMeas === undefined) {
        return {};
    }
    if (key2 === "Kaos-SlimFit" || key2 === "Kaos-RegularFit" || key2 === "PoloShirt-RegularFit" || key2 === "KemejaPendek-RegularFit") {
        var key2Constants = ConstantsForClothingTypeAndFitType[key2];
        var clothBodyMeas = CalculateClothBodyMeasurementsFromBodyMeasurements(clothingType, fitType, bodyMeas, defaultSize, []);
        resultClothingMeasurements.lingkarLeherBaju = clothBodyMeas.lingkarLeher;
        resultClothingMeasurements.lebarBahuBaju = clothBodyMeas.lebarBahu;
        resultClothingMeasurements.panjangBaju = clothBodyMeas.panjangBaju;
        var badanBodyToClothRatio = (1 + key2Constants["EaseBadan"]) * 0.5;
        resultClothingMeasurements.lebarDadaBaju = clothBodyMeas.lingkarDada * badanBodyToClothRatio;
        resultClothingMeasurements.lebarPerutBaju = clothBodyMeas.lingkarPerut * badanBodyToClothRatio;
        var bisepToLebarLenganAtasRatio = (1 + key2Constants["EaseLenganAtas"]) * 0.5;
        resultClothingMeasurements.lebarLenganAtasBaju = clothBodyMeas.lingkarLenganBisep * bisepToLebarLenganAtasRatio;
        // FOLLOWING Variables
        resultClothingMeasurements.panjangLenganPendek = clothBodyMeas.panjangLenganPendek;
        resultClothingMeasurements.lebarLenganBaju = clothBodyMeas.lebarLenganPendek;
    }
    if (key2 === "KokoTigaEmpat-RegularFit") {
        var key2Constants = ConstantsForClothingTypeAndFitType[key2];
        var clothBodyMeas = CalculateClothBodyMeasurementsFromBodyMeasurements(clothingType, fitType, bodyMeas, defaultSize, []);
        resultClothingMeasurements.lingkarLeherBaju = clothBodyMeas.lingkarLeher;
        resultClothingMeasurements.lebarBahuBaju = clothBodyMeas.lebarBahu;
        resultClothingMeasurements.panjangBaju = clothBodyMeas.panjangBaju;
        var badanBodyToClothRatio = (1 + key2Constants["EaseBadan"]) * 0.5;
        resultClothingMeasurements.lebarDadaBaju = clothBodyMeas.lingkarDada * badanBodyToClothRatio;
        resultClothingMeasurements.lebarPerutBaju = clothBodyMeas.lingkarPerut * badanBodyToClothRatio;
        var bisepToLebarLenganAtasRatio = (1 + key2Constants["EaseLenganAtas"]) * 0.5;
        resultClothingMeasurements.lebarLenganAtasBaju = clothBodyMeas.lingkarLenganBisep * bisepToLebarLenganAtasRatio;
        var ratioPanjangLenganPanjang = key2Constants["RatioPanjangLenganPanjang"];
        resultClothingMeasurements.panjangLenganPanjang = clothBodyMeas.panjangLenganPanjang * ratioPanjangLenganPanjang;
        // FOLLOWING Variables
        resultClothingMeasurements.lebarLenganBaju = clothBodyMeas.lebarLenganBawahTigaEmpat;
    }
    if (key2 === "KaosPanjang-RegularFit" || key2 === "PoloShirtPanjang-RegularFit" ||
        key2 === "KemejaPanjang-RegularFit" || key2 === "Sweater-RegularFit" || key2 === "Jaket-RegularFit") {
        var key2Constants = ConstantsForClothingTypeAndFitType[key2];
        var clothBodyMeas = CalculateClothBodyMeasurementsFromBodyMeasurements(clothingType, fitType, bodyMeas, defaultSize, []);
        resultClothingMeasurements.lingkarLeherBaju = clothBodyMeas.lingkarLeher;
        resultClothingMeasurements.lebarBahuBaju = clothBodyMeas.lebarBahu;
        resultClothingMeasurements.panjangBaju = clothBodyMeas.panjangBaju;
        resultClothingMeasurements.panjangLenganPanjang = clothBodyMeas.panjangLenganPanjang;
        var badanBodyToClothRatio = (1 + key2Constants["EaseBadan"]) * 0.5;
        resultClothingMeasurements.lebarDadaBaju = clothBodyMeas.lingkarDada * badanBodyToClothRatio;
        resultClothingMeasurements.lebarPerutBaju = clothBodyMeas.lingkarPerut * badanBodyToClothRatio;
        var bisepToLebarLenganAtasRatio = (1 + key2Constants["EaseLenganAtas"]) * 0.5;
        resultClothingMeasurements.lebarLenganAtasBaju = clothBodyMeas.lingkarLenganBisep * bisepToLebarLenganAtasRatio;
        var easePergelanganTanganRatio = (1 + key2Constants["EasePergelanganTangan"]) * 0.5;
        resultClothingMeasurements.lebarLenganBaju = clothBodyMeas.lingkarLenganPergelangan * easePergelanganTanganRatio;
    }
    return resultClothingMeasurements;
}
export function RoundClothingMeasurementsToHalfPoint(clothingMeas) {
    var resultClothingMeasurements = {};
    for (var clothingMeasKey in clothingMeas) {
        resultClothingMeasurements[clothingMeasKey] = (Math.round(clothingMeas[clothingMeasKey]));
        if (clothingMeasKey === 'lebarLenganBaju') {
            resultClothingMeasurements[clothingMeasKey] = (Math.round(clothingMeas[clothingMeasKey] * 2) / 2);
        }
    }
    return resultClothingMeasurements;
}
export function GetKey2CategoryClothingSizeMeasurementValues(clothingType, fitType, includeExtraSize) {
    var regularSizeNames = [
        RegularSizeValue.XXS,
        RegularSizeValue.XS,
        RegularSizeValue.S,
        RegularSizeValue.M,
        RegularSizeValue.L,
        RegularSizeValue.XL,
        RegularSizeValue.XXL,
    ];
    if (includeExtraSize) {
        regularSizeNames.push(RegularSizeValue.XXXL);
        regularSizeNames.push(RegularSizeValue.XXXXL);
        regularSizeNames.push(RegularSizeValue.XXXXXL);
    }
    var clothMeasValues = [];
    var clothBodyMeasValues = [];
    for (var i = 0; i < regularSizeNames.length; i++) {
        var clothingBodyMeas = CalculateClothBodyMeasurementsFromBodyMeasurements(clothingType, fitType, BodyMeasurementsForSize[regularSizeNames[i]], RegularSizeValue.L, []);
        var clothingMeas = CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, BodyMeasurementsForSize[regularSizeNames[i]], RegularSizeValue.L);
        clothMeasValues.push(RoundClothingMeasurementsToHalfPoint(clothingMeas));
        clothBodyMeasValues.push(clothingBodyMeas);
    }
    var resultsData = {
        regularSizeNames: regularSizeNames,
        regularSizeClothMeasValues: clothMeasValues,
        regularSizeClothBodyMeasValues: clothBodyMeasValues,
    };
    return resultsData;
}
// KEY2TODO-TYPE
export var SizeChartUrlForClothingTypeAndFitType = {
    "Kaos-RegularFit": "https://images-far-east.s3.ap-southeast-1.amazonaws.com/sizechart-kaos-regularfit.png",
    "Kaos-FoundationFit": "https://images-far-east.s3.ap-southeast-1.amazonaws.com/sizechart-kaos-foundationfit.png",
    "Kaos-SlimFit": "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/sizechart-kaos-slimfit-2024-03.png",
    "PoloShirt-RegularFit": "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/sizechart-kaos-slimfit-2024-03.png",
    "PoloShirtPanjang-RegularFit": "https://images-far-east.s3.ap-southeast-1.amazonaws.com/sizechart-poloshirtpanjang-regularfit.png",
    "KokoTigaEmpat-RegularFit": "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/sizechart-kokosantai-2024-03.png",
    "KemejaPendek-RegularFit": "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/sizechart-kemejapendek-regularfit-2024-06.png",
    "KemejaPanjang-RegularFit": "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/sizechart-kemejapanjang-regularfit-2024-06.png",
};

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

interface StylesProps {
  widthXs: string
  widthSm: string
  widthMd: string
  widthLg: string
  margin: string
}

const useStyles=({widthXs,widthSm,widthMd,widthLg,margin}: StylesProps) => makeStyles((theme: Theme) =>
createStyles({
  itemBreakpoint: {
    display: 'flex',
    justifyContent: 'center',
    width: widthXs,
    [theme.breakpoints.only('sm')]: {
      width: widthSm,
    },
    [theme.breakpoints.only('md')]: {
      width: widthMd,
    },
    [theme.breakpoints.up('lg')]: {
      width: widthLg,
    },
    margin: margin,
  }
}),
);

interface Props {
  content: JSX.Element
  widthXs: string
  widthSm: string
  widthMd: string
  widthLg: string
  margin?: string // can be extended to depend on breakpoint
}

// Used to determine external size of item and internal margins of the item.
export default function PageItemBreakpoint({content,widthXs,widthSm,widthMd,widthLg,margin} : Props) {
  margin = margin ? margin : '0px 0px 0px 0px';
  const classes = useStyles({widthXs,widthSm,widthMd,widthLg,margin})();
  
  return (
    <Box className={classes.itemBreakpoint}>
      {
        content
      }
    </Box>
  );
}
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Star, StarHalf, StarOutline } from "@material-ui/icons";
import { Stack } from "@mui/material";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    starFilled: {
      color: "#FFC839",
    },
    starEmpty: {
      color: "#d8d8d8",
    },
  })
);

interface RatingProps {
  productRating: number;
  starSize?: number | string;
  gap?: number | string;
  mr?: number | string;
  mt?: number | string;
  ml?: number | string;
  mb?: number | string;
}

const StarRating: React.FC<RatingProps> = ({
  productRating,
  starSize = "default",
  gap = 1,
  mr = 0,
  mt = 0,
  ml = 0,
  mb = 0,
}) => {
  const classes = useStyles();
  const stars = Array(5).fill(0);

  for (let i = 0; i < stars.length; i++) {
    if (productRating >= i + 1) {
      stars[i] = 1;
    } else if (productRating >= i + 0.5) {
      stars[i] = 0.5;
    } else {
      stars[i] = 0;
    }
  }

  return (
    <Stack
      direction="row"
      spacing={gap}
      alignItems="center"
      justifyContent="flex-start"
      mr={mr}
      mt={mt}
      ml={ml}
      mb={mb}
    >
      {stars.map((starState, index) => (
        <div key={index}>
          {starState === 1 && (
            <Star
              className={classes.starFilled}
              style={{ fontSize: starSize }}
            />
          )}
          {starState === 0.5 && (
            <StarHalf
              className={classes.starFilled}
              style={{ fontSize: starSize }}
            />
          )}
          {starState === 0 && (
            <StarOutline
              className={classes.starEmpty}
              style={{ fontSize: starSize }}
            />
          )}
        </div>
      ))}
    </Stack>
  );
};

export default StarRating;

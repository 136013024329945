import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import OverlayComponentBaseContainerVertical from './OverlayComponentBaseContainerVertical';
import OverlayComponentTitle from './OverlayComponentTitle';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentBaseTemplate from './OverlayComponentBaseTemplate';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  bodyContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  optionsContainer: {
    paddingTop: '24px',
    paddingBottom: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}),
);

interface Props {
  title: string
  titleNumber?: string
  bodyChildren: JSX.Element[]
  options: JSX.Element[]
}

export default function OverlayComponentChoice({ title, titleNumber, bodyChildren, options }: Props) {
  const classes = useStyles();

  return (
    <OverlayComponentBaseTemplate>
      <OverlayComponentTitle text={title} titleNumber={titleNumber} textAlign="center"/>
      <Box className={classes.bodyContainer}>
        {
          bodyChildren
        }
      </Box>
      <Box className={classes.optionsContainer}>
        {
          options
        }
      </Box>
    </OverlayComponentBaseTemplate>
  );
}
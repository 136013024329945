import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { ClothingType, FitType } from 'common-ts/dist/models/UserMeasurements';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OverlaySizeChartState {
  status: UIOverlayViewStatus,

  key2: string,
}

// Define the initial state using that type
const initialState: OverlaySizeChartState = {
  status: UIOverlayViewStatus.OffAndReady,
  key2: "",
}

export const overlaySizeChartSlice = createSlice({
  name: 'overlaySizeChart',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;

      state.key2 = "";
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;
    },

    updateSizeChartClothFitType: (state, action: PayloadAction<{
      clothingType: ClothingType,
      fitType: FitType
    }>) => {
      state.key2 = action.payload.clothingType + "-" + action.payload.fitType;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay,
  updateSizeChartClothFitType } = overlaySizeChartSlice.actions

export default overlaySizeChartSlice.reducer
import React, { useEffect } from 'react';

import { Box, Button, createStyles, IconButton, makeStyles, Slide, Theme, Typography } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryTabsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      overflow: 'hidden',
      backgroundColor: '#FDFDFD',
      boxShadow: '0px 4.5px 7px -2px rgba(0, 0, 0, 0.1)',
    },
    slider: {
      position: 'relative',
      width: '19em',
      height: '3.5em',
      overflow: 'hidden',
    },
    categoryTab: {
      position: 'absolute',
      left: `calc(50% - 2em)`,
      textAlign: 'center',
      width: '4em',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s',
    },
    selectedMainText: {
      display: 'block',
      fontWeight: 650,
      paddingTop: '0.3em',
      lineHeight: '1.1em',
    },
    selectedMainText2: {
      display: 'block',
      fontWeight: 650,
      fontSize: '0.8rem',
      paddingBottom: '0.1em',
      lineHeight: '1em',
      textWrap: 'nowrap',
    },
    selectedMainText2Box: {
      paddingBottom: '0.05em',
      paddingTop: '0.2em',
    },
    unselectedText: {
      display: 'block',
      fontWeight: 300,
      fontSize: '0.8em',
      lineHeight: '14px',
      paddingTop: '6px'
    },
    unselectedText2: {
      display: 'block',
      fontWeight: 300,
      fontSize: '0.67em',
      lineHeight: '11px',
      paddingBottom: '2px',
      textWrap: 'nowrap',
    },
    unselectedText2Box: {
      paddingBottom: '1px',
    },
    selectedSubText: {
      display: 'block',
      fontSize: '0.6em',
    },
    unselectedSubText: {
      display: 'block',
      fontSize: '0.4em',
      color: '#aaaaaa'
    },
    productAmountBoxAnimation: {
      position: "absolute",
      top: "64px",
      padding: "6px 9px 4px 9px",
      borderRadius: "14px",
      backgroundColor: "#232323",
      zIndex: -1,

      animation: `$popOutEffect 3500ms ${theme.transitions.easing.easeInOut}`,
      opacity: 1,
      transform: "translateY(-200%)"
    },
    productAmountBoxAnimationExiting: {
      position: "absolute",
      top: "64px",
      padding: "6px 9px 4px 9px",
      borderRadius: "14px",
      backgroundColor: "#232323",
      zIndex: -1,

      opacity: 0,
      display: 'none',
      transform: "translateY(-200%)"
    },
    "@keyframes popOutEffect": {
      "0%": {
        opacity: 0,
        transform: "translateY(-200%)"
      },
      "2%": {
        opacity: 1,
        transform: "translateY(-180%)"
      },
      "15%": {
        opacity: 1,
        transform: "translateY(0)"
      },
      "85%": {
        opacity: 1,
        transform: "translateY(0)"
      },
      "98%": {
        opacity: 1,
        transform: "translateY(-180%)"
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-200%)"
      }
    },
  }),
);

interface Props {
  categoriesMainText: string[],
  categoriesMainText2: string[],
  categoriesSubtext: string[],
  categoriesProductAmount: number[],
  onCategoryChange: (category: number) => void,
  currentCategoryValue?: number // Optional controlled state
}

export default function CategoryTabs({ categoriesMainText, categoriesMainText2, categoriesSubtext, 
  categoriesProductAmount, onCategoryChange, currentCategoryValue }: Props) {
  const [internalCategoryTabIndex, setCategoryTabIndex] = React.useState(0);

  const categoryTabIndex = currentCategoryValue !== undefined ? currentCategoryValue : internalCategoryTabIndex;

  const [animationAllowed, setAnimationAllowed] = React.useState(false);

  const classes = useStyles();

  const handleArrowClick = (direction: 'left' | 'right') => {
    const totalCategories = categoriesMainText.length;
    let newTabIndex;

    if (direction === 'left') {
      newTabIndex = (categoryTabIndex - 1 + totalCategories) % totalCategories;
    } else {
      newTabIndex = (categoryTabIndex + 1) % totalCategories;
    }

    setCategoryTabIndex(newTabIndex);
    onCategoryChange(newTabIndex);
    loadChange();
  }

  const loadChange = () => {
    // get all categoryTabs
    const categoryTabs = document.getElementsByClassName(classes.categoryTab);

    let activeCategoryTab = categoryTabs[categoryTabIndex] as HTMLElement;
    activeCategoryTab.style.transform = `none`;

    let translateLength = 150;

    let offset = 0;
    for (let i = categoryTabIndex + 1; i < categoriesMainText.length; i++) {
      offset++;
      let categoryTab = categoryTabs[i] as HTMLElement;
      categoryTab.style.transform = `translateX(${translateLength * offset}%)`;
    }
    offset = 0;
    for (let i = categoryTabIndex - 1; i >= 0; i--) {
      offset++;
      let categoryTab = categoryTabs[i] as HTMLElement;
      categoryTab.style.transform = `translateX(${-translateLength * offset}%)`;
    }
  }

  useEffect(() => {
    loadChange();
  })

  useEffect(() => {
    setTimeout(() => {
      setAnimationAllowed(true);
    }, 1000)
  }, [])

  return (
    <Box style={{ 
      position: 'relative', 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Box className={classes.categoryTabsContainer}>
        <IconButton
          style={{ visibility: categoryTabIndex !== 0 ? 'visible' : 'hidden' }}
          onClick={() => handleArrowClick('left')}
          disabled={categoryTabIndex === 0}>
          <ArrowLeft htmlColor='black' />
        </IconButton>

        <Box className={classes.slider}>
          {categoriesMainText.map((category, index) => {
            const isActive = index === categoryTabIndex;

            return (
              <Box
                key={index}
                className={classes.categoryTab}
                style={{
                  transform: `translateX(${150 * index}%)` // Added to remove "side movement on initialization"
                }}
              >
                {
                isActive ? 
                (
                  <div>
                    {
                      categoriesMainText2?.[index] ?
                      <Box className={classes.selectedMainText2Box}>
                        <Typography className={classes.selectedMainText2}>{category}</Typography>
                        <Typography className={classes.selectedMainText2}>{categoriesMainText2[index]}</Typography>
                      </Box> :
                      <Typography className={classes.selectedMainText}>{category}</Typography>
                    }
                    {
                      categoriesSubtext?.[index] ?
                      <Typography className={classes.selectedSubText}>{categoriesSubtext[index]}</Typography>:
                      null
                    }
                  </div>
                ) : 
                index === categoryTabIndex - 1 ? 
                (
                  <div>
                    {
                      categoriesMainText2?.[index] ?
                      <Box className={classes.unselectedText2Box} onClick={() => {handleArrowClick('left')}}>
                        <Typography className={classes.unselectedText2}>{category}</Typography>
                        <Typography className={classes.unselectedText2}>{categoriesMainText2[index]}</Typography>
                      </Box> :
                      <Typography className={classes.unselectedText} onClick={() => {handleArrowClick('left')}}>{category}</Typography>
                    }
                    {
                      categoriesSubtext?.[index] ?
                      <Typography className={classes.unselectedSubText} onClick={() => {handleArrowClick('left')}}>{categoriesSubtext[index]}</Typography>:
                      null
                    }
                  </div>
                ) : 
                index === categoryTabIndex + 1 ? 
                (
                  <div>
                    {
                      categoriesMainText2?.[index] ?
                      <Box className={classes.unselectedText2Box}  onClick={() => {handleArrowClick('right')}}>
                        <Typography className={classes.unselectedText2}>{category}</Typography>
                        <Typography className={classes.unselectedText2}>{categoriesMainText2[index]}</Typography>
                      </Box> :
                      <Typography className={classes.unselectedText} onClick={() => {handleArrowClick('right')}}>{category}</Typography>
                    }
                    {
                      categoriesSubtext?.[index] ?
                      <Typography className={classes.unselectedSubText} onClick={() => {handleArrowClick('right')}}>{categoriesSubtext[index]}</Typography>:
                      null
                    }
                  </div>
                ) : 
                (
                  <div>
                    {
                      categoriesMainText2?.[index] ?
                      <Box className={classes.unselectedText2Box}>
                        <Typography className={classes.unselectedText2}>{category}</Typography>
                        <Typography className={classes.unselectedText2}>{categoriesMainText2[index]}</Typography>
                      </Box> :
                      <Typography className={classes.unselectedText}>{category}</Typography>
                    }
                    {
                      categoriesSubtext?.[index] ?
                      <Typography className={classes.unselectedSubText}>{categoriesSubtext[index]}</Typography>:
                      null
                    }
                  </div>
                )}
              </Box>
            )
          })}
        </Box>

        <IconButton
          style={{ visibility: categoryTabIndex !== categoriesMainText.length - 1 ? 'visible' : 'hidden' }}
          onClick={() => handleArrowClick('right')}
          disabled={categoryTabIndex === categoriesMainText.length - 1}>
          <ArrowRight htmlColor='black' />
        </IconButton>

      </Box>

      {
        categoriesMainText.map((category, index) => {
          return (
          <Box className={index === categoryTabIndex && animationAllowed ? classes.productAmountBoxAnimation : classes.productAmountBoxAnimationExiting}>
            <Typography style={{
              fontSize: "10px",
              color: "#ffffff",
            }}>
              {"Ada " + categoriesProductAmount?.[index] + " Produk"}
            </Typography>
          </Box>
          );
        })
      }
    </Box>
  )
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { OrdersListState, Order } from 'common-ts/dist/models/Orders';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '../store';

// Define the initial state using that type
const initialState: OrdersListState = {
  ordersList: [],
  paidOrderCount: 0,
  status: APIRequestStatus.Idle,
  error: null,
}

export const fetchOrdersList = createAsyncThunk('ordersList/fetchOrdersList', 
async (payload, thunkAPI) => {
  const rootState = (thunkAPI.getState() as RootState);
  const idToken = rootState.account.idToken;
  
  var config: AxiosRequestConfig = {
    method: 'get',
    url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/orders',
    headers: { 
      'Authorization': 'Bearer ' + idToken, 
    }
  };

  const response = await axios(config);
  console.log(response.data);
  return response.data
})

export const orderSlice = createSlice({
  name: 'ordersList',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrdersList.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchOrdersList.fulfilled, (state, action) => {

        // Add Payload to our state
        const OrderArray: Order[] = JSON.parse(action.payload.body).Items;
        state.ordersList = []
        for (let key in OrderArray) {
          const orderDetail: Order = OrderArray[key];
          state.ordersList.push(orderDetail);
        }
        if (action.payload.paidOrderCount) {
          state.paidOrderCount = action.payload.paidOrderCount;
        }
        state.status = APIRequestStatus.Success;
      })
      .addCase(fetchOrdersList.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
        console.log("fetch is failure");
      })
  }
})

// Action creators are generated for each case reducer function
// export const { addProductToList } = productSlice.actions

export default orderSlice.reducer
import { Box, CircularProgress } from '@material-ui/core';
import {
  useParams, Redirect,
} from "react-router-dom";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { signIn } from '../redux/AccountRedux';
import { openOverlay as openOverlayPersonalSize } from '../redux/UIOverlayPersonalSizeRedux';

export default function PageCreatePersonalSize() {
  let { userSub } = useParams<{ userSub: string }>();
  const userIsSignedInAndHasPurchased = useAppSelector(state => state.account.authState === 'signedin' && state.ordersList.paidOrderCount >= 1);
  const userAcc = useAppSelector(state => state.account);
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const dispatch = useAppDispatch();

  // 01. Auto sign in on non-trivial userSub
  useEffect(() => {
    if (userSub && (!isSignedIn || userAcc.sub !== userSub)) {
      dispatch(signIn({ password: '123', useEmail: true, otpValue: '', subTempPass: 'skfajsakjf', userSub: userSub}));
    }
  }, [userSub]);

  // 02. We wait until sign in is completed
  if (!isSignedIn) {
    return (
    <Box style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress/>
    </Box>
    );
  }

  // 03. If the user is signed in and has purchased, we open personal size overlay and redirect to shop page
  if (userIsSignedInAndHasPurchased) {
    dispatch(openOverlayPersonalSize());

    return (<Redirect to={"/shop"} />);
  }

  // 04. If the user is signed in but has not purchased, we just return null / a blank page
  return (
    <Box style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress/>
    </Box>
    );
}
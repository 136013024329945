import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Popper,
  Paper,
  MenuItem,
  Grow,
  MenuList,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReviewItem from "./PageComponentReviewItem";
import StarRating from "./PageComponentReviewStarRating";
import { Stack } from "@mui/material";
import { Star } from "@material-ui/icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CategoryReview } from "common-ts/dist/models/CategoryReview";
import InfiniteScroll from "react-infinite-scroll-component";

interface Ratings {
  [key: number]: number;
}

interface RatingPopperProps {
  ratings: Ratings;
  onRatingSelect: (rating: number | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        maxWidth: "calc(35vw * 16 / 9)",
        maxHeight: "35vw",
        margin: 0,
        borderRadius: 20,
        overflow: "hidden",
        padding: "0 0 0 0",
        [theme.breakpoints.down('sm')]: {
          maxWidth: "87vw",
          maxHeight: "calc(87vw * 16 / 9)"
        }
      },
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    overlay: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(2),
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
    },
    categoryReviewContent: {
      marginBottom: "28px",
      paddingLeft: "26px",
      paddingRight: "30px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
        paddingRight: "20px",
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
    categoryReviewContentTitle: {
      fontWeight: "bold",
      color: "#121212",
      fontSize: "14px",
    },
    categoryReviewContentRating: {
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
    categoryReviewContentRatingPopperContainer: {
      border: "1px solid #BEBEBE",
      borderRadius: "20px",
      padding: "4px 12px",
      display: "inline-flex",
      alignItems: "center",
      cursor: "pointer",
      gap: theme.spacing(0.5),
    },
    ratingText: {
      fontWeight: "normal",
      fontSize: "12px",
    },
    ratingCount: {
      fontWeight: 400,
      fontSize: "11px",
      color: "#9B9B9B",
    },
    starFilled: {
      color: "#FFC839",
      fontSize: "20px",
    },
  })
);

// useMemo => Memoisasi komponen untuk performa
// RatingPopper hanya akan di re-render apabila propnya berubah (untuk callback function nanti menggunakan useCallback)
const RatingPopper: React.FC<RatingPopperProps> = React.memo(
  ({ ratings, onRatingSelect }) => {
    // MaterialUI Style
    const classes = useStyles();

    // State Initialization
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRating, setSelectedRating] = useState<number | null>(null);

    // Event Handler
    // Setting the anchor of the pooper to the UI element that is clicked
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleRatingSelect = (star: number) => {
      setSelectedRating(star === selectedRating ? null : star);
      setAnchorEl(null); // Close the pooper by setting the anchor to null
    };

    // Component mounting and dismounting
    useEffect(() => {
      onRatingSelect(selectedRating);

      // Trash collecting
      return () => {
        onRatingSelect(null);
      };
    }, [selectedRating, onRatingSelect]);

    // Get the sum of all reviews using .reduce from object's values
    const totalReviews = Object.values(ratings).reduce(
      (sum, count) => sum + count,
      0 // Initial value
    );

    return (
      <div>
        <Box
          onClick={handleClick}
          className={classes.categoryReviewContentRatingPopperContainer}
        >
          {selectedRating ? (
            <Box
              flexDirection={"row"}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box
                flexDirection={"row"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  alignItems: "center",
                }}
              >
                <Star className={classes.starFilled} />
                <Typography variant="body1" className={classes.ratingText}>
                  {selectedRating}
                </Typography>
                <Typography variant="body1" className={classes.ratingCount}>
                  ({ratings[selectedRating]})
                </Typography>
              </Box>
              <ArrowDropDownIcon
                style={{ color: "#1B1B1B", fontSize: "20px" }}
              />
            </Box>
          ) : (
            <Typography variant="body1" className={classes.ratingText}>
              All Ratings ({totalReviews})
            </Typography>
          )}
        </Box>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          transition
          style={{ zIndex: 1500 }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <MenuList>
                  {[5, 4, 3, 2, 1].map((star) => (
                    <MenuItem
                      key={star}
                      onClick={() => handleRatingSelect(star)}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          variant="body1"
                          className={classes.ratingText}
                        >
                          {star}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.ratingCount}
                        >
                          ({ratings[star] || 0})
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
);


interface ReviewDialogProps {
  open: boolean;
  onClose: () => void;
  categoryName: string;
  ratingNumber: number | undefined;
  ratingValue: number | undefined;
  reviews: CategoryReview[];
  onClickImage: (reviewIndex: number, imageIndex: number) => void;
}

// Helper function to sum and divide all the rating in the array and store it in the object
function countRatings(reviews: CategoryReview[]): Ratings {
  const ratings: Ratings = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };

  // forEach iterates through all the element and returns nothing
  reviews.forEach((review) => {
    if (review.rating >= 1 && review.rating <= 5) {
      ratings[review.rating]++;
    }
  });

  return ratings;
}

const ReviewDialog: React.FC<ReviewDialogProps> = ({
  open,
  onClose,
  categoryName,
  ratingNumber,
  ratingValue,
  reviews,
  onClickImage,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const ratings = countRatings(reviews);

  const renderReviewComponent = (item: any, index: number) => (
    <Box key={index} maxWidth={"100%"} mt={2}>
      <ReviewItem
        {...item}
        onClickImage={(imageIndex: number) =>
          onClickImage(index, imageIndex)
        }
        isCommentDisplayedFull={true}
      />
    </Box>
  );

  const [scrollObjectState, setScrollObjectState] = React.useState({
    reviews: reviews,
    items: reviews.slice(0, 6).map(renderReviewComponent),
    hasMore: true
  });

  // useCallback dipakai karena setiap kali re-render, function yang dijadikan prop ke komponen useMemo sebenarnya berbeda => Memoisasi gagal 
  const handleRatingSelect = useCallback(
    (selectedRating: number | null) => {
      if (selectedRating === null) {
        setScrollObjectState({
          reviews: reviews,
          items: reviews.slice(0, 6).map(renderReviewComponent),
          hasMore: true,
        });
      } else {
        const newFilteredReviews = reviews.filter((review) => review.rating === selectedRating);
        setScrollObjectState({
          reviews: newFilteredReviews,
          items: newFilteredReviews.splice(0, 6).map(renderReviewComponent),
          hasMore: true,
        });
      }
    },
    [reviews] // Jangan lupa dependensi sehingga function yang jadi prop pasti yang terbaru 
  );

  const fetchMoreData = () => {
    if (scrollObjectState.items && scrollObjectState.items.length >= scrollObjectState.reviews.length) {
      setScrollObjectState({ 
        reviews: scrollObjectState.reviews,
        items: scrollObjectState.items,
        hasMore: false 
      });
      return;
    }

    if (scrollObjectState.items) {
      setScrollObjectState({
        reviews: scrollObjectState.reviews,
        items: scrollObjectState.reviews.slice(0, scrollObjectState.items.length + 1).map(renderReviewComponent),
        hasMore: true,
      })
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className={classes.dialog}
    >
      <DialogTitle style={{ paddingLeft: "3.5vw" }}>
        <Box mt={0.8}>
          <Typography className={classes.categoryReviewContentTitle}>
            REVIEW KATEGORI {categoryName}
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "3.5vw" }} id="main-scroll-dialog">
        <Box>
          <Stack
            direction="row"
            className={classes.categoryReviewContentRating}
            mt={useMediaQuery(theme.breakpoints.down('sm')) ? -1.8 : -5}
          >
            <StarRating productRating={ratingValue ? ratingValue : 5} gap={"0.5vw"} mt={0.4} />
            <Typography variant="body1">
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", fontSize: "14px" }}
                component="span"
              >
                {ratingValue} /
              </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: "300", fontSize: "12px" }}
                component="span"
              >
                {" 5.0"}
              </Typography>
            </Typography>
            <Box style={{ paddingTop: 5 }}>
              <Typography
                variant="body2"
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  color: "#9B9B9B",
                }}
              >
                ({ratingNumber} REVIEW)
              </Typography>
            </Box>
          </Stack>

          <Box mt={1.5}>
            <RatingPopper
              ratings={ratings}
              onRatingSelect={handleRatingSelect}
            />
          </Box>

          <InfiniteScroll
            dataLength={scrollObjectState.items.length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={scrollObjectState.hasMore}
            loader={
            <div>
              <Typography style={{
                marginTop: '30px',
                marginLeft: '12px',
                marginBottom: '16px',
                color: '#565656',
                fontSize: '14px',
              }}>Tidak Ada Review</Typography>
            </div>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
              </p>
            }
            scrollThreshold="380px"
            scrollableTarget="main-scroll-dialog"
          >
            {scrollObjectState.items}
          </InfiniteScroll>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewDialog;

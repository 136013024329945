import React, { useState } from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px',
      [theme.breakpoints.only('xs')]: {
        width: '100vw',
        position: 'relative',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: '500px',
        margin: '0 auto',
        marginTop: '10px',
      },
      zIndex: 1000000,
      minHeight: '100px',
    },
    content: {
      width: '100%',
      height: '100%', 
    },
    image: {
      objectFit: 'cover', 
      width: '100%',
      height: '100%', 
      display: 'block', 
    },
  }),
);

interface BannerProps {
  source: string;
  handleClick: () => void;
}

export default function Banner({ source, handleClick }: BannerProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    console.error('Failed to load image:', source);
    setImageError(true);
  };

  return (
    <Box className={classes.banner} onClick={handleClick}>
      <Box className={classes.content}>
        {!imageError ? (
          <img 
            src={source} 
            className={classes.image}
            onError={handleImageError}
            alt="Banner" 
          />
        ) : (
          <Typography>Failed to load image</Typography>
        )}
      </Box>
    </Box>
  );
}
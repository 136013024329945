import { Box } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { RegularSizeValue, SizeType } from 'common-ts/dist/models/Cart';
import { BodyMeasurements, CalculateClothBodyMeasurementsFromBodyMeasurements, CalculateClothingMeasurementsFromBodyMeasurements, ClothingMeasurements, ClothingType, FitType, RoundClothingMeasurementsToHalfPoint, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputBodyMeasurementsForm, inputMeasurementsForm, postBodyMeasurement, postClothingMeasurement, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset } from '../redux/UIOverlayMeasurementEditViewRedux';
import { useAppDispatch } from '../reduxhooks';
import OverlayViewSaveMeasName from './OverlayViewMeasurementClothingEdit2SaveScreen';
import OverlayViewFavoriteModifySize from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';
import OverlayViewSizeOptions from './OverlayViewSizeOptions'
import ProductOnUser from 'common-ts/dist/models/Product';

// Determines which flow is used based on starting parameters
export interface FlowSelectionData {
  clothingType: ClothingType
  fitType: FitType
  productDetail?: ProductOnUser
}

// State data for flow
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateData {
  currentScreen: string
  titleNumber: number
}

// Callback to change state data for flow. 
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: string) => void
  setTitleNumber: (titleNumber: number) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentMasterPersonalSize(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentMensTops(flowSelectionData, flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentMensTops(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let titleNumber = -1;

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  switch (flowStateData.currentScreen) {
    case "selectionScreen": 
      children.push(<OverlayViewSizeOptions 
        fitType={flowSelectionData.fitType}
        clothingType={flowSelectionData.clothingType}
        onClose={() => {}} // TODO
      />);
      titleNumber = 1;
      break;
  }

  if (flowStateData.titleNumber != titleNumber) {
    flowStateCallback.setTitleNumber(titleNumber);
  }

  return children;
}
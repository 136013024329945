import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import {
  ClothingMeasurementsKey, ClothingType, FitType
} from 'common-ts/dist/models/UserMeasurements';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OverlayMeasureInstructionsState {
  status: UIOverlayViewStatus,

  clothingMeasurementKey: ClothingMeasurementsKey,
}

// Define the initial state using that type
const initialState: OverlayMeasureInstructionsState = {
  status: UIOverlayViewStatus.OffAndReady,
  clothingMeasurementKey: ClothingMeasurementsKey.lingkarLeherBaju,
}

export const overlayMeasureInstructionsSlice = createSlice({
  name: 'overlayMeasureInstructions',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;
      state.clothingMeasurementKey = ClothingMeasurementsKey.lingkarLeherBaju;
    },

    openOverlay: (state, action: PayloadAction<ClothingMeasurementsKey>) => {
      state.status = UIOverlayViewStatus.On;
      state.clothingMeasurementKey = action.payload;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay } = overlayMeasureInstructionsSlice.actions

export default overlayMeasureInstructionsSlice.reducer
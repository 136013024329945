import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { useAppSelector, useAppDispatch } from "../reduxhooks";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Link,
  Slide,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import OverlayComponentBaseContainerHorizontal from "./OverlayComponentBaseContainerHorizontal";
import { ArrowBack, Backspace } from "@material-ui/icons";
import OverlayComponentBaseContainerWidth from "./OverlayComponentBaseContainerWidth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowBackButtonContainer: {
      position: "relative",
      [theme.breakpoints.only("xs")]: {
        paddingBottom: "54px",
      },
    },
    arrowBackButton: {
      position: "absolute",
      left: "-120px",
      top: "2px",
      [theme.breakpoints.only("xs")]: {
        left: "-14px",
        top: "6px",
      },
    },
    arrowBack: {
      color: "rgba(0, 0, 0, 0.87)",
    },
    container: {
      display: "flex",
      alignItems: "center",
      paddingTop: "20px",
    },
    arrowButton: {
      backgroundColor: "#232323",
      color: "white",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
      borderRadius: 20,
      width: "24px",
      height: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    boldText: {
      fontWeight: 800,
      fontSize: "13px",
      marginRight: theme.spacing(1),
    },
    smallText: {
      fontWeight: 300,
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      fontSize: "8px",
    },
  })
);

interface BackButtonCustomSegmentProps {
  handleBack: () => void;
}

export default function BackButtonCustomSegment({
  handleBack,
}: BackButtonCustomSegmentProps) {
  const classes = useStyles();

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <OverlayComponentBaseContainerWidth widthMdUp="300px" widthSmDown="300px">
      <OverlayComponentBaseContainerHorizontal alignItems="flex-start">
        <Box
          style={{
            width: '100%',
            marginLeft: isMdOrUp ? '12.5px' : undefined,
            marginBottom: isMdOrUp ? '-10px' : undefined
          }}
        >
          <Box className={classes.container} onClick={handleBack}>
            <Box className={classes.arrowButton}>
              <ArrowBack
                style={{
                  fontSize: "18px",
                }}
              />
            </Box>
            <Typography component="span">
              <span className={classes.boldText}>Mau Dibuatin Aja?</span>
            </Typography>
            <Typography component="span" className={classes.smallText}>
              Pakai Instant Size Team
            </Typography>
          </Box>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#eeeeee",
              marginTop: "15px",
              marginBottom: "-5px",
            }}
          />
        </Box>
      </OverlayComponentBaseContainerHorizontal>
    </OverlayComponentBaseContainerWidth>
  );
}

import { Box, Dialog, DialogTitle, Divider, Fade, IconButton, Link, Slide, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Bantuan, BantuanSpacer } from '../App';
import { Mixpanel } from '../mixpanel';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputBodyMeasurementsForm, inputMeasurementsForm, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset, updateCurrentScreen, updateCurrentTab } from '../redux/UIOverlayMeasurementMethodSelectionViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import HorizontalBar from './OverlayViewGenerateMeasure0HorizontalProgressBar';
import VerticalBar from './OverlayViewGenerateMeasure0VerticalProgressBar';
import { FlowComponentMaster } from './OverlayViewMeasurementMethodSelectionBlocks';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 2) + " !important") as any,
      '& .MuiDialog-paper': {
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
          margin: 0,
          width: '100%',
          maxWidth: '100%',
          height: 'calc(100% - 32px)',
          maxHeight: 'none',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          position: 'fixed',
          bottom: 0,
          overflow: 'hidden',
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: '380px',
          borderRadius: '30px',
          margin: '0px 16px',
          maxHeight: 'calc(100% - 16px)',
        },
      },
    },
    dialogContentContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start', 
      overflow: 'scroll',
      "&::-webkit-scrollbar": {
          display: "none",
        },
    },
    dialogContentLeftContainer: {
      overflowX: 'hidden',
      "&::-webkit-scrollbar": {
          display: "none",
        },
      width: '100%',
      // paddingLeft: '29%',
      [theme.breakpoints.only('xs')]: {
        // paddingLeft: '10%',
        paddingTop: '20px',
        width: '100%',
        justifyContent: 'center',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleLogoContainer: {
      width: "100%",
      position: "relative",
      paddingTop: '2.5vw',
      paddingBottom: '6vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '5vw',
        paddingBottom: '7vw',
        height: '10vw',
      },
      cursor: 'pointer',
      backgroundColor: 'yellow'
    },
    titleLogoDesktop: {
      paddingTop: '1.5vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '3vw',
        height: '10vw',
      }
    },
    titleLogoClose: {
      position: "absolute",
      top: "3vw",
      right: "3vw",
      color: "#000000",
    },
    signInsignUpToggleText: {
      fontSize: '15px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px',
      }

    },
    horizontalBarDivider: {
      width: '100%',
      margin: '10px 0px 6px 0px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      }
    },
    horizontalContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    popupTitle: {
      padding: '0.5em 0.5em 0 0.5em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    popupTitlePilihUkuranText: {
      fontSize: '12px',
      color: '#D1D1D1',
      fontWeight: 300,
      padding: '16px 0px 0px 18px'
    },
    optionText: {
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.5px',
      padding: '3px 0px 1px 0px'
    },
    popupCloseButton: {
      marginRight: '3px',
    },
    sliderContainer: {
      display: 'flex',
      background: '#f0f0f0',
      borderRadius: 25,
      width: '80%',
      position: 'relative',
      cursor: 'pointer',
      marginTop: '-10px',
      maxWidth: '650px',
      [theme.breakpoints.up('sm')]: {
        marginTop: '20px',
      },
    },
    sliderOption: {
      flex: 1,
      textAlign: 'center',
      padding: '8px 16px',
      borderRadius: 20,
      transition: 'all 0.5s ease',
      zIndex: 1,
    },
    sliderBackground: {
      position: 'absolute',
      top: 4,
      width: 'calc(50% - 8px)',
      height: 'calc(100% - 8px)',
      backgroundColor: '#232323',
      borderRadius: 20,
      transition: 'all 0.5s ease',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

// I don't really know how this work
const variants = {
  hidden: { opacity: 0, x: 0 }, 
  visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }, 
  center: { x: 0, opacity: 1, transition: { duration: 0.8 } }, 
  slideLeft: { x: '130%', opacity: 0, transition: { duration: 0.8 } },
  slideRight: { x: '130%', opacity: 0, transition: { duration: 0.8 } },
};

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMeasurementMethodSelectionView);
  const overlayViewCreate = useAppSelector(state => state.overlayMeasurementCreateView);
  const overlayViewIST = useAppSelector(state => state.overlayInstantSizeTeam);
  const chatUserId = useAppSelector(state => state.account.chatUserId);
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const currentTab = overlayView.currentTab
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const currentScreen = overlayView.currentScreen

  const [childrenVisible, setChildrenVisible] = React.useState<boolean>(true);
  const [titleNumber, setTitleNumber] = React.useState<number>(-1);
  const [slideDirection, setSlideDirection] = React.useState<'slideLeft' | 'slideRight' | 'visible'>('visible');


  const currentUserMeasurement = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementDraft);
  const currentUserBodyMeasurement = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementDraft);

  React.useEffect(() => {
    // console.log("BLOCKING CALLED, STATUS: " + (overlayView.status !== UIOverlayViewStatus.On ? "NOT BLOCKING" : "BLOCKING"));
    // console.log("BLOCKING CALLED, HISTORY LENGTH: " + history.length);
    const unblockFunction = history.block((() => {
      if (overlayView.status === UIOverlayViewStatus.On) {
        return false;
      }
      return true;
    }) as any);
  
    return function cleanup() {
      unblockFunction();
    };
  }, [overlayView.status]);

  function delayedDispatch(targetScreen: 'regularSize' | 'dibuatinAtauBuatSendiri' | 'favoriteOrAlgorithmSize') {
    if ((targetScreen === 'regularSize') || (targetScreen === 'favoriteOrAlgorithmSize' && currentScreen === 'regularSize')) {
      setSlideDirection('visible')
    } else if (targetScreen === 'favoriteOrAlgorithmSize' && currentScreen === 'dibuatinAtauBuatSendiri') {
      setSlideDirection('slideLeft')
    } else if (targetScreen === 'dibuatinAtauBuatSendiri' && currentScreen === 'favoriteOrAlgorithmSize') {
      setSlideDirection('slideRight')
    } 

    setChildrenVisible(false);
    
    setTimeout(() => {
      dispatch(updateCurrentScreen(targetScreen));
      setChildrenVisible(true);
    }, 500)
  }

  const handleTabChange = (tab: 'regular' | 'custom') => {
      dispatch(updateCurrentTab(tab));
      delayedDispatch(tab === 'regular' ? 'regularSize' : 'dibuatinAtauBuatSendiri');
  };

  function closeOverlayAndResetUserMeasurementDraft() {
    handleTabChange('regular')
    dispatch(closeOverlayAndReset());
    dispatch(resetUserMeasurementDraft());
    Mixpanel.track('sizeCreateExit', {
      'signedIn': isSignedIn ? 'true' : 'false',
    });
  }

  if (currentUserMeasurement.clothingType === undefined 
    || currentUserMeasurement.fitType === undefined) {
    return null;
  }

  if (currentUserMeasurement.creationDateTime === undefined) {
    const creationDateTime = new Date().toISOString();
    
    var objToday = new Date(),
      dayOfMonth = objToday.getDate(),
      months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      curMonth = months[objToday.getMonth()],
      curYear = objToday.getFullYear(),
      curHour = objToday.getHours(),
      curMinute = objToday.getMinutes(),
      curSeconds = objToday.getSeconds() < 10 ? "0" + objToday.getSeconds() : objToday.getSeconds(),
      curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

    var measurementName = curMonth + " " + dayOfMonth + " " 
      + curYear + " " + curHour + ":" + curMinute + " - Measurement";
    
    const payload: UserClothTypeSpecificBodyMeasurements = {
      creationDateTime: creationDateTime,
      updateDateTime: creationDateTime,
      measurementName: measurementName,
    }
    dispatch(inputMeasurementsForm(payload));
    
    const payloadBody: UserBodyMeasurements = {
      creationDateTime: creationDateTime,
      updateDateTime: creationDateTime,
      measurementName: measurementName,
    }
    dispatch(inputBodyMeasurementsForm(payloadBody));

    return null;
  }

  let content: JSX.Element = 
  FlowComponentMaster({
    clothingType: currentUserMeasurement.clothingType,
    fitType: currentUserMeasurement.fitType,
    isSignedIn: isSignedIn,
    addToCartAfterCreation: overlayView.addToCartAfterCreation,
    productDetail: overlayView.productDetail,
    chatUserId: chatUserId,
  }, {
    currentScreen: overlayView.currentScreen,
    titleNumber: titleNumber,
    currentUserMeasurement: currentUserMeasurement,
    currentUserBodyMeasurement: currentUserBodyMeasurement,
    isDesktop: isDesktop
  }, {
    delayedDispatch: delayedDispatch,
    setTitleNumber: (titleNumber) => { setTitleNumber(titleNumber); },
  });

  return (
    <Dialog
    fullScreen={!isDesktop}
    maxWidth="lg"
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    onClose={closeOverlayAndResetUserMeasurementDraft}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}
    style={{display: overlayViewCreate.status === UIOverlayViewStatus.On || overlayViewIST.status === UIOverlayViewStatus.On ? 'none' : 'block'}}
    >
      <DialogTitle style={{ padding: '0px 0px' }}>
        <Box className={classes.popupTitle}>
          <Typography className={classes.popupTitlePilihUkuranText}>
            PILIH UKURAN
          </Typography>
          <IconButton
            className={classes.popupCloseButton}
            edge="end"
            color="inherit"
            onClick={closeOverlayAndResetUserMeasurementDraft}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <div className={classes.dialogContentContainer}>
        <div className={classes.dialogContentLeftContainer}>
          <Box className={classes.horizontalContent}>
            {
              titleNumber !== -1 ?
              <HorizontalBar start={0} end={4} current={titleNumber}/> :
              null
            }
            {
              titleNumber !== -1 ?
              <Divider variant="middle" className={classes.horizontalBarDivider} /> :
              null
            }
           <Box className={classes.sliderContainer}>
              <Box 
                className={classes.sliderBackground} 
                style={{ 
                  left: currentTab === 'regular' ? 4 : 'calc(50% + 4px)',
                  transition: 'all 0.5s ease'
                }}
              />
              <Box 
                className={classes.sliderOption}
                
                onClick={() => currentTab !== 'regular' && handleTabChange('regular')}
              >
                <Typography className={classes.optionText} style={{ color: currentTab === 'regular' ? 'white' : 'black', transition: 'all 0.5s ease' }}>
                  UKURAN REGULER
                </Typography>
              </Box>
              <Box 
                className={classes.sliderOption}
                
                onClick={() => currentTab !== 'custom' && handleTabChange('custom')}
              >
                <Typography className={classes.optionText} style={{ color: currentTab === 'custom' ? 'white' : 'black', transition: 'all 0.5s ease' }}> 
                  UKURAN CUSTOM
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: '100%'
              }}
            >
              {content}
            </Box>
          </Box>
          {
            titleNumber !== -1 ?
            <VerticalBar start={0} end={4} current={titleNumber}/> :
            null
          }
        </div>
      </div>
    </Dialog>
  );
}
import { BodyMeasurementDetail, BodyMeasurementDetailList, BodyMeasurements, BodyMeasurementsForSize, ClothingType, FitType } from 'common-ts/dist/models/UserMeasurements';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import {
  Box, CircularProgress, createStyles, Icon, IconButton, makeStyles, TextField, Typography
} from '@material-ui/core';
import {
  AddCircleOutline, ErrorOutlineOutlined, InfoOutlined, RemoveCircleOutline, ArrowBack
} from '@material-ui/icons';
import { Theme } from '@mui/material';

import {
  UniversalSizeActiveScreen, updateCurrentScreen
} from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentForm from './OverlayComponentForm';
import BackButtonSegment from './OverlayViewGenerateMeasure0BackSegment';
import React from 'react';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import { getBodyMeasurementsSubtext } from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';
import { Order } from 'common-ts/dist/models/Orders';
import ProductOnUser from 'common-ts/dist/models/Product';
import { shuffle } from './PageHome9RandomItems';
import { getHash } from './PageOrder';

enum InputValueStatus {
  Empty,
  Loading,
  Valid,
  Invalid
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitle: {
      fontSize: '0.65em',
      fontWeight: 600,
      textAlign: 'center',
    },
    tabs: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.5em 0 0.3em 0',
      margin: '0.2em 0em',
      position: 'relative',

      backgroundColor: '#EDEDED',
      borderRadius: '99px',
    },
    tabInput: {
      display: 'none',
      '&:checked': {
        '& + label': {
          '& > *': {
            color: 'black',
            fontWeight: 'bold',
            lineHeight: '2em'
          },
        },
      },
    },
    tab: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      borderRadius: '99px',
      cursor: 'pointer',
      '& > *': {
        zIndex: 2,
        fontSize: '0.6em',
        letterSpacing: '0.05em',
        color: '#585858',
        lineHeight: '2em',
        fontWeight: 400,
        transition: 'all 0.1s ease-in-out',
      },
    },
    glider: {
      position: 'absolute',
      display: 'flex',
      height: '80%',
      width: '48%',
      backgroundColor: 'white',
      zIndex: 1,
      borderRadius: '99px',
      left: '1%',
      top: '10%',
      transition: 'all 0.1s ease-in-out',
    },
    instructionVideo: {
      width: '100%',
      paddingBottom: '1em',
    },
    tabsSubtitle: {
      fontSize: '0.5rem',
      lineHeight: '1rem',
      color: '#ADADAD',
      fontWeight: 400,
      fontFamily: 'OakesGrotesk, sans-serif',
      textAlign: 'center',
      paddingBottom: '2em',
    },
    instructionTitle: {
      fontWeight: 'bold',
      fontSize: '0.65rem',
    },
    instructionText: {
      fontSize: '0.63rem',
    },
    infoBox: {
      display: 'flex',
      alignItems: 'center',
      margin: '1.5em 0em',

      backgroundColor: '#EDEDED',
      borderRadius: '99px',
      justifyContent: 'space-between',
    },
    infoIcon: {
      padding: '1em 1em 1em 1.5em',
      fontSize: '0.9em',
    },
    infoText: {
      fontSize: '0.5rem',
      color: '#606060',
      fontFamily: 'OakesGrotesk, sans-serif',
      width: '50%',
    },
    infoMeasurements: {
      fontSize: '0.7em',
      borderBottom: '0.1em solid black',
      marginRight: '1.4em',
      textWrap: 'nowrap',
    },
    inputBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    inputTitle: {
      fontSize: '4.3vw',
      fontWeight: 500,
      paddingRight: '0.5em',
    },
    lingkarPerutInput: {
      position: 'relative',
      display: 'flex',
      width: '45%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '14px',
    },
    minusIcon: {
      color: '#7E7E7E',
      fontSize: '0.8em',
      zIndex: 2,
    },
    plusIcon: {
      marginLeft: 'auto',
      color: '#7E7E7E',
      fontSize: '0.8em',
      zIndex: 2,
    },
    loadingIcon: {
      fontSize: '0.8em',
      zIndex: 2,
      position: 'absolute',
      left: '50%',
      transform: 'translate(1rem, -0.15rem)'
    },
    errorIcon: {
      color: 'red',
      fontSize: '1em',
      zIndex: 2,
      position: 'absolute',
      left: '50%',
      transform: 'translate(1rem, -0.15rem)'
    },
    lingkarPerutTextField: {
      position: 'absolute',
      width: '80%',
      top: '-0.8em',
      left: '50%',
      transform: 'translateX(-50%)',
      '& input::placeholder': {
        fontSize: '0.5em',
        opacity: 0.4,
      },
    },
    inputUnit: {
      fontSize: '0.9em',
    },
    errorTextBox: {
      marginTop: '0.5rem',
    },
    errorText: {
      fontSize: '0.6rem',
      fontWeight: 300,
      color: 'red',
    },
    resultInfoBox: {
      marginTop: '1em',
      display: 'flex',
      flexDirection: 'column',

      backgroundColor: '#EDEDED',
      borderRadius: '5px',
      padding: '0.5em 1em',
    },
    resultTitleBox: {
      marginLeft: '-0.5em',
      display: 'flex',
      alignItems: 'center',
    },
    resultTitle: {
      paddingLeft: '1em',
      fontWeight: 'bold',
      fontSize: '0.7rem',
    },
    resultSubtitle: {
      fontSize: '0.65rem',
      padding: '1em 0em',
    },
    resultSizes: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0.2em',
    },
    resultItemTitle: {
      fontSize: '0.7rem',
      fontWeight: 500,
    },
    resultItemMeasurements: {
      fontSize: '0.7rem',
    },
    submitButton: {
      // position: 'absolute',
      marginTop: '3rem',
    },
    videoContainer: {
      width: '100%',
      position: 'relative'
    },
    circularProgressContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }),
);

interface VideoProps {
  url: string,
  canPlayThroughCallback: () => void,
}

function VideoElement({url, canPlayThroughCallback}: VideoProps) {
  const classes = useStyles();

  return (
  <video key={'vid-' + url} style={{ 
      width: '100%', 
      height: '140%', 
      borderRadius: '1.6vw',
      backgroundColor: '#efefef',
      aspectRatio: '4 / 3',
    }} loop autoPlay muted
    onCanPlayThrough={canPlayThroughCallback}>
    <source src={url} type="video/mp4" />
    Your browser does not support the video tag.
  </video>);
}

interface Props {
  handleBack: () => void
  handleEachBack: (issues: string[] | undefined, newIssueIndex: number) => void
  handleEachSubmit: (issues: string[] | undefined, newIssueIndex: number, currentValue: number) => void
  handleFinish: () => void
  issues: string[] | undefined
  userSelfInputMeasurements: BodyMeasurements | undefined
  generatedMeasurements: BodyMeasurements | undefined
  generatedMeasurementsRange: {[key: string]: number} | undefined
  generatedMeasurementsSafe: BodyMeasurements | undefined
  marginTop?: string
  backButtonPosition?: any
}

const validationSchema = yup.object({
  measurement: yup.number()
    .positive('Ukuran anda pasti positif!')
    .required('Ukuran diperlukan'),
})

interface ClothBodyMeasSuggestion {
  productName: string
  measurementName: string
  productDisplayImage: string
  purchaseDateString: string
  clothMeasValue: BodyMeasurements
}

export function getClothBodyMeasSuggestions(ordersList: Order[]) {

  const paidClothBodyMeasSuggestion: ClothBodyMeasSuggestion[] = [];

  for (var i = 0; i < ordersList.length; i += 1) {
    const currentOrder = ordersList[i];
    if (currentOrder.paymentStatus === 'capture' || 
    currentOrder.paymentStatus === 'capture_stockonly' || 
    currentOrder.paymentStatus === 'settlement') {
      for (var j = 0; j < currentOrder.cartProductList.length; j += 1) {
        const currentPaidItem = currentOrder.cartProductList[j];
        paidClothBodyMeasSuggestion.push({
          productName: currentPaidItem.productDetail.name,
          productDisplayImage: currentPaidItem.productDetail.displayImage,
          measurementName: (currentPaidItem.userMeasurementsForItem as any)?.measurementName,
          purchaseDateString: currentOrder.creationDateTime,
          clothMeasValue: (currentPaidItem.userMeasurementsForItem as any)?.adjustedMeasurements,
        })
      }
    }
  }

  return paidClothBodyMeasSuggestion;
}

function getProductImagesWithClothFitType(clothingType: ClothingType, fitType: FitType, productsDictionary: {[key: string]: ProductOnUser}) {
  const productsList: string[] = Object.keys(productsDictionary);
  let productsListRandomOrder: string[] = shuffle(productsList, getHash('trr' + (new Date().toISOString()).substring(0, 15)));

  const productImageList: string[] = [];
  let imgCount = 0;

  for (let i = 0; i < productsListRandomOrder.length; i++) {
    const productObject = productsDictionary[productsListRandomOrder[i]];

    if (productObject.clothingType === clothingType && productObject.defaultFitType === fitType) {
      productImageList.push(productObject.displayImage);
      imgCount++;
    }

    if (imgCount >= 4) {
      break;
    }
  }

  return productImageList;
}

export default function PersonalSizeUkurTiapBagian({ issues, handleBack, handleEachBack, handleEachSubmit, handleFinish, 
  userSelfInputMeasurements, generatedMeasurements, generatedMeasurementsRange, generatedMeasurementsSafe, marginTop, backButtonPosition }: Props) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const clothMeasSuggestions = useAppSelector(state => getClothBodyMeasSuggestions(state.ordersList.ordersList));
  const [measTouched, setMeasTouched] = React.useState<boolean>(false);
  const [issueIndex, setIssueIndex] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const formik = useFormik({
    initialValues: {
      measurement: 
        issues && userSelfInputMeasurements && 
        userSelfInputMeasurements?.[issues?.[issueIndex] as keyof BodyMeasurements] ? 
        userSelfInputMeasurements[issues[issueIndex] as keyof BodyMeasurements] as number : 
        issues && generatedMeasurements && 
        generatedMeasurements?.[issues?.[issueIndex] as keyof BodyMeasurements] ? 
        generatedMeasurements[issues[issueIndex] as keyof BodyMeasurements] as number : 
        50,
    },
    validationSchema: validationSchema,
    onSubmit: (values, {resetForm} ) => {
      if (issues === undefined) {
        return;
      }
      handleEachSubmit(issues, issueIndex + 1, values.measurement);
      if (issueIndex + 1 === issues?.length) {
        handleFinish();
      } else {
        setIssueIndex(issueIndex + 1);
        setMeasTouched(false);
        setIsLoading(true);
        resetForm({
          values: {
            measurement: 
              issues && userSelfInputMeasurements && 
              userSelfInputMeasurements?.[issues?.[issueIndex + 1] as keyof BodyMeasurements] ? 
              userSelfInputMeasurements[issues[issueIndex + 1] as keyof BodyMeasurements] as number : 
              issues && generatedMeasurements && 
              generatedMeasurements?.[issues?.[issueIndex + 1] as keyof BodyMeasurements] ? 
              generatedMeasurements[issues[issueIndex + 1] as keyof BodyMeasurements] as number : 
              50,
          }
        });
      }
    },
  });

  let detailsOfMeasurement: BodyMeasurementDetail | undefined;
  let detailsCurrentValue = "";
  if (issues !== undefined && issues.length > 0) {
    detailsOfMeasurement = BodyMeasurementDetailList[issues[issueIndex]];
  }
  if (issues !== undefined && issues.length === 0) {
    handleFinish();
  }
  if (issues === undefined) {
    handleFinish();
  }

  const [activeTab, setActiveTab] = useState('ukuranBadan');
  const [inputValueStatus, setInputValueStatus] = useState<InputValueStatus>(InputValueStatus.Empty);

  const handleTabChange = (activeTab: string) => {
    setActiveTab(activeTab);
    let glider = document.querySelector(`.${classes.glider}`) as HTMLElement;
    if (activeTab === 'ukuranBadan') {
      glider.style.transform = 'translateX(0%)';
    } else {
      glider.style.transform = 'translateX(104%)';
    }
  }

  const handleIncrementDecrement = (operation: 'increment' | 'decrement') => {
    setMeasTouched(true);
    if (operation === 'increment') {
      formik.setFieldValue('measurement', formik.values.measurement + 1);
    } else {
      formik.setFieldValue('measurement', formik.values.measurement - 1);
    }
  }

  useEffect(() => {
    const minValidValue = generatedMeasurementsRange?.[issues?.[issueIndex] + 'Min'] ?
      generatedMeasurementsRange[issues?.[issueIndex] + 'Min'] :
      10;
    const maxValidValue = generatedMeasurementsRange?.[issues?.[issueIndex] + 'Max'] ?
      generatedMeasurementsRange[issues?.[issueIndex] + 'Max'] :
      200;
    if (formik.values.measurement === undefined || typeof formik.values.measurement === 'string') {
      setInputValueStatus(InputValueStatus.Empty);
    } else if (minValidValue <= formik.values.measurement && formik.values.measurement <= maxValidValue) {
      setInputValueStatus(InputValueStatus.Valid);
    } else {
      setInputValueStatus(InputValueStatus.Invalid);
    }
  }, [formik.values.measurement])

  let children: JSX.Element[] = [];

  children.push(
    <Box>
     <Typography className={classes.subTitle} style={{
        paddingBottom: '1.2em',
      }}>UKUR { detailsOfMeasurement ? detailsOfMeasurement.bodyMeasurementName.toUpperCase() : "" } </Typography>
    </Box>
  )

  // children.push(
  //   <Box>
  //     <Typography className={classes.subTitle}>PILIH CARA UKUR</Typography>
  //     <Box className={classes.tabs}>
  //       <input
  //         className={classes.tabInput}
  //         type="radio"
  //         id="ukuranBadan"
  //         name="caraUkur"
  //         onChange={() => handleTabChange("ukuranBadan")}
  //         checked={activeTab === "ukuranBadan"} />
  //       <label className={classes.tab} htmlFor="ukuranBadan">
  //         <Typography>UKURAN DARI BADAN</Typography>
  //       </label>
  //       <input
  //         className={classes.tabInput}
  //         type="radio"
  //         id="ukuranBaju"
  //         name="caraUkur"
  //         onChange={() => handleTabChange("ukuranBaju")}
  //         checked={activeTab === "ukuranBaju"} />
  //       <label className={classes.tab} htmlFor="ukuranBaju">
  //         <Typography>UKURAN DARI BAJU</Typography>
  //       </label>
  //       <span className={classes.glider}></span>
  //     </Box>
  //     <Typography className={classes.tabsSubtitle}>
  //       {/* "Bila" sebenarnya tidak baku. Kalau mau, ubah jadi "apabila" atau "jika" */}
  //       Gunakan ukuran dari baju bila kesulitan melakukan pengukuran dari badan
  //     </Typography>
  //   </Box>
  // );

  children.push(
    <Box className={classes.videoContainer}>
      { 
        isLoading && detailsOfMeasurement?.measurementInstructionVideoSrc  ?
        <div className={classes.circularProgressContainer}>
          <CircularProgress/> 
        </div>: 
        null
      }
      <VideoElement 
        url={issues && detailsOfMeasurement ? detailsOfMeasurement.measurementInstructionVideoSrc : ''}
        canPlayThroughCallback={() => { 
          if (detailsOfMeasurement?.measurementInstructionVideoSrc) {
            setIsLoading(false);
          }
        }}
      />
      {
        detailsOfMeasurement?.measurementInstructionVideoSrc ?
        null :
        <Typography style={{
          fontSize: '2.5vw',
          position: 'absolute',
          fontWeight: 600,
          letterSpacing: '0.1vw',
          top: '32vw',
          left: '24vw',
        }}>
          INSTRUCTION VIDEO COMING!
        </Typography>
      }
    </Box>
  );

  children.push(
    <Box style={{marginTop: '16px'}}>
      <Typography className={classes.instructionTitle}>
        Instruksi
      </Typography>
      <Typography className={classes.instructionText}>
        { detailsOfMeasurement ? detailsOfMeasurement.measurementInstructionDescription : "Instruksi pengukuran" }
      </Typography>
    </Box>
  );

  children.push(
    <Box className={classes.infoBox}>
      <InfoOutlined className={classes.infoIcon} />
      <Typography className={classes.infoText} >
        Ukuran { detailsOfMeasurement ? detailsOfMeasurement.bodyMeasurementName + ' ' : "ini " } 
        untuk tinggi dan berat badan kamu biasanya sekitar
      </Typography>
      <Typography className={classes.infoMeasurements}>
        {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Min'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Min'] :
          '-'
        } cm - {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Max'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Max'] :
          '-'
        } cm
      </Typography>
    </Box>
  );

  children.push(
    <Box className={classes.inputBox}>
      <Typography className={classes.inputTitle}>
        { detailsOfMeasurement ? detailsOfMeasurement.bodyMeasurementName : "Ukuran" } Kamu
      </Typography>
      <Box className={classes.lingkarPerutInput}>
        <IconButton
          onClick={() => handleIncrementDecrement('decrement')}
          style={{ visibility: inputValueStatus === InputValueStatus.Empty ? 'hidden' : 'visible' }}
        >
          <RemoveCircleOutline className={classes.minusIcon} />
        </IconButton>
          <TextField
            className={classes.lingkarPerutTextField}
            margin="normal"
            id="measurement"
            name="measurement"
            type="number"
            value={formik.values.measurement}
            onChange={(e: React.ChangeEvent<any>) => {
              setMeasTouched(true);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            placeholder={detailsOfMeasurement?.bodyMeasurementName}
            inputProps={{
              style: {
                MozAppearance: 'textfield', // Hide arrow on number input in Firefox.
                WebkitAppearance: 'textfield', // Hide arrow on number input in Chrome, Safari, Edge, Opera
                textAlign: 'center',
                marginTop: '0.2em',
                padding: '0.3em',
              },
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
          {
            (() => {
              switch (inputValueStatus) {
                case InputValueStatus.Loading:
                  return (
                    <CircularProgress
                      size={15}
                      className={classes.loadingIcon}
                    />
                  )
                case InputValueStatus.Invalid:
                  return (
                    <ErrorOutlineOutlined
                      className={classes.errorIcon}
                    />
                  )
              }
            })()
          }
        {
          measTouched && formik.values.measurement ?
          <Typography style={{
            position: 'absolute',
            bottom : '-13px',
            fontSize: '7px',
            color: '#787878',
            left: '0',
            right: '0',
            margin: '0 auto',
            textAlign: 'center',
          }}>
            {getBodyMeasurementsSubtext({
              ...BodyMeasurementsForSize[RegularSizeValue.L],
              [issues?.[issueIndex] as keyof BodyMeasurements]: formik.values.measurement,
            })?.[issues?.[issueIndex] as keyof BodyMeasurements]}
          </Typography>:
          null
        }
        <IconButton
          onClick={() => handleIncrementDecrement('increment')}
          style={{ visibility: inputValueStatus === InputValueStatus.Empty ? 'hidden' : 'visible' }}
        >
          <AddCircleOutline className={classes.minusIcon} />
        </IconButton>
      </Box>
      <Typography className={classes.inputUnit}>
        cm
      </Typography>
    </Box>
  );

  children.push(
    <Box
      className={classes.errorTextBox}
      style={{ display: inputValueStatus === InputValueStatus.Invalid ? 'block' : 'none' }}
    >
      <Typography className={classes.errorText}>
        Ukuran {detailsOfMeasurement?.bodyMeasurementName ? detailsOfMeasurement.bodyMeasurementName + ' ' : ''} 
        yang wajar untuk tinggi dan berat badan kamu
        berkisar dari {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Min'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Min'] :
          ''
        } cm hingga {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Max'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Max'] :
          ''
        } cm.
      </Typography>
    </Box>
  )

  // Dummy data
  const itemResult = [
    {
      category: 'Tees',
      measurementResult: '50cm',
    },
    {
      category: 'Shirt',
      measurementResult: '50cm',
    },
  ]

  // children.push(
  //   <Box className={classes.resultInfoBox} style={{ display: inputValueStatus === InputValueStatus.Valid ? 'flex' : 'none' }}>
  //     <Box className={classes.resultTitleBox}>
  //       <Icon>
  //         <img
  //           width={'24px'}
  //           src={process.env.PUBLIC_URL + '/assets/apparel.svg'}
  //           alt='universal size' />
  //       </Icon>
  //       <Typography className={classes.resultTitle}>
  //         Lihat Ukuran Baju Jadi
  //       </Typography>
  //     </Box>
  //     <Typography className={classes.resultSubtitle}>
  //       Ukuran ini akan disesuaikan dengan kategori baju
  //     </Typography>
  //     {
  //       itemResult.map((item) => {
  //         return (
  //           <Box className={classes.resultSizes}>
  //             <Typography className={classes.resultItemTitle}>
  //               {item.category}
  //             </Typography>
  //             <Typography className={classes.resultItemMeasurements}>
  //               {item.measurementResult}
  //             </Typography>
  //           </Box>
  //         )
  //       })
  //     }
  //   </Box>
  // )

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <Box className={classes.submitButton}>
      <OverlayComponentButton
        isTypeSubmit={true}
        formButtonText="SELANJUTNYA"
        buttonMarginTopBottom="0"
        buttonMarginLeftRight="0"
      />
    </Box>
  );

  return (
    <>
      <Box
      style={{
        top: '24px',
        left: '16px',
        padding: '8px',
        position: backButtonPosition ? backButtonPosition : 'absolute',
        borderRadius: '50%',
      }}
        onClick={() => {
            if (issueIndex === 0) {
              handleBack();
            } else {
              handleEachBack(issues, issueIndex - 1);
              formik.resetForm({
                values: {
                  measurement: 
                    issues && userSelfInputMeasurements && 
                    userSelfInputMeasurements?.[issues?.[issueIndex - 1] as keyof BodyMeasurements] ? 
                    userSelfInputMeasurements[issues[issueIndex - 1] as keyof BodyMeasurements] as number : 
                    issues && generatedMeasurements && 
                    generatedMeasurements?.[issues?.[issueIndex - 1] as keyof BodyMeasurements] ? 
                    generatedMeasurements[issues[issueIndex - 1] as keyof BodyMeasurements] as number : 
                    50,
                }
              });
              setMeasTouched(true); // We do not reset / remove measTouched when going backwards, only forwards.
              setIssueIndex(issueIndex - 1);
            }
          }}
      >
        <IconButton style={{
          padding: '0',
          color: 'black',
        }}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Box style={{
        padding: "0px 20px",
        marginTop: marginTop ? marginTop : '5em'
      }}>
        <OverlayComponentForm
          formTitle=""
          formSubmitBody={submitChildren}
          formFormBody={children}
          formHandleSubmit={formik.handleSubmit}
          titleWidthMdUp='0'
          titleWidthSmDown='0'
          formWidthMdUp='0'
          formWidthSmDown='100%'
          formJustifyContent='space-evenly'
          formAlignItems='flex-start'
        />
      </Box>
    </>
  )
}

import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClothingType, FitType } from 'common-ts/dist/models/UserMeasurements';

export interface OverlayOngkirState {
  fitType: FitType | undefined
  clothingType: ClothingType
  measurementName: string
  numberOfSimiliarProducts: number
  status: UIOverlayViewStatus,
}

// Define the initial state using that type
const initialState: OverlayOngkirState = {
  clothingType: ClothingType.Kaos,
  fitType: FitType.SlimFit,
  measurementName: 'Ukuran Custom',
  numberOfSimiliarProducts: 0,
  status: UIOverlayViewStatus.OffAndReady,
}

export const overlayQRResultSlice = createSlice({
  name: 'overlayQRResult',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.clothingType = ClothingType.Kaos;
      state.fitType = FitType.SlimFit;
      state.measurementName = 'Ukuran Custom';
      state.numberOfSimiliarProducts = 0;
      state.status = UIOverlayViewStatus.OffAndReady;
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;
    },

    setValues: (state, action: PayloadAction<{
      clothingType: ClothingType
      fitType: FitType
      measurementName: string
      numberOfSimiliarProducts: number
    }>) => {
      state.clothingType = action.payload.clothingType;
      state.fitType = action.payload.fitType;
      state.measurementName = action.payload.measurementName;
      state.numberOfSimiliarProducts = action.payload.numberOfSimiliarProducts;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, setValues } = overlayQRResultSlice.actions

export default overlayQRResultSlice.reducer
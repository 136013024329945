// LiveStream.tsx
import React, { useRef, useEffect } from "react";
import { SrsRtcWhepAsync } from "./SrsRtcWhepAsync";
import { videoMainStreamId } from "../App";

interface LiveStreamProps {
  url: string; // WHEP URL to liveserver.studios.id
}

const LiveStream: React.FC<LiveStreamProps> = ({ url }) => {
  const whepClientRef = useRef<SrsRtcWhepAsync | null>(null);

  useEffect(() => {
    const initializePlayer = async () => {
      try {
        whepClientRef.current = new SrsRtcWhepAsync();
        whepClientRef.current.ontrack = (event: RTCTrackEvent) => {
          console.log("EVENT: WHEP CLIENT REF ONTRACK CALLED " + event.track.kind);
          const mainEl: HTMLVideoElement = document.getElementById(
            videoMainStreamId
          ) as HTMLVideoElement;
          const fullEl: HTMLVideoElement = document.getElementById(
            videoMainStreamId + "-full"
          ) as HTMLVideoElement;
        
          if (!mainEl.srcObject) {
            mainEl.srcObject = new MediaStream();
          }
          if (!fullEl.srcObject) {
            fullEl.srcObject = new MediaStream();
          }
        
          (mainEl.srcObject as MediaStream).addTrack(event.track);
          (fullEl.srcObject as MediaStream).addTrack(event.track);
        
          if (event.track.kind === "audio") {
            console.log("Audio track added");
          } else if (event.track.kind === "video") {
            console.log("Video track added");
          }
        };

        await whepClientRef.current.play(url);
      } catch (error) {
        console.error("Failed to initialize player:", error);
      }
    };

    initializePlayer();

    return () => {
      if (whepClientRef.current) {
        whepClientRef.current.close();
      }
    };
  }, [url]);

  return <div />;
};

export default LiveStream;

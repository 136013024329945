import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { updateState } from "../redux/AccountRedux";
import { useAppDispatch, useAppSelector } from "../reduxhooks";
import { FareastButton } from "./OverlayComponentButton";
import {
  ClothingMeasurements,
  ClothingType,
  FitType,
} from "common-ts/dist/models/UserMeasurements";
import OverlayComponentCanvas from "./OverlayComponentCanvasFitAnimation";
import OverlayComponentBaseTemplate from "./OverlayComponentBaseTemplate";
import SizeOptions from "./PageProductDetailSizeOptions";
import {
  postClothingMeasurementPreference,
  saveLocallyClothingMeasurementPreference,
} from "../redux/MeasurementsRedux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popupContainer: {
      zIndex: (theme.zIndex.modal + 2 + " !important") as any,
      "& .MuiPaper-root": {
        width: "80vw",
        borderRadius: "40px",
      },
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
    },
    popupTitle: {
      padding: "0.5em 0.5em 0 0.5em",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    popupTitlePilihUkuranText: {
      fontSize: "12px",
      color: "#D1D1D1",
      fontWeight: 300,
      padding: "16px 0px 0px 18px",
    },
    popupCloseButton: {
      marginRight: "3px",
    },
    popupContent: {
      marginBottom: "1em",
      position: "relative",
      paddingBottom: "120px",
      boxSizing: 'border-box',
      width: '100%'
    },
    buttonsContainer: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "20px 10px",
      borderTop: `1px solid ${theme.palette.divider}`,
      boxShadow: "0px -4px 6px -1px rgba(0,0,0,0.1)",
      boxSizing: "border-box",
      backgroundColor: "#fff",
    },
    popupNewSizeButton: {
      marginBottom: theme.spacing(1),
      borderRadius: 20,
      border: "1px solid #000000",
    },
  })
);

interface Props {
  onClose: () => void;
  clothingType: ClothingType;
  fitType: FitType;
}

export default function SizeOptionsMenu({ clothingType, fitType }: Props) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  return (
    <OverlayComponentBaseTemplate alignItems="flex-start">
      <Box className={classes.popupContent}>
        <SizeOptions
          clothingType={clothingType}
          fitType={fitType}
        />
      </Box>
    </OverlayComponentBaseTemplate>
  );
}

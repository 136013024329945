import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MeasureSignUpFlow {
  status: UIOverlayViewStatus,
  signUpOrSignIn: 'signUp' | 'signIn',
}

// Define the initial state using that type
const initialState: MeasureSignUpFlow = {
  status: UIOverlayViewStatus.OffAndReady,
  signUpOrSignIn: 'signUp',
}

export const overlayAccountViewSlice = createSlice({
  name: 'overlayAccountView',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;
      state.signUpOrSignIn = 'signUp';
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;
      state.signUpOrSignIn = 'signUp';
    },

    updateSignUpOrSignIn: (state, action: PayloadAction<'signIn' | 'signUp'>) => {
      state.signUpOrSignIn = action.payload === 'signIn' ? 'signIn' : 'signUp';
    }
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay,
  updateSignUpOrSignIn } = overlayAccountViewSlice.actions

export default overlayAccountViewSlice.reducer
import { MenuItem, Typography, Box, Grid, useMediaQuery, IconButton, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Mixpanel } from '../mixpanel';
import { ClothingType, FitType } from 'common-ts/dist/models/UserMeasurements';
import { getCurrentSelectedMeasurement, getMeasurementObjectFromRegularSize, } from '../redux/MeasurementsRedux';
import { openOverlayCreateMeasWithInitialization } from '../redux/UIOverlayMeasurementCreateViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import ProductOnUser from 'common-ts/dist/models/Product';
import React, { useEffect } from 'react';
import { RegularSizeValue, SizeType } from 'common-ts/dist/models/Cart';
import { formatTimestampSize } from './PageProductDetailSizeSelection';
import EditIcon from '@material-ui/icons/Edit';
import { closeOverlayAndReset as closeOverlayAndResetEditView, openOverlay as openOverlayEditView, setValues as setValuesEditView } from '../redux/UIOverlayMeasurementEditViewRedux';
import { setPilihUkuranDataValues } from '../redux/UIOverlayMeasurementSelectionViewRedux';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  sizeOptionsBox: {
    // padding: "0px 10px 0px 10px",
  },
  sizeOptionsBoxBadan: {
    marginBottom: '30px'
  },
  sizeOptionsBoxBadanImage: {
    width: "100%",
    objectFit: "cover"
  },
  sizeOptionsBoxBadanCardBox: {
    position: 'absolute', 
    top: '3.2vw', 
    left: '1.8vw',
    [theme.breakpoints.up('sm')]: {
      top: '24px',
      left: '14px',
    }
  },
  sizeOptionsBoxBadanGantiBox: { 
    position: 'absolute', 
    top: '10.4vw', 
    right: '2vw',
    [theme.breakpoints.up('sm')]: {
      top: '75px',
      right: '15px',
    }
  },
  sizeOptionsBoxBadanGantiText: {
    fontSize: '2.2vw',
    fontWeight: 500,
    color: '#ffffff',
    padding: '1.7vw 2vw 1vw 2vw',
    borderRadius: '4vw',
    border: '0.7px solid #ffffff',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      padding: '13px 14px 9px 14px',
    }
  },
  sizeOptionsBoxBadanNameBox: {
    height: '16vw',
    [theme.breakpoints.up('sm')]: {
      height: '120px',
    }
  },
  sizeOptionsBoxBadanNameText: {
    fontSize: '3.8vw',
    letterSpacing: '0.2vw',
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: '0.2vw',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      fontSize: '21px',
    }
  },
  sizeOptionsBoxBadanTanggalText: {
    fontSize: '2vw',
    fontStyle: 'italic',
    fontWeight: 300,
    color: '#dfdfdf',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    }
  },
  sizeOptionsBoxBadanTinggiBeratText: {
    fontSize: '2vw',
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: '0.2vw',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    }
  },
  sizeOptionsBoxBadanTinggiBeratValueText: {
    fontSize: '3.6vw',
    fontWeight: 300,
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      fontSize: '26px',
    }
  },
  sizeOptionsBoxBaju: {
    marginBottom: '50px'
  },
  sizeOptionsBoxSegmentBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    padding: "0px 10px 0px 10px",
  },
  sizeOptionsBoxSegmentTitle: {
    fontSize: '3.2vw',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    }
  },
  sizeOptionsBoxSegmentSubtext: {
    fontSize: '2.7vw',
    letterSpacing: '0.6px',
    fontWeight: 300,
    color: "#a3a3a3",
    marginLeft: '10px',
    paddingBottom: '2px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '11px',
    }
  },
  productDetailRoot: {
  },
  productDetailNav: {
    paddingLeft: '20px',
    paddingRight: '30px',
    paddingTop: '0px',
    marginTop: '0px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '10px',
      marginTop: '0px',
    },
  },
  productDetailMain: { // ALLBIRDS is 18px left and right, 24px to leftmost image
    // paddingLeft: '24px',
    // paddingRight: '30px',
  },
  otherProductRecs: {
    display: 'block',
  },
  sectionDesktopAndMobile: {
    display: 'flex',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cartContentImageContainer: { // ALLBIRDS is 6px
    width: 'auto',
    padding: '0px 20px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 0px 0px',
    }
  },
  cartContentImage: {
    cursor: 'pointer',
  },
  stickyDetailContainer: {
    position: 'sticky',
    top: '40px',
  },
  productName: {
    marginBottom: '6px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '6px',
    },
  },
  productPrice: {
    marginBottom: '24px',
  },
  productLeftInStock: {
    marginBottom: '16px',
  },
  productDescription: {
    marginBottom: '28px',
    marginTop: "20px",
  },
  ukuranRegulerBox: {
    marginBottom: '28px',
    marginTop: "20px",
    position: "relative",
  },
  ukuranRegulerBoxSizeChartLink: {
    position: "absolute",
    right: "0",
    top: "2px",
    fontSize: "11px",
    color: "#5e5e5e",
    textDecoration: "underline #5e5e5e solid 1px",
    cursor: "pointer",
    '-webkit-tap-highlight-color': 'transparent',
  },
  ukuranRegulerSizeTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1vw",
    paddingLeft: "0.4vw",
    paddingRight: "0.4vw",
    [theme.breakpoints.down('xs')]: {
      marginTop: "4vw",
    }
  },
  ukuranRegulerSizeTextAda: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#232323",
    [theme.breakpoints.down('xs')]: {
      fontSize: "15px",
    }
  },
  ukuranRegulerSizeTextHabis: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#7e7e7e",
    textDecoration: "line-through #7e7e7e solid 1px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "15px",
    }
  },
  addToCartButton: {
    marginTop: '40px',
    marginBottom: '24px',
    justifyContent: 'center',
  },
  addToCartButtonText: {
    fontSize: '0.8rem', // 16px is 1 rem. 0.8 rem is 12.8px
    fontWeight: 600,
    letterSpacing: "0.03rem",
  },
  accordionStyle: {
    margin: '4px 0px !important',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  panelSummary: {
    "&.Mui-expanded": {
      minHeight: 0
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "auto"
    }
  },
  panelSummaryText: {
    "&:hover": {
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: '15px',
      textDecoration: 'underline',
    },
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '15px',
  },
  panelContentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '15px',
    paddingLeft: '10px',
  },
  productNameText: {
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
  },
  priceBox: {
    display: 'flex',
    alignItems: 'center',
  },
  productPriceText: {
    fontWeight: 'normal',
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  productPriceTextDiscount: {
    fontWeight: 'normal',
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  productPriceTextDiscountStrikeThrough: {
    fontWeight: 'normal',
    color: 'red',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '10px',
    marginTop: '2px',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    textDecoration: 'line-through',
  },
  productLeftInStockText: {
    fontWeight: 600,
    color: '#2a2a2a',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  productDescriptionText: {
    fontWeight: 'normal',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    marginBottom: '20px',
  },
  pilihUkuranSubtext: {
    fontWeight: 'normal',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    marginBottom: '8px',
  },
  productSubtitle: {
    fontWeight: 'bold',
    color: '#121212',
    fontSize: '14px',
    marginBottom: '6px',
  },
  talentInfoText: {
    color: '#343434',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Open sans, sans-serif',
    marginTop: "14px",
  },
  restockInfoText: {
    color: '#343434',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Open sans, sans-serif',
    marginTop: "10px",
  },
  restockInfoTextHighlight: {
    color: '#1f1f1f',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: 'Open sans, sans-serif',
    marginTop: "10px",
  },
  restockInfoTextIcon: {
    fontSize: '13px',
    marginRight: "9px",
  },
  chooseSizeButton: {
    width: "100%",
    paddingLeft: '20px',
    paddingRight: '24px',
    paddingTop: '9px',
    paddingBottom: '9px',
    backgroundColor: '#f1f1f1',
    marginTop: '6px',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#e9e9e9',
      borderColor: '#0062cc',
    },
    display: "flex",
    justifyContent: "space-between",
  },
  gantiUkuranText: {
    fontSize: "10px",
    textWrap: "nowrap",
    fontWeight: 500,
    textDecoration: "underline #121212 solid 1px",
    textUnderlineOffset: "3px",
    paddingLeft: "6px",
  },
  measurementMenuItemBody: {
    position: 'relative',
  },
  measurementMenuItemSelected: {
    padding: '14px 16px 14px 16px',
    marginBottom: '10px',
    borderRadius: '20px',
    position: 'relative',

    border: '1px solid #676767',

    [theme.breakpoints.only('xs')]: {
      padding: '3.2vw 3.4vw 3.2vw 3.4vw',
      borderRadius: '5vw',
    }
  },
  measurementMenuItem: {
    padding: '14px 16px 14px 16px',
    marginBottom: '10px',
    borderRadius: '20px',
    position: 'relative',

    border: '1px solid #ebebeb',

    [theme.breakpoints.only('xs')]: {
      padding: '3.2vw 3.4vw 3.2vw 3.4vw',
      borderRadius: '5vw',
    },
  },
  measurementMenuItemCheck: {
    width: '20px',
    position: 'absolute',
    top: '-5px',
    right: '-5px'
  },
  measurementMenuItemMainText: {
    fontSize: '12px',
    letterSpacing: '0.3px',
    fontWeight: 600,
    color: '#232323',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.1vw',
    }
  },
  measurementMenuItemDetailTitleText: {
    fontSize: '11px',
    fontWeight: 600,
    color: '#232323',
    marginBottom: '3px',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.3vw',
    }
  },
  measurementMenuItemDetailContentText: {
    fontSize: '10px',
    fontWeight: 300,
    color: '#232323',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.3vw',
    }
  },
  measurementMenuItemBuatUkuranText: {
    fontSize: '13px',
    letterSpacing: '0.5px',
    fontWeight: 600,
    paddingTop: '2px',
    paddingLeft: '6px',
    color: '#232323'
  },
  selectedMarkerBody: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: '0px',
    right: '0px',
    backgroundColor: 'green'
  },
  selectedMarker: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: '0px',
    right: '0px',
    backgroundColor: 'green'
  },
  sizeText: {
    color: '#3b3b3b',
    fontSize: '12px',
    textAlign: "left",
  },
  sizeTextNewSize: {
    color: '#3b3b3b',
    fontSize: '15px',
    marginLeft: '10px',
    marginTop: '1px',
  },
  mobileViewImageCarouselContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileViewImageCarousel: {
    // float: 'left',
  },
  cartCarouselImageContainer: {
    padding: '0px',
  },
  cartCarouselImage: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '2/3',
    marginBottom: '36px',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '2/3',
  },
  cartCarouselContainer: {
    width: '100%',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    marginBottom: '16px',
    position: "relative",
  },
  sisaBox: {
    backgroundColor: "#ff1e1e",
    textAlign: "center",
    position: "absolute",
    bottom: "1vw",
    left: "1vw",
    width: "6vw",
    borderRadius: "1.3vw",
    [theme.breakpoints.down('xs')]: {
      bottom: "15.4vw",
      left: "3.4vw",
      width: "20vw",
      borderRadius: "8vw",
    },
  },
  sisaText: {
    fontSize: "1.3vw",
    paddingTop: "0.3vw",
    color: "#ffffff",
    [theme.breakpoints.down('xs')]: {
      fontSize: "4vw",
      paddingTop: "1.2vw",
    },
  },
  sisaBoxInDetail: {
    backgroundColor: "#ff1e1e",
    textAlign: "center",
    width: "6vw",
    borderRadius: "1.3vw",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  sisaTextInDetail: {
    fontSize: "1.3vw",
    paddingTop: "0.3vw",
    color: "#ffffff",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  indicatorItemSelected: {
    display: 'inline-block',
    background: '#000',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  indicatorItem: {
    display: 'inline-block',
    background: '#999',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  limitedStockBubbleBox: {
    width: "fit-content",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "3px",
    borderRadius: "12px",
    border: "1px solid black",
    marginBottom: "28px"
  },
  limitedStockBubbleText: {
    fontFamily: "OakesGrotesk",
    fontWeight: 500,
    fontSize: "11px",
    letterSpacing: "0.5px",
  },
  starsElementContainer: {
    display: 'flex',
    marginBottom: '6px',
  },
  starsDescriptionText: {
    fontWeight: 'normal',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '10px',
    marginTop: '4px',
  },
  productRating: {
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '14px',
    },
  },
  reviewsSegment: {
    margin: '0px 0px 30px 0px',
  },
  reviewsSegmentTitle: {
    margin: '40px 20px 20px 20px',
    fontWeight: theme.typography.fontWeightBold as number,
    [theme.breakpoints.up('md')]: {
      margin: '0px 40px 10px 50px',
    }
  },
  reviewsSegmentBody: {
    marginTop: '18px',
    marginBottom: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    [theme.breakpoints.up('md')]: {
      marginTop: '18px',
      marginBottom: '20px',
      marginLeft: '60px',
      marginRight: '60px',
    },
  }, 
  reviewItem: {
    margin: '0px 0px 16px 0px',
    padding: '8px 2px 24px 2px',
    width: 'auto',
    borderBottom: "1px solid #dedede",

    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px 22px 0px',
      padding: '6px 4px 28px 4px',
    },
  },
  reviewItemNew: {
    margin: '16px 10px',
    padding: '20px 20px',
    width: 'auto',
    cursor: 'pointer',
  },
  reviewDisplayPicture: {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    padding: '10px',
    margin: '8px 18px 0px 6px',

    backgroundColor: '#d5d5d5',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  reviewDisplayText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '20px',
    color: '#fff',
    marginTop: '0px',
    width: '30px' // Set to equal to display picture size
  },
  reviewItemBoxDescription: {

  },
  reviewItemBox1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "6px",
    },
  },
  reviewItemIsVerifiedText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '11px',
    color: '#7A7A7A',
    paddingLeft: '1px',
    marginLeft: '10px', 
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  reviewItemName: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '15px',
    color: "#121212",
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  reviewItemBox2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "5px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "6px",
    },
    // [theme.breakpoints.down('sm')]: {
    //   flexDirection: 'column',
    // },
  },
  reviewItemDateAndPlaceText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '11px',
    color: "#6c6c6c",
    marginBottom: '12px', 
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  reviewItemHeadline: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '15px',
    paddingLeft: '2px',
    paddingTop: '1px', 
    marginLeft: '6px', 
    marginBottom: '6px',
    color: "#121212",
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      paddingTop: '1px', 
      marginLeft: '6px', 
    },
  },
  reviewItemComment: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: '15px',
    color: "#121212",
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  newReviewIcon: {
    marginLeft: '10px',
    marginBottom: '-4px'
  },
  starFilled: {
    color: '#FFA41C',
    marginLeft: '-3px',
    marginRight: '3px',
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      marginLeft: '-3px',
      marginRight: '3px',
    },
  },
  starEmpty: {
    color: '#d8d8d8',
    marginLeft: '-3px',
    marginRight: '3px',
    [theme.breakpoints.down('sm')]: {
      width: '24px',
    },
  },
  roundedButtonSelected: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '400px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '2px',
    paddingBottom: '2px',
    margin: '4px 8px 12px 0px',
    fontWeight: 600,
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
    border: '1px solid black',
  },
  roundedButton: {
    // color: 'white',
    borderRadius: '400px',
    color: '#727272',
    borderColor: '#727272',
    borderWidth: '1px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '2px',
    paddingBottom: '2px',
    margin: '4px 8px 12px 0px',
    fontWeight: 600,
    fontSize: '12px',
  },
  imageBoxWidth: {
    width: '30vw',
  },
  sectionPhoto: {
    margin: '0',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    flexGrow: 0,
    maxWidth: '70%',
    flexBasis: '70%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  sectionDescription: {
    margin: '0',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    flexGrow: 0,
    maxWidth: '30%',
    flexBasis: '30%',
    paddingLeft: '26px',
    paddingRight: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  svgContentFullWidthDesktop: {
    marginTop: "3.3vw",
    marginBottom: "1.2vw",
    width: '100%',
  },
  svgContentFullWidthMobile: {
    width: '100%',
    marginTop: "16px",
  },
  sizeOptionsBoxDetailIconButton: {
    border: '1px solid #969696',
    borderRadius: '30px',
    color: '#000',
    padding: '1.2vw 2.4vw',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 10px 5px 10px',
    }
  },
  sizeOptionsBoxDetailIconImg: {
    width: '4.1vw',
    margin: '-0.5vw',
    [theme.breakpoints.up('sm')]: {
      width: '16px',
      margin: '-2px',
    }
  },
  sizeOptionsBoxDetailText: {
    fontWeight: 500,
    letterSpacing: 0.5,
    paddingTop: '2px',
    fontSize: '2.1vw', // 9px -> 3px is 0.8vw
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px',
      paddingTop: '2px',
    }
  },
  sizeOptionsBoxEditIconButton: {
    border: '1px solid #969696',
    borderRadius: '40px',
    color: '#000',
    padding: '1.5vw 1.5vw',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 7px 5px 7px',
    }
  },
  sizeOptionsBoxEditText: {
    fontWeight: 500,
    paddingTop: '2px',
    fontSize: '2.4vw', // 9px -> 3px is 0.8vw
    [theme.breakpoints.up('sm')]: {
      fontSize: '10px',
      paddingTop: '2px',
    }
  }
}),
);

interface UkuranBadanCardProps {
  nama: string
  width: string
}

export function UkuranBadanCardMini({nama, width}: UkuranBadanCardProps) {
  return (
    <Box style={{
      width: width,
      position: 'relative',
    }}>
      <img 
      src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/card-ukuran-badan-name.png"} 
      alt="measkit-img"
      style={{
        width: "100%",
        objectFit: "cover"
      }}
      />
      <Typography style={{
        color: '#cfcfcf',
        fontSize: `calc(${width}*0.07)`,
        position: 'absolute',
        left: '13%',
        top: '44%',
      }}>
        Ukuran {nama}
      </Typography>
    </Box>
  )
}

interface UkuranBadanCardFullProps {
  nama: string
  height: string
  weight: string
  bodyType: string
  dibuatPada: string
  width: string
}

export function UkuranBadanCardFull({nama, height, weight, bodyType, dibuatPada, width}: UkuranBadanCardFullProps) {
  return (
    <Box style={{
      width: width,
      position: 'relative',
    }}>
      <img 
      src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/card-ukuran-badan-full.png"} 
      alt="measkit-img"
      style={{
        width: "100%",
        objectFit: "cover"
      }}
      />
      <Typography style={{
        color: '#cfcfcf',
        fontSize: `calc(${width}*0.07)`,
        position: 'absolute',
        left: '13%',
        top: '44%',
      }}>
        Ukuran {nama}
      </Typography>
      <Typography style={{
        color: '#cfcfcf',
        fontSize: `calc(${width}*0.035)`,
        position: 'absolute',
        left: '13%',
        top: '67%',
      }}>
        {height}
      </Typography>
      <Typography style={{
        color: '#cfcfcf',
        fontSize: `calc(${width}*0.035)`,
        position: 'absolute',
        left: '36%',
        top: '67%',
      }}>
        {weight}
      </Typography>
      <Typography style={{
        color: '#cfcfcf',
        fontSize: `calc(${width}*0.035)`,
        position: 'absolute',
        left: '13%',
        top: '58%',
      }}>
        {bodyType}
      </Typography>
      <Typography style={{
        color: '#cfcfcf',
        fontSize: `calc(${width}*0.035)`,
        position: 'absolute',
        left: '13%',
        top: '77%',
      }}>
        {dibuatPada}
      </Typography>
    </Box>
  )
}

interface SizeOptionsProps {
  clothingType: ClothingType
  fitType: FitType
  productDetail?: ProductOnUser
}

export default function SizeOptions({clothingType, fitType, productDetail}: SizeOptionsProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  const overlayViewSelection = useAppSelector(state => state.overlayMeasurementSelectionView);

  const key2 = clothingType + "-" + fitType;

  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const userAccount = useAppSelector(state => state.account);

  const canMakeUkuranBadan = useAppSelector(state => state.ordersList.paidOrderCount >= 1);

  const userPrefDict = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsPreferenceDictionary);

  const userBodyMeasurementsDictionary = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const userClothingMeasurementsDictionary = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsDictionary);
  
  let userBodyMeasurementCreationDateTimeList: string[] = Object.keys(userBodyMeasurementsDictionary).sort().reverse();
  let userClothingMeasurementCreationDateTimeList: string[] = userClothingMeasurementsDictionary[key2] ? Object.keys(userClothingMeasurementsDictionary[key2]).sort().reverse() : [];

  const {
    currentSelectedMeasurement,
    currentSelectedMeasurementId,
    currentSelectedMeasurementIsBody,
    key2DefaultSelectedMeasurementCanBeSet,
    key2DefaultSelectedMeasurementCanBeSetRecommended,
    currentSingleBodyMeasurementCreationDateTime
  } = getCurrentSelectedMeasurement(key2, userPrefDict, userBodyMeasurementsDictionary, userClothingMeasurementsDictionary);

  const singleSelectedBodyMeasurementCreationDateTime = currentSingleBodyMeasurementCreationDateTime ? currentSingleBodyMeasurementCreationDateTime.substring(5) : userBodyMeasurementCreationDateTimeList?.[0];
  const singleSelectedBodyMeasurement = userBodyMeasurementsDictionary?.[singleSelectedBodyMeasurementCreationDateTime]?.[0];

  let defaultCreationDateTimeUsed = currentSelectedMeasurement?.creationDateTime;
  let defaultIsBodyUsed = currentSelectedMeasurementIsBody;

  const regularSizeCreationDateTime: {[key: string]: string}= {
    [RegularSizeValue.NOTSELECTED]: "",
    [RegularSizeValue.XXS]: "",
    [RegularSizeValue.XS]: "",
    [RegularSizeValue.S]: "",
    [RegularSizeValue.M]: "",
    [RegularSizeValue.L]: "",
    [RegularSizeValue.XL]: "",
    [RegularSizeValue.XXL]: "",
    [RegularSizeValue.XXXL]: "",
    [RegularSizeValue.XXXXL]: "",
    [RegularSizeValue.XXXXXL]: "",
  };

  for (var regIndex = 0; regIndex < userClothingMeasurementCreationDateTimeList.length; regIndex++) {
    const creationDateTime = userClothingMeasurementCreationDateTimeList[regIndex];
    const clothingMeasObject = userClothingMeasurementsDictionary?.[key2]?.[creationDateTime][0];
    const regularSize = clothingMeasObject.regularSize;

    if (!clothingMeasObject 
      || clothingMeasObject.sizeType !== SizeType.regular
      || !regularSize
      || regularSizeCreationDateTime[regularSize as string] !== "") {
      continue;
    }

    regularSizeCreationDateTime[regularSize as string] = creationDateTime;
  }

  if (currentSelectedMeasurement?.sizeType === SizeType.regular && 
      currentSelectedMeasurement?.regularSize &&
      regularSizeCreationDateTime?.[currentSelectedMeasurement.regularSize as string] !== "") {
    defaultCreationDateTimeUsed = regularSizeCreationDateTime[currentSelectedMeasurement.regularSize];
    defaultIsBodyUsed = false;
  }

  useEffect(() => {
    if (defaultCreationDateTimeUsed) {
      console.log("Updated parent selected measurement data");
      dispatch(setPilihUkuranDataValues({
        key2: key2,
        creationDateTime: defaultCreationDateTimeUsed,
        prefCreationDateTime: (defaultIsBodyUsed ? "body-" : "") + defaultCreationDateTimeUsed,
        isOwnedByUser: false
      }))
    }
  }, [defaultCreationDateTimeUsed, overlayViewSelection.status]); // Untuk mengupdate yang dipilih setiap kali ukuran baru dibuat atau overlay berganti

  function handleSelect(creationDateTime: string, isBody: boolean, newSelectedMeasurement: any) {

    // We update remote preferences if the user is signed in and
    // the new selected measurement is owned by the user
    let isOwnedByUser = false;
    if (isSignedIn && newSelectedMeasurement.idType !== undefined) {
      isOwnedByUser = (newSelectedMeasurement.idType === 'email'
        && newSelectedMeasurement.email === userAccount.email)
        || (newSelectedMeasurement.idType === 'phoneNumber'
        && newSelectedMeasurement.phoneNumber === userAccount.phoneNumber);
    }

    dispatch(setPilihUkuranDataValues({
      key2: key2,
      creationDateTime: creationDateTime,
      prefCreationDateTime: (isBody ? "body-" : "") + creationDateTime,
      isOwnedByUser: isOwnedByUser
    }))
  }

  const postMeasurementStatus = useAppSelector(state => state.userMeasurementsList.postMeasurementStatus);

  if (postMeasurementStatus === APIRequestStatus.RequestInProgress) {
    return (<Box 
      key={'loading-bar-size'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '14px 16px 14px 16px',
        marginTop: '25vh',
        marginBottom: '10px',
        borderRadius: '20px',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
          padding: '3.2vw 3.4vw 3.2vw 3.4vw',
          borderRadius: '5vw',
        },
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px'
      }}
    >
      <CircularProgress style={{
        width: '50px',
        height: '50px',
      }}/>
      <Typography>
        Loading...
      </Typography>
    </Box>);
  }

  return (
  <Box className={classes.sizeOptionsBox}>
    {/* BADAN */}
    {/* <Box className={classes.sizeOptionsBoxBadan}>
      <Box className={classes.sizeOptionsBoxSegmentBox}>
        <Typography className={classes.sizeOptionsBoxSegmentTitle}>UKURAN BADAN</Typography>
        {
          singleSelectedBodyMeasurementCreationDateTime ? 
          null:
          <img key={'lock-image'} alt={'alt-lock'} src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/icon-lock.png"}
          style={{ width: '16px', paddingBottom: '5px', marginLeft: '5px'}}/>
        }
        <Typography className={classes.sizeOptionsBoxSegmentSubtext}>Untuk Semua Produk</Typography>
      </Box>
      {
        singleSelectedBodyMeasurementCreationDateTime ? 
        <Box
          onClick={(event) => {
            handleSelect(singleSelectedBodyMeasurementCreationDateTime, true, singleSelectedBodyMeasurement);
          }}
          style={{
            position: 'relative',
          }}
        >
          {
            creationDateTimeUsed && singleSelectedBodyMeasurementCreationDateTime === creationDateTimeUsed && isBodyUsed ?
            <img className={classes.measurementMenuItemCheck} 
            key={'select-green'} src={process.env.PUBLIC_URL + '/green-check-select.png'} alt='green-check'/> :
            null
          }
          <img 
          src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-popup-bg.jpg"} 
          alt="measkit-img"
          className={classes.sizeOptionsBoxBadanImage}/>
          <Box className={classes.sizeOptionsBoxBadanGantiBox}
            onClick={() => {

            }}>
            <Typography className={classes.sizeOptionsBoxBadanGantiText}>
              GANTI
            </Typography>
          </Box>
          <Box className={classes.sizeOptionsBoxBadanCardBox}>
            <UkuranBadanCardMini width={ isMdOrUp ? '120px' : '17vw' } nama={singleSelectedBodyMeasurement?.nama ? singleSelectedBodyMeasurement.nama : 'User'}/>
          </Box>
          <Grid container style={{ position: 'absolute', width: '70%', left: '30%', top: '10%'  }}>
            <Grid item xs={12} className={classes.sizeOptionsBoxBadanNameBox}>
              <Typography className={classes.sizeOptionsBoxBadanNameText}>UKURAN {singleSelectedBodyMeasurement?.nama ? singleSelectedBodyMeasurement.nama.toUpperCase() : 'User'}</Typography>
              <Typography className={classes.sizeOptionsBoxBadanTanggalText}>
                Dibuat pada {new Date(singleSelectedBodyMeasurementCreationDateTime).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.sizeOptionsBoxBadanTinggiBeratText}>TINGGI BADAN</Typography>
              <Typography className={classes.sizeOptionsBoxBadanTinggiBeratValueText}>{singleSelectedBodyMeasurement?.height ? singleSelectedBodyMeasurement.height : '175'} cm</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.sizeOptionsBoxBadanTinggiBeratText}>BERAT BADAN</Typography>
              <Typography className={classes.sizeOptionsBoxBadanTinggiBeratValueText}>{singleSelectedBodyMeasurement?.weight ? singleSelectedBodyMeasurement.weight : '75'} kg</Typography>
            </Grid>
          </Grid>
        </Box> :
        canMakeUkuranBadan ?
        <Box 
          onClick={(event) => {
            // closeFunction();
            // TODO Open ukuran badan here
          }}>
          <img 
          src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-popup-hidden-2.jpg"} 
          alt="measkit-img"
          className={classes.sizeOptionsBoxBadanImage}/>
        </Box>:
        <Box>
          <img 
          src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-popup-hidden-2.jpg"} 
          alt="measkit-img"
          className={classes.sizeOptionsBoxBadanImage}/>
        </Box>
      }
    </Box>
     */}

    {/* BADAN */}

    {/* BAJU */}

    

    <Box className={classes.sizeOptionsBoxBaju}>
      <Box className={classes.sizeOptionsBoxSegmentBox}>
        <Typography className={classes.sizeOptionsBoxSegmentTitle}>UKURAN BAJU</Typography>
        <Typography className={classes.sizeOptionsBoxSegmentSubtext}>Untuk Kategori Ini</Typography>
      </Box>
      {
        userClothingMeasurementCreationDateTimeList.map((creationDateTime, index) => {

          const clothingMeasObject = userClothingMeasurementsDictionary?.[key2]?.[creationDateTime][0];

          if (!clothingMeasObject) {
            return null;
          }

          const measName = clothingMeasObject.measurementName;
          const caraBuatString = clothingMeasObject.caraBuatString;
          const sizeType = clothingMeasObject.sizeType;

          const isSelected = overlayViewSelection?.pilihUkuranData?.creationDateTime === creationDateTime;

          if (sizeType === SizeType.personal) {
            return (<Box className={isSelected ? classes.measurementMenuItemSelected : classes.measurementMenuItem}
                key={creationDateTime}
              >
                {
                  isSelected ?
                  <img className={classes.measurementMenuItemCheck} 
                  key={'select-green'} src={process.env.PUBLIC_URL + '/green-check-select.png'} alt='green-check'/> :
                  null
                }
                <Grid container>
                  <Grid item xs={7} style={{display: 'flex', alignItems: 'center'}} 
                    onClick={(event) => {
                      handleSelect(creationDateTime, false, userClothingMeasurementsDictionary[key2][creationDateTime][0]);
                  }}>
                    <Grid container>
                      <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                        <Typography className={classes.measurementMenuItemDetailContentText}>
                          Dibuat {formatTimestampSize(creationDateTime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                        <Typography className={classes.measurementMenuItemMainText}>
                          {measName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Grid container spacing={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Box
                          style={{
                            display: 'flex',
                            gap: '5px'
                          }}
                        >
                          <IconButton
                            className={classes.sizeOptionsBoxDetailIconButton}
                            disabled={!clothingMeasObject}
                            onClick={() => {
                              dispatch(closeOverlayAndResetEditView());
                              dispatch(setValuesEditView({
                                overlayType: 'view',
                                clothingType: clothingType,
                                fitType: fitType,
                                isFullScreen: false,
                                currentMeasurementObject: clothingMeasObject,
                                productDetail: overlayViewSelection.productDetail ?? undefined,
                              }))
                              dispatch(openOverlayEditView());
                            }}aria-label="zoom-out">
                              <Typography className={classes.sizeOptionsBoxDetailText}>
                                DETAIL ⓘ
                              </Typography>
                          </IconButton>
                      
                          <IconButton
                            className={classes.sizeOptionsBoxEditIconButton}
                            disabled={!clothingMeasObject}
                            onClick={() => {
                              dispatch(closeOverlayAndResetEditView());
                              dispatch(setValuesEditView({
                                overlayType: 'edit',
                                clothingType: clothingType,
                                fitType: fitType,
                                isFullScreen: false,
                                currentMeasurementObject: clothingMeasObject,
                                productDetail: overlayViewSelection.productDetail ?? undefined,
                              }))
                              dispatch(openOverlayEditView());
                            }} aria-label="zoom-out">
                              <EditIcon style={{height: '12px', width: '12px'}}/>
                          </IconButton>
                        </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            );
          } else if (sizeType === SizeType.regular) {
            if (clothingMeasObject.regularSize && regularSizeCreationDateTime[clothingMeasObject.regularSize as string] !== creationDateTime) {
              return null;
            }
            return (<Box className={isSelected ? classes.measurementMenuItemSelected : classes.measurementMenuItem}
                key={creationDateTime}
                onClick={(event) => {
                  handleSelect(creationDateTime, false, userClothingMeasurementsDictionary[key2][creationDateTime][0]);
                }}
              >
                {
                  isSelected ?
                  <img className={classes.measurementMenuItemCheck} 
                  key={'select-green'} src={process.env.PUBLIC_URL + '/green-check-select.png'} alt='green-check'/> :
                  null
                }
                <Grid container>
                  <Grid item xs={11} style={{display: 'flex', alignItems: 'center'}}>
                  <Typography className={classes.measurementMenuItemMainText}>
                    {userClothingMeasurementsDictionary[key2][creationDateTime][0].measurementName}
                  </Typography>
                  </Grid>
                  <Grid item xs={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <IconButton
                      className={classes.sizeOptionsBoxEditIconButton}
                      style={{marginRight: '-4px'}}
                      disabled={!clothingMeasObject.regularSize}
                      onClick={() => {
                        dispatch(closeOverlayAndResetEditView());
                        dispatch(setValuesEditView({
                          overlayType: 'edit',
                          clothingType: clothingType,
                          fitType: fitType,
                          isFullScreen: false,
                          currentMeasurementObject: getMeasurementObjectFromRegularSize(creationDateTime, fitType, clothingType, clothingMeasObject.regularSize ? clothingMeasObject.regularSize : RegularSizeValue.M),
                          productDetail: overlayViewSelection.productDetail ?? undefined,
                        }))
                        dispatch(openOverlayEditView());
                      }} aria-label="zoom-out">
                        <EditIcon style={{height: '12px', width: '12px'}}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
            
          }

          return null;
        })
      }
    </Box>

    {/* BAJU */}
  </Box>)
}
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import {
  Box, createStyles, Dialog, IconButton, makeStyles, Theme, Typography
} from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import Icon from '@mui/material/Icon';

import { FareastButton } from './OverlayComponentButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(2),
      backgroundColor: 'black',
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'white',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1em 1.5em',
      overflowY: 'hidden',
    },
    pageTitle: {
      marginTop: '1em',
      fontSize: '1.2em',
      width: '80%',
      fontWeight: 550,
    },
    pageItem: {
      display: 'grid',
      gridTemplateColumns: '1fr 8fr',
      marginTop: '1em',
    },
    pageItemIcon: {
      marginRight: '0.2em',
    },
    pageItemTitle: {
      paddingTop: '0.2em',
      fontSize: '0.8em',
      fontWeight: 500,
    },
    pageItemDescription: {
      gridColumn: '2',
      paddingTop: '0.2em',
      paddingRight: '0.5em',
      fontSize: '0.7em',
    },
  }),
);

interface Props {
  handleBack: () => void
  handleNext: () => void
}

export default function PersonalSizeBenefit({ handleBack, handleNext }: Props) {
  const classes = useStyles()

  return (
  <>
    <Box
      className={classes.backButton}
      onClick={handleBack}
    >
      <IconButton className={classes.backButtonIcon}>
        <Close />
      </IconButton>
    </Box>
    <img
      width={'100%'}
      src={'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/Universal_Size_Benefits.jpg'}
      alt='ukuran pas' />
    <Box className={classes.dialogContent}>
      <Typography className={classes.pageTitle}>
        Buat Ukuran Dari Badan, Semuanya Bisa Pas
      </Typography>
      <Box className={classes.pageItem}>
        <AddTaskIcon className={classes.pageItemIcon} />
        <Typography className={classes.pageItemTitle}>Mudah Cara Buatnya</Typography>
        <Typography className={classes.pageItemDescription}>
          Cukup siapkan pita ukur, dan kami beri panduan cara mengukur, cukup
          dengan waktu 5 menit dan ukuran kamu siap digunakan.
        </Typography>
      </Box>
      <Box className={classes.pageItem}>
        <Icon>
          <img
            width={'24px'}
            src={process.env.PUBLIC_URL + '/assets/apparel.svg'}
            alt='universal size' />
        </Icon>
        <Typography className={classes.pageItemTitle}>Dibuat Custom untuk Kamu</Typography>
        <Typography className={classes.pageItemDescription}>
          Semua produk akan dibuat secara custom sesuai dengan ukuran
          yang disimpan.
        </Typography>
      </Box>
      <Box className={classes.pageItem}>
        <ChecklistRtlIcon className={classes.pageItemIcon} />
        <Typography className={classes.pageItemTitle}>Selalu Puas</Typography>
        <Typography className={classes.pageItemDescription}>
          Hasil yang memuaskan, lebih bagus dari beli baju offline.
        </Typography>
      </Box>
      <FareastButton
        buttonMarginLeftRight='0em'
        buttonMarginTopBottom='2em'
        onClick={handleNext}>
        LIHAT CARA
      </FareastButton>
    </Box>
  </>);
}
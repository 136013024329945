import { BodyMeasurementDetail, BodyMeasurementDetailList, BodyMeasurements, 
  BodyMeasurementsForSize, CalculateClothingMeasurementsFromBodyMeasurements, 
  ClothingMeasurementAdjustmentDisplayRange, ClothingType, FitType, 
  GetKey2CategoryClothingSizeMeasurementValues, RoundClothingMeasurementsToHalfPoint,
  ClothingMeasurementsKey } from 'common-ts/dist/models/UserMeasurements';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import {
  Box, CircularProgress, createStyles, Fade, Icon, Button, Grid, IconButton, makeStyles, TextField, Typography
} from '@material-ui/core';
import {
  AddCircleOutline, ErrorOutlineOutlined, InfoOutlined, RemoveCircleOutline, ArrowBack
} from '@material-ui/icons';
import { Theme, fabClasses } from '@mui/material';

import {
  UniversalSizeActiveScreen, updateCurrentScreen
} from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentForm from './OverlayComponentForm';
import BackButtonSegment from './OverlayViewGenerateMeasure0BackSegment';
import React from 'react';
import { RegularSizeValue, RegularSizeValueShortName } from 'common-ts/dist/models/Cart';
import { getBodyMeasurementsSubtext } from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';
import OverlayComponentCanvas from './OverlayComponentCanvasFitAnimation';
import { getClothBodyMeasSuggestions } from './OverlayViewUniversalSize5UkurTiapBagian';

enum InputValueStatus {
  Empty,
  Loading,
  Valid,
  Invalid
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitle: {
      fontSize: '0.65em',
      fontWeight: 600,
      textAlign: 'center',
    },
    tabs: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.5em 0 0.3em 0',
      margin: '0.2em 0em',
      position: 'relative',

      backgroundColor: '#EDEDED',
      borderRadius: '99px',
    },
    tabInput: {
      display: 'none',
      '&:checked': {
        '& + label': {
          '& > *': {
            color: 'black',
            fontWeight: 'bold',
            lineHeight: '2em'
          },
        },
      },
    },
    tab: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      borderRadius: '99px',
      cursor: 'pointer',
      '& > *': {
        zIndex: 2,
        fontSize: '0.6em',
        letterSpacing: '0.05em',
        color: '#585858',
        lineHeight: '2em',
        fontWeight: 400,
        transition: 'all 0.1s ease-in-out',
      },
    },
    glider: {
      position: 'absolute',
      display: 'flex',
      height: '80%',
      width: '48%',
      backgroundColor: 'white',
      zIndex: 1,
      borderRadius: '99px',
      left: '1%',
      top: '10%',
      transition: 'all 0.1s ease-in-out',
    },
    instructionVideo: {
      width: '100%',
      paddingBottom: '1em',
    },
    tabsSubtitle: {
      fontSize: '0.5rem',
      lineHeight: '1rem',
      color: '#ADADAD',
      fontWeight: 400,
      fontFamily: 'OakesGrotesk, sans-serif',
      textAlign: 'center',
      paddingBottom: '2em',
    },
    instructionTitle: {
      fontWeight: 'bold',
      fontSize: '0.65rem',
    },
    instructionText: {
      fontSize: '0.63rem',
    },
    infoBox: {
      display: 'flex',
      alignItems: 'center',
      margin: '1em 0em 0.7em 0em',

      backgroundColor: '#EDEDED',
      borderRadius: '99px',
      justifyContent: 'space-between',
    },
    infoIcon: {
      padding: '1em 1em 1em 1.5em',
      fontSize: '0.9em',
    },
    infoText: {
      fontSize: '0.5rem',
      color: '#606060',
      fontFamily: 'OakesGrotesk, sans-serif',
      width: '50%',
    },
    infoMeasurements: {
      fontSize: '0.7em',
      borderBottom: '0.1em solid black',
      marginRight: '1.4em',
      textWrap: 'nowrap',
    },
    inputBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    inputTitle: {
      fontSize: '4.3vw',
      fontWeight: 500,
      paddingRight: '0.5em',
    },
    lingkarPerutInput: {
      position: 'relative',
      display: 'flex',
      width: '45%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '14px',
    },
    minusIcon: {
      color: '#7E7E7E',
      fontSize: '0.8em',
      zIndex: 2,
    },
    plusIcon: {
      marginLeft: 'auto',
      color: '#7E7E7E',
      fontSize: '0.8em',
      zIndex: 2,
    },
    loadingIcon: {
      fontSize: '0.8em',
      zIndex: 2,
      position: 'absolute',
      left: '50%',
      transform: 'translate(1rem, -0.15rem)'
    },
    errorIcon: {
      color: 'red',
      fontSize: '1em',
      zIndex: 2,
      position: 'absolute',
      left: '50%',
      transform: 'translate(1rem, -0.15rem)'
    },
    lingkarPerutTextField: {
      position: 'absolute',
      width: '80%',
      top: '-0.8em',
      left: '50%',
      transform: 'translateX(-50%)',
      '& input::placeholder': {
        fontSize: '0.5em',
        opacity: 0.4,
      },
    },
    inputUnit: {
      fontSize: '0.9em',
    },
    errorTextBox: {
      marginTop: '0.5rem',
    },
    errorText: {
      fontSize: '0.6rem',
      fontWeight: 300,
      color: 'red',
    },
    resultInfoBox: {
      marginTop: '1em',
      display: 'flex',
      flexDirection: 'column',

      backgroundColor: '#EDEDED',
      borderRadius: '5px',
      padding: '0.5em 1em',
    },
    resultTitleBox: {
      marginLeft: '-0.5em',
      display: 'flex',
      alignItems: 'center',
    },
    resultTitle: {
      paddingLeft: '1em',
      fontWeight: 'bold',
      fontSize: '0.7rem',
    },
    resultSubtitle: {
      fontSize: '0.65rem',
      padding: '1em 0em',
    },
    resultSizes: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0.2em',
    },
    resultItemTitle: {
      fontSize: '0.7rem',
      fontWeight: 500,
    },
    resultItemMeasurements: {
      fontSize: '0.7rem',
    },
    submitButton: {
      // position: 'absolute',
      // marginTop: '3rem',
    },
    videoContainer: {
      width: '100%',
      position: 'relative'
    },
    circularProgressContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    canvasContainer: {
      width: '100%',
      position: 'relative',
      border: 'solid 1px #9a9a9a',
      borderRadius: '30px',
    },
    categoryTab: {
      position: 'absolute',
      left: `calc(50% - 40vw)`,
      textAlign: 'center',
      width: '80vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s',
    },
    contentCategoryContainer: {
      padding: '0px 20px',
      marginTop: '2vw',
      width: 'calc(100% - 40px)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    methodText: {
      marginTop: '0.4rem',
      fontSize: '0.95rem',
      fontWeight: 500,
    },
    methodCaraText: {
      backgroundColor: 'black',
      borderRadius: '30px',
      color: 'white',
      fontSize: '0.69rem',
      letterSpacing: '0.04rem',
      padding: '0.3rem 0.5rem 0.14rem 0.5rem',
      fontWeight: 500,
    }
  }),
);

interface ClothMeasSuggestionElementProps {
  srcImageUrl: string
  productName: string
  measurementName: string
  purchaseDateTime: string
  bodyMeasName: string | undefined
  bodyMeasValue: number | undefined
  bodyMeasSubtext: string
  displayPakaiButton: boolean
  handleClick: () => void
}

function ClothMeasSuggestionElement({ srcImageUrl, productName, measurementName, 
  purchaseDateTime, bodyMeasName, bodyMeasValue, bodyMeasSubtext, displayPakaiButton, handleClick }: ClothMeasSuggestionElementProps) {
  return (
    <Grid container
      direction="row"
      justifyContent="space-between"
      alignItems="center" style={{
        backgroundColor: '#ededed',
        borderRadius: '30px',
        padding: '14px',
        border: '1px solid #d7d7d7',
        marginBottom: '8px',
      }}>
      <Grid xs={3}>
        <img src={srcImageUrl} alt={'disp-img'}
        style={{
          width: '90%'
        }}/>
      </Grid>
      <Grid xs={5} style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '26vw',
      }}>
        <Typography
        style={{
          fontSize: '3.4vw',
          fontWeight: 500,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        >{productName}</Typography>
        <Typography
        style={{
          fontSize: '2.6vw',
          fontWeight: 300,
          color: '#717171'
        }}
        >{measurementName}</Typography>
        <Typography
        style={{
          fontSize: '2.4vw',
          fontWeight: 300,
          color: '#232323',
        }}
        >Dibuat pada {new Date(purchaseDateTime).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})}</Typography>
      </Grid>
      <Grid xs={4} style={{
      }} onClick={handleClick}>
        <Box style={{
          paddingLeft: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography
          style={{
            fontSize: '0.4rem',
            fontWeight: 300,
            color: '#717171',
            paddingTop: '6px'
          }}
          >
            DIBUAT UNTUK
          </Typography>
          <Typography
          style={{
            fontSize: '0.8rem',
            fontWeight: 400,
            paddingTop: '0.3rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            textAlign: 'center',
          }}
          >
            {bodyMeasName}
          </Typography>
          <Typography
          style={{
            fontSize: '1rem',
            fontWeight: 400,
          }}
          >
            {bodyMeasValue} cm
          </Typography>
          <Typography
          style={{
            fontSize: '0.5rem',
            fontWeight: 300,
            color: '#717171',
            paddingTop: '0.05rem',
            paddingBottom: '6px'
          }}
          >
            {bodyMeasSubtext}
          </Typography>
          {
            displayPakaiButton ?
            <Typography style={{
              fontSize: '2.7vw',
              letterSpacing: '0.1vw',
              textAlign: 'center',
              fontWeight: 600,
              border: '1px solid #898989',
              borderRadius: '10vw',
              padding: '3px 0px 0px 0px',
              minWidth: '0px',
              width: '15vw',
            }}>
              PAKAI
            </Typography>:
            null
          }
        </Box>
      </Grid>
    </Grid>);
}

interface VideoProps {
  url: string,
  canPlayThroughCallback: () => void,
}

function VideoElement({url, canPlayThroughCallback}: VideoProps) {
  const classes = useStyles();

  return (
  <video key={'vid-' + url} style={{ 
      width: '100%', 
      height: '140%', 
      borderRadius: '1.6vw',
      backgroundColor: '#efefef',
      aspectRatio: '4 / 3',
    }} loop autoPlay muted
    onCanPlayThrough={canPlayThroughCallback}>
    <source src={url} type="video/mp4" />
    Your browser does not support the video tag.
  </video>);
}

interface Props {
  handleBack: () => void
  handleEachBack: (issues: string[] | undefined, newIssueIndex: number) => void
  handleEachSubmit: (issues: string[] | undefined, newIssueIndex: number, currentValue: number) => void
  handleFinish: () => void
  issues: string[] | undefined
  userSelfInputMeasurements: BodyMeasurements | undefined
  generatedMeasurements: BodyMeasurements | undefined
  generatedMeasurementsRange: {[key: string]: number} | undefined
  generatedMeasurementsSafe: BodyMeasurements | undefined
  marginTop?: string
  backButtonPosition?: any
}

const validationSchema = yup.object({
  measurement: yup.number()
    .positive('Ukuran anda pasti positif!')
    .required('Ukuran diperlukan'),
})

export default function PersonalSizeUkurTiapBagian({ issues, handleBack, handleEachBack, handleEachSubmit, handleFinish, 
  userSelfInputMeasurements, generatedMeasurements, generatedMeasurementsRange, generatedMeasurementsSafe, marginTop, backButtonPosition }: Props) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [measTouched, setMeasTouched] = React.useState<boolean>(false);
  const [issueIndex, setIssueIndex] = React.useState<number>(0);
  const clothMeasSuggestions = useAppSelector(state => getClothBodyMeasSuggestions(state.ordersList.ordersList));
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [categoryTabIndex, setCategoryTabIndex] = React.useState(0);
  const [contentCategoryTabIndex, setContentCategoryTabIndex] = React.useState(0);
  const [currentlyFading, setCurrentlyFading] = React.useState(false);

  const triggerContentFade = () => {
    setCurrentlyFading(true);
    setTimeout(() => {
      setContentCategoryTabIndex(categoryTabIndex);
      setCurrentlyFading(false);
    }, 300);
  }

  const loadChange = () => {
    // get all categoryTabs
    const categoryTabs = document.getElementsByClassName(classes.categoryTab);

    let activeCategoryTab = categoryTabs[categoryTabIndex] as HTMLElement;
    activeCategoryTab.style.transform = `none`;

    for (let i = categoryTabIndex + 1; i < 3; i++) {
      let categoryTab = categoryTabs[i] as HTMLElement;
      categoryTab.style.transform = `translateX(${400}%)`;
    }
    for (let i = categoryTabIndex - 1; i >= 0; i--) {
      let categoryTab = categoryTabs[i] as HTMLElement;
      categoryTab.style.transform = `translateX(${-400}%)`;
    }
  }

  const formik = useFormik({
    initialValues: {
      measurement: 
        issues && userSelfInputMeasurements && 
        userSelfInputMeasurements?.[issues?.[issueIndex] as keyof BodyMeasurements] ? 
        userSelfInputMeasurements[issues[issueIndex] as keyof BodyMeasurements] as number : 
        issues && generatedMeasurements && 
        generatedMeasurements?.[issues?.[issueIndex] as keyof BodyMeasurements] ? 
        generatedMeasurements[issues[issueIndex] as keyof BodyMeasurements] as number : 
        50,
    },
    validationSchema: validationSchema,
    onSubmit: (values, {resetForm} ) => {
      if (issues === undefined) {
        return;
      }
      handleEachSubmit(issues, issueIndex + 1, values.measurement);
      if (issueIndex + 1 === issues?.length) {
        handleFinish();
      } else {
        setIssueIndex(issueIndex + 1);
        setMeasTouched(false);
        setIsLoading(true);
        resetForm({
          values: {
            measurement: 
              issues && userSelfInputMeasurements && 
              userSelfInputMeasurements?.[issues?.[issueIndex + 1] as keyof BodyMeasurements] ? 
              userSelfInputMeasurements[issues[issueIndex + 1] as keyof BodyMeasurements] as number : 
              issues && generatedMeasurements && 
              generatedMeasurements?.[issues?.[issueIndex + 1] as keyof BodyMeasurements] ? 
              generatedMeasurements[issues[issueIndex + 1] as keyof BodyMeasurements] as number : 
              50,
          }
        });
      }
    },
  });

  let detailsOfMeasurement: BodyMeasurementDetail | undefined;
  let detailsCurrentValue = "";
  if (issues !== undefined && issues.length > 0) {
    detailsOfMeasurement = BodyMeasurementDetailList[issues[issueIndex]];
  }
  if (issues !== undefined && issues.length === 0) {
    handleFinish();
  }
  if (issues === undefined) {
    handleFinish();
  }

  const [activeTab, setActiveTab] = useState('ukuranBadan');
  const [inputValueStatus, setInputValueStatus] = useState<InputValueStatus>(InputValueStatus.Empty);
  
  const handleArrowClick = (direction: 'left' | 'right') => {
    const totalCategories = 3;
    let newTabIndex;

    if (direction === 'left') {
      newTabIndex = (categoryTabIndex - 1 + totalCategories) % totalCategories;
    } else {
      newTabIndex = (categoryTabIndex + 1) % totalCategories;
    }

    setCategoryTabIndex(newTabIndex);
    triggerContentFade();
    loadChange();
  }

  const handleTabChange = (activeTab: string) => {
    setActiveTab(activeTab);
    let glider = document.querySelector(`.${classes.glider}`) as HTMLElement;
    if (activeTab === 'ukuranBadan') {
      glider.style.transform = 'translateX(0%)';
    } else {
      glider.style.transform = 'translateX(104%)';
    }
  }

  const handleIncrementDecrement = (operation: 'increment' | 'decrement') => {
    setMeasTouched(true);
    if (operation === 'increment') {
      formik.setFieldValue('measurement', formik.values.measurement + 1);
    } else {
      formik.setFieldValue('measurement', formik.values.measurement - 1);
    }
  }

  useEffect(() => {
    loadChange();
  })

  useEffect(() => {
    const minValidValue = generatedMeasurementsRange?.[issues?.[issueIndex] + 'Min'] ?
      generatedMeasurementsRange[issues?.[issueIndex] + 'Min'] :
      10;
    const maxValidValue = generatedMeasurementsRange?.[issues?.[issueIndex] + 'Max'] ?
      generatedMeasurementsRange[issues?.[issueIndex] + 'Max'] :
      200;
    if (formik.values.measurement === undefined || typeof formik.values.measurement === 'string') {
      setInputValueStatus(InputValueStatus.Empty);
    } else if (minValidValue <= formik.values.measurement && formik.values.measurement <= maxValidValue) {
      setInputValueStatus(InputValueStatus.Valid);
    } else {
      setInputValueStatus(InputValueStatus.Invalid);
    }
  }, [formik.values.measurement])

  const key2ClothSizeValues = GetKey2CategoryClothingSizeMeasurementValues(ClothingType.Kaos, FitType.SlimFit, false);

  let children: JSX.Element[] = [];

  const itemKey: any = (detailsOfMeasurement?.bodyMeasurement ? detailsOfMeasurement.bodyMeasurement : '');

  const itemKeyClothMeas: string = 
    itemKey === 'lingkarDada' ? 'lebarDadaBaju':
    itemKey === 'lingkarPerut' ? 'lebarPerutBaju':
    itemKey === 'lingkarPinggul' ? '':
    itemKey === 'panjangLenganPanjang' ? 'panjangLenganPanjang':
    itemKey === 'lebarBahu' ? 'lebarBahuBaju':
    itemKey === 'lingkarLenganBisep' ? 'lebarLenganBaju':
    itemKey === 'lingkarLeher' ? 'lingkarLeherBaju':
    itemKey;

  if (itemKey !== '') {
    children.push(
      <Box className={classes.canvasContainer}>
        <OverlayComponentCanvas
        clothingType={ClothingType.Kaos}
        fitType={FitType.SlimFit}
        putAtTop={false}
        scaleRatio={0.5}
        incrementTogether={true}
        goalFit={RoundClothingMeasurementsToHalfPoint(CalculateClothingMeasurementsFromBodyMeasurements(ClothingType.Kaos, 
          FitType.SlimFit, {
            ...BodyMeasurementsForSize[RegularSizeValue.L],
            [itemKey as keyof BodyMeasurements]: (itemKey === "lingkarPerut" ? 
              Math.round(formik.values.measurement / 0.916) : formik.values.measurement), // We convert to the NORMALIZED version (bigger) before comparing
          }, RegularSizeValue.L))}
        drawGuidelinesData={{
          lastChangedMeasurement: itemKeyClothMeas,
          lastChangedMeasurementName: '',
          guidelineDisplayRange: ClothingMeasurementAdjustmentDisplayRange[itemKeyClothMeas as ClothingMeasurementsKey],
          sizeMeasurementValues: key2ClothSizeValues.regularSizeClothMeasValues,
          sizeMeasurementNames: key2ClothSizeValues.regularSizeNames
        }}
        canvasManualHeight='50vw'/>
      </Box>
    )
  }

  children.push(
    <Box className={classes.infoBox}>
      <InfoOutlined className={classes.infoIcon} />
      <Typography className={classes.infoText} >
        Ukuran { detailsOfMeasurement ? detailsOfMeasurement.bodyMeasurementName + ' ' : "ini " } 
        untuk tinggi dan berat badan kamu biasanya sekitar
      </Typography>
      <Typography className={classes.infoMeasurements}>
        {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Min'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Min'] :
          '-'
        } cm - {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Max'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Max'] :
          '-'
        } cm
      </Typography>
    </Box>
  );

  children.push(
    <Box className={classes.inputBox}>
      <Typography className={classes.inputTitle}>
        { detailsOfMeasurement ? detailsOfMeasurement.bodyMeasurementName : "Ukuran" } Kamu
      </Typography>
      <Box className={classes.lingkarPerutInput}>
        <IconButton
          onClick={() => handleIncrementDecrement('decrement')}
          style={{ visibility: inputValueStatus === InputValueStatus.Empty ? 'hidden' : 'visible' }}
        >
          <RemoveCircleOutline className={classes.minusIcon} />
        </IconButton>
          <TextField
            className={classes.lingkarPerutTextField}
            margin="normal"
            id="measurement"
            name="measurement"
            type="number"
            value={formik.values.measurement}
            onChange={(e: React.ChangeEvent<any>) => {
              setMeasTouched(true);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            placeholder={detailsOfMeasurement?.bodyMeasurementName}
            inputProps={{
              style: {
                MozAppearance: 'textfield', // Hide arrow on number input in Firefox.
                WebkitAppearance: 'textfield', // Hide arrow on number input in Chrome, Safari, Edge, Opera
                textAlign: 'center',
                marginTop: '0.2em',
                padding: '0.3em',
              },
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
          {
            (() => {
              switch (inputValueStatus) {
                case InputValueStatus.Loading:
                  return (
                    <CircularProgress
                      size={15}
                      className={classes.loadingIcon}
                    />
                  )
                case InputValueStatus.Invalid:
                  return (
                    <ErrorOutlineOutlined
                      className={classes.errorIcon}
                    />
                  )
              }
            })()
          }
        {
          measTouched && formik.values.measurement ?
          <Typography style={{
            position: 'absolute',
            bottom : '-13px',
            fontSize: '7px',
            color: '#787878',
            left: '0',
            right: '0',
            margin: '0 auto',
            textAlign: 'center',
          }}>
            {getBodyMeasurementsSubtext({
              ...BodyMeasurementsForSize[RegularSizeValue.L],
              [issues?.[issueIndex] as keyof BodyMeasurements]: (issues?.[issueIndex] === "lingkarPerut" ? 
                Math.round(formik.values.measurement / 0.916) : formik.values.measurement), // We convert to the NORMALIZED version (bigger) before comparing
            })?.[issues?.[issueIndex] as keyof BodyMeasurements]}
          </Typography>:
          null
        }
        <IconButton
          onClick={() => handleIncrementDecrement('increment')}
          style={{ visibility: inputValueStatus === InputValueStatus.Empty ? 'hidden' : 'visible' }}
        >
          <AddCircleOutline className={classes.minusIcon} />
        </IconButton>
      </Box>
      <Typography className={classes.inputUnit}>
        cm
      </Typography>
    </Box>
  );

  children.push(
    <Box
      className={classes.errorTextBox}
      style={{ display: inputValueStatus === InputValueStatus.Invalid ? 'block' : 'none' }}
    >
      <Typography className={classes.errorText}>
        Ukuran {detailsOfMeasurement?.bodyMeasurementName ? detailsOfMeasurement.bodyMeasurementName + ' ' : ''} 
        yang wajar untuk tinggi dan berat badan kamu
        berkisar dari {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Min'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Min'] :
          ''
        } cm hingga {
          generatedMeasurementsRange?.[issues?.[issueIndex] + 'Max'] ?
          generatedMeasurementsRange[issues?.[issueIndex] + 'Max'] :
          ''
        } cm.
      </Typography>
    </Box>
  )

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <Box className={classes.submitButton}>
      <OverlayComponentButton
        isTypeSubmit={false}
        formButtonText="NEXT"
        buttonMarginTopBottom="0"
        buttonMarginLeftRight="0"
        handleClick={() => {
          formik.handleSubmit();
        }}
      />
    </Box>
  );

  return (
    <>
      <Box
      style={{
        top: '24px',
        left: '16px',
        padding: '8px',
        position: backButtonPosition ? backButtonPosition : 'fixed',
        borderRadius: '50%',
        zIndex: 4,
      }}
        onClick={() => {
            if (issueIndex === 0) {
              handleBack();
            } else {
              handleEachBack(issues, issueIndex - 1);
              formik.resetForm({
                values: {
                  measurement: 
                    issues && userSelfInputMeasurements && 
                    userSelfInputMeasurements?.[issues?.[issueIndex - 1] as keyof BodyMeasurements] ? 
                    userSelfInputMeasurements[issues[issueIndex - 1] as keyof BodyMeasurements] as number : 
                    issues && generatedMeasurements && 
                    generatedMeasurements?.[issues?.[issueIndex - 1] as keyof BodyMeasurements] ? 
                    generatedMeasurements[issues[issueIndex - 1] as keyof BodyMeasurements] as number : 
                    50,
                }
              });
              setMeasTouched(true); // We do not reset / remove measTouched when going backwards, only forwards.
              setIssueIndex(issueIndex - 1);
            }
          }}
      >
        <IconButton style={{
          padding: '0',
          color: 'black',
        }}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Typography
      style={{
        top: '25px',
        left: '56px',
        padding: '8px',
        position: 'fixed',
        zIndex: 4,
        fontSize: '5vw',
        fontWeight: 500,
      }}>
        {detailsOfMeasurement?.bodyMeasurementName}
      </Typography>
      <Box style={{
        padding: "3.5em 20px 0px 20px",
        boxShadow: 'rgb(152 152 152 / 44%) 0px 2px 8px 1px',
        borderRadius: '0px 0px 26px 26px',
        position: 'fixed',
        zIndex: 3,
        backgroundColor: '#ffffff'
      }}>
        <OverlayComponentForm
          formTitle=""
          formSubmitBody={[]}
          formFormBody={children}
          formHandleSubmit={formik.handleSubmit}
          titleWidthMdUp='0'
          titleWidthSmDown='0'
          formWidthMdUp='0'
          formWidthSmDown='100%'
          formJustifyContent='space-evenly'
          formAlignItems='flex-start'
        />
      </Box>
      <Box style={{
        padding: "3.5em 20px 0px 20px",
        boxShadow: 'rgb(220, 220, 220) 0px 2px 8px 1px',
        borderRadius: '0px 0px 26px 26px',
        visibility: 'hidden',
        color: '#ffffff'
      }}>
        <OverlayComponentForm
          formTitle=""
          formSubmitBody={[]}
          formFormBody={children}
          formHandleSubmit={formik.handleSubmit}
          titleWidthMdUp='0'
          titleWidthSmDown='0'
          formWidthMdUp='0'
          formWidthSmDown='100%'
          formJustifyContent='space-evenly'
          formAlignItems='flex-start'
        />
      </Box>
      <Box style={{
        marginTop: '6vw',
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflow: 'hidden',
      }}>
        <IconButton
        onClick={() => handleArrowClick('left')}
        disabled={categoryTabIndex === 0}
        style={{
          backgroundColor: categoryTabIndex === 0 ? '#9e9e9e' : 'black',
          color: 'white',
          padding: '4px',
          margin: '10px',
        }}>
          <ArrowLeft />
        </IconButton>

        <Box
          key={0}
          className={classes.categoryTab}
          style={{
            transform: `translateX(${0}%)` // Added to remove "side movement on initialization"
          }}>
          <Typography className={classes.methodCaraText}>CARA #1</Typography>
          <Typography className={classes.methodText}>Dari Ukuran Order Terakhir</Typography>
        </Box>
        <Box
          key={1}
          className={classes.categoryTab}
          style={{
            transform: `translateX(${400}%)` // Added to remove "side movement on initialization"
          }}>
          <Typography className={classes.methodCaraText}>CARA #2</Typography>
          <Typography className={classes.methodText}>Pengukuran Badan Manual</Typography>
        </Box>
        <Box
          key={2}
          className={classes.categoryTab}
          style={{
            transform: `translateX(${800}%)` // Added to remove "side movement on initialization"
          }}>
          <Typography className={classes.methodCaraText}>CARA #3</Typography>
          <Typography className={classes.methodText}>Pakai Ukuran Standar</Typography>
        </Box>

        <IconButton
        onClick={() => handleArrowClick('right')}
        disabled={categoryTabIndex === 2}
        style={{
          backgroundColor: categoryTabIndex === 2 ? '#9e9e9e' : 'black',
          color: 'white',
          padding: '4px',
          margin: '10px',
        }}>
          <ArrowRight />
        </IconButton>
      </Box>
      <Fade in={!currentlyFading} timeout={800}
      style={{
        minHeight: '30vw'
      }}>
        {
          !currentlyFading && categoryTabIndex === 0?
          <Box className={classes.contentCategoryContainer}>
            <Typography style={{
              color: '#9a9a9a',
              textAlign: 'center',
              paddingBottom: '14px',
              fontSize: '2.5vw',
              fontWeight: 300,
            }}>
              Gunakan ukuran {detailsOfMeasurement?.bodyMeasurementName.toLowerCase()} produk yang terakhir kamu beli<br></br>sebagai awal, dan perbesar atau perkecil sesuai keinginan.
            </Typography>
            {
              clothMeasSuggestions.map((suggestionObject) => {
                // DENORMALIZE BEFORE USING
                const denormalizedMeasValue = (itemKey === "lingkarPerut" ? 
                  Math.round((clothMeasSuggestions[0].clothMeasValue?.[itemKey as keyof BodyMeasurements] as number) * 0.916):
                  clothMeasSuggestions[0].clothMeasValue?.[itemKey as keyof BodyMeasurements]);

                return (
                  <ClothMeasSuggestionElement
                    srcImageUrl={suggestionObject.productDisplayImage}
                    productName={suggestionObject.productName}
                    measurementName={suggestionObject.measurementName}
                    purchaseDateTime={suggestionObject.purchaseDateString}
                    bodyMeasName={detailsOfMeasurement?.bodyMeasurementName}
                    bodyMeasValue={denormalizedMeasValue}
                    bodyMeasSubtext={getBodyMeasurementsSubtext(clothMeasSuggestions[0].clothMeasValue)?.[itemKey as keyof BodyMeasurements]} // Subtext uses the NORMALIZED (corrected) one
                    displayPakaiButton={denormalizedMeasValue !== formik.values.measurement}
                    handleClick={() => {
                      setMeasTouched(true);

                      // USE THE DENORMALIZED VALUE
                      formik.setFieldValue('measurement', denormalizedMeasValue);
                    }}
                  />
                )
              })
            }
          </Box>:
          !currentlyFading && categoryTabIndex === 1?
          <Box className={classes.contentCategoryContainer}
            style={{
              marginTop: '3vw',
            }}>
            <Box className={classes.videoContainer}>
              { 
                isLoading && detailsOfMeasurement?.measurementInstructionVideoSrc  ?
                <div className={classes.circularProgressContainer}>
                  <CircularProgress/> 
                </div>: 
                null
              }
              <VideoElement 
                url={issues && detailsOfMeasurement ? detailsOfMeasurement.measurementInstructionVideoSrc : ''}
                canPlayThroughCallback={() => { 
                  if (detailsOfMeasurement?.measurementInstructionVideoSrc) {
                    setIsLoading(false);
                  }
                }}
              />
              {
                detailsOfMeasurement?.measurementInstructionVideoSrc ?
                null :
                <Typography style={{
                  fontSize: '2.5vw',
                  position: 'absolute',
                  fontWeight: 600,
                  letterSpacing: '0.1vw',
                  top: '32vw',
                  left: '24vw',
                }}>
                  INSTRUCTION VIDEO COMING!
                </Typography>
              }
            </Box>
            <Box style={{marginTop: '16px'}}>
              <Typography className={classes.instructionTitle}>
                Instruksi
              </Typography>
              <Typography className={classes.instructionText}>
                { detailsOfMeasurement ? detailsOfMeasurement.measurementInstructionDescription : "Instruksi pengukuran" }
              </Typography>
            </Box>
          </Box>:
          !currentlyFading && categoryTabIndex === 2?
          <Box className={classes.contentCategoryContainer}
            style={{
              marginTop: '3vw',
            }}>
            <Grid container>
              {
                [
                  RegularSizeValue.XXS,
                  RegularSizeValue.XS,
                  RegularSizeValue.S,
                  RegularSizeValue.M,
                  RegularSizeValue.L,
                  RegularSizeValue.XL,
                  RegularSizeValue.XXL,
                ].map((value, index) => {
                  let measValue = BodyMeasurementsForSize[value][itemKey as keyof BodyMeasurements];
                  if (measValue === undefined) {
                    measValue = 0;
                  }
                  measValue = (itemKey === "lingkarPerut" ? Math.round(measValue * 0.916) : measValue); // We convert to the DENORMALIZED version (smaller)
                  const sizeShortName = RegularSizeValueShortName[value];
                  return (
                    <Grid xs={3}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      backgroundColor: measValue === formik.values.measurement ? '#ededed' : undefined,
                      padding: '6px 0px 3px 0px',
                      borderRadius: '0.6rem',
                      margin: '3px 0px 3px 0px',
                    }} onClick={() => {
                      setMeasTouched(true);
                      formik.setFieldValue('measurement', measValue);
                    }}>
                      <Typography style={{
                        fontWeight: 500,
                        fontSize: '0.85rem',
                      }}>
                        {measValue} cm
                      </Typography>
                      <Typography style={{
                        fontWeight: 300,
                        fontSize: '0.9rem',
                        color: '#c0c0c0'
                      }}>
                        {sizeShortName}
                      </Typography>
                    </Grid>
                  );
                })
              }
            </Grid>
          </Box>:
          <Box></Box>
        }
      </Fade>
      <Box style={{
        width: '100%',
        height: '100px',
      }}>
      </Box>
      <Box style={{
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '20px',
      }}>
        { submitChildren }
      </Box>
    </>
  )
}

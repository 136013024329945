import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import {
  Box, createStyles, Dialog, IconButton, makeStyles, Theme, Typography
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import {
  UniversalSizeActiveScreen, updateCurrentScreen
} from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FareastButton } from './OverlayComponentButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(2),
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'black',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1em 1.5em'
    },
    pageItemsContainer: {
      display: 'grid',
      margin: '2em 1em',
      gridTemplateColumns: '1fr 10fr',
      rowGap: '3em',
    },
    pageTitle: {
      fontSize: '1.1em',
      width: '100%',
      fontWeight: 550,
      marginTop: '5em',
    },
    pageItemIcon: {
      paddingTop: '0.5em',
    },
    pageItemTextBox: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    pageItemTitle: {
      paddingTop: '0.2em',
      fontSize: '0.8em',
      fontWeight: 500,
      gridRow: '1',
    },
    pageItemDescription: {
      gridColumn: '2',
      paddingTop: '0.2em',
      paddingRight: '0.5em',
      fontSize: '0.6em',
      gridRow: '2',
    },
    buatUkuranButton: {
      marginTop: '1em',
      marginLeft: 'auto',
    },
  }),
);

interface Props {
  handleBack: () => void
  handleNext: () => void
}

export default function PersonalSizeCaraBuat({ handleBack, handleNext }: Props) {
  const classes = useStyles()

  return <>
    <Box
      className={classes.backButton}
      onClick={handleBack}
    >
      <IconButton className={classes.backButtonIcon}>
        <ArrowBack />
      </IconButton>
    </Box>
    <Box className={classes.dialogContent}>
      <Typography className={classes.pageTitle}>
        Cara Buat Universal Body Size
      </Typography>
      <Box className={classes.pageItemsContainer}>

        <Box className={classes.pageItemIcon}>
          <img
            src={process.env.PUBLIC_URL + '/assets/pita-ukur.svg'}
            alt='pita ukur' />
        </Box>
        <Box className={classes.pageItemTextBox}>
          <Typography className={classes.pageItemTitle}>01.</Typography>
          <Typography className={classes.pageItemDescription}>
            Siapkan Pita ukur untuk pengukuran
          </Typography>
        </Box>
        <Box className={classes.pageItemIcon}>
          <img
            src={process.env.PUBLIC_URL + '/assets/human-dimension.svg'}
            alt='pita ukur' />
        </Box>
        <Box className={classes.pageItemTextBox}>
          <Typography className={classes.pageItemTitle}>02.</Typography>
          <Typography className={classes.pageItemDescription}>
            Isi Tinggi dan Berat badan serta pilih tipe badan yang sesuai dengan kamu
          </Typography>
        </Box>
        <Box className={classes.pageItemIcon}>
          <img
            src={process.env.PUBLIC_URL + '/assets/clothes-ruler.svg'}
            alt='pita ukur' />
        </Box>
        <Box className={classes.pageItemTextBox}>
          <Typography className={classes.pageItemTitle}>03.</Typography>
          <Typography className={classes.pageItemDescription}>
            Lakukan pengukuran sesuai dengan instruksi. Kamu bisa menggunakan pengukuran
            menggunakan baju apabila kesulitan melakukan ukuran badan.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.buatUkuranButton}>
        <FareastButton
          buttonMarginLeftRight='0em'
          buttonMarginTopBottom='2em'
          onClick={handleNext}>
          BUAT UKURAN
        </FareastButton>
      </Box>
    </Box>
  </>
}
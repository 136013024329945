import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { useAppSelector } from '../reduxhooks';
import OverlayComponentChoice from './OverlayComponentChoice';
import OverlayComponentTitle from './OverlayComponentTitle';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
}),
);

interface Props {
  onFetchMeasEstimateSuccess: () => void
}

export default function PageCheckoutMeasurements({ onFetchMeasEstimateSuccess }: Props) {
  const classes = useStyles();
  const fetchMeasurementEstimateState = useAppSelector(state => state.userMeasurementsList.fetchMeasurementEstimateStatus);

  if (fetchMeasurementEstimateState === APIRequestStatus.Success) {
    setTimeout(() => {
      onFetchMeasEstimateSuccess();
    }, 500);
  }

  return (
    <>
      <Box style={{
        padding: "0px 20px",
        marginTop: '5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Typography style={{ marginBottom: "60px" }}>
          Generating Your Measurements...
        </Typography>
        <CircularProgress/>
      </Box>
    </>
  );
}
import { ProductOnUser } from 'common-ts/dist/models/Product';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { inputMeasurementsForm, resetUserMeasurementDraft } from './MeasurementsRedux';
import { ClothingType, FitType, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';

export interface MeasureSignUpFlow {
  status: UIOverlayViewStatus,

  currentScreen: string,
  previousScreen: string | undefined
  currentTab: string,

  addToCartAfterCreation: boolean,
  productDetail: ProductOnUser | undefined,
}

// Define the initial state using that type
const initialState: MeasureSignUpFlow = {
  status: UIOverlayViewStatus.OffAndReady,

  currentScreen: "regularSize",
  previousScreen: undefined,
  currentTab: 'regular',

  addToCartAfterCreation: false,
  productDetail: undefined,
}

export const openOverlayCreateMeasWithInitialization = createAsyncThunk(
  'overlayMeasurementMethodSelection/openOverlayWithInitialization', 
  async (payload: {
    clothingType: ClothingType,
    fitType: FitType,
    productDetail?: ProductOnUser,
    addToCartAfterCreation: boolean,
  }, thunkAPI) => {


  thunkAPI.dispatch(resetUserMeasurementDraft());
  const payload2: UserClothTypeSpecificBodyMeasurements = {
    clothingType: payload.clothingType,
    fitType: payload.fitType,
  }
  thunkAPI.dispatch(inputMeasurementsForm(payload2));

  thunkAPI.dispatch(openOverlay({ productDetail: payload.productDetail, addToCartAfterCreation: payload.addToCartAfterCreation }));

  return null;
});

export const overlayAccountViewSlice = createSlice({
  name: 'overlayMeasurementMethodSelection',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      console.log('Method selection closed')
      state.status = UIOverlayViewStatus.OffAndReady;
      state.currentTab = 'regular';
      state.currentScreen = "regularSize";
      state.previousScreen = undefined
      state.addToCartAfterCreation = false;
      state.productDetail = undefined;
    },

    closeOverlayButDoNotResetScreenState: (state) => {
      console.log('Method selection closed')
      state.status = UIOverlayViewStatus.OffAndReady;
      state.previousScreen = undefined
      state.addToCartAfterCreation = false;
      state.productDetail = undefined;
    },

    openOverlay: (state, action: PayloadAction<{
      addToCartAfterCreation: boolean,
      productDetail: ProductOnUser | undefined,
    }>) => {
      state.status = UIOverlayViewStatus.On;
      state.currentTab = 'regular';
      state.currentScreen = "regularSize";
      state.previousScreen = undefined
      state.addToCartAfterCreation = action.payload.addToCartAfterCreation;
      state.productDetail = action.payload.productDetail;
    },

    updateCurrentScreen: (state, action: PayloadAction<string>) => {
      state.previousScreen = state.currentScreen
      state.currentScreen = action.payload;
    },

    updateCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
  },
  extraReducers(builder) {
    builder
    .addCase(openOverlayCreateMeasWithInitialization.pending, (state, action) => {
    })
    .addCase(openOverlayCreateMeasWithInitialization.fulfilled, (state, action) => {
    })
    .addCase(openOverlayCreateMeasWithInitialization.rejected, (state, action) => {
    })
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, closeOverlayButDoNotResetScreenState, openOverlay,
  updateCurrentScreen, updateCurrentTab } = overlayAccountViewSlice.actions

export default overlayAccountViewSlice.reducer
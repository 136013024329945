import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import React, { useEffect, useState } from 'react';

import {
  Box, ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, Fade, IconButton, Slide, Tab, Tabs, Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Add } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import { updateUserTrigger } from '../redux/CartRedux';
import { closeOverlayAndReset, setViewedBodyMeas, updateCategoryIndex, updatePanelIndex } from '../redux/UIOverlayPersonalSizeRedux';
import { openOverlayCreateMeasWithInitialization as openUniversalSizeOverlay } from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FareastButton, FareastButtonWhite } from './OverlayComponentButton';
import CategoryTabs from './OverlayViewPersonalSizeCategoryTabs';
import PageElementProduct from './PageComponentElementProduct';
import SizeSelection from './PageProductDetailSizeSelection';
import { ClothingType, FitType } from 'common-ts/dist/models/UserMeasurements';
import SizeOptions, { UkuranBadanCardFull } from './PageProductDetailSizeOptions';
import ProductOnUser, { ProductSizeAvailabilityObject } from 'common-ts/dist/models/Product';
import { postClothingMeasurementPreference, saveLocallyClothingMeasurementPreference } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset as closeOverlayAndResetSelection, openOverlay as openOverlaySelection, setValues as setValuesSelection } from '../redux/UIOverlayMeasurementSelectionViewRedux';

import { motion, useAnimate, useMotionValue, useTransform } from 'framer-motion';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const CATEGORY_FADE_DURATION_MS = 600;
const CATEGORY_FADE_DELAY_MS = 200;
const CATEGORY_SLIDE_DURATION_MS = 500;
const CATEGORY_SLIDE_DELAY_MS = CATEGORY_FADE_DURATION_MS + CATEGORY_FADE_DELAY_MS;
const CATEGORY_FOOTER_TRANSITION_DELAY_MS = CATEGORY_SLIDE_DURATION_MS + CATEGORY_SLIDE_DELAY_MS;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    dialogContentContainer: {
      height: '100%',
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      height: '5.15rem',
      zIndex: theme.zIndex.appBar,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      padding: '0',
    },
    stickyCategoryTabs: {
      position: 'sticky',
      height: '3rem',
      top: '5.15rem',
      zIndex: theme.zIndex.appBar - 1,
      backgroundColor: theme.palette.background.paper,
    },
    headerRowOne: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `0.5px solid ${theme.palette.divider}`,
    },
    headerRowLeft: {
      whiteSpace: 'nowrap',
      paddingLeft: '5%',
      textAlign: 'left',
    },
    headerText: {
      fontWeight: 400,
      fontSize: '0.6em',
      color: 'black',
      fontFamily: 'Open sans, sans-serif',
    },
    headerRowRight: {
      whiteSpace: 'nowrap',
      textAlign: 'right',
      marginLeft: 'auto',
    },
    userName: {
      fontWeight: 600,
      color: '#121212',
      fontSize: '0.8em',
      paddingTop: '0.5em',
    },
    closeButton: {
      height: '100%',
      paddingRight: '1em',
    },
    headerRowTwo: {
      whitespace: 'nowrap',
      boxShadow: '0px 2.5px 10px rgba(0, 0, 0, 0.1)',
    },
    personalSizeTabs: {
      minHeight: 'auto',
    },
    personalSizeTab: {
      fontSize: '0.5em',
      minHeight: 'auto',
      padding: '0.8em',
    },
    personalSizeActiveTab: {
      fontWeight: 'bold',
    },
    personalSizePanel: {

    },
    existUniversalSizeDisplay: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // padding: '0em 1em',
    },
    existUniversalSizeCardContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    existUniversalSizeIntro: {
      fontWeight: 400,
      fontSize: '0.6em',
      color: 'black',
      fontFamily: 'Open sans, sans-serif',
      margin: '3em 0em 1em 0em',
    },
    existUniversalSizeIntro2: {
      fontWeight: 400,
      fontSize: '0.6em',
      color: 'black',
      fontFamily: 'Open sans, sans-serif',
      margin: '0em 0em 2em 0em',
    },
    existUniversalSizeCardImage: {
      marginTop: '-0.5em',
      width: '50%',
    },
    existUniversalSizeButtons: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '1em',
    },
    buatUkuranBaruButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5em',
      marginTop: '-0.2em',
      marginBottom: '0.2em',
    },
    existUniversalSizeTitle: {
      margin: '1em 0em',
      fontSize: '1em',
      fontWeight: 600,
    },
    universalSizeImages: {
      position: 'absolute',
      left: '0',
      right: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      top: '4em',
      width: '100%',
      height: '50%',
      overflow: 'hidden',
    },
    universalSizeLeftImg: {
      position: 'absolute',
      width: '85%',
      left: '-6.5em',
    },
    universalSizeCardImg: {
      width: '100%'
    },
    universalSizeRightImg: {
      position: 'absolute',
      width: '75%',
      top: '1em',
      right: '-4.5em',
    },
    universalSizeTextContent: {
      position: 'absolute',
      left: '0',
      right: '0',
      top: '26.5em',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
    },
    universalSizeSubtitle: {
      fontSize: '0.6em',
      fontWeight: 400,
      color: '#A7A7A7',
    },
    universalSizeTitle: {
      fontSize: '1.5em',
      width: '70%',
      fontWeight: 600,
      marginTop: '0.3em',
      marginBottom: '0.7em',
      lineHeight: '1.2em',
    },
    universalSizeDescription: {
      fontSize: '0.7em',
      fontWeight: 400,
      width: '90%',
      marginBottom: '1em',
    },
    universalSizeButton: {
      width: '100%',
    },
    categoryContent: {
      padding: '0 1em',
      marginBottom: '5rem',
    },
    misalignedProductsDisplay: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '0.6em',
      paddingBottom: "180px",
    },
    misalignedLeftProduct: {

    },
    misalignedRightProduct: {
      marginTop: '1.4em',
      marginBottom: '0.4em',
    },
    categoryFooter: {
      position: 'fixed',
      bottom: '0',
      width: '100%',
      zIndex: theme.zIndex.appBar,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
      padding: '0',
      boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
      visibility: 'hidden',
    },
    categoryFooterAnim: {
      position: 'fixed',
      bottom: '0',
      width: '100%',
      zIndex: theme.zIndex.appBar,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
      padding: '0',
      boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
      opacity: '0',
      transition: `opacity ${CATEGORY_FADE_DURATION_MS}ms ease ${CATEGORY_FADE_DELAY_MS}ms, transform ${CATEGORY_SLIDE_DURATION_MS}ms ease ${CATEGORY_SLIDE_DELAY_MS}ms`,
    },
    footerText: {
      fontWeight: 600,
      color: '#121212',
      fontSize: '0.6em',
      marginTop: '1em',
      marginBottom: '1em',
    },
    popupContainer: {
      zIndex: ((theme.zIndex.modal + 2) + " !important") as any,
      '& .MuiPaper-root': {
        width: "80vw",
        borderRadius: '40px',
      },
      '& .MuiBackdrop-root': {
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      },
    },
    popupTitle: {
      padding: '0.5em 0.5em 0 0.5em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    popupTitlePilihUkuranText: {
      fontSize: '12px',
      color: '#D1D1D1',
      fontWeight: 300,
      padding: '16px 0px 0px 18px'
    },
    popupCloseButton: {
      marginRight: '3px',
    },
    popupContent: {
      padding: '0 1em',
      marginBottom: '1em',
    },
    ukuranBadanOptionsBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    ukuranBadanOptionsIcon: {
      backgroundColor: '#232323',
      color: '#ffffff',
      borderRadius: '40px'
    },
    ukuranBadanOptionsText: {
      color: '#565656',
      fontSize: '9px',
      letterSpacing: '0.13px',
      marginTop: '10px'
    },
    ukuranBadanInfoIslandOption: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '30%',
      height: '60%',
      textAlign: 'center',
    },
    ukuranBadanInfoIslandOptionText: {
      color: '#565656',
      fontSize: '2vw',
      letterSpacing: '0.1vw',
    },
    ukuranBadanInfoIslandOptionSubtextHeightWeight: {
      color: '#565656',
      fontSize: '3.5vw',
      letterSpacing: '0.1vw',
      fontWeight: 500,
      paddingTop: '0.2vw',
    },
    ukuranBadanInfoIslandOptionSubtextBodyType: {
      color: '#565656',
      fontSize: '3vw',
      paddingTop: '0.6vw',
      letterSpacing: '0.1vw',
      fontWeight: 500,
    },
    ukuranBadanInfoIslandOptionSubtextBodyType2Line: {
      color: '#565656',
      fontSize: '2.3vw',
      letterSpacing: '0.07vw',
      fontWeight: 500,
      marginTop: '-0.1vw',
    },
  }),
);

const UpwardTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

interface PersonalSizePanelProps {
  children?: React.ReactNode;
  height?: number;
  index: number;
  value: number;
}

function PersonalSizePanel(props: PersonalSizePanelProps) {
  const classes = useStyles();
  const { children, height, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.personalSizePanel}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

export function getSortKey(productDetail: ProductOnUser, productSizeAvailability: ProductSizeAvailabilityObject | undefined) {
  const valueKey = (productDetail.isSoldOut || productDetail.isComingAgainSoon || productSizeAvailability?.numberOfItemsRemaining === 0 ? "B" : "A") + (productDetail.productBackendVersion === 'v4.0' ? "A" : "B") + (productDetail.productId);
  return valueKey;
}

export const itemCategoriesMainText = ["Kaos", "Kaos", "Polo"];
export const itemCategoriesMainText2 = ["", "", ""];
export const itemCategoriesSubtext = ["Slim Fit", "Regular Fit", ""];
export const itemCategoriesClothingType = [ClothingType.Kaos, ClothingType.Kaos, ClothingType.PoloShirt];
export const itemCategoriesFitType = [FitType.SlimFit, FitType.RegularFit, FitType.RegularFit];

export default function OverlayViewPersonalSize() {
  const overlayView = useAppSelector(state => state.overlayPersonalSize);
  const userAccount = useAppSelector(state => state.account);
  const userIsSignedInAndHasPurchased = useAppSelector(state => state.account.authState === 'signedin' && state.ordersList.paidOrderCount >= 1);
  const productsDictionary = useAppSelector(state => state.productsList.productsDictionary);
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);

  const panelTabIndex = useAppSelector(state => state.overlayPersonalSize.panelTabIndex);
  const categoryTabIndex = useAppSelector(state => state.overlayPersonalSize.categoryTabIndex);

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [showCategoryContent, setShowCategoryContent] = useState(false);

  const currentViewedUkuranBadanCreationDateTime = overlayView.currentViewedBodyMeasCreationDateTime;

  // Animations using framer motion
  const animPos = useMotionValue(0); // This is in px
  const [triggerAnimationToggle, triggerFullAnimations] = useState(0);
  const [scope, animate] = useAnimate();

  const { width: windowWidth } = getWindowDimensions();

  const positionValues = [-1 * windowWidth, -1 * 0.5 * windowWidth, 0, 0.5 * windowWidth, windowWidth];
  const positionValues1 = positionValues.map(value => value - positionValues[1]);
  const positionValues3 = positionValues.map(value => value - positionValues[3]);

  const leftValues = [-76, -16, 30, 76, 136]; // In vw
  const topValues = [10, 10, 3, 10, 10]; // In vw
  const scaleValues = [1, 1, 1.25, 1, 1]; // In vw

  const leftCardLeftVal = useTransform(animPos, positionValues1, leftValues);
  const leftCardTopVal = useTransform(animPos, positionValues1, topValues);
  const leftCardScaleVal = useTransform(animPos, positionValues1, scaleValues);

  const centerCardLeftVal = useTransform(animPos, positionValues, leftValues);
  const centerCardTopVal = useTransform(animPos, positionValues, topValues);
  const centerCardScaleVal = useTransform(animPos, positionValues, scaleValues);

  const rightCardLeftVal = useTransform(animPos, positionValues3, leftValues);
  const rightCardTopVal = useTransform(animPos, positionValues3, topValues);
  const rightCardScaleVal = useTransform(animPos, positionValues3, scaleValues);


  const userPrefDict = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsPreferenceDictionary);
  const userBodyMeasurementsDictionary = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  let userBodyMeasurementCreationDateTimeList: string[] = Object.keys(userBodyMeasurementsDictionary).sort();
  const existUniversalSize = userBodyMeasurementCreationDateTimeList.length > 0;

  const selectedUkuranBadanCreationDateTime = existUniversalSize ? 
    (userPrefDict?.["BODY"] ? userPrefDict["BODY"].substring(5) : userBodyMeasurementCreationDateTimeList[(userBodyMeasurementCreationDateTimeList.length - 1)]) : 
    undefined;

  const currentViewedUkuranBadan = currentViewedUkuranBadanCreationDateTime ? 
    userBodyMeasurementsDictionary?.[currentViewedUkuranBadanCreationDateTime]?.[0] : undefined;
  const currentViewedUkuranBadanIndex = userBodyMeasurementCreationDateTimeList.findIndex((value) => value === currentViewedUkuranBadanCreationDateTime);

  const key2ProductIdArray: string[][] = [];
  const key2ProductIdArrayLengths: number[] = [];

  if (productsDictionary) {
    const startingProductIds = Object.keys(productsDictionary)
    .sort((a, b) => getSortKey(productsDictionary[a], productSizeAvailabilityDict?.[a]) > getSortKey(productsDictionary[b], productSizeAvailabilityDict?.[b]) ? 1 : -1)

    for (var i = 0; i < itemCategoriesMainText.length; i += 1) {
      const clothingTypeCurrent = itemCategoriesClothingType[i];
      const fitTypeCurrent = itemCategoriesFitType[i];
      const filteredArray = startingProductIds.filter((productId) => productsDictionary[productId].clothingType === clothingTypeCurrent && productsDictionary[productId].defaultFitType === fitTypeCurrent);
      key2ProductIdArray.push(filteredArray);
      key2ProductIdArrayLengths.push(filteredArray.length);
    }
  }

  const clothingType: ClothingType = itemCategoriesClothingType[categoryTabIndex];
  const fitType: FitType = itemCategoriesFitType[categoryTabIndex];

  const canClickLeft = currentViewedUkuranBadanIndex !== -1 && currentViewedUkuranBadanIndex > 0;
  const canClickRight = currentViewedUkuranBadanIndex !== -1 && currentViewedUkuranBadanIndex < userBodyMeasurementCreationDateTimeList.length - 1;
  function handleClickUkuranBadanLeft() {
    if (currentViewedUkuranBadanIndex !== -1 && currentViewedUkuranBadanIndex > 0) {
      dispatch(setViewedBodyMeas(userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex - 1]));
    }
    animPos.set(0);
    triggerFullAnimations(triggerAnimationToggle === 1 ? 0 : 1);
  };
  function handleClickUkuranBadanRight() {
    if (currentViewedUkuranBadanIndex !== -1 && currentViewedUkuranBadanIndex < userBodyMeasurementCreationDateTimeList.length - 1) {
      dispatch(setViewedBodyMeas(userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex + 1]));
    }
    animPos.set(0);
    triggerFullAnimations(triggerAnimationToggle === 1 ? 0 : 1);
  }


  const handleChangePanelTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(updatePanelIndex(newValue));
    handleCategoryChange(0);
  };

  const handleCategoryChange = (newCategoryIndex: number) => {
    dispatch(updateCategoryIndex(newCategoryIndex));

    setTimeout(() => {
      // Scroll the page back to top
      const misalignedProductsDisplays = document.getElementsByClassName("empty-header-scroll");
      for (let ind = 0; ind < misalignedProductsDisplays.length; ind += 1) {
        let misalignedProductsDisplay = misalignedProductsDisplays[ind] as HTMLElement;
        misalignedProductsDisplay.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    }, 300);
  }

  const togglePilihUkuranPopup = () => {
    dispatch(closeOverlayAndResetSelection());
    dispatch(setValuesSelection({ // Doesn't include product detail (generalized)
      clothingType: clothingType,
      fitType: fitType,
      isFullScreen: false,
    }))
    dispatch(openOverlaySelection());
  }

  if (!currentViewedUkuranBadanCreationDateTime && selectedUkuranBadanCreationDateTime) {
    dispatch(setViewedBodyMeas(selectedUkuranBadanCreationDateTime));

    // After assigning, we trigger the "centering" function 1 s later after the cards are up
    setTimeout(() => {
      triggerFullAnimations(triggerAnimationToggle === 1 ? 0 : 1);
    }, 1000);
  }

  useEffect(() => {
    // Configure the entrance animation for the category panel
    if (panelTabIndex === 1) {
      // Fade in the footer and slide it to the bottom of the page
      let footer = document.getElementById('category-footer-anim');
      footer = footer as HTMLElement;
      if (footer) {
        const footerHeight = footer ? footer.clientHeight : 0;
        const viewportHeight = window.innerHeight;
        const initialTopOffset = 8.15 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        footer.style.opacity = '1';
        footer.style.top = initialTopOffset + 'px';
        footer.style.bottom = 'auto';
        footer.style.transform = `translateY(${viewportHeight - footerHeight - initialTopOffset}px)`;
        setTimeout(() => {
          let footer = document.getElementById('category-footer-anim');
          footer = footer as HTMLElement;
          if (footer) {
            footer.style.display = 'none';
          }
          let footer2 = document.getElementById('category-footer');
          footer2 = footer2 as HTMLElement;
          if (footer2) {
            footer2.style.visibility = 'visible';
          }
        }, 3000);
      }

      // Ensure category content Slide animation is triggered
      // after the footer animation is done
      const delayTimeout = setTimeout(() => {
        setShowCategoryContent(true);
      }, CATEGORY_FOOTER_TRANSITION_DELAY_MS);

      return () => {
        clearTimeout(delayTimeout);
      };
    } else {
      setShowCategoryContent(false);
    }
  }, [panelTabIndex]);

  useEffect(() => {
    if (scope.current) {
      const userMeasLeftHidKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex - 2] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex - 2] : undefined;
      const userMeasLeftKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex - 1] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex - 1] : undefined;
      const userMeasCenterKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex] : undefined;
      const userMeasRightKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex + 1] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex + 1] : undefined;
      const userMeasRightHidKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex + 2] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex + 2] : undefined;
      

      if (userMeasLeftHidKey) {
        const selectedAnimEl = document.getElementById(userMeasLeftHidKey);
        if (selectedAnimEl) {
          animate(selectedAnimEl, { 
            left: '-100vw',
            top: '10vw',
            scale: 1,
            opacity: 0,
          });
        }
      }
      if (userMeasLeftKey) {
        const selectedAnimEl = document.getElementById(userMeasLeftKey);
        if (selectedAnimEl) {
          animate(selectedAnimEl, { 
            left: leftCardLeftVal.get() + 'vw',
            top: leftCardTopVal.get() + 'vw',
            scale: leftCardScaleVal.get(),
            opacity: 1,
          });
        }
      }
      if (userMeasCenterKey) {
        const selectedAnimEl = document.getElementById(userMeasCenterKey);
        if (selectedAnimEl) {
          const newValues: any = { 
            left: centerCardLeftVal.get() + 'vw',
            top: centerCardTopVal.get() + 'vw',
            scale: centerCardScaleVal.get(),
            opacity: 1,
          };
          animate(selectedAnimEl, newValues);
        }
      }
      if (userMeasRightKey) {
        const selectedAnimEl = document.getElementById(userMeasRightKey);
        if (selectedAnimEl) {
          animate(selectedAnimEl, { 
            left: rightCardLeftVal.get() + 'vw',
            top: rightCardTopVal.get() + 'vw',
            scale: rightCardScaleVal.get(),
            opacity: 1,
          });
        }
      }
      if (userMeasRightHidKey) {
        const selectedAnimEl = document.getElementById(userMeasRightHidKey);
        if (selectedAnimEl) {
          animate(selectedAnimEl, { 
            left: '150vw',
            top: '10vw',
            scale: 1,
            opacity: 0,
          });
        }
      }
    }
  }, [triggerAnimationToggle, overlayView]);

  function closeOverlayAndTurnOffUserTrigger() {
    dispatch(closeOverlayAndReset());
    dispatch(updateUserTrigger(false));
  }

  if (!userIsSignedInAndHasPurchased) {
    console.log('Not signed in or haven\'t bought an item');
    dispatch(closeOverlayAndReset());
    return null;
  }

  return (
    <Dialog
      fullScreen
      maxWidth="lg"
      open={overlayView.status === UIOverlayViewStatus.On}
      TransitionComponent={UpwardTransition}
      keepMounted
      className={classes.dialogContainer}>
      <DialogTitle className={classes.stickyHeader}>
        <Box className={classes.headerRowOne}>
          <Box className={classes.headerRowLeft}>
            <Typography className={classes.headerText}>
              PERSONAL PROFILE MILIK
            </Typography>
          </Box>
          <Box className={classes.headerRowRight}>
            <Typography className={classes.userName}>
              {userAccount.name.split(' ')[0].toUpperCase()}
            </Typography>
          </Box>
          <Box className={classes.closeButton}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => closeOverlayAndTurnOffUserTrigger()}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>

        <Box className={classes.headerRowTwo}>
          <Tabs
            value={panelTabIndex}
            onChange={handleChangePanelTab}
            variant="fullWidth"
            className={classes.personalSizeTabs}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="UKURAN BADAN" className={`${classes.personalSizeTab}
              ${panelTabIndex === 0 ? classes.personalSizeActiveTab : ''}`} />
            <Tab label="UKURAN TIAP KATEGORI" className={`${classes.personalSizeTab}
              ${panelTabIndex === 1 ? classes.personalSizeActiveTab : ''}`} />
          </Tabs>
        </Box>
      </DialogTitle>

      <Box className={classes.dialogContentContainer}>
        {/* Universal Size Tab */}
        <PersonalSizePanel value={panelTabIndex} index={0}>
          <Box className={"empty-header-scroll"}/>
          {
            existUniversalSize ?
              <Box className={classes.existUniversalSizeDisplay}>
                <Box key={'ukuran-badan-header'} style={{
                  height: '56px',
                  width: '100%',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Typography style={{
                    fontSize: '11px',
                    letterSpacing: '0.8px',
                    // color: '#9e9e9e',
                    paddingTop: '3px'
                  }}>
                    UKURAN BADAN KAMU
                  </Typography>
                  <Box style={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                    onClick={() => dispatch(openUniversalSizeOverlay())}
                  >
                    <ButtonBase
                      style={{
                        backgroundColor: '#232323',
                        color: '#ffffff',
                        padding: '4px',
                        borderRadius: '40px'
                      }}>
                      <AddIcon />
                    </ButtonBase>
                    <Typography 
                      style={{
                        color: '#565656',
                        fontSize: '9px',
                        letterSpacing: '0.13px',
                        marginTop: '8px'
                      }}>
                      Buat Baru
                    </Typography>
                  </Box>
                </Box>
                <motion.div ref={scope}>
                <Box key={'ukuran-badan-present-slider'}
                  style={{
                    position: 'relative',
                    width: '100vw',
                    marginTop: '10px',
                    height: '75vw',
                    overflowX: 'clip',
                  }}>
                  {
                    userBodyMeasurementCreationDateTimeList.map((creationDateTime, index) => {

                      if (index < currentViewedUkuranBadanIndex - 2 || index > currentViewedUkuranBadanIndex + 2) {
                        return null;
                      }

                      const userCurrentMeas = userBodyMeasurementsDictionary?.[creationDateTime]?.[0];
                      if (userCurrentMeas === undefined) {
                        return null;
                      }

                      if (currentViewedUkuranBadanIndex === -1) {
                        return null;
                      }

                      const userMeasLeftHidKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex - 2] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex - 2] : undefined;
                      const userMeasLeftKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex - 1] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex - 1] : undefined;
                      const userMeasCenterKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex] : undefined;
                      const userMeasRightKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex + 1] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex + 1] : undefined;
                      const userMeasRightHidKey = currentViewedUkuranBadanIndex !== -1 && userBodyMeasurementCreationDateTimeList?.[currentViewedUkuranBadanIndex + 2] ? userBodyMeasurementCreationDateTimeList[currentViewedUkuranBadanIndex + 2] : undefined;

                      const userCurrentMeasKey = 
                        index === currentViewedUkuranBadanIndex - 2 ? userMeasLeftHidKey:
                        index === currentViewedUkuranBadanIndex - 1 ? userMeasLeftKey :
                        index === currentViewedUkuranBadanIndex ? userMeasCenterKey :
                        index === currentViewedUkuranBadanIndex + 1 ? userMeasRightKey :
                        index === currentViewedUkuranBadanIndex + 2 ? userMeasRightHidKey :
                        undefined;

                      const opacityValue = 
                        index === currentViewedUkuranBadanIndex - 2 ? 0:
                        index === currentViewedUkuranBadanIndex - 1 ? 1 :
                        index === currentViewedUkuranBadanIndex ? 1 :
                        index === currentViewedUkuranBadanIndex + 1 ? 1 :
                        index === currentViewedUkuranBadanIndex + 2 ? 0 :
                        0;

                      return (
                      <motion.div
                        style={{
                            width: '40vw',
                            position: 'absolute',
                            opacity: opacityValue,
                            left: userCurrentMeasKey === undefined ? (index < currentViewedUkuranBadanIndex ? '-150vw' : '150vw') : undefined, // Put out of screen
                            x: index === currentViewedUkuranBadanIndex ? animPos : 
                            index === currentViewedUkuranBadanIndex - 1 ? animPos :
                            index === currentViewedUkuranBadanIndex + 1 ? animPos :
                            undefined,
                          }}
                        onClick={index === currentViewedUkuranBadanIndex - 1 ? handleClickUkuranBadanLeft :
                          index === currentViewedUkuranBadanIndex + 1 ? handleClickUkuranBadanRight : () => {}}
                        id={userCurrentMeasKey}
                        drag={index === currentViewedUkuranBadanIndex ? 'x' : undefined}
                        dragMomentum={false}
                        onDrag={(event, info) => {
                          // if (index === currentViewedUkuranBadanIndex) {
                          //   triggerFullAnimations(animPos.get());
                          // }
                        }}
                        onDragEnd={(event, info) => {
                          const animPosCur = animPos.get();
                          if (animPosCur > 0.15 * windowWidth) {
                            handleClickUkuranBadanLeft();
                          } else if (animPosCur < -0.15 * windowWidth) {
                            handleClickUkuranBadanRight();
                          } else {
                            // animate(animPos, 0, {duration: 0.5});
                            animPos.set(0);
                            triggerFullAnimations(triggerAnimationToggle === 1 ? 0 : 1);
                          }
                        }}
                        >
                          <UkuranBadanCardFull 
                          nama={userCurrentMeas?.nama ? userCurrentMeas.nama : 'User'}
                          height={userCurrentMeas?.height ? userCurrentMeas.height + ' cm' : '175 cm'}
                          weight={userCurrentMeas?.weight ? userCurrentMeas.weight + ' kg' : '75 kg'}
                          bodyType={userCurrentMeas?.bodyType ? (
                            userCurrentMeas.bodyType === 'upright_triangle' ? 'Upright Triangle' :
                            userCurrentMeas.bodyType === 'inverted_triangle' ? 'Inverted Triangle' : 'Rectangle') : 'Rectangle'}
                          dibuatPada={userCurrentMeas?.creationDateTime ? 
                            new Date(userCurrentMeas.creationDateTime).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'}):
                            '-'}  
                          width={'40vw'}/>
                        </motion.div>);
                    })
                  }
                </Box>
                </motion.div>
                <Box key={'ukuran-badan-footer'} style={{
                  position: 'fixed',
                  bottom: '0px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px 0px',
                    width: '100%',
                  }}>
                    <IconButton
                      disabled={!canClickLeft}
                      onClick={handleClickUkuranBadanLeft}>
                      <ArrowLeft 
                      style={{
                        color: currentViewedUkuranBadanIndex === 0  || currentViewedUkuranBadanIndex === -1 ? '#efefef' : '#121212'
                      }}
                      />
                    </IconButton>
                    <Box style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                      <Typography style={{
                        fontSize: '10px',
                        letterSpacing: '1px',
                        color: '#232323',
                        fontWeight: 300,
                      }}>
                        UKURAN BADAN
                      </Typography>
                      <Typography style={{
                        fontSize: '40px',
                        color: '#232323',
                        fontWeight: 500,
                      }}>
                        {currentViewedUkuranBadan?.nama ? currentViewedUkuranBadan.nama : '-'}
                      </Typography>
                      <Typography style={{
                        fontSize: '9px',
                        letterSpacing: '0.3px',
                        color: '#232323',
                        fontWeight: 300,
                      }}>
                        Dibuat Pada {currentViewedUkuranBadanCreationDateTime ? 
                        new Date(currentViewedUkuranBadanCreationDateTime).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'}):
                        '-'}
                      </Typography>
                    </Box>
                    <IconButton
                      disabled={!canClickRight}
                      onClick={handleClickUkuranBadanRight}>
                      <ArrowRight
                      style={{
                        color: currentViewedUkuranBadanIndex === userBodyMeasurementCreationDateTimeList.length - 1  || 
                        currentViewedUkuranBadanIndex === -1 ? '#efefef' : '#121212'
                      }}
                      />
                    </IconButton>
                  </Box>
                  <Box key={'ukuran-badan-info-island'} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '70vw',
                    height: '13vw', // 13 to 70
                    position: 'relative',
                  }}>
                    <Box className={classes.ukuranBadanInfoIslandOption}>
                      <Typography className={classes.ukuranBadanInfoIslandOptionText}>
                        TINGGI BADAN
                      </Typography>
                      <Typography className={classes.ukuranBadanInfoIslandOptionSubtextHeightWeight}>
                        {currentViewedUkuranBadan?.height ? currentViewedUkuranBadan.height : '175'} cm
                      </Typography>
                    </Box>
                    <Box className={classes.ukuranBadanInfoIslandOption}>
                      <Typography className={classes.ukuranBadanInfoIslandOptionText}>
                        BERAT BADAN
                      </Typography>
                      <Typography className={classes.ukuranBadanInfoIslandOptionSubtextHeightWeight}>
                        {currentViewedUkuranBadan?.weight ? currentViewedUkuranBadan.weight : '75'} cm
                      </Typography>
                    </Box>
                    <Box className={classes.ukuranBadanInfoIslandOption}>
                      <Typography className={classes.ukuranBadanInfoIslandOptionText}>
                        BODY TYPE
                      </Typography>
                      {
                        currentViewedUkuranBadan?.bodyType === 'upright_triangle' ?
                        <>
                          <Typography className={classes.ukuranBadanInfoIslandOptionSubtextBodyType2Line}>
                            Upright
                          </Typography>
                          <Typography className={classes.ukuranBadanInfoIslandOptionSubtextBodyType2Line}>
                            Triangle
                          </Typography>
                        </>:
                        currentViewedUkuranBadan?.bodyType === 'inverted_triangle' ?
                        <>
                          <Typography className={classes.ukuranBadanInfoIslandOptionSubtextBodyType2Line}>
                            Inverted
                          </Typography>
                          <Typography className={classes.ukuranBadanInfoIslandOptionSubtextBodyType2Line}>
                            Triangle
                          </Typography>
                        </>:
                        <Typography className={classes.ukuranBadanInfoIslandOptionSubtextBodyType}>
                          Rectangle
                        </Typography>
                      }
                    </Box>
                    <img 
                    src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-island-bg-2.png"} 
                    alt="measkit-img"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      position: 'absolute',
                      top: '0px',
                      left: '0px',
                      zIndex: -1
                    }}/>
                  </Box>
                  <Box key={'ukuran-badan-options'} style={{
                    padding: '20px 0px 40px 0px',
                    width: '70vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <Box className={classes.ukuranBadanOptionsBox} 
                      // onClick={() => dispatch(openUniversalSizeOverlay())}
                      >
                      <ButtonBase className={classes.ukuranBadanOptionsIcon} 
                      style={{
                        backgroundColor: true ? '#b9b9b9' : '#232323'
                      }}>
                        <img src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-icon-visibility.svg"}
                        alt={'icon'}
                        style={{ padding: '9px' }}/>
                      </ButtonBase>
                      <Typography className={classes.ukuranBadanOptionsText}
                      style={{
                        color: true ? '#b9b9b9' : '#232323'
                      }}>
                        Detail Ukuran
                      </Typography>
                    </Box>
                    <Box className={classes.ukuranBadanOptionsBox} 
                      // onClick={() => dispatch(openUniversalSizeOverlay())}
                      >
                      <ButtonBase className={classes.ukuranBadanOptionsIcon}
                      style={{
                        backgroundColor: true ? '#b9b9b9' : '#232323'
                      }}>
                        <img src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-icon-palette.svg"}
                        alt={'icon'}
                        style={{ padding: '9px' }}/>
                      </ButtonBase>
                      <Typography className={classes.ukuranBadanOptionsText}
                      style={{
                        color: true ? '#b9b9b9' : '#232323'
                      }}>
                        Style Kartu
                      </Typography>
                    </Box>
                    <Box className={classes.ukuranBadanOptionsBox} 
                      onClick={() => {
                        if (selectedUkuranBadanCreationDateTime !== currentViewedUkuranBadanCreationDateTime) {
                          dispatch(saveLocallyClothingMeasurementPreference({
                            key2: "BODY",
                            creationDateTime: 'body-' + currentViewedUkuranBadanCreationDateTime,
                          }));
                          dispatch(postClothingMeasurementPreference({
                            key2: "BODY",
                            creationDateTime: 'body-' + currentViewedUkuranBadanCreationDateTime
                          }));
                        }
                      }}>
                      <ButtonBase className={classes.ukuranBadanOptionsIcon}
                      style={{
                        backgroundColor: selectedUkuranBadanCreationDateTime === currentViewedUkuranBadanCreationDateTime ? '#b9b9b9' : '#232323'
                      }}>
                        <img src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-icon-check.svg"}
                        alt={'icon'}
                        style={{ padding: '9px' }}/>
                      </ButtonBase>
                      <Typography className={classes.ukuranBadanOptionsText}
                      style={{
                        color: selectedUkuranBadanCreationDateTime === currentViewedUkuranBadanCreationDateTime ? '#b9b9b9' : '#232323'
                      }}>
                        Pakai Ukuran
                      </Typography>
                    </Box>
                  </Box>
                  <img 
                    src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/ukuran-badan-detail-bg.jpg"} 
                    alt="measkit-img"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      position: 'absolute',
                      top: '0px',
                      left: '0px',
                      zIndex: -2
                    }}/>
                </Box>
              </Box>
              :
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}>
                <Box style={{
                  position: 'absolute',
                  top: '150vw',
                  width: '100%',
                  backgroundColor: '#090F16',
                  height: '30vw',
                }}/>
                <img
                  className={classes.universalSizeCardImg}
                  src={'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/personal-size-landing-1.jpg'}
                  alt='left holo' />
                <Box style={{
                  width: '100%',
                  height: '28vw',
                }}/>
                <img
                  className={classes.universalSizeCardImg}
                  src={'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/personal-size-landing-2.jpg'}
                  alt='left holo 2' />
                <Button 
                  onClick={() => {
                    dispatch(openUniversalSizeOverlay());
                  }}
                  style={{
                    position: 'absolute',
                    top: '148vw',
                    width: '80%',
                    backgroundColor: '#090F16',
                    color: '#FFFFFF',
                    borderRadius: '30px',
                    border: '1px solid #FFFFFF',
                    paddingTop: "10px",
                    paddingBottom: "8px",
                  }}>
                  BUAT UKURAN BADAN
                </Button>
                <Button 
                  onClick={() => {
                    dispatch(openUniversalSizeOverlay());
                  }}
                  style={{
                    position: 'absolute',
                    top: '308vw',
                    width: '80%',
                    backgroundColor: '#090F16',
                    color: '#FFFFFF',
                    borderRadius: '30px',
                    border: '1px solid #FFFFFF',
                    paddingTop: "10px",
                    paddingBottom: "8px",
                  }}>
                  BUAT UKURAN BADAN
                </Button>
              </Box>
          }
        </PersonalSizePanel>

        {/* Ukuran Tiap Kategori Tab */}
        <PersonalSizePanel value={panelTabIndex} index={1}>
          <Box className={classes.stickyCategoryTabs}>
            <CategoryTabs 
              currentCategoryValue={categoryTabIndex}
              categoriesMainText={itemCategoriesMainText} 
              categoriesMainText2={itemCategoriesMainText2} 
              categoriesSubtext={itemCategoriesSubtext} 
              categoriesProductAmount={key2ProductIdArrayLengths}
              onCategoryChange={handleCategoryChange} />
          </Box>
          <Fade
            timeout={600}
            in={showCategoryContent && panelTabIndex === 1}
          >
            <Box className={classes.categoryContent}>
              <Box className={"empty-header-scroll"}/>
              {itemCategoriesMainText.map((category, index) => (
                <PersonalSizePanel value={categoryTabIndex} index={index}>
                  <Box className={classes.misalignedProductsDisplay} style={{ marginTop: "20px" }}>
                    {key2ProductIdArray?.[index]?.map((productId, index2) => { 
                      let productItem = productsDictionary[productId];
                      let boxClass = index2 % 2 === 0 ? classes.misalignedLeftProduct : classes.misalignedRightProduct;
                      return (
                        <Box className={boxClass} onClick={closeOverlayAndTurnOffUserTrigger}>
                          <PageElementProduct
                            product_id={productItem.productId}
                            image_url={productItem.displayImage}
                            image_url_hover={productItem.displayImageHover}
                            title={productItem.name}
                            discountedPrice={productItem.discountedPrice}
                            originalPrice={productItem.originalPrice}
                            isSoldOut={productItem.isSoldOut}
                            isComingAgainSoon={productItem.isComingAgainSoon}
                            product_url={`/shop/product/` + productItem.productId}
                            padding='0px 0px 0px 0px'
                            margin='0px 0px 0px 0px'
                          />
                        </Box>
                      )
                    })}
                  </Box>
                </PersonalSizePanel>
              ))}
            </Box>
          </Fade>
          <Box
            id='category-footer'
            className={classes.categoryFooter}
          >
            <Box style={{margin: '0.5em 1em 0.5em 1em'}}>
              <SizeSelection clothingType={clothingType} fitType={fitType} 
                handleClick={() => {}} 
                headerElement=
                {
                  <Typography className={classes.footerText}>SEMUA MENGGUNAKAN UKURAN</Typography>
                }
                noMeasAlternativeElement=
                {
                <>
                  <Typography className={classes.footerText}>SEMUA MENGGUNAKAN UKURAN</Typography>
                  <FareastButton
                    fullWidth
                    buttonMarginLeftRight='0px'
                    buttonMarginTopBottom='0px'
                    onClick={togglePilihUkuranPopup}
                  >
                    PILIH UKURAN
                  </FareastButton>
                </>
                }/>
            </Box>
          </Box>
          <Box
            id='category-footer-anim'
            className={classes.categoryFooterAnim}
          >
            <Box style={{margin: '0.5em 1em 0.5em 1em'}}>
              <SizeSelection clothingType={clothingType} fitType={fitType} 
                handleClick={() => {}} 
                headerElement=
                {
                  <Typography className={classes.footerText}>SEMUA MENGGUNAKAN UKURAN</Typography>
                }
                noMeasAlternativeElement=
                {
                <>
                  <Typography className={classes.footerText}>SEMUA MENGGUNAKAN UKURAN</Typography>
                  <FareastButton
                    fullWidth
                    buttonMarginLeftRight='0px'
                    buttonMarginTopBottom='0px'
                    onClick={togglePilihUkuranPopup}
                  >
                    PILIH UKURAN
                  </FareastButton>
                </>
                }/>
            </Box>
          </Box>
        </PersonalSizePanel>
      </Box>
    </Dialog>
  );
}
export var multiDescriptionDataExample = [
    {
        tabTitle: 'DESKRIPSI',
        tabType: 'DESCRIPTION',
        tabData: [
            'Not your regular polo shirt. Kali ini kita ingin membuat polo shirt yang berbeda dari polo shirt buttons pada umumnya mmenggunakan zipper dengan modern feel. Polo shirt ini bisa banget dipakai untuk acara formal maupun acara informal.',
            [
                'Bahan terbuat dari cavalry twill, mudah menyerap keringat tahan seharian.',
                'Hidden button buat kerah tetap solid seharian, tidak berubah bentuk untuk kamu yang aktif.',
                'Serat yang padat, tahan lama dan nyaman dipakai seharian.',
                'Kain tidak mudah luntur',
            ]
        ],
    },
    {
        tabTitle: 'MATERIAL',
        tabType: 'DETAILLIST',
        tabData: [
            {
                title: 'Bahan Serat',
                text: '100% Katun'
            },
            {
                title: 'Base Construction',
                text: 'Double Thread Overlock'
            },
            {
                title: 'Tipe Konstruksi',
                text: 'Fabric Knit 40S Pattern Waffle'
            },
            {
                title: 'Label',
                text: '40S High Density Damask with DTF Printed Name'
            },
        ]
    },
    {
        tabTitle: 'KONSTRUKSI',
        tabType: 'DETAILLIST',
        tabData: [
            {
                title: 'Bahan Serat',
                text: '100% Katun'
            },
            {
                title: '',
                text: ''
            },
            {
                title: 'Tipe Konstruksi',
                text: 'Fabric Knit 40S Pattern Waffle'
            },
            {
                title: '',
                text: ''
            },
            {
                title: 'Base Construction',
                text: 'Double Thread Overlock'
            },
        ],
    }
];
export var ProductCategory;
(function (ProductCategory) {
    ProductCategory["basic-tshirt"] = "basic-tshirt";
    ProductCategory["design-tshirt"] = "design-tshirt";
    ProductCategory["polo-shirt"] = "polo-shirt";
    ProductCategory["basic-shirt"] = "basic-shirt";
    ProductCategory["sweater"] = "sweater";
    ProductCategory["other-atasan"] = "other-atasan";
    ProductCategory["all"] = "all";
})(ProductCategory || (ProductCategory = {}));
export var ProductCategoryDisplayName = {
    "basic-tshirt": "Basic Tee",
    "design-tshirt": "Design T-Shirts",
    "polo-shirt": "Polo Shirt",
    "basic-shirt": "Kemeja",
    "sweater": "Sweater",
    "other-atasan": "Atasan Lainnya",
    "all": "SEMUA PRODUK"
};

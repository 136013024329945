import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OverlayOngkirState {
  status: UIOverlayViewStatus,
}

// Define the initial state using that type
const initialState: OverlayOngkirState = {
  status: UIOverlayViewStatus.OffAndReady,
}

export const overlayOngkirSlice = createSlice({
  name: 'overlayOngkir',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay } = overlayOngkirSlice.actions

export default overlayOngkirSlice.reducer
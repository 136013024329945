import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { SetStateAction } from 'react';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import { Link as RouterLink } from "react-router-dom";
import { fetchProductsList } from '../redux/ProductsListRedux';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paymentContent: {
    height: '100%',
    padding: '0px 24px',
    marginTop: '-10px',
  },
  subFormTitle: {
    marginTop: '28px',
    marginBottom: '20px',
  },
  paymentPortalButton: {
    marginTop: '20px',
  },
  formNavButtonsContainer: {
    height: 'auto',
    width: '100%',
    right: '0px',
    display: 'flex',
    justifyContent: 'right',
  },
  formNavButtons: {
    marginTop: '20px',
    marginRight: '18px',
    marginBottom: '24px',
  },
}),
);

interface PageCheckoutProps {
  checkoutStep: string,
  setCheckoutStep: React.Dispatch<SetStateAction<string>>,
  rejectionMessage: string,
}

export default function PageCheckoutForms({ checkoutStep, setCheckoutStep, rejectionMessage }: PageCheckoutProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  return (
    <div className={classes.paymentContent}>
      <Typography variant="h5" className={classes.subFormTitle}>
        Your order has been rejected.
      </Typography>
      <Typography>
        {rejectionMessage}
      </Typography>
      <div className={classes.formNavButtonsContainer}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={categoryMap["HOME"]}
          className={classes.formNavButtons}
          onClick={() => dispatch(fetchProductsList({})) }
        >
          Go back to main page
        </Button>
      </div>
    </div>
  );
}
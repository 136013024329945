import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import React from 'react';
import PageAccountYourAccount from './PageAccountYourAccount';
import PageAccountSignIn from './PageAccountSignIn';
import PageAccountSignUp from './PageAccountSignUp';
import PageAccountSignUpConfirm from './PageAccountSignUpConfirm';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { Box } from '@material-ui/core';
import { SignUpSignInLink } from './OverlayViewAccount';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signInSignUpPage: {
      height: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    signUpSignInContainer: {
      paddingTop: '80px',
      paddingBottom: '80px',
      [theme.breakpoints.down('md')]: {
        paddingTop: '20px',
        paddingBottom: '120px',
      },
    }
  }),
);

export default function PageAccount() {
  const authState = useAppSelector(state => state.account.authState);
  const [isSignIn, setIsSignIn] = React.useState<boolean>(true);
  const [signInPhoneNumber, setSignInPhoneNumber] = React.useState<string>('');
  const classes = useStyles();

  if (authState === 'signedin') {
    return (
      <PageAccountYourAccount />
    );
  } else {
    return (
      <div className={classes.signInSignUpPage}>
        <Box className={classes.signUpSignInContainer}>
          {
            isSignIn ? 
            <PageAccountSignIn phoneNumber={signInPhoneNumber} 
            changeToSignUpWithPhoneNumber={(phoneNumber: string) => {
              setIsSignIn(false);
            }}/>:
            <PageAccountSignUp changeToSignInWithPhoneNumber={(phoneNumber: string) => {
              setSignInPhoneNumber(phoneNumber);
              setIsSignIn(true);
            }}/>
          }
          <SignUpSignInLink 
          isSignUp={!isSignIn}
          handleClick={(isSignUp) => {
            if (isSignUp) {
              setIsSignIn(true);
            } else {
              setIsSignIn(false);
            }
          }}/>
        </Box>
      </div>
    )
  }
}
import { ProductOnUser } from 'common-ts/dist/models/Product';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { inputMeasurementsForm, resetUserMeasurementDraft } from './MeasurementsRedux';
import { ClothingType, FitType, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';

export interface MeasureSignUpFlow {
  status: UIOverlayViewStatus,

  currentScreen: string,

  addToCartAfterCreation: boolean,
  productDetail: ProductOnUser | undefined,

  hasFinishedResultsRating: boolean,
  resultsRatingStatus: UIOverlayViewStatus,
  resultsRatingCurrentScreen: string,
}

// Define the initial state using that type
const initialState: MeasureSignUpFlow = {
  status: UIOverlayViewStatus.OffAndReady,
  currentScreen: "personalOrRegularSize",
  addToCartAfterCreation: false,
  productDetail: undefined,

  hasFinishedResultsRating: false,
  resultsRatingStatus: UIOverlayViewStatus.OffAndReady,
  resultsRatingCurrentScreen: "rating",
}

export const openOverlayCreateMeasWithInitialization = createAsyncThunk(
  'overlayMeasurementCreateNew/openOverlayCreateMeasWithInitialization', 
  async (payload: {
    clothingType: ClothingType,
    fitType: FitType,
    productDetail?: ProductOnUser,
    addToCartAfterCreation: boolean,
  }, thunkAPI) => {

  thunkAPI.dispatch(resetUserMeasurementDraft());
  const payload2: UserClothTypeSpecificBodyMeasurements = {
    clothingType: payload.clothingType,
    fitType: payload.fitType,
  }
  thunkAPI.dispatch(inputMeasurementsForm(payload2));

  thunkAPI.dispatch(openOverlay({ productDetail: payload.productDetail, addToCartAfterCreation: payload.addToCartAfterCreation }));

  return null;
});

export const overlayAccountViewSlice = createSlice({
  name: 'overlayMeasurementCreateNew',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;
      state.currentScreen = "personalOrRegularSize";
      state.addToCartAfterCreation = false;
      state.productDetail = undefined;

      state.hasFinishedResultsRating = false;
      state.resultsRatingStatus = UIOverlayViewStatus.OffAndReady;
      state.resultsRatingCurrentScreen = "rating";
    },

    openOverlay: (state, action: PayloadAction<{
      addToCartAfterCreation: boolean,
      productDetail: ProductOnUser | undefined,
    }>) => {
      state.status = UIOverlayViewStatus.On;
      state.currentScreen = "personalOrRegularSize";
      state.addToCartAfterCreation = action.payload.addToCartAfterCreation;
      state.productDetail = action.payload.productDetail;

      state.hasFinishedResultsRating = false;
      state.resultsRatingStatus = UIOverlayViewStatus.OffAndReady;
      state.resultsRatingCurrentScreen = "rating";
    },

    updateCurrentScreen: (state, action: PayloadAction<string>) => {
      state.currentScreen = action.payload;
    },

    closeResultRatingOverlay: (state) => {
      state.hasFinishedResultsRating = true;
      state.resultsRatingStatus = UIOverlayViewStatus.OffAndReady;
      state.resultsRatingCurrentScreen = "rating";
    },

    openResultRatingOverlay: (state) => {
      state.hasFinishedResultsRating = false;
      state.resultsRatingStatus = UIOverlayViewStatus.On;
      state.resultsRatingCurrentScreen = "rating";
    },

    updateResultRatingCurrentScreen: (state, action: PayloadAction<string>) => {
      state.resultsRatingCurrentScreen = action.payload;
    },
  },
  extraReducers(builder) {
    builder
    .addCase(openOverlayCreateMeasWithInitialization.pending, (state, action) => {
    })
    .addCase(openOverlayCreateMeasWithInitialization.fulfilled, (state, action) => {
    })
    .addCase(openOverlayCreateMeasWithInitialization.rejected, (state, action) => {
    })
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay,
  updateCurrentScreen, updateResultRatingCurrentScreen, closeResultRatingOverlay, openResultRatingOverlay } = overlayAccountViewSlice.actions

export default overlayAccountViewSlice.reducer
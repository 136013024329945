import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CategoryOptions } from 'common-ts/dist/models/CategoryList';
import { Link as RouterLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useAppDispatch } from '../reduxhooks';
import { updateCurrentScreen } from '../redux/UIOverlayNavbarRedux';
import { Mixpanel } from '../mixpanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemContainer: {
      display: 'block',
    },
    menuItem: {
      display: 'block',
      width: '100%',
      padding: '30px',
      },
    },
  ),
);

interface MobileCategoryMenuItemProps {
    navState: string,
    title: string,
    routeLink: string | CategoryOptions,
    eventName: string,
}

export default function MobileCategoryMenuItem({navState, title, routeLink, eventName}: MobileCategoryMenuItemProps ) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    return (
        <div className={classes.menuItemContainer}>
            <Divider />
            <Button color="primary" className={classes.menuItem} 
                component={RouterLink} to={routeLink}
                onClick={() => {
                  dispatch(updateCurrentScreen("DEFAULT"));
                  Mixpanel.track('navigateRoute', {
                    'routePath': routeLink,
                    'routeSource': 'mobileMenu',
                  });
                  if (eventName !== '') {
                    Mixpanel.track(eventName, {
                      'routeSource': 'mobileMenu',
                    });
                  }
                }}>
                {title}
            </Button>
        </div>
    )
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

interface StyleProps {
  widthMdUp: string
  widthSmDown: string
}

const useStyles = ({widthMdUp, widthSmDown}: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  container: {
    width: widthSmDown,
    [theme.breakpoints.up('sm')]: {
      width: widthMdUp,
    }
  },
}),
);

interface Props {
  widthMdUp: string
  widthSmDown: string
  children?: JSX.Element | JSX.Element[];
}

export default function OverlayComponentBaseContainerWidth({ widthMdUp, widthSmDown, children }: Props) {
  const classes = useStyles({
    widthMdUp: widthMdUp,
    widthSmDown: widthSmDown,
  })();

  return (
    <Box className={classes.container}>
      {
        children
      }
    </Box>
  );
}
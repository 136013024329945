import { Box, Button, ButtonBase, Divider, Grid, Link, Slide, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import React from 'react';
import { Link as RouterLink, Route, Switch, useHistory } from "react-router-dom";
import { Mixpanel } from '../mixpanel';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import { ProductCategory } from 'common-ts/dist/models/Product';
import { getNumberOfItems } from '../redux/CartRedux';
import { updateCurrentScreen } from '../redux/UIOverlayNavbarRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import CartWindow from './CartWindow';
import MobileCategoryMenu from './MobileCategoryMenu';
import { FareastButton, FareastButtonWhite } from './OverlayComponentButton';
import { isNullishCoalesce } from 'typescript';

interface stylesProps {
  textColor: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'block',
      margin: 'auto',
    },
    titleLogoContainer: {
      height: '60px',
      paddingTop: '2px',
      paddingBottom: '2px',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        height: '70px',
      }
    },
    titleLogoButtonBase: {
      justifyContent: 'flex-start', // This is required for safari and ios
    },
    titleLogo: {
      height: '100%',
      display: 'block', // We use block so that the img is unique horizontally in the div
      filter: 'invert(0%) sepia(0%) saturate(7477%) hue-rotate(83deg) brightness(93%) contrast(106%)',
      // This is equivalent to #000.
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'calc(9.1vw - 22px)',
      }
    },
    sectionDesktopAndMobile: {
      display: 'flex',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    shopButton: {
      marginLeft: '24px',
    },
    buttonLinkContainer: {
      display: 'flex',
      paddingRight: '20px',
      [theme.breakpoints.down('xs')]: {
        paddingRight: '6px',
      },
      alignItems: 'center',
    },
    buttonLink: {
      margin: 'auto',
      color: '#000',
      fontSize: '14px',
    },
    barIcon: {
      color: '#000',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '4px',
        marginRight: '2px',
        padding: '4px'
      }
    },
    barIconArrowBack: {
      color: '#000',
      marginRight: '12px',
      [theme.breakpoints.down('xs')]: {
        padding: '4px',
        marginRight: '8px',
      }
    },
    appBar: {
      paddingLeft: '20px',
      paddingRight: '20px',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '6px',
        paddingRight: '6px',
      },
    },
    productNavigation: {
      position: 'fixed',
      visibility: 'visible',
      top: '64px',
      height: '60px',
      [theme.breakpoints.down('xs')]: {
        top: '56px',
        height: '46px',
      },
      left: '0px',
      backgroundColor: 'white',
      width: '100%',
      borderTop: '1px solid #efefef',
      borderBottom: '1px solid #efefef',
      display: 'flex',
      alignItems: 'center',
      overflowX: 'auto',
      overflowY: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    productNavigationLeftSpacer: {
      minWidth: '20px',
      height: '10px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '10px',
      },
    },
    productNavigationLinkText: {
      padding: '0px 10px 0px 30px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
    productNavigationLinkTextSelected: {
      padding: '0px 10px 0px 30px',
      fontSize: '16px',
      fontWeight: 600,
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    },
    shopText: {
      fontFamily: 'OakesGrotesk',
      fontWeight: 600,
      fontSize: '13px',
      letterSpacing: "0.8px",
      // color: '#121212',
      margin: '0px 4px 0px 0px',
      // border: '1px solid #121212',
      // borderRadius: '20px',
      padding: '4px 0px 0px 0px',
      [theme.breakpoints.only('xs')]: {
        margin: '0px 8px 0px 0px',
        padding: '5px 0px 0px 0px',
      },
    },
    boxScrollTo: {
      cursor: 'pointer',
      textAlign: 'right',
      position: 'absolute',
      top: '32%',
    },
    textScrollTo: {
      fontFamily: 'OakesGrotesk',
      fontWeight: 300,
      fontSize: '1.03vw',
      paddingTop: '0.4vw',
    },
    navBarCareerButtonContainerBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    navBarCareerButton: {
      marginTop: '4px',
      marginBottom: '4px',
      marginLeft: '4px',
      marginRight: '4px',
      color: '#232323',
      backgroundColor: '#fafafa',
      borderRadius: '30px',
      padding: '10px 10px 10px 10px',
      '&:hover': {
        backgroundColor: '#232323',
        color: '#fafafa'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
      },
      border: 'solid 2px #232323'
    },
    navBarCareerButtonText: {
      fontFamily: 'OakesGrotesk',
      fontWeight: 600,
      fontSize: '1.08vw',
      paddingTop: '0.4vw',
      textTransform: 'capitalize',
    },
  }),
);

const ScrollHandlerAppBar = (props:any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  // const triggerMove = useScrollTrigger({ target: window ? window : undefined, threshold: 40 });
  const triggerMove = false;

  const colorAtTop = props.transparentAtTop ? "transparent" : "#ffffff";
  const elevationAtTop = props.transparentAtTop ? 0 : 0;

  const transparency = props.transparent ? "" : "#FFFFFF";

  return (
    <Slide appear={false} direction="down" in={!triggerMove}>
      {React.cloneElement(props.children, {
        color: trigger ? "default" : colorAtTop,
        elevation: trigger ? 0 : elevationAtTop,
        style: {
          transition: trigger ? "0.3s" : "0.5s",
          background: trigger ? "#FFFFFF" : "",
          borderBottom: trigger ? "solid 1px #eaeaea" : "solid 1px #ffffff",
        }
      })}
    </Slide>
  )
};

const ScrollHandlerBarElementColor = (props:any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  const topColor = props.coloredAtTop ? "#fff" : "#000";

  return React.cloneElement(props.children, {
    style: {
      color: trigger ? "#000" : topColor,
    }
  });
};

const ScrollHandlerLogo = (props:any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const hexfff = "invert(100%) sepia(0%) saturate(0%) hue-rotate(75deg) brightness(105%) contrast(103%)";
  const hex000 = "invert(0%) sepia(0%) saturate(7477%) hue-rotate(83deg) brightness(100%) contrast(106%)";

  const hexf3e45ckuning = "invert(81%) sepia(58%) saturate(418%) hue-rotate(4deg) brightness(100%) contrast(98%)";

  const topColor = props.coloredAtTop ? hexfff : hex000;
  let topSize;
  let topMargin;
  let defaultSize = "60%";
  if (props.largerTitleAtTop) {
    if (props.isSmOrUp) {
      topSize = "82%";
      topMargin = "50%";
      defaultSize = "70%";
    } else {
      topSize = "13vw";
      topMargin = "11vw";
      defaultSize = "10vw";
    }
  } else {
    if (props.isSmOrUp) {
      topSize = "100%";
      topMargin = "";
      defaultSize = topSize;
    } else {
      topSize = "100%";
      topMargin = "";
      defaultSize = topSize;
    }
  }

  return React.cloneElement(props.children, {
    style: {
      filter: trigger ? hex000 : topColor,
      transition: trigger ? "0.24s" : "0.24s",
      height: trigger ? defaultSize : topSize,
      marginTop: trigger ? "" : topMargin,
    }
  });
}

interface Props {
  transparentAtTop: boolean
  coloredAtTop: boolean
  largerTitleAtTop: boolean
  transparent: boolean
  executeScrollAbout: () => void
  executeScrollWorkWithUs: () => void
  executeScrollVacancies: () => void
  executeScrollWhatWeDo: () => void
}

export default function NavBarCareers({transparentAtTop, coloredAtTop, largerTitleAtTop, transparent,
executeScrollAbout, executeScrollVacancies, executeScrollWorkWithUs, executeScrollWhatWeDo}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className={classes.grow}>
      <ScrollHandlerAppBar transparentAtTop={transparentAtTop} transparent={transparent}>
        <AppBar position="fixed" color="transparent" className={classes.appBar} elevation={0}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              {
                isSmOrUp ? 
                <Grid item xs={1} md={1}></Grid> : 
                null
              }
              <Grid item xs={5} md={5} className={classes.sectionDesktopAndMobile}>
                <Box className={classes.leftContainer}>
                  <Box justifyContent="center" className={classes.titleLogoContainer} >
                    <ButtonBase component={RouterLink} to={'/careers'}
                    onClick={() => {}}
                    className={classes.titleLogoButtonBase}>
                      <ScrollHandlerLogo coloredAtTop={coloredAtTop} largerTitleAtTop={largerTitleAtTop} isSmOrUp={isSmOrUp} >
                        <img className={classes.titleLogo} src={'https://images-far-east.s3.ap-southeast-1.amazonaws.com/sur_monogram_minimum.svg'} alt="maja logo"/>
                      </ScrollHandlerLogo>
                    </ButtonBase>
                  </Box>
                </Box>
              </Grid>

              {
                isSmOrUp ? 
                <Grid item xs={6} md={5}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Box onClick={executeScrollAbout} className={classes.boxScrollTo}>
                            <Typography className={classes.textScrollTo}>
                              <Link>
                                WHO WE ARE
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box onClick={executeScrollWorkWithUs} className={classes.boxScrollTo}>
                            <Typography className={classes.textScrollTo}>
                              <Link>
                                OUR VALUES
                              </Link>
                            </Typography>
                          </Box>                    
                        </Grid>
                        <Grid item xs={4}>
                          <Box onClick={executeScrollWhatWeDo} className={classes.boxScrollTo}>
                            <Typography className={classes.textScrollTo}>
                              <Link>
                                WHAT WE DO
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className={classes.navBarCareerButtonContainerBox}>
                        <Button
                        className={classes.navBarCareerButton}
                        onClick={executeScrollVacancies}>
                          <Typography className={classes.navBarCareerButtonText} >
                            Work With Us
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>:
                null
              }

              <Grid item xs={1} md={1}></Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ScrollHandlerAppBar>
    </div>
  );
}
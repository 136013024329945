import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentForm from './OverlayComponentForm';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
}),
);

const validationSchema = yup.object({
  givenName: yup.string()
  .required()
  .min(2, 'Nama anda?'),
  age: yup.number()
  .required()
  .min(1, 'Masukkan umur anda'),
});

interface Props {
  initialName?: string
  initialAge?: number
  handleSubmit: (values: {givenName: string, age: number}) => void
  titleNumber?: string
}

export default function PageCheckoutMeasurements({ initialName, initialAge, handleSubmit, titleNumber }: Props) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      givenName: initialName ? initialName : '',
      age: initialAge ? initialAge : 0,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  let children: JSX.Element[] = [];

  children.push(
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      margin="normal"
      fullWidth
      id="givenName"
      name="givenName"
      label="Nama"
      type="text"
      value={formik.values.givenName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.givenName && Boolean(formik.errors.givenName)}
      helperText={formik.touched.givenName && formik.errors.givenName && "Name is required"}
    />
  );

  children.push(
    <TextField
      margin="normal"
      fullWidth
      id="age"
      name="age"
      label="Umur"
      type="number"
      value={formik.values.age}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.age && Boolean(formik.errors.age)}
      helperText={formik.touched.age && formik.errors.age}
    />
  );

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <OverlayComponentButton
      isTypeSubmit={true}
      formButtonText="Next"
      buttonMarginTopBottom="10px"
      buttonMarginLeftRight="0px"
    />
  )

  return (
    <OverlayComponentForm
      formTitle="Pertama-tama, boleh kenalan dulu?"
      formTitleNumber={titleNumber}
      formSubmitBody={submitChildren}
      formFormBody={children}
      formHandleSubmit={formik.handleSubmit}
      titleWidthMdUp='400px'
      titleWidthSmDown='100%'
      formWidthMdUp='360px'
      formWidthSmDown='100%'
      formJustifyContent='space-evenly'
      formAlignItems='flex-start'
    />
  );
}
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, createStyles, Dialog, Grid, IconButton,
  makeStyles, Theme, Typography
} from '@material-ui/core';
import { ArrowBack, ArrowDropDown } from '@material-ui/icons';

import {
  closeOverlayAndReset, UniversalSizeActiveScreen, updateCurrentScreen
} from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FareastButton, FareastButtonWhite } from './OverlayComponentButton';
import { BodyMeasurementDetailList, BodyMeasurements, CalculateClothingMeasurementsFromBodyMeasurements, ClothingType, FitType, GetKey2CategoryClothingSizeMeasurementValues, RoundClothingMeasurementsToHalfPoint } from 'common-ts/dist/models/UserMeasurements';
import { getSortKey, itemCategoriesClothingType, itemCategoriesFitType, itemCategoriesMainText, itemCategoriesMainText2, itemCategoriesSubtext } from './OverlayViewPersonalSize';
import CategoryTabs from './OverlayViewPersonalSizeCategoryTabs';
import OverlayComponentCanvas from './OverlayComponentCanvasFitAnimation';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import React, { useEffect } from 'react';
import { ClothingMeasurementDetailPanel, getClothingMeasurementsSubtext } from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(2),
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'black',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5),
      padding: '2em 0em 1em',
    },
    resultIntro: {
      fontSize: '0.8em',
      fontWeight: 400,
    },
    resultTitle: {
      fontSize: '0.8em',
      fontWeight: 400,
    },
    resultImage: {
      width: '60%',
      margin: '0.7em 0em 0.3em 0em',
    },
    resultSubtitle: {
      fontSize: '0.9em',
      width: '70%',
      fontWeight: 500,
      textAlign: 'center',
    },
    resultAccordion: {
      marginTop: '0.5em',
      width: '100%',
    },
    accordionHeader: {
      backgroundColor: '#F2F2F2',
      minHeight: '2em',
      maxHeight: '2em',
      '&.Mui-expanded': {
        minHeight: '2em',
        maxHeight: '2em',
      },
      '& .MuiAccordionSummary-expandIcon': {
        color: 'black',
      },
    },
    accordionTitle: {
      fontSize: '0.8em',
      fontWeight: 600,
    },
    accordionContent: {
      backgroundColor: '#F8F8F8',
      display: 'flex',
      flexDirection: 'column',
    },
    detailSizes: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '0.5em',
    },
    detailItemTitle: {
      fontSize: '0.8em',
      fontWeight: 500,
    },
    detailItemMeasurements: {
      fontSize: '0.8em',
      fontWeight: 400,
    },
    bottomButtons: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1em',
    },
    categoryClothTab: {
      position: 'absolute',
      left: `calc(50% - 25vw)`,
      textAlign: 'center',
      width: '50vw',
      height: '50vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s',
    },
    scrollProductImages: {
      marginTop: '1vw',
      position: 'relative',
      width: '100%',
      height: '30vw',
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
      overflowY: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }
  }),
);

interface VarietySliderComponentProps {
  isLoading: boolean
  title?: string
  displayImagesArray: string[]
  numOfProducts?: number
  customScrollProductHeight?: string
  customElementWidth?: string
}

export function VarietySliderComponent({ isLoading, displayImagesArray, numOfProducts, 
  customScrollProductHeight, customElementWidth, title }: VarietySliderComponentProps) {
  const classes = useStyles();

  return (
    <Box style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Typography style={{
        fontSize: '2.8vw',
        letterSpacing: '0.1vw',
        fontWeight: 500,
        color: '#232323',
      }}>
        {title || 'AKAN DIPAKAI UNTUK'}
      </Typography>
      {
        isLoading ? 
        <Box style={{
          marginTop: '2vw',
          position: 'relative',
          width: '100%',
          height: customScrollProductHeight ? customScrollProductHeight : '30vw',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CircularProgress/> 
        </Box>:
        <Box className={classes.scrollProductImages} style={{
          height: customScrollProductHeight ? customScrollProductHeight : '30vw',
        }}>
          {
            displayImagesArray.map((value, index) => {
              return (
                <img
                src={value}
                alt={'img-' + value}
                style={{
                  width: customElementWidth ? customElementWidth : '17.8vw',
                  padding: '0.6vw'
                }}
                />
              );
            })
          }
        </Box>
      }
      {
        numOfProducts ? 
        <Typography style={{
          fontSize: '2.7vw',
          letterSpacing: '0.1vw',
          fontWeight: 300,
          color: '#808080',
          marginTop: '1vw'
        }}>
          {numOfProducts} PRODUK
        </Typography>:
        null
      }
    </Box>
  );
}

interface Props {
  bodyMeasurements: BodyMeasurements
  name: string
  handleBack: () => void
  handleSubmit: () => void
}

export default function UniversalSizeResult({ bodyMeasurements, name, handleBack, handleSubmit }: Props) {
  const classes = useStyles();
  const [categoryTabIndex, setCategoryTabIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const loadChange = (newIndex: number) => {
    setIsLoading(true);
    // get all categoryTabs
    const categoryTabs = document.getElementsByClassName(classes.categoryClothTab);

    for (let i = 0; i < categoryTabs.length; i++) {
      let categoryTab = categoryTabs[i] as HTMLElement;

      let currentKey = 'category-clothing-' + newIndex;

      if (categoryTab.id < currentKey) {
        if ('category-clothing-' + (newIndex - 1) === categoryTab.id) {
          categoryTab.style.transform = `translateX(${-100}%)`;
        } else {
          categoryTab.style.transform = `translateX(${-200}%)`;
        }
        categoryTab.style.scale = `0.9`;
        categoryTab.style.opacity = `0.6`;
      } else if (categoryTab.id === currentKey) {
        categoryTab.style.transform = `none`;
        categoryTab.style.scale = `1.1`;
        categoryTab.style.opacity = `1.0`;
      } else {
        if ('category-clothing-' + (newIndex + 1) === categoryTab.id) {
          categoryTab.style.transform = `translateX(${100}%)`;
        } else {
          categoryTab.style.transform = `translateX(${200}%)`;
        }
        categoryTab.style.scale = `0.9`;
        categoryTab.style.opacity = `0.6`;
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  const productsDictionary = useAppSelector(state => state.productsList.productsDictionary);
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  
  const key2ProductIdArray: string[][] = [];
  const key2ProductIdDisplayImagesArray: string[][] = [];
  const key2ProductIdArrayLengths: number[] = [];

  if (productsDictionary) {
    const startingProductIds = Object.keys(productsDictionary)
    .sort((a, b) => getSortKey(productsDictionary[a], productSizeAvailabilityDict?.[a]) > getSortKey(productsDictionary[b], productSizeAvailabilityDict?.[b]) ? 1 : -1)

    for (var i = 0; i < itemCategoriesMainText.length; i += 1) {
      const clothingTypeCurrent = itemCategoriesClothingType[i];
      const fitTypeCurrent = itemCategoriesFitType[i];
      const filteredArray = startingProductIds.filter((productId) => productsDictionary[productId].clothingType === clothingTypeCurrent && productsDictionary[productId].defaultFitType === fitTypeCurrent);
      key2ProductIdArray.push(filteredArray);
      key2ProductIdDisplayImagesArray.push(filteredArray.map((productId) => productsDictionary[productId].displayImage));
      key2ProductIdArrayLengths.push(filteredArray.length);
    }
  }

  const key2ClothSizeValues = GetKey2CategoryClothingSizeMeasurementValues(itemCategoriesClothingType[categoryTabIndex], 
    itemCategoriesFitType[categoryTabIndex], false);
  const currentClothingMeas = RoundClothingMeasurementsToHalfPoint(CalculateClothingMeasurementsFromBodyMeasurements(
    itemCategoriesClothingType[categoryTabIndex], 
    itemCategoriesFitType[categoryTabIndex],
    bodyMeasurements, RegularSizeValue.L));

  // We set subtext to default to disable "jumping" between categories TODO TODO TODO
  const defaultKey2ClothSizeValues = GetKey2CategoryClothingSizeMeasurementValues(ClothingType.Kaos, 
    FitType.SlimFit,false);
  const defaultClothingMeas = RoundClothingMeasurementsToHalfPoint(CalculateClothingMeasurementsFromBodyMeasurements(
    ClothingType.Kaos, 
    FitType.SlimFit,
    bodyMeasurements, RegularSizeValue.L));
  const subtextDict = getClothingMeasurementsSubtext("GENERAL", key2ClothSizeValues, currentClothingMeas);

  return <>
    <Box
      className={classes.backButton}
      onClick={handleBack}
    >
      <IconButton className={classes.backButtonIcon}>
        <ArrowBack />
      </IconButton>
    </Box>
    <Box className={classes.dialogContent}>
      <Typography className={classes.resultIntro}>
        HASIL BAJU UNTUK
      </Typography>
      <Typography className={classes.resultTitle}>
        UKURAN BADAN {name.toUpperCase()}
      </Typography>
      <Typography style={{
        fontSize: '0.6rem',
        color: '#898989',
        marginTop: '0.3rem',
        marginBottom: '0.3rem',
      }}>
        Lihat hasil baju tiap kategori disini.
      </Typography>

      <Box style={{
        width: '100%'
      }}>
        <CategoryTabs 
          categoriesMainText={itemCategoriesMainText} 
          categoriesMainText2={itemCategoriesMainText2} 
          categoriesSubtext={itemCategoriesSubtext} 
          categoriesProductAmount={key2ProductIdArrayLengths}
          onCategoryChange={(category) => {
            setCategoryTabIndex(category);
            loadChange(category);
          }} />
      </Box>

      <Box style={{
        marginTop: '6vw',
        position: 'relative',
        width: '100%',
        height: '50vw',
        overflow: 'hidden',
      }}>
        {
          itemCategoriesMainText.map((value, index) => {
            return (
              <Box
              id={'category-clothing-' + index}
              className={classes.categoryClothTab}
              style={{
                scale: index === 0 ? '1.1' : '0.9',
                opacity: index === 0 ? '1' : '0.6',
                transform: `translateX(${index * 100}%)` // Added to remove "side movement on initialization"
              }}>
                <OverlayComponentCanvas
                clothingType={itemCategoriesClothingType[index]}
                fitType={itemCategoriesFitType[index]}
                putAtTop={false}
                scaleRatio={0.8}
                incrementTogether={true}
                goalFit={RoundClothingMeasurementsToHalfPoint(CalculateClothingMeasurementsFromBodyMeasurements(
                  itemCategoriesClothingType[index], itemCategoriesFitType[index],
                  bodyMeasurements, RegularSizeValue.L))}
                canvasManualHeight='50vw'/>
              </Box>
            );
          })
        }
      </Box>

      <VarietySliderComponent
        isLoading={isLoading}
        displayImagesArray={key2ProductIdDisplayImagesArray[categoryTabIndex]}
        numOfProducts={key2ProductIdArrayLengths[categoryTabIndex]}
      />

      <Box style={{
        margin: '10px 30px',
        padding: '12px 0px 12px 19px',
        backgroundColor: '#efefef',
        borderRadius: '14px'
      }}>
        <ClothingMeasurementDetailPanel
          clothingType={itemCategoriesClothingType[categoryTabIndex]}
          fitType={itemCategoriesFitType[categoryTabIndex]}
          allowEditing={false}
          currentClothingMeas={currentClothingMeas}
          currentClothingMeasSubtextDict={subtextDict}
          currentlyFetchingAvailability={false}
        />
      </Box>
      
      <Box className={classes.bottomButtons}>
        <FareastButton
          buttonMarginLeftRight='10px'
          buttonMarginTopBottom='10px'
          onClick={handleSubmit}
        >
          NEXT
        </FareastButton>
      </Box>
    </Box>
  </>
}
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { SizeType } from 'common-ts/dist/models/Cart';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentTwoChoices from './OverlayComponentChoiceTwo';
import OverlayComponentForm from './OverlayComponentForm';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  optionGridItemSpacer: {
    minHeight: '80px',
    margin: '0px 16px 20px 0px',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 16px 10px 0px',
    },
  },
  optionGridItemContainer: {
    '-webkit-tap-highlight-color': 'transparent',
    borderRadius: '4.5vw',
    minHeight: '20px',
    cursor: 'pointer',
    aspectRatio: 1.9,
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.20) 0px 0px 11px 1px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '27px',
    },
  },
  optionBox: {
    marginTop: '6px',
    minHeight: '14px',
    width: '14px',
    backgroundColor: '#E4E4E4',
  },
  optionBoxSelected: {
    marginTop: '6px',
    minHeight: '14px',
    width: '14px',
    backgroundColor: '#232323',
  },
  optionTitle: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#343434',
    fontSize: '4.1vw',
    marginBottom: '2.2vw',
    marginTop: '2.4vw',
    lineHeight: '6vw',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      marginBottom: '8px',
      marginTop: '8px',
      lineHeight: '16px',
    }
  },
  optionSubtitle: {
    fontFamily: 'Raleway',
    fontWeight: 300,
    color: '#858585',
    fontSize: '2.2vw',
    lineHeight: '3vw',
    marginTop: '2.2vw',
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px',
      marginTop: '9px',
      lineHeight: '11px',
    }
  },
  optionSubtitleUnderline: {
    fontFamily: 'Raleway',
    fontWeight: 500,
    color: '#000000',
    fontSize: '9px',
    lineHeight: '18px',
  },
  optionSubtitleHighlight: {
    fontFamily: 'Raleway',
    fontWeight: 500,
    color: '#000000',
    fontSize: '9px',
    lineHeight: '18px',
  },
  optionSubtitleGratis: {
    fontFamily: 'Raleway',
    fontWeight: 500,
    color: '#FF821C',
    fontSize: '9px',
    marginTop: '10px',
    lineHeight: '18px',
  },
  title: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#121212',
    fontSize: '28px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '24px',
    },
    marginBottom: '18px',
  },
  titleEmoji: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#121212',
    fontSize: '32px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '28px',
    },
    marginBottom: '18px',
    marginLeft: '2px',
  },
  subtitle: {
    fontFamily: 'Raleway',
    fontWeight: 300,
    color: '#000000',
    fontSize: '13.71px',
    lineHeight: '20.57px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px',
    },
    marginBottom: '32px',
  }
}),
);

interface Props {
  handleSubmit: (choosePersonalSize: boolean) => void
  isMinimized?: boolean
}

export default function Page({ handleSubmit, isMinimized }: Props) {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const [selected, setSelected] = React.useState<"algorithm" | "favorite" | undefined>(undefined);

  function handleSubmitFunction(e: any) {
    e.preventDefault();
    handleSubmit(selected === "algorithm");
  }

  let children: JSX.Element[] = [];
  let submitChildren: JSX.Element[] = [];

  children.push(
    <OverlayComponentBaseContainerHorizontal>
      <OverlayComponentBaseContainerWidth
        widthMdUp="100%"
        widthSmDown="100%">
          {
            isMinimized ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: isMdOrUp ? '85%' : '95%',
                  margin: isMdOrUp ? '2.5% 0% 0% 5%' : undefined,
                }}
              >
                <Typography style={{
                  fontFamily: 'OakesGrotesk',
                  fontWeight: 600,
                  color: '#121212',
                  fontSize: '16px',
                  [theme.breakpoints.only('xs')]: {
                    fontSize: '12px',
                  },
                  marginBottom: '12px'
                }}>
                  Web Size Creator
                </Typography>
                <Typography className={classes.subtitle} style={{
                  marginBottom: '8px',
                  fontSize: '10px',
                  [theme.breakpoints.only('xs')]: {
                    fontSize: '6px',
                  },
                }}>
                  Tersedia Dalam 2 Opsi
                </Typography>
              </div>
            ) : (
              <div>
                <Typography className={classes.title}>
                  Buat Ukuran Custom,<br></br> 
                  Tanpa Perlu Ukur <span className={classes.titleEmoji}>&#128207;</span>
                </Typography>
                <Typography className={classes.subtitle}>
                  Kita punya <u>2 cara berbeda</u> untuk membuat ukuran custom.
                </Typography>
              </div>
            )
          }
        <Grid container
          style={{
            width: isMdOrUp ? '90%' : undefined,
            marginLeft: isMdOrUp ? '5%' : undefined,
          }}
        >
          <Grid item xs={12}
            style={{
              marginBottom: '10px',
            }}
            onClick={() => {
              setSelected("algorithm");
            }}>
            <Box className={classes.optionGridItemSpacer}>
              <Grid container className={classes.optionGridItemContainer} style={{
                opacity: selected === "favorite" ? 0.35 : 1,
              }}>
                <Grid item xs={7} style={{
                  position: 'relative',
                }}>
                  <Box style={{
                    position: 'absolute',
                    top: isMdOrUp ? '10px' : '2vw',
                    left: isMdOrUp ? '10px' : '2vw',
                    backgroundColor: selected === "algorithm" ? '#121212' : '#ffffff',
                    border: isMdOrUp ? '3px solid #ffffff' : '0.75vw solid #ffffff',
                    borderRadius: '60px',
                    width: isMdOrUp ? '12px' : '3vw',
                    height: isMdOrUp ? '12px' : '3vw',
                  }}>
                  </Box>
                  <img src={'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/custom-smart-size.jpg'} style={{
                    width: '100%',
                  }}></img>
                </Grid>
                <Grid item xs={5}>
                  <Grid container style={{
                    width: '92%',
                    paddingLeft: '8%',
                    paddingTop: isMdOrUp ? '16px' : '3vw',
                    position: 'relative',
                  }}>
                    <Typography style={{
                      fontSize: isMdOrUp ? '6px' : '2vw',
                      letterSpacing: isMdOrUp ? '0.4px' : '0.13vw',
                      borderRadius: '10px',
                      color: '#ffffff',
                      backgroundColor: '#232323',
                      padding: isMdOrUp ? '3px 7px 2px' : '0.8vw 1.5vw 0.3vw',
                      position: 'absolute',
                      right: isMdOrUp ? '6px' : '1vw',
                      top: isMdOrUp ? '12px' : '3.4vw',
                      visibility: selected === "algorithm" ? 'visible' : 'hidden',
                    }}>
                      DIPILIH
                    </Typography>  
                    <Grid item xs={12}>
                      <Typography style={{
                        fontSize: isMdOrUp ? '9px' : '2.3vw',
                        fontWeight: 300,
                      }}>
                        OPSI #1
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.optionTitle} style={{
                        lineHeight: isMdOrUp ? '14px' : '4.5vw',
                        marginTop: isMdOrUp ? '6px' : '3.4vw',
                      }}>
                        Pakai<br></br>
                        Smart Size<sup>TM</sup>
                      </Typography>
                      <Typography className={classes.optionSubtitle}>
                        Dengan data tinggi dan berat kamu, kami akan meng-<i>adjust </i>
                        ukuran reguler sehingga lebih pas saat kamu pakai.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}
            onClick={() => {
              setSelected("favorite");
            }}>
            <Box className={classes.optionGridItemSpacer}>
              <Grid container className={classes.optionGridItemContainer} style={{
                opacity: selected === "algorithm" ? 0.35 : 1,
              }}>
                <Grid item xs={7} style={{
                  position: 'relative',
                }}>
                  <Box style={{
                    position: 'absolute',
                    top: isMdOrUp ? '10px' : '2vw',
                    left: isMdOrUp ? '10px' : '2vw',
                    backgroundColor: selected === "favorite" ? '#121212' : '#ffffff',
                    border: isMdOrUp ? '3px solid #ffffff' : '0.75vw solid #ffffff',
                    borderRadius: '60px',
                    width: isMdOrUp ? '12px' : '3vw',
                    height: isMdOrUp ? '12px' : '3vw',
                  }}>
                  </Box>
                  <img src={'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/custom-favorit.jpg'} style={{
                    width: '100%',
                  }}></img>
                </Grid>
                <Grid item xs={5}>
                  <Grid container style={{
                    width: '92%',
                    paddingLeft: '8%',
                    paddingTop: isMdOrUp ? '16px' : '3vw',
                    position: 'relative',
                  }}>
                    <Typography style={{
                      fontSize: isMdOrUp ? '6px' : '2vw',
                      letterSpacing: isMdOrUp ? '0.4px' : '0.13vw',
                      borderRadius: '10px',
                      color: '#ffffff',
                      backgroundColor: '#232323',
                      padding: isMdOrUp ? '3px 7px 2px' : '0.8vw 1.5vw 0.3vw',
                      position: 'absolute',
                      right: isMdOrUp ? '6px' : '1vw',
                      top: isMdOrUp ? '12px' : '3.4vw',
                      visibility: selected === "favorite" ? 'visible' : 'hidden',
                    }}>
                      DIPILIH
                    </Typography>  
                    <Grid item xs={12}>
                      <Typography style={{
                        fontSize: isMdOrUp ? '9px' : '2.3vw',
                        fontWeight: 300,
                      }}>
                        OPSI #2
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.optionTitle}>
                        Buat Ukuran<br></br>
                        Favorit
                      </Typography>
                      <Typography className={classes.optionSubtitle}>
                        Buat ukuranmu sendiri. <br></br>
                        Mulai dari ukuran reguler, dan 
                        atur sesukamu. Bisa disesuaikan 
                        seperti baju yang kamu punya.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </OverlayComponentBaseContainerWidth>
    </OverlayComponentBaseContainerHorizontal>
  );
  submitChildren.push(
    <OverlayComponentBaseContainerHorizontal
    alignItems='flex-end' >
      <Box>
        {
          selected !== undefined ? 
          <OverlayComponentButton
            formButtonText="Next"
            isTypeSubmit={true}
            buttonMarginTopBottom='10px'
            buttonMarginLeftRight={isMdOrUp ? '35px' : "0px"}/> :
          null
        }
      </Box>
    </OverlayComponentBaseContainerHorizontal>
  )

  return (
    <OverlayComponentForm
      formTitle=""
      formSubmitBody={submitChildren}
      formFormBody={children}
      formHandleSubmit={handleSubmitFunction}
      titleWidthMdUp='380px'
      titleWidthSmDown='100%'
      formWidthMdUp='380px'
      formWidthSmDown='100%'
      formJustifyContent='space-evenly'
      formAlignItems='center'
    />
  );
}
import { AppBar, Link, Toolbar, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Route, Switch, useRouteMatch, Link as RouterLink,
} from "react-router-dom";
import PageProductDetail from './PageProductDetail';
import PageProductHeaderVariant1 from './PageProductHeaderVariant1';
import PageProductList from './PageProductList';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  productPageRoot: {
  },
  productListCategoryText: {
    fontWeight: "lighter",
    '&:hover': {
      fontWeight: "normal",
    },
  }
}),
);

interface PageProductProps {
    
}

export default function PageProduct() {
  const classes = useStyles();

  let match = useRouteMatch();

  return (
    <Box className={classes.productPageRoot}>
      <Switch>
        <Route path={`${match.path}/hero/var1`}>
          <PageProductHeaderVariant1/>
        </Route>
        <Route>
        </Route>
      </Switch>
      <Switch>
        <Route path={`${match.path}/product/:productId`}>
          <PageProductDetail />
        </Route>
        <Route path={`${match.path}/category/:categoryName`}>
          <PageProductList />
        </Route>
        <Route path={match.path}>
          <PageProductList />
        </Route>
      </Switch>
    </Box>
  );
}
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

interface StyleProps {
  justifyContent: string
  widthXs: string
  widthSm: string
  widthMd: string
  widthLg: string
  margin: string
}

const useStyles = ({ justifyContent, 
  widthXs, widthSm, widthMd, widthLg, margin }: StyleProps) => 
makeStyles((theme: Theme) =>
createStyles({
  justInContainer: {
    display: 'flex',
    justifyContent: justifyContent,
    margin: margin,
    width: '100%',
  },
  justInElement: {
    width: widthXs,
    [theme.breakpoints.only('sm')]: {
      width: widthSm,
    },
    [theme.breakpoints.only('md')]: {
      width: widthMd,
    },
    [theme.breakpoints.up('lg')]: {
      width: widthLg,
    },
    display: 'flex',
    justifyContent: 'center', // This is the default for the inner container
  },
}));

interface Props {
  content: JSX.Element[]
  justifyContent?: string
  widthXs?: string
  widthSm?: string
  widthMd?: string
  widthLg?: string
  margin?: string
}

// With this outer component, we can control 
// where the inner component lies horizontally, and its maximum size
// horizontally as well. If the content is smaller than 
export default function PageElementContainer({ content, justifyContent, 
  widthXs, widthSm, widthMd, widthLg, margin }: Props) {
  justifyContent = justifyContent ? justifyContent : 'center';
  widthXs = widthXs ? widthXs : '100%';
  widthSm = widthSm ? widthSm : '100%';
  widthMd = widthMd ? widthMd : '100%';
  widthLg = widthLg ? widthLg : '100%';
  margin = margin ? margin : '60px 0px 60px 0px';

  const classes = useStyles({ justifyContent, 
    widthXs, widthSm, widthMd, widthLg, margin })();

  return (
    <div className={classes.justInContainer}>
      <div className={classes.justInElement}>
        {
          content
        }
      </div>
    </div>
  )
}
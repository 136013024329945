import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Box, Typography } from '@material-ui/core';
import React, { SetStateAction } from 'react';
import { updateAddressInOrder } from '../redux/CartRedux';
import { openOverlay, updateCurrentScreen } from '../redux/UIOverlayAddressViewRedux';
import { inputAddressForm, resetAddressDraft } from '../redux/AddressRedux';
import EditIcon from '@material-ui/icons/Edit';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  addressSegmentBody: {
  },
  title: {
    padding: '20px 20px 10px 20px',
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  containerStyle: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  nameText: {
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '16px',
    marginBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  bodyText: {
    fontWeight: theme.typography.fontWeightMedium as number,
    fontSize: '15px',
    marginLeft: '3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  buttonStyle: {
    marginTop: '5px',
  },
  chooseAddressButton: {
    marginTop: '12px',
  }
}),
);

interface PageCheckoutProps {
  checkoutStep: string,
  setCheckoutStep: React.Dispatch<SetStateAction<string>>,
  isDisabled: boolean,
}

export default function PageCheckoutAddress({ checkoutStep, setCheckoutStep, isDisabled } : PageCheckoutProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const currentAddress = useAppSelector(state => state.addressList.addressDraft);
  const addressList = useAppSelector(state => state.addressList.addressList);

  // If no address is set, we choose the first address to be the current address
  if (currentAddress.creationDateTime === undefined && addressList.length > 0) {
    dispatch(resetAddressDraft());
    dispatch(inputAddressForm(addressList[0]));
    dispatch(updateAddressInOrder({
      addressName: addressList[0].addressName,
      address: addressList[0].address,
      provinsi: addressList[0].provinsi,
      kabupaten: addressList[0].kabupaten,
      kecamatan: addressList[0].kecamatan,
      kodePos: addressList[0].kodePos,
      kotaKecamatanText: addressList[0].kotaKecamatanText,
      keterangan: addressList[0].keterangan,
      phoneNumber: addressList[0].phoneNumber,
      destinationObject: addressList[0].destinationObject,
      destinationKiriminAjaObject: addressList[0].destinationKiriminAjaObject,
    }));
    // dispatch(getShipmentPrice(addressList[0].destinationObject));
  }

  return (
    <Box className={classes.addressSegmentBody}>
      <Typography variant="h5" className={classes.title}>
        Alamat Pengiriman
      </Typography>
      {
        currentAddress.creationDateTime !== undefined ?
        <Box className={classes.containerStyle}>
          <Typography className={classes.nameText}>
            {currentAddress.addressName}
          </Typography>
          <Typography className={classes.bodyText}>
            {currentAddress.address}
          </Typography>
          <Typography className={classes.bodyText}>
            {currentAddress.kotaKecamatanText} {currentAddress.kodePos}
          </Typography>
          {
            !currentAddress.destinationKiriminAjaObject || !currentAddress.kecamatan ? 
            <Typography className={classes.bodyText} 
            style={{color: "#fa801c", fontSize: "12px", padding: "7px 0px 6px 0px"}}>
              Alamat Ini Butuh Dilengkapi Kecamatan. <br/> Lengkapi Kecamatan Alamat Ini Dalam Daftar Alamat
            </Typography>:
            null
          }
          <Button onClick={() => {
            dispatch(openOverlay());
            dispatch(updateCurrentScreen("list"));
          }}
            disabled={isDisabled}
            variant="outlined"
            className={classes.chooseAddressButton}
            startIcon={<EditIcon/>}>
            <b>BUKA DAFTAR ALAMAT</b>
          </Button>
        </Box> : 
        <Box className={classes.containerStyle}>
          <Button onClick={() => {
            sendWebsiteEventMessage("Make new address.");
            dispatch(openOverlay());
            dispatch(updateCurrentScreen("list"));
          }}
            disabled={isDisabled}
            variant="outlined"
            startIcon={<EditIcon/>}>
            <b>BUAT ALAMAT BARU</b>
          </Button>
        </Box>
      }
    </Box>
  );
}

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductOnUser, ProductsList } from 'common-ts/dist/models/Product';
import { CartState, CartStateItem, RegularSizeValue, SizeType, ProductSpotlight } from 'common-ts/dist/models/Cart';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import { OrderItem } from 'common-ts/dist/models/Orders';
import { BodyMeasurements, BodyMeasurementsForSize, BodyMeasurementsKey, CalculateClothBodyMeasurementsFromBodyMeasurements, ClothingType, FitType, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements, UserPersonalSizeInput } from 'common-ts/dist/models/UserMeasurements';
import { Address } from 'common-ts/dist/models/Address';
import { Mixpanel } from '../mixpanel';
import hash from 'object-hash';
import { postUserPersonalSizeInput } from './MeasurementsRedux';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { sendWebsiteEventMessage } from '../telegrambotevents';

export const retryGetRequest = async (config: AxiosRequestConfig,  maxRetries: number) => {
  let result = null;
  var retries: number = 0;

  while (retries < maxRetries) {
    try {
      result = await axios(config);
      break;
    } catch (error) {
      console.log("Retry number: " + retries);
    }
    retries = retries + 1;
  }
  
  if (retries >= maxRetries) {
    console.log(`Too many  request retries.`)
  }
  return result;
}

// Define the initial state using that type
const initialState: CartState = {
  cartProductList: 
    localStorage.getItem('cartProductList') !== null ? 
    JSON.parse(localStorage.getItem('cartProductList') as string) : 
    [],
  addressName: undefined,
  address: undefined,
  kodePos: undefined,
  provinsi: undefined,
  kabupaten: undefined,
  kecamatan: undefined,
  kotaKecamatanText: undefined,
  keterangan: undefined,
  phoneNumber: undefined,
  destinationObject: undefined,
  destinationKiriminAjaObject: undefined,
  courierSelectionObject: undefined,
  courierWeight: undefined,
  shipmentPrice: undefined,
  estimasiPengiriman: undefined,
  couponKey: undefined,
  coupon: undefined,
  orderStatus: 'unsubmitted',
  orderData: undefined,
  userTriggerCheckout: false,

  // Livestream Discounts
  productSpotlightList: [],

  cartProductListHash: '',
  canAddItemDict: {},

  courierOptions: undefined,
  courierOptionsFetchStatus: APIRequestStatus.Idle,
  
  couponFetchStatus: APIRequestStatus.Idle,

  paymentToken: "",
  paymentTokenFetchStatus: APIRequestStatus.Idle,
}

function getCookieValueByName(name: string) {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : "";
}

export const submitOrderRequest = createAsyncThunk(
  'cart/submitOrderRequest', 
  async (payload, thunkAPI) => {
    const url = 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/order';

    const rootState = (thunkAPI.getState() as RootState);
    const idToken = rootState.account.idToken;

    const cart = rootState.cart;
    const productsList = rootState.productsList;
    const userBodyMeasurementsDict = rootState.userMeasurementsList.userBodyMeasurementsDictionary;
    const userClothingMeasurementsDict = rootState.userMeasurementsList.userClothingMeasurementsDictionary;

    const totalCartAmount = getDiscountedPriceAmountWithPromotions(cart, rootState.productsList, userBodyMeasurementsDict);
    const totalCartAmountWithShipment = getDiscountedPriceAmountWithPromotionsAndShipment(cart, rootState.productsList, userBodyMeasurementsDict);

    if (cart.shipmentPrice === undefined) {
      console.log("ERROR! SHIPMENT PRICE HAS TO ALWAYS EXIST BEFORE THIS IS CALLED");
      return;
    }

    // Prepare user personal size last input for the current user sub
    const userPersonalSizeInputNewEntry: UserPersonalSizeInput = {
      userSub: rootState.account.sub,
      name: rootState.account.name,
      sizeType: "regular",
    }

    let cartProductListWithProductDetail: OrderItem[] = [];
    for (let key in cart.cartProductList) {
      const cartItem = cart.cartProductList[key];
      const productDetail = productsList.productsDictionary[cartItem.productId];
      const key2 = productDetail.clothingType + "-" + cartItem.fitType;
      const orderItem: OrderItem = {
        ...cartItem,
        productDetail: productDetail,
        userMeasurementsForItem: 'NOTSELECTED',
        labelNama: '',
      };
      if (cartItem.measurementIsBodyMeasurement) {
        if (orderItem.sizeType === 'personal') {
          orderItem.userMeasurementsForItem = userBodyMeasurementsDict[cartItem.measurementCreationDateTime][0];
  
          userPersonalSizeInputNewEntry.sizeType = "personal";
          userPersonalSizeInputNewEntry.age = orderItem.userMeasurementsForItem.umur;
          userPersonalSizeInputNewEntry.height = orderItem.userMeasurementsForItem.height;
          userPersonalSizeInputNewEntry.weight = orderItem.userMeasurementsForItem.weight;
          userPersonalSizeInputNewEntry.bodyType = orderItem.userMeasurementsForItem.bodyType;
        }
        if (orderItem.sizeType === "regular") {
          orderItem.userMeasurementsForItem = {
            sizeType: SizeType.regular,
            regularSize: cartItem.regularSize,
            adjustedMeasurements: BodyMeasurementsForSize[cartItem.regularSize],
            nama: userPersonalSizeInputNewEntry?.name ? userPersonalSizeInputNewEntry.name : "User"
          };

          if (userPersonalSizeInputNewEntry.sizeType === "regular") {
            userPersonalSizeInputNewEntry.regularSize = cartItem.regularSize;
          }
        }
      } else {
        if (orderItem.sizeType === 'personal') {
          // FOR NOW, PERSONAL AND IS CLOTHING MEASUREMENT EQUALS DARI UKURAN BAJU FAVORIT TO UPDATE
          orderItem.userMeasurementsForItem = userClothingMeasurementsDict[key2][cartItem.measurementCreationDateTime][0];

          // userPersonalSizeInputNewEntry.sizeType = "personal";
          // userPersonalSizeInputNewEntry.age = orderItem.userMeasurementsForItem.umur;
          // userPersonalSizeInputNewEntry.height = orderItem.userMeasurementsForItem.height;
          // userPersonalSizeInputNewEntry.weight = orderItem.userMeasurementsForItem.weight;
          // userPersonalSizeInputNewEntry.bodyType = orderItem.userMeasurementsForItem.bodyType;

          // FOR NOW, PERSONAL AND IS CLOTHING MEASUREMENT EQUALS DARI UKURAN BAJU FAVORIT TO UPDATE
          if (userPersonalSizeInputNewEntry.sizeType === "regular") {
            userPersonalSizeInputNewEntry.regularSize = cartItem.regularSize;
          }
        }
        if (orderItem.sizeType === "regular") {
          orderItem.userMeasurementsForItem = {
            sizeType: SizeType.regular,
            regularSize: cartItem.regularSize,
            adjustedMeasurements: BodyMeasurementsForSize[cartItem.regularSize],
            nama: userPersonalSizeInputNewEntry?.name ? userPersonalSizeInputNewEntry.name : "User"
          };

          if (userPersonalSizeInputNewEntry.sizeType === "regular") {
            userPersonalSizeInputNewEntry.regularSize = cartItem.regularSize;
          }
        }
      }
      cartProductListWithProductDetail.push(orderItem);
    }

    // Calculate requestData
    var requestDataProducts = getRequestDataProducts(cart.cartProductList, userBodyMeasurementsDict, userClothingMeasurementsDict);
  

    let customerNumber = rootState.account.phoneNumber;

    customerNumber = customerNumber.replace(/[^\d]/g, '');
    while (customerNumber.startsWith("62") || customerNumber.startsWith("0")) {
      if (customerNumber.startsWith("62")) {
        customerNumber = customerNumber.substring(2);
      } else if (customerNumber.startsWith("0")) {
        customerNumber = customerNumber.substring(1);
      }
    }

    customerNumber = "62" + customerNumber;

    const jsonBody = {
      cartProductList: cartProductListWithProductDetail,
      addressName: cart.addressName,
      address: cart.address,
      provinsi: cart.provinsi,
      kabupaten: cart.kabupaten,
      kecamatan: cart.kecamatan,
      kodePos: cart.kodePos,
      kotaKecamatanText: cart.kotaKecamatanText,
      keterangan: cart.keterangan,
      phoneNumber: "+" + customerNumber,
      destinationObject: cart.destinationObject,
      destinationKiriminAjaObject: cart.destinationKiriminAjaObject,
      courierSelectionObject: cart.courierSelectionObject,
      courierWeight: cart.courierWeight,
      shipmentPrice: cart.shipmentPrice,
      estimasiPengiriman: cart.estimasiPengiriman,
      couponKey: cart.couponKey,
      coupon: cart.coupon,
      totalCartAmount: totalCartAmount,
      totalPaymentAmount: totalCartAmountWithShipment,
      requestData: requestDataProducts,
      orderTrackingDetails: {
        userSub: rootState.account.sub,
        email: rootState.account.email,
        phoneNumber: customerNumber,
        fbc: getCookieValueByName("_fbc"),
        fbp: getCookieValueByName("_fbp"),
      },
      orderName: rootState.account.name
    };

    const data = JSON.stringify(jsonBody);


    var config: AxiosRequestConfig = {
      method: 'post',
      url: url,
      headers: { 
        'Authorization': 'Bearer ' + idToken, 
      },
      data: data
    };

    const response = await axios(config);

    console.log(response);

    // Update user personal input data
    // We update the user personal size input measurements
    thunkAPI.dispatch(postUserPersonalSizeInput(userPersonalSizeInputNewEntry));

    return response.data;
  });

export const cancelUserOrders = createAsyncThunk('cart/cancelUserOrders', async (arg, thunkAPI) => {

  const rootState = (thunkAPI.getState() as RootState);
  const idToken = rootState?.account?.idToken;

  if (idToken) {
    axios({
        method: 'post',
        url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/orders-cancel-post",
        headers: { 
          'Authorization': 'Bearer ' + idToken, 
        }
    });
  }

  return {};
})

export const postCustomerNotification = createAsyncThunk('cart/postCustomerNotification', async (arg: any, thunkAPI) => {
  axios({
      method: 'post',
      url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/customer-notify",
      data: arg
  });

  return {};
})

export const getCurrentOrder = createAsyncThunk('cart/getCurrentOrder', async (payload, thunkAPI) => {
  const url = 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/order';

  const rootState = (thunkAPI.getState() as RootState);
  const idToken = rootState.account.idToken;

  const orderData = rootState.cart.orderData;

  var config: AxiosRequestConfig = {
    method: 'get',
    url: url + '?creationDateTime=' + orderData.creationDateTime,
    headers: { 
      'Authorization': 'Bearer ' + idToken, 
    }
  };

  let response = await axios(config);
  console.log("current order: ", response.data);

  let retryCount = 0;
  while (response.data.paymentStatus === 'unpaid' && retryCount < 5) {
    console.log("Payment status is still unpaid. Refetching in 1 s.");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    retryCount = retryCount + 1;
    response = await axios(config);
    console.log("current order refetch: ", response.data);
  }

  return response.data;
})

export const submitCouponRequest = createAsyncThunk('cart/submitCouponRequest', async (payload: string, thunkAPI) => {
  const url = 'https://hx969cwq4f.execute-api.ap-southeast-1.amazonaws.com/CouponAPIProduction';
  const urlNotSignedIn = 'https://hx969cwq4f.execute-api.ap-southeast-1.amazonaws.com/CouponAPIProduction/coupons';

  const rootState = (thunkAPI.getState() as RootState);
  const isSignedIn = rootState.account.authState === "signedin";
  const idToken = rootState.account.idToken;

  var config: AxiosRequestConfig = {
    method: 'get',
    url: (isSignedIn ? url : urlNotSignedIn) + '?couponkey=' + payload,
    headers: isSignedIn ?
    { 
      'Authorization': 'Bearer ' + idToken, 
    } : undefined
  };

  let response = await axios(config);

  return response.data;
})

export const paymentTokenFetch = createAsyncThunk('cart/paymentTokenFetch', async (payload: {
  orderId: string,
  enabledPayments: string[] | undefined,
}, thunkAPI) => {

  var config: AxiosRequestConfig = {
    method: 'post',
    url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/order-generate-token",
    headers: undefined,
    data: JSON.stringify(
    {
      "orderId": payload.orderId,
      "enabledPayments": payload.enabledPayments,
    })
  };

  let response = await axios(config);

  return response.data;
})

export const getCourierOptions = createAsyncThunk('cart/getCourierOptions', async (arg: { dest: number, weight: number }, thunkAPI) => {

  const response = await axios({
      method: 'post',
      url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/kirimin-aja-proxy",
      data: JSON.stringify({
        "axiosConfig": {
          "method": "post",
          "url": "https://client.kiriminaja.com/api/mitra/shipping_price",
          "data": {
            "origin": 6315,
            "destination": arg.dest,
            "weight": arg.weight
          }
        }
      })
  });

  return response.data;
})

export const getShipmentPrice = createAsyncThunk('cart/getShipmentPrice', async (destination: any) => {
  var data = {
    "origin":"CGK",
    "destination":destination.destination_code,
    "weight":"0.8",
    "p":"30",
    "l":"20",
    "t":"3",
  };

  var config: AxiosRequestConfig = {
    method: 'post',
    url: 'https://content-main-api-production.sicepat.com/public/delivery-fee/fare-non-international',
    headers: { 
      'authority': 'content-main-api-production.sicepat.com', 
      'pragma': 'no-cache', 
      'cache-control': 'no-cache', 
      'sec-ch-ua': '"Google Chrome";v="93", " Not;A Brand";v="99", "Chromium";v="93"', 
      'accept': 'application/json, text/plain, */*', 
      'content-type': 'application/json;charset=UTF-8', 
      'sec-ch-ua-mobile': '?0', 
      'user-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Safari/537.36', 
      'sec-ch-ua-platform': '"macOS"', 
      'origin': 'https://www.sicepat.com', 
      'sec-fetch-site': 'same-site', 
      'sec-fetch-mode': 'cors', 
      'sec-fetch-dest': 'empty', 
      'referer': 'https://www.sicepat.com/', 
      'accept-language': 'en-US,en;q=0.9,id-ID;q=0.8,id;q=0.7'
    },
    data: data
  };

  const response = await axios(config);

  const regularShipment = response.data.sicepat.results.find((item: any) => item.service === "REG");

  sendWebsiteEventMessage("Getting shipment estimate SiCepat" + JSON.stringify(regularShipment));

  return {
    regularShipment: regularShipment,
    destinationObject: destination,
  }
})

export function getMeasurementForCartStateItem(cartStateItemObject: CartStateItem,
  userBodyMeasurementsDict: {[key: string]: UserBodyMeasurements[]}, 
  userClothingMeasurementsDict: {[key: string]:{[key: string]: UserClothTypeSpecificBodyMeasurements[]};}) {

  const key2 = cartStateItemObject.clothingType + "-" + cartStateItemObject.fitType;
  var measurementsForItem: BodyMeasurements | undefined;
  if (cartStateItemObject.sizeType === 'personal') {
    if (cartStateItemObject.measurementIsBodyMeasurement) {
      measurementsForItem = userBodyMeasurementsDict[cartStateItemObject.measurementCreationDateTime][0].adjustedMeasurements;

      if (measurementsForItem === undefined) {
        measurementsForItem = userBodyMeasurementsDict[cartStateItemObject.measurementCreationDateTime][0].generatedMeasurements;
      }
    } else {
      measurementsForItem = userClothingMeasurementsDict[key2][cartStateItemObject.measurementCreationDateTime][0].adjustedMeasurements;

      if (measurementsForItem === undefined) {
        measurementsForItem = userClothingMeasurementsDict[key2][cartStateItemObject.measurementCreationDateTime][0].generatedMeasurements;
      }
    }
  } else if (cartStateItemObject.sizeType === 'regular') {
    measurementsForItem = BodyMeasurementsForSize[cartStateItemObject.regularSize];
  }

  if (measurementsForItem === undefined) {
    measurementsForItem = {};
  }

  measurementsForItem = {...measurementsForItem};

  // BACKWARDS COMPATIBILITY FIX - Has panjang lengan pendek but not lebar lengan pendek (INCOMPLETE CB)
  if (measurementsForItem['panjangLenganPendek'] && measurementsForItem['lebarLenganPendek'] === undefined) {
    measurementsForItem = CalculateClothBodyMeasurementsFromBodyMeasurements(cartStateItemObject.clothingType, cartStateItemObject.fitType,
      measurementsForItem, RegularSizeValue.L, [BodyMeasurementsKey.lebarLenganPendek]);
  }

  return measurementsForItem;
}

export function getRequestDataProducts(cartProductList: CartStateItem[], 
  userBodyMeasurementsDict: {[key: string]: UserBodyMeasurements[]}, 
  userClothingMeasurementsDict: {[key: string]:{[key: string]: UserClothTypeSpecificBodyMeasurements[]};}) {

  var requestDataProducts = [];

  for (var i = 0; i < cartProductList.length; i = i + 1) {
    var cartStateItemObject = cartProductList[i];

    for (var j = 0; j < cartProductList[i].amount; j = j + 1) {
      requestDataProducts.push({
        resource: "PRODUCT",
        resourceId: cartStateItemObject.productId, 
        provisionStrategy: "PRODUCT",
        provisionStrategyMetadata: {
          productId: cartStateItemObject.productId,
          measurements: getMeasurementForCartStateItem(cartStateItemObject, userBodyMeasurementsDict, userClothingMeasurementsDict),
        }
      });
    }
  }

  return requestDataProducts;
}

export const postCheckCurrentCartAvailability = createAsyncThunk('cart/postCheckCurrentCartAvailability', async (payload: {}, thunkAPI) => {

  const rootState = (thunkAPI.getState() as RootState);
  const userBodyMeasurementsDict = rootState.userMeasurementsList.userBodyMeasurementsDictionary;
  const userClothingMeasurementsDict = rootState.userMeasurementsList.userClothingMeasurementsDictionary;

  const cartProductList = rootState.cart.cartProductList;

  var requestDataProducts = getRequestDataProducts(cartProductList, userBodyMeasurementsDict, userClothingMeasurementsDict);

  console.log("Fetch new");
  console.log(JSON.stringify(requestDataProducts, null, 4));

  var config: AxiosRequestConfig = {
    method: 'post',
    url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/availability',
    data: JSON.stringify(
      {
        "requestData": requestDataProducts,
      })
  };

  const response = await retryGetRequest(config, 8);

  sendWebsiteEventMessage("Checked availability for current cart items " + JSON.stringify(requestDataProducts) + " and result is " + response?.data?.provisionStatus);

  return {
    canBeProvisioned: response?.data?.provisionStatus === "ENOUGH",
  }
})

export const postCanAddItemCount = createAsyncThunk('cart/postCanAddItemCount', async (payload: CartStateItem, thunkAPI) => {

  const rootState = (thunkAPI.getState() as RootState);
  const userBodyMeasurementsDict = rootState.userMeasurementsList.userBodyMeasurementsDictionary;
  const userClothingMeasurementsDict = rootState.userMeasurementsList.userClothingMeasurementsDictionary;

  const cartProductList = rootState.cart.cartProductList;

  const hashKey = hash(payload);

  console.log(JSON.stringify(payload)); // TODO TESTING NOW FOR DARI BAJU FAVORIT

  var requestDataProducts = getRequestDataProducts(cartProductList, userBodyMeasurementsDict, userClothingMeasurementsDict);

  // New potential item
  var newCartStateItemMeasurement = getMeasurementForCartStateItem(payload, userBodyMeasurementsDict, userClothingMeasurementsDict);

  requestDataProducts.push({
    resource: "PRODUCT",
    resourceId: payload.productId, 
    provisionStrategy: "PRODUCT",
    provisionStrategyMetadata: {
      productId: payload.productId,
      measurements: newCartStateItemMeasurement,
    }
  });

  console.log("Fetch new");
  console.log(JSON.stringify(requestDataProducts, null, 4));

  var config: AxiosRequestConfig = {
    method: 'post',
    url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/availability',
    data: JSON.stringify(
      {
        "requestData": requestDataProducts,
      })
  };

  const response = await retryGetRequest(config, 8);

  sendWebsiteEventMessage("Checked availability for " + JSON.stringify(requestDataProducts) + " and result is " + response?.data?.provisionStatus);

  return {
    cartStateHash: hashKey,
    canBeProvisioned: response?.data?.provisionStatus === "ENOUGH",
  }
})

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemCount: (state, action: PayloadAction<{ productId: string, 
      productDetail: ProductOnUser, fitType: FitType, sizeType: SizeType,
      measurementCreationDateTime: string, measurementIsBodyMeasurement: boolean, regularSize: RegularSizeValue }>) => {
      const { productId, productDetail, fitType, sizeType, measurementCreationDateTime, measurementIsBodyMeasurement, regularSize } = action.payload;
      if (measurementCreationDateTime === '') {
        return;
      }
      var cartStateItem: CartStateItem = {
        productId: productId,
        amount: 1,
        clothingType: productDetail.clothingType,
        fitType: fitType,
        sizeType: sizeType,
        measurementCreationDateTime: measurementCreationDateTime,
        measurementIsBodyMeasurement: measurementIsBodyMeasurement,
        regularSize: regularSize,
      }
      if (canAddProductAmountToCart(state, cartStateItem) !== "ENOUGH") {
        console.log("Can't add product to cart: not enough.")
        return;
      }
      const existingCartItem = state.cartProductList
      .find(item => item.productId === productId 
        && item.fitType === fitType
        && item.sizeType === sizeType
        && item.measurementCreationDateTime === measurementCreationDateTime
        && item.measurementIsBodyMeasurement === measurementIsBodyMeasurement
        && item.regularSize === regularSize);
      if (existingCartItem) {
        existingCartItem.amount = existingCartItem.amount + 1;
      } else {
        state.cartProductList.push(cartStateItem);
      }
      localStorage.setItem('cartProductList', JSON.stringify(state.cartProductList));

      // Reset add item dict every time we add a new item
      state.canAddItemDict = {};
      state.cartProductListHash = hash(state.cartProductList);
    },
    removeItemCount: (state, action: PayloadAction<{productId: string, 
      fitType: FitType, sizeType: SizeType,
      measurementCreationDateTime: string, regularSize: RegularSizeValue}>) => {
      const { productId, measurementCreationDateTime, fitType, sizeType, regularSize } = action.payload;
      const existingCartItem = state.cartProductList
      .find(item => item.productId === productId 
        && item.fitType === fitType
        && item.sizeType === sizeType
        && item.measurementCreationDateTime === measurementCreationDateTime
        && item.regularSize === regularSize);
      if (existingCartItem) {
        existingCartItem.amount = existingCartItem.amount - 1;
        if (existingCartItem.amount === 0) {
          state.cartProductList = state.cartProductList.filter(item => !(item.productId === productId
            && item.sizeType === sizeType
            && item.measurementCreationDateTime === measurementCreationDateTime
            && item.regularSize === regularSize));
        }
      }
      localStorage.setItem('cartProductList', JSON.stringify(state.cartProductList));

      // Reset add item dict every time we add a new item
      state.canAddItemDict = {};
      state.cartProductListHash = hash(state.cartProductList);
    },
    removeItem: (state, action: PayloadAction<{productId: string, 
      fitType: FitType, sizeType: SizeType,
      measurementCreationDateTime: string, regularSize: RegularSizeValue}>) => {
      const { productId, measurementCreationDateTime, fitType, sizeType, regularSize } = action.payload;
      state.cartProductList = state.cartProductList.filter(item => !(item.productId === productId 
        && item.fitType === fitType
        && item.sizeType === sizeType
        && item.measurementCreationDateTime === measurementCreationDateTime
        && item.regularSize === regularSize));
      localStorage.setItem('cartProductList', JSON.stringify(state.cartProductList));

      // Reset add item dict every time we add a new item
      state.canAddItemDict = {};
      state.cartProductListHash = hash(state.cartProductList);
    },
    updateAddressInOrder: (state, action: PayloadAction<Address>) => {
      Object.assign(state, action.payload);
    },
    updateCourierInOrder: (state, action: PayloadAction<{ 
      shipmentPrice: number, 
      estimasiPengiriman: string, 
      courierSelectionObject: any, 
      courierWeight: number,
    }>) => {
      Object.assign(state, action.payload);
    },
    resetCourier: (state) => {
      state.shipmentPrice = undefined;
      state.estimasiPengiriman = undefined;
      state.courierSelectionObject = undefined;
      state.courierWeight = undefined;
    },
    resetCartState: (state) => {
      state.cartProductList = [];
      state.orderData = undefined;
      state.orderStatus = 'unsubmitted';
      state.addressName = undefined;
      state.address = undefined;
      state.provinsi = undefined;
      state.kabupaten = undefined;
      state.kecamatan = undefined;
      state.kodePos = undefined;
      state.kotaKecamatanText = undefined;
      state.keterangan = undefined;
      state.phoneNumber = undefined;
      state.destinationObject = undefined;
      state.destinationKiriminAjaObject = undefined;
      state.courierSelectionObject = undefined;
      state.courierWeight = undefined;
      state.shipmentPrice = undefined;
      state.estimasiPengiriman = undefined;
      state.couponKey = undefined;
      state.coupon = undefined;

      localStorage.setItem('cartProductList', JSON.stringify(state.cartProductList));

      // Reset add item dict every time we add a new item
      state.canAddItemDict = {};
      state.cartProductListHash = hash(state.cartProductList);
    },
    resetCartStateAndSetOrderStatus: (state, action: PayloadAction<"unsubmitted" | "waitingForPayment" 
    | "verifyPaymentWithServer" | "success" | "paymentFailed" | "rejected">) => {
      state.cartProductList = [];
      state.orderData = undefined;
      state.orderStatus = action.payload;
      state.addressName = undefined;
      state.address = undefined;
      state.provinsi = undefined;
      state.kabupaten = undefined;
      state.kecamatan = undefined;
      state.kodePos = undefined;
      state.kotaKecamatanText = undefined;
      state.keterangan = undefined;
      state.phoneNumber = undefined;
      state.destinationObject = undefined;
      state.destinationKiriminAjaObject = undefined;
      state.courierSelectionObject = undefined;
      state.courierWeight = undefined;
      state.shipmentPrice = undefined;
      state.estimasiPengiriman = undefined;
      state.couponKey = undefined;
      state.coupon = undefined;

      localStorage.setItem('cartProductList', JSON.stringify(state.cartProductList));

      // Reset add item dict every time we add a new item
      state.canAddItemDict = {};
      state.cartProductListHash = hash(state.cartProductList);
    },
    resetCartAddressAndPaymentState: (state) => {
      state.orderData = undefined;
      state.orderStatus = 'unsubmitted';
      state.addressName = undefined;
      state.address = undefined;
      state.provinsi = undefined;
      state.kabupaten = undefined;
      state.kecamatan = undefined;
      state.kodePos = undefined;
      state.kotaKecamatanText = undefined;
      state.keterangan = undefined;
      state.phoneNumber = undefined;
      state.destinationObject = undefined;
      state.destinationKiriminAjaObject = undefined;
      state.courierSelectionObject = undefined;
      state.courierWeight = undefined;
      state.shipmentPrice = undefined;
      state.estimasiPengiriman = undefined;
    },
    updateOrderState (state, action) {
      state.orderStatus = action.payload;
    },
    updateUserTrigger (state, action) {
      state.userTriggerCheckout = action.payload;
    },
    removeCurrentCoupon (state) {
      state.couponKey = undefined;
      state.coupon = undefined;
    },
    removePaymentToken(state) {
      state.paymentToken = "";
    },
    setProductSpotlightList(state, action) {
      state.productSpotlightList = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(submitCouponRequest.pending, (state, action) => {
        state.couponFetchStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(submitCouponRequest.fulfilled, (state, action) => {
        state.couponFetchStatus = APIRequestStatus.Success;
        Mixpanel.track('couponKeyValue', {
          'couponKey': action.payload.couponKey,
          'coupon': action.payload.coupon,
        })
        console.log(action.payload);
        if (action.payload.isUsable) {
          state.couponKey = action.payload.couponKey;
          state.coupon = action.payload.coupon;
        }
      })
      .addCase(submitCouponRequest.rejected, (state, action) => {
        state.couponFetchStatus = APIRequestStatus.Failure;
      })
      .addCase(getShipmentPrice.pending, (state, action) => {
      })
      .addCase(getShipmentPrice.fulfilled, (state, action) => {
        state.shipmentPrice = action.payload.regularShipment.tariff;
        state.estimasiPengiriman = action.payload.regularShipment.etd;
        state.destinationObject = action.payload.destinationObject;
        console.log(action.payload);
      })
      .addCase(getShipmentPrice.rejected, (state, action) => {
      })
      .addCase(submitOrderRequest.pending, (state, action) => {
      })
      .addCase(submitOrderRequest.fulfilled, (state, action) => {
        // Checking results of order request
        console.log("Check results of order request");
        if (action.payload.creationDateTime === undefined || 
          action.payload.transactionToken === undefined) {
          // Order creation has failed. Display error message, then redirect to home page.
          console.log("Order request is rejected");
          state.orderData = action.payload;
          state.orderStatus = 'rejected';
        } else {
          // Order creation success
          state.orderData = action.payload;
          state.orderStatus = 'waitingForPayment';
        }
      })
      .addCase(submitOrderRequest.rejected, (state, action) => {
      })
      .addCase(getCurrentOrder.pending, (state, action) => {
      })
      .addCase(getCurrentOrder.fulfilled, (state, action) => {
        console.log("Received current order status.");
        console.log(action.payload);
        if (action.payload.paymentStatus === 'pending' 
        || action.payload.paymentStatus === 'capture'
        || action.payload.paymentStatus === 'settlement') {
          state.orderStatus = 'success';
          state.orderData = undefined;
          state.addressName = undefined;
          state.address = undefined;
          state.provinsi = undefined;
          state.kabupaten = undefined;
          state.kecamatan = undefined;
          state.kodePos = undefined;
          state.kotaKecamatanText = undefined;
          state.keterangan = undefined;
          state.phoneNumber = undefined;
          state.destinationObject = undefined;
          state.destinationKiriminAjaObject = undefined;
          state.courierSelectionObject = undefined;
          state.courierWeight = undefined;
          state.shipmentPrice = undefined;
          state.estimasiPengiriman = undefined;
          state.couponKey = undefined;
          state.coupon = undefined;
          state.cartProductList = [];

          localStorage.setItem('cartProductList', JSON.stringify(state.cartProductList));

          // Reset add item dict every time we add a new item
          state.canAddItemDict = {};
          state.cartProductListHash = hash(state.cartProductList);
        } else {
          console.log("PAYMENT ERROR");
          state.orderStatus = 'paymentFailed';
          state.orderData = undefined;
          state.addressName = undefined;
          state.address = undefined;
          state.provinsi = undefined;
          state.kabupaten = undefined;
          state.kecamatan = undefined;
          state.kodePos = undefined;
          state.kotaKecamatanText = undefined;
          state.keterangan = undefined;
          state.phoneNumber = undefined;
          state.destinationObject = undefined;
          state.destinationKiriminAjaObject = undefined;
          state.courierSelectionObject = undefined;
          state.courierWeight = undefined;
          state.shipmentPrice = undefined;
          state.estimasiPengiriman = undefined;
          state.couponKey = undefined;
          state.coupon = undefined;
          state.estimasiPengiriman = undefined;
        }
      })
      .addCase(getCurrentOrder.rejected, (state, action) => {
      })
      .addCase(postCanAddItemCount.pending, (state, action) => {
        var cartStateItemHash = hash(action.meta.arg);
        state.canAddItemDict[cartStateItemHash] = "LOADING";
      })
      .addCase(postCheckCurrentCartAvailability.fulfilled, (state, action) => {
        if (!action.payload.canBeProvisioned) {
          // If it is not available anymore, we reset the contents
          state.cartProductList = [];
          state.orderData = undefined;
          state.orderStatus = 'unsubmitted';
          state.addressName = undefined;
          state.address = undefined;
          state.provinsi = undefined;
          state.kabupaten = undefined;
          state.kecamatan = undefined;
          state.kodePos = undefined;
          state.kotaKecamatanText = undefined;
          state.keterangan = undefined;
          state.phoneNumber = undefined;
          state.destinationObject = undefined;
          state.destinationKiriminAjaObject = undefined;
          state.courierSelectionObject = undefined;
          state.courierWeight = undefined;
          state.shipmentPrice = undefined;
          state.estimasiPengiriman = undefined;
          state.couponKey = undefined;
          state.coupon = undefined;
    
          localStorage.setItem('cartProductList', JSON.stringify(state.cartProductList));
    
          // Reset add item dict every time we add a new item
          state.canAddItemDict = {};
          state.cartProductListHash = hash(state.cartProductList);

          console.log("Cart contents are unavailable. Emptying.");
        } else {
          console.log("Cart contents are safe / available. Carry on.");
        }
      })
      .addCase(postCheckCurrentCartAvailability.rejected, (state, action) => {
      })
      .addCase(postCheckCurrentCartAvailability.pending, (state, action) => {
        // We put the hash in, so it is not calculated / trigerred twice
        state.canAddItemDict = {};
        state.cartProductListHash = hash(state.cartProductList);
      })
      .addCase(postCanAddItemCount.fulfilled, (state, action) => {
        state.canAddItemDict[action.payload.cartStateHash] = {
          canBeProvisioned: action.payload.canBeProvisioned
        };
      })
      .addCase(postCanAddItemCount.rejected, (state, action) => {
      })
      .addCase(paymentTokenFetch.pending, (state, action) => {
        state.paymentTokenFetchStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(paymentTokenFetch.fulfilled, (state, action) => {
        state.paymentTokenFetchStatus = APIRequestStatus.Success;
        if (action.payload.success) {
          state.paymentToken = action.payload.transactionToken;
        }
      })
      .addCase(paymentTokenFetch.rejected, (state, action) => {
        state.paymentTokenFetchStatus = APIRequestStatus.Failure;
      })
      .addCase(postCustomerNotification.pending, (state, action) => {
      })
      .addCase(postCustomerNotification.fulfilled, (state, action) => {
      })
      .addCase(postCustomerNotification.rejected, (state, action) => {
      })
      .addCase(cancelUserOrders.pending, (state, action) => {
      })
      .addCase(cancelUserOrders.fulfilled, (state, action) => {
      })
      .addCase(cancelUserOrders.rejected, (state, action) => {
      })
      .addCase(getCourierOptions.pending, (state, action) => {
        state.courierOptionsFetchStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(getCourierOptions.fulfilled, (state, action) => {
        state.courierOptionsFetchStatus = APIRequestStatus.Success;
        
        if (action.payload?.success && action.payload?.returnBody?.results) {
          state.courierOptions = action.payload.returnBody.results;
        }
      })
      .addCase(getCourierOptions.rejected, (state, action) => {
        state.courierOptionsFetchStatus = APIRequestStatus.Failure;
      })
  }
})

export function getOriginalPriceAmount(state: CartState, productListState: ProductsList) {
  let total = 0;
  for (let itemKey in state.cartProductList) {
    let item = state.cartProductList[itemKey];
    if (productListState.productsDictionary[item.productId] === undefined) {
      continue;
    }
    total = total + item.amount * productListState.productsDictionary[item.productId].originalPrice;
  }
  return total;
}

export function getDiscountedPriceInProductSpotlightList(productSpotlightList: ProductSpotlight[], productId: string): number {
  const timestampCurrent = new Date().toISOString();

  for (let i = 0; i < productSpotlightList.length; i++) {
    // Returns the first item that is relevant
    if (productId === productSpotlightList[i].productId && timestampCurrent < productSpotlightList[i].timestampLimit) {
      return productSpotlightList[i].discountedPrice;
    }
  }

  return -1;
}
export function getTimestampInProductSpotlightList(productSpotlightList: ProductSpotlight[], productId: string): string {
  const timestampCurrent = '2023-07-02T05:07:42.894Z'; // Set to expired date

  for (let i = 0; i < productSpotlightList.length; i++) {
    // Returns the first item that is relevant
    if (productId === productSpotlightList[i].productId && timestampCurrent < productSpotlightList[i].timestampLimit) {
      return productSpotlightList[i].timestampLimit;
    }
  }

  return timestampCurrent;
}

export function getDiscountedPriceAmount(state: CartState, productListState: ProductsList) {
  let total = 0;
  for (let itemKey in state.cartProductList) {
    let item = state.cartProductList[itemKey];
    if (productListState.productsDictionary[item.productId] === undefined) {
      continue;
    }

    // Equals to -1 if not exist
    let defaultDiscPrice = productListState.productsDictionary[item.productId].discountedPrice === undefined ? -1 :
      productListState.productsDictionary[item.productId].discountedPrice;

    // TODO: TEMPORARY FIX 
    let spotlightDiscPrice = state?.productSpotlightList ? getDiscountedPriceInProductSpotlightList(state.productSpotlightList, item.productId) : -1;
    let thereIsDiscountedPrice = defaultDiscPrice !== -1 || spotlightDiscPrice !== -1;

    if (thereIsDiscountedPrice) {
      let discountedPrice = spotlightDiscPrice !== -1 ? spotlightDiscPrice : defaultDiscPrice;
      total = total + item.amount * discountedPrice;
    } else {
      total = total + item.amount * productListState.productsDictionary[item.productId].originalPrice;
    }
  }

  return total;
}

export function checkIfMeasKitSizeExists(cartState: CartState, 
  userBodyMeasurementsDict: {[key:string]: UserBodyMeasurements[]} ) {

  let measKitSizeExists = false;

  for (let cartProductItem of cartState.cartProductList) {
    if (cartProductItem.sizeType === SizeType.personal
      && cartProductItem.measurementIsBodyMeasurement
      && (userBodyMeasurementsDict?.[cartProductItem.measurementCreationDateTime]?.[0]?.creationMethod === "itemQrSizeModify"
      || userBodyMeasurementsDict?.[cartProductItem.measurementCreationDateTime]?.[0]?.creationMethod === "measurementKit")) {
      measKitSizeExists = true;
    }
  }

  return measKitSizeExists;
}

export function getDiscountedPriceAmountWithPromotions(state: CartState, productListState: ProductsList, 
  userBodyMeasurementsDict: {[key:string]: UserBodyMeasurements[]}) {
  let total = getDiscountedPriceAmount(state, productListState);

  if (state.couponKey && state.coupon) {
    // Check usability
    let couponUsable = true;

    if (state.coupon.expirationDateTime && state.coupon.expirationDateTime !== '') {
      couponUsable = couponUsable && (new Date().toISOString() < state.coupon.expirationDateTime);
    }

    let allConditionsSatisfied = true;
    for (let couponConditionItem of state.coupon.couponConditions) {
      if (couponConditionItem.conditionKey === "MEASKITSIZEEXISTS") {
        let measKitSizeExists = checkIfMeasKitSizeExists(state, userBodyMeasurementsDict);

        allConditionsSatisfied = allConditionsSatisfied && measKitSizeExists;
      }
    }
    couponUsable = couponUsable && allConditionsSatisfied;

    // Use coupon
    if (couponUsable) {
      for (let key in state.coupon.couponEffects) {
        const couponEffect = state.coupon.couponEffects[key];
        if (couponEffect.effectKey === "PRICECUT") {
          total -= couponEffect.effectValue as number;
        }
        if (couponEffect.effectKey === "PRICECUTPERCENT") {
          total = total * (1 - couponEffect.effectValue as number);
        }
      }
    }
  }

  return total;
}

export function getDiscountedPriceAmountWithPromotionsAndShipment(state: CartState, productListState: ProductsList, 
  userBodyMeasurementsDict: {[key:string]: UserBodyMeasurements[]}) {
  let total = getDiscountedPriceAmountWithPromotions(state, productListState, userBodyMeasurementsDict);

  if (state.shipmentPrice) {
    let endShipmentPrice = getEndShipmentPrice(total, getNumberOfItems(state), state.shipmentPrice);
    total = total + (endShipmentPrice as number);
  }

  return total;
}

export function getNumberOfItems(state: CartState) {
  let total = 0;
  for (let itemKey in state.cartProductList) {
    total += state.cartProductList[itemKey].amount;
  }
  return total;
}

export function getShipmentPriceSubsidyAmount(numberOfItemsInCart: number) {
  return (
    numberOfItemsInCart === 0 ? 0 : 
    numberOfItemsInCart === 1 ? 10000 : 
    numberOfItemsInCart === 2 ? 25000 : 
    numberOfItemsInCart === 3 ? 45000 : 
    numberOfItemsInCart >= 4 ? 60000 : 0
  )
}

export function getEndShipmentPrice(totalCartAmount: number, numberOfItemsInCart: number, initialShipmentPrice: number | undefined) {
  if (!initialShipmentPrice || initialShipmentPrice === -1) {
    return initialShipmentPrice;
  }

  let endCartShipmentPrice = initialShipmentPrice;
  let subsidyAmount = getShipmentPriceSubsidyAmount(numberOfItemsInCart);

  endCartShipmentPrice = endCartShipmentPrice > subsidyAmount ? endCartShipmentPrice - subsidyAmount : 0;
  
  return endCartShipmentPrice;
}

export function getCurrentShipmentPromo() {
  const month = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus",
  "September","Oktober","November","Desember"];
  
  const d = new Date();
  
  const shipmentPromoObject = {
    bulan: month[d.getMonth()],
    tahun: d.getFullYear(),
    bagianBulan: d.getDate() > 15 ? "akhir" : "launch",
    tanggalAkhir: d.getDate() > 15 ? (new Date(d.getFullYear(), d.getMonth() + 1, 0)).getDate() : 15,
  }

  return shipmentPromoObject;
}

export function getAddToCartNextSteps(product: ProductOnUser, measurement: UserBodyMeasurements | UserClothTypeSpecificBodyMeasurements) {
  const nextSteps: string[] = []; // if there is no next steps, the product can be added to cart right away.

  // TODO:
  // Actual consideration and steps to be done, determined by the product details
  // and the measurement requirements it has.
  return nextSteps;
}

export function getMeasurementsUsedInCart(state: CartState) {
  let results: {
    clothingType: ClothingType,
    fitType: FitType,
    creationDateTime: string,
  }[] = [];

  for (let key in state.cartProductList) {
    const cartItem = state.cartProductList[key];
    results.push({
      clothingType: cartItem.clothingType,
      fitType: cartItem.fitType,
      creationDateTime: cartItem.measurementCreationDateTime,
    })
  }

  return results;
}

// Returns "ENOUGH", "NOTENOUGH", or "LOADING"
export function canAddProductAmountToCart(state: CartState, cartStateItem: CartStateItem): "ENOUGH" | "NOTENOUGH" | "LOADING" | undefined {

  var cartStateItemHash = hash(cartStateItem);

  if (state.canAddItemDict[cartStateItemHash] !== undefined) {
    if (state.canAddItemDict[cartStateItemHash] === "LOADING") {
      return "LOADING";
    } else if (typeof state.canAddItemDict[cartStateItemHash] === "object") {
      return (state.canAddItemDict[cartStateItemHash] as any).canBeProvisioned ? "ENOUGH" : "NOTENOUGH";
    } else {
      return "LOADING";
    }

  } else {
    return "LOADING";
  }
}

// Action creators are generated for each case reducer function
export const { addItemCount, removeItemCount, removeItem, updateCourierInOrder, resetCourier,
  updateAddressInOrder, updateOrderState, resetCartState, resetCartStateAndSetOrderStatus,
  resetCartAddressAndPaymentState, updateUserTrigger, removeCurrentCoupon, removePaymentToken, setProductSpotlightList } = cartSlice.actions

export default cartSlice.reducer
import { Box, Dialog, IconButton, Slide, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { closeOverlayAndReset } from '../redux/UIOverlayPopupRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentButton from './OverlayComponentButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    dialogContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
      textAlign: 'center',
      width: '600px',
      height: '500px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    closeBarMobile: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end', 
    },
    textTitle: {
      fontSize: '17px',
      marginBottom: '20px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    textContent: {
      fontSize: '12px',
      marginBottom: '20px',
      marginLeft: '20px',
      marginRight: '20px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    textContent2: {
      fontSize: '13px',
      marginBottom: '40px',
      marginLeft: '20px',
      marginRight: '20px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        marginBottom: '40px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    }
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayPopup);
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useAppDispatch();
  const classes = useStyles();

  let children: JSX.Element[] = [];
  children.push(
    <Typography className={classes.textTitle} >
      <b>PERSONAL SIZE ALGORITHM</b>
    </Typography>
  )
  children.push(
    <Typography className={classes.textContent} >
      Cuma butuh tinggi badan, berat badan, dan tipe badan kamu <br></br>
      untuk estimasi ukuran badan dengan akurasi 95%
    </Typography>
  )
  children.push(
    <Typography className={classes.textContent2} >
      Hanya butuh dilakukan sekali untuk belanja seterusnya.
    </Typography>
  )
  children.push(
    <OverlayComponentButton
      formButtonText={"START MY SIZING JOURNEY"}
      handleClick={
        () => {
          dispatch(closeOverlayAndReset());
          // dispatch(openOverlayAccount());
        }
      }
      isTypeSubmit={false}
      buttonMarginTopBottom="0px"
      buttonMarginLeftRight="20px"
    />
    );

  if (isSmOrUp) {
    return (
      <Dialog
      maxWidth="lg"
      open={overlayView.status === UIOverlayViewStatus.On}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch(closeOverlayAndReset())}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialogContainer}>
        <div className={classes.dialogContentContainer}>
          {children}
        </div>
      </Dialog>
    )
  } else {
    return (
      <Dialog
      fullScreen
      open={overlayView.status === UIOverlayViewStatus.On}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch(closeOverlayAndReset())}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialogContainer}>
        <Box className={classes.closeBarMobile}>
          <IconButton color="inherit" onClick={() => dispatch(closeOverlayAndReset())} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <div className={classes.dialogContentContainer}>
          {children}
        </div>
      </Dialog>
    )
  }
}
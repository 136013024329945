import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { Close, Star, StarHalf, StarOutline } from '@material-ui/icons';
import { useFormik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import * as yup from 'yup';
import OverlayComponentForm from './OverlayComponentForm';
import OverlayComponentButton, { FareastButton } from './OverlayComponentButton';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  introductionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center', 
  },
  titleText: {
    fontSize: '5vw',
    fontWeight: 600,
    paddingTop: "16vw",
    paddingBottom: "6vw",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8vw',
      paddingTop: "3vw",
      paddingBottom: "1.6vw",
    }
  },
  titleTextSubtextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "90%",
  },
  titleTextSubtextTop: {
    fontSize: '3vw',
    fontWeight: 300,
    color: "#232323",
    textAlign: "center",
    marginTop: "-3vw",
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1vw',
      marginTop: "-1.1vw"
    }
  },
  titleTextSubtextFocus: {
    fontSize: '3vw',
    fontWeight: 400,
    color: "#000000",
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1vw',
    }
  },
  titleTextSubtextAjakan: {
    fontSize: '3vw',
    fontWeight: 300,
    color: "#232323",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1vw',
    }
  },
  titleTextSubtextEmojiBig: {
    fontSize: '68px',
  },
  titleTextSubtextEmoji: {
    fontSize: '24px',
    paddingLeft: '4px',
  },
  submitChidrenContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    padding: "0 4vw",
    [theme.breakpoints.up('md')]: {
      padding: '0 1.5vw',
    }
  },
}),
);


interface Props {
  handleSubmit: () => void
}

export default function PageResultsRating({ handleSubmit }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  let children: JSX.Element[] = [];

  children.push(
    <Box>
      <Typography className={classes.titleText}>
        Terima kasih banget atas<br></br>feedback nya kak!
      </Typography>
    </Box>
  );

  children.push(
    <Box className={classes.titleTextSubtextContainer}>
      <Typography className={classes.titleTextSubtextEmojiBig}>
        &#129395;
      </Typography>
      <Typography className={classes.titleTextSubtextTop}>
        Terima kasih sudah membantu kami. 
        Kalau kakak ada masalah apapun, jangan ragu hubungi 
        <span className={classes.titleTextSubtextFocus}> Mike (CEO & co-founder) di +6287813028340 </span>
        atau chat IG kami.
      </Typography>
      <Typography className={classes.titleTextSubtextAjakan}>
        Malam jam 12 juga boleh kok kak. 
        <span className={classes.titleTextSubtextEmoji}></span>
      </Typography>
    </Box>
  );

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <Box className={classes.buttonContainer}>
      <OverlayComponentButton
        isTypeSubmit={false}
        handleClick={handleSubmit}
        formButtonText="SUBMIT"
        buttonMarginTopBottom="30px"
        buttonMarginLeftRight="0px"
      />
    </Box>
  )

  return (
    <Box className={classes.introductionContainer}>
      {children}
      <Box className={classes.submitChidrenContainer}>
        {submitChildren}
      </Box>
    </Box>
  );
}
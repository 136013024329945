import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { BodyMeasurementDetailList, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { useAppSelector } from '../reduxhooks';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayViewBodyMeasurementDraftEditPanel from './OverlayViewMeasurementBodyPanelEditDraft';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  formContainer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subformTitle: {
    paddingBottom: '20px',
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '22px',
  },
  submitFormContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '6vw',
  },
  title: {
    fontSize: '35.54px',
    fontWeight: 600,
    marginBottom: '12px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '18px',
    }
  }, 
  sizeText: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '12px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '13.5px',
    }
  },
  svgUkuranText: {
    width: '24vw',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    }
  },
  svgPenyesuaianText: {
    width: '24vw',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: '10vw',
    }
  },
  imageItemContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-start',
      marginBottom: '10vw',
    }
  },
  imageItem: {
    width: '90%',
    height: 'fit-content',
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      paddingTop: '2vw',
    }
  },
  pelajariLanjut: {
    cursor: 'pointer',
    marginTop: '0.3vw',
    marginBottom: '1.2vw',
    [theme.breakpoints.only('xs')]: {
      marginBottom: '20vw',
    }
  },
  pelajariLanjutText: {
    fontSize: '15px',
    [theme.breakpoints.only('xs')]: {
      width: '80vw',
      fontSize: '13.5px',
    }
  },
}),
);

const useStylesSegment = (width:string, isNegative:boolean) => makeStyles((theme: Theme) =>
createStyles({
  container: {
    width: '90%', 
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    } 
  },
  namaPenyesuaian: {
    backgroundColor: '#E3E3E3',
    fontSize: '15px',
    padding: '6px 0px 6px 8px',
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      fontSize: '13.5px',
    }
  },
  horizontalBarProgressBackground: {
    height: '1px',
    width: '100%',
    backgroundColor: '#aaa',
    marginTop: '36px',
    marginBottom: '18px',
    position: 'relative',
  },
  verticalLeftBar: {
    position: 'absolute',
    height: '12px',
    width: '1px',
    left: '0%',
    top: '-6px',
    backgroundColor: '#aaa',
  },
  verticalRightBar: {
    position: 'absolute',
    height: '12px',
    width: '1px',
    left: '100%',
    top: '-6px',
    backgroundColor: '#aaa',
  },
  verticalMiddleBar: {
    position: 'absolute',
    height: '12px',
    width: '2px',
    left: '50%',
    top: '-6px',
    backgroundColor: '#000',
  },
  horizontalBarProgress: {
    position: 'absolute',
    height: '2px',
    left: isNegative? '' : '50%',
    right: isNegative? '50%' : '',
    bottom: '0px',
    backgroundColor: '#000',
    transition: 'all .25s linear',
    width: width,
  },
  lengthChangeText: {
    position: 'absolute',
    width: '60px',
    fontSize: '15px',
    padding: '10px 0px 0px 0px',
    bottom: '8px',
    left: isNegative ? '-12px' : '',
    right: isNegative ? '' : '-36px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '13.5px',
    }
  },
  verticalProgressBar: {
    position: 'absolute',
    height: '12px',
    width: '2px',
    left: isNegative ? '' : '100%',
    right: isNegative ? '100%' : '',
    top: '-6px',
    backgroundColor: '#000',
  },
  contentText: {
    fontSize: '11.26px',
    margin: '14px 0px 24px 0px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '10.13px',
    }
  },
}),
);

interface PenyesuaianProps {
  namaPenyesuaian: string
  valueBar: number
  maksimalBar: number
  contentText: string
}

function PenyesuaianSegment({namaPenyesuaian, valueBar, maksimalBar, contentText}: PenyesuaianProps) {
  let numberText = " cm";
  let ratio = "0%";
  let numberRatio = Math.abs(valueBar)/maksimalBar;
  if (numberRatio > 1) {
    numberRatio = 1;
  }
  if (valueBar >= 0) {
    numberText = "+ " + Math.round(Math.abs(valueBar)) + numberText;
    ratio = (50 * numberRatio).toString() + '%'; 
  } else {
    numberText = "- " + Math.round(Math.abs(valueBar)) + numberText;
    ratio = (50 * numberRatio).toString() + '%'; 
  }

  const classes = useStylesSegment(ratio, valueBar < 0)();

  return (
    <Box className={classes.container}>
      <Typography className={classes.namaPenyesuaian}>
        {namaPenyesuaian}
      </Typography>
      <div className={classes.horizontalBarProgressBackground}>
        <div className={classes.verticalLeftBar}>
        </div>
        <div className={classes.verticalRightBar}>
        </div>
        <div className={classes.verticalMiddleBar}>
        </div>
        <div className={classes.horizontalBarProgress}>
          <div className={classes.verticalProgressBar}>
          </div>
          <Typography className={classes.lengthChangeText}>
            {numberText}
          </Typography>
        </div>
      </div>
      <Typography className={classes.contentText}>
        {contentText + numberText}
      </Typography>
    </Box>
  )
}

function getSizeFromMeasurements(lingkarDada: number, lingkarPerut: number, panjangBaju: number) {

  let sizeObject: {
    sizeText: string
    lingkarDada: number
    lingkarPerut: number
    panjangBaju: number
    lebarBahu: number
  } = {
    sizeText: "",
    lingkarDada: 96,
    lingkarPerut: 83.3,
    panjangBaju: 66,
    lebarBahu: 51,
  };

  // S
  if (lingkarDada <= 93 && lingkarPerut <= 81 && panjangBaju <= 66) {
    sizeObject.sizeText = "Small (S)"
    sizeObject.lingkarDada = 87;
    sizeObject.lingkarPerut = 78; // Set to be 10% less
    sizeObject.panjangBaju = 68;
    sizeObject.lebarBahu = 44.5;
  }
  // M
  else if (lingkarDada <= 99 && lingkarPerut <= 88 && panjangBaju <= 68) {
    sizeObject.sizeText = "Medium (M)"
    sizeObject.lingkarDada = 95;
    sizeObject.lingkarPerut = 85;
    sizeObject.panjangBaju = 69;
    sizeObject.lebarBahu = 46;
  }
  // L
  else if (lingkarDada <= 105 && lingkarPerut <= 95 && panjangBaju <= 70) {
    sizeObject.sizeText = "Large (L)"
    sizeObject.lingkarDada = 102;
    sizeObject.lingkarPerut = 91;
    sizeObject.panjangBaju = 71;
    sizeObject.lebarBahu = 48;
  }
  // XL
  else if (lingkarDada <= 112 && lingkarPerut <= 104 && panjangBaju <= 72) {
    sizeObject.sizeText = "Extra Large (XL)"
    sizeObject.lingkarDada = 110;
    sizeObject.lingkarPerut = 99;
    sizeObject.panjangBaju = 73;
    sizeObject.lebarBahu = 49.5;
  }
  // XXL
  else {
    sizeObject.sizeText = "XX Large (XXL)"
    sizeObject.lingkarDada = 116;
    sizeObject.lingkarPerut = 106;
    sizeObject.panjangBaju = 74;
    sizeObject.lebarBahu = 51;
  }

  return sizeObject;
}

const adjustmentsMessage: {
  [key:string]: {[key:string]:string},
} = {
  lingkarDada: {
    'tambah': 'Tinggi dan berat badan kamu mengindikasikan bahwa kamu sering mendapatkan baju yang sempit di bagian dada, sehingga kami akan melebarkan bagian dada sebesar ',
    'kurang': 'Tinggi dan berat badan kamu mengindikasikan bahwa kamu sering mendapatkan baju yang terlalu lebar di bagian dada, sehingga kami akan mengurangi lebar bagian dada sebesar ',
  },
  lingkarPerut: {
    'tambah': 'Tipe badan kamu mengindikasikan bahwa kamu sering mendapatkan baju yang sempit di bagian perut, sehingga kami akan melebarkan bagian perut sebesar ',
    'kurang': 'Tipe badan kamu mengindikasikan bahwa kamu sering mendapatkan baju yang kelebaran di bagian perut, sehingga kami akan mengurangi lebar bagian perut sebesar ',
  },
  panjangBaju: {
    'tambah': 'Tinggi badan kamu mengindikasikan bahwa kamu sulit mendapatkan baju yang cukup panjang, sehingga kami menambahkan panjang baju kamu sebesar ',
    'kurang': 'Tinggi badan kamu mengindikasikan bahwa kamu sering mendapatkan baju yang terlalu panjang, sehingga kami mengurangi panjang baju kamu sebesar ',
  },
  lebarBahu: {
    'tambah': 'Tipe badan kamu mengindikasikan bahwa kamu sering mendapatkan baju yang sempit di bagian pundak, sehingga kami akan memperlebar bagian pundak sebesar ',
    'kurang': 'Tipe badan kamu mengindikasikan bahwa kamu sering mendapatkan baju yang terlalu lebar di bagian pundak, sehingga kami akan mengurangi lebar bagian pundak sebesar ',
  },
}

interface Props {
  handleSubmit: () => void
  userMeasurements: UserClothTypeSpecificBodyMeasurements | UserBodyMeasurements
  useClothingMeasurementDraft: boolean
  hasFinishedResultsRating: boolean
}

export default function PageCheckoutMeasurements({ handleSubmit, userMeasurements, useClothingMeasurementDraft, hasFinishedResultsRating }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [seeMore, setSeeMore] = React.useState<boolean>(false);

  // Prevents default, otherwise the page crashes
  const handleSubmitFunction = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    handleSubmit();
  }

  let personName = "";
  let sizeText = "";
  let adjustments:  {
    [key:string]: number,
  } ={
    lingkarDada: 0,
    lingkarPerut: 0,
    panjangBaju: 0,
    lebarBahu: 0,
  };
  let adjustmentsMax: {
    [key:string]: number,
  } = {
    lingkarDada: 15,
    lingkarPerut: 15,
    panjangBaju: 5,
    lebarBahu: 4.5,
  };
  let adjustmentsPercentage: {
    [key:string]: number,
  } = {
    lingkarDada: 0,
    lingkarPerut: 0,
    panjangBaju: 0,
    lebarBahu: 0,
  };
  let adjustmentsOrder: string[] = [];
  let bodyTypeImageSrc = process.env.PUBLIC_URL + '/assets/Body_Types_Grey_State_Rectangle.svg';

  if (userMeasurements.generatedMeasurements?.lingkarDada && userMeasurements.generatedMeasurements?.lingkarPerut
    && userMeasurements.generatedMeasurements?.panjangBaju && userMeasurements.generatedMeasurements?.lebarBahu 
    && userMeasurements.bodyType && userMeasurements.nama) {
    
    personName = userMeasurements.nama;
    
    if (userMeasurements.bodyType === "upright_triangle") {
      bodyTypeImageSrc = process.env.PUBLIC_URL + '/assets/Body_Types_Grey_State_Triangle.svg';
    } else if (userMeasurements.bodyType === "inverted_triangle") {
      bodyTypeImageSrc = process.env.PUBLIC_URL + '/assets/Body_Types_Grey_State_Inverted_Triangle.svg';
    }

    // Get the adjustment values relative to size
    let sizeObject = getSizeFromMeasurements(userMeasurements.generatedMeasurements.lingkarDada, userMeasurements.generatedMeasurements.lingkarPerut, userMeasurements.generatedMeasurements.panjangBaju);
    sizeText = sizeObject.sizeText;
    adjustments.lingkarDada = userMeasurements.generatedMeasurements.lingkarDada - sizeObject.lingkarDada;
    adjustmentsPercentage.lingkarDada = Math.abs(adjustments.lingkarDada) / adjustmentsMax.lingkarDada;
    if (userMeasurements.generatedMeasurements.lingkarPerut < sizeObject.lingkarPerut) {
      adjustments.lingkarPerut = userMeasurements.generatedMeasurements.lingkarPerut - sizeObject.lingkarPerut;
    } else if (userMeasurements.generatedMeasurements.lingkarPerut < sizeObject.lingkarDada) {
      adjustments.lingkarPerut = 0;
    } else {
      adjustments.lingkarPerut = userMeasurements.generatedMeasurements.lingkarPerut - sizeObject.lingkarDada;
    }
    adjustmentsPercentage.lingkarPerut = Math.abs(adjustments.lingkarPerut) / adjustmentsMax.lingkarPerut;
    adjustments.panjangBaju = userMeasurements.generatedMeasurements.panjangBaju - sizeObject.panjangBaju;
    adjustmentsPercentage.panjangBaju = Math.abs(adjustments.panjangBaju) / adjustmentsMax.panjangBaju;
    adjustments.lebarBahu = userMeasurements.generatedMeasurements.lebarBahu - sizeObject.lebarBahu;
    adjustmentsPercentage.lebarBahu = Math.abs(adjustments.lebarBahu) / adjustmentsMax.lebarBahu;

    // Order the adjustments
    for (let i = 0; i < 2; i++) {
      let highestChange = "panjangBaju";
      let highestValue = 0;
      for (let key in adjustmentsPercentage) {
        if (!adjustmentsOrder.includes(key) 
        && adjustmentsPercentage[key] > highestValue) {
          highestChange = key;
          highestValue = adjustmentsPercentage[key];
        }
      }
      adjustmentsOrder.push(highestChange);
    }
  }

  const handleClickFunction = () => {
    if (!hasFinishedResultsRating) {
      sendWebsiteEventMessage("MEASUREMENT RESULTS FOR USER " + personName);
      sendWebsiteEventMessage(sizeText);
      sendWebsiteEventMessage(JSON.stringify(userMeasurements));
      for (let adjIndex = 0; adjIndex < 2; adjIndex = adjIndex + 1) {
        const item = adjustmentsOrder[adjIndex];
        sendWebsiteEventMessage(item + " " + BodyMeasurementDetailList[item].bodyMeasurementName + " " + 
        adjustments[item] + " " + adjustmentsMax[item] + " " + adjustmentsMessage[item][adjustments[item] >= 0 ? 'tambah' : 'kurang']);
      }
    }
    handleSubmitFunction();
  }

  if (isMdOrUp) {
    return (
      <OverlayComponentBaseContainerWidth widthSmDown='400px' widthMdUp='600px'>
        <Typography className={classes.title}>
          Ukuran untuk {personName}
        </Typography>
        <Grid container>
          <Grid item xs={7}>
            <img src="https://d31zz7s33c4k72.cloudfront.net/CreatePersonalSize_Results_UkuranText.svg" className={classes.svgUkuranText}/>
            <Typography className={classes.sizeText}>
              {sizeText}
            </Typography>
            <img src="https://d31zz7s33c4k72.cloudfront.net/CreatePersonalSize_Results_PenyesuaianText.svg" className={classes.svgPenyesuaianText}/>
            {
              adjustmentsOrder.map((item, index) => {
                if (index < 2) {
                  return (
                    <PenyesuaianSegment
                      namaPenyesuaian={BodyMeasurementDetailList[item].bodyMeasurementName}
                      valueBar={adjustments[item]}
                      maksimalBar={adjustmentsMax[item]}
                      contentText={adjustmentsMessage[item][adjustments[item] >= 0 ? 'tambah' : 'kurang']}
                      />
                  )
                }
              })
            }
          </Grid>
          <Grid item xs={5} className={classes.imageItemContainer}>
            <img src={bodyTypeImageSrc} className={classes.imageItem}/>
          </Grid>
        </Grid>
        {
          !seeMore ? 
          <Box>
            <Box className={classes.pelajariLanjut} onClick={() => setSeeMore(true)}>
              <Typography className={classes.pelajariLanjutText}>
                <b>{"LIHAT DETAIL UKURAN +"}</b>
              </Typography>
            </Box>
          </Box> :
          <Box>
            <Box className={classes.pelajariLanjut} onClick={() => setSeeMore(false)}>
              <Typography className={classes.pelajariLanjutText}>
                <b>{"SEMBUNYIKAN DETAIL -"}</b>
              </Typography>
            </Box>
            <OverlayViewBodyMeasurementDraftEditPanel isEditingStart={false} 
              onEditStateChanged={(isEditingState) => {}} 
              allowNameEdit={false}
              allowEditing={false}
              useClothingMeasurementDraft={useClothingMeasurementDraft}
              nameAlignLeft={false}/>
          </Box>
        }
        <Box className={classes.submitFormContainer}>
          <OverlayComponentButton 
          isTypeSubmit={false}
          handleClick={handleClickFunction}
          formButtonText="Next" 
          buttonMarginTopBottom="10px"
          buttonMarginLeftRight="0px"/>
        </Box>
      </OverlayComponentBaseContainerWidth>
    );
  } else {
    return (
      <OverlayComponentBaseContainerWidth widthSmDown='100%' widthMdUp='260px'>
        <Grid container>
          <Grid item xs={4} className={classes.imageItemContainer}>
            <img src={bodyTypeImageSrc} className={classes.imageItem}/>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.title}>
              Ukuran untuk {personName}
            </Typography>
            <img src="https://d31zz7s33c4k72.cloudfront.net/CreatePersonalSize_Results_UkuranText.svg" className={classes.svgUkuranText}/>
            <Typography className={classes.sizeText}>
              {sizeText}
            </Typography>
            <img src="https://d31zz7s33c4k72.cloudfront.net/CreatePersonalSize_Results_PenyesuaianText.svg" className={classes.svgPenyesuaianText}/>
          </Grid>
        </Grid>
        <Box>
          {
            adjustmentsOrder.map((item, index) => {
              if (index < 2) {
                return (
                  <PenyesuaianSegment
                    namaPenyesuaian={BodyMeasurementDetailList[item].bodyMeasurementName}
                    valueBar={adjustments[item]}
                    maksimalBar={adjustmentsMax[item]}
                    contentText={adjustmentsMessage[item][adjustments[item] >= 0 ? 'tambah' : 'kurang']}
                    />
                )
              }
            })
          }
        </Box>
        {
          !seeMore ? 
          <Box>
            <Box className={classes.pelajariLanjut} onClick={() => setSeeMore(true)}>
              <Typography className={classes.pelajariLanjutText}>
                <b>{"LIHAT DETAIL UKURAN +"}</b>
              </Typography>
            </Box>
          </Box> :
          <Box>
            <Box className={classes.pelajariLanjut} onClick={() => setSeeMore(false)}>
              <Typography className={classes.pelajariLanjutText}>
                <b>{"SEMBUNYIKAN DETAIL -"}</b>
              </Typography>
            </Box>
            <OverlayViewBodyMeasurementDraftEditPanel isEditingStart={false} 
              onEditStateChanged={(isEditingState) => {}} 
              allowNameEdit={false}
              allowEditing={false}
              useClothingMeasurementDraft={useClothingMeasurementDraft}
              nameAlignLeft={false}/>
          </Box>
        }
        <Box className={classes.submitFormContainer}>
          <OverlayComponentButton 
          isTypeSubmit={false}
          handleClick={handleClickFunction}
          formButtonText="Next" 
          buttonMarginTopBottom="10px"
          buttonMarginLeftRight="0px"/>
        </Box>
      </OverlayComponentBaseContainerWidth>
    );
  }
}
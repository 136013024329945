import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

interface StyleProps {
  padding: string,
  alignItems: string, // Default is center
}

const useStyles = ({padding,alignItems}: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: alignItems,
    padding: padding,
  },
}),
);

interface Props {
  padding?: string,
  // justifyContent is not supported, this is a vertical modifier
  alignItems?: string, // Default is center
  children?: JSX.Element | JSX.Element[];
}

export default function OverlayComponentBaseContainerHorizontal({ padding, alignItems, children }: Props) {
  const classes = useStyles({
    padding: padding ? padding : '0px',
    alignItems: alignItems ? alignItems : 'center',
  })();

  return (
    <Box className={classes.container}>
      {
        children
      }
    </Box>
  );
}
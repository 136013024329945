import { Box, Paper, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useAppSelector } from '../reduxhooks';
import PageComponentElementProduct from './PageComponentElementProduct';
import OptionsSlider from './PageComponentOptionsSlider';
import OptionsItemContainer from './PageComponentOptionsItemContainer';
import { shuffle } from './PageHome9RandomItems';
import { Collection } from 'common-ts/dist/models/Product';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  productSuggestionsContainer: {
    paddingBottom: '40px',
    marginTop: '40px',
    marginBottom: '80px',
  },
  title: {
    margin: '10px 40px',
    [theme.breakpoints.up("sm")]: {
      margin: '0px 0px 0px 72px',
      fontSize: '2.3vw',
    },
    fontSize: '5.5vw',
    fontWeight: 600,
  },
  productContainerSpacer: {
    // padding: '0px 12px',
    margin: '0',
    // boxSizing: 'border-box',
    // flexGrow: 0,
    // maxWidth: '14%',
    // flexBasis: '14%',
    flex: '0 0 auto',
    width: '10px',
  },
  productContainer: {
    padding: '0px 8px',
    marginTop: "-20px",
    boxSizing: 'border-box',
    flexGrow: 0,
    maxWidth: '25%',
    flexBasis: '25%',
    flex: '0 0 auto',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '60%',
      flexBasis: '60%',
    }
  }
}));

interface Props {
  seedNumber: number,
  excludedProductId?: string,
  titleText?: string,
  marginTop?: string
  marginBottom?: string
}

export function PageProductSuggestions({seedNumber, excludedProductId, titleText, marginTop, marginBottom}: Props) {
  const productsList = useAppSelector(state => Object.keys(state.productsList.productsDictionary));
  const productsDictionary = useAppSelector(state => state.productsList.productsDictionary);
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  // const numberOfItemsRemaining = productSizeAvailabilityDict?.[productId]?.numberOfItemsRemaining;
  const productDatabase = useAppSelector(state => state.productsList);
  const classes = useStyles();
  const theme = useTheme();

  // We filter out products that are not in discover
  const discoveryProductsList: {[key: string]: number} = {};
  for (var i = 0; i < productDatabase.discoveryCollectionsOrder.length; i++) {
    let collectionId = productDatabase.discoveryCollectionsOrder[i];
    let collectionDetail: Collection = productDatabase.collectionsDictionary[collectionId];
    for (let j = 0; j < collectionDetail.productItemsList.length; j++) {
      let productItemId = collectionDetail.productItemsList[j];
      discoveryProductsList[productItemId] = 1;
    }
  }

  let productsListRandomOrder: string[] = shuffle(productsList, seedNumber);

  return (
    <Box className={classes.productSuggestionsContainer} 
      style={{ 
        marginTop: marginTop ? marginTop : undefined,
        marginBottom: marginBottom ? marginBottom : undefined,
      }}>
      <Typography  variant="h5" className={classes.title}>
        {
          titleText ? titleText : "Cari Baju Buat Acara Lain"
        }
      </Typography>
      <OptionsSlider
        title={[]}
        contentsChange={JSON.stringify(productsListRandomOrder)}
        contents={
          [<Box className={classes.productContainerSpacer}>

          </Box>].concat(
          productsListRandomOrder.filter(function(item) {
            let productItem = productsDictionary[item];
            let numberOfItemsRemaining = productSizeAvailabilityDict?.[productItem.productId]?.numberOfItemsRemaining;
            if (productItem.isSoldOut || productItem.isComingAgainSoon || numberOfItemsRemaining === 0 || productItem.productId === excludedProductId) {
              return false; // skip
            }
            if (discoveryProductsList[productItem.productId] === undefined) {
              return false;
            }
            return true;
          }).slice(0, 24).map((item, i) => {
            let productItem = productsDictionary[item];
            return (
              <Box className={classes.productContainer}>
                <PageComponentElementProduct 
                product_id={productItem.productId}
                image_url={productItem.displayImage} 
                image_url_hover={productItem.displayImageHover} 
                title={productItem.name}
                discountedPrice={productItem.discountedPrice}
                originalPrice={productItem.originalPrice}
                isSoldOut={productItem.isSoldOut}
                isComingAgainSoon={productItem.isComingAgainSoon}
                product_url={`/shop/product/` + productItem.productId} /> 
              </Box>)
            }))
        }
        marginDesktop='0px 0px 0px 0px'
        marginMobile='0px 0px 0px 0px'
      />
    </Box>
    );
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ProductOnUser from 'common-ts/dist/models/Product';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    introductionContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    svgTextMain: {
      width: '54vw',
      marginBottom: '14px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
        marginTop: '20px',
      }
    },
    mulaiButton: {
      marginTop: '1.7vw',
      padding: '5px 40px 5px 40px',
      borderColor: '#000000',
      textTransform: 'none',
      [theme.breakpoints.only('xs')]: {
        marginTop: '8vw',
      }
    },
    submitChidrenContainer: {
      width: '54vw',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      }
    },
    gridContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    headerText: {
      fontWeight: 600,
      fontSize: '18px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '4.8vw',
      }
    },
    liveBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '2.5px 10px 3px 10px',
      borderRadius: 50,
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.10)'
    },
    liveDot: {
      width: '12px',
      height: '12px',
      backgroundColor: '#12AB21',
      borderRadius: 100,
      [theme.breakpoints.only('xs')]: {
        width: '3.2vw',
        height: '3.2vw',
      }
    },
    blackDot: {
      width: '10px',
      height: '10px',
      backgroundColor: '#232323',
      borderRadius: 100,
      [theme.breakpoints.only('xs')]: {
        width: '2.4vw',
        height: '2.4vw',
      }
    },
    liveText: {
      paddingTop: '3px',
      fontSize: '12px',
      letterSpacing: '0.15vw',
      fontWeight: 700,
      [theme.breakpoints.only('xs')]: {
        paddingTop: '0.8vw',
        fontSize: '3.2vw',
      }
    },
    profileBox: {
      marginTop: '18px',
      marginBottom: '18px',
      padding: '11px 11px 11px 11px',
      borderRadius: '10px',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.10)',
      [theme.breakpoints.only('xs')]: {
        marginTop: '4vw',
        marginBottom: '8vw',
        padding: '3vw 3vw 1.8vw 3vw',
      }
    },
    profileImage: {
      width:'80px',
      height: '80px',
      borderRadius: 80,
      objectFit: "cover",
      objectPosition: '50% top'

    },
    subtitle: {
      fontSize: '9px',
      color: '#AFAFAF',
      fontWeight: 300,
      [theme.breakpoints.only('xs')]: {
        fontSize: '2.6vw',
      }
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5px',
      [theme.breakpoints.only('xs')]: {
        gap: '0.4vw',
      }
    },
    smallBoldText: {
      fontSize: '9px',
      fontWeight: 600,
      [theme.breakpoints.only('xs')]: {
        fontSize: '2.6vw',
      }
    },
    smallGrayText: {
      fontSize: '9px',
      color: '#AFAFAF',
      fontWeight: 400,
      [theme.breakpoints.only('xs')]: {
        fontSize: '2.6vw',
      }
    },
    smallBlackText: {
      fontSize: '9px',
      color: '#232323',
      fontWeight: 300,
      [theme.breakpoints.only('xs')]: {
        fontSize: '2.6vw',
      }
    },
    lastSeenText: {
      fontSize: '8px',
      color: '#0446C5',
      fontWeight: 600,
      margin: '7px 0px 3px 0px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '2.2vw',
      }
    },
    consultationBox: {
      marginTop: '18px',
      padding: '3vw 4vw 4vw 4vw',
      borderRadius: '10px',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.10)',
      [theme.breakpoints.only('xs')]: {
        marginTop: '3vw',
      }
    },
    consultationTitle: {
      fontSize: '8px',
      fontWeight: 600,
      paddingTop: '3px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '3.4vw',
      }
    },
    divider: {
      height: '1.5px',
      width: '100%',
      backgroundColor: '#EEEEEE',
      marginTop: '18px',
      marginBottom: '18px',
      [theme.breakpoints.only('xs')]: {
        marginTop: '3.2vw',
        marginBottom: '2.8vw',
      }
    },
    marginTop: {
      marginTop: '18px',
      [theme.breakpoints.only('xs')]: {
        marginTop: '2.4vw',
      }
    },
    expertiseText: {
      fontSize: '9px',
      color: '#0B8325',
      fontWeight: 400,
      [theme.breakpoints.only('xs')]: {
        fontSize: '2.6vw',
      }
    },
    expertiseBox: {
      [theme.breakpoints.only('xs')]: {
      }
    },
  }),
);

interface Props {
  selectedRegularSize: RegularSizeValue,
  height: number,
  weight: number,
  productDetail: ProductOnUser | undefined
}

export default function PageCheckoutMeasurements({ selectedRegularSize, height, weight, productDetail }: Props) {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles({ isMdOrUp });

  const expertiseArray = ['Kemeja', 'Slim Fit', 'Regular Fit', 'Kemeja Pendek', 'Kemeja Panjang', 'Polo']

  let children: JSX.Element[] = [];
  let submitChildren: JSX.Element[] = [];

  children.push(
    <Grid container>
      <Grid xs={12} className={classes.gridContainer}>
        <Typography className={classes.headerText}>
          Terhubung Dengan
        </Typography>
        <Box className={classes.liveBox}>
          <div className={classes.liveDot}/>
          <Typography className={classes.liveText}>
            LIVE
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Box className={classes.profileBox}>
          <Grid container>
            <Grid xs={4}>
              <Box style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative' }}>
                <img className={classes.profileImage} src='https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/Sartono.jpeg'/>
                <div className={classes.liveDot} style={{
                  position: 'absolute',
                  bottom: '5px',
                  right: '15px',
                  border: 'solid',
                  borderWidth: '3px',
                  borderColor: '#ffffff'
                }}/>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Typography className={classes.headerText}>
                Sartono
              </Typography>
              <Typography className={classes.subtitle}>
                Pattern Maker, 5 Tahun
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '3px',
                  marginTop: '10px'
                }}
              >
                {
                  expertiseArray.length && expertiseArray.length > 0 && (
                    expertiseArray.slice(0, 2).map((expertise, index) => (
                      <Box
                      key={index}
                      style={{
                        borderRadius: '5px',
                        padding: '1px 8px 1px 8px',
                        backgroundColor: '#cbfed3'
                      }}
                      >
                        <Typography
                          className={classes.expertiseText}
                        >
                          {expertise}
                        </Typography>
                      </Box>
                    ))
                  )
                }
                {
                  expertiseArray.length && expertiseArray.length > 2 && (
                      <Box
                      style={{
                        borderRadius: '5px',
                        padding: '1px 8px 1px 8px',
                        backgroundColor: '#cbfed3'
                      }}
                      >
                        <Typography
                          className={classes.expertiseText}
                        >
                          +{expertiseArray.length - 2}
                        </Typography>
                      </Box>
                  )
                }
              </Box>
            </Grid>
            {/* <Grid xs={7} className={classes.textContainer} style={{
              marginTop: '3.2vw',
              marginBottom: '2vw'}}
            >
              <Typography className={classes.smallBoldText}>
                Working Hours
              </Typography>
              <Typography className={classes.smallGrayText}>
                Senin - Rabu 00:00 - 00:00
              </Typography>
            </Grid>
            <Grid xs={5} className={classes.textContainer} style={{
              marginTop: '3.2vw',
              marginBottom: '2vw'}}
            >
              <Typography className={classes.smallBoldText}>
                Konsultasi Berhasil
              </Typography>
              <Typography className={classes.smallGrayText}>
                {calculateSuccessfulConsultations()} Kali
              </Typography>
            </Grid> */}
            <Grid xs={12}>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography className={classes.lastSeenText}>
                  Last Seen: {generateLastSeen()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={classes.headerText}>
          Detail Konsultasi
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Box className={classes.consultationBox}>
          <Grid container>
            <Grid xs={12}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '2.2vw',
                  gap: '8px'
                }}
              >
                <ConfirmationNumberOutlinedIcon style={{fontSize: '5vw'}}/>
                <Typography className={classes.consultationTitle}>
                  Informasi Konsultasi
                </Typography>
              </Box>
            </Grid>
            <Grid xs={7}>
              <Typography className={classes.smallBoldText}>
                Tanggal Konsultasi
              </Typography>
              <Typography className={classes.smallBlackText}>
                {getCurrentDateTime()}
              </Typography>
            </Grid>
            <Grid xs={5}>
              <Typography className={classes.smallBoldText}>
                ID Konsultasi
              </Typography>
              <Typography className={classes.smallBlackText}>
                {generateRandomId()}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <div className={classes.divider}/>
            </Grid>
            <Grid xs={12}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '2.2vw',
                  gap: '8px'
                }}
              >
                <AccessibilityNewIcon style={{fontSize: '5vw'}}/>
                <Typography className={classes.consultationTitle}>
                  Informasi Kamu
                </Typography>
              </Box>
            </Grid>
            <Grid xs={7}  className={classes.textContainer}>
              <Typography className={classes.smallBoldText}>
                Tinggi Badan
              </Typography>
              <Typography className={classes.smallBlackText}>
                {height} cm
              </Typography>
            </Grid>
            <Grid xs={5}  className={classes.textContainer}>
              <Typography className={classes.smallBoldText}>
                Berat Badan
              </Typography>
              <Typography className={classes.smallBlackText}>
                {weight} kg
              </Typography>
            </Grid>
            <Grid xs={7} className={classes.textContainer} style={{
              marginTop: '3.2vw'
              }}
            >
              <Typography className={classes.smallBoldText}>
                Size Terdekat
              </Typography>
              <Typography className={classes.smallBlackText}>
                {selectedRegularSize}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12} container justifyContent='center'>
        <div
          style={{
            display: 'flex',
            gap: '7px',
            marginTop: '30px'
          }}
        >
          <div className={classes.blackDot}/>
          <div className={classes.blackDot}/>
          <div className={classes.blackDot}/>
        </div>
      </Grid>
      <Grid xs={12} container justifyContent='center' direction='column' alignItems='center'>
        <div
          style={{
            display: 'flex',
            margin: '20px 0px 14px 0px'
          }}
        >
          <img src='https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/CHAT_WA.png' style={{
            width: isMdOrUp ? '400px' : '70vw',
          }}
          onClick={() => {
            const url = 'https://wa.me/6287813028340?text=Halo%20Instant%20Size%20Team,' + 
            '%20saya%20mau%20buat%20ukuran%20' + 
            selectedRegularSize +
            '.%20Dengan%20tinggi%20badan%20' + height + '%20cm%20dan%20berat%20badan%20' + weight + '%20kg.%20' 
            + (productDetail ? `Untuk%20Produk%20${productDetail.name}.` : '');
            window.open(url, '_blank');
          }}
          />
        </div>
        <div>
          <Typography className={classes.smallBlackText}>
            Semua sesi konsultasi size dilakukan via Whatsapp.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.introductionContainer}>
      {children}
      <Box className={classes.submitChidrenContainer}>
        {submitChildren}
      </Box>
    </Box>
  );
}

function generateRandomId(): string {
  const now = new Date();
  const dateString = now.toISOString().slice(2,10).replace(/-/g, '');
  const randomNumber = Math.floor(Math.random() * 10000).toString().padStart(5, '0');
  return `${dateString}${randomNumber}`;
}

function generateLastSeen(): string {
  const now = new Date();
  const currentHour = now.getHours();
  
  if (currentHour >= 21 || currentHour < 8) {
    const lastActiveTime = new Date(now);
    lastActiveTime.setHours(21, 0, 0, 0); 
    if (currentHour < 8) lastActiveTime.setDate(lastActiveTime.getDate() - 1);
    const diffInMinutes = Math.floor((now.getTime() - lastActiveTime.getTime()) / (1000 * 60));
    
    if (diffInMinutes > 60) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else {
      return `${diffInMinutes} minutes ago`;
    }
  } else {
    const randomMinutes = Math.floor(Math.random() * 50) + 10; 
    return `${randomMinutes} minutes ago`;
  }
}

function calculateSuccessfulConsultations(): number {
  const baseDate = new Date(2024, 7, 20);
  const currentDate = new Date();
  const daysSinceBase = Math.floor((currentDate.getTime() - baseDate.getTime()) / (1000 * 3600 * 24));
  
  const baseValue = 10;
  const additionalConsultations = daysSinceBase * (Math.random() < 0.5 ? 1 : 2);
  
  return baseValue + additionalConsultations;
}

function getCurrentDateTime(): string {
  const now = new Date();
  const date = now.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
  const time = now.toLocaleTimeString('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit'
  });
  return `${date}, ${time}`;
}
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import React from 'react';
import { useAppSelector } from '../reduxhooks';
import { BlockProductArrayCenterData, renderBlockElement } from './PageComponentElementBlocks';
import PageItemParent from './PageComponentElementItemParentSize';
import PageElementProduct from './PageComponentElementProduct';
import OptionsSlider from './PageComponentOptionsSlider';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  productListBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

interface FormatProps {
  imageUrl: string
  textTitle: string
  linkTo: string
}

export function shuffle(array: string[], seed: number) {                // <-- ADDED ARGUMENT
  var m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);        // <-- MODIFIED LINE

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed                                     // <-- ADDED LINE
  }

  return array;
}

function random(seed: number) {
  var x = Math.sin(seed++) * 10000; 
  return x - Math.floor(x);
}

export default function LastToOrder() {
  const productsList = useAppSelector(state => Object.keys(state.productsList.productsDictionary));
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  // We seed by day of the month
  var today = new Date();
  var dd = today.getDate();

  let productsInCategory: string[] = shuffle(productsList, dd + 5).slice(0, 6);

  let param: BlockProductArrayCenterData = {
    productIds: productsInCategory,
  
    containerWidthXs: '100%',
    containerWidthSm: '81%',
    containerWidthMd: '81%',
    containerWidthLg: '81%',
    productWidthXs: '48%',
    productWidthSm: '30%',
    productWidthMd: '30%',
    productWidthLg: '30%',
  
    containerMargin: isDesktop ? '85px 0px 60px 0px' : '65px 0px 60px 0px',
    productPadding: isDesktop ? '20px 0px 9px 0px' : '8px 0px 9px 0px',
  }

  return (
    <Box className={classes.productListBody}>
      {
        renderBlockElement({code: "BlockProductArrayCenter", contentJson: JSON.stringify(param)})
      }
    </Box>
  );
}
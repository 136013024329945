import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Box, Button, CircularProgress, Grid, Grow, MenuItem, MenuList, Paper, Popper, TextField, Typography } from '@material-ui/core';
import React, { SetStateAction } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import zIndex from '@material-ui/core/styles/zIndex';
import { Address } from 'common-ts/dist/models/Address';
import { inputAddressForm, resetAddressDraft } from '../redux/AddressRedux';
import OverlayComponentButton from './OverlayComponentButton';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { ArrowForward } from '@material-ui/icons';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '300px',
    [theme.breakpoints.only('xs')]: {
      width: '240px',
    }
  },
  textFieldKodePos: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '236px',
    [theme.breakpoints.only('xs')]: {
      width: '240px',
    }
  },
  form: {
    height: '100%',
    padding: '0px 24px',
    marginTop: '-10px',
  },
  formRow: {
  },
  formRowKotaKecamatan: {
    [theme.breakpoints.up('md')]: {
      display: "flex",
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      alignItems: 'baseline',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  subFormTitle: {
    marginTop: '28px',
  },
  popper: {
    zIndex: zIndex.modal,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px) !important',
    }
  },
  menuPaper: {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '40ch',
    width: '80ch',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px) !important',
    }
  },
  menuItem: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    }
  },
  formNavButtonsContainer: {
    height: 'auto',
    width: '100%',
    right: '0px',
    display: 'flex',
    justifyContent: 'right',
  },
  formNavButtons: {
    marginTop: '20px',
    marginRight: '18px',
    marginBottom: '24px',
  },
  pilihKecamatanTextBulletContainer: {
    display: "flex",
    alignItems: "center",
  },
  pilihKecamatanTextBullet: {
    fontSize: "12px",
    fontWeight: 600,
    backgroundColor: "#232323",
    color: "#ffffff",
    borderRadius: "12px",
    marginRight: "6px",
    width: "18px",
    height: "17px",
    paddingTop: "1px",
    textAlign: "center",
  },
  pilihKecamatanTextBulletGreyed: {
    fontSize: "12px",
    fontWeight: 600,
    backgroundColor: "#c2c2c2",
    color: "#ffffff",
    borderRadius: "12px",
    marginRight: "6px",
    width: "18px",
    height: "17px",
    paddingTop: "1px",
    textAlign: "center",
  },
  pilihKecamatanText: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "8px",
    marginBottom: "4px",
    letterSpacing: "0.3px",
  },
  pilihKecamatanTextGreyed: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "8px",
    marginBottom: "4px",
    letterSpacing: "0.3px",
    color: "#c2c2c2"
  },
  pilihKecamatanTextInvisible: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "6px",
    marginBottom: "6px",
    letterSpacing: "0.3px",
    color: "#ffffff"
  },
  circularProgressContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "250px",
  },
  destinationOptionsHeader: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#ffffff",
    width: 'calc(100% - 16px) !important',
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderBottom: "solid 1px #e4e4e4"
  },
  destinationOptionsHeaderSpacer: {
    backgroundColor: "#ffffff",
    width: "100%",
    paddingBottom: "16px",
  }
}),
);

const validationSchema = yup.object({
  addressName: yup.string()
    .min(1, 'Masukkan nama alamat anda')
    .required('Nama alamat dibutuhkan'),
  alamat: yup.string()
    .min(1, 'Masukkan alamat anda')
    .required('Alamat dibutuhkan'),
  provinsi: yup.string()
    .min(1, 'Pilih provinsi anda')
    .required('Provinsi dibutuhkan'),
  kabupaten: yup.string()
    .min(1, 'Pilih kabupaten anda')
    .required('Kabupaten dibutuhkan'),
  kecamatan: yup.string()
    .min(1, 'Pilih kecamatan anda')
    .required('Kecamatan dibutuhkan'),
  kodePos: yup.number()
    .min(10000, 'Kode pos anda terlalu pendek')
    .max(99999, 'Kode pos anda terlalu panjang')
    .required('Kode pos is required'),
  keterangan: yup.string(),
  phoneNumber: yup.string()
    .min(6, 'Nomor telepon anda terlalu pendek'),
});

interface Props {
  handleSubmit: (address: Address) => void
}

const mergeDestArray = (a: any, b: any, predicate = (a: any, b: any) => a.destination_code === b.destination_code) => {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem: any) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c;
}

export default function OverlayViewAddressEdit({ handleSubmit } : Props) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const currentAddress = useAppSelector(state => state.addressList.addressDraft);

  const [provinsiAnchorEl, setProvinsiAnchorEl] = React.useState<null | HTMLElement>(null);
  const [kabupatenAnchorEl, setKabupatenAnchorEl] = React.useState<null | HTMLElement>(null);
  const [kecamatanAnchorEl, setKecamatanAnchorEl] = React.useState<null | HTMLElement>(null);
  const [provinsiOptions, setProvinsiOptions] = React.useState<null | any>(null);
  const [kabupatenOptions, setKabupatenOptions] = React.useState<null | any>(null);
  const [kecamatanOptions, setKecamatanOptions] = React.useState<null | any>(null);
  const [kiriminAjaObject, setKiriminAjaObject] = React.useState<any>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  if (kiriminAjaObject === null && currentAddress.destinationKiriminAjaObject) {
    setKiriminAjaObject(currentAddress.destinationKiriminAjaObject);
  }

  const formik = useFormik({
    initialValues: {
      addressName: currentAddress.addressName,
      alamat: currentAddress.address,
      provinsi: currentAddress.provinsi,
      kabupaten: currentAddress.kabupaten,
      kecamatan: currentAddress.kecamatan,
      kodePos: currentAddress.kodePos,
      keterangan: currentAddress.keterangan,
      phoneNumber: currentAddress.phoneNumber,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      let address: Address = {
        addressName: values.addressName,
        address: values.alamat,
        provinsi: values.provinsi,
        kabupaten: values.kabupaten,
        kecamatan: values.kecamatan,
        kodePos: values.kodePos,
        kotaKecamatanText: values.kecamatan + ", " + values.kabupaten + ", " + values.provinsi,
        keterangan: values.keterangan,
        phoneNumber: values.phoneNumber,
        destinationObject: {},
        destinationKiriminAjaObject: kiriminAjaObject,
      };
      dispatch(inputAddressForm(address));

      // The address submitted is not the currentAddress, 
      // as this is a hook and its value is likely to change.
      // We use a single source of truth to carry out this action. 
      // The destination object is determined from the starting definition of address.
      handleSubmit(address);
    },
  });

  function onClickProvinsi(event: any) {
    if (kabupatenAnchorEl !== null) {
      setKabupatenAnchorEl(null);
    }
    if (kecamatanAnchorEl !== null) {
      setKecamatanAnchorEl(null);
    }

    setProvinsiAnchorEl(event.target);
    fetchProvinsiOptions();
  }

  function onClickKabupaten(event: any) {
    if (provinsiAnchorEl !== null) {
      setProvinsiAnchorEl(null);
    }
    if (kecamatanAnchorEl !== null) {
      setKecamatanAnchorEl(null);
    }

    setKabupatenAnchorEl(event.target);
    if (!kabupatenOptions && kiriminAjaObject?.provinsi_id) {
      fetchKabupatenOptions(kiriminAjaObject.provinsi_id);
    }
  }

  function onClickKecamatan(event: any) {
    if (provinsiAnchorEl !== null) {
      setProvinsiAnchorEl(null);
    }
    if (kabupatenAnchorEl !== null) {
      setKabupatenAnchorEl(null);
    }

    setKecamatanAnchorEl(event.target);
    if (!kabupatenOptions && kiriminAjaObject?.kabupaten_id) {
      fetchKecamatanOptions(kiriminAjaObject.kabupaten_id);
    }
  }

  async function fetchProvinsiOptions() {
    setIsLoading(true);

    const response = await axios({
        method: 'post',
        url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/kirimin-aja-proxy",
        data: JSON.stringify({
          "axiosConfig": {
            "method": "post",
            "url": "https://client.kiriminaja.com/api/mitra/province"
          }
        })
    });
    setProvinsiOptions(response.data?.returnBody?.datas);
    
    setIsLoading(false);
  }

  async function fetchKabupatenOptions(arg: number) {
    setIsLoading(true);

    const response = await axios({
        method: 'post',
        url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/kirimin-aja-proxy",
        data: JSON.stringify({
          "axiosConfig": {
            "method": "post",
            "url": "https://client.kiriminaja.com/api/mitra/city",
            "data": {
              "provinsi_id": arg
            }
          }
        })
    });
    setKabupatenOptions(response.data?.returnBody?.datas);

    setIsLoading(false);
  }

  async function fetchKecamatanOptions(arg: number) {
    setIsLoading(true);

    const response = await axios({
        method: 'post',
        url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/kirimin-aja-proxy",
        data: JSON.stringify({
          "axiosConfig": {
            "method": "post",
            "url": "https://client.kiriminaja.com/api/mitra/kecamatan",
            "data": {
              "kabupaten_id": arg
            }
          }
        })
    });
    setKecamatanOptions(response.data?.returnBody?.datas);

    setIsLoading(false);
  }

  return (
    <form className={classes.form} noValidate autoComplete="chrome-off" onSubmit={formik.handleSubmit}>
      <Typography variant="h5" className={classes.subFormTitle}>
        Your Address
      </Typography>
      <div className={classes.formRow}>
        <TextField
          margin="normal"
          className={classes.textField}
          id="addressName"
          name="addressName"
          label="Nama Alamat"
          type="text"
          value={formik.values.addressName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressName && Boolean(formik.errors.addressName)}
          helperText={(formik.touched.addressName && formik.errors.addressName) || "Seperti 'Rumah' atau 'Kantor'"}
        />
      </div>
      <div className={classes.formRow}>
        <TextField
          margin="normal"
          className={classes.textField}
          id="alamat"
          name="alamat"
          label="Alamat"
          type="text"
          value={formik.values.alamat}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.alamat && Boolean(formik.errors.alamat)}
          helperText={(formik.touched.alamat && formik.errors.alamat) || "Tulis nama jalan, nomor rumah, nomor kompleks, nama gedung, unit apartemen"}
        />
      </div>
      <div className={classes.formRow}>
        <TextField
          margin="normal"
          className={classes.textField}
          placeholder="Pilih Provinsi"
          InputLabelProps={{
            shrink: true,
          }}
          id="provinsi"
          name="provinsi"
          label="Provinsi"
          type="text"
          value={formik.values.provinsi}
          onClick={onClickProvinsi}
          InputProps={{
            readOnly: true,
          }}
          onBlur={(e) => {
            setProvinsiAnchorEl(null);
            formik.handleBlur(e);
          }}
          error={(formik.touched.provinsi && !formik.values.provinsi)}
        />
        <Popper className={classes.popper} open={Boolean(provinsiAnchorEl)} anchorEl={provinsiAnchorEl} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center bottom' }}
            >
              <Paper className={classes.menuPaper}>
                {
                  isLoading ?
                  <Box className={classes.circularProgressContainer}>
                    <CircularProgress/>
                  </Box>:
                  <MenuList autoFocusItem={false} id="menu-list-grow">
                    {
                      provinsiOptions?.map((content: any, index: any) => {
                        var provinsi_name = content.provinsi_name;
                        var provinsi_id = content.id;
                        return (
                        <MenuItem className={classes.menuItem} onClick={(event) => {
                          sendWebsiteEventMessage("Pilih provinsi selected: " + provinsi_name);
                          
                          formik.setFieldValue("provinsi", provinsi_name);
                          formik.setFieldValue("kabupaten", "");
                          formik.setFieldValue("kecamatan", "");
                          setKiriminAjaObject({
                            provinsi: provinsi_name,
                            provinsi_id: provinsi_id
                          })
                          
                          fetchKabupatenOptions(provinsi_id);

                          // Close anchor element
                          setProvinsiAnchorEl(null);
                        }} >
                          {provinsi_name}
                        </MenuItem>);
                      })
                    }
                  </MenuList>
                }
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={classes.formRow}>
        <TextField
          margin="normal"
          className={classes.textField}
          placeholder="Pilih Kabupaten"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!formik.values.provinsi}
          id="kabupaten"
          name="kabupaten"
          label="Kabupaten"
          type="text"
          value={formik.values.kabupaten}
          onClick={onClickKabupaten}
          InputProps={{
            readOnly: true,
          }}
          onBlur={(e) => {
            setKabupatenAnchorEl(null);
            formik.handleBlur(e);
          }}
          error={(formik.touched.kabupaten && !formik.values.kabupaten)}
        />
        <Popper className={classes.popper} open={Boolean(kabupatenAnchorEl)} anchorEl={kabupatenAnchorEl} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center bottom' }}
            >
              <Paper className={classes.menuPaper}>
                {
                  isLoading ?
                  <Box className={classes.circularProgressContainer}>
                    <CircularProgress/>
                  </Box>:
                  <MenuList autoFocusItem={false} id="menu-list-grow">
                    {
                      kabupatenOptions?.map((content: any, index: any) => {
                        var kabupaten_name = content.type + " " + content.kabupaten_name;
                        var kabupaten_id = content.id;
                        return (
                        <MenuItem className={classes.menuItem} onClick={(event) => {
                          sendWebsiteEventMessage("Pilih kabupaten selected: " + kabupaten_name);
                          
                          formik.setFieldValue("kabupaten", kabupaten_name);
                          formik.setFieldValue("kecamatan", "");
                          setKiriminAjaObject({
                            provinsi: kiriminAjaObject.provinsi,
                            provinsi_id: kiriminAjaObject.provinsi_id,
                            kabupaten: kabupaten_name, 
                            kabupaten_id: kabupaten_id
                          })
                          
                          fetchKecamatanOptions(kabupaten_id);

                          // Close anchor element
                          setKabupatenAnchorEl(null);
                        }} >
                          {kabupaten_name}
                        </MenuItem>);
                      })
                    }
                  </MenuList>
                }
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={classes.formRow}>
        <TextField
          margin="normal"
          className={classes.textField}
          placeholder="Pilih Kecamatan"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!formik.values.provinsi || !formik.values.kabupaten}
          id="kecamatan"
          name="kecamatan"
          label="Kecamatan"
          type="text"
          value={formik.values.kecamatan}
          onClick={onClickKecamatan}
          InputProps={{
            readOnly: true,
          }}
          onBlur={(e) => {
            setKecamatanAnchorEl(null);
            formik.handleBlur(e);
          }}
          error={(formik.touched.kecamatan && !formik.values.kecamatan)}
        />
        <Popper className={classes.popper} open={Boolean(kecamatanAnchorEl)} anchorEl={kecamatanAnchorEl} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center bottom' }}
            >
              <Paper className={classes.menuPaper}>
                {
                  isLoading ?
                  <Box className={classes.circularProgressContainer}>
                    <CircularProgress/>
                  </Box>:
                  <MenuList autoFocusItem={false} id="menu-list-grow">
                    {
                      kecamatanOptions?.map((content: any, index: any) => {
                        var kecamatan_name = content.kecamatan_name;
                        var kecamatan_id = content.id;
                        var postal_code = content.postal_code;
                        return (
                        <MenuItem className={classes.menuItem} onClick={(event) => {
                          sendWebsiteEventMessage("Pilih kecamatan selected: " + kecamatan_name);

                          formik.setFieldValue("kecamatan", kecamatan_name);
                          setKiriminAjaObject({
                            provinsi: kiriminAjaObject.provinsi,
                            provinsi_id: kiriminAjaObject.provinsi_id,
                            kabupaten: kiriminAjaObject.kabupaten, 
                            kabupaten_id: kiriminAjaObject.kabupaten_id,
                            kecamatan: kecamatan_name,
                            kecamatan_id: kecamatan_id,
                            kode_pos: postal_code
                          })

                          // Close anchor element
                          setKecamatanAnchorEl(null);
                        }} >
                          {kecamatan_name}
                        </MenuItem>);
                      })
                    }
                  </MenuList>
                }
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={classes.formRow}>
        <TextField
          margin="normal"
          className={classes.textFieldKodePos}
          id="kodePos"
          name="kodePos"
          label="Kode Pos"
          type="number"
          value={formik.values.kodePos}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.kodePos && Boolean(formik.errors.kodePos)}
          helperText={(formik.touched.kodePos && formik.errors.kodePos)}
        />
      </div>
      <div className={classes.formRow}>
        <TextField
          margin="normal"
          className={classes.textField}
          id="keterangan"
          name="keterangan"
          label="Keterangan"
          type="text"
          value={formik.values.keterangan}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.keterangan && Boolean(formik.errors.keterangan)}
          helperText={(formik.touched.keterangan && formik.errors.keterangan) || "Keterangan pengiriman"}
        />
        <TextField
          margin="normal"
          className={classes.textField}
          id="phoneNumber"
          name="phoneNumber"
          label="Phone Number"
          type="text"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={(formik.touched.phoneNumber && formik.errors.phoneNumber)}
        />
      </div>
      <div className={classes.formNavButtonsContainer}>
        <OverlayComponentButton
            formButtonText="Submit"
            isTypeSubmit={true}
            buttonMarginTopBottom="10px"
            buttonMarginLeftRight="0px"/>
      </div>
    </form>
  );
}

import { Box } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { SizeType } from 'common-ts/dist/models/Cart';
import { BodyMeasurementDetailList, BodyMeasurements, CoreBodyMeasurements, UserBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { fetchUserMeasurementEstimatesBody, inputBodyMeasurementsForm, postBodyMeasurement } from '../redux/MeasurementsRedux';
import { closeOverlayAndResetWithCleanup } from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch } from '../reduxhooks';
import PersonalSizeBenefit from './OverlayViewUniversalSize1Benefit';
import PersonalSizeCaraBuat from './OverlayViewUniversalSize2CaraBuat';
import PersonalSizeHeightWeightBodyType from './OverlayViewUniversalSize3HeightWeightBodyType';
import PersonalSizeMeasEstimateLoading from './OverlayViewUniversalSize4MeasEstimateLoading';
import PersonalSizeUkurTiapBagianV2 from './OverlayViewUniversalSize5UkurTiapBagianV2';
import PersonalSizeResult from './OverlayViewUniversalSize6Result';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { UniversalSizeActiveScreen } from '../redux/UIOverlayUniversalSizeRedux';

// Determines which flow is used based on starting parameters
export interface FlowSelectionData {
  isSignedIn: boolean
}

// State data for flow
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateData {
  currentScreen: UniversalSizeActiveScreen
  titleNumber: number
  currentUserBodyMeasurements: UserBodyMeasurements
  userSub: string
}

// Callback to change state data for flow. 
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: UniversalSizeActiveScreen) => void
  setTitleNumber: (titleNumber: number) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentMaster(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentMensTops(flowSelectionData, flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentMensTops(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let titleNumber = -1;

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  switch (flowStateData.currentScreen) {
    case UniversalSizeActiveScreen.Benefit:
      children.push(<PersonalSizeBenefit
        handleBack={() => {
          dispatch(closeOverlayAndResetWithCleanup());
          sendWebsiteEventMessage("User universal size benefit close");
        }}
        handleNext={() => {
          delayedDispatch(UniversalSizeActiveScreen.CaraBuat);
          sendWebsiteEventMessage("User universal size benefit next");
        }}
      />);
      break;
    case UniversalSizeActiveScreen.CaraBuat:
      children.push(<PersonalSizeCaraBuat
        handleBack={() => {
          delayedDispatch(UniversalSizeActiveScreen.Benefit);
          sendWebsiteEventMessage("User universal size cara buat close");
        }}
        handleNext={() => {
          delayedDispatch(UniversalSizeActiveScreen.HeightWeightBodyType);
          sendWebsiteEventMessage("User universal size cara buat next");
        }}
      />);
      break;
    case UniversalSizeActiveScreen.HeightWeightBodyType:
      children.push(<PersonalSizeHeightWeightBodyType
        handleBack={() => {
          delayedDispatch(UniversalSizeActiveScreen.CaraBuat);
          sendWebsiteEventMessage("User universal size height-weight-bodytype close");
        }}
        handleSubmit={(values) => {
          const payloadBody: UserBodyMeasurements = {
            height: values.tinggi_badan,
            weight: values.berat_badan,
            sizeType: SizeType.personal,
            bodyType: values.tipe_badan,
            measurementName: 'Ukuran Badan ' + flowStateData.currentUserBodyMeasurements.nama
            + ' | ' + values.berat_badan + ' kg'
          }
          dispatch(inputBodyMeasurementsForm(payloadBody));
          sendWebsiteEventMessage("User universal size height-weight-bodytype  next");

          dispatch(fetchUserMeasurementEstimatesBody());
          delayedDispatch(UniversalSizeActiveScreen.MeasEstimateLoading);
        }}
      />);
      break;
    case UniversalSizeActiveScreen.MeasEstimateLoading:
      children.push(<PersonalSizeMeasEstimateLoading
        onFetchMeasEstimateSuccess={() => {
          delayedDispatch(UniversalSizeActiveScreen.UkurTiapBagian);
          sendWebsiteEventMessage("User universal size finished measurement prediction fetch");
        }}/>);
      break;
    case UniversalSizeActiveScreen.UkurTiapBagian:
      children.push(<PersonalSizeUkurTiapBagianV2
        issues={CoreBodyMeasurements.filter((value) => value !== 'lingkarPinggul')}
        userSelfInputMeasurements={flowStateData.currentUserBodyMeasurements.userSelfInputMeasurements}
        generatedMeasurements={flowStateData.currentUserBodyMeasurements.generatedMeasurements}
        generatedMeasurementsRange={flowStateData.currentUserBodyMeasurements.generatedMeasurementsRange}
        generatedMeasurementsSafe={flowStateData.currentUserBodyMeasurements.generatedMeasurementsSafe}
        handleBack={() => {
          const payloadBody: UserBodyMeasurements = {
            height: undefined,
            weight: undefined,
            sizeType: undefined,
            bodyType: undefined,
            measurementName: undefined
          }
          dispatch(inputBodyMeasurementsForm(payloadBody));
          delayedDispatch(UniversalSizeActiveScreen.HeightWeightBodyType);
        }}
        handleEachBack={(issues, newIssueIndex) => {
          if (issues === undefined) {
            return;
          }
          let payloadBody: UserBodyMeasurements = {
          }
          payloadBody.userSelfInputMeasurements = {};
          for (let i = 0; i < newIssueIndex; i++) {
            if (!flowStateData.currentUserBodyMeasurements.userSelfInputMeasurements) {
              break;
            }
            payloadBody.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] = flowStateData.currentUserBodyMeasurements.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] as any;
          }
          dispatch(inputBodyMeasurementsForm(payloadBody));
        }}
        handleEachSubmit={(issues, newIssueIndex, currentValue) => {
          if (issues === undefined) {
            return;
          }
          Mixpanel.track('algorithmMeasureBodyPartsEachSubmit');
          let payloadBody: UserBodyMeasurements = {
          }
          payloadBody.userSelfInputMeasurements = {};
          for (let i = 0; i < newIssueIndex - 1; i++) {
            if (!flowStateData.currentUserBodyMeasurements.userSelfInputMeasurements) {
              break;
            }
            payloadBody.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] = flowStateData.currentUserBodyMeasurements.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] as any;
          }
          payloadBody.userSelfInputMeasurements[issues[newIssueIndex - 1] as keyof BodyMeasurements] = currentValue;
          dispatch(inputBodyMeasurementsForm(payloadBody));
        }}
        handleFinish={() => {
          const adjustedMeasurements: BodyMeasurements = {...flowStateData.currentUserBodyMeasurements.adjustedMeasurements};

          const selfInputMeasurements = flowStateData.currentUserBodyMeasurements.userSelfInputMeasurements ? flowStateData.currentUserBodyMeasurements.userSelfInputMeasurements : {};
          const selfInputMeasurementsKeys = Object.keys(selfInputMeasurements);
          for (var i = 0; i < selfInputMeasurementsKeys.length; i += 1) {
            const selfInputMeasurementsKey = selfInputMeasurementsKeys[i] as keyof BodyMeasurements;
            adjustedMeasurements[selfInputMeasurementsKey] = selfInputMeasurements[selfInputMeasurementsKey];
          }

          let payloadBody: UserBodyMeasurements = {
            adjustedMeasurements: adjustedMeasurements
          }

          // NORMALIZE LINGKAR PERUT (MAKE BIGGER)
          if (payloadBody?.["adjustedMeasurements"]?.["lingkarPerut"]) {
            payloadBody["adjustedMeasurements"]["lingkarPerut"] = Math.round(payloadBody["adjustedMeasurements"]["lingkarPerut"] / 0.916);
          }

          dispatch(inputBodyMeasurementsForm(payloadBody));

          delayedDispatch(UniversalSizeActiveScreen.Result);
        }}/>);
      break;
    case UniversalSizeActiveScreen.Result:
    children.push(<PersonalSizeResult
      name={flowStateData.currentUserBodyMeasurements.nama ? flowStateData.currentUserBodyMeasurements.nama : 'User'}
      bodyMeasurements={flowStateData.currentUserBodyMeasurements.adjustedMeasurements ? flowStateData.currentUserBodyMeasurements.adjustedMeasurements : {}}
      handleBack={() => {
        delayedDispatch(UniversalSizeActiveScreen.UkurTiapBagian);
      }}
      handleSubmit={() => {
        const userMeasurements: UserBodyMeasurements = {};
        userMeasurements['bodyOrClothingMeasurements'] = "BODY";
        userMeasurements["creationMethod"] = "online-body";
        dispatch(inputBodyMeasurementsForm(userMeasurements));
        
        dispatch(postBodyMeasurement(""));
  
        dispatch(closeOverlayAndResetWithCleanup());
      }}
    />);
    break;
  }

  if (flowStateData.titleNumber != titleNumber) {
    flowStateCallback.setTitleNumber(titleNumber);
  }

  return children;
}
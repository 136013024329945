import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import store from './store'
import { Provider } from 'react-redux'
import Amplify from "aws-amplify";
import 'webrtc-adapter'
// import awsExports from "./aws-exports";

// Amplify.configure(awsExports);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
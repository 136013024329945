import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { __String } from 'typescript';

interface StyleProps {
  textAlign: string
  fontSize: string
  fontSizeMobile: string
}

const useStyles = ({ textAlign, fontSize, fontSizeMobile }: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  title: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    fontSize: fontSize,
    marginTop: '10px',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: fontSizeMobile,
    },
    textAlign: textAlign as any,
    position: 'relative',
  },
  titleNumber: {
    fontFamily: 'OakesGrotesk',
    position: 'absolute',
    left: '-24px',
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      left: '-20px',
    },
  }
}),
);

interface Props {
  text: string
  titleNumber?: string
  fontSize?: string
  fontSizeMobile?: string
  textAlign?: string // default is left
}

export default function OverlayComponentTitle({ text, titleNumber, fontSize, fontSizeMobile, textAlign }: Props) {
  const classes = useStyles({
    textAlign: textAlign ? textAlign : 'left',
    fontSize: fontSize ? fontSize : '',
    fontSizeMobile: fontSizeMobile ? fontSizeMobile : '18px',
  })();

  return (
    <Typography variant="h5" className={classes.title} color="textPrimary">
      {
        titleNumber ?
        <sup className={classes.titleNumber}>{titleNumber}</sup> :
        null
      }
      {text}
    </Typography> 
  );
}
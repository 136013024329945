import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

// As always
import { enableMapSet } from "immer";
enableMapSet();

interface ProductAnalyticsState {
  productViewMap: Map<string, string>;
  productVisitMap: Map<string, string>;
  productSelectSizeMap: Map<string, string>;
  productCheckoutMap: Map<string, string>;
}

const loadMapFromLocalStorage = (key: string): Map<string, string> => {
  const storedData = localStorage.getItem(key);
  return storedData ? new Map(JSON.parse(storedData)) : new Map(); // Buat baru kalau tidak ada
};

const saveMapToLocalStorage = (key: string, map: Map<string, string>) => {
  localStorage.setItem(key, JSON.stringify(Array.from(map.entries())));
};

const initialState: ProductAnalyticsState = {
  productViewMap: loadMapFromLocalStorage('productViewMap'),
  productVisitMap: loadMapFromLocalStorage('productVisitMap'),
  productSelectSizeMap: loadMapFromLocalStorage('productSelectSizeMap'),
  productCheckoutMap: loadMapFromLocalStorage('productCheckoutMap'),
};

interface ProductAnalyticsPayload {
  event: string;
  isMobileView: boolean;
  productId: string;
  origin?: string;
}

const EVENT_TYPES = {
  VIEW: "VIEW",
  VISIT: "VISIT",
  SELECT_SIZE: "SELECT-SIZE",
  CHECKOUT: "CHECKOUT",
  PURCHASE: "PURCHASE",
};

const TIMEOUT = 2 * 60 * 60 * 1000;

export const postNewProductAnalyticsLog = createAsyncThunk(
  "productAnalyticsLog/postNewProductAnalyticsLog",
  async (payload: ProductAnalyticsPayload, thunkAPI) => {
    const { productId, event, isMobileView, origin } = payload;
    const rootState = thunkAPI.getState() as RootState;
    const currentState = rootState.productAnalyticsLog;
    const userSub = rootState.account.sub || rootState.account.distinctUserId || "GUEST";
    const currentProductId = productId;

    if (!currentProductId) return;

    const currentTimestamp = new Date().toISOString();
    let eventMap: Map<string, string> | null = null;
  
    switch (event) {
      case EVENT_TYPES.VIEW:
        eventMap = currentState.productViewMap;
        break;
      case EVENT_TYPES.VISIT:
        eventMap = currentState.productVisitMap;
        break;
      case EVENT_TYPES.SELECT_SIZE:
        eventMap = currentState.productSelectSizeMap;
        break;
      case EVENT_TYPES.CHECKOUT:
        eventMap = currentState.productCheckoutMap;
        break;
      default:
        return;
    }
  
    const lastTimestamp = eventMap?.get(currentProductId);
    const shouldPostAnalytics = !lastTimestamp || new Date(currentTimestamp).getTime() - new Date(lastTimestamp).getTime() > TIMEOUT
  
    switch (event) {
      case EVENT_TYPES.VIEW:
        thunkAPI.dispatch(updateProductViewMap({ currentProductId, timestamp: currentTimestamp }));
        break;
      case EVENT_TYPES.VISIT:
        thunkAPI.dispatch(updateProductVisitMap({ currentProductId, timestamp: currentTimestamp }));
        break;
      case EVENT_TYPES.SELECT_SIZE:
        thunkAPI.dispatch(updateProductSelectSizeMap({ currentProductId, timestamp: currentTimestamp }));
        break;
      case EVENT_TYPES.CHECKOUT:
        thunkAPI.dispatch(updateProductCheckoutMap({ currentProductId, timestamp: currentTimestamp }));
        break;
    }

    if (shouldPostAnalytics) {
      const data = {
        productId: currentProductId,
        timestamp: currentTimestamp,
        userSub,
        isMobileView,
        event,
        origin,
      };

      console.log('ANALYTICS EVENT: ' + data.event + ' ON ' + data.productId)

      axios({
        method: "post",
        url: "https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/product-analytics",
        data: JSON.stringify(data)
      });
    }
  }
);


export const productAnalyticsLogSlice = createSlice({
  name: "productAnalyticsLog",
  initialState,
  reducers: {
    updateProductViewMap: (
      state,
      action: PayloadAction<{ currentProductId: string; timestamp: string }>
    ) => {
      state.productViewMap.set(
        action.payload.currentProductId,
        action.payload.timestamp
      );
      saveMapToLocalStorage('productViewMap', state.productViewMap);
    },
    updateProductVisitMap: (
      state,
      action: PayloadAction<{ currentProductId: string; timestamp: string }>
    ) => {
      state.productVisitMap.set(
        action.payload.currentProductId,
        action.payload.timestamp
      );
      saveMapToLocalStorage('productVisitMap', state.productVisitMap);
    },
    updateProductSelectSizeMap: (
      state,
      action: PayloadAction<{ currentProductId: string; timestamp: string }>
    ) => {
      state.productSelectSizeMap.set(
        action.payload.currentProductId,
        action.payload.timestamp
      );
      saveMapToLocalStorage('productSelectSizeMap', state.productSelectSizeMap);
    },
    updateProductCheckoutMap: (
      state,
      action: PayloadAction<{ currentProductId: string; timestamp: string }>
    ) => {
      state.productCheckoutMap.set(
        action.payload.currentProductId,
        action.payload.timestamp
      );
      saveMapToLocalStorage('productCheckoutMap', state.productCheckoutMap);
    },
  },
  extraReducers(builder) {
    builder.addCase(
      postNewProductAnalyticsLog.fulfilled,
      (state, action) => {}
    );
  },
});

export const {
  updateProductViewMap,
  updateProductVisitMap,
  updateProductSelectSizeMap,
  updateProductCheckoutMap,
} = productAnalyticsLogSlice.actions;

export default productAnalyticsLogSlice.reducer;
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OverlayPopupFlow {
  status: UIOverlayViewStatus,

  currentScreen: string,
}

// Define the initial state using that type
const initialState: OverlayPopupFlow = {
  status: UIOverlayViewStatus.OffAndReady,
  currentScreen: "",
}

export const overlayPopupSlice = createSlice({
  name: 'overlayPopup',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;

      state.currentScreen = "";
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;

      state.currentScreen = "";
    },

    updateCurrentScreen: (state, action: PayloadAction<string>) => {
      state.currentScreen = action.payload;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay,
  updateCurrentScreen, } = overlayPopupSlice.actions

export default overlayPopupSlice.reducer
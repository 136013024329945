import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import OverlayComponentBaseContainerVertical from './OverlayComponentBaseContainerVertical';
import OverlayComponentTitle from './OverlayComponentTitle';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentBaseTemplate from './OverlayComponentBaseTemplate';
import { StringMappingType } from 'typescript';

interface StyleProps {
  titleWidthMdUp: string
  titleWidthSmDown: string
  formWidthMdUp: string
  formWidthSmDown: string
  formJustifyContent: string
  formAlignItems: string
}

const useStyles = ({ titleWidthMdUp, titleWidthSmDown, 
  formWidthMdUp, formWidthSmDown, formJustifyContent, formAlignItems }: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  form: {
    paddingTop: '20px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: formJustifyContent,
    alignItems: formAlignItems,
    width: formWidthSmDown,
  },
  subFormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitFormContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}),
);

interface Props {
  formHandleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  formTitle: string
  formTitleNumber?: string
  formFormBody: JSX.Element[]
  formSubmitBody: JSX.Element[]
  titleWidthMdUp: string
  titleWidthSmDown: string
  formWidthMdUp: string
  formWidthSmDown: string
  formJustifyContent: string
  formAlignItems: string
}

export default function OverlayComponentForm({ formHandleSubmit, formFormBody, formTitle, formTitleNumber, formSubmitBody,
  titleWidthMdUp, titleWidthSmDown, formWidthMdUp, formWidthSmDown, formJustifyContent, formAlignItems }: Props) {
  const classes = useStyles({titleWidthMdUp, titleWidthSmDown, formWidthMdUp, 
    formWidthSmDown, formJustifyContent, formAlignItems})();

  return (
    <OverlayComponentBaseTemplate
      alignItems={formAlignItems}>
      {
        formTitle !== "" && formTitle ? 
        <OverlayComponentBaseContainerWidth
          widthMdUp={titleWidthMdUp}
          widthSmDown={titleWidthSmDown}>
          <OverlayComponentTitle text={formTitle} titleNumber={formTitleNumber} textAlign={ formAlignItems === 'center' ? 'center' : 'left' }/>
        </OverlayComponentBaseContainerWidth> :
        <Box></Box>
      }      
      <form className={classes.form} noValidate autoComplete="off" onSubmit={formHandleSubmit}>
        <OverlayComponentBaseContainerWidth
          widthMdUp={formWidthMdUp}
          widthSmDown={formWidthSmDown}>
          <Box className={classes.subFormContainer}>
            {formFormBody}
          </Box>
        </OverlayComponentBaseContainerWidth>
        <OverlayComponentBaseContainerWidth
          widthMdUp={formWidthMdUp}
          widthSmDown={formWidthSmDown}>
          <Box className={classes.submitFormContainer}>
            {formSubmitBody}
          </Box>
        </OverlayComponentBaseContainerWidth>
      </form>
    </OverlayComponentBaseTemplate>
  );
}
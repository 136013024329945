import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import StarRating from "./PageComponentReviewStarRating";
import { Stack } from "@mui/material";

interface stylesProps {
  margin: string;
  padding: string;
}

const useStyles = ({ margin, padding }: stylesProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      reviewRoot: {
        backgroundColor: "#F3F3F3",
        "&:hover": {
          zIndex: 100,
        },
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      },
      reviewContainer: {
        margin: margin,
        padding: padding,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      },
      reviewContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent'
      },
      imageContainer: {
        WebkitTapHighlightColor: 'transparent',
        display: "flex",
        overflow: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        marginTop: theme.spacing(1.5),
        height: 96,
        gap: theme.spacing(1.5),
      },
      image: {
        cursor: 'pointer',
        width: '8vw',
        // height: '8vw',
        borderRadius: '10px',
        objectFit: "cover",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          width: '96px',
          height: '96px',
        },
      },
      imageOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
      },
      overlayText: {
        color: "#FFFFFF",
        fontSize: "18px",
        fontWeight: "bold",
      },
      ReviewTitle: {
        fontWeight: "bold",
        color: "#121212",
        fontSize: "17px",
      },
      reviewProductNameListText: {
        fontWeight: "bold",
        fontSize: "13px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
        },
        color: "#121212",
      },
      timestampText: {
        fontSize: 10,
        color: "#B1B1B1",
        margin: 0,
      },
      reviewComment: {
        fontWeight: "normal",
        fontSize: "13px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
        },
        color: "#343434",
        fontFamily: "Open sans, sans-serif",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: 1,
      },
    })
  );

interface ReviewItemProps {
  name: string;
  productNameList?: string;
  timestamp: string;
  rating: number;
  comment: string;
  displayImageList: string;
  userSub?: string;
  margin?: string;
  padding?: string;
  onClickImage?: (imageIndex: number) => void;
  onClickContent?: () => void;
  isCommentDisplayedFull?: boolean;
}

const ReviewItem: React.FC<ReviewItemProps> = ({
  name,
  productNameList,
  timestamp,
  rating,
  comment,
  displayImageList,
  userSub,
  margin,
  padding,
  onClickImage,
  onClickContent,
  isCommentDisplayedFull,
}) => {
  margin = margin ? margin : "0px 0px 0px 0px";
  padding = padding ? padding : "12px 16px 16px 16px";
  const classes = useStyles({ margin, padding })();
  const formattedDate = formattimestamp(timestamp);

  const maskedname =
    name.slice(0, 3) + "*".repeat(Math.max(name.length - 3, 0));

  // Biar error "Cannot invoke an object which is possibly 'undefined'" gak muncul, pusing juga keeping track of the functions
  const handleImageClick = (index: number) => {
    if (onClickImage) {
      onClickImage(index);
    }
  };

  const handleContentClick = () => {
    if (onClickContent) {
      onClickContent();
    }
  };

  const parsedDisplayImageList = JSON.parse(displayImageList)

  return (
    <Box className={classes.reviewRoot}>
      <Box className={classes.reviewContainer}>
        <Box className={classes.reviewContent} onClick={handleContentClick}>
          <Box>
            <Typography className={classes.ReviewTitle}>
              {maskedname}
            </Typography>
          </Box>
          <Box mb={2}>
            {/* <Typography
              variant="body1"
              className={classes.reviewProductNameListText}
            > */}
              {/* Produk : {selectedReview.productNameList[0]} */}
              {/* Yang ini sampai sekarang belum tahu mau diapakan, kemungkinan dimasukkan manual saja? Kalau iya nanti di model-nya ganti string saja */}
              {/* Produk : Placeholder Product Name Text  */}
            {/* </Typography> */}
          </Box>
          <Box mt={-2}>
            <Stack direction="row" alignItems="center">
              <StarRating productRating={rating} starSize={16} gap={0} mr={1} />
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.timestampText}
                gutterBottom
              >
                {formattedDate}
              </Typography>
            </Stack>
          </Box>
          <Box mb={1}>
            <Typography
              variant="body1"
              className={classes.reviewComment}
              style={{ WebkitLineClamp: isCommentDisplayedFull ? 999 : 3 }}
            >
              {comment}
            </Typography>
          </Box>
        </Box>
        <Box>
          {parsedDisplayImageList.length > 0 && (
            <Box className={classes.imageContainer}>
              {parsedDisplayImageList.slice(0, 2).map((url: any, index: any) => (
                <Box
                  key={index}
                  className={classes.image}
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={url.replace('.jpg', '_thumbnail.jpg')}
                    style={{
                      borderRadius: 10,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt={`Review image ${index + 1}`}
                  />
                  {index === 1 && parsedDisplayImageList.length > 2 && (
                    <Box className={classes.imageOverlay}>
                      <Typography className={classes.overlayText}>
                        {`${parsedDisplayImageList.length - 1}+`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const formattimestamp = (timestamp: string): string => {
  const now = new Date();
  const timestampTime = new Date(timestamp);
  const diffInMilliseconds = now.getTime() - timestampTime.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  const diffInDays = diffInHours / 24;
  const diffInMonths = diffInDays / 30;
  const diffInYears = diffInDays / 365;

  if (diffInHours < 24) {
    if (diffInHours < 1) {
      return `${Math.floor(diffInHours)} Saat ini`;
    } else {
      return `${Math.floor(diffInHours)} Jam yang lalu`;
    }
  } else if (diffInDays < 2) {
    return "Kemarin";
  } else if (diffInDays < 30) {
    return `${Math.floor(diffInDays)} Hari yang lalu`;
  } else if (diffInMonths < 12) {
    return `${Math.floor(diffInMonths)} Bulan yang lalu`;
  } else {
    return `${Math.floor(diffInYears)} Tahun yang lalu`;
  }
};

export default ReviewItem;

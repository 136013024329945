import { AppBar, Collapse, Divider, Grid, Link, Toolbar, Typography, useMediaQuery, TextField, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import {
  Route, Switch, useRouteMatch, Link as RouterLink, useParams, useHistory,
} from "react-router-dom";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CartForOrderContent from './CartForOrderContent';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { CartState, CartStateItem } from 'common-ts/dist/models/Cart';
import PageCheckoutPayment from './PageCheckoutPayment';
import PageCheckoutFormsComponent from './PageCheckoutPaymentToOrder';
import Countdown from 'react-countdown';
import { ProductsList } from 'common-ts/dist/models/Product';
import { FareastButton } from './OverlayComponentButton';
import DoneIcon from '@material-ui/icons/Done';
import { PageProductSuggestions } from './PageProductSuggestions';

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import axios, { AxiosRequestConfig } from 'axios';

import { updateState } from '../redux/AccountRedux';
import { TailorChatEvent } from '../App';
import { socket } from '../socket';
import { sendWebsiteLivestreamMessage } from '../telegrambotevents';
import LivestreamDashboardDialog from './LivestreamDashboardDialog';


const useStyles = makeStyles((theme: Theme) =>
createStyles({
  fullHeight: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  titleLogoContainer: {
    height: '42px',
    marginTop: '34px',
    marginBottom: '10px',
  },
  titleLogo: {
    height: '24px',
    display: 'block', // We use block so that the img is unique horizontally in the div
    marginLeft: '40px',
  },
  checkoutFormContainer: {
    height: 'auto',
    width: '100%',
  },
  sideCartList: {
    display: 'none',
    height: '100vh',
    background: '#fafafa',
    width: '480px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: 'auto',
    },
  },
  sideCartListInner: {
    background: '#fafafa',
    width: '432px',
    padding: '24px',
  },
  cartMainSegment: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 480px)',
    },
  },
  cartMobile: {
    width: 'calc(100%-48px)',
    padding: '16px 24px 26px 24px',
    backgroundColor: '#fafafa',
  },
  cartMobileAccordion: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px',
    backgroundColor: '#fafafa',
    '&:active': {
      backgroundColor: '#eaeaea',
    }
  },
  cartMobileAccordionIcon: {
    marginRight: '12px',
    color: '#222',
  },
  cartMobileAccordionText: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#222',
  },
  cartMobileAccordionIconOpenClose: {
    marginLeft: '4px',
    color: '#222',
  },
  cartMobileAccordionPrice: {
    color: '#222',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
  },
  cartMobileText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cartMobileContainer: {
    width: '100%',
  },
  nextStepButtonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: '16px',
  },
  nextStepButton: {
    marginRight: '20px',
    marginLeft: 'auto',
  },
  orderSpacer: {
    [theme.breakpoints.down('sm')]: {
      width: "100px",
      height: "30px",
    },
  },
  countdownComponent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  countdownMainText: {
    fontSize: "20px",
    fontWeight: 500,
    paddingTop: "8px",
    paddingBottom: "10px",
  },
  countdownMainTextTimeout: {
    fontSize: "24px",
    fontWeight: 500,
    paddingTop: "8px",
    paddingBottom: "16px",
  },
  countdownNumberText: {
    fontSize: "22px",
    fontWeight: 300,
    letterSpacing: "1.2px",
    fontFamily: 'Open Sans, sans-serif',
    paddingBottom: "22px",
    color: "#343434",
  },
  countdownSubTextHighlight: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
  },
  countdownSubText: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    paddingBottom: "42px",
    textAlign: "center",
  },
  countdownSubTextTimeout: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    paddingBottom: "4px",
    paddingTop: "4px",
    textAlign: "center",
  },
  countdownButtonText: {
    fontSize: "12px",
    letterSpacing: "0.7px",
    fontWeight: 500,
  },
  orderTrackerContainer: {
    padding: "0px 32px",
  },
  orderTrackerMainText: {
    fontSize: "20px",
    fontWeight: 500,
    paddingBottom: "2px",
  },
  orderTrackerSubText: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    letterSpacing: "0.6px",
  },
  orderTrackerImageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: "5px",
  },
  orderTrackerImage: {
    height: "100px",
    paddingTop: "15px",
    paddingBottom: "12px",
  },
  orderTrackerImageText: {
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "21px",
  },
  estimasiPengirimanMainText: {
    fontSize: "12px",
    fontWeight: 500,
    paddingBottom: "2px",
    letterSpacing: "0.4px",
  },
  estimasiPengirimanSubText: {
    fontSize: "10px",
    fontWeight: 300,
    paddingBottom: "22px",
    letterSpacing: "0.7px",
  },
  orderProgressBarContainer: {
    marginTop: "30px",
    marginBottom: "20px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "50px",
    paddingBottom: "80px",
  },
  orderProgressBarPointGreened: {
    width: "35px",
    height: "35px",
    borderRadius: "104px",
    backgroundColor: "#30ac0a",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orderProgressBarPointGreyed: {
    width: "35px",
    height: "35px",
    borderRadius: "104px",
    backgroundColor: "#B9B9B9",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orderProgressBarPoint: {
    width: "35px",
    height: "35px",
    borderRadius: "104px",
    backgroundColor: "#1c1c1c",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orderProgressBarText: {
    textAlign: "center",
    width: "35px",
    paddingTop: "8.5px",
    fontSize: "13px",
    fontWeight: 400,
    color: "#ffffff"
  },
  orderProgressBarDoneIcon: {
    color: "#ffffff",
    paddingTop: "6px",
    width: "22px",
    height: "22px"
  },
  orderProgressPointImageIcon: {
    position: "absolute",
    top: "-60px",
  },
  orderProgressPointDescription: {
    fontSize: "10px",
    fontWeight: 300,
    textAlign: "center",
    position: "absolute",
    top: "46px",
  },
  pengirimanTrackerMainText: {
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.8px",
    paddingBottom: "2px",
    marginTop: "14px",
  },
  pengirimanTrackerSubBoldText: {
    fontSize: "13px",
    fontWeight: 600,
    letterSpacing: "0.5px",
    color: "#232323",
    marginTop: "16px",
  },
  pengirimanTrackerSubLightText: {
    fontSize: "12px",
    letterSpacing: "0.3px",
    fontWeight: 300,
    color: "#454545",
    marginTop: "3px"
  },
  pengirimanTrackerHistory: {
    fontSize: "19px",
    fontWeight: 500,
    letterSpacing: "0.6px",
    paddingBottom: "2px",
    marginTop: "27px",
    marginBottom: "9px",
  },
  pengirimanTrackerHistorySubText: {
    fontSize: "13px",
    letterSpacing: "0.3px",
    fontWeight: 500,
    color: "#343434",
    paddingTop: "5.5px",
    marginLeft: "12px"
  },
  pengirimanTrackerHistorySubTextTime: {
    fontSize: "12px",
    letterSpacing: "0.3px",
    fontWeight: 300,
    color: "#676767",
    paddingTop: "3.5px",
    marginLeft: "12px"
  }
}),
);

const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}

function dataURItoBlob(dataURI: any) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const uploadImageFile = async (fileContent: any) => {

  var postFileInformationSuccess = true;
  var currentFileDraft: any = {};
  currentFileDraft.fileName = "tailor-chat-" + new Date().toISOString() + ".jpg"; // Remove whitespace
  currentFileDraft.fileDataType = 'image/jpeg';
  currentFileDraft.fileCategory = 'general';

  const signedURLResponse = await axios({
    method: 'post',
    url: 'https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/signedurl',
    data: currentFileDraft
  })
  
  console.log(signedURLResponse);
  const uploadURL = signedURLResponse.data.uploadURL;
  const createdFileKey = signedURLResponse.data.fileKey;

  try {
    const uploadResult = await axios({
      method: 'put',
      url: uploadURL,
      data: dataURItoBlob(fileContent),
      headers: {
        "Content-Type": currentFileDraft.fileDataType,
      }
    })

    console.log("upload to s3 success:");
    console.log(uploadResult.headers);
    console.log(uploadResult.statusText);
    console.log(uploadResult);
  } catch (err) {
    console.log("Error uploading to s3:");
    console.log(err);
  }
  
  console.log("continue...")
  const fileURL = uploadURL.split('?')[0]

  return fileURL;
} 

export default function PageOrder({ clientVersion }: { clientVersion: boolean }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const chatUserId = useAppSelector(state => state.account.chatUserId);
  const [tailorChatUserId, setTailorChatUserId] = React.useState('');
  const chatEvents = useAppSelector(state => state.account.chatEvents);
  const [kirimText, setKirimText] = React.useState('');

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box style={{
      height: clientVersion ? '70vh' : '99vh',
      overflow: 'clip',
      position: 'relative',
      width: '100%',
    }}>
      <div id='chat-tailor-logs' style={{
        overflowY: 'scroll',
        overflowX: 'clip',
        padding: '0px 16px 4px',
        marginBottom: '7px',
        height: clientVersion ? 'calc(70vh - 120px)' : 'calc(99vh - 120px)'
      }}>
        {
          chatEvents.map((chatEvent) => {
            if (clientVersion && chatEvent.chatUserId !== chatUserId) {
              return null;
            }
            if (!clientVersion && tailorChatUserId !== '' && chatEvent.chatUserId !== tailorChatUserId) {
              return null;
            }
            if (chatEvent.contentType === "STRING") {
              return (
                <Typography onClick={() => setTailorChatUserId(chatEvent.chatUserId)}
                style={{
                  fontSize: '12px',
                  color: '#454545',
                  padding: '6px 8px',
                  fontFamily: 'OakesGrotesk',
                  width: clientVersion && chatEvent.author === "CLIENT" ? '96%' : '100%',
                  textAlign: clientVersion && chatEvent.author === "CLIENT" ? 'right' : 'left',
                }}
                >
                  {
                    !clientVersion ? 
                    <span style={{
                      backgroundColor: stringToColour(chatEvent.chatUserId),
                      padding: '7px 8px 5px 8px',
                      fontSize: '10px',
                      color: '#ffffff',
                      borderRadius: '20px',
                      marginRight: '8px',
                    }}>{clientVersion ? "" : chatEvent.chatUserId}</span>:
                    null
                  }
                  {
                    clientVersion && chatEvent.author !== "CLIENT" ?
                    <span style={{
                      backgroundColor: '#232323',
                      padding: '7px 8px 5px 8px',
                      fontSize: '10px',
                      color: '#ffffff',
                      borderRadius: '20px',
                      marginRight: '8px',
                    }}>Tailor</span>:
                    null
                  }
                {(clientVersion ? '' : (chatEvent.author === "CLIENT" ? '' : "[TAILOR] ")) + chatEvent.content}</Typography>
              );
            } else if (chatEvent.contentType === "IMAGE") {
              return (
                <img src={chatEvent.content} style={{
                  width: isMdOrUp ? '220px' : '70vw',
                  margin: '12px',
                  borderRadius: '15px',
                  display: 'table-row',
                }}/>
              )
            }
            return null;
          })
        }
        {
          clientVersion ? 
          <div id={'chat-tailor-typing-indicator'} style={{
              visibility: 'hidden',
          }}>
            <Typography style={{
              fontSize: '10px',
              color: '#414141',
              padding: '13px 8px 16.5px',
            }}>
              Tailor is typing...
            </Typography>
          </div>:
          null
        }
      </div>
      <Box style={{
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        width: '100%',
        height: '150px',
        backgroundColor: '#ffffff',
        borderTop: '1px solid #eeeeee',
      }}>
        {
          !clientVersion ?
          <Typography style={{
            padding: '8px 6px 6px 6px',
            backgroundColor: '#232323',
            color: '#ffffff',
            borderRadius: '20px',
            textAlign: 'center',
            fontFamily: 'OakesGrotesk',
            fontWeight: '400',
            fontSize: '10px',
            letterSpacing: '0.7px',
            margin: '12px 8px 6px 8px',
          }} onClick={handleOpenDialog}
          >LIVESTREAM</Typography>:
          null
        }
        {
          !clientVersion ?
          <LivestreamDashboardDialog 
            open={dialogOpen} 
            onClose={handleCloseDialog}
          />:
          null
        }
        
        {
          !clientVersion ?
          <Typography style={{
            padding: '8px 6px 6px 6px',
            backgroundColor: tailorChatUserId === '' ? '#232323' : stringToColour(tailorChatUserId),
            color: '#ffffff',
            borderRadius: '20px',
            textAlign: 'center',
            fontFamily: 'OakesGrotesk',
            fontWeight: '400',
            fontSize: '10px',
            letterSpacing: '0.7px',
            margin: '12px 8px 6px 8px',
          }} onClick={() => setTailorChatUserId('')}
          >{tailorChatUserId === '' ? "PILIH CHAT" : "MEMBALAS CHAT USER ID: " + tailorChatUserId}</Typography>:
          null
        }
        <Grid container style={{
          maxWidth: '100vw', 
          padding: clientVersion ? '10px 8px 14px 15px' : '4px 8px 14px 15px',
        }}>
          <Grid item xs={clientVersion ? 9 : 7}>
            <TextField
              margin="dense"
              fullWidth
              id="livestreamChatInput"
              name="livestreamChatInput"
              label="Kirim Pesan"
              type="text"
              value={kirimText}
              error={false}
              onChange={(e) => {
                // When it is changing, we send "typing" events
                setKirimText(e.target?.value);
                socket.timeout(5000).emit('chat message', {
                  "chatUserId": clientVersion ? chatUserId : tailorChatUserId,
                  "creationDateTime": new Date().toISOString(),
                  "contentType": "INFO",
                  "content": "TYPING",
                  "author": clientVersion ? "CLIENT" : "OTHER"
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}/>
          </Grid>
          <Grid item xs={clientVersion ? 3 : 5} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderRadius: '20px',
          }}>
            {
              !clientVersion ?
              <Button variant="outlined" onClick={() => {
                if (!clientVersion && tailorChatUserId === '') {
                  return;
                }

                var input = document.createElement('input');
                input.type = 'file';

                input.onchange = e => { 
                  // getting a hold of the file reference
                  var file = (e as any).target.files[0]; 

                  // setting up the reader
                  var reader = new FileReader();
                  reader.readAsDataURL(file); // this is reading as data url

                  // here we tell the reader what to do when it's done reading...
                  reader.onload = readerEvent => {
                    if (readerEvent?.target?.result) {
                      var content = readerEvent.target.result; // this is the content!
                      uploadImageFile(content).then((fileURL => {
                        socket.timeout(5000).emit('chat message', {
                          "chatUserId": clientVersion ? chatUserId : tailorChatUserId,
                          "creationDateTime": new Date().toISOString(),
                          "contentType": "IMAGE",
                          "content": fileURL,
                          "author": clientVersion ? "CLIENT" : "OTHER"
                        });
                      }));
                    }
                  }
                }

                input.click();
  
              }} style={{
                marginRight: isMdOrUp ? '10px' : '4px',
                borderRadius: '12px',
                fontSize: '12px',
                padding: '4px 13px 1px 13px',
              }}>
                FILE
              </Button>:
              null
            }
            <Button onClick={() => {
              if (!clientVersion && tailorChatUserId === '') {
                return;
              }
              if (kirimText !== '') {
                socket.timeout(5000).emit('chat message', {
                  "chatUserId": clientVersion ? chatUserId : tailorChatUserId,
                  "creationDateTime": new Date().toISOString(),
                  "contentType": "STRING",
                  "content": kirimText,
                  "author": clientVersion ? "CLIENT" : "OTHER"
                });
                if (clientVersion) {
                  sendWebsiteLivestreamMessage("[INSTANT SIZE TEAM] " + kirimText);
                }
              }
              setKirimText('');
            }} style={{
              backgroundColor: '#232323',
              color: '#ffffff',
              fontWeight: 400,
              letterSpacing: '0.9px',
              fontSize: '12px',
              padding: '4px 13px 1px 13px',
              marginRight: '3px',
              borderRadius: '12px',
              minWidth: '4px',
            }}>
              KIRIM
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, IconButton, TextField, Typography, Link } from '@material-ui/core';
import { UserClothTypeSpecificBodyMeasurements, ClothingMeasurements, BodyMeasurements, BodyMeasurementDetailList, UserBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import React from 'react';
import { Remove, Add, Edit } from '@material-ui/icons';
import { inputBodyMeasurementsForm, inputMeasurementsForm } from '../redux/MeasurementsRedux';
import OverlayComponentButton from './OverlayComponentButton';

interface StyleProps {
  nameAlignLeft: boolean
}

const useStyles = ({nameAlignLeft}: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    height: '100%',
    width: '100%',
  },
  formRow: {
  },
  componentContainer: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: nameAlignLeft ? 'flex-start' : 'center',
    width: '100%',
  },
  measurementNameContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '40px',
    paddingBottom: '20px',
  },
  gridContainer: {
    width: '100%',
  },
  gridItemLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  gridItemRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  submitFormContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
  },
  subFormTitle: {
  },
  measurementValueContainer: {
    width: '100%',
    height: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  measurementValueContainerTextField: {
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  measurementValueTextField: {
    padding: '10px',
  },
  sizeNameText: {
    marginBottom: '15px',
    fontSize: '16px',
    fontWeight: 500,
  },
  measurementNameText: {
    fontSize: '15px',
    fontWeight: 500,

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  measurementValueText: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
  },
  buttonContainer: {
    display: 'flex',
  },
  pelajariLanjut: {
    cursor: 'pointer',
    paddingBottom: '10px',
    paddingLeft: '10px',
  },
  pelajariLanjutText: {
    fontSize: '15px',
    fontWeight: 600,
    [theme.breakpoints.only('xs')]: {
      fontSize: '13.5px',
    }
  },
}),
);

interface Props {
  isEditingStart: boolean
  onEditStateChanged: (isEditingState: boolean) => void
  allowNameEdit: boolean
  allowEditing: boolean
  useClothingMeasurementDraft: boolean
  nameAlignLeft: boolean
}

export default function OverlayViewMeasurementBodyDraftEditPanel({ isEditingStart, onEditStateChanged, allowNameEdit, allowEditing, useClothingMeasurementDraft, nameAlignLeft }: Props) {
  const dispatch = useAppDispatch();
  const classes = useStyles({nameAlignLeft})();
  const [isEditing, setIsEditing] = React.useState<boolean>(isEditingStart);
  const userBodyMeasurement = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementDraft);
  const userClothingMeasurement = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementDraft);

  let bodyMeasurements: BodyMeasurements = {};
  let measurementName = "";
  if (useClothingMeasurementDraft) {
    if (userClothingMeasurement.adjustedMeasurements === undefined) {
      let userMeas: UserClothTypeSpecificBodyMeasurements = {};
      if (userClothingMeasurement.generatedMeasurements === undefined) {
        return null;
      } else {
        userMeas.adjustedMeasurements = {...userClothingMeasurement.generatedMeasurements};
      }
      dispatch(inputMeasurementsForm(userMeas));
      return null;
    }
    bodyMeasurements = userClothingMeasurement.adjustedMeasurements;
    measurementName = userClothingMeasurement.measurementName ? userClothingMeasurement.measurementName : "";
  } else {
    if (userBodyMeasurement.bodyMeasurements === undefined) {
      return null;
    }
    bodyMeasurements = userBodyMeasurement.bodyMeasurements;
    measurementName = userBodyMeasurement.measurementName ? userBodyMeasurement.measurementName : "";
  }

  // measurement name / display
  // dispatch update to the redux state when plus or minus is clicked
  function updateMeasurement(measurementName:string, value:number) {
    const inputBodyMeasurements: {[key:string]:number} = {...bodyMeasurements};
    inputBodyMeasurements[measurementName] = value;
    if (useClothingMeasurementDraft) {
      const inputUserClothingMeasurements: UserClothTypeSpecificBodyMeasurements = {};
      inputUserClothingMeasurements.adjustedMeasurements = inputBodyMeasurements;
      dispatch(inputMeasurementsForm(inputUserClothingMeasurements));
    } else {
      const inputUserBodyMeasurements: UserBodyMeasurements = {};
      inputUserBodyMeasurements.bodyMeasurements = inputBodyMeasurements;
      dispatch(inputBodyMeasurementsForm(inputUserBodyMeasurements));
    }
  }

  let children: JSX.Element[] = [];

  children.push(
    <Box className={classes.measurementNameContainer}>
      {
        isEditing && allowNameEdit ?
        <TextField 
          margin="dense"
          id={"measurementName"} 
          name={"measurementName"}
          label={"Measurement Name"}
          multiline
          defaultValue={measurementName}
          type="text"
          onChange={(event) => {
            if (useClothingMeasurementDraft) {
              const userMeasurements: UserClothTypeSpecificBodyMeasurements = {};
              userMeasurements.measurementName = event.target.value;
              dispatch(inputMeasurementsForm(userMeasurements));
            } else {
              const userMeasurements: UserBodyMeasurements = {};
              userMeasurements.measurementName = event.target.value;
              dispatch(inputBodyMeasurementsForm(userMeasurements));
            }
          }} /> :
          <TextField 
            margin="dense"
            id={"measurementName"} 
            name={"measurementName"}
            label={"Measurement Name"}
            multiline
            defaultValue={measurementName}
            type="text"
            onChange={(event) => {
            }} 
            InputProps={{
              readOnly: true,
            }} />
      }
      {
        allowEditing && !isEditing ?
        <Link
          color="primary"
          aria-label="Decrement item count"
          onClick={() => {
            setIsEditing(true);
            onEditStateChanged(true);
          }}
          className={classes.pelajariLanjut}
        >
          <Typography className={classes.pelajariLanjutText}>
            UBAH
          </Typography>
        </Link> : 
        null
      }  
    </Box>
  );

  children.push(
    <Grid container className={classes.gridContainer} 
      direction="row" justifyContent="flex-start">
        {
          Object.keys(bodyMeasurements).map((itemKey) => {
            const measurementName = BodyMeasurementDetailList[itemKey].bodyMeasurementName;
            const measurementValue = bodyMeasurements[itemKey as keyof BodyMeasurements] as number;
            return (
              <Grid item xs={12} md={12}>
                {
                  isEditing ?
                  <Box className={classes.measurementValueContainer}>
                    <Typography className={classes.measurementNameText}>
                      {measurementName}
                    </Typography>
                    <Box className={classes.buttonContainer}>
                      <Button onClick={() => {
                        updateMeasurement(itemKey, measurementValue - 1);
                      }}>
                        -
                      </Button>
                      <Typography className={classes.measurementValueText}>
                        {measurementValue}
                      </Typography>
                      <Button onClick={() => {
                        updateMeasurement(itemKey, measurementValue + 1);
                      }}>
                        +
                      </Button>
                    </Box>
                  </Box> :
                  <Box className={classes.measurementValueContainer}>
                    <Typography className={classes.measurementNameText}>
                      {measurementName}
                    </Typography>
                    <Box className={classes.buttonContainer}>
                      <Button disabled>
                        
                      </Button>
                      <Typography className={classes.measurementValueText}>
                        {measurementValue}
                      </Typography>
                      <Button disabled>
                        
                      </Button>
                    </Box>
                  </Box>
                }
              </Grid>
            )
          })
        }
    </Grid>
  );

  return (
    <Box className={classes.componentContainer}>
      {children}
      {
        isEditing ? 
        <Box className={classes.submitFormContainer}>
          <OverlayComponentButton
            formButtonText="Finish Editing"
            isTypeSubmit={false}
            handleClick={() => {
              setIsEditing(false);
              onEditStateChanged(false);
            }}
            buttonMarginTopBottom="10px"
            buttonMarginLeftRight="0px"/>
        </Box> : 
        null
      }
    </Box>
  );
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography, Box, Hidden } from '@material-ui/core';
import { resetCartState } from '../redux/CartRedux';
import { FareastButton } from './OverlayComponentButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  title: {
    padding: '20px',
    fontWeight: 600,
    textTransform: "uppercase",
    fontSize: '28px',
  },
  containerStyle: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginBottom: "20px",
  },
  orderNameText: {
    fontWeight: 500,
    fontSize: '20px',
    marginBottom: '1px',
  },
  orderNameValueText: {
    fontWeight: 300,
    fontSize: '16px',
    marginBottom: '12px',
  },
  nameText: {
    fontWeight: 600,
    fontSize: '15px',
    marginBottom: '7px',
    letterSpacing: "0.4px",
  },
  bodyTextBold: {
    fontWeight: 500,
    fontSize: '13px',
    marginLeft: '3px',
    letterSpacing: "0.4px",
  },
  bodyTextLight: {
    fontWeight: 300,
    fontSize: '11px',
    marginLeft: '3px',
    letterSpacing: "0.4px",
  },
  buttonStyle: {
    marginTop: '5px',
  }
}),
);

export default function PageAccountYourAccount() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  let ordersList = useAppSelector(state => state.ordersList.ordersList);
  if (ordersList.length > 0) {
    ordersList = [...ordersList].reverse();
  }

  return (
      <Box>
        <Typography variant="h5" className={classes.title}>
            Your Orders
        </Typography>
        {
          ordersList.map((option, index) => {

            let paymentStatusString = "PENDING";
            switch (option.paymentStatus) {
              case "capture":
              case "capture_stockonly":
              case "settlement":
                paymentStatusString = "PEMBAYARAN SUKSES";
                break;
              case "abandoned":
                paymentStatusString = "STOK DIKEMBALIKAN";
                break;
              case "pending":
                paymentStatusString = "PENDING";
                break;
              case "unpaid":
                paymentStatusString = "BELUM DIBAYAR";
                break;
            }

            return (
            <Box className={classes.containerStyle}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.orderNameText}>
                    Order ID:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.orderNameValueText}>
                    {option.orderId.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.bodyTextBold}>
                    Estimasi Pengiriman:
                  </Typography>
                  <Typography className={classes.bodyTextLight}>
                    {option.estimasiPengiriman}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.bodyTextBold}>
                    Status Order:
                  </Typography>
                  <Typography className={classes.bodyTextLight}>
                    {paymentStatusString}
                  </Typography>
                </Grid>
              </Grid>
              <FareastButton
                aria-label="balik"
                onClick={() => {
                  history.push("/order/" + option.orderId);
                }}
                buttonMarginLeftRight='0px'
                buttonMarginTopBottom='20px'
                style={{
                  marginBottom: "8px",
                }}
              >
                {
                  option.paymentStatus === "unpaid" ? 
                  "BAYAR SEKARANG":
                  "LIHAT ORDER"
                }
              </FareastButton>
            </Box>);
          })
        }
      </Box>
  );
}
import { Box, Dialog, Divider, Fade, IconButton, Link, Slide } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Bantuan, BantuanSpacer } from '../App';
import { Mixpanel } from '../mixpanel';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputBodyMeasurementsForm, inputMeasurementsForm, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset as closeOverlayAndResetCreateView, updateCurrentScreen } from '../redux/UIOverlayMeasurementCreateViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import HorizontalBar from './OverlayViewGenerateMeasure0HorizontalProgressBar';
import VerticalBar from './OverlayViewGenerateMeasure0VerticalProgressBar';
import { FlowComponentMaster } from './OverlayViewMeasurementCreateNewBlocks';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 2) + " !important") as any,
    },
    dialogContentContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start', 
    },
    dialogContentLeftContainer: {
      width: '46%',
      paddingLeft: '29%',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: '10%',
        paddingTop: '20px',
        width: '80%',
        justifyContent: 'center',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleLogoContainer: {
      width: "100%",
      position: "relative",
      paddingTop: '2.5vw',
      paddingBottom: '6vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '5vw',
        paddingBottom: '7vw',
        height: '10vw',
      },
      cursor: 'pointer',
    },
    titleLogoDesktop: {
      paddingTop: '1.5vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '3vw',
        height: '10vw',
      }
    },
    titleLogoClose: {
      position: "absolute",
      top: "3vw",
      right: "3vw",
      color: "#000000",
    },
    signInsignUpToggleText: {
      fontSize: '15px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px',
      }

    },
    horizontalBarDivider: {
      width: '100%',
      margin: '10px 0px 6px 0px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      }
    },
    horizontalContent: {
      width: '100%',
    }
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMeasurementCreateView);
  const overlayMethodSelectionView = useAppSelector(state => state.overlayMeasurementMethodSelectionView);
  const userMeasurementDraft = useAppSelector(state => state.userMeasurementsList);
  const chatUserId = useAppSelector(state => state.account.chatUserId);
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [childrenVisible, setChildrenVisible] = React.useState<boolean>(true);
  const [titleNumber, setTitleNumber] = React.useState<number>(-1);

  const currentUserMeasurement = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementDraft);
  const currentUserBodyMeasurement = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementDraft);

  React.useEffect(() => {
    // console.log("BLOCKING CALLED, STATUS: " + (overlayView.status !== UIOverlayViewStatus.On ? "NOT BLOCKING" : "BLOCKING"));
    // console.log("BLOCKING CALLED, HISTORY LENGTH: " + history.length);
    const unblockFunction = history.block((() => {
      if (overlayView.status === UIOverlayViewStatus.On) {
        return false;
      }
      return true;
    }) as any);
  
    return function cleanup() {
      unblockFunction();
    };
  }, [overlayView.status]);

  function delayedDispatch(currentScreen: string) {
    setChildrenVisible(false);
    setTimeout(() => {
      dispatch(updateCurrentScreen(currentScreen));
      setChildrenVisible(true);
    }, 1000)
  }

  function closeOverlayAndResetUserMeasurementDraft() {
    dispatch(closeOverlayAndResetCreateView());

    // This makes the code explode since it resets the draft entirely, it removes the method selection dialog and freezes the screen, I still don't know why but the workaround fixed it
    // dispatch(resetUserMeasurementDraft());

    // Workaround for exiting the flow directly => resetting the draft JSON to basically its state at the beginning of method selection flow (but with current time since we lost the original time)
    if (overlayMethodSelectionView.status === UIOverlayViewStatus.On) {
      const payload1: UserClothTypeSpecificBodyMeasurements = {
        clothingType: userMeasurementDraft.userClothingMeasurementDraft.clothingType,
        fitType: userMeasurementDraft.userClothingMeasurementDraft.fitType,
        creationDateTime: userMeasurementDraft.userClothingMeasurementDraft.creationDateTime,
        updateDateTime: userMeasurementDraft.userClothingMeasurementDraft.creationDateTime,
        measurementName: userMeasurementDraft.userClothingMeasurementDraft.measurementName,
      }
      const payload2: UserBodyMeasurements = {
        creationDateTime: userMeasurementDraft.userBodyMeasurementDraft.creationDateTime,
        updateDateTime: userMeasurementDraft.userBodyMeasurementDraft.creationDateTime,
        measurementName: userMeasurementDraft.userBodyMeasurementDraft.measurementName,
      }
      dispatch(resetUserMeasurementDraft());
      dispatch(inputMeasurementsForm(payload1));
      dispatch(inputBodyMeasurementsForm(payload2));
    } else {
      // Normal flow direct exit => who knows, maybe we still want to use this overall flow in the future, such as the Desktop UI, it's still pretty much usable
      dispatch(resetUserMeasurementDraft());
    }

    Mixpanel.track('sizeCreateExit', {
      'signedIn': isSignedIn ? 'true' : 'false',
    });
  }

  if (currentUserMeasurement.clothingType === undefined 
    || currentUserMeasurement.fitType === undefined) {
    return null;
  }

  if (currentUserMeasurement.creationDateTime === undefined) {
    const creationDateTime = new Date().toISOString();
    
    var objToday = new Date(),
      dayOfMonth = objToday.getDate(),
      months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      curMonth = months[objToday.getMonth()],
      curYear = objToday.getFullYear(),
      curHour = objToday.getHours(),
      curMinute = objToday.getMinutes(),
      curSeconds = objToday.getSeconds() < 10 ? "0" + objToday.getSeconds() : objToday.getSeconds(),
      curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

    var measurementName = curMonth + " " + dayOfMonth + " " 
      + curYear + " " + curHour + ":" + curMinute + " - Measurement";
    
    const payload: UserClothTypeSpecificBodyMeasurements = {
      creationDateTime: creationDateTime,
      updateDateTime: creationDateTime,
      measurementName: measurementName,
    }
    dispatch(inputMeasurementsForm(payload));
    
    const payloadBody: UserBodyMeasurements = {
      creationDateTime: creationDateTime,
      updateDateTime: creationDateTime,
      measurementName: measurementName,
    }
    dispatch(inputBodyMeasurementsForm(payloadBody));

    return null;
  }

  let content: JSX.Element = 
  FlowComponentMaster({
    clothingType: currentUserMeasurement.clothingType,
    fitType: currentUserMeasurement.fitType,
    isSignedIn: isSignedIn,
    addToCartAfterCreation: overlayView.addToCartAfterCreation,
    productDetail: overlayView.productDetail,
    chatUserId: chatUserId,
  }, {
    currentScreen: overlayView.currentScreen,
    hasFinishedResultsRating: overlayView.hasFinishedResultsRating,
    titleNumber: titleNumber,
    currentUserMeasurement: currentUserMeasurement,
    currentUserBodyMeasurement: currentUserBodyMeasurement,
  }, {
    delayedDispatch: delayedDispatch,
    setTitleNumber: (titleNumber) => { setTitleNumber(titleNumber); },
  });

  const showTailorHelpButton = overlayView.currentScreen !== "personalOrRegularSize"
  && overlayView.currentScreen !== "regularSelectSize"
  && overlayView.currentScreen !== "dibuatinAtauBuatSendiri"
  && overlayView.currentScreen !== "dibuatinPilihSize"
  && overlayView.currentScreen !== "dibuatinChat";

  const hideBantuanValue = overlayView.currentScreen === "dibuatinAtauBuatSendiri"
  || overlayView.currentScreen === "dibuatinPilihSize"
  || overlayView.currentScreen === "dibuatinChat";

  return (
    <Dialog
    fullScreen
    maxWidth="lg"
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    onClose={closeOverlayAndResetUserMeasurementDraft}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <Box className={classes.titleLogoContainer} >
          <Link onClick={closeOverlayAndResetUserMeasurementDraft}>
            <img className={classes.titleLogoDesktop} src={'https://images-far-east.s3.ap-southeast-1.amazonaws.com/CreateSizeOverlay_StudioSLogo.svg'} alt="maja logo"/>
          </Link>
          <IconButton aria-label="closebutton" className={classes.titleLogoClose} 
          onClick={closeOverlayAndResetUserMeasurementDraft}>
            <Close />
          </IconButton>
        </Box>
        <div className={classes.dialogContentLeftContainer}>
          <Box className={classes.horizontalContent}>
            {
              titleNumber !== -1 ?
              <HorizontalBar start={0} end={4} current={titleNumber}/> :
              null
            }
            {
              titleNumber !== -1 ?
              <Divider variant="middle" className={classes.horizontalBarDivider} /> :
              null
            }
            <Fade in={overlayView.status === UIOverlayViewStatus.On && childrenVisible} timeout={800}>
              {content}
            </Fade>
          </Box>
          {
            titleNumber !== -1 ?
            <VerticalBar start={0} end={4} current={titleNumber}/> :
            null
          }
        </div>
      </div>
      {
        showTailorHelpButton ? 
        <>
          <BantuanSpacer/>
          <Bantuan useTailor={true} hideBantuan={hideBantuanValue} showPersonalSizeProfile={false} openPersonalSizeOverlay={() => {}}/>
        </>:
        <>
          <BantuanSpacer/>
          <Bantuan useTailor={false} hideBantuan={hideBantuanValue} showPersonalSizeProfile={false} openPersonalSizeOverlay={() => {}}/>
        </>
      }
    </Dialog>
  );
}
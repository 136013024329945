import {
  Box,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import { updateState } from "../redux/AccountRedux";
import { useAppDispatch, useAppSelector } from "../reduxhooks";
import { FareastButton } from "./OverlayComponentButton";
import {
  ClothingMeasurements,
  ClothingType,
  FitType,
} from "common-ts/dist/models/UserMeasurements";
import OverlayComponentCanvas from "./OverlayComponentCanvasFitAnimation";
import OverlayComponentBaseTemplate from "./OverlayComponentBaseTemplate";
import { UIOverlayViewStatus } from "common-ts/dist/models/UIOverlayViewStatus";
import {
  Link as RouterLink,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Mixpanel } from "../mixpanel";
import { categoryMap } from "common-ts/dist/models/CategoryList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "300px",
      [theme.breakpoints.only("xs")]: {
        width: "240px",
      },
    },
    content: {
      height: "auto",
      [theme.breakpoints.only("xs")]: {
        padding: "0px 0px 0px 0px",
      },
    },
    contentRow: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "100px",
      textAlign: "center",
      gap: "30px",
    },
    subFormTitle: {
      fontFamily: "OakesGrotesk",
      fontWeight: 400,
      color: "#000000",
      fontSize: "24px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "18px",
      },
    },
    toggleSignUpSignInContainer: {
      paddingTop: "34px",
      paddingBottom: "6px",
      // paddingLeft: '10px',
      paddingRight: "10px",
    },
  })
);

const validationSchema = yup.object({
  measName: yup.string().required("Is required").min(1, "Cannot be empty"),
});

export interface Props {
  clothingMeasurements: ClothingMeasurements | undefined;
  clothingType: ClothingType;
  fitType: FitType;
  key2: string;
  measurementName: string;
  handleSubmit: () => void;
}

export default function RegularSaveMeasurement({
  clothingMeasurements,
  clothingType,
  fitType,
  key2,
  measurementName,
  handleSubmit,
}: Props) {
  const classes = useStyles();

  return (
    <OverlayComponentBaseTemplate alignItems="flex-start">
      <Box>
        <div className={classes.content}>
          <Box
            style={{
              marginTop: "2vw",
              marginBottom: "5vw",
            }}
          >
            {clothingMeasurements ? (
              <OverlayComponentCanvas
                clothingType={clothingType}
                fitType={fitType}
                putAtTop={false}
                scaleRatio={0.9}
                incrementTogether={true}
                goalFit={clothingMeasurements}
                drawGuidelinesData={undefined}
              />
            ) : null}
          </Box>
          <div className={classes.contentRow}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {measurementName}
              </Typography>
              <Typography
                style={{
                  fontSize: "9px",
                  fontWeight: 400,
                  color: "#797979",
                }}
              >
                {getCurrentTimeString()}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Ukuran Kamu Siap Dipakai
              </Typography>
              <Typography
                style={{
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              >
                Ukuran kamu bisa langsung dipakai untuk belanja produk Studio S.
              </Typography>
            </div>
          </div>
          <Link
            component={RouterLink}
            to={categoryMap["SHOP"]}
            onClick={() => {
              handleSubmit();
            }}
          >
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  padding: "10px 25px 7.5px 25px",
                  borderRadius: "20px",
                  fontSize: "10.5px",
                  fontWeight: 600,
                  color: "white",
                  letterSpacing: "0.75px",
                  backgroundColor: "#232323",
                  margin: "0px 7px 20px 7px",
                  textAlign: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "10px",
                    fontWeight: 400,
                    letterSpacing: "1px",
                  }}
                >
                  LANJUT BELANJA
                </Typography>
              </Box>
            </Box>
          </Link>
        </div>
      </Box>
    </OverlayComponentBaseTemplate>
  );
}

function getCurrentTimeString(): string {
  const now = new Date();
  const daysOfWeek = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
  ];
  const dayOfWeek = daysOfWeek[now.getDay()];

  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `Dibuat Hari ini (${dayOfWeek}), ${formattedHours}:${formattedMinutes} ${ampm}`;
}

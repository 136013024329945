import { ProductOnUser } from 'common-ts/dist/models/Product';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { inputMeasurementsForm, resetUserMeasurementDraft } from './MeasurementsRedux';
import { ClothingType, FitType, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';

export interface MeasureInstantSizeTeamFlow {
  status: UIOverlayViewStatus,
  currentScreen: string,
  selectedRegularSize: RegularSizeValue,
  weight: number,
  height: number,
  productDetail: ProductOnUser | undefined,
}

// Define the initial state using that type
const initialState: MeasureInstantSizeTeamFlow = {
  status: UIOverlayViewStatus.OffAndReady,
  currentScreen: "instantSizeTeamIntroduction",
  selectedRegularSize: RegularSizeValue.NOTSELECTED,
  weight: 75,
  height: 175,
  productDetail: undefined,
}


export const overlayAccountViewSlice = createSlice({
  name: 'overlayMeasurementInstantSizeTeam',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;
      state.currentScreen = "instantSizeTeamIntroduction";
      state.selectedRegularSize = RegularSizeValue.NOTSELECTED;
      state.weight = 75;
      state.height = 175;
      state.productDetail = undefined;
    },

    openOverlay: (state, action: PayloadAction<ProductOnUser | undefined>) => {
      state.status = UIOverlayViewStatus.On;
      state.currentScreen = "instantSizeTeamIntroduction";
      state.productDetail = action.payload
    },

    updateCurrentScreen: (state, action: PayloadAction<string>) => {
      state.currentScreen = action.payload;
    },

    updateSelectedRegularSizeValue: (state, action: PayloadAction<RegularSizeValue>) => {
      state.selectedRegularSize = action.payload;
    },

    updateHeightWeightValues: (state, action: PayloadAction<{
      height: number,
      weight: number
    }>) => {
      state.height = action.payload.height;
      state.weight = action.payload.weight;
    }
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, updateCurrentScreen, updateSelectedRegularSizeValue, updateHeightWeightValues } = overlayAccountViewSlice.actions

export default overlayAccountViewSlice.reducer
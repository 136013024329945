import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useRef } from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  contentContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: '3.7vw',
    lineHeight: '5vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    marginBottom: '32px',
    padding: '0px 20px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
  },
  contentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    lineHeight: '1.8',
    marginBottom: '0px',
    padding: '0px 20px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  element: {
    width: '100%',
  },
  svgImageTop: {
    marginTop: "40px",
    width: '100%',
  },
  svgImage: {
    width: '100%',
    marginBottom: "-100px",
  },
  svgImageBottom: {
    marginTop: "100px",
    width: '100%',
    marginBottom: "-20px",
  }
}));

export default function PageProductHeaderVariant1() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const refWa = useRef(null);
  const executeScrollRefWa = () => (refWa as any).current.scrollIntoView({ behavior: 'smooth' })    

  const content = (
    <Box className={classes.contentContainer}>
      <img src="https://d31zz7s33c4k72.cloudfront.net/section-hero-variant-start.jpg" className={classes.svgImageTop}
      onClick={executeScrollRefWa}/>
      <img src="https://d31zz7s33c4k72.cloudfront.net/section-cara-pesan-variant-wa-focus.svg" className={classes.svgImage}/>
      <div ref={refWa}></div>
      <img src="https://d31zz7s33c4k72.cloudfront.net/section-pesan-wa-aja.jpg" className={classes.svgImageBottom}
      onClick={() => {
          // Open WhatsApp verification link message
          const url = 'https://wa.me/6287813028340?text=Permisi%20kak,%20saya%20mau%20tanya%20soal%20produk%20Studio%20S';
          window.open(url, '_blank');
      }}/>
    </Box>
  )

  if (isMdOrUp) {
    return null;
  } else {
    return content;
  }
}
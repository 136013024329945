import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

interface StyleProps {
  widthXs: string,
  widthSm: string,
  widthMd: string,
  widthLg: string,
  padding: string,
  paddingSmDown: string,
  justifyContent: string, // Default is flex-start
}

const useStyles = ({widthXs,widthSm,widthMd,widthLg,padding,paddingSmDown,justifyContent}: StyleProps) => makeStyles((theme: Theme) =>
createStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent,
    alignItems: 'center',
    padding: padding,
    [theme.breakpoints.only('xs')]: {
      width: widthXs,
      padding: paddingSmDown,
    },
    [theme.breakpoints.only('sm')]: {
      width: widthSm,
      padding: paddingSmDown,
    },
    [theme.breakpoints.only('md')]: {
      width: widthMd,
    },
    [theme.breakpoints.up('lg')]: {
      width: widthLg,
    },
  },
}),
);

interface Props {
  widthXs?: string,
  widthSm?: string,
  widthMd?: string,
  widthLg?: string,
  padding?: string,
  paddingSmDown?: string,
  justifyContent?: string, // Default is flex-start
  // alignItems is not supported, this is a vertical modifier
  children?: JSX.Element | JSX.Element[];
}

export default function OverlayComponentBaseContainerVertical({widthXs,widthSm,widthMd,widthLg,padding,paddingSmDown,justifyContent,children}: Props) {
  const classes = useStyles({
    widthXs: widthXs ? widthXs : '100%',
    widthSm: widthSm ? widthSm : '100%',
    widthMd: widthMd ? widthMd : '100%',
    widthLg: widthLg ? widthLg : '100%',
    padding: padding ? padding : '0px',
    paddingSmDown: paddingSmDown ? paddingSmDown : '0px',
    justifyContent: justifyContent ? justifyContent : '100%',
  })();

  return (
    <Box className={classes.container}>
      {
        children
      }
    </Box>
  );
}
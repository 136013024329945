import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { useAppSelector } from '../reduxhooks';
import OverlayComponentChoice from './OverlayComponentChoice';
import OverlayComponentTitle from './OverlayComponentTitle';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  circularProgressContainer: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',

  }
}),
);

interface Props {
  onFetchMeasEstimateSuccess: () => void
}

export default function PageCheckoutMeasurements({ onFetchMeasEstimateSuccess }: Props) {
  const classes = useStyles();
  const fetchMeasurementEstimateState = useAppSelector(state => state.userMeasurementsList.fetchMeasurementEstimateStatus);

  if (fetchMeasurementEstimateState === APIRequestStatus.Success) {
    setTimeout(() => {
      onFetchMeasEstimateSuccess();
    }, 1000);
  }

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <div className={classes.circularProgressContainer}>
      <CircularProgress/>
    </div>);

  return (
    <OverlayComponentChoice
    title=""
    bodyChildren={[
      <OverlayComponentTitle text="Generating your measurements..."/>
    ]}
    options={submitChildren}/>
  );
}
import { Box, Button, Dialog, IconButton, Paper, Slide, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { closeOverlayAndReset, openOverlay } from '../redux/UIOverlayMeasurementKitRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { Close, FiberManualRecord } from '@material-ui/icons';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ReactHtmlParser from 'react-html-parser';
import
{
    AnimatePresence,
    motion,
    MotionProps,
    PanInfo
} from "framer-motion";
import { StyledButtonWrapper, StyledIndicatorIconButtonActive, StyledIndicatorIconButtonNonActive, StyledIndicators } from './OverlayViewPopupFlowVersionStyles';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 5) + " !important") as any,
      '& .MuiPaper-root': { 
        width: "80vw",
        borderRadius: "5.4vw",
        backgroundColor: 'transparent',
      },
      '& .MuiBackdrop-root': { 
        backgroundColor: "rgba(0, 0, 0, 0.7)"
      } 
    },
    dialogContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
      textAlign: 'center',
      overflow: 'hidden'
    },
    dialogDivImage: {
      width: "100%",
      height: "125vw",
      overflow: "hidden",
      position: "relative"
    },
    mainImg: {
      width: "102%",
      height: "125vw",
      objectFit: "cover"
    },

  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMeasurementKit);
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useAppDispatch();
  const classes = useStyles();

  if (isSmOrUp) {
    return (null);
  }

  return (
    <Dialog
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    onClose={() => {
      dispatch(closeOverlayAndReset());
      sendWebsiteEventMessage("User closed measurement kit popup");
    }}
    onClick={() => {
      dispatch(closeOverlayAndReset());
      sendWebsiteEventMessage("User closed measurement kit popup");
    }}
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <div className={classes.dialogDivImage}>
          <img 
          src={"https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/POPUP_MEASKIT.png"} 
          alt="measurement-kit-img"
          className={classes.mainImg}/>
          {/* <IconButton aria-label="closebutton" className={classes.dialogDivImageCloseButton} 
          onClick={() => {
            dispatch(closeOverlayAndReset());
            sendWebsiteEventMessage("User closed measurement kit popup");
          } }>
            <Close />
          </IconButton> */}
        </div>
      </div>
    </Dialog>
  )
}
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartContentEmpty: {
      display: 'block',
      position: 'relative',
      background: '#fafafa',
    },
  }),
);

function CartContentEmpty() {
  const classes = useStyles();

  return (
      <Box className={classes.cartContentEmpty}>
        <Typography variant="body2">
            <b><br></br>YOUR CART IS EMPTY</b>
        </Typography>
      </Box>
  );
}

export default CartContentEmpty;
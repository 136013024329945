// SrsRtcWhepAsync.ts

export interface WhepOptions {
    videoOnly?: boolean;
    audioOnly?: boolean;
  }
  
  export interface WhepResponse {
    sessionid: string;
    simulator: string;
  }
  
  export class SrsRtcWhepAsync {
    private pc: RTCPeerConnection;
    public stream: MediaStream;
    public ontrack?: (event: RTCTrackEvent) => void;
  
    constructor() {
      this.pc = new RTCPeerConnection();
      this.stream = new MediaStream();
  
      this.pc.ontrack = (event: RTCTrackEvent) => {
        if (this.ontrack) {
          this.ontrack(event);
        }
      };
    }
  
    async play(url: string, options?: WhepOptions): Promise<WhepResponse> {
      if (url.indexOf('/whep/') === -1) throw new Error(`invalid WHEP url ${url}`);
      if (options?.videoOnly && options?.audioOnly) throw new Error(`The videoOnly and audioOnly in options can't be true at the same time`);
  
      if (!options?.videoOnly) this.pc.addTransceiver("audio", {direction: "recvonly"});
      if (!options?.audioOnly) this.pc.addTransceiver("video", {direction: "recvonly"});
  
      const offer = await this.pc.createOffer();
      await this.pc.setLocalDescription(offer);
      const answer = await this.sendOfferAndGetAnswer(url, offer.sdp!);
      await this.pc.setRemoteDescription(
        new RTCSessionDescription({type: 'answer', sdp: answer})
      );
  
      return this.parseId(url, offer.sdp || '', answer);
    }
  
    close(): void {
      this.pc.close();
    }
  
    private async sendOfferAndGetAnswer(url: string, offerSdp: string): Promise<string> {
      // console.log(`Generated offer: ${offerSdp}`);
    
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/sdp',
        },
        body: offerSdp,
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    
      const data = await response.text();
      // console.log("Got answer: ", data);
    
      if (data.indexOf('v=0') === -1) {
        throw new Error('Invalid SDP answer');
      }
    
      return data;
    }
  
    private parseId(url: string, offer: string, answer: string): WhepResponse {
      let sessionid = offer.substr(offer.indexOf('a=ice-ufrag:') + 'a=ice-ufrag:'.length);
      sessionid = sessionid.substr(0, sessionid.indexOf('\n') - 1) + ':';
      sessionid += answer.substr(answer.indexOf('a=ice-ufrag:') + 'a=ice-ufrag:'.length);
      sessionid = sessionid.substr(0, sessionid.indexOf('\n'));
  
      const a = document.createElement("a");
      a.href = url;
      return {
        sessionid: sessionid, // Should be ice-ufrag of answer:offer.
        simulator: a.protocol + '//' + a.host + '/rtc/v1/nack/',
      };
    }
  }
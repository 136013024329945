import { Avatar, ButtonBase, Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentForm from './OverlayComponentForm';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  bodyTypeContentImage: {
    width: '100%',
  },
  bodyTypeGridSelection: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  bodyTypeGridItem: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  selectedImageContainer: {
    width: '100%',
  },
  selectedImage: {
    opacity: '1',
    height: '150px',
  },
  selectedImageCheck: {
    color: 'green',
  },
  unselectedImage: {
    height: '150px',
  },
  selectedImageSign: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    backgroundColor: 'rgba(255, 0, 0, 0)',
    [theme.breakpoints.down('xs')]: {
      left: '60px',
    },
  },
  descriptionText: {
    fontSize: '13px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
    fontWeight: 500,
    marginTop: '10px',
    marginBottom: '10px',
  }
}),
);

function SelectedImage({ selected, image_url, image_alt }: 
  { selected: boolean, image_url: string, image_alt: string }) {
  const classes = useStyles();
  if (selected) {
    return (
      <Box className={classes.selectedImageContainer}>
        <Avatar className={classes.selectedImageSign} >
          <CheckCircle className={classes.selectedImageCheck}/>
        </Avatar>
        <img className={classes.selectedImage} src={image_url} alt={image_alt} />
      </Box>
    )
  } else {
    return (
      <Box className={classes.selectedImageContainer}>
        <img className={classes.unselectedImage} src={image_url} alt={image_alt} />
      </Box>
    )
  }
}

const validationSchema = yup.object({
  bodyType: yup.string()
  .min(1, 'Tipe tubuh anda?'),
});

interface Props {
  initialBodyType?: string
  handleSubmit: (values: {bodyType: string}) => void
  titleNumber?: string
}

export default function PageCheckoutMeasurements({ initialBodyType, handleSubmit, titleNumber }: Props) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      bodyType: initialBodyType ? initialBodyType : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.bodyType !== '') {
        handleSubmit(values);
      }
    },
  });

  let children: JSX.Element[] = [];

  children.push(
    <Grid container spacing={4} className={classes.bodyTypeGridSelection}>
      <Grid item xs={12} md={4} className={classes.bodyTypeGridItem}>
        <ButtonBase disableRipple
          onClick={() => {
            formik.setFieldValue("bodyType", "upright_triangle");
            }}>
          <SelectedImage selected={formik.values.bodyType === "upright_triangle"} 
          image_url={process.env.PUBLIC_URL + '/assets/Body_Types_Grey_State_Triangle.svg'} 
          image_alt="body type img 0"/>
        </ButtonBase>
        <Typography className={classes.descriptionText}>
          {"Lebar Bahu < Lebar Perut"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.bodyTypeGridItem}>
        <ButtonBase disableRipple
          onClick={() => {
            formik.setFieldValue("bodyType", "rectangle");
            }}>
          <SelectedImage selected={formik.values.bodyType === "rectangle"} 
          image_url={process.env.PUBLIC_URL + '/assets/Body_Types_Grey_State_Rectangle.svg'} 
          image_alt="body type img 1"/>
        </ButtonBase>
        <Typography className={classes.descriptionText}>
          {"Lebar Bahu = Lebar Perut"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.bodyTypeGridItem}>
        <ButtonBase disableRipple
          onClick={() => {
            formik.setFieldValue("bodyType", "inverted_triangle");
            }}>
          <SelectedImage selected={formik.values.bodyType === "inverted_triangle"} 
          image_url={process.env.PUBLIC_URL + '/assets/Body_Types_Grey_State_Inverted_Triangle.svg'} 
          image_alt="body type img 2"/>
        </ButtonBase>
        <Typography className={classes.descriptionText}>
          {"Lebar Bahu > Lebar Perut"}
        </Typography>
      </Grid>
    </Grid>
  );

  let submitChildren: JSX.Element[] = [];

  if (formik.values.bodyType !== '') {
    submitChildren.push(
      <OverlayComponentButton
        isTypeSubmit={true}
        formButtonText="Next"
        buttonMarginTopBottom="10px"
        buttonMarginLeftRight="0px"
      />
    )
  }

  return (
    <OverlayComponentForm
      formTitle="Mana tipe badan yang paling mirip dengan kamu?"
      formTitleNumber={titleNumber}
      formSubmitBody={submitChildren}
      formFormBody={children}
      formHandleSubmit={formik.handleSubmit}
      titleWidthMdUp='400px'
      titleWidthSmDown='100%'
      formWidthMdUp='420px'
      formWidthSmDown='100%'
      formJustifyContent='space-evenly'
      formAlignItems='flex-start'
    />
  );
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import OverlayComponentForm from './OverlayComponentForm';
import OverlayComponentButton from './OverlayComponentButton';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '330px',
    [theme.breakpoints.down('sm')]: {
      width: '230px',
    }
  },
  form: {
    height: '100%',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  formRow: {
  },
  formContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  subFormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitFormContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    margin: '20px',
    fontWeight: theme.typography.fontWeightMedium as number,
    color: 'white',
    backgroundColor: '#373737',
    borderRadius: '0',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'grey',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    }
  },
  title: {
    marginLeft: theme.spacing(1),
    marginBottom: '10px',
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    }
  },
}),
);

const validationSchema = yup.object({
  tinggi_badan: yup.number()
  .positive('Tinggi badan anda pasti positif!')
  .min(80, 'Tinggi badan tidak valid')
  .max(280, 'Tinggi badan tidak valid')
  .required('Tinggi badan is required'),
  berat_badan: yup.number()
  .positive('Berat badan anda pasti positif!')
  .min(40, 'Berat badan tidak valid')
  .max(300, 'Berat badan tidak valid')
  .required('Berat badan is required'),
});

interface Props {
  initialHeight?: number
  initialWeight?: number
  handleSubmit: (values: {tinggi_badan: number, berat_badan: number}) => void
  titleNumber?: string
  titleText?: string
}

export default function PageCheckoutMeasurements({ initialHeight, initialWeight, handleSubmit, titleNumber, titleText }: Props) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      tinggi_badan: initialHeight ? initialHeight : 0,
      berat_badan: initialWeight ? initialWeight : 0,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  let children: JSX.Element[] = [];

  children.push(
    <TextField
      margin="normal"
      fullWidth
      id="tinggi_badan"
      name="tinggi_badan"
      label="Tinggi Badan"
      type="number"
      value={formik.values.tinggi_badan}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.tinggi_badan && Boolean(formik.errors.tinggi_badan)}
      helperText={(formik.touched.tinggi_badan && formik.errors.tinggi_badan) || "Dalam cm"}
    />
  );
  
  children.push(
    <TextField
      margin="normal"
      fullWidth
      id="berat_badan"
      name="berat_badan"
      label="Berat Badan"
      type="number"
      value={formik.values.berat_badan}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.berat_badan && Boolean(formik.errors.berat_badan)}
      helperText={(formik.touched.berat_badan && formik.errors.berat_badan) || "Dalam kg"}
    />
  );

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <OverlayComponentButton
      isTypeSubmit={true}
      formButtonText="Next"
      buttonMarginTopBottom="10px"
      buttonMarginLeftRight="0px"
    />
  )

  return (
    <OverlayComponentForm
      formTitle= {titleText ? titleText : "Berapa tinggi dan berat badan kamu?"}
      formTitleNumber={titleNumber}
      formSubmitBody={submitChildren}
      formFormBody={children}
      formHandleSubmit={formik.handleSubmit}
      titleWidthMdUp='400px'
      titleWidthSmDown='100%'
      formWidthMdUp='360px'
      formWidthSmDown='100%'
      formJustifyContent='space-evenly'
      formAlignItems='flex-start'
    />
  );
}
import { CircularProgress, Dialog, Slide, TextField, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close, Star, StarHalf, StarOutline } from '@material-ui/icons';
import { useFormik } from 'formik';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as yup from 'yup';
import { postNewReview } from '../redux/ProductReviewsRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentForm from './OverlayComponentForm';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  dialogContainer: {
    padding: '40px 40px 40px 40px',
    backgroundColor: '#ffffff',
    width: 'auto',
    height: '80%',
    [theme.breakpoints.up('md')]: {
      width: '400px',
      padding: '20px 40px 20px 60px',
    },
  },
  closeButton: {
    // color: '#fff',
  },
  loaderContainer: {
    width: '100%',
    height: 'calc(100% - 80px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

const validationSchema = yup.object({
  name: yup.string()
  .min(1, '')
  .required('Nama anda dibutuhkan'),
  stars: yup.number()
  .min(1, '')
  .required('Rating anda dibutuhkan'),
  // headline: yup.string()
  // .min(1, '')
  // .required('Judul komentar dibutuhkan'),
  message: yup.string()
  .min(1, '')
  .required('Komentar anda dibutuhkan'),
});


interface StarsInputElementProps {
  stars: number
  onStarClicked: (stars: number) => void
}

function StarsInputElement({stars, onStarClicked}: StarsInputElementProps) {
  const classes = useStyles();

  return (
    <Box>
      <IconButton onClick={() => {onStarClicked(1)}}>
        {
          stars >= 1 ? 
          <Star/> : 
          stars >= 0.5 ?
          <StarHalf/> :
          <StarOutline/>
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(2)}}>
        {
          stars >= 2 ? 
          <Star/> : 
          stars >= 1.5 ?
          <StarHalf/> :
          <StarOutline/>
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(3)}}>
        {
          stars >= 3 ? 
          <Star/> : 
          stars >= 2.5 ?
          <StarHalf/> :
          <StarOutline/>
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(4)}}>
        {
          stars >= 4 ? 
          <Star/> : 
          stars >= 3.5 ?
          <StarHalf/> :
          <StarOutline/>
        }
      </IconButton>
      <IconButton onClick={() => {onStarClicked(5)}}>
        {
          stars >= 5 ? 
          <Star/> : 
          stars >= 4.5 ?
          <StarHalf/> :
          <StarOutline/>
        }
      </IconButton>
    </Box>
  )
}

interface Props {
  productId: string
  newReviewOverlayOpen: boolean
  setNewReviewOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PageProductOverlayNewReview({productId, newReviewOverlayOpen, setNewReviewOverlayOpen}: Props) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.account.authState === 'signedin');
  const nameOfUser = useAppSelector((state) => state.account.name);

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(true);

  const formik = useFormik({
    initialValues: {
      name: isLoggedIn ? nameOfUser : '',
      stars: 0,
      headline: "",
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(postNewReview({
        productId: productId,
        creationTimestamp: "" + Math.floor(Date.now() / 1000),
        isVerified: false,
        name: values.name,
        sourceOfReview: "WEBSITE",
        stars: values.stars,
        headline: values.headline,
        message: values.message,
      }));
      setIsSubmitting(false);
      formik.setFieldValue("stars", 0);
      formik.setFieldValue("name", '');
      formik.setFieldTouched("name", false);
      formik.setFieldValue("message", '');
      formik.setFieldTouched("message", false);
      setTimeout(() => {
        setNewReviewOverlayOpen(false);
      }, 1000);
      setTimeout(() => {
        setIsSubmitting(true);
      }, 1500);
    },
  });

  let children: JSX.Element[] = [];

  children.push(
    <TextField
      margin="normal"
      fullWidth
      id="name"
      name="name"
      label="Nama"
      type="text"
      disabled={isLoggedIn}
      value={formik.values.name}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.name && Boolean(formik.errors.name)}
      helperText={(formik.touched.name && formik.errors.name)}
    />
  );

  children.push(
    <StarsInputElement
      stars={formik.values.stars}
      onStarClicked={(stars) => {
        formik.setFieldValue("stars", stars);
      }}/>
  );
  
  // children.push(
  //   <TextField
  //     margin="normal"
  //     fullWidth
  //     id="headline"
  //     name="headline"
  //     label="Judul"
  //     type="text"
  //     value={formik.values.headline}
  //     onChange={formik.handleChange}
  //     onBlur={formik.handleBlur}
  //     error={formik.touched.headline && Boolean(formik.errors.headline)}
  //     helperText={(formik.touched.headline && formik.errors.headline)}
  //   />
  // );
  
  children.push(
    <TextField
      margin="normal"
      fullWidth
      id="message"
      name="message"
      label="Review anda"
      type="text"
      value={formik.values.message}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.message && Boolean(formik.errors.message)}
      helperText={(formik.touched.message && formik.errors.message)}
    />
  );

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <OverlayComponentButton
      isTypeSubmit={true}
      formButtonText="Send Review"
      buttonMarginTopBottom="10px"
      buttonMarginLeftRight="0px"
    />
  )


  return (
    <Dialog
    maxWidth="lg"
    fullScreen={!isMdOrUp}
    open={newReviewOverlayOpen}
    TransitionComponent={Transition}
    onClose={() => {setNewReviewOverlayOpen(false)}}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    >
      <Box className={classes.dialogContainer}>
        <OverlayComponentBaseContainerHorizontal alignItems="flex-end">
          <IconButton onClick={() => {setNewReviewOverlayOpen(false)}}
            className={classes.closeButton}
            edge="end">
            <Close/>
          </IconButton>
        </OverlayComponentBaseContainerHorizontal>
        {
          isSubmitting ? 
          <OverlayComponentForm
            formTitle="Send a new review"
            formSubmitBody={submitChildren}
            formFormBody={children}
            formHandleSubmit={formik.handleSubmit}
            titleWidthMdUp='400px'
            titleWidthSmDown='240px'
            formWidthMdUp='360px'
            formWidthSmDown='100%'
            formJustifyContent='space-evenly'
            formAlignItems='flex-start'
          /> :
          <Box className={classes.loaderContainer}>
            <CircularProgress/> 
          </Box>
        }
      </Box>
    </Dialog>
  );
}
import { Box } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { RegularSizeValue, RegularSizeValueShortName, SizeType } from 'common-ts/dist/models/Cart';
import { ProductOnUser } from 'common-ts/dist/models/Product';
import { BodyMeasurements, BodyMeasurementsForSize, CalculateClothBodyMeasurementsFromBodyMeasurements, CalculateClothingMeasurementsFromBodyMeasurements, ClothingMeasurements, ClothingType, ClothingTypeDisplayString, FitType, FitTypeDisplayString, RoundClothingMeasurementsToHalfPoint, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { fetchUserMeasurementEstimates, inputMeasurementsForm, postClothingMeasurement } from '../redux/MeasurementsRedux';
import { openOverlayCreateMeasWithInitialization, openResultRatingOverlay, updateCurrentScreen as updateCurrentScreenCreateView } from '../redux/UIOverlayMeasurementCreateViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import BackButtonSegment from './OverlayViewGenerateMeasure0BackSegment';
import OverlayViewSignUpPersonalOrRegularSize from './OverlayViewGenerateMeasure1SelectMethod1PersonalOrRegularSize';
import OverlayViewSignUpAlgorithmOrFavoriteSize from './OverlayViewGenerateMeasure1SelectMethod2AlgorithmOrFavoriteSize';
import OverlayViewSignUpDibuatinAtauBuatSendiri from './OverlayViewGenerateMeasure1SelectMethod3DibuatinAtauBuatSendiri';
import OverlayViewSignUpStart from './OverlayViewGenerateMeasureMethod1Algorithm0NameAge';
import OverlayViewFaq from './OverlayViewGenerateMeasureMethod1Algorithm10FAQ';
import OverlayViewAlgorithmIntroduction from './OverlayViewGenerateMeasureMethod1Algorithm0Introduction';
import OverlayViewSignUpHeightWeight from './OverlayViewGenerateMeasureMethod1Algorithm1HeightWeight';
import OverlayViewSignUpBodyType from './OverlayViewGenerateMeasureMethod1Algorithm2BodyType';
import OverlayViewSignUpSelectFitIssues from './OverlayViewGenerateMeasureMethod1Algorithm4SelectFitIssues';
import OverlayViewSignUpHaveMeasurementTape from './OverlayViewGenerateMeasureMethod1Algorithm5HaveMeasurementTape';
import OverlayViewSignUpWantToMeasure from './OverlayViewGenerateMeasureMethod1Algorithm5WantToMeasure2';
import OverlayViewSignUpMeasureBodyParts from './OverlayViewGenerateMeasureMethod1Algorithm7MeasureBodyParts';
import OverlayViewSignUpMeasurementEstimateLoading from './OverlayViewGenerateMeasureMethod1Algorithm8MeasurementEstimateLoading';
import OverlayViewSignUpMeasurementEstimate from './OverlayViewGenerateMeasureMethod1Algorithm9MeasurementEstimateIter2';
import OverlayViewRegularSelectSize from './OverlayViewGenerateMeasureMethod2Regular0SelectSize';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import PageTailorChat from './PageTailorChat';
import { socket } from '../socket';
import { openOverlay as openOverlayMeasurementKit} from '../redux/UIOverlayMeasurementKitRedux';
import { openOverlay as openOverlayIST} from '../redux/UIOverlayMeasurementInstantSizeTeamViewRedux';
import BackButtonCustomSegment from './OverlayViewGenerateMeasure0BackSegmentCustom';
import Banner from './OverlayViewGenerateMeasure0Banner';
import { closeOverlayAndReset } from '../redux/UIOverlayMeasurementMethodSelectionViewRedux';
import { closeOverlayAndReset as closeOverlayAndResetSelection } from '../redux/UIOverlayMeasurementSelectionViewRedux';

// Determines which flow is used based on starting parameters
export interface FlowSelectionData {
  chatUserId: string
  clothingType: ClothingType
  fitType: FitType
  isSignedIn: boolean
  addToCartAfterCreation: boolean
  productDetail: ProductOnUser | undefined
}

// State data for flow
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateData {
  currentScreen: string
  titleNumber: number
  currentUserMeasurement: UserClothTypeSpecificBodyMeasurements
  currentUserBodyMeasurement: UserBodyMeasurements
  isDesktop: boolean
}

// Callback to change state data for flow. 
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: 'regularSize' | 'dibuatinAtauBuatSendiri' | 'favoriteOrAlgorithmSize') => void
  setTitleNumber: (titleNumber: number) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentMaster(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentMensTops(flowSelectionData, flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentMensTops(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let titleNumber = -1;

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  switch (flowStateData.currentScreen) {
    case "regularSize": 
    children.push(<Banner source={'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/MEAS_KIT_BANNER.png'} handleClick={() => {
      dispatch(openOverlayMeasurementKit())}}
    />);
    children.push(
    <Box style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <Box style={{
      width: '90%',
    }}>
    <OverlayViewRegularSelectSize
      isMinimized={true}
      useMobileView={true}
      productId={ flowSelectionData?.productDetail?.productId }
      clothingType={flowStateData.currentUserMeasurement.clothingType as ClothingType}
      fitType={flowStateData.currentUserMeasurement.fitType as FitType}
      key2={flowStateData.currentUserMeasurement.clothingType + "-" + flowStateData.currentUserMeasurement.fitType}
      handleSubmit={(value) => {
        Mixpanel.track('regularSelectSizeNext', {
          'selectedSize': value,
        });
        let userMeasurements: UserClothTypeSpecificBodyMeasurements = {
          sizeType: SizeType.regular,
          measurementName: value,
          regularSize: value,
          bodyOrClothingMeasurements: "CLOTHING",
          creationMethod: "online-reguler",
        }
        dispatch(inputMeasurementsForm(userMeasurements));

        dispatch(postClothingMeasurement());

        dispatch(closeOverlayAndReset());
        dispatch(closeOverlayAndResetSelection());
      }}
    />
    </Box>
    </Box>);
    break;
    case "dibuatinAtauBuatSendiri":
    case "favoriteOrAlgorithmSize":
      children.push(
    <Box style={{
      width: '100%',
      overflowX: 'hidden'
    }} id={'method-selection-slider-box'} > 
    <Box style={{
      width: '200%',
      maxWidth: flowStateData.isDesktop ? '760px' : '1000px',
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'hidden'
    }}>
      <Box style={{
        width: '100%',
        maxWidth: flowStateData.isDesktop ? '380px' : undefined
      }}>
        <OverlayViewSignUpDibuatinAtauBuatSendiri
          isMinimized={true}
          handleSubmit={(chooseInstantSizeTeam: boolean) => {
            if (chooseInstantSizeTeam) {
              Mixpanel.track('dibuatinAtauBuatSendiriNext', {
                'answer': 'dibuatin',
              });
              dispatch(openOverlayIST(flowSelectionData.productDetail))
              sendWebsiteEventMessage("User chooses dibuatkan tim.");
            } else {
              Mixpanel.track('dibuatinAtauBuatSendiriNext', {
                'answer': 'sendiri',
              });
              // delayedDispatch("favoriteOrAlgorithmSize");
              sendWebsiteEventMessage("User chooses buat sendiri.");

              // SLIDE TO OPTIONS (RIGHT)
              const methodSelectionSliderBoxEl = document.getElementById('method-selection-slider-box');
              methodSelectionSliderBoxEl?.scrollTo({
                top: 0,
                left: flowStateData.isDesktop ? 1500 : 1000,
                behavior: 'smooth',
              });
            }
          }
        }/>
      </Box>
      <Box style={{
        width: '100%',
        maxWidth: flowStateData.isDesktop ? '380px' : undefined
      }}>
        <Box style={{
          width: '90%',
          paddingLeft: '7%',
        }}>
          <BackButtonCustomSegment
            handleBack={() => {
              Mixpanel.track('favoriteOrAlgorithmSizeBack');
              // delayedDispatch("dibuatinAtauBuatSendiri");

              // SLIDE TO OPTIONS (LEFT)
              const methodSelectionSliderBoxEl = document.getElementById('method-selection-slider-box');
              methodSelectionSliderBoxEl?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }}
          />
          <OverlayViewSignUpAlgorithmOrFavoriteSize
            isMinimized={true}
            handleSubmit={(chooseAlgorithmSize: boolean) => {
              if (chooseAlgorithmSize) {
                Mixpanel.track('algorithmOrFavoriteSizeNext', {
                  'answer': 'algorithm',
                });
                Mixpanel.track('algorithmSizeStart');
                sendWebsiteEventMessage("User chooses to create custom size using algorithm.");

                dispatch(openOverlayCreateMeasWithInitialization({
                  clothingType: flowSelectionData.clothingType,
                  fitType: flowSelectionData.fitType,
                  productDetail: flowSelectionData.productDetail,
                  addToCartAfterCreation: flowSelectionData.addToCartAfterCreation,
                }));
                
                const payloadBody: UserClothTypeSpecificBodyMeasurements = {
                  sizeType: SizeType.personal,
                }
                dispatch(inputMeasurementsForm(payloadBody));
                
                dispatch(updateCurrentScreenCreateView('algorithmIntroduction'))
              } else {
                Mixpanel.track('algorithmOrFavoriteSizeNext', {
                  'answer': 'favorite',
                });
                Mixpanel.track('favoriteSizeStart');
                sendWebsiteEventMessage("User chooses to create custom size using favorite size.");

                dispatch(openOverlayCreateMeasWithInitialization({
                  clothingType: flowSelectionData.clothingType,
                  fitType: flowSelectionData.fitType,
                  productDetail: flowSelectionData.productDetail,
                  addToCartAfterCreation: flowSelectionData.addToCartAfterCreation,
                }));

                const payloadBody: UserClothTypeSpecificBodyMeasurements = {
                  sizeType: SizeType.personal,
                }
                dispatch(inputMeasurementsForm(payloadBody));

                dispatch(updateCurrentScreenCreateView('favoriteSelectSize'))
              }
            }
          }/>
        </Box>
      </Box>
    </Box>
    </Box>
      );
      break
  }

  if (flowStateData.titleNumber != titleNumber) {
    flowStateCallback.setTitleNumber(titleNumber);
  }

  return children;
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Box, Dialog, IconButton, Link, Slide, Typography } from '@material-ui/core';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import { ArrowBack, Backspace } from '@material-ui/icons';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentTitle from './OverlayComponentTitle';

interface StyleProps {
  height: string
}

const useStyles = ({ height }: StyleProps) => makeStyles((theme: Theme) =>
  createStyles({
    barContainer: {
      display:'flex',
      flexDirection: 'column',
      // paddingTop: '34px',
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      }
    },
    verticalBarProgressBackground: {
      width: '1px',
      height: '15rem',
      backgroundColor: '#aaa',
      position: 'relative',
      // marginTop: '10px',
      marginLeft: '8px',
    },
    verticalBarProgress: {
      position: 'absolute',
      width: '2px',
      left: '-1px',
      backgroundColor: '#000',
      transition: 'all .25s linear',
      height: height,
    }
  }),
);

interface BackButtonSegmentProps {
  start: number
  end: number
  current: number
}

export default function VerticalBar({ start, end, current }: BackButtonSegmentProps) {
  let calcHeight = 0;
  calcHeight = 100 * (current - start)/(end - start); 
  const classes = useStyles({height: calcHeight.toString() + '%' })();

  return (
    <div className={classes.barContainer}>
      <OverlayComponentTitle text={'0' + current.toString() + '.'} fontSize='15px'/>
      <div className={classes.verticalBarProgressBackground}>
        <div className={classes.verticalBarProgress}>
        </div>
      </div>
      <OverlayComponentTitle text={'0' + end.toString() + '.'} fontSize='15px'/>
    </div>
  )
}
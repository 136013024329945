import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';
import PageComponentTitleBelowImage from './PageComponentTitleBelowImage';
import PageComponentTitleNextToImageFloat from './PageComponentTitleNextToImageFloat';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  contentContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.only('xs')]: {
      padding: '20px 0px 0px 0px',
      marginBottom: '80px',
    }
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: '3.7vw',
    lineHeight: '5vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    marginBottom: '32px',
    padding: '0px 20px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
  },
  contentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    lineHeight: '1.8',
    marginBottom: '0px',
    padding: '0px 20px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  element: {
    width: '100%',
  },
  svgText: {
    width: '100%',
    paddingLeft: '2vw',
  }
}));

export default function GreatFitGreatLooks() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const content = (
    <Box>
    </Box>
  )

  const nextToImage = (
    <div className={classes.container}>
      <div className={classes.element}>
        <PageComponentTitleNextToImageFloat
          imageUrl='https://d31zz7s33c4k72.cloudfront.net/SLA_1_D.svg'
          isVideo={false}
          imageLeft={true}
          mediaWidth='100%'
          imageAlignItems='center'
          imageGridSize={12}
          contentGridSize={0}
          content={content}
          topTextContainer='0px'
          leftTextContainer='0vw'
          margin='0px 0px 0px 0px'
        />
      </div>
    </div>);

  
const mobile = (
  <div className={classes.container}>
    <div className={classes.element}>
      <PageComponentTitleBelowImage
        imageUrl='https://d31zz7s33c4k72.cloudfront.net/SLA_1_M.svg'
        isVideo={false}
        mediaWidth='100%'
        content={content}
      />
    </div>
  </div>);

if (isMdOrUp) {
  return nextToImage;
} else {
  return mobile;
}
}
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import { Address, AddressListState } from 'common-ts/dist/models/Address';

// Define the initial state using that type
const initialState: AddressListState = {
  addressDraft: {},
  addressList: [],
  status: APIRequestStatus.Idle,
  error: null,
}

export const fetchAddresses = createAsyncThunk('addressList/fetchAddresses', 
  async (payload, thunkAPI) => {
    const rootState = (thunkAPI.getState() as RootState);
    const idToken = rootState.account.idToken;

    var config: AxiosRequestConfig = {
      method: 'get',
      url: 'https://2bncuh2384.execute-api.ap-southeast-1.amazonaws.com/AddressAPIProduction/addresses',
      headers: { 
        'Authorization': 'Bearer ' + idToken, 
      }
    };

    const response = await axios(config);
    return response.data
  })

export const postAddress = createAsyncThunk(
  'addressList/postAddress', 
  async (payload, thunkAPI) => {

    let addressDraft = (thunkAPI.getState() as RootState).addressList.addressDraft;

    const data = JSON.stringify(addressDraft);

    const rootState = (thunkAPI.getState() as RootState);
    const idToken = rootState.account.idToken;

    var config: AxiosRequestConfig = {
      method: 'post',
      url: 'https://2bncuh2384.execute-api.ap-southeast-1.amazonaws.com/AddressAPIProduction/address',
      headers: { 
        'Authorization': 'Bearer ' + idToken, 
      },
      data: data,
    };

    const response = await axios(config);

    thunkAPI.dispatch(fetchAddresses());

    return response.data
  });

export const slice = createSlice({
  name: 'addressList',
  initialState,
  reducers: {
    resetAddressDraft: (state) => {
      state.addressDraft = {};
    },

    resetAddressList: (state) => {
      state.addressList = [];
      state.status = APIRequestStatus.Idle;
    },

    inputAddressForm: (state, action: PayloadAction<Address>) => {
      Object.assign(state.addressDraft, action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAddresses.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        // Add Payload to our state
        state.addressList = JSON.parse(action.payload.result).Items;
        state.status = APIRequestStatus.Success;
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
        console.log("fetch is failure");
      })
      .addCase(postAddress.pending, (state, action) => {
        console.log("Sending user measurement data");
      })
      .addCase(postAddress.fulfilled, (state, action) => {
        console.log("Success: Sent user measurement data");
      })
      .addCase(postAddress.rejected, (state, action) => {
        console.log("Failure: User measurement data send error");
      })
  }
})

// Action creators are generated for each case reducer function
export const { resetAddressDraft, resetAddressList, inputAddressForm } = slice.actions

export default slice.reducer
import { Box, Dialog, Fade, Slide, DialogTitle, Typography, IconButton, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { postClothingMeasurementPreference, resetUserMeasurementDraft, saveLocallyClothingMeasurementPreference } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset } from '../redux/UIOverlayMeasurementSelectionViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FlowComponentMasterPersonalSize } from './OverlayViewMeasurementSelectionBlocks';
import { Close } from '@material-ui/icons';
// import { openOverlayCreateMeasWithInitialization } from '../redux/UIOverlayMeasurementCreateViewRedux';
import { openOverlayCreateMeasWithInitialization } from '../redux/UIOverlayMeasurementMethodSelectionViewRedux';
import { Mixpanel } from '../mixpanel';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';

interface stylesProps {
  isFullScreen: boolean
}

const useStyles = ({ isFullScreen }: stylesProps) =>  makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 2) + " !important") as any,
      '& .MuiPaper-root': {
        width: '380px',
        borderRadius: isFullScreen ? '0px' : '30px',
        margin: isFullScreen ? '0px' : '0px 16px',
        maxHeight: isFullScreen ? '100%' : 'calc(100% - 16px)',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        position: 'relative',
      },
    },
    dialogContentContainer: {
      width: '100%',
      display: 'block',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
      paddingBottom: '120px',
      height: '70vh',
      overflow: 'scroll',
    },
    popupTitle: {
      padding: '0.5em 0.5em 0 0.5em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    popupTitlePilihUkuranText: {
      fontSize: '12px',
      color: '#D1D1D1',
      fontWeight: 300,
      padding: '16px 0px 0px 18px'
    },
    popupCloseButton: {
      marginRight: '3px',
    },
    popupActions: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      margin: "0 auto",
      padding: '20px 10px 20px 10px',
      borderTop: `1px solid ${theme.palette.divider}`,
      boxShadow: "0px -4px 6px -1px rgba(0,0,0,0.1)",
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
    },
    popupNewSizeButton: {
      marginBottom: theme.spacing(1),
      borderRadius: 20,
      border: "1px solid #969696",
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMeasurementSelectionView);
  const overlayViewEditView = useAppSelector(state => state.overlayMeasurementEditView);
  const overlayMethodSelection = useAppSelector(state => state.overlayMeasurementMethodSelectionView);
  const postMeasurementStatus = useAppSelector(state => state.userMeasurementsList.postMeasurementStatus);
  const dispatch = useAppDispatch();
  const classes = useStyles({ isFullScreen: overlayView.isFullScreen })();

  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');

  const [currentScreen, setCurrentScreen] = React.useState<string>("selectionScreen");
  const [childrenVisible, setChildrenVisible] = React.useState<boolean>(true);
  const [titleNumber, setTitleNumber] = React.useState<number>(-1);

  const isSelectionDisabled = !overlayView.pilihUkuranData.key2 || !overlayView.pilihUkuranData.creationDateTime || !overlayView.pilihUkuranData.prefCreationDateTime || postMeasurementStatus === APIRequestStatus.RequestInProgress

  function delayedDispatch(currentScreenValue: string) {
    setChildrenVisible(false);
    setTimeout(() => {
      setCurrentScreen(currentScreenValue);
      setChildrenVisible(true);
    }, 1000)
  }

  function closeOverlay() {
    dispatch(closeOverlayAndReset());
    delayedDispatch("selectionScreen");
  }

  let content: JSX.Element = 
  FlowComponentMasterPersonalSize({
    clothingType: overlayView.clothingType,
    fitType: overlayView.fitType,
    productDetail: overlayView.productDetail ?? undefined,
  }, {
    currentScreen: currentScreen,
    titleNumber: titleNumber,
  }, {
    delayedDispatch: delayedDispatch,
    setTitleNumber: (titleNumber) => { setTitleNumber(titleNumber); },
  });

  return (
    <Dialog
      maxWidth="lg"
      fullScreen={overlayView.isFullScreen}
      open={overlayView.status === UIOverlayViewStatus.On}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeOverlay}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialogContainer}
      style={{
        // display: overlayViewEditView.status === UIOverlayViewStatus.On || overlayMethodSelection.status === UIOverlayViewStatus.On ? 'none' : 'block'
        display: 'block',
      }}
    >
      <DialogTitle style={{ padding: '0px 0px' }}>
        <Box className={classes.popupTitle}>
          <Typography className={classes.popupTitlePilihUkuranText}>
            PILIH UKURAN
          </Typography>
          <IconButton
            className={classes.popupCloseButton}
            edge="end"
            color="inherit"
            onClick={closeOverlay}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <div className={classes.dialogContentContainer}>
        <Fade in={childrenVisible} timeout={800} style={{
          width: '90%',
          paddingLeft: '5%'
        }}>
          {content}
        </Fade>
      </div>
      <Box className={classes.popupActions}>
        <Button
          fullWidth
          variant="outlined"
          className={classes.popupNewSizeButton}
          style={{
            letterSpacing: '0.46px',
            fontSize: '11.7px',
            padding: '7px 0px',
            fontWeight: 600
          }}
          onClick={(event) => {
            dispatch(openOverlayCreateMeasWithInitialization({
              clothingType: overlayView.clothingType,
              fitType: overlayView.fitType,
              productDetail: overlayView.productDetail ?? undefined,
              addToCartAfterCreation: false,
            }));
            
            (window as any).dataLayer.push({'event': 'sizeCreateNew'});
            Mixpanel.track('sizeCreateNew', {
              'signedIn': isSignedIn ? 'true' : 'false',
              'source': 'productPageSizeSelection',
            });
          }}
        >
          + BUAT UKURAN BARU
        </Button>
        <Button 
          fullWidth 
          disabled={isSelectionDisabled}
          onClick={() => {
            dispatch(saveLocallyClothingMeasurementPreference({
              key2: overlayView.pilihUkuranData.key2,
              creationDateTime: overlayView.pilihUkuranData.prefCreationDateTime
            }));
            if (overlayView.pilihUkuranData.isOwnedByUser) {
              dispatch(postClothingMeasurementPreference({
                key2: overlayView.pilihUkuranData.key2,
                creationDateTime: overlayView.pilihUkuranData.creationDateTime
              }));
            }
            closeOverlay()
          }} 
          color="primary"
          style={{
            letterSpacing: '0.46px',
            fontSize: '11.7px',
            fontWeight: 600,
            padding: '7px 0px',
            backgroundColor: isSelectionDisabled ? 'lightgray' : '#121212',
            color: '#ffffff',
            borderRadius: '30px',
          }}
        >
          PILIH UKURAN
        </Button>
      </Box>
    </Dialog>
  )
}
import { Box, ButtonBase, CircularProgress, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ClothingType, FitType } from 'common-ts/dist/models/UserMeasurements';
import { getCurrentSelectedMeasurement } from '../redux/MeasurementsRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { SizeType } from 'common-ts/dist/models/Cart';
import { UkuranBadanCardMini } from './PageProductDetailSizeOptions';
import { closeOverlayAndReset as closeOverlayAndResetEditView, openOverlay as openOverlayEditView, setValues as setValuesEditView } from '../redux/UIOverlayMeasurementEditViewRedux';
import { closeOverlayAndReset as closeOverlayAndResetSelection, openOverlay as openOverlaySelection, setValues as setValuesSelection } from '../redux/UIOverlayMeasurementSelectionViewRedux';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import ProductOnUser from 'common-ts/dist/models/Product';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  measurementMenuItemBody: {
    marginBottom: '10px',
    position: 'relative',
    textAlign: 'left',

    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  measurementMenuItem: {
    padding: '14px 16px 14px 16px',
    marginBottom: '10px',
    borderRadius: '40px',
    position: 'relative',
    textAlign: 'left',

    backgroundColor: '#eaeaea',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  measurementMenuItemCheck: {
    width: '20px',
    position: 'absolute',
    top: '-5px',
    right: '-5px'
  },
  measurementMenuItemMainText: {
    fontSize: '12px',
    letterSpacing: '0.3px',
    fontWeight: 600,
    color: '#232323',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.2vw',
    }
  },
  measurementMenuItemDetailTitleText: {
    fontSize: '11px',
    fontWeight: 600,
    color: '#232323',
    marginBottom: '3px',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.3vw',
    }
  },
  measurementMenuItemDetailContentText: {
    fontSize: '10px',
    fontWeight: 300,
    color: '#232323',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.3vw',
    }
  },
  measurementMenuItemTanggalContentText: {
    fontSize: '2.1vw',
    fontStyle: 'italic',
    fontWeight: 300,
    textAlign: 'left',
    color: '#232323',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      fontSize: '8.4px'
    }
  },
  measurementMenuItemBuatUkuranText: {
    fontSize: '13px',
    letterSpacing: '0.5px',
    fontWeight: 600,
    paddingTop: '2px',
    paddingLeft: '6px',
    color: '#232323'
  },
  gantiUkuranTextBody: {
    fontSize: "3vw",
    letterSpacing: '0.2px',
    fontWeight: 500,
    textDecoration: "underline #121212 solid 1px",
    textUnderlineOffset: "3px",
    paddingTop: '2px',
    color: '#ffffff',
    position: 'absolute',
    right: '20px',
    bottom: '18px',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px'
    }
  },
  gantiUkuranTextPersonal: {
    fontSize: "3vw",
    letterSpacing: '0.2px',
    // textWrap: "nowrap",
    fontWeight: 500,
    textDecoration: "underline #121212 solid 1px",
    textUnderlineOffset: "3px",
    paddingLeft: "6px",
    paddingTop: '2px',
    position: 'absolute',
    right: '20px',
    bottom: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px'
    }
  },
  gantiUkuranText: {
    fontSize: "11px",
    letterSpacing: '0.2px',
    // textWrap: "nowrap",
    fontWeight: 500,
    textDecoration: "underline #121212 solid 1px",
    textUnderlineOffset: "3px",
    paddingLeft: "6px",
    paddingTop: '2px',
    position: 'absolute',
    right: '20px',
    top: '16px',
  },
  sizeOptionsBoxBadanImage: {
    width: "100%",
    objectFit: "cover",
    borderRadius: '20px',
    padding: '1.5px',
    border: '1.4px solid #BBBBBB',
  },
  sizeOptionsBoxBadanCardBox: {
    position: 'absolute', 
    top: '2.8vw', 
    left: '2.3vw',
    [theme.breakpoints.up('sm')]: {
      top: '12px',
      left: '13px',
    }
  },
  sizeOptionsBoxBadanGantiText: {
    fontSize: '2.2vw',
    fontWeight: 500,
    color: '#ffffff',
    padding: '1.7vw 2vw 1vw 2vw',
    borderRadius: '4vw',
    border: '0.7px solid #ffffff',
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px'
    }
  },
  sizeOptionsBoxBadanNameText: {
    fontSize: '3.7vw',
    letterSpacing: '0.2vw',
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: '0.2vw',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      letterSpacing: '0.8px',
      marginBottom: '2px',
    }
  },
  sizeOptionsBoxBadanTanggalText: {
    fontSize: '2.1vw',
    fontStyle: 'italic',
    fontWeight: 300,
    color: '#bfbfbf',
    textWrap: 'nowrap',
    paddingRight: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px'
    }
  },
  sizeOptionsBoxBadanTinggiBeratText: {
    fontSize: '1.5vw',
    fontWeight: 400,
    color: '#ffffff',
    marginTop: '0.8vw',
    marginLeft: '1.4vw',
    marginBottom: '0.2vw',
    [theme.breakpoints.up('sm')]: {
      fontSize: '6px',
      marginTop: '3px',
      marginLeft: '7px',
      marginBottom: '1px',
    }
  },
  sizeOptionsBoxBadanTinggiBeratValueText: {
    fontSize: '3vw',
    fontWeight: 400,
    marginLeft: '1.4vw',
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      fontSize: '11px',
      marginLeft: '7px',
    }
  },
  sizeOptionsBoxLihatHasilBajuIconButton: {
    border: '1px solid #ffffff',
    borderRadius: '30px',
    color: '#ffffff',
    marginTop: '1.8vw',
    padding: '0.8vw 1.7vw',
    [theme.breakpoints.up('sm')]: {
      marginTop: '7px',
      padding: '3px 7px 3px 7px',
    }
  },
  sizeOptionsBoxLihatHasilBajuIconImg: {
    width: '4.1vw',
    margin: '-0.5vw',
    [theme.breakpoints.up('sm')]: {
      width: '16px',
      margin: '-2px',
    }
  },
  sizeOptionsBoxLihatHasilBajuText: {
    fontSize: '2.4vw', // 9px -> 3px is 0.8vw
    marginLeft: '1.6vw',
    paddingTop: '0.52vw',
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px',
      marginLeft: '6px',
      paddingTop: '2px',
    }
  },
  sizeOptionsBoxDetailIconButton: {
    border: '1px solid #969696',
    borderRadius: '30px',
    color: '#000',
    padding: '1.2vw 2.4vw',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 10px 5px 10px',
    }
  },
  sizeOptionsBoxDetailIconImg: {
    width: '4.1vw',
    margin: '-0.5vw',
    [theme.breakpoints.up('sm')]: {
      width: '16px',
      margin: '-2px',
    }
  },
  sizeOptionsBoxDetailText: {
    fontWeight: 500,
    letterSpacing: 0.5,
    paddingTop: '2px',
    fontSize: '2.1vw', // 9px -> 3px is 0.8vw
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px',
      paddingTop: '2px',
    }
  },
  sizeOptionsBoxGantiSizeIconButton: {
    border: '1px solid #000',
    backgroundColor: '#000',
    borderRadius: '30px',
    color: '#fff',
    padding: '1.2vw 2.4vw',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 10px 5px 10px',
    }
  },
  sizeOptionsBoxGantiSizeText: {
    fontWeight: 500,
    letterSpacing: 0.5,
    paddingTop: '2px',
    fontSize: '2.1vw', // 9px -> 3px is 0.8vw
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px',
      paddingTop: '2px',
    }
  }
}),
);


interface SizeSelectionProps {
  clothingType: ClothingType
  fitType: FitType
  handleClick: () => void
  productDetail?: ProductOnUser
  headerElement?: JSX.Element
  noMeasAlternativeElement?: JSX.Element
}

export default function SizeSelection({handleClick, clothingType, fitType, productDetail, noMeasAlternativeElement, headerElement}: SizeSelectionProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const key2 = clothingType + "-" + fitType;

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  const userPrefDict = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsPreferenceDictionary);

  const userBodyMeasurementsDictionary = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const userClothingMeasurementsDictionary = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsDictionary);

  const postMeasurementStatus = useAppSelector(state => state.userMeasurementsList.postMeasurementStatus);

  const {
    currentSelectedMeasurement,
    currentSelectedMeasurementIsBody,
  } = getCurrentSelectedMeasurement(key2, userPrefDict, userBodyMeasurementsDictionary, userClothingMeasurementsDictionary);

  if (postMeasurementStatus === APIRequestStatus.RequestInProgress) {
    return (<Box className={classes.measurementMenuItem}
      key={'loading-bar-size'}
      style={{
        justifyContent: 'center'
      }}
    >
      <CircularProgress style={{
        width: '30px',
        height: '30px',
      }}/>
    </Box>);
  }

  if (currentSelectedMeasurement) {

    const measName = currentSelectedMeasurement.measurementName;
    const isBody = currentSelectedMeasurement.bodyOrClothingMeasurements === "BODY";
    const caraBuatString = currentSelectedMeasurement.caraBuatString;
    const sizeType = currentSelectedMeasurement.sizeType;
    const creationDateTime = currentSelectedMeasurement.creationDateTime;

    console.log(JSON.stringify(currentSelectedMeasurement, null, 2));

    // BODY MEASUREMENT
    const nama = currentSelectedMeasurement.nama;
    const height = currentSelectedMeasurement.height ? currentSelectedMeasurement.height : '175';
    const weight = currentSelectedMeasurement.weight ?currentSelectedMeasurement.weight : '75';

    return (
      <Box 
        className={classes.measurementMenuItem}
        key={creationDateTime}
      >
        <Grid container>
          <Grid item xs={7} style={{display: 'flex', alignItems: 'center'}}>
            <Grid container>
              {
                sizeType === SizeType.personal && (
                  <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                    <Typography className={classes.measurementMenuItemTanggalContentText}>
                      Dibuat {formatTimestampSize(creationDateTime)}
                    </Typography>
                  </Grid>
                )
              }
              <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                <Typography className={classes.measurementMenuItemMainText} style={{width: '98%'}}>
                  {measName} {isBody ? `| TB ${height} BB ${weight}` : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <Grid container spacing={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Box
                  style={{
                    display: 'flex',
                    gap: '5px'
                  }}
                >
                  {
                    sizeType === SizeType.personal && (
                      <IconButton
                        className={classes.sizeOptionsBoxDetailIconButton}
                        onClick={() => {
                          handleClick()
                          dispatch(closeOverlayAndResetEditView());
                          dispatch(setValuesEditView({
                            overlayType: 'view',
                            clothingType: clothingType,
                            fitType: fitType,
                            isFullScreen: false,
                            currentMeasurementObject: currentSelectedMeasurement,
                            productDetail: productDetail ?? undefined,
                          }))
                          dispatch(openOverlayEditView());
                        }} aria-label="detail">
                          <Typography className={classes.sizeOptionsBoxDetailText}>
                            DETAIL ⓘ
                          </Typography>
                      </IconButton>
                    )
                  }
                  <IconButton
                    className={classes.sizeOptionsBoxGantiSizeIconButton}
                    onClick={() => {
                      handleClick()
                      dispatch(closeOverlayAndResetSelection());
                      dispatch(setValuesSelection({
                        clothingType: clothingType,
                        fitType: fitType,
                        isFullScreen: false,
                        productDetail: productDetail ?? undefined,
                      }))
                      dispatch(openOverlaySelection());
                    }} aria-label="change-size">
                      <Typography className={classes.sizeOptionsBoxGantiSizeText}>
                        GANTI SIZE
                      </Typography>
                  </IconButton>
                </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  } else {
    return noMeasAlternativeElement !== undefined ? noMeasAlternativeElement : null;
  }
}

export function formatTimestampSize(isoString: string): string {
  const now = new Date();
  const date = new Date(isoString);
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const availableDays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']; // Minggu di 0 ternyata

  if (seconds < 60) {
    return 'Sekarang';
  } else if (minutes < 60) {
    return `${minutes} Menit Lalu`;
  } else if (hours < 24) {
    return formatTime(date);
  } else if (days < 7) {
    return `${days} hari lalu (${availableDays[date.getDay()]}), ${formatTime(date)}`;
  } else {
    return date.toLocaleString('id-ID', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }
}
import { Box, Dialog, Divider, Fade, Link, Slide } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { closeResultRatingOverlay, updateResultRatingCurrentScreen } from '../redux/UIOverlayMeasurementCreateViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FlowComponentRatingsBlocks } from './OverlayViewMeasurementCreateNewRatingsBlocks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 5) + " !important") as any,
      '& .MuiPaper-root': { 
        width: "90vw",
        borderRadius: "5.4vw",
        margin: "0",
        [theme.breakpoints.up('md')]: {
          width: "30vw",
          borderRadius: "2vw",
        }
      }
    },
    dialogContentContainer: {
      // minWidth: "348px",
      // minHeight: "420px",
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMeasurementCreateView);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [childrenVisible, setChildrenVisible] = React.useState<boolean>(true);

  function delayedDispatch(currentScreen: string) {
    setChildrenVisible(false);
    setTimeout(() => {
      dispatch(updateResultRatingCurrentScreen(currentScreen));
      setChildrenVisible(true);
    }, 1000)
  }

  let content: JSX.Element = 
  FlowComponentRatingsBlocks({
    currentScreen: overlayView.resultsRatingCurrentScreen,
  }, {
    delayedDispatch: delayedDispatch,
  });

  return (
    <Dialog
    maxWidth="lg"
    open={overlayView.resultsRatingStatus === UIOverlayViewStatus.On}
    keepMounted
    TransitionComponent={Transition}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <Fade in={overlayView.resultsRatingStatus === UIOverlayViewStatus.On && childrenVisible} timeout={800}>
          {content}
        </Fade>
      </div>
    </Dialog>
  );
}
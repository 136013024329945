import { useFormik } from 'formik';
import * as yup from 'yup';

import { ArrowBack } from '@material-ui/icons';
import { Box, createStyles, makeStyles, TextField, Typography, IconButton } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { Theme } from '@mui/material';

import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentForm from './OverlayComponentForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(2),
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'black',
    },
    blockContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    pageTitleContainer: {
      display: 'flex',
      gap: '0.8em',
    },
    pageTitle: {
      fontSize: '1.1em',
      fontWeight: 550,
    },
    pageItemIcon: {
      paddingTop: '0.5em',
    },
    pageItemTitle: {
      backgroundColor: 'red',
      flex: 'auto',
      paddingTop: '0.2em',
      fontSize: '0.8em',
      fontWeight: 500,
    },
    nextButton: {
      marginTop: '1em',
      marginLeft: 'auto',
    },
    bodyTypeImageContainer: {
      marginTop: '1em',
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
    },
    bodyTypeLabel: {
      margin: '2em 0em',
      fontSize: '0.8rem',
      lineHeight: '1rem',
      opacity: '0.54',
      fontWeight: 400,
      fontFamily: 'OakesGrotesk, sans-serif',
    },
    bodyTypeImage: {
      position: 'relative',
      width: '20%',
      height: 'auto',
      padding: '5%',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
      cursor: 'pointer',
      '-webkit-tap-highlight-color': 'transparent',
    },
    checkIcon: {
      position: 'absolute',
      top: '5%',
      left: '5%',
      color: 'green',
      fontSize: '1.5rem',
      opacity: 0,
      transition: 'opacity 0.1s ease-in-out',
    },
    checkIconSelected: {
      opacity: 1,
    },
    submitButton: {
      marginTop: '5em',
    },
  }),
);

const validationSchema = yup.object({
  tinggi_badan: yup.number()
    .positive('Tinggi badan Anda pasti positif!')
    .min(80, 'Tinggi badan tidak valid')
    .max(280, 'Tinggi badan tidak valid')
    .required('Tinggi badan is required'),
  berat_badan: yup.number()
    .positive('Berat badan Anda pasti positif!')
    .min(40, 'Berat badan tidak valid')
    .max(300, 'Berat badan tidak valid')
    .required('Berat badan is required'),
  tipe_badan: yup.string()
    .min(1)
    .required()
})

interface Props {
  handleBack: () => void
  handleSubmit: (values: any) => void
}

export default function UniversalSizeHeightWeightBodyType({ handleBack, handleSubmit }: Props) {
  const classes = useStyles();

  const bodyTypes = ['triangle', 'rectangle', 'inverted_triangle'];

  const formik = useFormik({
    initialValues: {
      tinggi_badan: 0,
      berat_badan: 0,
      tipe_badan: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleBodyTypeClick = (type: string) => {
    bodyTypes.forEach((otherType) => {
      const otherCheckmark = document.getElementById(`${otherType}-check`);
      if (otherCheckmark) {
        otherCheckmark.classList.remove(classes.checkIconSelected);
      }
    });

    formik.setFieldValue('tipe_badan', type);
    // Reset opacity to trigger the fade-in effect on each click
    document.getElementById(`${type}-check`)?.classList.add(classes.checkIconSelected);
  };

  let children: JSX.Element[] = [];
  children.push(
    <Box className={classes.pageTitleContainer}>
      <Typography className={classes.pageTitle}>
        01.
      </Typography>
      <Typography className={classes.pageTitle}>
        Masukkan Tinggi, Berat Badan, dan Tipe Badan
      </Typography>
    </Box>
  );
  children.push(
    <TextField
      margin="normal"
      fullWidth
      id="tinggi_badan"
      name="tinggi_badan"
      label="Tinggi Badan (cm)"
      type="number"
      value={formik.values.tinggi_badan}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.tinggi_badan && Boolean(formik.errors.tinggi_badan)}
      helperText={(formik.touched.tinggi_badan && formik.errors.tinggi_badan)}
    />
  );
  children.push(
    <TextField
      margin="normal"
      fullWidth
      id="berat_badan"
      name="berat_badan"
      label="Berat Badan (kg)"
      type="number"
      value={formik.values.berat_badan}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.berat_badan && Boolean(formik.errors.berat_badan)}
      helperText={(formik.touched.berat_badan && formik.errors.berat_badan)}
    />
  );
  children.push(
    <Box>
      <Typography className={classes.bodyTypeLabel}>
        Tipe Badan
      </Typography>
    </Box>
  );
  children.push(
    <Box className={classes.bodyTypeImageContainer} key="body_types">
      {bodyTypes.map((type) => (
        <div key={type} className={classes.bodyTypeImage} onClick={() => handleBodyTypeClick(type)}>
          <img
            src={process.env.PUBLIC_URL + `/assets/Body_Types_Grey_State_${type}.svg`}
            alt={`${type} body type`}
            draggable={false} />
          <CheckCircle id={`${type}-check`} className={classes.checkIcon} />
        </div>
      ))}
    </Box>
  );

  let submitChildren: JSX.Element[] = [];

  submitChildren.push(
    <Box className={classes.submitButton}>
      <OverlayComponentButton
        isTypeSubmit={true}
        formButtonText="SELANJUTNYA"
        buttonMarginTopBottom="0px"
        buttonMarginLeftRight="0px"
      />
    </Box>
  )

  return (
    <>
      <Box
      style={{
        top: '24px',
        left: '16px',
        padding: '8px',
        position: 'absolute',
        borderRadius: '50%',
      }}
        onClick={handleBack}
      >
        <IconButton className={classes.backButtonIcon}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Box style={{
        padding: "0px 20px",
        marginTop: '5em'
      }}>
        <OverlayComponentForm
          formTitle=""
          formSubmitBody={submitChildren}
          formFormBody={children}
          formHandleSubmit={formik.handleSubmit}
          titleWidthMdUp='0'
          titleWidthSmDown='0'
          formWidthMdUp='100%'
          formWidthSmDown='100%'
          formJustifyContent='space-evenly'
          formAlignItems='center'
        />
      </Box>
    </>
  )
}

import { CircularProgress, Dialog, Fade, Slide, TextField, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close, Star, StarHalf, StarOutline } from '@material-ui/icons';
import { useFormik } from 'formik';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { closeOverlayAndReset } from '../redux/UIOverlayMeasureInstructionsRedux';
import { ClothingMeasurementDetailList, ClothingMeasurementInstructionsImageUrl, ClothingMeasurementsKey } from 'common-ts/dist/models/UserMeasurements';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  dialogContainer: {
    zIndex: ((theme.zIndex.modal + 2) + " !important") as any,
    '& .MuiPaper-root': { 
      width: '30vw',
      borderRadius: "2vw",
      [theme.breakpoints.down('sm')]: {
        width: '80vw',
        borderRadius: "5.4vw",
      },
    },
    '& .MuiBackdrop-root': { 
      backgroundColor: "rgba(0, 0, 0, 0.7)"
    } 
  },
  dialogContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center', 
    textAlign: 'center',
    position: "relative"
  },
  dialogTitleText: {
    position: "absolute",
    top: "2vw",
    left: "2vw",
    fontSize: "1.3vw",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      top: "5.4vw",
      left: "5.4vw",
      fontSize: '3.4vw',
    },
  },
  dialogDivImageCloseButton: {
    position: "absolute",
    top: "0",
    right: "0",
    margin: "4px",
    color: "#000000",
    zIndex: 2,
  },
  dialogDivImage: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "24vw",
    [theme.breakpoints.down('sm')]: {
      height: "64vw",
    },
  },
  mainImg: {
    width: "27vw",
    paddingTop: "4.5vw",
    paddingBottom: "1.5vw",
    [theme.breakpoints.down('sm')]: {
      width: "72vw",
      paddingTop: "12vw",
      paddingBottom: "4vw",
    },
  },
}),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} timeout={170} />;
});

export default function PageProductOverlayNewReview() {
  const overlayMeasureInstructions = useAppSelector((state) => state.overlayMeasureInstructions);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  
  const imageExists = ClothingMeasurementInstructionsImageUrl[overlayMeasureInstructions.clothingMeasurementKey] !== undefined
  && ClothingMeasurementInstructionsImageUrl[overlayMeasureInstructions.clothingMeasurementKey] !== "";

  return (
    <Dialog
    open={overlayMeasureInstructions.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    onClose={() => { dispatch(closeOverlayAndReset()) }}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <IconButton aria-label="closebutton" className={classes.dialogDivImageCloseButton} 
        onClick={() => {
          dispatch(closeOverlayAndReset());
        } }>
          <Close />
        </IconButton>
        {
          imageExists ? 
          <Typography className={classes.dialogTitleText}>
            {ClothingMeasurementDetailList[overlayMeasureInstructions.clothingMeasurementKey as ClothingMeasurementsKey].clothingMeasurementName}
          </Typography>:
          null
        }
        <div className={classes.dialogDivImage}>
          {
            imageExists ?
            <img 
            src={ ClothingMeasurementInstructionsImageUrl[overlayMeasureInstructions.clothingMeasurementKey] } 
            alt="sizechart-img"
            className={classes.mainImg} />:
            null
          }
        </div>
      </div>
    </Dialog>
  );
}
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetUserMeasurementDraft } from './MeasurementsRedux';

export enum UniversalSizeActiveScreen {
    Benefit,
    CaraBuat,
    HeightWeightBodyType,
    MeasEstimateLoading,
    UkurTiapBagian,
    Result,
}

export interface UniversalSizeFlow {
    status: UIOverlayViewStatus,

    currentScreen: UniversalSizeActiveScreen,
}

export const openOverlayCreateMeasWithInitialization = createAsyncThunk(
  'overlayUniversalSize/openOverlayCreateMeasWithInitialization', 
  async (payload, thunkAPI) => {

  thunkAPI.dispatch(resetUserMeasurementDraft());
  thunkAPI.dispatch(openOverlay());

  return null;
});

export const closeOverlayAndResetWithCleanup = createAsyncThunk(
  'overlayUniversalSize/closeOverlayAndResetWithCleanup', 
  async (payload, thunkAPI) => {

  thunkAPI.dispatch(resetUserMeasurementDraft());
  thunkAPI.dispatch(closeOverlayAndReset());

  return null;
});

// Define the initial state using that type
const initialState: UniversalSizeFlow = {
    status: UIOverlayViewStatus.OffAndReady,

    currentScreen: UniversalSizeActiveScreen.Benefit,
}

export const overlayUniversalSize = createSlice({
    name: 'overlayUniversalSize',
    initialState,
    reducers: {
        closeOverlayAndReset: (state) => {
            state.status = UIOverlayViewStatus.OffAndReady;
            state.currentScreen = UniversalSizeActiveScreen.Benefit;
        },

        openOverlay: (state) => {
            state.status = UIOverlayViewStatus.On;
            state.currentScreen = UniversalSizeActiveScreen.Benefit;
        },

        updateCurrentScreen: (state, action: PayloadAction<UniversalSizeActiveScreen>) => {
            state.currentScreen = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, updateCurrentScreen } = overlayUniversalSize.actions

export default overlayUniversalSize.reducer
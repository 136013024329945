import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import Review from 'common-ts/dist/models/Review';
import axios from 'axios';
import { RootState } from '../store';


interface ProductReviews {
  productId: string | undefined
  fetchProductId: string | undefined
  reviews: Review[]
  averageStars: number | undefined
  postStatus: APIRequestStatus,
  getStatus: APIRequestStatus,
}

// Define the initial state using that type
const initialState: ProductReviews = {
  productId: undefined,
  fetchProductId: undefined,
  reviews: [],
  averageStars: undefined,
  postStatus: APIRequestStatus.Idle,
  getStatus: APIRequestStatus.Idle,
}

export const postNewReview = createAsyncThunk('productReview/postNewReview', async (payload: Review, thunkAPI) => {
  const rootState = (thunkAPI.getState() as RootState);

  var response;

  if (rootState.account.authState === "signedin") {
    const idToken = rootState.account.idToken;
    const data = JSON.stringify(payload);

    response = await axios({
      method: 'post',
      url: 'https://wyo8qs71uc.execute-api.ap-southeast-1.amazonaws.com/ReviewAPIProduction/',
      headers: { 
        'Authorization': 'Bearer ' + idToken, 
      },
      data: data,
    });
  } else {
    const data = JSON.stringify(payload);

    response = await axios({
      method: 'post',
      url: 'https://wyo8qs71uc.execute-api.ap-southeast-1.amazonaws.com/ReviewAPIProduction/notloggedin',
      data: data,
    });
  }

  // Re-fetch reviews
  thunkAPI.dispatch(fetchProductReviews(payload.productId));

  return response.data
})

export const fetchProductReviews = createAsyncThunk('productReview/fetchProductReviews', async (payload: string, thunkAPI) => {
  const response = await axios({
    method: 'get',
    url: 'https://wyo8qs71uc.execute-api.ap-southeast-1.amazonaws.com/ReviewAPIProduction/?productId=' + payload,
  });

  return response.data
})

export const productReviewsSlice = createSlice({
  name: 'productReviews',
  initialState,
  reducers: {
    fetchNewProductId: (state, action: PayloadAction<string>) => {
      state.fetchProductId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(postNewReview.pending, (state, action) => {
        state.postStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(postNewReview.fulfilled, (state, action) => {
        state.postStatus = APIRequestStatus.Success;
      })
      .addCase(postNewReview.rejected, (state, action) => {
        state.postStatus = APIRequestStatus.Failure;
      })
      .addCase(fetchProductReviews.pending, (state, action) => {
        state.getStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductReviews.fulfilled, (state, action) => {

        // Add Payload to our state
        let reviewsArray: Review[];
        try {
          reviewsArray = JSON.parse(action.payload);
        } catch (e) {
          reviewsArray = action.payload;
        }
        state.reviews = [];

        if (reviewsArray.length === 0) {
          state.productId = state.fetchProductId;
          state.getStatus = APIRequestStatus.Success;
          return;
        }

        let totalstars = 0;
        for (let key in reviewsArray) {
          const review: Review = reviewsArray[key];
          if (review.stars < 1) {
            review.stars = 1;
          } else if (review.stars > 5) {
            review.stars = 5;
          }

          state.reviews.push({...review});

          totalstars = totalstars + review.stars;
        }
        state.averageStars = +(totalstars / reviewsArray.length).toFixed(2);
        // We round the average stars

        state.productId = state.fetchProductId;
        state.getStatus = APIRequestStatus.Success;
      })
      .addCase(fetchProductReviews.rejected, (state, action) => {
        state.getStatus = APIRequestStatus.Failure;
        // state.error = action.error.message
        console.log("fetch is failure");
      })
  }
})

// Action creators are generated for each case reducer function
export const { fetchNewProductId } = productReviewsSlice.actions

export default productReviewsSlice.reducer
import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import { useAppDispatch } from '../reduxhooks';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentButton, { FareastButton, FareastButtonGrey } from './OverlayComponentButton';
import OverlayComponentChoice from './OverlayComponentChoice';
import OverlayComponentForm from './OverlayComponentForm';
import OverlayComponentBaseTemplate from './OverlayComponentBaseTemplate';
import OverlayComponentTitle from './OverlayComponentTitle';
import OverlayComponentCanvas from './OverlayComponentCanvasFitAnimation';
import { BodyMeasurementsForSize, CalculateClothingMeasurementsFromBodyMeasurements, ClothingMeasurements, ClothingType, FitType } from 'common-ts/dist/models/UserMeasurements';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '300px',
      [theme.breakpoints.only('xs')]: {
        width: '240px',
      }
    },
    form: {
      height: 'auto',
      padding: '0px 24px 0px 24px',
      [theme.breakpoints.only('xs')]: {
        padding: '0px 0px 0px 0px',
      }
    },
    formRow: {
      paddingBottom: '30px',
    },
    subFormTitle: {
    },
    toggleSignUpSignInContainer: {
      paddingTop: '30px',
      paddingBottom: '30px',
      // paddingLeft: '10px',
      paddingRight: '10px',
    },
    buttonContainer: {
      width: '80%',
      paddingBottom: '12px',
      [theme.breakpoints.only('xs')]: {
        paddingBottom: '6px',
      }
    },
    canvasContainer: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.only('xs')]: {
        height: '75vw',
      }
    }
  }),
);

function enumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e as any) as (keyof E)[];
}

export interface Props {
  clothingType: ClothingType
  fitType: FitType
  key2: string
  handleSubmit: (value: RegularSizeValue) => void
}

export default function RegularSelectSize({clothingType, fitType, key2, handleSubmit}: Props) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [selected, setSelected] = React.useState<RegularSizeValue | undefined>(undefined);
  const [goalFit, setGoalFit] = React.useState<ClothingMeasurements>({
    lingkarLeherBaju: 40,
    lebarBahuBaju: 48,
    lebarDadaBaju: 52,
    lebarPerutBaju: 52,
    panjangBaju: 70,
    lebarLenganBaju: 20,
    panjangLenganPendek: 24,
    panjangLenganPanjang: 58,
  })

  useEffect(() => {
    setGoalFit(CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, 
      BodyMeasurementsForSize[RegularSizeValue.NOTSELECTED], RegularSizeValue.L));
  }, [clothingType]);

  let children: JSX.Element[] = [];
  let submitChildren: JSX.Element[] = [];

  children.push(
    <Box>
      <Typography style={{
        fontWeight: 300,
        fontSize: isMdOrUp ? '10.8px' : '2.7vw',
        letterSpacing: isMdOrUp ? '1px' : '0.25vw',
        marginBottom: '6px',
      }}>
        SEBELUM KITA MULAI
      </Typography>
      <Typography style={{
        fontWeight: 500,
        fontSize: isMdOrUp ? '21px' : '5.25vw',
        letterSpacing: isMdOrUp ? '1.1px' : '0.275vw',
        marginBottom: '6px',
      }}>
        Ukuran Kamu Biasanya Apa?
      </Typography>
      <Typography style={{
        fontWeight: 300,
        fontSize: isMdOrUp ? '10px' : '2.5vw',
        letterSpacing: isMdOrUp ? '0.4px' : '0.1vw',
        marginBottom: isMdOrUp ? '30px' : '1px',
        color: "rgb(154 154 154)",
      }}>
        Informasi ini memudahkan tailor saat membantu kamu. 
      </Typography>
    </Box>
  )

  children.push(
    <OverlayComponentBaseContainerHorizontal>
      <OverlayComponentBaseContainerWidth
        widthMdUp="100%"
        widthSmDown="100%">
        <Grid container>
          {
            !isMdOrUp ?
            <Grid item xs={12}>
              <Box className={classes.canvasContainer}>
                <OverlayComponentCanvas
                clothingType={clothingType}
                fitType={fitType}
                putAtTop={false}
                scaleRatio={0.5}
                incrementTogether={true}
                goalFit={goalFit}/>
              </Box>
            </Grid> : 
            null
          }
          <Grid item xs={12} sm={6}>
            <Grid container>
              {
                enumKeys(RegularSizeValue).map((item, i) => {
                  if (item === "NOTSELECTED" || item === "XXXL" || item === "XXXXL" || item === "XXXXXL") {
                    return;
                  }
                  return (
                    <Grid item xs={4} sm={4}>
                      <Box className={classes.buttonContainer}>
                        {
                          selected === RegularSizeValue[item] ?
                          <FareastButton fullWidth
                          buttonMarginTopBottom="10px"
                          buttonMarginLeftRight="0px"
                          >
                            {
                              item
                            }
                          </FareastButton> :
                          <FareastButtonGrey fullWidth onClick={() => {
                            setSelected(RegularSizeValue[item]);
                            setGoalFit(CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, 
                              BodyMeasurementsForSize[RegularSizeValue[item]], RegularSizeValue.L));
                          }}
                          buttonMarginTopBottom="10px"
                          buttonMarginLeftRight="0px">
                            {
                              item
                            }
                          </FareastButtonGrey>
                        }
                      </Box>
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
          {
            isMdOrUp ?
            <Grid item xs={6}>
              <Box className={classes.canvasContainer}>
                <OverlayComponentCanvas
                clothingType={clothingType}
                fitType={fitType}
                putAtTop={true}
                scaleRatio={0.5}
                incrementTogether={true}
                goalFit={goalFit}/>
              </Box>
            </Grid> : 
            null
          }
        </Grid>
      </OverlayComponentBaseContainerWidth>
    </OverlayComponentBaseContainerHorizontal>
  );
  submitChildren.push(
    <OverlayComponentBaseContainerHorizontal
    alignItems='flex-start' >
      <Box>
        {
          selected !== undefined ? 
          <OverlayComponentButton
            formButtonText="PILIH SIZE"
            isTypeSubmit={false}
            handleClick={() => {
              if (selected !== undefined) {
                handleSubmit(selected);
              }
            }}
            buttonMarginTopBottom="10px"
            buttonMarginLeftRight="0px"/> :
          null
        }
      </Box>
    </OverlayComponentBaseContainerHorizontal>
  )

  return (
    <OverlayComponentBaseTemplate alignItems="flex-start">
      <OverlayComponentTitle text="" />
      <Box>
        {
          children
        }
      </Box>
      <Box>
        {
          submitChildren
        }
      </Box>
    </OverlayComponentBaseTemplate>
  );
}
import { Breadcrumbs, Button, ButtonBase, Dialog, DialogContent, DialogTitle, Fade, Grow, Link, Menu, MenuItem, MenuList, Paper, Popper, TextField, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ArrowBack, FiberManualRecordRounded, Message, Star, StarHalf, StarOutline } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactImageMagnify from 'react-image-magnify';
import { Carousel, CarouselProps } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Children } from 'react';
import {
  Link as RouterLink, useParams
} from "react-router-dom";
import { Mixpanel } from '../mixpanel';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { CartStateItem, RegularSizeValue, SizeType } from 'common-ts/dist/models/Cart';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import { ClothingType, FitType, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { addItemCount, canAddProductAmountToCart, getAddToCartNextSteps, getDiscountedPriceInProductSpotlightList, getTimestampInProductSpotlightList, postCanAddItemCount } from '../redux/CartRedux';
import { getCurrentSelectedMeasurement, inputMeasurementsForm, postClothingMeasurementPreference, resetLastCreatedMeasurement, resetUserMeasurementDraft, saveLocallyClothingMeasurementPreference, setKey2PreferencesMeasurement } from '../redux/MeasurementsRedux';
import { fetchNewProductId, fetchProductReviews } from '../redux/ProductReviewsRedux';
import { openOverlay, updateSignUpOrSignIn } from '../redux/UIOverlayAccountViewRedux';
import { openOverlayCreateMeasWithInitialization } from '../redux/UIOverlayMeasurementMethodSelectionViewRedux';
import { openOverlay as openOverlaySizeChart } from '../redux/UIOverlaySizeChartRedux';
import { updateCurrentScreen } from '../redux/UIOverlayNavbarRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FareastButton, FareastButtonWhite } from './OverlayComponentButton';
import { SignUpSignInLink } from './OverlayViewAccount';
import OptionsSlider from './PageComponentOptionsSlider';
import OptionsItemContainer from './PageComponentOptionsItemContainer';
import PageProductOverlayImageCarousel from './PageProductOverlayImageCarousel';
import PageProductOverlayNewReview from './PageProductOverlayNewReview';
import PageProductOverlaySizeChart from './PageProductOverlaySizeChart';
import { PageProductSuggestions } from './PageProductSuggestions';
import axios from 'axios';
import { renderBlockElement, RenderBlockElementProps } from './PageComponentElementBlocks';
import hash from 'object-hash';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { updateSizeChartClothFitType } from '../redux/UIOverlaySizeChartRedux';
import { getHash } from './PageOrder';
import ProductOnUser, { multiDescriptionDataExample, ProductCategory, ProductCategoryDisplayName } from 'common-ts/dist/models/Product';
import SizeSelection from './PageProductDetailSizeSelection';
import { Stack } from '@mui/material';
import StarRating from './PageComponentReviewStarRating';
import ReviewSlider from './PageComponentReviewSlider';
import ReviewSliderImage from './PageComponentReviewSliderImage';
import ReviewDialog from './PageComponentReviewDialog';
import ReviewDialogImage from './PageComponentReviewDialogImage';
import CloseIcon from '@material-ui/icons/Close';
import Countdown from 'react-countdown';
import { allowedReviewCategories, CategoryReview } from 'common-ts/dist/models/CategoryReview';
import { fetchCategoryReviewList } from '../redux/CategoryReviewRedux';
import { useInView } from 'react-intersection-observer';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { VideoComponent } from './PageComponentElementCarousel';
import { postNewProductAnalyticsLog } from '../redux/ProductAnalyticsRedux';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  productDetailRoot: {
  },
  productDetailNav: {
    paddingLeft: '20px',
    paddingRight: '30px',
    paddingTop: '0px',
    marginTop: '0px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '10px',
      marginTop: '0px',
    },
  },
  productDetailMain: { // ALLBIRDS is 18px left and right, 24px to leftmost image
    // paddingLeft: '24px',
    // paddingRight: '30px',
  },
  otherProductRecs: {
    display: 'block',
  },
  sectionDesktopAndMobile: {
    display: 'flex',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cartContentImageContainer: { // ALLBIRDS is 6px
    width: 'auto',
    padding: '0px 20px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 0px 0px',
    }
  },
  cartContentImage: {
    cursor: 'pointer',
  },
  stickyDetailContainer: {
    position: 'sticky',
    top: '40px',
  },
  productNameList: {
    marginBottom: '6px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '6px',
    },
  },
  productPrice: {
    marginBottom: '24px',
  },
  productLeftInStock: {
    marginBottom: '16px',
  },
  productDescription: {
    marginBottom: '28px',
    marginTop: "20px",
  },
  ukuranRegulerBox: {
    marginBottom: '28px',
    marginTop: "20px",
    position: "relative",
  },
  ukuranRegulerBoxSizeChartLink: {
    position: "absolute",
    right: "0",
    top: "2px",
    fontSize: "11px",
    color: "#5e5e5e",
    textDecoration: "underline #5e5e5e solid 1px",
    cursor: "pointer",
    '-webkit-tap-highlight-color': 'transparent',
  },
  ukuranRegulerSizeTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1vw",
    paddingLeft: "0.4vw",
    paddingRight: "0.4vw",
    [theme.breakpoints.down('xs')]: {
      marginTop: "4vw",
    }
  },
  ukuranRegulerSizeTextAda: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#232323",
    [theme.breakpoints.down('xs')]: {
      fontSize: "15px",
    }
  },
  ukuranRegulerSizeTextHabis: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#7e7e7e",
    textDecoration: "line-through #7e7e7e solid 1px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "15px",
    }
  },
  addToCartButton: {
    marginTop: '40px',
    marginBottom: '24px',
    justifyContent: 'center',
  },
  addToCartButtonText: {
    fontSize: '0.75rem', // 16px is 1 rem. 0.8 rem is 12.8px
    fontWeight: 600,
    letterSpacing: "0.03rem",
  },
  accordionStyle: {
    margin: '4px 0px !important',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  panelSummary: {
    "&.Mui-expanded": {
      minHeight: 0
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "auto"
    }
  },
  panelSummaryText: {
    "&:hover": {
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: '15px',
      textDecoration: 'underline',
    },
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '15px',
  },
  panelContentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '15px',
    paddingLeft: '10px',
  },
  productNameListText: {
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
  },
  priceBox: {
    display: 'flex',
    alignItems: 'center',
  },
  productPriceText: {
    fontWeight: 'normal',
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  productPriceTextDiscount: {
    fontWeight: 'normal',
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  productPriceTextDiscountStrikeThrough: {
    fontWeight: 'normal',
    color: 'red',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '10px',
    marginTop: '2px',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    textDecoration: 'line-through',
  },
  productLeftInStockText: {
    fontWeight: 600,
    color: '#2a2a2a',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  productDescriptionText: {
    fontWeight: 'normal',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    marginBottom: '20px',
  },
  pilihUkuranSubtext: {
    fontWeight: 'normal',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '1px',
    marginTop: '2px',
    marginBottom: '8px',
  },
  productSubtitle: {
    fontWeight: 'bold',
    color: '#121212',
    fontSize: '14px',
    marginBottom: '6px',
  },
  talentInfoText: {
    color: '#343434',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Open sans, sans-serif',
    marginTop: "14px",
  },
  restockInfoText: {
    color: '#343434',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Open sans, sans-serif',
    marginTop: "10px",
  },
  restockInfoTextHighlight: {
    color: '#1f1f1f',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: 'Open sans, sans-serif',
    marginTop: "10px",
  },
  restockInfoTextIcon: {
    fontSize: '13px',
    marginRight: "9px",
  },
  chooseSizeButton: {
    width: "100%",
    paddingLeft: '20px',
    paddingRight: '24px',
    paddingTop: '9px',
    paddingBottom: '9px',
    backgroundColor: '#f1f1f1',
    marginTop: '6px',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#e9e9e9',
      borderColor: '#0062cc',
    },
    display: "flex",
    justifyContent: "space-between",
  },
  gantiUkuranText: {
    fontSize: "10px",
    textWrap: "nowrap",
    fontWeight: 500,
    textDecoration: "underline #121212 solid 1px",
    textUnderlineOffset: "3px",
    paddingLeft: "6px",
  },
  measurementMenuItemBody: {
    // display: 'flex',
    // justifyContent: 'space-between',
  },
  measurementMenuItem: {
    // display: 'flex',
    // justifyContent: 'space-between',
  },
  sizeText: {
    color: '#3b3b3b',
    fontSize: '12px',
    textAlign: "left",
  },
  sizeTextNewSize: {
    color: '#3b3b3b',
    fontSize: '15px',
    marginLeft: '10px',
    marginTop: '1px',
  },
  mobileViewImageCarouselContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileViewImageCarousel: {
    // float: 'left',
  },
  cartCarouselImageContainer: {
    padding: '0px',
  },
  cartCarouselVideoContainer: {
    position: "relative",
  },
  cartCarouselVideo: {
    width: '100%',
    paddingBottom: '36px',
  },
  cartCarouselVideoSoundButton: {
    position: "absolute",
    bottom: "52px",
    right: "16px",
    color: "#ffffff",
    backgroundColor: "#232323",
    padding: "6px 6px 4px 6px",
    borderRadius: "18px",
  },
  cartCarouselImage: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '2/3',
    marginBottom: '36px',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '2/3',
  },
  cartCarouselContainer: {
    width: '100%',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    marginBottom: '16px',
    position: "relative",
  },
  sisaBox: {
    backgroundColor: "#ff1e1e",
    textAlign: "center",
    position: "absolute",
    bottom: "1vw",
    left: "1vw",
    width: "6vw",
    borderRadius: "1.3vw",
    [theme.breakpoints.down('xs')]: {
      bottom: "15.4vw",
      left: "3.4vw",
      width: "20vw",
      borderRadius: "8vw",
    },
  },
  sisaText: {
    fontSize: "1.3vw",
    paddingTop: "0.3vw",
    color: "#ffffff",
    [theme.breakpoints.down('xs')]: {
      fontSize: "4vw",
      paddingTop: "1.2vw",
    },
  },
  sisaBoxInDetail: {
    backgroundColor: "#ff1e1e",
    textAlign: "center",
    width: "6vw",
    borderRadius: "1.3vw",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  sisaTextInDetail: {
    fontSize: "1.3vw",
    paddingTop: "0.3vw",
    color: "#ffffff",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  indicatorItemSelected: {
    display: 'inline-block',
    background: '#000',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  indicatorItem: {
    display: 'inline-block',
    background: '#999',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  limitedStockBubbleBox: {
    width: "fit-content",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "3px",
    borderRadius: "12px",
    border: "1px solid black",
    marginBottom: "28px"
  },
  limitedStockBubbleText: {
    fontFamily: "OakesGrotesk",
    fontWeight: 500,
    fontSize: "11px",
    letterSpacing: "0.5px",
  },
  starsElementContainer: {
    display: 'flex',
    marginBottom: '6px',
  },
  starsDescriptionText: {
    fontWeight: 'normal',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    color: '#343434',
    fontFamily: 'Open sans, sans-serif',
    marginLeft: '10px',
    marginTop: '4px',
  },
  productRating: {
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '14px',
    },
  },
  reviewsSegment: {
    margin: '0px 0px 30px 0px',
  },
  reviewsSegmentTitle: {
    margin: '40px 20px 20px 20px',
    fontWeight: theme.typography.fontWeightBold as number,
    [theme.breakpoints.up('md')]: {
      margin: '0px 40px 10px 50px',
    }
  },
  reviewsSegmentBody: {
    marginTop: '18px',
    marginBottom: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    [theme.breakpoints.up('md')]: {
      marginTop: '18px',
      marginBottom: '20px',
      marginLeft: '60px',
      marginRight: '60px',
    },
  }, 
  reviewItem: {
    margin: '0px 0px 16px 0px',
    padding: '8px 2px 24px 2px',
    width: 'auto',
    borderBottom: "1px solid #dedede",

    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px 22px 0px',
      padding: '6px 4px 28px 4px',
    },
  },
  reviewItemNew: {
    margin: '16px 10px',
    padding: '20px 20px',
    width: 'auto',
    cursor: 'pointer',
  },
  reviewDisplayPicture: {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    padding: '10px',
    margin: '8px 18px 0px 6px',

    backgroundColor: '#d5d5d5',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  reviewDisplayText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '20px',
    color: '#fff',
    marginTop: '0px',
    width: '30px' // Set to equal to display picture size
  },
  reviewItemBoxDescription: {

  },
  reviewItemBox1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "6px",
    },
  },
  reviewItemIsVerifiedText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '11px',
    color: '#7A7A7A',
    paddingLeft: '1px',
    marginLeft: '10px', 
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  reviewItemName: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '15px',
    color: "#121212",
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  reviewItemBox2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "5px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "6px",
    },
    // [theme.breakpoints.down('sm')]: {
    //   flexDirection: 'column',
    // },
  },
  reviewItemDateAndPlaceText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '11px',
    color: "#6c6c6c",
    marginBottom: '12px', 
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  reviewItemHeadline: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '15px',
    paddingLeft: '2px',
    paddingTop: '1px', 
    marginLeft: '6px', 
    marginBottom: '6px',
    color: "#121212",
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      paddingTop: '1px', 
      marginLeft: '6px', 
    },
  },
  reviewItemComment: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: '15px',
    color: "#121212",
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  newReviewIcon: {
    marginLeft: '10px',
    marginBottom: '-4px'
  },
  starFilled: {
    color: '#FFA41C',
    marginLeft: '-3px',
    marginRight: '3px',
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      marginLeft: '-3px',
      marginRight: '3px',
    },
  },
  starEmpty: {
    color: '#d8d8d8',
    marginLeft: '-3px',
    marginRight: '3px',
    [theme.breakpoints.down('sm')]: {
      width: '24px',
    },
  },
  roundedButtonSelected: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '400px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '2px',
    paddingBottom: '2px',
    margin: '4px 8px 12px 0px',
    fontWeight: 600,
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
    border: '1px solid black',
  },
  roundedButton: {
    // color: 'white',
    borderRadius: '400px',
    color: '#727272',
    borderColor: '#727272',
    borderWidth: '1px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '2px',
    paddingBottom: '2px',
    margin: '4px 8px 12px 0px',
    fontWeight: 600,
    fontSize: '12px',
  },
  imageBoxWidth: {
    width: '30vw',
  },
  sectionPhoto: {
    margin: '0',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    flexGrow: 0,
    maxWidth: '70%',
    flexBasis: '70%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  sectionDescription: {
    margin: '0',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    flexGrow: 0,
    maxWidth: '30%',
    flexBasis: '30%',
    paddingLeft: '26px',
    paddingRight: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  svgContentFullWidthDesktop: {
    marginTop: "3.3vw",
    marginBottom: "1.2vw",
    width: '100%',
  },
  svgContentFullWidthMobile: {
    width: '100%',
    marginTop: "16px",
  },

  // Style Untuk Review Haidar
  categoryReviewContainer: {
    marginBottom: '28px',
    marginTop: "42px",
    boxSizing: 'border-box',
    flex: '0 0 auto',
    flexGrow: 0,
    width: '100%',
    flexBasis: '30%',
  },
  categoryReviewContent: {
    marginBottom: '28px',
    paddingLeft: '26px',
    paddingRight: '30px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  categoryReviewButtonContainer: {
    marginBottom: '28px',
    paddingLeft: '26px',
    paddingRight: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  categoryReviewContentTitle: {
    fontWeight: 'bold',
    color: '#121212',
    fontSize: '14px',
    marginBottom: '10px',
  },
  categoryReviewContentRating:{
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center'
  },
  categoryReviewButtonSeeAll: {
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    marginTop: 20,
    backgroundColor: '#F7F7F7',
    borderRadius: 40,
    borderWidth: '2px',
    border: 'solid #D9D9D9',
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    padding: '8px'
  },
  categoryReviewButtonSeeAllText: {
    fontWeight: 'bold',
    color: '#121212',
    fontSize: '12px',
  },
}),
);

interface StarsElementProps {
  stars: number
  withText?: boolean
  // colorStarFilled: string
  // colorStarEmpty: string
}

// Video Dari Page Component Element Carousel
export function determineMediaType(filename: string): string {
  const videoExtensions = ['.webm', '.mp4'];
  const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.tiff', '.svg'];

  const lowerCaseFilename = filename.toLowerCase();

  for (const extension of videoExtensions) {
    if (lowerCaseFilename.endsWith(extension)) {
      return 'video';
    }
  }

  for (const extension of imageExtensions) {
    if (lowerCaseFilename.endsWith(extension)) {
      return 'image';
    }
  }

  return 'unknown';
}

export function StarsElementRate({stars, withText,}: StarsElementProps) {
  const classes = useStyles();

  return (
    <Box className={classes.starsElementContainer}>
      {
        stars >= 1 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 0.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 2 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 1.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 3 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 2.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 4 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 3.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 5 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 4.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        withText === false ?
        null : 
        <Typography className={classes.starsDescriptionText}>
          {stars + "/5 stars"}
        </Typography>
      }
    </Box>
  )
}

export function StarsElement({stars, withText,}: StarsElementProps) {
  const classes = useStyles();

  return (
    <Box className={classes.starsElementContainer}>
      {
        stars >= 1 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 0.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 2 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 1.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 3 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 2.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 4 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 3.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        stars >= 5 ? 
        <Star className={classes.starFilled}/> : 
        stars >= 4.5 ?
        <StarHalf className={classes.starFilled}/> :
        <StarOutline className={classes.starEmpty}/>
      }
      {
        withText === false ?
        null : 
        <Typography className={classes.starsDescriptionText}>
          {stars + " / 5 stars"}
        </Typography>
      }
    </Box>
  )
}

interface ReviewElementProps {
  name: string
  isVerified: boolean
  stars: number
  creationTimestamp: string
  headline: string
  message: string
}

export function ReviewElement({name, isVerified, stars, creationTimestamp, headline, message}: ReviewElementProps) {
  const classes = useStyles();

  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var date = new Date(Number(creationTimestamp) * 1000);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

  return (
    <Box className={classes.reviewItem}>
      <Box className={classes.reviewDisplayPicture}>
        <Typography className={classes.reviewDisplayText}>
          {name.substring(0,1)}
        </Typography>
      </Box>
      <Box className={classes.reviewItemBoxDescription}>
        <Box className={classes.reviewItemBox1}>
          <Typography className={classes.reviewItemName}>
            {name}
          </Typography>
          {
            isVerified ? 
            <Typography className={classes.reviewItemIsVerifiedText}>
              <li>Verified Purchase</li>
            </Typography> : 
            null
          }
        </Box>
        <Box className={classes.reviewItemBox2}>
          <StarsElement stars={stars} withText={false}/>
          {/* <Typography className={classes.reviewItemHeadline}>
            {headline}
          </Typography> */}
        </Box>
        <Typography className={classes.reviewItemDateAndPlaceText}>
          Reviewed on {date.toISOString().substring(0, 10)} {formattedTime}
        </Typography>
        <Typography className={classes.reviewItemComment}>
          {message}
        </Typography>
      </Box>
    </Box>
  )

}

const countdownRenderer = ({ hours, minutes, seconds, completed }: any) => {
  // const classes = useStyles();

  if (completed) {
    return (
    <Box >
    </Box>);
  } else {
    return (
    <Box style={{
      margin: '-10px 0px 5px 0px',
      padding: '4px 14px 1px 14px',
      borderRadius: '20px',
      color: '#ffffff',
      backgroundColor: '#121212',
      maxWidth: '10vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Typography style={{
        fontSize: '12px',
        fontFamily: 'OakesGrotesk',
        fontWeight: 400,
      }}>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</Typography>
    </Box>)
  }
};

function ReviewComponent({ratingNumber, ratingValue, categoryName}: {ratingNumber: number | undefined, ratingValue: number | undefined, categoryName: string}) {
  const classes = useStyles();

  return (
    <Stack 
      direction='row' 
      className={classes.categoryReviewContentRating}
      style={{
        marginBottom: '3px',
      }}
    >
      <StarRating productRating={ratingValue ? ratingValue : 5} gap={'0.5vw'} mt={0.4} />
      <Typography variant="body1">
        <Typography variant="body1" style={{fontWeight: 600, color: '#232323', fontSize: '14px'}} component='span'>
          {ratingValue} / 
        </Typography>
        <Typography variant="body1" style={{fontWeight: '300', fontSize: '12px'}} component='span'>
          {' 5.0'}
        </Typography>
      </Typography>
      <Box style={{paddingTop: '4px'}}>
        <Typography variant="body2" style={{fontWeight: '400', fontSize: '10px', color: '#9B9B9B'}} >
          {ratingNumber} REVIEWS <br/>({categoryName})
        </Typography>
      </Box>
    </Stack>
  )
}

export default function PageProductDetail() {
  let { productId } = useParams<{ productId: string }>();
  productId = decodeURIComponent(productId); // We decode product URI
  const productDict = useAppSelector(state => state.productsList.productsDictionary);
  const productDetail = productDict[productId];

  const userPrefDict = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsPreferenceDictionary);
  const userBodyMeasurementsDictionary = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const userClothingMeasurementsDictionary = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsDictionary);

  const lastCreatedMeasurement = useAppSelector(state => state.userMeasurementsList.lastCreatedMeasurement);
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  const numberOfItemsRemaining = productSizeAvailabilityDict?.[productId]?.numberOfItemsRemaining;
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const cart = useAppSelector(state => state.cart);

  const postMeasurementStatus = useAppSelector(state => state.userMeasurementsList.postMeasurementStatus);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const isSoldOut = productDetail?.isSoldOut || numberOfItemsRemaining === 0;

  const authState = useAppSelector(state => state.account.authState);

  const productReviewsState = useAppSelector(state => state.productReviews);

  const [selectedReviewTab, setSelectedReviewTab] = React.useState<number>(0);

  const [imageOverlayIndex, setImageOverlayIndex] = React.useState<number>(0);
  const [imageOverlayOpen, setImageOverlayOpen] = React.useState<boolean>(false);

  const [newReviewOverlayOpen, setNewReviewOverlayOpen] = React.useState<boolean>(false);

  // State Review Haidar
  const productCategoryReviewState = useAppSelector(state => state.categoryReviewList)
  const productCategoryReviewRecap = useAppSelector(state => state.categoryReviewList.categoryReviewRecap);
  const productCategoryReviewDict = useAppSelector(state => state.categoryReviewList.categoryReviewListDict);
  const [openDialogReviewImage, setDialogOpenReviewImagDialog] = React.useState(false);
  const [openDialogSeeAllReview, setOpenDialogSeeAllReview] = React.useState(false);
  const [selectedReviewIndex, setSelectedReviewIndex] = React.useState<number>(0);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);

  const [lastRenderTime, setLastRenderTime] = React.useState<string>('');

  const productCategoryForReview = productDetail && allowedReviewCategories[productDetail.category] ? productDetail.category : 'all';
  const productCategoryForReviewName = productCategoryForReview === 'all' ? 
  'SEMUA PRODUK' : 
  ProductCategoryDisplayName[productCategoryForReview].toUpperCase();

  const productCategoryReviewList = productCategoryReviewDict[productCategoryForReview]?.categoryReviewList ?? [];
  const sortedProductCategoryReviewList = sortProductCategoryReviewList(productCategoryReviewList ? [...productCategoryReviewList] : [])
  const fullReviewList = sortedProductCategoryReviewList.length > 0 ? sortedProductCategoryReviewList : productCategoryReviewList;
  const shortenedReviewList = fullReviewList.slice(0, 50); // Set a max of 50 reviews to avoid too heavy rendering

  useEffect(() => {
    if (productDetail) {
      setImageOverlayIndex(0);
      if ((window as any).fbq !== null) { 
        (window as any).fbq('track', 'ViewContent', {
          content_ids: [productDetail.productId],
          content_type: 'product',
        });
      }

      if (
        productCategoryReviewState.getStatus !== APIRequestStatus.RequestInProgress
      ) {
        dispatch(fetchCategoryReviewList(productCategoryForReview));
      }
    }
  }, [productDetail]);

  // Analytics
  useEffect(() => {
    dispatch(postNewProductAnalyticsLog({event: 'VISIT', productId: productId, isMobileView: !isDesktop})) // We'll just default to shop
  }, [productId])

  // If product detail is still loading, we return null
  if (productDetail === undefined) {
    return null;
  }

  let spotlightDiscPrice = getDiscountedPriceInProductSpotlightList(cart.productSpotlightList, productId);
  let discountedPrice = spotlightDiscPrice !== -1 ? spotlightDiscPrice : productDetail.discountedPrice;
 
  let productSpotlightTime = getTimestampInProductSpotlightList(cart.productSpotlightList, productId);

  // We set fitType to the default fit type
  const clothingType = productDetail.clothingType;
  const fitType: FitType = productDetail.defaultFitType ? productDetail.defaultFitType : productDetail.fitTypes[0];
  const key2 = clothingType + "-" + fitType;

  const {
    currentSelectedMeasurement,
    currentSelectedMeasurementId,
    currentSelectedMeasurementIsBody,
    key2DefaultSelectedMeasurementCanBeSet,
    key2DefaultSelectedMeasurementCanBeSetRecommended
  } = getCurrentSelectedMeasurement(key2, userPrefDict, userBodyMeasurementsDictionary, userClothingMeasurementsDictionary);

  // AUTO MEASUREMENT ASSIGNMENT IF CONDITIONS ARE MET
  if (key2DefaultSelectedMeasurementCanBeSet && key2DefaultSelectedMeasurementCanBeSetRecommended) {
    dispatch(setKey2PreferencesMeasurement(key2));
  }

  var reviewProductId = productDetail.parentProductId ? productDetail.parentProductId : productId;
  if (productReviewsState.productId + "" !== reviewProductId + "" && productReviewsState.getStatus !== APIRequestStatus.RequestInProgress) {
    console.log("Fetch reviews for product " + productId + " which has parent " + reviewProductId);
    dispatch(fetchNewProductId(reviewProductId));
    dispatch(fetchProductReviews(reviewProductId));
  }

  const productImages = productDetail.productImages;

  var cartStateItem: CartStateItem = {
    productId: productId,
    amount: 1,
    clothingType: productDetail.clothingType,
    fitType: fitType,
    sizeType: SizeType.personal, // "personal" or "regular"
    measurementCreationDateTime: "NOTSELECTED",
    measurementIsBodyMeasurement: false,
    regularSize: RegularSizeValue.NOTSELECTED,
  };

  var hashCartStateItem = '';

  if (currentSelectedMeasurement) {
    if (currentSelectedMeasurement.sizeType === 'personal') {
      cartStateItem.sizeType = SizeType.personal;
      cartStateItem.measurementCreationDateTime = currentSelectedMeasurement.creationDateTime as string;
      cartStateItem.measurementIsBodyMeasurement = currentSelectedMeasurementIsBody;
      cartStateItem.regularSize = currentSelectedMeasurement.regularSize as RegularSizeValue ? currentSelectedMeasurement.regularSize as RegularSizeValue : RegularSizeValue.NOTSELECTED;
    } else if (currentSelectedMeasurement.sizeType === 'regular') {
      cartStateItem.sizeType = SizeType.regular;
      cartStateItem.measurementCreationDateTime = "NOTSELECTED";
      cartStateItem.measurementIsBodyMeasurement = currentSelectedMeasurementIsBody;
      cartStateItem.regularSize = currentSelectedMeasurement.regularSize as RegularSizeValue;
    }

    hashCartStateItem = hash(cartStateItem);

    if (cart.canAddItemDict[hashCartStateItem] === undefined) {
      dispatch(postCanAddItemCount(cartStateItem));
    }
  }

  // Change the button text according to the state of the product
  var productPurchaseStage;
  if (currentSelectedMeasurement === undefined) {
    productPurchaseStage = "PILIH_UKURAN";
  } else if (hashCartStateItem === undefined) {
    productPurchaseStage = "UNDEFINED_ITEM";
  } else if (cart.canAddItemDict[hashCartStateItem] === "LOADING") {
    productPurchaseStage = "CHECKING_STOCK";
  } else if ((cart.canAddItemDict[hashCartStateItem] as any)?.canBeProvisioned) {
    productPurchaseStage = "ADD_TO_CART";
    if (lastCreatedMeasurement && currentSelectedMeasurementId === lastCreatedMeasurement) { // Auto add to cart, to be done once per creation.
      if (cartStateItem.sizeType === 'personal') {
        dispatch(addItemCount({ 
          productId: productId, 
          productDetail: productDetail,
          fitType: fitType,
          sizeType: cartStateItem.sizeType,
          measurementCreationDateTime: currentSelectedMeasurement.creationDateTime as string,
          measurementIsBodyMeasurement: currentSelectedMeasurementIsBody,
          regularSize: cartStateItem.regularSize ? cartStateItem.regularSize : RegularSizeValue.NOTSELECTED,
        }));
        dispatch(updateCurrentScreen("CART"));
      } else if ((cartStateItem.sizeType === 'regular')) {
        dispatch(addItemCount({ 
          productId: productId, 
          productDetail: productDetail,
          fitType: fitType,
          sizeType: cartStateItem.sizeType,
          measurementCreationDateTime: 'NOTSELECTED',
          measurementIsBodyMeasurement: currentSelectedMeasurementIsBody,
          regularSize: cartStateItem.regularSize,
        }));
        dispatch(updateCurrentScreen("CART"));
      }
      dispatch(resetLastCreatedMeasurement());
    }
  } else if ((cart.canAddItemDict[hashCartStateItem] as any)?.canBeProvisioned === false){
    productPurchaseStage = "OUT_OF_STOCK";
    if (lastCreatedMeasurement && currentSelectedMeasurementId === lastCreatedMeasurement) { // Auto add to cart, to be done once per creation.
      dispatch(resetLastCreatedMeasurement());
    }
  }

  // Fungsi Review Haidar
  const handleOpenDialogSeeAllReview = () => {
    setOpenDialogSeeAllReview(true);
  };
  const handleCloseDialogSeeAllReview = () => {
    setOpenDialogSeeAllReview(false);
  };

  const handleOpenDialogReviewImage = (reviewIndex: number, imageIndex: number) => {
    setSelectedReviewIndex(reviewIndex);
    setSelectedImageIndex(imageIndex);
    setDialogOpenReviewImagDialog(true);
  };

  const handleCloseDialogReviewImage = () => {
    setDialogOpenReviewImagDialog(false);
  };

  const handleOpenDialogReviewImageFromReviewDialog = (reviewIndex: number, imageIndex: number) => {
    handleOpenDialogReviewImage(reviewIndex, imageIndex)
    handleCloseDialogSeeAllReview()
  }

  return (
    <Box className={classes.productDetailRoot}>
      <Box className={classes.cartCarouselContainer}>
        <Carousel
          selectedItem={imageOverlayIndex}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={12}
          showThumbs={false} 
          showStatus={false}
          showArrows={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.indicatorItemSelected}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                >

                </li>
              );
            }
            return (
              <li
                className={classes.indicatorItem}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              >
              </li>
            );
          }}
          onChange={ (index, item) => {
            if (imageOverlayIndex !== index) {
              setImageOverlayIndex(index);
              Mixpanel.track('fullscreenImageSlide', {
                'index': index,
                'productId': "productId-" + productId,
              });
            }
          }}
          >
          {
            productImages.map((item, i) => {
              let contentType = determineMediaType(item);

              if (contentType === "image") {
                return (
                  <div 
                    key={"img-product-" + productId + i }
                    onClick={() => {
                        setImageOverlayOpen(true);
                        Mixpanel.track('fullscreenImageOpen', {
                          'productId': "productId-" + productId,
                        });
                      }}>
                  <img src={item} className={classes.cartCarouselImage}/>
                </div>
                );
              } else if (contentType === "video") {
                return (
                  <div 
                  key={"img-product-" + productId + i }
                  onClick={() => {
                      setImageOverlayOpen(true);
                    }}>
                    <VideoComponent 
                      source={item} 
                      // videoHasSound={item?.includes("_with_sound")}
                      videoSoundOn={item?.includes("_with_sound") && imageOverlayOpen === false && imageOverlayIndex === i}
                    />
                  </div>
                );
              } else {
                return (
                  <div 
                  key={"img-product-" + productId + i }>
                    <img src={item} className={classes.cartCarouselImage} />
                  </div>
                );
              }
            }).filter(Boolean)
          }
        </Carousel>
        {
          !isSoldOut && numberOfItemsRemaining && numberOfItemsRemaining < 14 ?
          <Box className={classes.sisaBox}>
            <Typography className={classes.sisaText}>
              Sisa {numberOfItemsRemaining}
            </Typography>
          </Box>:
          null
        }
      </Box>
      <Box className={classes.productDetailMain}>
        <Grid container>
          {/* Buat Desktop Ternyata */}
          <Box className={classes.sectionPhoto}>
            <OptionsSlider
              title={[]}
              buttonMarginRight='24px'
              contentsChange={productId}
              contents={
                productImages.map((item, i) => {
                  let contentType = determineMediaType(item);

                  return (
                    <OptionsItemContainer
                    xsSize={6}
                    smSize={6}
                    mdSize={6}
                    lgSize={6}
                    content={
                      <div className={classes.cartContentImageContainer}
                        onClick={() => {
                          Mixpanel.track('fullscreenImageOpen', {
                            'productId': "productId-" + productId,
                          });
                          if (imageOverlayIndex !== i) {
                            setImageOverlayIndex(i);
                            Mixpanel.track('fullscreenImageSlide', {
                              'index': i,
                              'productId': "productId-" + productId,
                            });
                          }
                          setTimeout(() => {
                            setImageOverlayOpen(true);
                          }, 250);
                        }}>
                          {
                            contentType === "image" ?
                            <ReactImageMagnify {...{
                              smallImage: {
                                alt: 'Wristwatch by Ted Baker London',
                                isFluidWidth: true,
                                src: item,
                              },
                              largeImage: {
                                src: item,
                                width: 720,
                                height: 1080,
                              }
                            }} 
                            enlargedImagePosition="over"
                            isHintEnabled={ i === 1 ? true : false} />:
                            contentType === "video" ?
                            <VideoComponent 
                              source={item} 
                              // videoHasSound={item?.includes("_with_sound")}
                              videoSoundOn={item?.includes("_with_sound") && imageOverlayOpen === false && imageOverlayIndex === i}
                            />:
                            <div></div>
                          }
                      </div>
                    }/>)})
                }
              marginDesktop='0px 0px 0px 0px'
              marginMobile='0px 0px 0px 0px'
            />
          </Box>
          <Box className={classes.sectionDescription} >
            <Box className={classes.stickyDetailContainer}>
              <Box className={classes.mobileViewImageCarouselContainer}>
              </Box>
              {
                (new Date().toISOString() < productSpotlightTime) &&
                <Countdown
                  date={productSpotlightTime}
                  renderer={countdownRenderer}
                  onComplete={(timeDelta, completedOnStart) => {
                    setTimeout(() => {
                      setLastRenderTime(new Date().toISOString())
                    }, 400)
                  }}
                />
              } 
              <div className={classes.productNameList} style={{
                  marginBottom: '1px',
                }}>
                <Typography className={classes.productNameListText}>
                  <b>{ReactHtmlParser(productDetail.name)}</b>
                </Typography>
              </div>
              
              <div onClick={() => {
                const element = document.getElementById("product-review-container");

                if (element) {
                  element.scrollIntoView({behavior: "smooth", block: isDesktop ? "end" : "center"});
                }
              }} style={{
                cursor: 'pointer',
                WebkitTapHighlightColor: 'transparent'
              }}>
              <ReviewComponent 
                ratingNumber={productCategoryReviewRecap[productCategoryForReview]?.numberOfRatings} 
                ratingValue={productCategoryReviewRecap[productCategoryForReview]?.averageRating}
                categoryName={productCategoryForReviewName}
              />
              </div>
             

              <div className={classes.productPrice}>
                {
                  discountedPrice === -1 || isSoldOut || productDetail.isComingAgainSoon
                  // || productDetail.productBackendVersion !== "v3.0"  
                  ?
                  <Box className={classes.priceBox}>
                    <Typography variant="body1"  className={classes.productPriceText}>
                      Rp {productDetail.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "}
                    </Typography>
                  </Box> 
                  : 
                  <Box className={classes.priceBox}>
                    <Typography variant="body1"  className={classes.productPriceText}>
                      Rp {discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "}
                    </Typography>
                    <Typography variant="body1"  className={classes.productPriceTextDiscountStrikeThrough}>
                      Rp {productDetail.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "}
                    </Typography>
                  </Box>
                }
              </div>

              {

                productDetail.stockAvailType === "LimitedStock-1" ? 
                <Box className={classes.limitedStockBubbleBox}>
                  <Typography className={classes.limitedStockBubbleText}>
                    LIMITED STOCK
                  </Typography>
                </Box>:
                productDetail.stockAvailType === "RoutineRestock-1" ? 
                null:
                <Box className={classes.limitedStockBubbleBox}>
                  <Typography className={classes.limitedStockBubbleText}>
                    LIMITED STOCK
                  </Typography>
                </Box>
              }
              {
                productDetail.stockAvailType === "RoutineRestock-1" ?
                (
                  productReviewsState.productId + "" !== reviewProductId + "" ?
                  <div className={classes.productRating}>
                    <Typography className={classes.productDescriptionText}>
                      Loading reviews..
                    </Typography>
                  </div>:
                  productReviewsState.reviews.length === 0 || productReviewsState.averageStars === undefined ? 
                  <div className={classes.productRating}>
                    <Typography className={classes.productDescriptionText}>
                      No ratings yet
                    </Typography>
                  </div> : 
                  <div className={classes.productRating}>
                    <StarsElement stars={productReviewsState.averageStars} />
                    <Typography className={classes.productDescriptionText}>
                      {productReviewsState.reviews.length + " Ratings"}
                    </Typography>
                  </div> 
                ):
                null
              }
              
              {
                !isSoldOut && numberOfItemsRemaining && numberOfItemsRemaining < 14 ?
                <Box className={classes.sisaBoxInDetail}>
                  <Typography className={classes.sisaTextInDetail}>
                    Sisa {numberOfItemsRemaining}
                  </Typography>
                </Box>:
                null
              }

              {
                isSoldOut ? 
                <div className={classes.productLeftInStock}>
                  <Typography variant="body1"  className={classes.productLeftInStockText}>
                    SOLD OUT
                  </Typography>
                </div>:
                productDetail.isComingAgainSoon 
                //|| productDetail.productBackendVersion !== "v3.0" 
                ? 
                <div className={classes.productLeftInStock}>
                  <Typography variant="body1"  className={classes.productLeftInStockText}>
                    COMING BACK SOON
                  </Typography>
                </div>:
                null
              }
              {
                productDetail?.multiDescriptionData?.length && productDetail.multiDescriptionData.length > 0 ?
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    {productDetail.multiDescriptionData.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          padding: '5px 6px 12px',
                        }}
                        onClick={() => setSelectedReviewTab(index)}
                      >
                        <Typography style={{
                          fontWeight: 600,
                          fontSize: '13px',
                          letterSpacing: '0.6px',
                          color: index === selectedReviewTab ? '#121212' : '#676767',
                          textDecoration: index === selectedReviewTab ? 'underline' : '',
                          textUnderlineOffset: '5px',
                          textDecorationThickness: '1.2px',
                          cursor: 'pointer',
                        }}>{item.tabTitle}</Typography>
                      </div>
                    ))}
                  </div>
                  
                  <div
                   style={{ 
                    width: '100%', 
                    // color: 'yellow',
                     }}>
                      <div style={{
                        height: 'fit-content',
                        width: '100%',
                        position: 'relative',
                      }}>
                        {productDetail.multiDescriptionData.map((item, index) => (
                          <Fade key={index} in={index === selectedReviewTab} timeout={300}>
                            <div
                              style={{
                                width: '100%',
                                color: 'grey',
                                display: index === selectedReviewTab ? 'block' : 'none',
                                // visibility: index === selectedReviewTab ? 'visible' : 'hidden',
                                // position: index === 0 ? undefined : 'absolute',
                                // top: '0px',
                              }}
                            >
                              {item.tabType === 'DESCRIPTION' && (
                                item.tabData.map((subItem: {} | null | undefined, subIndex: React.Key | null | undefined) => {
                                  if (typeof subItem === 'string') {
                                    return (
                                      <div key={subIndex}>
                                        <Typography className={classes.productDescriptionText} style={{
                                          marginBottom: '1px',
                                        }}>{subItem}</Typography>
                                      </div>
                                    );
                                  } else if (Array.isArray(subItem)) {
                                    return (
                                      <div key={subIndex}>
                                        <ul style={{
                                          marginTop: '10px',
                                          marginBottom : '23px',
                                        }}>
                                          {subItem.map((listItem, listItemIndex) => (
                                            <li key={listItemIndex}>
                                              <Typography className={classes.productDescriptionText} 
                                              style={{
                                                marginBottom: '5px',
                                              }}>{listItem}</Typography>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    );
                                  }
                                  return null;
                                })
                              )}
                              {item.tabType === 'DETAILLIST' && (
                                <Grid container
                                  style={{
                                    marginTop: '6px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    flexWrap: 'wrap',
                                    background: 'lightred'
                                  }}
                                >
                                  {item.tabData.map((detailItem: any, detailItemIndex: React.Key | null | undefined) => (
                                    <Grid item xs={6}
                                      key={detailItemIndex}
                                      style={{
                                      }}
                                    >
                                      {/* Hacky workaround ts */}
                                      <Typography className={classes.productDescriptionText} style={{
                                        fontWeight: 600,
                                        letterSpacing: '0px',
                                        marginBottom: '0px',
                                      }}>{detailItem.title}</Typography> 
                                      <Typography className={classes.productDescriptionText} style={{
                                        fontWeight: 300,
                                        fontStyle: 'italic',
                                        color: '#757575',
                                        marginBottom: '19px',
                                        letterSpacing: '0.2px',
                                      }}>{detailItem.text}</Typography>
                                    </Grid>
                                  ))}
                                </Grid>
                              )}
                            </div>
                          </Fade>
                        ))}

                      </div>
                  </div>
                </div>
                :
                <div className={classes.productDescription}>
                  <Typography className={classes.productSubtitle}>
                    DESKRIPSI
                  </Typography>
                  <Typography variant="body1"  className={classes.productDescriptionText}>
                    {
                      ReactHtmlParser(productDetail.description)
                    }
                  </Typography>
                </div>
              }
              
              <div className={classes.ukuranRegulerBox} id='ukuran-reguler-box'>
                <Typography className={classes.productSubtitle}>
                  UKURAN REGULER
                </Typography>
                <Typography className={classes.ukuranRegulerBoxSizeChartLink}
                onClick={() => {
                  dispatch(updateSizeChartClothFitType({
                    clothingType: clothingType,
                    fitType: fitType
                  }));
                  dispatch(openOverlaySizeChart());
                  sendWebsiteEventMessage("User opens size chart");
                }}>
                  + LIHAT SIZE CHART
                </Typography>
                <Box className={classes.ukuranRegulerSizeTextBox} id='size-reguler-list' onClick={() => {
                  if (currentSelectedMeasurement === undefined) {
                    if ((window as any).fbq !== null) { 
                      (window as any).fbq('trackCustom', 'CreateSize', {
                        currency: "IDR",
                        value: productDetail.discountedPrice === -1 ? productDetail.originalPrice : productDetail.discountedPrice,
                      })
                    }

                    sendWebsiteEventMessage("Ada yang click pilih ukuran dari reguler. Product: " + productDetail.name);

                    dispatch(postNewProductAnalyticsLog({event: 'SELECT-SIZE', productId: productId, isMobileView: !isDesktop }))

                    dispatch(openOverlayCreateMeasWithInitialization({
                      clothingType: clothingType,
                      fitType: fitType,
                      productDetail: productDetail,
                      addToCartAfterCreation: true,
                    }));
                    return;
                  } else {
                    const element = document.getElementById("size-reguler-list");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }
                  }
                }}>
                  {
                    ["XXS", "XS", "S", "M", "L", "XL", "XXL"].map((value, index) => {
                      const ukuranAda = productSizeAvailabilityDict?.[productId]?.availabilityArray?.[index];
                      return (
                        <Typography className={ ukuranAda ? classes.ukuranRegulerSizeTextAda : classes.ukuranRegulerSizeTextHabis }>
                          {value}
                        </Typography>
                      );
                    })
                  }
                </Box>
                <Typography className={classes.talentInfoText} id='talent-info-text'>
                  Talent tinggi 175 cm dan berat 76 kg, memakai size L.
                </Typography>
                <Typography className={classes.restockInfoText}>
                  <span className={classes.restockInfoTextIcon}>&#x24D8;</span>
                  {
                    productDetail.stockAvailType === "LimitedStock-1" ? 
                    "Tidak ada restock untuk item ini / limited restock." :
                    productDetail.stockAvailType === "RoutineRestock-1" ? 
                    "Jika ukuran habis, produk restock ":
                    "Tidak ada restock untuk item ini."
                  }
                  {
                    productDetail.stockAvailType === "LimitedStock-1" ? 
                    null :
                    productDetail.stockAvailType === "RoutineRestock-1" ? 
                    <span className={classes.restockInfoTextHighlight}>
                      est. {(productDetail.stockRestockTime ? productDetail.stockRestockTime : "7-8 minggu")}
                    </span>:
                    null
                  }
                  {
                    productDetail.stockAvailType === "LimitedStock-1" ? 
                    null :
                    productDetail.stockAvailType === "RoutineRestock-1" ? 
                    " lagi":
                    null
                  }
                </Typography>
                
              </div>
              {
                currentSelectedMeasurement !== undefined ? 
                <div id='pilih-ukuran-segment-header'>
                  <Typography className={classes.productSubtitle}>
                    UKURAN KAMU
                  </Typography>
                  <SizeSelection clothingType={clothingType} fitType={fitType} 
                    productDetail={productDetail}
                    handleClick={() => {
                        dispatch(postNewProductAnalyticsLog({event: 'SELECT-SIZE', productId: productId, isMobileView: !isDesktop }))
                      }
                    }
                  />
                </div>
                :
                <div id='pilih-ukuran-segment-header'>
                  <Typography className={classes.productSubtitle}>
                    PILIH UKURAN & BELI
                  </Typography>
                  <Typography className={classes.pilihUkuranSubtext}>
                    {"Tersedia ukuran reguler, atau ada ukuran custom."}
                  </Typography>
                </div>
              }
              <FareastButton
                buttonMarginTopBottom='10px'
                buttonMarginLeftRight='0px'
                disabled={isSoldOut || productDetail.isComingAgainSoon
                  || (currentSelectedMeasurement !== undefined && canAddProductAmountToCart(cart, cartStateItem) !== "ENOUGH")
                  || postMeasurementStatus === APIRequestStatus.RequestInProgress}
                variant="contained"
                aria-label="add to cart"
                fullWidth
                onClick={() => {
                  (window as any).dataLayer.push({'event': 'cartAddItemOrSizeCreateNew', 'productId': "productId-" + productId});
                  Mixpanel.track('cartAddItemOrSizeCreateNew', {
                    'productId': "productId-" + productId,
                  });
                  if ((window as any).fbq !== null) { 
                    (window as any).fbq('trackCustom', 'CreateSizeOrAddToCart', {
                      currency: "IDR",
                      value: productDetail.discountedPrice === -1 ? productDetail.originalPrice : productDetail.discountedPrice,
                    })
                  };

                  if ((window as any).fbq !== null) { 
                    (window as any).fbq('track', 'AddToCart', {
                      currency: "IDR",
                      value: productDetail.discountedPrice === -1 ? productDetail.originalPrice : productDetail.discountedPrice,
                    })
                  };

                  if (currentSelectedMeasurement === undefined) {
                    if ((window as any).fbq !== null) { 
                      (window as any).fbq('trackCustom', 'CreateSize', {
                        currency: "IDR",
                        value: productDetail.discountedPrice === -1 ? productDetail.originalPrice : productDetail.discountedPrice,
                      })
                    }
                    (window as any).dataLayer.push({'event': 'sizeCreateNew'});
                    Mixpanel.track('sizeCreateNew', {
                      'productId': "productId-" + productId,
                      'signedIn': authState === 'signedin' ? 'true' : 'false',
                      'source': 'productPageAddToCartButton',
                    });
                    console.log("Tracking mixpanel sizeCreateNew for product " + productId);

                    sendWebsiteEventMessage("Ada yang click pilih ukuran. Product: " + productDetail.name);

                    dispatch(postNewProductAnalyticsLog({event: 'SELECT-SIZE', productId: productId, isMobileView: !isDesktop }))

                    dispatch(openOverlayCreateMeasWithInitialization({
                      clothingType: clothingType,
                      fitType: fitType,
                      productDetail: productDetail,
                      addToCartAfterCreation: true,
                    }));
                    return;
                  }

                  // We temporarily consider add to cart the same event as select size (select size is included in add to cart)
                  dispatch(postNewProductAnalyticsLog({event: 'SELECT-SIZE', productId: productId, isMobileView: !isDesktop }))

                  sendWebsiteEventMessage("Ada yang click add to cart. Product: " + productDetail.name);

                  (window as any).dataLayer.push({'event': 'cartAddItem', 'productId': "productId-" + productId});
                  Mixpanel.track('cartAddItem', {
                    'productId': "productId-" + productId,
                  });
                  console.log("Tracking mixpanel cartAddItem for product " + productId);

                  if (cartStateItem.sizeType === 'personal') {
                    dispatch(addItemCount({ 
                      productId: productId, 
                      productDetail: productDetail,
                      fitType: fitType,
                      sizeType: cartStateItem.sizeType,
                      measurementCreationDateTime: currentSelectedMeasurement.creationDateTime as string,
                      measurementIsBodyMeasurement: currentSelectedMeasurementIsBody,
                      regularSize: cartStateItem.regularSize ? cartStateItem.regularSize : RegularSizeValue.NOTSELECTED,
                    }));
                    dispatch(updateCurrentScreen("CART"));
                  } else if ((cartStateItem.sizeType === 'regular')) {
                    dispatch(addItemCount({ 
                      productId: productId, 
                      productDetail: productDetail,
                      fitType: fitType,
                      sizeType: cartStateItem.sizeType,
                      measurementCreationDateTime: 'NOTSELECTED',
                      measurementIsBodyMeasurement: currentSelectedMeasurementIsBody,
                      regularSize: cartStateItem.regularSize,
                    }));
                    dispatch(updateCurrentScreen("CART"));
                  }
                }}
              >
                <Typography className={classes.addToCartButtonText} id='text-add-to-cart'>
                  {
                    postMeasurementStatus === APIRequestStatus.RequestInProgress ?
                    "LOADING..." :
                    productPurchaseStage === "PILIH_UKURAN" ? 
                    "PILIH UKURAN & BELI PRODUK" :
                    productPurchaseStage === "UNDEFINED_ITEM" ?
                    "UNDEFINED ITEM" :
                    productPurchaseStage === "CHECKING_STOCK" ? 
                    "CHECKING STOCK..." :
                    productPurchaseStage === "ADD_TO_CART" ?
                    "ADD TO CART" :
                    (
                      currentSelectedMeasurementIsBody ? 
                      "BAHAN TIDAK CUKUP UNTUK UKURAN" :
                      currentSelectedMeasurement?.sizeType === 'personal' ? 
                      "BAHAN TIDAK CUKUP UNTUK UKURAN" : 
                      "STOK HABIS"
                    )
                  }
                </Typography>
              </FareastButton>
              {
                isSignedIn ?
                null : 
                <SignUpSignInLink 
                isSignUp={true}
                handleClick={(isSignUp) => {
                  if (isSignUp) {
                    dispatch(openOverlay());
                    dispatch(updateSignUpOrSignIn('signIn'))
                  } else {
                    dispatch(openOverlay());
                    dispatch(updateSignUpOrSignIn('signUp'))
                  }
                }}/>
              }
            </Box>
          </Box>
        </Grid>
      </Box>

      {/* Review Haidar */}
      
      <Box className={classes.categoryReviewContainer} id='product-review-container'>
        <Box className={classes.categoryReviewContent} 
          onClick={() => handleOpenDialogSeeAllReview()}>
          <Typography className={classes.categoryReviewContentTitle}>
            REVIEW UNTUK {productCategoryForReviewName}
          </Typography>
          <ReviewComponent 
            ratingNumber={productCategoryReviewRecap[productCategoryForReview]?.numberOfRatings} 
            ratingValue={productCategoryReviewRecap[productCategoryForReview]?.averageRating}
            categoryName={productCategoryForReviewName}
          />
        </Box>

        {
          productCategoryReviewList && productCategoryReviewList.length > 0 &&
          <div>
              <ReviewSliderImage reviews={shortenedReviewList} onClickImage={(reviewIndex: number, imageIndex: number) => handleOpenDialogReviewImage(reviewIndex, imageIndex)}/>

              <ReviewSlider reviews={shortenedReviewList} onClickContent={() => handleOpenDialogSeeAllReview()} onClickImage={(reviewIndex: number, imageIndex: number) => handleOpenDialogReviewImage(reviewIndex, imageIndex)}/>

              <Box className={classes.categoryReviewButtonContainer}>
                <Box 
                className={classes.categoryReviewButtonSeeAll}
                onClick={() => handleOpenDialogSeeAllReview()}
                >
                  <Typography className={classes.categoryReviewButtonSeeAllText}>
                    LIHAT SEMUA REVIEW ({productCategoryReviewRecap[productCategoryForReview]?.numberOfRatings} REVIEW)
                  </Typography>
                </Box>
              </Box>

              <ReviewDialogImage
                open={openDialogReviewImage}
                onClose={handleCloseDialogReviewImage}
                reviews={fullReviewList}
                initialReviewIndex={selectedReviewIndex}
                initialImageIndex={selectedImageIndex}
              />

              <ReviewDialog
                  open={openDialogSeeAllReview}
                  onClose={handleCloseDialogSeeAllReview}
                  categoryName={productCategoryForReviewName}
                  ratingValue={productCategoryReviewRecap[productCategoryForReview]?.averageRating}
                  ratingNumber={productCategoryReviewRecap[productCategoryForReview]?.numberOfRatings}
                  reviews={fullReviewList}
                  onClickImage={(reviewIndex: number, imageIndex: number) => handleOpenDialogReviewImageFromReviewDialog(reviewIndex, imageIndex)}
              />
          </div>
          
        }
      </Box>
     
      {/* Review Haidar */}

      <PageProductOverlayNewReview 
        productId={reviewProductId}
        newReviewOverlayOpen={newReviewOverlayOpen}
        setNewReviewOverlayOpen={setNewReviewOverlayOpen} />
      <PageProductOverlaySizeChart />
      <PageProductOverlayImageCarousel 
        imageOverlayIndex={imageOverlayIndex}
        setImageOverlayIndex={setImageOverlayIndex}
        imageOverlayOpen={imageOverlayOpen} 
        setImageOverlayOpen={setImageOverlayOpen} />
      
      {
        isDesktop ? 
        <img
          alt="svg-sla"
          src='https://images-far-east.s3.ap-southeast-1.amazonaws.com/SLA_September2023_D.svg'
          className={classes.svgContentFullWidthDesktop}
        /> :
        <img
          alt="svg-sla"
          src='https://images-far-east.s3.ap-southeast-1.amazonaws.com/SLA_Oktober2023_M.svg'
          className={classes.svgContentFullWidthMobile}
        />
      }

      <PageProductSuggestions seedNumber={getHash(productId + (new Date().toISOString()).substring(0, 15))} excludedProductId={productId}/>
    </Box>
  );
}


function sortProductCategoryReviewList(productCategoryReviewList: CategoryReview[] | undefined): CategoryReview[] {
  if (!productCategoryReviewList) {
    return [];
  }

  return productCategoryReviewList.sort((a, b) => {
    const aClass = a.reviewClass || 1;
    const bClass = b.reviewClass || 1;

    if (aClass !== bClass) {
      return aClass > bClass ? -1 : 1;
    }

    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  });
}
        
        
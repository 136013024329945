import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Stack } from "@mui/material";
import StarRating from "./PageComponentReviewStarRating";
import { CategoryReview } from "common-ts/dist/models/CategoryReview";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface ReviewDialogImageProps {
  open: boolean;
  onClose: () => void;
  reviews: CategoryReview[];
  initialReviewIndex: number;
  initialImageIndex: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        width: "calc(35vw * 16 / 9)",
        height: "35vw",
        margin: 0,
        borderRadius: 20,
        overflow: "hidden",
        padding: "0 0 0 0",
        [theme.breakpoints.down("sm")]: {
          width: "87vw",
          height: "calc(87vw * 16 / 9)",
        },
      },
    },
    closeButton: {
      position: "absolute",
      right: "4px",
      top: "4px",
      color: "white",
      zIndex: 1,
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    carousel: {
      "& .carousel .control-dots": {
        top: "10px",
        bottom: "auto",
      },
    },
    overlay: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(2.5),
      color: "white",
      zIndex: 2000,
      pointerEvents: "none",
    },
    overlayFade: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(2),
      background:
        "linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
      color: "white",
      height: "80%",
      zIndex: 1500,
      pointerEvents: "none",
    },
    comment: {
      fontWeight: "normal",
      fontSize: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      color: "#FFFFFF",
      fontFamily: "Open sans, sans-serif",
      display: "-webkit-box",
      // WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      // textOverflow: 'ellipsis',
      flex: 1,
    },
    ReviewTitle: {
      fontWeight: "bold",
      color: "##FFFFFF",
      fontSize: "14px",
    },
    reviewProductNameListText: {
      fontWeight: "bold",
      fontSize: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
      color: "#A5A5A5",
    },
    timestampText: {
      fontSize: 10,
      color: "#A5A5A5",
      margin: 0,
    },
    reviewComment: {
      fontWeight: "normal",
      fontSize: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
      color: "#FFFFFF",
      fontFamily: "Open sans, sans-serif",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: 1,
    },
    navButton: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
      "&:disabled": {
        color: "rgba(255, 255, 255, 0.3)",
      },
    },
  })
);

const ReviewDialogImage: React.FC<ReviewDialogImageProps> = ({
  open,
  onClose,
  reviews,
  initialReviewIndex,
  initialImageIndex,
}) => {
  const classes = useStyles();
  const [currentReviewIndex, setCurrentReviewIndex] =
    useState(initialReviewIndex);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);

  useEffect(() => {
    setCurrentReviewIndex(initialReviewIndex);
    setCurrentImageIndex(initialImageIndex);
  }, [initialReviewIndex, initialImageIndex]);

  const handleChange = (index: number) => {
    setCurrentImageIndex(index);
  };

  const parseDisplayImageList = (stringifiedList: string): string[] => {
    try {
      return JSON.parse(stringifiedList);
    } catch {
      return [];
    }
  };

  // Untuk mengecek apakah review punya gambar
  const findNextReviewWithImages = (startIndex: number, direction: number) => {
    while (startIndex >= 0 && startIndex < reviews.length) {
      const imageList = parseDisplayImageList(
        reviews[startIndex].displayImageList
      );
      if (imageList.length > 0) {
        return startIndex;
      }
      startIndex += direction;
    }
    return -999;
  };

  // Logika pindah review
  const handleClickItem = (direction: number) => {
    const currentReview = reviews[currentReviewIndex];
    const currentImageList = parseDisplayImageList(
      currentReview.displayImageList
    );

    if (direction === -1 && currentImageIndex === 0) {
      if (currentReviewIndex > 0) {
        const prevReviewIndex = findNextReviewWithImages(
          currentReviewIndex - 1,
          -1
        );
        if (prevReviewIndex !== -999) {
          setCurrentReviewIndex(prevReviewIndex);
          const prevImageList = parseDisplayImageList(
            reviews[prevReviewIndex].displayImageList
          );
          setCurrentImageIndex(prevImageList.length - 1);
        }
      }
    } else if (
      direction === 1 &&
      currentImageIndex === currentImageList.length - 1
    ) {
      if (currentReviewIndex < reviews.length - 1) {
        const nextReviewIndex = findNextReviewWithImages(
          currentReviewIndex + 1,
          1
        );
        if (nextReviewIndex !== -999) {
          setCurrentReviewIndex(nextReviewIndex);
          setCurrentImageIndex(0);
        }
      }
    } else {
      setCurrentImageIndex((prevIndex) => prevIndex + direction);
    }
  };

  const selectedReview = reviews[currentReviewIndex];
  const selectedImageList = parseDisplayImageList(
    selectedReview.displayImageList
  );

  const isFirstImage = currentReviewIndex === 0 && currentImageIndex === 0;
  const isLastImage =
    currentReviewIndex === reviews.length - 1 &&
    currentImageIndex === selectedImageList.length - 1;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      fullWidth
      maxWidth={false}
    >
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ padding: 0, backgroundColor: "black" }}>
        {selectedReview && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Box position="relative" width="100%" height="100%">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img src={selectedImageList[currentImageIndex]} className={classes.image} />
              </div>
              <IconButton
                onClick={() => handleClickItem(-1)}
                disabled={isFirstImage}
                className={classes.navButton}
                style={{ left: 10 }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                onClick={() => handleClickItem(1)}
                disabled={isLastImage}
                className={classes.navButton}
                style={{ right: 10 }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
            <Box className={classes.overlayFade}></Box>
            <Box className={classes.overlay}>
              <Box>
                <Typography className={classes.ReviewTitle}>
                  {selectedReview.name.slice(0, 3) +
                    "*".repeat(Math.max(selectedReview.name.length - 3, 0))}
                </Typography>
              </Box>
              <Box mt={0.5} mb={0.5}>
                <Stack direction="row" alignItems="center">
                  <StarRating
                    productRating={selectedReview.rating}
                    starSize={16}
                    gap={0}
                    mr={1}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.timestampText}
                    gutterBottom
                  >
                    {formattimestamp(selectedReview.timestamp)}
                  </Typography>
                </Stack>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body1" className={classes.reviewComment}>
                  {selectedReview.comment}
                </Typography>
              </Box>
            </Box>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

const formattimestamp = (timestamp: string): string => {
  const now = new Date();
  const timestampTime = new Date(timestamp);
  const diffInMilliseconds = now.getTime() - timestampTime.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  const diffInDays = diffInHours / 24;
  const diffInMonths = diffInDays / 30;
  const diffInYears = diffInDays / 365;

  if (diffInHours < 24) {
    if (diffInHours < 1) {
      return `${Math.floor(diffInHours)} Saat ini`;
    } else {
      return `${Math.floor(diffInHours)} Jam yang lalu`;
    }
  } else if (diffInDays < 2) {
    return "Kemarin";
  } else if (diffInDays < 30) {
    return `${Math.floor(diffInDays)} Hari yang lalu`;
  } else if (diffInMonths < 12) {
    return `${Math.floor(diffInMonths)} Bulan yang lalu`;
  } else {
    return `${Math.floor(diffInYears)} Tahun yang lalu`;
  }
};

export default ReviewDialogImage;
